import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';

import { Avatar, Button, CircularProgress, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import useStyles from '../../../theme/useStyles';

import { Logout } from '../../../actions/auth/authActions';

const ListItemUser = ( ) => {

    const dispatch = useDispatch();

    const { nombres, imagenPerfil } = useSelector( state => state.auth );

    const { avatar } = useSelector( state => state.user );

    const { openLogout } = useSelector(state => state.loading);

    const classes = useStyles();
    
    const [anchorEl, anchorElOpen] = useState(null);
  
    const open = Boolean(anchorEl);    

    const handleOpen = (e) => {
        anchorElOpen( e.currentTarget );
    };
  
    const handleClose = () => {
        anchorElOpen( null );
    };

    const handleSalir = () => {

        dispatch( Logout() );

    }

    return (
        <>
            <ListItem component="div" style={{ textAlign: 'right', }}>  
                <ListItemText className={classes.listItem} inset>
                    <Button color="inherit" className={ classes.buttonIcon } onClick={ handleOpen } >
                        <Avatar 
                            alt="Mi Avatar" className={classes.avatarPerfilAppBar} 
                            src={ 
                                imagenPerfil 
                                ? 
                                (
                                    avatar 
                                    ? 
                                    avatar
                                    :
                                    imagenPerfil 
                                )
                                : 
                                'https://media.istockphoto.com/vectors/default-avatar-profile-icon-grey-photo-placeholder-vector-id846183008?k=6&m=846183008&s=170667a&w=0&h=bCKJRIR5IBFOv80Rd8HfBVj_VirFxgWuZIDJcg9OK9M=' } 
                        />
                        
                        <Typography color="inherit" variant="subtitle2" className={classes.ListTypography}>
                            { nombres }
                        </Typography>

                        <KeyboardArrowDownIcon />                     
                    </Button>
                </ListItemText>
            </ListItem>

            <Menu elevation={ 4 } anchorEl={ anchorEl } anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }} keepMounted open={ open } onClose={ handleClose }>
                
                <Link to="/User/Profile" className={classes.link}>
                    <MenuItem className={ classes.listSubItem} onClick={ () => {} }>
                        <ListItemIcon className={ classes.listItemIcon }>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText>Perfil</ListItemText>
                    </MenuItem>
                </Link>

                <MenuItem className={ classes.listSubItem } onClick={ () => {} }>
                    <ListItemIcon className={ classes.listItemIcon }>
                        {
                            openLogout
                            ?
                                <CircularProgress size={20} />
                            :
                                <ExitToAppIcon />
                        } 
                    </ListItemIcon>
                    <ListItemText onClick={ handleSalir }> Salir </ListItemText>                       
                </MenuItem>                    
            </Menu>  

        </>
    )
}

export default withRouter(ListItemUser);