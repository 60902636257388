import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Autocomplete, Button, Checkbox, Dialog, DialogContentText, DialogActions, DialogContent, Snackbar, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputBase, InputLabel,   MenuItem, Paper, RadioGroup, Radio, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import { LoadingButton } from '@mui/lab';

import { types } from '../../../types/types';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalNuevaPersona } from '../../modals/ModalNuevaPersona';
import { ModalBuscarPersona } from '../../modals/ModalBuscarPersona';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';
import { usePages } from '../../../hooks/usePages';

import { ObtenerTipoParte } from '../../../actions/admin/catalogs/tipoparteActions';
import { ObtenerDependencias } from '../../../actions/admin/catalogs/dependenciaActions';
import { ObtenerTipoIdentificacion } from '../../../actions/admin/catalogs/tipoidentificacionActions';
import { GuardarPartesAudiencia } from '../../../actions/audiencias/audienciaActions';

export const PartesAudienciaScreen = ({ edit }) => {

    const { audiencia, partesAudiencia, partesExpediente } = useSelector( state => state.audiencias);

    const { tribunal } = useSelector( state => state.auth );
    
    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const dispatch = useDispatch();    

    const [open, setOpen] = useState(false);

    const [alert, setAlert] = useState( {  } );

    const [rows, setRows] = useState( [ ] );

    const [errors, setErrors] = useState({ });

    const [idReferencia, setReferencia] = useState( 0 );

    const [openConfirmacionEliminarParte, setOpenConfirmacionEliminarParte] = useState(false);

    const [loadingInformacion, setLoadingInformacion] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );

    const [tipoParte, setTipoParte] = useState( 0 );
    const [tipoParteArray, setTipoParteArray] = useState( [ ] );

    const [tipoIdentificacion, setTipoIdentificacion] = useState( 0 );
    const [tipoIdentificacionArray, setTipoIdentificacionArray] = useState( [ ] );

    const [tipoIdentificacionEditar, setTipoIdentificacionEditar] = useState( 0 );
    const [tipoIdentificacionEditarArray, setTipoIdentificacionEditarArray] = useState( [ ] );

    const [personaDependencia, setPersonaDependencia] = useState( 0 );

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const { idPersona , nombreCompleto } = persona;

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const [nuevaParte, setNuevaParte] = useState( false );
    const [parte, setParte] = useState( 0 );
    const [arrayParte, setArrayParte] = useState( [] );

    const handleChangePersonaDependencia = ( value ) => {
        setPersonaDependencia( value );
        setPersona( { idPersona: 0, nombreCompleto: '' } );
        setAutocompleteValue( null );
        setDependencia( 0 );
        setTipoParte( 0 );
        setTipoIdentificacion(0);
    }

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const handleGuardarTipoIdentificacion = () => {
        const tipoIdentificacionData = tipoIdentificacionEditarArray.find( ( element ) => { return element.idTipoIdentificacion === tipoIdentificacionEditar });

        var newArray = rows.map(function( element ){ 
            if(element.id === idReferencia){
                element.tipoIdentificacion = tipoIdentificacionData;
            }
            return element;
        });
        
        setRows( newArray );

        setReferencia(0);

        setTipoIdentificacionEditar(0);

        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseAlert = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleEditarTipoIdentificacion = (Id,idTipoIdentificacion) => {
        
        setReferencia( Id );
        setTipoIdentificacionEditar( idTipoIdentificacion );
        
        setOpen(true);

    }

    const handleOpenConfirmacionEliminarParte = () => {       
        setOpenConfirmacionEliminarParte(true);
    };

    const handleCloseConfirmacionEliminarParte = () => {
        setOpenConfirmacionEliminarParte(false);
    };

    const handleClicAgregarParte = () => {

        const tipoIdentificacionData = tipoIdentificacionArray.find( ( element ) => { return element.idTipoIdentificacion === tipoIdentificacion });       
        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

        if( nuevaParte ){
            
            const tipoParteData = tipoParteArray.find( ( element ) => { return element.idTipoParte === tipoParte });

            if( idPersona !== 0 ){

                const personaValidate =  rows.find( ( element ) => { return element.idPersona === persona.idPersona });

                if( !personaValidate ){
                    const data = {
                        id: parseInt( id ),
                        idPersona : persona.idPersona,
                        persona: persona,
                        idDependencia: null,
                        dependencia: null,
                        tipoParte: tipoParteData,
                        tipoIdentificacion: tipoIdentificacionData,
                        idDomicilio: 0
                    };

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La persona ya se encuentra agregada';
                
                    setErrors( errores );  
                }  
            }
            else if ( dependencia !== 0 ){

                const dependenciaValidate =  rows.find( ( element ) => { return element.idDependencia === dependencia });

                if( !dependenciaValidate ){

                    var dependenciaData = dependenciaArray.find( ( element ) => { return element.idDependencia === dependencia  });

                    const data = {
                        id: parseInt( id ),
                        idPersona: null,
                        persona: null,
                        idDependencia: dependencia,
                        dependencia: dependenciaData,
                        tipoParte: tipoParteData,
                        tipoIdentificacion: tipoIdentificacionData,
                        idDomicilio: 0
                    };
        
                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La dependencia ya se encuentra agregada';
                
                    setErrors( errores ); 
                }            
            }

        }
        else {

            const idPartePersona = arrayParte.find( ( elem ) => elem.id === parte )?.idPersona ?? 0;
            const idParteDependencia = arrayParte.find( ( elem ) => elem.id === parte )?.idDependencia ?? 0;

            if( idPartePersona !== 0 ){

                const personaValidate =  rows.find( ( element ) => { return element.idPersona === idPartePersona });

                if( !personaValidate ){

                    const partePersona = arrayParte.find( ( elem ) => elem.id === parte );

                    const data = {
                        id: parseInt( id ),
                        idPersona : partePersona.idPersona,
                        persona: partePersona.persona,
                        idDependencia: null,
                        dependencia: null,
                        tipoParte: partePersona.tipoParte,
                        tipoIdentificacion: tipoIdentificacionData,
                        idDomicilio: 0
                    };
            
                    dispatch({
                        type: types.actualizarNotificacionesAcuerdo,                                
                        payload: [ ...rows, data ],                              
                    });

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La persona ya se encuentra agregada';
                
                    setErrors( errores );  
                }  
            }
            else if ( idParteDependencia !== 0 ){

                const dependenciaValidate =  rows.find( ( element ) => { return element.idDependencia === idParteDependencia });

                if( !dependenciaValidate ){

                    const parteDependencia = arrayParte.find( ( elem ) => elem.id === parte );
                  
                    const data = {
                        id: parseInt( id ),
                        idPersona: null,
                        persona: null,
                        idDependencia: parteDependencia.idDependencia,
                        dependencia: parteDependencia.dependencia,
                        tipoParte: parteDependencia.tipoParte,
                        tipoIdentificacion: tipoIdentificacionData,
                        idDomicilio: 0
                    };
            
                    dispatch({
                        type: types.actualizarNotificacionesAcuerdo,                                
                        payload: [ ...rows, data ],                              
                    });

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La dependencia ya se encuentra agregada';
                
                    setErrors( errores ); 
                }            
            }

        }

        setPersonaDependencia( 0 );  
        setPersona( { idPersona: 0, nombreCompleto: '' } );      
        setAutocompleteValue( null );
        setDependencia( 0 );        
        setTipoParte( 0 );
        setParte( 0 );
        setTipoIdentificacion( 0 );
    }

    const handleClicEliminarParte = ( Id ) => {

        handleOpenConfirmacionEliminarParte();

        setReferencia( Id );
        
    }

    const eliminarParte = () => {

        var newArray = rows.filter(function( element ){ 
            return element.id !== idReferencia;
        });
        
        setRows( newArray );

        dispatch({
            type: types.eliminarParteAudiencia,
            payload: { id: idReferencia }
        });

        handleCloseConfirmacionEliminarParte();
    }

    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPersona( persona );

        }

    } 

    const validateFiels = () => {
        let valid = true;
        let errores = {};
        
        if( rows.length === 0 ){
            valid = false;
            errores.error = 'Debes agregar por lo menos una parte';
        }       
        
        setErrors( errores );        

        return valid;
    } 

    const GuardarPartes = async () => {
        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        let arrayPartes = [];

        rows.forEach(element => {
            
            const parte = {
                idPersona : element.persona ? element.persona.idPersona : null,
                idTipoParte : element.tipoParte.idTipoParte,
                idDependencia: element.dependencia ? element.dependencia.idDependencia : null,
                idTipoIdentificacion : element.tipoIdentificacion ? element.tipoIdentificacion.idTipoIdentificacion : null,
                asistio: element.asistio,
            };       
            
            arrayPartes.push( parte );
           
        });       

        const params = {

            idAudiencia : audiencia.idAudiencia,
            audienciaPartes : arrayPartes

        };

        setLoadingInformacion( true );

        await GuardarPartesAudiencia( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    dispatch({

                        type: types.agregarPartesAudiencia,
                        payload: rows
            
                    });
            
                    setTimeout( () => {
                    

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingInformacion( false ); 

                    }, 1000); 

                } else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingInformacion( false ); 

                    }, 1000); 

                }

            }

        });

    }

    const handleChangeAsistio = ( { target }, Id ) => {

        const newRows = rows.map( ( element ) => {            
            
            if( element.id === Id ){
                element.asistio = target.checked;
            } 

            return element; 
        });        
       
        setRows( newRows );     
    }

    const handleNuevaPersona = ( value ) => {

        setNuevaParte( value ); 

        setParte( 0 );
        setPersonaDependencia( 0 );  
        setPersona( { idPersona: 0, nombreCompleto: '' } );      
        setAutocompleteValue( null );
        setDependencia( 0 );        
        setTipoParte( 0 );       
    }

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoIdentificacion().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoIdentificacionArray( response.data );
                        setTipoIdentificacionEditarArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response.status === 200){
                    setDependenciaArray( response.data ); 
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoParte().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoParteArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);

    useEffect(() => {
      
        if( partesAudiencia.length !== 0 ){

            setRows( partesAudiencia );

        }

    }, [ partesAudiencia ])  

    useEffect(() => {
      
        if( partesExpediente.length !== 0 ){
            setArrayParte( partesExpediente );
        }

    }, [ partesExpediente ])  
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleCloseAlert }>
                        <Alert onClose={ handleCloseAlert } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
          
            {
                edit
                &&
                (
                    <>
                        <Grid item md={12} xs={12} >

                            <FormControl fullWidth>
                                                                
                                <Grid container spacing={3}>
                                    
                                    <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                        <FormLabel sx={{ color: 'black' }} >Tipo de persona para agregar</FormLabel>
                                    </Grid>
                                    
                                    <Grid item md={8} xs={12} >
                                        <RadioGroup row >
                                            <Grid container>                                                
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="No" control={ <Radio checked={ nuevaParte === false } onChange={ () => { handleNuevaPersona( false ); } } /> } label="Actor / Demandado" />     
                                                </Grid>   
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Si" control={ <Radio checked={ nuevaParte === true } onChange={ () => { handleNuevaPersona( true ); } } /> } label="Otro" />
                                                </Grid>                                                         
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>

                                </Grid>

                            </FormControl>

                        </Grid>

                        {
                            !nuevaParte
                            &&
                                <Grid item md={12} xs={12} > 

                                    <FormControl fullWidth>
                                        <InputLabel id="select-parte-label">Seleccione una persona</InputLabel>

                                        <Select
                                            labelId="select-parte-label"
                                            id="select-parte"                                            
                                            label="Seleccione una persona"   
                                            name="seleccioneParte"
                                            value={ parte }
                                            onChange={ (e) => { setParte(e.target.value); } }                                                                       
                                        >
                                            <MenuItem value={0}>Selecciona una opción</MenuItem>                                     
                                            {                                       
                                                arrayParte                                          
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombre } &nbsp; <strong>({ elem?.tipoParte?.descripcion ?? '' })</strong> </MenuItem>
                                                ))                                       
                                            } 
                                        </Select>

                                    </FormControl>

                                    {   
                                        errors.parte && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.parte } </Alert> )
                                    }

                                </Grid> 
                        }

                        {
                            tribunal && nuevaParte
                            &&               
                            <Grid item md={12} xs={12} >

                                <FormControl fullWidth>
                                                                    
                                    <Grid container spacing={3}>
                                        
                                        <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                            <FormLabel sx={{ color: 'black' }} >Selecciona una opción para agregar: </FormLabel>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={12} >
                                            <RadioGroup row >
                                                <Grid container >
                                                    <Grid item md={6} xs={6} >
                                                        <FormControlLabel value="Masculino" control={ <Radio checked={ personaDependencia === 1 } onChange={ () => { handleChangePersonaDependencia(1) } } /> } label="Persona" />
                                                    </Grid>
                                                    <Grid item md={6} xs={6} >
                                                        <FormControlLabel value="Femenino" control={ <Radio checked={ personaDependencia === 2 } onChange={ () => { handleChangePersonaDependencia(2) } } /> } label="Dependencia" />     
                                                    </Grid>                                                            
                                                </Grid>
                                            </RadioGroup>
                                        </Grid>

                                    </Grid>

                                </FormControl>

                            </Grid>
                        }

                        {
                            nuevaParte
                            &&
                                <Grid item md={6} xs={12} sx={{ mt: 1 }} > 
                                    <Paper component="div" sx={{ p: '12px 0px', display: 'flex', alignItems: 'center', width: '100%' }}>                            
                                                    
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar persona' }} 
                                            placeholder="Buscar persona"     
                                            name="persona"
                                            value={ nombreCompleto }   
                                            onChange={ () => { } }             
                                            autoComplete="off"      
                                            disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                        /> 

                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalBuscarPersona }
                                            size='small'
                                            disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                        >
                                            <SearchIcon />
                                        </Button>         

                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalNuevaPersona }
                                            size='small' 
                                            disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                        >
                                            <PersonAddIcon />
                                        </Button>         

                                    </Paper>

                                </Grid>
                        }

                        {
                            tribunal && nuevaParte
                            &&            
                            <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                                
                                <Autocomplete                    
                                    disablePortal 
                                    fullWidth              
                                    value={ autocompleteValue }   
                                    options={ dependenciaArray }
                                    onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                    getOptionLabel={ (option) => option.descripcion}
                                    renderOption={ ( props, option ) => (
                                        <li { ...props } key={ option.idDependencia} >                         
                                            { option.descripcion }
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Dependencia" />}
                                    disabled={ personaDependencia === 1 || personaDependencia === 0 }                    
                                />

                            </Grid>  
                        }

                        {
                            nuevaParte
                            &&
                                <Grid item md={6} xs={12} sx={{ mt: 1 }} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>
                                    
                                        <Select
                                            labelId="select-tipoParte-label"
                                            id="select-tipoParte"                                            
                                            label="Tipo de parte"
                                            name="tipoParte"
                                            value={ tipoParte }
                                            onChange={ ( e ) => { setTipoParte( e.target.value ); } }
                                            disabled={ tribunal ? personaDependencia === 0 : false }  
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                            {
                                                    tipoParteArray
                                                    .filter( (element ) => { return element.idTipoParte !== 3 && element.idTipoParte !== 4 && element.idTipoParte !== 5 && element.idTipoParte !== 6; } )
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                    ))
                                            }
                                        </Select>

                                    </FormControl>
                                </Grid>                
                        }

                        <Grid item md={ tribunal ? 6 : 12 } xs={12} sx={{ mt: 1 }}  >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoIdentificacion-label">Tipo de identificación</InputLabel>
                            
                                <Select
                                    labelId="select-tipoIdentificacion-label"
                                    id="select-tipoIdentificacion"                                            
                                    label="Tipo de identificación"
                                    name="tipoIdentificacion"
                                    value={ tipoIdentificacion }
                                    onChange={ (e) => {setTipoIdentificacion(e.target.value);} }
                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }  
                                >
                                    <MenuItem key ={ 0 } value={0}>Selecciona una opción</MenuItem>                   
                                    {
                                        tipoIdentificacionArray.map((elem)=>(
                                        <MenuItem key={elem.idTipoIdentificacion} value={ elem.idTipoIdentificacion }>{ elem.descripcion }</MenuItem>
                                    ))
                                }
                                </Select>

                            </FormControl>
                        </Grid> 

                        <Grid item md={12} xs={12} style={{ textAlign: 'right', marginTop: '0.5em' }} >
                            <Button 
                                variant="contained"                    
                                startIcon={ <AddIcon /> }
                                disabled={ 
                                    tribunal ?
                                        (
                                            personaDependencia === 0 ||
                                            ( personaDependencia === 1 && idPersona === 0 ) ||
                                            ( personaDependencia === 2 && dependencia === 0 ) || 
                                            tipoParte === 0 || (tipoIdentificacion === 0 && personaDependencia === 1)
                                        ) 
                                    :
                                        nuevaParte
                                        ?
                                            ( idPersona === 0 || tipoParte === 0 || tipoIdentificacion === 0 )
                                        :
                                            (
                                                parte === 0 ||                                               
                                                tipoIdentificacion === 0 
                                            )
                                }
                                onClick={ handleClicAgregarParte }
                            >
                                Agregar
                            </Button>
                        </Grid> 
                    </>
                )
            }

            <Grid item md={12} xs={12} >

                <TableContainer style={{ marginTop: '10px' }}>

                    <Table>

                        <TableHead>

                            <TableRow>
                                <TableCell style={{ width:'35%' , fontWeight:'bold' }}> DESCRIPCIÓN </TableCell>
                                <TableCell style={{ width:'20%' , fontWeight:'bold'}}> TIPO DE PARTE </TableCell> 
                                <TableCell style={{ width:'30%' , fontWeight:'bold'}}> TIPO DE IDENTIFICACIÓN </TableCell>                                
                                <TableCell style={{ width:'5%' , fontWeight:'bold'}}> NOTIFICADO </TableCell>
                                <TableCell style={{ width:'5%' , fontWeight:'bold'}}> ASISTIÓ </TableCell>

                                {
                                    edit
                                    ?
                                        <>
                                            <TableCell style={{ width:'5%' }}> </TableCell>
                                            <TableCell style={{ width:'5%' }}> </TableCell>
                                        </>
                                    :
                                        null
                                }

                            </TableRow>

                        </TableHead>

                        <TableBody>

                        {
                            (rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map( (row) => (

                                <TableRow key={ row.id } >
                                    <TableCell> { row.dependencia ? row.dependencia.descripcion : '' } { row.persona ? row.persona.nombreCompleto : '' } </TableCell>
                                    <TableCell> { row.tipoParte.descripcion } </TableCell>
                                    <TableCell> { row?.tipoIdentificacion?.descripcion ?? '' } </TableCell>
                                
                                    <TableCell 
                                        sx={{ textAlign: 'center' }} 
                                    >  
                                        {
                                            row?.notificado 
                                            ?                                                 
                                                <CheckIcon />                                                   
                                            : 
                                                null
                                        }
                                    </TableCell>                                
                                
                                    <TableCell sx={{ textAlign: 'center' }} >  

                                        {
                                            edit
                                            ?
                                                <Checkbox 
                                                    checked={ row.asistio }
                                                    onChange={ ( e ) => { handleChangeAsistio( e, row.id ); } }
                                                /> 
                                            :
                                            (   
                                                row.asistio
                                                ?
                                                    <CheckIcon />     
                                                :
                                                null
                                            )
                                        }

                                    </TableCell>

                                    {
                                        edit
                                        ?
                                            <>
                                                <TableCell> 
                                                    {
                                                        row?.persona?.idTipoPersona === 1
                                                        ?                                             
                                                            <IconButton 
                                                                color="primary"  
                                                                onClick={ () => { handleEditarTipoIdentificacion( row.id, row?.tipoIdentificacion?.idTipoIdentificacion ?? 0 ); } }
                                                            >
                                                                <EditIcon /> 
                                                            </IconButton>
                                                        : 
                                                        null
                                                    }
                                                </TableCell>

                                                <TableCell> 

                                                    <IconButton color="primary" onClick={ () => { handleClicEliminarParte( row.id ); } }>
                                                        <DeleteIcon /> 
                                                    </IconButton>

                                                </TableCell>
                                            </>
                                        :
                                        null
                                    }

                                </TableRow>
                            ))
                        }

                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[ ]}
                                    colSpan={7}
                                    count={rows.length}                                        
                                    rowsPerPage={rowsPerPage}
                                    page={page}                                                                                
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                    ActionsComponent={ TablePaginationActions }
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                        return '' + from + '-' + to + ' de ' + count
                                        }
                                    }
                                />
                            </TableRow>
                        </TableFooter>

                    </Table>

                </TableContainer>

            </Grid> 
            
            {   
                errors.error && ( 
                    <Grid item md={12} xs={12} >

                        <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                    </Grid>
                )
            }

            {
                edit
                &&            
                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                    <LoadingButton 
                        variant="contained" 
                        onClick={ GuardarPartes }
                        startIcon={ <SaveIcon /> }
                        loading={ loadingInformacion }
                        disabled={ rows.filter( ( element ) => { return element.tipoIdentificacion === null && element?.persona?.idTipoPersona === 1; } ).length !== 0 || rows.length === 0 }
                    > 
                        Guardar asistentes
                    </LoadingButton>

                </Grid>
            }

            <Dialog open={ open } maxWidth='xs' fullWidth={true} onClose={ () => {} } >
                
                <DialogTitle>
                    Tipo de identificación
                </DialogTitle>
                
                <DialogContent>
                                    
                    <Grid item md={12} xs={12} >    
                    
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="select-tipoIdentificacion-label">Tipo de identificación</InputLabel>
                        
                            <Select
                                labelId="select-tipoIdentificacion-label"
                                id="select-tipoIdentificacion"                                            
                                label="Tipo de identificación"
                                name="tipoIdentificacion"
                                value={ tipoIdentificacionEditar }
                                onChange={ (e) => { setTipoIdentificacionEditar( e.target.value ); } }                            
                            >
                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                {
                                    tipoIdentificacionEditarArray
                                    .map( ( elem ) => (
                                        <MenuItem key={elem.idTipoIdentificacion} value={ elem.idTipoIdentificacion }>{ elem.descripcion }</MenuItem>
                                    ))
                                }
                            </Select>

                        </FormControl>

                    </Grid>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }> Cancelar </Button>
                    <Button onClick={ handleGuardarTipoIdentificacion } variant='contained' disabled={ tipoIdentificacionEditar === 0 }> Guardar </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ openConfirmacionEliminarParte } onClose={ () => {} } >       
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacionEliminarParte }> Cancelar </Button>
                    <Button onClick={ eliminarParte } variant='contained' > Aceptar </Button>
                </DialogActions>
            </Dialog>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona }
                seleccionarPersona={ seleccionarPersona } />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona }
                seleccionarPersona={ seleccionarPersona } />
        </>
    )
}
