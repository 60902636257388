import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/lab';

import DateAdapter from '@mui/lab/AdapterMoment';

import moment from 'moment';

import 'moment/locale/es-mx';

import { types } from '../../types/types';

import '../../css/Calendar.css';

import { ObtenerTiposAudiencias } from '../../actions/admin/catalogs/tipoAudienciaActions';
import { BuscarUsuarioJuez, BuscarUsuarioSecretario } from '../../actions/admin/users/usuarioAction';

export const ModalAudienciaDetalle = ({ openModal, handleCloseModal, edit, enAudiencia }) => {

    const { juzgado } = useSelector(state => state.auth);

    const FechaDefault = new Date();
    const HoraInicioDefault = FechaDefault.getFullYear() + '-' + ( ( '00' +  ( FechaDefault.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + FechaDefault.getDate() ).slice(-2) + ' ' + FechaDefault.getHours() +  ':00';  
    const HoraFinDefault = FechaDefault.getFullYear() + '-' + ( ( '00' +  ( FechaDefault.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + FechaDefault.getDate() ).slice(-2) + ' ' + ( FechaDefault.getHours() + 1) +  ':00';  

    const dispatch = useDispatch();        

    const { Selected, expediente: expedienteAudiencia } = useSelector( state => state.audiencias );

    const [juez, setJuez] = useState( 0 );
    const [juezArray, setJuezArray] = useState( [ ] );

    const [secretario, setSecretario] = useState( 0 );
    const [secretarioArray, setSecretarioArray] = useState( [ ] );

    const [tipoAudiencia, setTipoAudiencia] = useState( 0 );
    const [tipoAudienciaArray, setTipoAudienciaArray] = useState( [ ] );

    const [valueTimeInicio, setValueTimeInicio] = useState( new Date( HoraInicioDefault ) );
    const [valueTimeFin, setValueTimeFin] = useState( new Date( HoraFinDefault ) );  
  
    const [expediente, setExpediente] = useState( { success: false } );
        
    const Cerrar = () => {
    
        handleCloseModal();

        dispatch( { type: types.audienciaSelectedClear });
    }
 
    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTiposAudiencias( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoAudienciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioSecretario( ).then( response => {

                if( response ){

                    if( response.data ){
                        setSecretarioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {

        if( Selected )
        {      
            setTipoAudiencia( Selected.idTipoAudiencia ?? 0 )    ;
            setJuez( Selected.idJuez ?? 0 );          
            setValueTimeInicio( Selected.start );
            setValueTimeFin( Selected.end  );
            setExpediente( Selected.expediente ?? {} );     
            setSecretario( Selected.idSecretario ?? 0 );    
        }

    }, [ Selected, expedienteAudiencia, edit ]);

    return (

        <>

            <Dialog open={openModal} maxWidth='md' fullWidth={true}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                
                <DialogTitle>  
                    {      
                       Selected?.estatus                   
                    }                     
                </DialogTitle>

                <DialogContent id="DialogContentAgendar">
                    
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        
                        <Grid container spacing={4} sx={{ paddingTop: 2 }}>  

                            {
                                expediente.success === true 
                                &&
                                (
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Número de expediente:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.numeroExpediente }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Tipo de juicio:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.tipoJuicio }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                               
                                            {
                                                expediente.actor.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                
                                            </List>                            
                                    
                                        </Grid> 
                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                  
                                            </List>
                                        </Grid>    

                                    </>
                                )
                            }      

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Fecha de la audiencia:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { moment( FechaDefault ).format('DD-MM-yyyy') }
                                </Typography>
                            </Grid>    

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Hora de la audiencia:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { moment( valueTimeInicio ).format('HH:mm') + ' - ' + moment( valueTimeFin ).format('HH:mm') }
                                </Typography>
                            </Grid>    

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Tipo de audiencia:
                                </Typography>
                            </Grid>

                            <Grid item md={10} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { tipoAudienciaArray.find( (e) => e.idTipoAudiencia === tipoAudiencia )?.descripcion ?? '' }
                                </Typography>
                            </Grid>

                            {
                                juzgado && juez !== 0
                                &&
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Juez:
                                        </Typography>
                                    </Grid>
                            }

                            {
                                juzgado && juez !== 0
                                &&                                
                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { juezArray.find( (e) => e.id === juez )?.nombreCompleto ?? '' }
                                        </Typography>
                                    </Grid>                           

                            }

                            {
                                juzgado && secretario !== 0
                                &&
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Secretario :
                                    </Typography>
                                </Grid>
                            }

                            {       
                                juzgado && secretario !== 0
                                &&
                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { secretarioArray.find( (e) => e.id === secretario )?.nombreCompleto ?? '' }
                                    </Typography>
                                </Grid>      
                            }                            

                            {                 
                                Selected?.motivoCancelacion 
                                ?                                     
                                    <Grid item md={12} xs={12} sx={{ mt: 3, textAlign: 'center' }}>  
                                        <Typography sx={{ fontSize: 20 }}>
                                            Motivo de cancelación: { Selected?.motivoCancelacion } 
                                        </Typography>                                        
                                    </Grid>  
                                : 
                                    null                     
                            }                              

                        </Grid>
                            
                    </LocalizationProvider>

                </DialogContent>

                <DialogActions>

                    <Button 
                        onClick={ Cerrar } 
                        color="primary" 
                        variant="text"
                    >
                        Cerrar
                    </Button>                     
                </DialogActions>

            </Dialog>
        </>
    )
}
