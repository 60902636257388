import React, { useState } from 'react';

import { Button, ListItemText, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import useStyles from '../../../theme/useStyles';
import { ModalBuscarActividad } from '../../modals/ModalBuscarActividad';

export const ListItemSearchActivity = () => {

    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {   
        setOpenModal( false );
    };

    return (
        <>
            <ListItemText className={classes.listItemOptions} >

                <Button color="inherit" className={classes.buttonIcon} onClick={ () => {  setOpenModal( true ); } } >
                    <SearchIcon className={classes.PaddingIcon} />

                    <Typography color="inherit" variant="subtitle2" className={classes.ListTypography2} >
                        Buscar actividad
                    </Typography>
                                        
                </Button>

            </ListItemText>  

            <ModalBuscarActividad 
                openModal= { openModal } 
                handleCloseModal= { handleCloseModal }  
            />
        </>
    )

}
