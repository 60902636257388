import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import LaunchIcon from '@mui/icons-material/Launch';

import { BuscarActividad } from '../../actions/admin/actividades/actividadActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';

export const ModalBuscarActividad = ({ openModal, handleCloseModal }) => {

    const { administrador, coordinador } = useSelector( state => state.auth );

    const history = useHistory();

    const [loading, setLoading] = useState( false );

    const [loadingSearch, setLoadingSearch] = useState( false );

    const [errors, setErrors] = useState({  });

    const [noSeguimiento, setNoSeguimiento] = useState( '' );

    const [rows, setRows] = useState( [] );
    
    const [buscarPor, setBuscarPor] = useState( 1 );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const handleBuscarActividad = async () => {
        
        setErrors( { } );
        
        if( noSeguimiento === '' ){

            var legend = "";
            if(buscarPor === 1){
                legend =  "Debe escribir el Número de Seguimiento";     
            }
            else if( buscarPor === 2 ){
                legend = "Debe escribir el Número de Expediente";     
            }
            else if( buscarPor === 3 ){
                legend = "Debe escribir el Número de Exhorto Recibido";     
            }
            else if( buscarPor === 4 ){
                legend = "Debe escribir el Folio de Búzon Electrónico" ;
            }            

            let errores = {};  
                errores.noSeguimiento = legend;
                errores.type = 'warning';
        
            setErrors( errores );

            setLoadingSearch( false );

            return false;
        }
        else if( buscarPor === 2 || buscarPor === 3|| buscarPor === 4){   
            
            if( autoridad === 0 && ( administrador || coordinador ) ){

                let errores = {};  
                errores.autoridad = "Seleccione una autoridad";
                errores.type = 'warning';
        
                setErrors( errores );

                setLoadingSearch( false );

                return false;            
            }
        }

        setLoading( true );

        const params = {
            numeroSeguimiento : noSeguimiento.trim(),
            idTipoBusqueda: buscarPor,  
            idAutoridad: autoridad,
        }

        await BuscarActividad( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {
            
                        setLoading( false );
            
                        setLoadingSearch( true );                     
                        setRows( response.data );                          
            
                    }, 1500);

                }
            }

        });
        
    }

    const cerrarModal = () => {

        setErrors( { } );

        setRows( [ ] );
        setBuscarPor( 1 );
        setNoSeguimiento( '' );
        setAutoridad( 0 );

        setLoading( false );
        
        setLoadingSearch( false );

        handleCloseModal();
    }

    const NavegarProceso = ( path ) => {

        history.push( path );

    }

    useEffect(() => {
        
        if( openModal ){

            setRows( [ ] ); 

        }        

    }, [ openModal ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (      

        <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
            <DialogTitle>Buscar actividad</DialogTitle>
        
            <DialogContent>

                <Grid container spacing={4} >

                    <Grid item md={12} xs={12} >
                        <FormControl component="fieldset">                         
                            <RadioGroup row
                                aria-label="tipoOficio"
                                defaultValue="expediente"
                                name="radio-buttons-group"  
                            >
                                <FormControlLabel value={1} control={ <Radio checked={ buscarPor === 1 } onChange={ () => { setBuscarPor( 1 ); setAutoridad(0); } } />} label="Número de Seguimiento" />
                                <FormControlLabel value={2} control={ <Radio checked={ buscarPor === 2 } onChange={ () => { setBuscarPor( 2 ); } } />} label="Número de Expediente" />
                                <FormControlLabel value={3} control={ <Radio checked={ buscarPor === 3 } onChange={ () => { setBuscarPor( 3 ); } } />} label="Número de Exhorto recibido" />
                                <FormControlLabel value={3} control={ <Radio checked={ buscarPor === 4 } onChange={ () => { setBuscarPor( 4 ); } } />} label="Folio de Búzon Electrónico" />
                               
                            </RadioGroup>
                        </FormControl>
                    </Grid>                   

                    <Grid item md={ ( administrador || coordinador ) ? 6 : 8 } xs={12} > 

                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }} 
                                inputProps={{ 'aria-label': 'Número de seguimiento' }} 
                                placeholder={
                                    buscarPor === 1
                                    ? "Número de Seguimiento"     
                                    : buscarPor === 2
                                    ? "Número de Expediente"     
                                    : buscarPor === 3
                                    ? "Número de Exhorto Recibido"     
                                    : buscarPor === 4
                                    ? "Folio de Búzon Electrónico"     
                                    : ''
                                }
                                name="noSeguimiento"
                                value={ noSeguimiento }   
                                onChange={ ( e ) => { setNoSeguimiento( e.target.value.toUpperCase() ); } }             
                                autoComplete="off"        
                            />             

                        </Paper>

                        {   
                            errors.noSeguimiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.noSeguimiento } </Alert> )
                        }

                    </Grid>

                    {
                        ( administrador || coordinador )
                        &&     
                        <Grid item md={6} xs={12}> 
                        
                            <FormControl fullWidth>
                                <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                            
                                <Select
                                    labelId="select-autoridad-label"
                                    id="select-autoridad"                                            
                                    label="Autoridad"
                                    name="autoridad"
                                    value={ autoridad }                                
                                    onChange={ ( e ) => setAutoridad( e.target.value ) }
                                    disabled={ buscarPor === 1 }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                    {
                                        coordinador
                                        ?
                                            autoridadArray 
                                            .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                            .map( ( elem ) => (
                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                            ))
                                        :
                                            autoridadArray.map( ( elem ) => (
                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                            ))
                                    }                          
                                </Select>

                            </FormControl>

                            {   
                                errors.autoridad && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridad } </Alert> )
                            }
                        
                        </Grid>
                    }

                    <Grid item md={  ( administrador || coordinador ) ? 12 : 4 } xs={12} textAlign={ ( administrador || coordinador ) ? 'end' : 'start'}> 
                   
                        <LoadingButton                           
                            variant='contained'
                            startIcon={ <SearchIcon /> } 
                            onClick={ handleBuscarActividad }
                            loading={ loading }
                        >
                            Buscar
                        </LoadingButton>     

                    </Grid>

                    {
                        loadingSearch
                        &&
                    
                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width: '5%', fontWeight: 'bold' }}>NO. SEGUIMIENTO</TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}>USUARIO ASIGNADO</TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}>PROCESO (MÓDULO)</TableCell>                                            
                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>ACTIVIDAD</TableCell>                                            
                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>FECHA</TableCell>     
                                            <TableCell style={{ width: '1%', fontWeight: 'bold' }}> </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {                                       
                                            rows.map( ( row ) => (

                                                <TableRow key={ row.id } >                                                                    
                                                
                                                    <TableCell sx={{ fontWeight: 'bold' }}> { row.numeroSeguimiento } </TableCell>
                                                   
                                                    <TableCell> { row.usuario } </TableCell>

                                                    <TableCell> 
                                                        <strong style={{ fontSize: 15 }}>{  row.proceso } </strong> <br />
                                                        <small style={{ fontSize: 13 }}> { row.tipoTramite && " (" + row.tipoTramite + ")" } </small>
                                                    </TableCell>

                                                    <TableCell> { row.actividad } </TableCell>

                                                    <TableCell> { row.fecha } </TableCell>

                                                    <TableCell sx={{ textAlign: 'center' }}> 

                                                        {
                                                            row.idEstatus === 47
                                                            ?
                                                                <Chip 
                                                                    label={ row.estatus } 
                                                                    color='primary'
                                                                    size='small'
                                                                />
                                                            :
                                                                row.usuarioActual
                                                                ?
                                                                    <IconButton 
                                                                        onClick={ () => { NavegarProceso( row.path ); } }                                     
                                                                    >
                                                                        <LaunchIcon />
                                                                    </IconButton>
                                                                :
                                                                    null
                                                        }

                                                    </TableCell>

                                                </TableRow>
                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        No se encontraron resultados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                </Table>
                                
                            </TableContainer>

                        </Grid>     
                    
                    }

                </Grid>

            </DialogContent>

            <DialogActions>
                <Button variant="text" color="primary" onClick={ cerrarModal }>Cancelar</Button>
            </DialogActions>

        </Dialog>

    )
}
