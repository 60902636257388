import React from 'react';

import { Box } from '@mui/material';

import AppBarMenu from '../ui/AppBarMenu';

import { CalendarScreen } from './CalendarScreen';

export const AgendarAudienciaScreen = () => {
    return (
        <>    
            <AppBarMenu />

            <Box sx={{ mt: 11 }} >

                <CalendarScreen edit={ true } />
                
            </Box>

        </>
    )
}
