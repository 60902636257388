import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,  TextField,  Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SourceIcon from '@mui/icons-material/Source';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';

import { ObtenerDatos } from '../../actions/librosElectronicos/libroIndiceAlfabeticoActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';

export const LibroIndiceAlfabeticoScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );

    const [rows, setRows] = useState( [ ] );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 10 );

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [expediente, setExpediente] = useState( 0 );
    const [openDetalleExpediente, setOpenDetalleExpediente] = useState( false );

    const [numeroReferencia, setnumeroReferencia] = useState( '' );  
    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );  

    const [filtroAplicado, setFiltroAplicado] = useState( false );  

    const handleClearFiltro = () => {

        setFiltroAplicado( false );         
      
        setnumeroReferencia( '' );
        setAutoridad( 0 );

        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();
    }

    const handleBuscar = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );       
        setRecordsCount( 0 );         
        resetPage();
        
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setRows( [ ] );

            await ObtenerDatos( { page, rowsPerPage } ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){
                            
                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( !filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado ])    

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setRows( [ ] );

            await ObtenerDatos( { page, rowsPerPage, idAutoridad: autoridad, numeroReferencia } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoading( false );
                                setLoadingSearch( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, autoridad, numeroReferencia ])   

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '7em' }}>  
                
                <Container maxWidth="lg" component={ Paper }>

                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >

                        <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
                            
                            <Grid item md={ ( administrador || coordinador ) ? 12 : 5 } xs={12} >
                            
                                <Typography variant="h4" >
                                    Libro de índice alfabético
                                </Typography>

                            </Grid>

                            {     
                                ( administrador || coordinador )
                                && 
                                <Grid item md={4} xs={12}> 
                                                    
                                    <FormControl fullWidth>
                                        <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                    
                                            <Select
                                                labelId="select-autoridad-label"
                                                id="select-autoridad"                                            
                                                label="Autoridad"
                                                name="autoridad"
                                                value={ autoridad }                                
                                                onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                disabled={ filtroAplicado }  
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                {
                                                    coordinador
                                                    ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    :
                                                        autoridadArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                    ))
                                                }                          
                                            </Select>
        
                                        </FormControl>
                                                                
                                </Grid>
                            }   

                            <Grid item md={4} xs={12} >
                                <TextField
                                    fullWidth
                                    label="Nombre(s) / Apellido(s) del actor"
                                    name="nombreActor"
                                    variant="outlined" 
                                    autoComplete='off'           
                                    InputLabelProps={{
                                        shrink: true
                                    }}                               
                                    value={ numeroReferencia }
                                    onChange={ ( e ) => { setnumeroReferencia( e.target.value ); } }    
                                    disabled={ filtroAplicado }                            
                                />
                            </Grid>

                            <Grid item md={3} xs={12} >

                                <LoadingButton 
                                    type="button" 
                                    fullWidth 
                                    variant="contained"
                                    startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                    onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                    loading={ loadingSearch }
                                    disabled={ 
                                        ( administrador || coordinador )
                                        ? 
                                            autoridad === 0 
                                        :
                                        numeroReferencia === '' ||
                                        numeroReferencia?.length < 3
                                    }
                                > 
                                    { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                </LoadingButton> 

                            </Grid> 

                        </Grid>

                    </Toolbar>

                    <TableContainer>

                        <Table>

                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> AÑO </TableCell>
                                    <TableCell style={{ width:'15%', fontWeight: 'bold' }}> EXPEDIENTE</TableCell>
                                    <TableCell style={{ width:'25%', fontWeight: 'bold' }}> JUICIO </TableCell>
                                    <TableCell style={{ width:'25%', fontWeight: 'bold' }}> ACTOR </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row ) => (

                                        <TableRow key={ row.idLibroIndiceAlfabetico } >

                                            <TableCell> { row?.anio } </TableCell>

                                            <TableCell>                                  

                                                <Button
                                                    variant='contained'
                                                    color='inherit'
                                                    startIcon={ <SourceIcon /> }
                                                    size='small'
                                                    onClick={ () => { setExpediente( row?.expediente?.idExpediente ); setOpenDetalleExpediente( true ); } }
                                                >
                                                    { row?.expediente?.numeroExpediente ?? '' }  
                                                </Button>
                                                
                                            </TableCell>

                                            <TableCell>                                             
                                                { row?.expediente?.tipoJuicio?.descripcion ?? '' }  
                                            </TableCell>

                                            <TableCell> { row?.actor } </TableCell>


                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        null
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron registros'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }

                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell> 

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={ 10 }
                                        count={ recordsCount }                                        
                                        rowsPerPage={rowsPerPage}
                                        page={ page }                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>                       

                </Container>
            </Box>

            <ModalDetalleExpediente 
                open={ openDetalleExpediente }    
                setOpen={ setOpenDetalleExpediente }
                idExpediente={ expediente }
            />

        </>
    )
}
