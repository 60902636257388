import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import LoginIcon from '@mui/icons-material/Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useForm } from '../../hooks/useForm';

import useStyles from '../../theme/useStyles';

import { Login } from '../../actions/auth/authActions';

export const LoginScreen = () => {    

    const classes = useStyles();

    const dispatch = useDispatch();

    const [{ open, message }, setAlert] = useState({
        open: false,
        message: '',
    });

    const [loading, setLoading] = useState( false );
    
    const [viewContrasena, setViewContrasena] = useState( false );

    const [ formValues, handleInputChange ] = useForm( {
        correoElectronico: '',
        contrasena: ''
    } );

    const { correoElectronico, contrasena} = formValues;

    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const handleLogin = (e) => {
        e.preventDefault();  

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);

        setLoading( true );

        if(!correoElectronico){    
            setAlert({
                open: true,
                message: 'Debe escribir un correo electrónico',
            });
            setLoading( false );
        }        
        else if(!validEmail){            
            setAlert({
                open: true,
                message: 'El correo electrónico no tiene el formato correcto',
            });
            setLoading( false );
        }
        else if(!contrasena){
            setAlert({
                open: true,
                message: 'Debe escribir la contraseña',
            });
            setLoading( false );
        }
        else{   

            setTimeout(() => {
            
                dispatch( Login( formValues, setAlert, setLoading ) ) ;              

            }, 1200);
            
        }        
    }    

    return (  
        <>            
            <Snackbar open={ open } anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={ 2500 } onClose={ () => setAlert({ open: false, message }) }>
                <Alert onClose={ () => setAlert({ open: false, message }) } variant="filled" severity="error" sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Container component="main" maxWidth="xs">   

                <Box className={classes.formLogin}>
                   
                    <Typography component="h1" variant="h3">
                        {/* SAJI */}

                        <img style={{ width: 290  }}
                            src={ process.env.PUBLIC_URL + '/logos/SAJI-H.svg'}
                            alt='logo_saji'
                        />

                    </Typography>

                    <form>
                    
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="h6" style={{ textAlign: 'center'}}>
                                Inicio de sesión
                            </Typography>
                            
                            <Typography variant="subtitle2" sx={{ mt: 1 }} className={classes.subtitle}>                    
                                Ingresa con tu correo electrónico y contraseña.
                            </Typography>                      

                            <TextField
                                margin="normal"                      
                                fullWidth
                                label="Correo Electrónico"
                                name="correoElectronico"
                                type="email"
                                autoComplete="off"
                                value={ correoElectronico }
                                onChange={ handleInputChange }
                            />

                            <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                                <InputLabel>Contraseña</InputLabel>
                                <OutlinedInput
                                    label="Contraseña"
                                    name="contrasena"
                                    type={ viewContrasena ? 'text' : 'password'}
                                    value={ contrasena }                                
                                    onChange={ handleInputChange }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                {
                                                    viewContrasena
                                                    ?
                                                    <VisibilityOffIcon />
                                                    :
                                                    <VisibilityIcon />
                                                }                                                    
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>         
                    
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={ handleLogin }
                                sx={{ mt: 3, mb: 2 }}
                                startIcon={ <LoginIcon />}
                                loading={ loading }
                            >
                                Ingresar
                            </LoadingButton>

                            <Grid container className={classes.containerGrid} >
                                <Grid item xs>
                                    <Link to="/Auth/Forgot" style={{ textDecoration: 'none' }} >                                   
                                        <Button style={{ color: '#626c76', textTransform: 'none' }} > 
                                            ¿Olvidaste tu contraseña?, Ingresa aquí.
                                        </Button>                                  
                                    </Link>
                                </Grid>              
                            </Grid>
                        </Box>

                    </form>

                </Box>

            </Container>
        </>
    );
}
