import React, { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, Dialog,  DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { ExhortoLibradoDetalle } from '../../actions/tribunal/exhortoLibradoActions';

import moment from 'moment/moment';

export const ModalDetalleExhortoLibrado = ( { open, setOpen, idExhortoLibrado } ) => {

    const [loading, setLoading] = useState( false );
    const [exhortoLibrado, setExhortoLibrado] = useState( { } );

    useEffect(() => {   
        
        async function Obtener(){
            
            setLoading( true );

            await ExhortoLibradoDetalle( idExhortoLibrado ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setExhortoLibrado( response.data );
                            setLoading( false );

                        }, 500);
                        
                    }

                }

            });
        }
        
        if( open ){
            Obtener();
        }

    }, [ open, idExhortoLibrado ])      

    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth >
              
                <DialogContent>

                    <Grid item md={12} xs={12} >
                        
                        {
                            loading
                            ?
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                </Box>
                            :
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Número de exhorto:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { exhortoLibrado?.numeroExhorto }
                                            </Typography>
                                        </Grid>
                                    </Grid>  

                                    {
                                        exhortoLibrado?.ponencia
                                        &&   
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item md={4} xs={4}>
                                                <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                    Ponencia:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={8} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { exhortoLibrado?.ponencia }
                                                </Typography>
                                            </Grid>
                                        </Grid>    
                                    }

                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha de envio:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { exhortoLibrado?.fechaEnvio ? moment(exhortoLibrado?.fechaEnvio  ).format('DD-MM-YY HH:mm a') : '' }
                                            </Typography>
                                        </Grid> 

                                    </Grid>   
                                    
                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha de diligenciación:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { exhortoLibrado?.fechaDiligenciacion ? moment(exhortoLibrado?.fechaDiligenciacion  ).format('DD-MM-YY HH:mm a') : '' }
                                            </Typography>
                                        </Grid> 

                                    </Grid>   
                                    
                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha de devolucion:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { exhortoLibrado?.fechaDevolucion ? moment(exhortoLibrado?.fechaDevolucion  ).format('DD-MM-YY HH:mm a') : '' }
                                            </Typography>
                                        </Grid> 

                                    </Grid>   

                                    {
                                        exhortoLibrado?.exhortoFirmado
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Exhorto firmado:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe exhorto librado'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={  exhortoLibrado?.exhortoFirmado }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                </Box>
                        }
                          
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setExhortoLibrado({ }); } } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
