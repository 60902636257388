import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalNuevaPersona } from '../../../modals/ModalNuevaPersona';

import { ModalBuscarPersona } from '../../../modals/ModalBuscarPersona';

import { TablePaginationActions } from '../../../../helpers/TablePaginationActions';
import { usePages } from '../../../../hooks/usePages';

import { ObtenerTipoParte } from '../../../../actions/admin/catalogs/tipoparteActions';
import { ObtenerDependencias } from '../../../../actions/admin/catalogs/dependenciaActions';

import { types } from '../../../../types/types';

import { AgregarPartesIrregular, ObtenerPartes } from '../../../../actions/recepcion/irregularActions';

export const ModalEditarPartes = ({ Id, open, setOpen }) => {

    const dispatch = useDispatch();

    const { tribunal } = useSelector( state => state.auth );

    const { irregular } = useSelector( state => state.acuerdo );
    
    const [idReferencia, setReferencia] = useState( 0 );
    
    const [tipoParte, setTipoParte] = useState( 0 );
    const [tipoParteArray, setTipoParteArray] = useState( [ ] );

    const [personaDependencia, setPersonaDependencia] = useState( 0 );
   
    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [errors, setErrors] = useState({ });
    
    const [loading, setLoading] = useState( true );

    const [loadingNext, setLoadingNext] = useState( false );

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const { idPersona , nombreCompleto } = persona;

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPersona( persona );

        }

    }   

    const handleChangePersonaDependencia = ( value ) => {
    
        setPersonaDependencia( value );
        setPersona( { idPersona: 0, nombreCompleto: '' } );
        setAutocompleteValue( null );
    }

    const handleClicAgregarParte = () => {

        const tipoParteData = tipoParteArray.find( ( element ) => { return element.idTipoParte === tipoParte });
        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

        if( idPersona !== 0 ){

            const personaValidate =  rows.find( ( element ) => { return element.idPersona === persona.idPersona });

            if( !personaValidate ){
                const data = {
                    id: parseInt( id ),
                    idPersona : persona.idPersona,
                    persona: persona,
                    idDependencia: null,
                    dependencia: null,
                    tipoParte: tipoParteData
                };
        
                setRows( [ ...rows, data ] );
            }
            else{
                let errores = {};

                errores.error = 'La persona ya se encuentra agregada';
            
                setErrors( errores );  
            }  
        }
        else if ( dependencia !== 0 ){

            const dependenciaValidate =  rows.find( ( element ) => { return element.idDependencia === dependencia });

            if( !dependenciaValidate ){

                var dependenciaData = dependenciaArray.find( ( element ) => { return element.idDependencia === dependencia  });

                const data = {
                    id: parseInt( id ),
                    idPersona: null,
                    persona: null,
                    idDependencia: dependencia,
                    dependencia: dependenciaData,
                    tipoParte: tipoParteData
                };
        
                setRows( [ ...rows, data ] );
            }
            else{
                let errores = {};

                errores.error = 'La dependencia ya se encuentra agregada';
            
                setErrors( errores ); 
            }            
        }

        setPersonaDependencia( 0 );  
        setPersona( { idPersona: 0, nombreCompleto: '' } );      
        setAutocompleteValue( null );
        setDependencia( 0 );        
        setTipoParte( 0 );
    }

    const handleClicEliminarParte = ( Id ) => {

        handleOpenConfirmacion();

        setReferencia( Id );
        
    }

    const eliminarParte = () => {

        var newArray = rows.filter(function( element ){ 
            return element.id !== idReferencia;
        });

        setRows( newArray );        

        handleCloseConfirmacion();
    }

    const validarInformacion = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );
        let arrayPartes = [];

        rows.forEach(element => {
            
            const parte = {
                idTipoParte : element.tipoParte.idTipoParte,
                idPersona : element.persona ? element.persona.idPersona : null,
                idDependencia: element.dependencia ? element.dependencia.idDependencia : null

            };       
            
            arrayPartes.push( parte );
           
        });       

        const params = {

            idIrregular: Id,
            partes : arrayPartes

        };

        await AgregarPartesIrregular( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    let arrayActores = [];
                    let arrayDemandados = [];

                    rows.forEach(element => {   
                        if( element.tipoParte.idTipoParte === 1 ){
                            if( element.persona ){
                                arrayActores.push( element.persona.nombreCompleto );
                            }
                            else if( element.dependencia ){
                                arrayActores.push( element.dependencia.descripcion );
                            }                            
                        } 
                        else if( element.tipoParte.idTipoParte === 2 ){
                            if( element.persona ){
                                arrayDemandados.push( element.persona.nombreCompleto );
                            }
                            else if( element.dependencia ){
                                arrayDemandados.push( element.dependencia.descripcion );
                            }                            
                        } 
                    });       

                    dispatch({
                        type: types.actualizarDetalleAcuerdo,                                
                        payload: {  
                            irregular: {
                                ...irregular,
                                actor: arrayActores,
                                demandado: arrayDemandados
                            } 
                        }
                    }); 
            
                    setTimeout(() => {
                                    
                        setLoadingNext( false );

                        setOpen( false );
            
                    }, 500);  

                }

            }

        });
        
    }

    const validateFiels = () => {
        let valid = true;
        let errores = {};
        
        if( rows.length === 0 ){
            valid = false;
            errores.error = 'Debes agregar por lo menos una parte';
        }       
        
        setErrors( errores );        

        return valid;
    } 

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoParte().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoParteArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response.status === 200){
                    setDependenciaArray( response.data ); 
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {

        async function Obtener(){

            setLoading( true );

            await ObtenerPartes( Id ).then( response => {

                if( response ){

                    if( response.status === 200){

                        if( response.data ){
                            
                            setTimeout(() => {
                                
                                setLoading( false );

                                setRows( response.data );

                            }, 500);

                        }                        

                    }

                }


            });

        }
      
        if( open ){

            Obtener();

        }

    }, [ Id, open ])    

    return (
        <>    
            <Dialog open={ open } onClose={ () => {} } maxWidth='md' >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent> 

                    <Grid container spacing={3}>
          
                        {
                        tribunal
                        &&               
                            <Grid item md={12} xs={12} >

                                <FormControl fullWidth>
                                                                    
                                    <Grid container spacing={3}>
                                        
                                        <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                            <FormLabel sx={{ color: 'black' }} >Selecciona una opción para agregar: </FormLabel>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={12} >
                                            <RadioGroup row >
                                                <Grid container >
                                                    <Grid item md={6} xs={6} >
                                                        <FormControlLabel value="Masculino" control={ <Radio checked={ personaDependencia === 1 } onChange={ () => { handleChangePersonaDependencia(1) } } /> } label="Persona" />
                                                    </Grid>
                                                    <Grid item md={6} xs={6} >
                                                        <FormControlLabel value="Femenino" control={ <Radio checked={ personaDependencia === 2 } onChange={ () => { handleChangePersonaDependencia(2) } } /> } label="Dependencia" />     
                                                    </Grid>                                                            
                                                </Grid>
                                            </RadioGroup>
                                        </Grid>

                                    </Grid>

                                </FormControl>

                            </Grid>
                        }

                        <Grid item md={6} xs={12} sx={{ mt: 1 }} > 
                            <Paper component="div" sx={{ p: '12px 0px', display: 'flex', alignItems: 'center', width: '100%' }}>                            
                                            
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar persona' }} 
                                    placeholder="Buscar persona"     
                                    name="persona"
                                    value={ nombreCompleto }   
                                    onChange={ () => { } }             
                                    autoComplete="off"      
                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                /> 

                                <Button 
                                    sx={{ borderRadius: 10, mr: 1 }} 
                                    variant='contained' 
                                    onClick={ handleOpenModalBuscarPersona }
                                    size='small'
                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                >
                                    <SearchIcon />
                                </Button>         

                                <Button 
                                    sx={{ borderRadius: 10, mr: 1 }} 
                                    variant='contained' 
                                    onClick={ handleOpenModalNuevaPersona }
                                    size='small' 
                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                >
                                    <PersonAddIcon />
                                </Button>         

                            </Paper>

                        </Grid>

                        {
                            tribunal
                            &&            
                            <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                                
                                <Autocomplete                    
                                    disablePortal 
                                    fullWidth              
                                    value={ autocompleteValue }   
                                    options={ dependenciaArray }
                                    onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                    getOptionLabel={ (option) => option.descripcion}
                                    renderOption={ ( props, option ) => (
                                        <li { ...props } key={ option.idDependencia} >                         
                                            { option.descripcion }
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Dependencia" />}
                                    disabled={ personaDependencia === 1 || personaDependencia === 0 }                    
                                />

                            </Grid>  
                        }

                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>
                            
                                <Select
                                    labelId="select-tipoParte-label"
                                    id="select-tipoParte"                                            
                                    label="Tipo de parte"
                                    name="tipoParte"
                                    value={ tipoParte }
                                    onChange={ ( e ) => { setTipoParte( e.target.value ); } }
                                    disabled={ tribunal ? personaDependencia === 0 : false }  
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        
                                        tipoParteArray
                                        .filter( (element ) => { return element.idTipoParte === 1 || element.idTipoParte === 2 } )
                                        .map( ( element ) => (
                                            <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                        ))
                                    }
                                </Select>

                            </FormControl>
                        </Grid>                

                        <Grid item md={6} xs={12} style={{ marginTop: '0.5em' }} >
                            <Button 
                                variant="contained"                    
                                startIcon={ <AddIcon /> }
                                disabled={ 
                                    tribunal ?
                                    (
                                        personaDependencia === 0 ||
                                        ( personaDependencia === 1 && idPersona === 0 ) ||
                                        ( personaDependencia === 2 && dependencia === 0 ) || 
                                        tipoParte === 0
                                    ) 
                                    :
                                    (
                                        idPersona === 0 ||
                                        tipoParte === 0
                                    )
                                }
                                onClick={ handleClicAgregarParte }
                            >
                                Agregar
                            </Button>
                        </Grid>    

                        <Grid item md={12} xs={12} >

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'60%' }}>DESCRIPCIÓN</TableCell>
                                            <TableCell style={{ width:'30%' }}>TIPO DE PARTE</TableCell>                                
                                            <TableCell style={{ width:'10%' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                    {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map( (row) => (

                                        <TableRow key={ row.id } >
                                            <TableCell> { row.dependencia ? row.dependencia.descripcion : '' } { row.persona ? row.persona.nombreCompleto : '' } </TableCell>
                                            <TableCell> { row.tipoParte.descripcion } </TableCell>
                                            <TableCell> 
                                                <IconButton color="primary" onClick={ () => { handleClicEliminarParte( row.id ); } }>
                                                    <DeleteIcon /> 
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        ))
                                    }

                                    {
                                        rows.length === 0 && 
                                        (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="center"
                                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                                >
                                                    {
                                                        loading
                                                        ?
                                                            'Cargando ....'
                                                        :
                                                            'No se encontraron resultados'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={3}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid> 

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >

                                    <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                                </Grid>
                            )
                        }
                    
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setRows( [ ] ); setErrors( { } ); } }> Cancelar </Button>
                    <LoadingButton onClick={ validarInformacion } variant='contained' loading={ loadingNext } > Guardar </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <Button onClick={ eliminarParte } variant='contained' > Aceptar </Button>
                </DialogActions>
            </Dialog>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />
        </>
    )
}
