import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl, InputLabel, MenuItem, Select, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination, Typography, Radio, RadioGroup, Alert, Autocomplete } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { usePages } from '../../hooks/usePages';

import { ObtenerEstados } from '../../actions/admin/catalogs/estadoActions';
import { ObtenerMunicipios } from '../../actions/admin/catalogs/municipioActions';
//import { ObtenerAsentamientos, ObtenerAsentamientosMunicipio, ObtenerCodigosPostales } from '../../actions/admin/catalogs/asentamientoActions';
import { ObtenerAsentamientosMunicipio } from '../../actions/admin/catalogs/asentamientoActions';
import { ObtenerTiposDomicilios } from '../../actions/admin/catalogs/tipoDomicilioActions';

import { DomicilioPersona } from '../../actions/comun/personaActions';
import { ObtenerDomicilioDependencia } from '../../actions/admin/catalogs/dependenciaActions';
import { AgregarNotificacionDomicilioDependencia, AgregarNotificacionDomicilioPersona } from '../../actions/notificaciones/notificacionActions';

export const ModalAgregarDomicilio = ( { openModal, handleCloseModal, persona, dependencia, SeleccionarDomicilio, domicilio, edit } ) => {

    const [rows, setRows] = useState( [] );

    const [radioSelected, setRadioSelected] = useState( 0 );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
   
    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );
    
    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );

    // const [codigoPostal, setCodigoPostal] = useState( 0 );
    // const [codigoPostalArray, setCodigoPostalArray] = useState( [] );

    const [asentamiento, setAsentamiento] = useState( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState( [] );
    const [autocompleteValue, setAutocompleteValue] = useState( null );
    
    const [tipoDomicilio, setTipoDomicilio] = useState( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState( [] );
    
    const [descripcion, setDescripcion] = useState( '' );
    const [numero, setNumero] = useState( '' );
    const [referencia, setReferencia] = useState( '' );    

    const [errors, setErrors] = useState({ });

    const [loading, setLoading] = useState(false);
    const [loadingGuardar, setLoadingGuardar] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [validateSelected, setValidateSelected] = useState(false);

    const [domicilioSelected, setDomicilioSelected] = useState(false);

    const handleChangeRadio = ( value, domicilio ) => {
        setRadioSelected( value );
        setValidateSelected( true );
        setDomicilioSelected( domicilio );
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( estado === 0 ){
            valid = false;
            errores.estado = 'Debes seleccionar un estado';
        }
        else if( municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }
        // else if( codigoPostal === 0 ){
        //     valid = false;
        //     errores.codigoPostal = 'Debes seleccionar un código postal';
        // }
        else if( asentamiento === 0 ){
            valid = false;
            errores.asentamiento = 'Debes seleccionar un asentamiento';
        }

        if( tipoDomicilio === 0 ){
            valid = false;
            errores.tipoDomicilio = 'Debes seleccionar un tipo de domicilio';
        }

        if( descripcion === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }   

        // if( numero === '' || numero === 0 ){
        //     valid = false;
        //     errores.numero = 'Debes escribir el número int./ext.';
        // }   
        
        setErrors( errores );
        return valid;
    }

    const ClearData = () => {
        
        setEstado(0);
        setMunicipio(0);
        //setCodigoPostal(0);
        setAsentamiento(0);
        setAutocompleteValue(null);
        
        setTipoDomicilio(0);
        setDescripcion('');
        setNumero('');         
        setReferencia('');       

        setAsentamientoArray([]);
        //setCodigoPostalArray([]);
        setMunicipioArray([]);
    }

    const GuardarDomicilio = async () =>{

        const valid = validateFiels();

        if( !valid ){

            return false;
        }

        setLoading( true );

        if( Object.keys( persona ).length !== 0 ){     
            
            const params = {

                descripcion: descripcion,                
                numero: numero === ''? 0 : numero ,
                referencia: referencia,
                idTipoDomicilio: tipoDomicilio,
                idAsentamiento: asentamiento,
                idPersona: persona.idPersona               
    
            }

            await AgregarNotificacionDomicilioPersona( params ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                const newArray = rows.map( ( element ) => {
                                    element.activo = false;
                                    return element;
                                });
                                                    
                                setRows( [ ...newArray, response.data] );
    
                                ClearData();
    
                                setLoading( false );
    
                            }, 1000);
                            
                        }
                    }
                    else{

                        const { message: msg } = response.data;

                        setTimeout(() => {
                            
                            setLoading( false );

                            setErrors( { message : msg ? msg: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                        }, 1000);
                    }                     

                }

            });              
        }
        else if( Object.keys( dependencia ).length !== 0 ){

            const params = {

                descripcion: descripcion,                
                numero: numero === ''? 0 : numero ,
                referencia: referencia,
                idTipoDomicilio: tipoDomicilio,
                idAsentamiento: asentamiento,
                idDependencia: dependencia.idDependencia               
    
            }

            await AgregarNotificacionDomicilioDependencia( params ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {

                                const newArray = rows.map( ( element ) => {
                                    element.activo = false;
                                    return element;
                                });
                                                    
                                setRows( [ ...newArray, response.data] );
    
                                ClearData();
    
                                setLoading( false );
    
                            }, 1000);
                            
                        }
                    }
                    else{

                        const { message: msg } = response.data;

                        setTimeout(() => {
                            
                            setLoading( false );

                            setErrors( { message : msg ? msg: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                        }, 1000);
                    }     

                }

            });   

        } 
        else{
            setLoading( false );
        }      

    }

    const handleCloseModalDomicilio = () => {

        handleCloseModal();
        
        setTimeout(() => {
            
            ClearData();
        
            setRadioSelected( 0 );

            setRows([]);

            setErrors( {} );
            
        }, 200);       

    }

    const handleGuardarDomicilio = () => {

        setLoadingGuardar( true );

        setTimeout(() => {
            
            SeleccionarDomicilio( radioSelected, domicilioSelected );

            handleCloseModalDomicilio();

            setLoadingGuardar( false );

        }, 1000);

    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerEstados().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setEstadoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);
    
    useEffect(() => {
        
        async function Obtener(){     

            setMunicipio( 0 );

            if( estado !== 0 ){       
                await ObtenerMunicipios( estado ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setMunicipioArray( response.data );   
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ estado ]);

    // useEffect(() => {
        
    //     async function Obtener(){     

    //         setCodigoPostal( 0 );

    //         if( municipio !== 0 ){       
    //             await ObtenerCodigosPostales( municipio ).then( response => {
    //                 if(response){
    //                     if( response.status === 200){
    //                         setCodigoPostalArray( response.data );
    //                     }
    //                 }
    //             });
    //         }

    //     }

    //     Obtener();

    // }, [ municipio ]);

    // useEffect(() => {
        
    //     async function Obtener(){     

    //         setAsentamiento( 0 );

    //         if( codigoPostal !== 0 ){       

    //             const params = {
    //                 'idMunicipio': municipio,
    //                 'codigoPostal' : codigoPostal
    //             };

    //             await ObtenerAsentamientos( params ).then( response => {
    //                 if(response){
    //                     if( response.status === 200){
    //                         setAsentamientoArray( response.data );
    //                     }
    //                 }
    //             });
    //         }

    //     }

    //     Obtener();

    // }, [ municipio, codigoPostal ]);

    useEffect(() => {
        
        async function Obtener(){     

            setAsentamiento( 0 );
            setAsentamientoArray( [] );
            setAutocompleteValue( null );

            if( municipio !== 0 ){   

                await ObtenerAsentamientosMunicipio( municipio ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setAsentamientoArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ municipio ]);

    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerTiposDomicilios().then( response => {
                if(response){
                    if( response.status === 200){
                        setTipoDomicilioArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    useEffect(() => {

        async function Obtener(){     

            setLoadingSearch( true );

            if( Object.keys( persona ).length !== 0 ){       

                await DomicilioPersona( persona.idPersona ).then( response => {

                    if( response ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRows( response.data );

                                setLoadingSearch( false );

                            }, 1200);
                            
                        }

                    }

                });              
            }
            else if( Object.keys( dependencia ).length !== 0 ){

                await ObtenerDomicilioDependencia( dependencia.idDependencia ).then( response => {

                    if( response ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRows( response.data );

                                setLoadingSearch( false );

                            }, 1200);

                        }

                    }

                });   

            }
            else{
                setTimeout(() => {
                    
                    setLoadingSearch( false );
                    
                }, 1000);                
            }

        }

        if( openModal ){
            
            Obtener();
        
        }               

    }, [ persona, dependencia, openModal ]);

    useEffect(() => {
      
        if( rows.length !== 0 ){
            setRadioSelected( domicilio );
            
            if( domicilio !== 0){
                setValidateSelected( true );
            }
        }

    }, [ rows, domicilio ])  

    return (
        <>
            <Dialog open={ openModal } maxWidth='lg' fullWidth={true}>

                {
                    edit && <DialogTitle>Seleccionar domicilio</DialogTitle>
                }                
            
                <DialogContent>
                    
                    <Grid container spacing={3} sx={{ mt: 1 }} >

                        {
                            edit
                            && 
                            <>
                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-estado-label">Estado</InputLabel>
                                    
                                        <Select
                                            labelId="select-estado-label"
                                            id="select-estado"                                            
                                            label="Estado"
                                            name="estado"
                                            value={ estado }
                                            onChange={ ( e ) => { setEstado( e.target.value ); } }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                estadoArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </MenuItem>
                                                ))
                                            }
                                        </Select>

                                    </FormControl>
                                    {   
                                        errors.estado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.estado } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-municipio-label">Municipio</InputLabel>
                                    
                                        <Select
                                            labelId="select-municipio-label"
                                            id="select-municipio"                                            
                                            label="Municipio"
                                            name="municipio"
                                            value={ municipio }
                                            onChange={ ( e ) => { setMunicipio( e.target.value ); } }
                                            disabled={ estado === 0 }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                municipioArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </MenuItem>
                                                ))
                                            }
                                        </Select>

                                    </FormControl>
                                    {   
                                        errors.municipio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.municipio } </Alert> )
                                    }
                                </Grid>

                                {/* <Grid item md={4} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>
                                    
                                        <Select
                                            labelId="select-codigoPostal-label"
                                            id="select-codigoPostal"                                            
                                            label="Código Postal"
                                            name="codigoPostal"
                                            value={ codigoPostal }
                                            onChange={ ( e ) => { setCodigoPostal( e.target.value ); } }
                                            disabled={ municipio === 0 }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                codigoPostalArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.codigoPostal } value={ elem.codigoPostal }> { elem.codigoPostal } </MenuItem>
                                                ))
                                            }
                                        </Select>

                                    </FormControl>
                                    {   
                                        errors.codigoPostal && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.codigoPostal } </Alert> )
                                    }
                                </Grid> */}

                                <Grid item md={8} xs={12} >                            
                                  
                                    <FormControl fullWidth>
                                    
                                        <Autocomplete       
                                            disabled={ municipio === 0 }         
                                            disablePortal 
                                            fullWidth              
                                            value={ autocompleteValue }   
                                            options={ asentamientoArray }
                                            onChange={ (event, value) => { setAsentamiento( value?.idAsentamiento ?? 0 ); setAutocompleteValue( value ); } }                        
                                            getOptionLabel={ (option) => option.asentamiento}
                                            renderOption={ ( props, option ) => (
                                                <li { ...props } key={ option.idAsentamiento} >                         
                                                    <strong>{ option.asentamiento }</strong>&nbsp;(C.P. { option.codigoPostal }) - { option.tipo }
                                                </li>
                                            )}
                                            renderInput={(params) => <TextField {...params} label="Asentamiento" placeholder='Seleccione una opción' InputLabelProps={{ shrink: true }} />}         
                                        />      

                                    </FormControl>
                                    {   
                                        errors.asentamiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.asentamiento } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={4} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-tipoDomicilio-label">Tipo Domicilio</InputLabel>
                                    
                                        <Select
                                            labelId="select-tipoDomicilio-label"
                                            id="select-tipoDomicilio"                                            
                                            label="Tipo Domicilio"
                                            name="tipoDomicilio"
                                            value={ tipoDomicilio }
                                            onChange={ ( e ) => { setTipoDomicilio( e.target.value ); } }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                tipoDomicilioArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.idTipoDomicilio } value={ elem.idTipoDomicilio }> { elem.descripcion } </MenuItem>
                                                ))
                                            }
                                        </Select>

                                    </FormControl>
                                    {   
                                        errors.tipoDomicilio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.tipoDomicilio } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={4} xs={12} >
                                    <TextField
                                        fullWidth
                                        label="Calle o vialidad"
                                        name="descripcion"
                                        variant="outlined"                           
                                        autoComplete='off'             
                                        value={ descripcion }                                
                                        onChange={ ( e ) => { setDescripcion( e.target.value ); } }
                                    />
                                    {   
                                        errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.descripcion } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={3} xs={12} >
                                    <TextField
                                        fullWidth
                                        label="Numero Int./Ext."
                                        name="numero"
                                        variant="outlined"   
                                        type="number"                                     
                                        autoComplete='off'
                                        value={ numero }                                
                                        onChange={ ( e ) => { setNumero( e.target.value ); } }
                                    />
                                    {   
                                        errors.numero && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.numero } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={5} xs={12} >
                                    <TextField
                                        fullWidth
                                        label="Referencia"
                                        name="referencia"
                                        variant="outlined"        
                                        multiline
                                        rows={4}                                        
                                        InputLabelProps={{
                                            shrink: true
                                        }}                               
                                        value={ referencia }                                
                                        onChange={ ( e ) => { setReferencia( e.target.value ); } }   
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} xs={12} >                    
                    
                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>                   
                                        
                                        <LoadingButton 
                                            type="button" 
                                            variant="contained" 
                                            startIcon={ <SaveIcon /> }
                                            onClick={ GuardarDomicilio }
                                            loading={ loading }
                                            disabled={ 
                                                asentamiento === 0 || descripcion === '' //|| numero === '' || numero === '0'
                                            }
                                        > 
                                            Agregar domicilio
                                        </LoadingButton> 

                                    </Grid>                    
                            
                                </Grid>

                                {   
                                    errors.message && ( 
                                        <Grid item md={12} xs={12} >
                                            <Alert color={ errors.type } severity={ errors.type } variant="filled" > 
                                                { errors.message } 
                                            </Alert> 
                                        </Grid>
                                    )
                                }

                            </>
                        }

                        <Grid item md={12} xs={12} >

                            {
                                edit
                                ?
                                    <Typography variant="h6"> Domicilio(s) registrado(s) </Typography>
                                :
                                    <Typography variant="h6"> Domicilio seleccionado </Typography>
                            }                            

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'10%' }}>ESTADO</TableCell>
                                            <TableCell style={{ width:'10%' }}>MUNICIPIO</TableCell>
                                            <TableCell style={{ width:'10%' }}>CÓDIGO POSTAL</TableCell>
                                            <TableCell style={{ width:'15%' }}>ASENTAMIENTO</TableCell>
                                            <TableCell style={{ width:'15%' }}>DESCRIPCIÓN</TableCell>
                                            <TableCell style={{ width:'10%' }}>NÚMERO</TableCell>
                                            <TableCell style={{ width:'15%' }}>REFERENCIA</TableCell>
                                            <TableCell style={{ width:'2%' }}>ACTIVO</TableCell>
                                            <TableCell style={{ width:'5%' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( (row) => (

                                                <TableRow key={ row.domicilio.idDomicilio }>
                                                    <TableCell> { row.domicilio.asentamiento.estado.descripcion } </TableCell>
                                                    <TableCell> { row.domicilio.asentamiento.municipio.descripcion } </TableCell>
                                                    <TableCell> { row.domicilio.asentamiento.codigoPostal } </TableCell>
                                                    <TableCell> { row.domicilio.asentamiento.descripcion } </TableCell>
                                                    <TableCell> { row.domicilio.descripcion } </TableCell>
                                                    <TableCell> { row.domicilio.numero } </TableCell>
                                                    <TableCell> { row.domicilio.referencia } </TableCell>

                                                    <TableCell> { row.activo && <CheckIcon /> } </TableCell>

                                                    <TableCell> 
                                                        <RadioGroup 
                                                            key={ row.domicilio.idDomicilio }
                                                            name="selectedRadio" 
                                                            value={ radioSelected }
                                                            onChange={ () => handleChangeRadio( row.domicilio.idDomicilio, row.domicilio ) }
                                                        >                                                        
                                                            <Radio value={ row.domicilio.idDomicilio } disabled={ !edit } />
                                                        </RadioGroup>
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={9}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        {
                                                            loadingSearch
                                                            ?
                                                                'Buscando ....'
                                                            :
                                                                'No se encontraron resultados'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={9}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }   
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>
                            
                            </TableContainer>

                        </Grid> 

                    </Grid>

                </DialogContent>

                <DialogActions>

                    <Button onClick={ handleCloseModalDomicilio }>Cancelar</Button>

                    {
                        edit
                        &&                    
                        <LoadingButton 
                            variant="contained" 
                            color="primary" 
                            startIcon={ <SaveIcon /> } 
                            disabled={ !validateSelected }
                            loading={ loadingGuardar }
                            onClick={ handleGuardarDomicilio } 
                        >
                            Guardar
                        </LoadingButton>
                    }

                </DialogActions>

            </Dialog>
        </>
    )
}
