import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography, Checkbox, FormControlLabel, FormLabel, RadioGroup  } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SearchIcon from '@mui/icons-material/Search';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { BuscarPersona } from '../../actions/comun/personaActions';

export const ModalBuscarPersona = ( { openModal, handleCloseModal, seleccionarPersona } ) => {

    const [rows, setRows] = useState( [] );
    const {juzgado, tribunal} = useSelector( state => state.auth );  
    const [curp, setCurp] = useState( '' );
    const [nombres, setNombres] = useState( '' );
    const [apellido1, setApellido1] = useState( '' );
    const [apellido2, setApellido2] = useState( '' );
    const [tipoPersona, setTipoPersona] = useState( 1 );
    const [razonSocial, setRazonSocial] = useState( '' );
    const [rfc, setRfc] = useState( '' );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages();

    const [loading, setLoading] = useState( false );
  
    const Buscar = async () => {

        setLoading( true );
        setRows( [ ] );
        resetPage();

        const params = {           
            nombres: nombres,
            apellido1: apellido1,
            apellido2: apellido2,
            curp: curp,
            rfc: rfc,
            razonSocial: razonSocial, 
            idTipoPersona: tribunal ? 1 : tipoPersona,  
        };

        await BuscarPersona( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {

                        setRows( response.data );
                        
                        setLoading( false );

                    }, 1200);

                }
                else{
                    setTimeout(() => {

                        setRows( [ ] );
                        
                        setLoading( false );

                    }, 1200);
                }

            }

        });
    }

    const handleSeleccionar = ( persona ) => {

        seleccionarPersona( persona );
        
        CerrarModal();

    }

    const CerrarModal = () => {

        handleCloseModal();

        setCurp( '' );
        setNombres( '' );
        setApellido1( '' );
        setApellido2( '' );
        resetPage();
        setRows( [ ] );
        setTipoPersona(1);
        setRazonSocial( '' );
        setRfc( '' );

    }
    const handleChangeTipoPersona = (valor) => {
        
        setTipoPersona(valor);
        setNombres( '' );   
        setApellido1( '' );
        setApellido2( '' );
        setRazonSocial( '' );
        setRfc( '' );

    }

    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Buscar Persona</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}>    
                        {
                            juzgado && 
                            (
                                <>
                                    <Grid item md={4} xs={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                        <FormLabel sx={{ color: 'black' }} >Tipo de persona: </FormLabel>
                                    </Grid>
                                        
                                    <Grid item md={8} xs={10} >
                                        <RadioGroup row sx={{ textAlign: 'center' }} >
                                            <Grid container >
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Física" control={ <Checkbox checked={ tipoPersona === 1 } onChange={ () => {  handleChangeTipoPersona(1); setRows([]); } } /> } label="Física" />
                                                </Grid>
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Moral" control={ <Checkbox checked={ tipoPersona === 2 } onChange={ () => { handleChangeTipoPersona(2); setRows([]); } } /> } label="Moral" />     
                                                </Grid>                                                            
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </>
                            )
                        }

                        {
                            (
                                ( juzgado && tipoPersona === 1 ) ||
                                tribunal
                            ) 
                            && 
                            (                        
                                <>
                    
                                    <Grid item md={4} xs={12} >

                                        <TextField
                                            fullWidth
                                            label="Nombre(s)"
                                            name="nombres"
                                            variant="outlined"   
                                            value={ nombres }          
                                            autoComplete="off"                  
                                            onChange={ ( e ) => { setNombres( e.target.value ); } }
                                        />

                                    </Grid>

                                    <Grid item md={4} xs={12} >

                                        <TextField
                                            fullWidth
                                            label="Apellido 1"
                                            name="apellido1"
                                            variant="outlined"          
                                            value={ apellido1 }            
                                            autoComplete="off"                
                                            onChange={ ( e ) => { setApellido1( e.target.value ); } }             
                                        />

                                    </Grid>

                                    <Grid item md={4} xs={12} >

                                        <TextField
                                            fullWidth
                                            label="Apellido 2"
                                            name="apellido2"
                                            variant="outlined"          
                                            value={ apellido2 }       
                                            autoComplete="off"                     
                                            onChange={ ( e ) => { setApellido2( e.target.value ); } }                             
                                        />

                                    </Grid>  

                                    <Grid item md={4} xs={12} >

                                        <TextField
                                            fullWidth
                                            label="CURP"
                                            name="curp"
                                            variant="outlined"
                                            value={ curp }            
                                            autoComplete="off"                
                                            onChange={ ( e ) => { setCurp( e.target.value ); } }
                                        />

                                    </Grid> 
                                </>
                            )
                        }

                        {
                            juzgado && tipoPersona === 2                    
                            && 
                            (
                                <>
                                    <Grid item md={12} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Razon social"
                                            name="razonSocial"
                                            variant="outlined"    
                                            value={ razonSocial }      
                                            autoComplete="off"                             
                                            onChange={ ( e ) => { setRazonSocial( e.target.value ); } }                       
                                        /> 
                                    </Grid>

                                    <Grid item md={6} xs={6} >
                                        <TextField
                                            fullWidth
                                            label="RFC"
                                            name="rfc"
                                            variant="outlined"     
                                            value={ rfc }        
                                            onChange={ ( e ) => { setRfc( e.target.value ); } }        
                                            inputProps={{ maxLength: 12 }}               
                                            autoComplete="off"                      
                                        />
                                    </Grid>
                                </>
                            )
                        }

                        <Grid item md={4} xs={12} style={{ textAlign: 'right', marginTop: '0.5em' }} >
                            
                            <LoadingButton  
                                variant="contained"     
                                loading={ loading }     
                                loadingPosition="start"
                                fullWidth                       
                                startIcon={ <SearchIcon /> }
                                onClick={ Buscar }
                                disabled={ ( nombres.trim() === ''|| nombres.length < 3 ) && 
                                    ( apellido1.trim() === '' || apellido1.length < 3 ) && 
                                    ( apellido2.trim() === '' || apellido2.length < 3 ) && 
                                    ( curp.trim() === ''|| curp.length < 5 ) &&
                                    ( rfc.trim() === '' || rfc.length < 3 ) && 
                                    ( razonSocial.trim() === '' || razonSocial.length < 3 )
                                }
                            >
                                Buscar
                            </LoadingButton >

                        </Grid>           

                        <Grid item md={12} xs={12} sx={{ mt: 2 }} >

                            <Typography variant="h6"> Resultado de la busqueda </Typography>

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>
                            
                                    {
                                        tipoPersona === 1                  
                                        && (
                                            <>
                                                <TableHead>

                                                    <TableRow>
                                                        <TableCell style={{ width:'20%' }}>CURP</TableCell>
                                                        <TableCell style={{ width:'30%' }}>Nombre(s)</TableCell>
                                                        <TableCell style={{ width:'20%' }}>Apellido 1</TableCell>
                                                        <TableCell style={{ width:'20%' }}>Apellido 2</TableCell>
                                                        <TableCell style={{ width:'10%' }}>Sexo</TableCell>
                                                        <TableCell style={{ width:'10%' }}> </TableCell>
                                                    </TableRow>

                                                </TableHead>
                                            </>
                                        )
                                    }

                                    {
                                        tipoPersona === 2               
                                        && 
                                        (
                                            <>
                                                <TableHead>

                                                    <TableRow>

                                                        <TableCell style={{ width:'20%' }}>RFC</TableCell>
                                                        <TableCell style={{ width:'49%' }}>Razón social</TableCell>
                                                        <TableCell style={{ width:'10%' }}> </TableCell>
                                                    </TableRow>

                                                </TableHead>
                                            </>
                                        )
                                    }

                                    {
                                        tipoPersona === 1               
                                        && 
                                        (
                                            <>
                                                <TableBody>

                                                    {
                                                        (rowsPerPage > 0
                                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : rows
                                                        ).map( (row) => (

                                                        <TableRow key={ row.idPersona }>
                                                            <TableCell> { row.curp } </TableCell>
                                                            <TableCell> { row.nombres } </TableCell>
                                                            <TableCell> { row.apellido1 } </TableCell>
                                                            <TableCell> { row.apellido2 } </TableCell>
                                                            <TableCell> { row.sexo ? row.sexo.descripcion : '' } </TableCell>
                                                            <TableCell> 
                                                                <Button 
                                                                    variant="contained"
                                                                    endIcon={ <PlaylistAddCheckIcon /> }
                                                                    size="small"
                                                                    onClick={ () => { handleSeleccionar( row ); } }
                                                                    sx={{ fontSize: 12 }}
                                                                >
                                                                    Seleccionar
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>

                                                        ))
                                                    }

                                                    {
                                                        rows.length === 0 && 
                                                        (
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={6}
                                                                    align="center"
                                                                    style={{ fontWeight: 'bold', fontSize: 15 }}
                                                                >
                                                                    {
                                                                        loading
                                                                        ?
                                                                            'Buscando ....'
                                                                        :
                                                                            'No se encontraron resultados'
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>
                                            </>
                                        )
                                    }

                                    {
                                        tipoPersona === 2               
                                        && 
                                        (
                                            <>                             
                                        
                                                <TableBody>

                                                    {
                                                        (rowsPerPage > 0
                                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : rows
                                                        ).map( (row) => (

                                                        <TableRow key={ row.idPersona }>
                                                            <TableCell> { row.rfc } </TableCell>
                                                            <TableCell> { row.razonSocial } </TableCell>
                                                            <TableCell> 
                                                                <Button 
                                                                    variant="contained"
                                                                    endIcon={ <PlaylistAddCheckIcon /> }
                                                                    size="small"
                                                                    onClick={ () => { handleSeleccionar( row ); } }
                                                                    sx={{ fontSize: 12 }}
                                                                >
                                                                    Seleccionar
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>

                                                        ))
                                                    }

                                                    {
                                                        rows.length === 0 && 
                                                        (
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={6}
                                                                    align="center"
                                                                    style={{ fontWeight: 'bold', fontSize: 15 }}
                                                                >
                                                                    {
                                                                        loading
                                                                        ?
                                                                            'Buscando ....'
                                                                        :
                                                                            'No se encontraron resultados'
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>
                                                
                                            </>
                                        )
                                    }

                                    <TableFooter>

                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={6}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                      return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>
                            
                            </TableContainer>

                        </Grid>           
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ CerrarModal }>Cancelar</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
