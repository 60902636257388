export const getUserFilter = ( usuarios, value ) => {

    const usuariosFilter = usuarios.filter(function ({ nombreCompleto, puesto }) { 

        const nombreCompletoFilter = nombreCompleto.toLowerCase().indexOf(value.toLowerCase() );    
        
        if( puesto ){
            const puestoFilter = puesto.toLowerCase().indexOf(value.toLowerCase() );    
            return nombreCompletoFilter !== -1 || puestoFilter !== -1;
        }    
        else{
            return nombreCompletoFilter !== -1;
        }        
    });

    return usuariosFilter;
}
