import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SearchIcon from '@mui/icons-material/Search';

import useStyles from '../../../theme/useStyles';

export const ListItemSearch = () => {

    const { roles } = useSelector(state => state.auth);
    
    const classes = useStyles();
    
    const [anchorEl, anchorElOpen] = useState(null);
  
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        anchorElOpen( e.currentTarget );
    };
  
    const handleClose = () => {
        anchorElOpen( null );
    };

    return (
        <>
            <ListItemText className={classes.listItemOptions} inset>
                <Button color="inherit" className={classes.buttonIcon} onClick={ handleOpen }>
                    <ManageSearchIcon className={classes.PaddingIcon} />

                    <Typography color="inherit" variant="subtitle2" className={classes.ListTypography}>
                        Consultas
                    </Typography>
                    
                    <KeyboardArrowDownIcon />                            
                </Button>
            </ListItemText>   

            <Menu elevation={ 2 } anchorEl={ anchorEl } anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }} keepMounted open={ open } onClose={ handleClose }>                

                {
                    ( roles?.VisualizadorExpedienteVirtual || roles?.VisualizadorExpedienteVirtualPonencia )
                    && (
                        <Link to="/Consulta/ExpedienteVirtual" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <MenuBookIcon /> </ListItemIcon>
                                <ListItemText>Expediente Virtual</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaAudiencias && (
                        <Link to="/Consulta/Audiencias" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Audiencias</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaTramitesRecibidos && (
                        <Link to="/Consulta/TramitesRecibidos" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Trámites recibidos</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaTramitesPorDependencia && (
                        <Link to="/Consulta/TramitesDependencias" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Trámites por dependencias</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaAcuerdos && (
                        <Link to="/Consulta/AcuerdosPendientes" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Acuerdos Pendientes</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaOficios && (
                        <Link to="/Consulta/Oficios" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Oficios</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaExhortosLibrados && (
                        <Link to="/Consulta/ExhortoLibrado" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Exhortos Librados</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaNotificaciones && (
                        <Link to="/Consulta/Notificaciones" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Notificaciones</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorConsultaCierreInstruccion && (
                        <Link to="/Consulta/CierreInstruccion" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Cierre de instrucción</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorExpedientes && (
                        <Link to="/Consulta/MisExpedientes" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <MenuBookIcon /> </ListItemIcon>
                                <ListItemText>Mis Expedientes</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.VisualizadorAgendaAudienciasJuez && (
                        <Link to="/Consulta/AgendaAudienciasJuez" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <MenuBookIcon /> </ListItemIcon>
                                <ListItemText>Mi Agenda de audiencias</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }   

                {
                    roles?.VisualizadorAgendaAudienciasSecretario && (
                        <Link to="/Consulta/AgendaAudienciasSecretario" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <MenuBookIcon /> </ListItemIcon>
                                <ListItemText>Agenda de audiencias</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                             
                }  

                {
                    roles?.VisualizadorExpedientesAutorizados && (
                        <Link to="/Consulta/ExpedientesAutorizados" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Expedientes Autorizados</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }     

                {
                    roles?.VisualizadorConsultaExpedientesPorEtapaProcesal && (
                        <Link to="/Consulta/ExpedientesEtapaProcesal" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Expedientes por etapa procesal</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }       

                {
                    roles?.ReasignacionNotificaciones && (
                        <Link to="/Consulta/ReasignacionNotificaciones" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Reasignación de notificaciones</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }       

                {
                    roles?.VisualizadorConsultaConveniosFueraJuicio && (
                        <Link to="/Consulta/ConveniosFueraJuicio" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                                <ListItemIcon className={ classes.listItemIcon }> <SearchIcon /> </ListItemIcon>
                                <ListItemText>Convenios fuera de juicio</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }            
                
            </Menu>
        </>
    )
}
