import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

export const ModalDetalleAcuerdo = ( { openModal, handleCloseModal, notificacion } ) => {
    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={ true } disablePortal
                     aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
              
                <DialogContent>

                    <Grid container sx={{ mt: 1, mb: 1 }}>
                        <Grid item lg={4} md={4} xs={4}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }} >
                                Tipo de acuerdo:
                            </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} xs={8} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { notificacion.tipoAcuerdo }
                            </Typography>
                        </Grid>
                    </Grid>  

                    <Grid container sx={{ mt: 1, mb: 1 }}>
                { 
                        notificacion.subtipoAcuerdo
                        &&
                    <>
                        <Grid item lg={4} md={4} xs={4} >
                            <Typography gutterBottom variant="subtitle2" >
                                Subtipo de acuerdo:
                            </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} xs={8} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { notificacion.subtipoAcuerdo }
                            </Typography>
                        </Grid>
                        </>     
                }                   
                        <Grid item lg={12} md={12} xs={12}>

                        {
                            notificacion.documentoAcuerdoFirmado
                            ?
                            <iframe 
                                title='iframe  Demanda o Escrito Inicial'
                                style={{ width: '100%', height: '65vh' }} 
                                src={ notificacion.documentoAcuerdoFirmado }  
                            />
                            :                                        
                            <iframe 
                                title='iframe  Demanda o Escrito Inicial'
                                style={{ width: '100%', height: '65vh' }} 
                                src={ "data:application/pdf;base64," + notificacion.documentoAcuerdo }  
                            />
                        }

                        </Grid>
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ handleCloseModal } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>            
        </>
    )
}
