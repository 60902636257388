import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useStyles from '../../theme/useStyles';

import { useForm } from '../../hooks/useForm';

import { CambiarContrasena } from '../../actions/admin/users/usuarioAction';

import { Logout } from '../../actions/auth/authActions';

export const ReplacePasswordScreen = ( ) => {

    const dispatch = useDispatch();

    const classes = useStyles(); 

    const [alert, setAlert] = useState({  });

    const [open, setOpen] = useState( false );

    const [loading, setLoading] = useState( false );
    const [loadingInicio, setLoadingInicio] = useState( false );

    const [ formValues, handleInputChange, resetValues ] = useForm( {
        contrasena: '',
        contrasenaConfirm: ''
    } );

    const { contrasena, contrasenaConfirm} = formValues;

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const handleChangeViewConfirmarContrasena = () => {
        setViewConfirmarContrasena( ( prev ) => !prev );
    }

    const handleChangePassword = ( e ) => {

        e.preventDefault();  

        if(!contrasena){
                
            setAlert({
                open: true,
                msg: 'Debe escribir una contraseña',
                type: 'error'
            });          
            
        } 
        else if(!contrasenaConfirm){
                
            setAlert({
                open: true,
                msg: 'Debe escribir la confirmación de la contraseña',
                type: 'error'
            });       
            
        } 
        else if( contrasena !== contrasenaConfirm){
                
            setAlert({
                open: true,
                msg: 'Las contraseñas no coinciden',
                type: 'error'
            });          
            
        }   
        else{

            setLoading( true );

            const params = { "contrasena": contrasena }

            CambiarContrasena( params ).then( response => {
                if(response.data.success === true){       
                        
                    setTimeout(() => {
                        
                        setAlert({
                            open: true,
                            msg: 'Se cambió la contraseña correctamente',
                            type: 'success'
                        });

                        resetValues();

                        setLoading( false );

                        setOpen( true );   

                    }, 1200);
                    
                }
                else{

                    setTimeout(() => {
                        
                        setAlert({
                            open: true,
                            msg: 'Ocurrio un error, por favor de contactar con el administrador del sistema.',
                            type: 'error'
                        });

                        resetValues();

                        setLoading( false );

                    }, 1200);
                   
                }
            });   
        }          
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const IniciarSesion = () => {

        setLoadingInicio( true );

        setTimeout(() => {
            
            handleClose();

            dispatch( Logout() );
        
        }, 1200);
        
    }

    return (

        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={ 3000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }               
        
            <Container component="main" maxWidth="xs">   

                <Box className={classes.formLogin}>
                    
                    <Typography component="h1" variant="h3">
                        {/* SAJI */}

                        <img style={{ height: 170  }}
                            src={ process.env.PUBLIC_URL + '/logos/SAJI-V.svg'}
                            alt='logo_saji'
                        />
                        
                    </Typography>
                
                    <Box>

                        <Typography component="h1" variant="h6" style={{ textAlign: 'center' }}>
                            Actualizar contraseña
                        </Typography>

                        <Typography component="h1" variant="subtitle2" sx={{ mt: 1 }} className={classes.subtitle} >                    
                            Para mayor seguridad de tu cuenta o si es la primera vez que iniciás sesión, necesitamos que cambies tu contraseña.
                        </Typography>

                        <form>
                            
                            <Grid container spacing={2} style={{ marginTop: '5px' }} >
                            
                                <Grid item md={12} xs={12} >

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Contraseña</InputLabel>
                                        <OutlinedInput
                                            label="Contraseña"
                                            name="contrasena"
                                            type={ viewContrasena ? 'text' : 'password'}
                                            value={ contrasena }                                
                                            onChange={ handleInputChange }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                        {
                                                            viewContrasena
                                                            ?
                                                            <VisibilityOffIcon />
                                                            :
                                                            <VisibilityIcon />
                                                        }                                                    
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Confirmar contraseña</InputLabel>
                                        <OutlinedInput
                                            label="Confirmar contraseña"
                                            name="contrasenaConfirm"
                                            type={ viewConfirmarContrasena ? 'text' : 'password'}
                                            value={ contrasenaConfirm }                                
                                            onChange={ handleInputChange }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                        {
                                                            viewConfirmarContrasena
                                                            ?
                                                            <VisibilityOffIcon />
                                                            :
                                                            <VisibilityIcon />
                                                        }                                                    
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>    

                                </Grid>

                            </Grid>
                    
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={ handleChangePassword }
                                sx={{ mt: 3, mb: 2 }} 
                                loading={ loading }
                                startIcon={ <SaveIcon />}
                            >
                                Cambiar contraseña
                            </LoadingButton>

                        </form>

                    </Box>
                </Box>

            </Container>

            <Dialog open={ open } onClose={ () => {} } >
                <DialogTitle>
                    Iniciar sesión
                </DialogTitle>

                <DialogContent>
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        Para que la cuenta tome los nuevos cambios es necesario volver a iniciar sesión.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton variant="contained" color="primary" loading={ loadingInicio } onClick={ IniciarSesion }>
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>  

        </>

    )
}
