import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Alert, FormControl, FormControlLabel, FormLabel, Grid, InputBase, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import moment from 'moment';

import { BuscarExpediente } from '../../../actions/recepcion/expedienteActions';
import { ObtenerAutoridadesAmparo } from '../../../actions/admin/catalogs/autoridadAmparoActions';
import { ObtenerTiposAmparos } from '../../../actions/admin/catalogs/tipoAmparoActions';

import { RegistrarInformacion } from '../../../actions/recepcion/recepcionActions';

import { types } from '../../../types/types';
import { ObtenerPonencias } from '../../../actions/admin/catalogs/ponenciaActions';

export const RegistrarInformacionScreen = ({ handleNext, idRecepcion }) => {

    const dispatch = useDispatch();
    
    const { amparo } = useSelector( state => state.recepcion );

    const { tribunal } = useSelector( state => state.auth );
    
    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [loading, setLoading] = useState( false );

    const [errors, setErrors] = useState({ });

    const [expediente, setExpediente] = useState( {} );

    const [numeroExpediente, setNumeroExpediente] = useState('');

    const [tipoAmparo, setTipoAmparo] = useState( 0 );
    const [tipoAmparoArray, setTipoAmparoArray] = useState( [ ] );

    const [autoridadAmparo, setAutoridadAmparo] = useState( 0 );
    const [autoridadAmparoArray, setAutoridadAmparoArray] = useState( [ ] );

    const [fechaReloj, setFechaReloj] = useState( DateNow );
    const [recibidoBuzon, setRecibidoBuzon] = useState( false );
    const [recibidoReloj, setRecibidoReloj] = useState( false );

    const [dirigidoExpediente, setDirigidoExpediente] = useState( false );

    const [loadingNext, setLoadingNext] = useState( false );

    const [asignacionDirecta, setAsignacionDirecta] = useState( false );
    const [motivoAsignacion, setMotivoAsignacion] = useState( '' );

    const [ponencia, setPonencia] = useState( 0 );
    const [ponenciaArray, setPonenciaArray] = useState( [ ] );

    const [activate, setActivate] = useState( true );    

    const handleChangeAsignacion = ( checked ) => {

        setAsignacionDirecta( checked );
        setMotivoAsignacion( '' );
        setPonencia( 0 );

    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );

        if( numeroExpediente === '' ){

            setExpediente( { } );
           
            let errores = {}; 

            errores.expediente = 'Debes teclear un expediente';
            errores.type = 'warning';

            setErrors( errores );
            setLoading( false );

            return false;
        } 

        const params = {
            numeroExpediente: numeroExpediente
        }
                
        await BuscarExpediente( params ).then( response => {

            setLoading( true );

            if( response ){
               
                if( response.status === 200){

                    setTimeout(() => {

                        setLoading( false );

                        if(response.data){

                            if(response.data.success){
                               
                                setErrors( { } );

                                setExpediente(response.data);
                                setActivate( response?.data?.acumulado ?? false );

                            }
                            else{

                                errorExpediente();

                            }

                        }

                    }, 1500);  

                    setNumeroExpediente('');
                } 
                else {

                    setTimeout(() => {
                      
                        setLoading( false );

                    }, 1500);  

                    setNumeroExpediente('');
                }

            }

        });

    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( ( !expediente.success && tipoAmparo === 1 ) || ( !expediente.success && tipoAmparo === 2 && dirigidoExpediente ) ){
            valid = false;
            errores.expediente = 'Debe buscar un expediente para poder continuar';
            errores.type = 'warning';
            setLoading( false );
        } 

        if( tipoAmparo === 0 ){
            valid = false;
            errores.tipoAmparo = 'Seleccione un tipo de amparo';
            errores.type = 'warning';
            setLoading( false );
        } 

        if( autoridadAmparo === 0 ){
            valid = false;
            errores.autoridadAmparo = 'Seleccione la autoridad del amparo';
            errores.type = 'warning';
            setLoading( false );
        } 

        if( fechaReloj === '' && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'Debes seleccionar la fecha de reloj';
            errores.type = 'warning';
            setLoading( false );
        }

        if( moment( new Date( fechaReloj ) ) > moment( new Date( ) ) && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'La fecha de reloj no puede ser mayor a la fecha actual';
        }

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleChangeRecibidoReloj = ( value ) => {

        setRecibidoReloj( value );

        const Now = moment( new Date()).format("YYYY-MM-DDTHH:mm");
        setFechaReloj( Now );
    }

    const handleChangeTipoAmparo = ( value ) => {

        setTipoAmparo( value ); 
        setAutoridadAmparo( 0 );
        setExpediente( {} ); 
        setDirigidoExpediente( false );
        setAsignacionDirecta( false );

    }

    const handleChangeDirigidoExpediente = ( value ) => {
        
        setDirigidoExpediente( value ); 
        setExpediente( {} ); 
        setAsignacionDirecta( false );
        setAsignacionDirecta( false );

    }

    const Siguiente = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );

        const params = {

            idRecepcion: idRecepcion,          
            recibidoPorReloj: recibidoReloj,
            fechaReloj: recibidoReloj ? fechaReloj : null,
            RecibidoBuzonElectronico: recibidoBuzon,
            idTipoAmparo: tipoAmparo,
            idAutoridadAmparo: autoridadAmparo,
            idExpediente: expediente.idExpediente ?? 0,
            dirigidoExpediente : dirigidoExpediente,
            asignacionDirecta: asignacionDirecta,
            motivoAsignacion: motivoAsignacion,
            idPonencia: ponencia,
        };

        await RegistrarInformacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    var { descripcion: descripcionTipoAmparo } = tipoAmparoArray.find( ( element ) => { return element.idTipoAmparo === tipoAmparo });                   
                    var { descripcion: descripcionAutoridadAmparo } = autoridadAmparoArray.find( ( element ) => { return element.idAutoridadAmparo === autoridadAmparo });                   

                    var descripcionPonencia = '';
                    if( ponencia !== 0 ){
                        var { descripcion : descPonencia } = ponenciaArray.find( ( element ) => { return element.idPonencia === ponencia });       
                        descripcionPonencia = descPonencia;            
                    }                    

                    dispatch({

                        type: types.actualizarAmparo,
                        payload: {

                            idTipoAmparo: tipoAmparo,
                            tipoAmparo:descripcionTipoAmparo ,
                            idAutoridadAmparo: autoridadAmparo,
                            autoridadAmparo: descripcionAutoridadAmparo,
                            recibidoBuzon: recibidoBuzon,
                            recibidoReloj: recibidoReloj,                                      
                            fechaReloj: recibidoReloj ? moment( new Date( fechaReloj ) ).format("YYYY-MM-DDTHH:mm") : '',
                            expediente: expediente,
                            dirigidoExpediente: dirigidoExpediente,            
                            asignacionDirecta: false,
                            motivoAsignacion: motivoAsignacion,
                            idPonencia: ponencia,
                            ponencia: descripcionPonencia,
                            numeroAmparo: ''
                        }
                    });    
                    
                    setTimeout(() => {
                        
                        setLoadingNext( false );

                        handleNext();

                    }, 500);  

                }

            }

        });
    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerAutoridadesAmparo().then( response => {

                if( response ){

                    if( response.data ){
                        setAutoridadAmparoArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTiposAmparos().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoAmparoArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerPonencias( ).then( response => {

                if( response ){

                    if( response.data ){
                        setPonenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {      

        if( amparo ){
            setRecibidoBuzon( amparo.recibidoBuzon );
            setRecibidoReloj( amparo.recibidoReloj );          
            setFechaReloj( amparo.fechaReloj === '' ? DateNow : amparo.fechaReloj );
            setExpediente( amparo.expediente );
            setDirigidoExpediente( amparo.dirigidoExpediente );     
            setAsignacionDirecta( amparo.asignacionDirecta );
            setMotivoAsignacion( amparo.motivoAsignacion );                
        }      
      
    }, [ amparo, DateNow ])

    useEffect(() => {     
        
        if( amparo && tipoAmparoArray.length > 0 ){
            setTipoAmparo( amparo.idTipoAmparo ?? 0 );
        }  
      
    }, [ amparo, tipoAmparoArray ])    

    useEffect(() => {    
        
        if( amparo && tipoAmparo !== 0 && autoridadAmparoArray.length > 0 ){
            setAutoridadAmparo( amparo.idAutoridadAmparo ?? 0 );
        }
              
    }, [ amparo, tipoAmparo, autoridadAmparoArray ])

    useEffect(() => {      

        if( amparo && ponenciaArray.length > 0 ){
            setPonencia( amparo.idPonencia ?? 0 );   
        }
      
    }, [ amparo, ponenciaArray ])

    useEffect(() => {
     
        const requests = axios.CancelToken.source(); 

        return () => {
            requests.cancel();
        }

    }, [ ])

    return (
        <>           
            <Grid item md={6} xs={12} >                            
                <FormControl fullWidth>
                    <InputLabel id="select-tipoAmparo-label">Tipo de amparo</InputLabel>
                
                    <Select
                        labelId="select-tipoAmparo-label"
                        id="select-tipoAmparo"                                            
                        label="Tipo de amparo"
                        name="tipoAmparo"
                        value={ tipoAmparo }
                        onChange={ ( e ) => { handleChangeTipoAmparo( e.target.value ); } }
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>       
                        {
                            tipoAmparoArray.map( ( element ) => (
                                <MenuItem key={ element.idTipoAmparo } value={ element.idTipoAmparo }>{ element.descripcion }</MenuItem>                        
                            ))
                        } 
                    </Select>
                    {   
                        errors.tipoAmparo && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.tipoAmparo  } </Alert> )
                    }
                </FormControl>
            </Grid> 

            <Grid item md={6} xs={12} >                            
                <FormControl fullWidth>
                    <InputLabel id="select-autoridadAmparo-label">Autoridad del amparo</InputLabel>
                
                    <Select
                        labelId="select-autoridadAmparo-label"
                        id="select-autoridadAmparo"                                            
                        label="Autoridad del amparo"
                        name="autoridadAmparo"
                        value={ autoridadAmparo }
                        onChange={ ( e ) => { setAutoridadAmparo( e.target.value ); } }
                        disabled={ tipoAmparo === 0 }
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                        {
                            tipoAmparo === 1
                            &&
                                autoridadAmparoArray
                                .filter( ( elem ) => {
                                    return elem.idAutoridadAmparo === 2;
                                })
                                .map( ( element ) => (
                                    <MenuItem key={ element.idAutoridadAmparo } value={ element.idAutoridadAmparo }>{ element.descripcion }</MenuItem>                        
                                ))
                        } 
                        {
                            tipoAmparo === 2
                            &&
                                autoridadAmparoArray
                                .filter( ( elem ) => {
                                    return elem.idAutoridadAmparo === 1;
                                })
                                .map( ( element ) => (
                                    <MenuItem key={ element.idAutoridadAmparo } value={ element.idAutoridadAmparo }>{ element.descripcion }</MenuItem>                        
                                ))
                        } 
                    </Select>
                    {   
                        errors.autoridadAmparo && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.autoridadAmparo  } </Alert> )
                    }
                </FormControl>
            </Grid> 

            {
                tipoAmparo === 2
                &&
                <Grid item md={12} xs={12} >
                    <FormControl component="fieldset">
                        <FormLabel component="legend">¿El amparo va dirigido a un expediente?</FormLabel>
                        <RadioGroup row
                            aria-label="dirigidoExpediente"
                            name="dirigidoExpediente"                         
                        >
                            <FormControlLabel control={ <Radio checked={ dirigidoExpediente === false } onChange={ () => { handleChangeDirigidoExpediente( false ); } } />} label="No" />
                            <FormControlLabel control={ <Radio checked={ dirigidoExpediente === true } onChange={ () => { handleChangeDirigidoExpediente( true ); } } />} label="Si" />   
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }

            <Grid item md={12} xs={12} >
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿El trámite proviene del buzón electrónico?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoBuzon"
                        name="recibidoBuzon"                         
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === false } onChange={ () => { setRecibidoBuzon( false ); } } />} label="No" />
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === true } onChange={ () => { setRecibidoBuzon( true ); } } />} label="Si" />   
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿El trámite es recibido por reloj?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoReloj"
                        name="recibidoReloj"                           
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === false } onChange={ () => { handleChangeRecibidoReloj( false ); } } />} label="No" />
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === true } onChange={ () => { handleChangeRecibidoReloj( true ); } } />} label="Si" />                        
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >
                {
                    recibidoReloj && (
                        <>
                            <TextField                              
                                label="Fecha recibida por reloj"
                                type="datetime-local"   
                                name="fechaReloj"                             
                                value={ fechaReloj }    
                                onChange={ ( e ) => { setFechaReloj( e.target.value ); } }                                                     
                                fullWidth
                                inputProps={{
                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                }}                             
                                InputLabelProps={{
                                    shrink: true,
                                }}                   
                                                
                            />

                            {   
                                errors.fechaReloj && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaReloj } </Alert> )
                            }

                        </>
                    )
                }
            </Grid>

            {
                ( tribunal && tipoAmparo === 2 && !dirigidoExpediente )
                &&
                <Grid item md={3} xs={12} >
                    <FormControl component="fieldset" disabled={ amparo.numeroAmparo !== "" } >
                        <FormLabel component="legend">¿Es asignación directa?</FormLabel>
                        <RadioGroup row
                            aria-label="asignacionDirecta"                                   
                            name="asignacionDirecta"                     
                        >
                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === false } onChange={ () => { handleChangeAsignacion( false ); } } />} label="No" />
                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === true } onChange={ () => { handleChangeAsignacion( true ); } } />} label="Si" />   
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }

            {
                asignacionDirecta && (
                    <>
                        <Grid item md={9} xs={12} >
                            <TextField                              
                                label="Motivo de la asignación directa"    
                                name="motivoAsignacion"                                                 
                                value={ motivoAsignacion }
                                onChange={ ( e ) => { setMotivoAsignacion( e.target.value ); } }                           
                                multiline
                                rows={4}  
                                fullWidth         
                                disabled={ amparo.numeroAmparo !== "" }                 
                            />

                            {   
                                errors.motivoAsignacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.motivoAsignacion } </Alert> )
                            }

                        </Grid>

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth disabled={ amparo.numeroAmparo !== "" }>
                                <InputLabel id="select-Ponencia-label">Ponencia</InputLabel>
                            
                                <Select
                                    labelId="select-Ponencia-label"
                                    id="select-Ponencia"                                            
                                    label="Ponencia"
                                    name="ponencia"
                                    value={ ponencia }
                                    onChange={ ( e ) => { setPonencia( e.target.value ); } }                           
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        ponenciaArray.map( ( element ) => (
                                            <MenuItem key={ element.idPonencia } value={ element.idPonencia }>{ element.descripcion }</MenuItem>                        
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.ponencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.ponencia } </Alert> )
                            }

                        </Grid> 
                    </>
                )
            }   

            {
                ( tipoAmparo === 1 || ( tipoAmparo === 2 && dirigidoExpediente ) )
                &&
                <Grid item md={12} xs={12} > 
                        
                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                    
                        <InputBase 
                            sx={{ ml: 1, flex: 1 }} 
                            inputProps={{ 'aria-label': 'Buscar expediente' }} 
                            placeholder="Buscar por número de expediente"     
                            name="expediente"
                            value={ numeroExpediente } 
                            onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                            autoComplete="off"
                        /> 

                        <LoadingButton 
                            variant='contained' 
                            sx={{ borderRadius: 10 }} 
                            loading={ loading }
                            onClick={ handleClicBuscarExpediente }
                            size='small'
                        >
                            <SearchIcon />
                        </LoadingButton>   

                    </Paper>

                    {   
                        errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                    }
                </Grid>
            }

            {
                ( expediente.success ) && (
                    <>
                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                Expediente:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                { expediente.numeroExpediente }
                            </Typography>
                        </Grid>

                        <Grid item md={2} xs={6}>    
                            {
                                expediente.ponencia
                                &&                                                     
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Ponencia:
                                </Typography>   
                            }                             
                        </Grid>                            

                        <Grid item md={4} xs={6} >
                            {
                                expediente.ponencia
                                &&       
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { expediente.ponencia }
                                </Typography>
                            }
                        </Grid> 

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                Actor(es):
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <List disablePadding>                           
                            {
                                expediente.actor.map((elem, i)=>(
                                    <ListItem key={ i } disablePadding> 
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                            { elem }
                                        </Typography>
                                    </ListItem>                    
                                ))
                            }                                                 
                            </List>      
                        </Grid>
                        
                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                Demandado(s):
                            </Typography>
                        </Grid>
                        
                        <Grid item md={4} xs={6} >
                            <List disablePadding>
                                    {
                                        expediente.demandado.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                                                                
                            </List>
                        </Grid>  
                       
                    </>
                )
            }     

            {
                expediente?.acumulado
                &&                            
                    <Grid item md={12} xs={12} mt={1} >
                        <Alert severity="info" color="warning">
                            <strong>
                                El expediente { expediente?.numeroExpediente } se encuentra acumulado 
                                { 
                                    expediente?.numeroExpedientePrincipal !== '' 
                                    ? 
                                        ' en el expediente principal: ' + expediente?.numeroExpedientePrincipal + '.'
                                    : 
                                        '.' 
                                }
                            </strong>
                        </Alert>               
                    </Grid>       
            }

            <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>  
              
              <LoadingButton 
                  variant="contained" 
                  onClick={ Siguiente }
                  endIcon={ <ArrowForwardIcon /> }
                  loading={ loadingNext }
                  disabled={ activate }
              > 
                  Siguiente 
              </LoadingButton>

            </Grid>
            
        </>
    );
}
