import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton, List, ListItem, ListItemText, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

import moment from 'moment';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { ActualizarApiKey, ActualizarApiKeyEstatus, AgregarApiKey, ObtenerApiKeys } from '../../../actions/admin/apikey/apiKeysActions';

export const ApiKeyScreen = () => {

    const [rows, setRows] = useState( [] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 5 );

    const [loading, setLoading] = useState( true );
    const [loadingSave, setLoadingSave] = useState( false );

    const [openGenerar, setOpenGenerar] = useState( false );
    const [openApiKey, setOpenApiKey] = useState( false );

    const [descripcion, setDescripcion] = useState( '' );
    const [dominio, setDominio] = useState( '' );
    const [dominios, setDominios] = useState( [] );

    const [errors, setErrors] = useState( {} );
    const [referencia, setReferencia] = useState( '' );
    const [apiKey, setApiKey] = useState( '' );

    const handleChangeApiKeyActiva = async ( value, id ) => {

        await ActualizarApiKeyEstatus({ id, activar: value }).then( response => {

            if( response ){

                if( response.status === 200 ){                      
                           
                    const newArray = rows.map( ( elem ) => {
                        if( elem.id === id ){
                            elem.activo = value;
                        }
                        return elem;
                    });
            
                    setRows( newArray );
            
                }
            }

        })

    }

    const handleAgregarDominio = () => {

        setErrors( {} );

        const validateDominio =  dominios.find( ( element ) => { return element.dominio === dominio });           

        if( dominio.includes('http') || dominio.includes('https') || dominio.includes(':') || dominio.includes('/') ){
            let errores = {};
    
            errores.error = 'El dominio no debe incluir http o https y ningun caracter como : /';
        
            setErrors( errores );  
        }
        else if( !validateDominio && dominio !== '' ){

            const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

            setDominios([
                ...dominios,
                {
                    id,
                    dominio,
                    activo: true,
                }
            ]);

            setDominio( '' );
        }
        else{
            let errores = {};
    
            errores.error = dominio === '' ? 'Escribe un dominio' :'Ya se tiene agregado un dominio con la misma descripción';
        
            setErrors( errores );  
        }

    }

    const handleGuardarApiKey = async () => {

        if( descripcion === '' ){
            setErrors({ error: 'Debes escribir el nombre' }); 
            return false;
        }
        else if( dominios.length === 0 ){
            setErrors({ error: 'Agrega un dominio por lo menos' }); 
            return false;
        }

        setLoadingSave( true );

        if( referencia){

            await ActualizarApiKey({ id: referencia, descripcion, dominios }).then( response => {

                if( response ){

                    if( response.status === 200 ){          
                        
                        const { success, data } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setRows(rows.map( ( elem ) => {
                                    if( elem.id === referencia){
                                        elem = data;
                                    }
                                    return elem;
                                }));
    
                                handleClose();

                                setLoadingSave( false );
                                
                            }, 700);

                        }
                
                    }
                }
    
            })    

        }
        else{

            await AgregarApiKey({ descripcion, dominios }).then( response => {

                if( response ){

                    if( response.status === 200 ){          
                        
                        const { success, data } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setRows([
                                    ...rows,
                                    data
                                ]);
    
                                setApiKey( data?.apiKey  ?? '' );
    
                                handleClose();
                                
                                setLoadingSave( false );

                                setOpenApiKey( true );
                                
                            }, 700);

                        }
                
                    }
                }
    
            })    

        }

    }

    const handleClose = () => {

        setReferencia( '' );
        setDescripcion( '' );
        setDominio( '' );
        setDominios( [] );
        setErrors( {} );
        setOpenGenerar( false );

    }

    const handleDeleteDomain = ( Id ) => {

        const newDomains = dominios.filter( ( elem ) => elem.id !== Id );
        setDominios( newDomains );

    }

    useEffect(() => {
      
        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerApiKeys().then( response => {

                if( response ){

                    if( response.status === 200 ){                       

                        setTimeout(() => {
                            
                            setRows( response.data ?? [] );                
                            setLoading( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }

        Obtener();

    }, [ ])

    return (
        <>

            <AppBarMenu />            

            <Box sx={{ marginTop: '10em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg" component={ Paper } >   

                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12} >  

                            <Grid container spacing={3} display='flex' flexDirection='revert' justifyContent='end' >

                                <Grid item md={3} xs={12}>

                                    <Button
                                        variant='contained'
                                        onClick={ () => { handleClose(); setOpenGenerar( true ); } }
                                        color='primary'
                                        startIcon={ <AddIcon /> }
                                        fullWidth
                                    >
                                        Generar Api Key
                                    </Button>

                                </Grid>

                            </Grid>

                        </Grid>  

                        <Grid item md={12} xs={12} >

                            <TableContainer>

                                <Table>
                                    
                                    <TableHead>

                                        <TableRow>     
                                        
                                            <TableCell style={{ width:'15%', fontWeight: 'bold' }}> NOMBRE </TableCell>
                                            <TableCell style={{ width:'15%', fontWeight: 'bold' }}> FECHA DE CREACIÓN </TableCell>
                                            <TableCell style={{ width:'20%', fontWeight: 'bold' }}> API KEY </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold', textAlign: 'center' }}> HABILITAR  </TableCell>
                                            <TableCell style={{ width:'1%', textAlign: 'center' }}>  </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            ( rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( ( row, i) => (

                                                <TableRow key={ row.id } >
                                                    
                                                    <TableCell> { row.descripcion } </TableCell>
                                                    <TableCell> { row.fechaCreacion ?  moment( row.fechaCreacion ).format('DD-MM-YYYY HH:mm a') : '' } </TableCell>
                                                    
                                                    <TableCell sx={{ fontWeight: 'bold' }}> 
                                                        
                                                        <Typography>
                                                            <strong> { row.apiKey } </strong>
                                                        </Typography>

                                                    </TableCell>

                                                    <TableCell sx={{ textAlign: 'center' }}> 

                                                        <Switch checked={ row.activo } onChange={ (e) => handleChangeApiKeyActiva( e.target.checked, row.id ) } />
                                        
                                                    </TableCell>
                                
                                                    <TableCell sx={{ textAlign: 'center' }}> 

                                                        <IconButton
                                                            color='primary'
                                                            onClick={ () => { 
                                                                setOpenGenerar( true ); 
                                                                setDescripcion( row?.descripcion ?? '' );
                                                                setDominios( row?.dominios ?? [] );
                                                                setReferencia( row?.id );
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>  
                                        
                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        { 
                                            rows.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                        {
                                                            loading
                                                            ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                            :
                                                                ''
                                                        }
                                                        {
                                                            !loading && rows.length === 0
                                                            ?
                                                                'No se encontraron Api key´s'
                                                            :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>
                                
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={10}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog
                open={ openGenerar }
                onClose={ () => { } }
            >
                <DialogTitle>
                    { referencia ? 'Editar Api Key' : 'Generar Api Key' }
                </DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12}>

                            <TextField 
                                name='nombre'
                                label='Nombre'
                                variant='standard'
                                type='text'
                                autoComplete='off'
                                fullWidth
                                value={ descripcion }
                                onChange={ (e) => setDescripcion( e.target.value ) }
                            />

                        </Grid>

                        <Grid item md={12} xs={12}>

                            <Box component="div" sx={{ display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <TextField
                                    label="Agregar dominio"
                                    name='dominiosAutorizados'
                                    variant="standard"
                                    autoComplete='off'
                                    fullWidth
                                    value={ dominio }
                                    onChange={ (e) => setDominio( e.target.value ) }
                                />

                                <LoadingButton 
                                    variant='contained'
                                    sx={{ borderRadius: 10 }}
                                    size='small'
                                    onClick={ handleAgregarDominio }
                                > 
                                    <AddIcon size='small' /> 
                                </LoadingButton>             

                            </Box>

                            <FormHelperText>Permite a los sitios web especificados hacer solicitudes mediante el uso de la clave .</FormHelperText>

                        </Grid>

                        <Grid item md={12} xs={12}>

                            <List subheader={ <Typography variant='h6' sx={{ fontSize: 16, mb: 2, fontWeight: 'bold' }} >Dominio(s) autorizado(s)</Typography> }>

                                {
                                    dominios.map( ( elem ) => (

                                        <ListItem disablePadding key={ elem.id }>
                                
                                            <ListItemText 
                                                primary={ elem.dominio }
                                            />
                                            
                                            <IconButton onClick={ () => handleDeleteDomain( elem.id ) }>
                                                <DeleteIcon color='error' />
                                            </IconButton>
        
                                        </ListItem>

                                    ))
                                }

                            </List>

                        </Grid>

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >

                                    <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                                </Grid>
                            )
                        }

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleGuardarApiKey } startIcon={ <SaveIcon /> } loading={ loadingSave } >
                        Guardar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={ openApiKey }
                onClose={ () => { } }
            >
                <DialogTitle>
                    Api key 
                </DialogTitle>
            
                <DialogContent>

                    <Chip color='primary' sx={{ fontWeight: 'bold', fontSize: 16 }} label={ apiKey } />

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenApiKey( false ) } }>Cerrar</Button>
                </DialogActions>

            </Dialog>
            
        </>
    )
}
