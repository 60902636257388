import React from 'react';
import ReactDOM from 'react-dom';
import { SajiApp } from './SajiApp';

import './css/index.css';

ReactDOM.render(
    <SajiApp />,  
    document.getElementById('root')
);
