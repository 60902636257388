import React, { useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SourceIcon from '@mui/icons-material/Source';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';

import { ConsultaDependencias } from '../../actions/recepcion/expedienteActions';
import { ObtenerTipoJuicio } from '../../actions/admin/catalogs/tipojuicioActions';
import { ObtenerDependencias } from '../../actions/admin/catalogs/dependenciaActions';
import { useSelector } from 'react-redux';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';

export const TramitesDependenciasScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );
                
    const [rows, setRows] = useState( [ ] );
    
    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [autocompleteValue, setAutocompleteValue] = useState( null );
    const [dependencia, setDependencia] = useState( 0 );
    const [dependenciaArray, setDependenciaArray] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages();

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [openModal, setOpenModal] = useState( false );

    const [expediente, setExpediente] = useState( 0 );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );


    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setTipoJuicio( 0 );
        setDependencia( 0 );
        setAutocompleteValue( null );
        setAutoridad( 0 );
        
        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();

    }

    const handleBuscarTramites = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );                

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoJuicio( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoJuicioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);   

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerDependencias( ).then( response => {

                if( response ){

                    if( response.data ){
                        setDependenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaDependencias( { page, rowsPerPage, idDependencia: dependencia, idTipoJuicio: tipoJuicio, idAutoridad: autoridad } ).then( response => {
                    
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoadingSearch( false );
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });

        }     
        
        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, tipoJuicio, dependencia, autoridad ]);   

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  
                
                <Container maxWidth="xl">
                    
                    <Grid container spacing={3} >

                        <Grid item lg={12} md={12} xs={12}>

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3}>

                                {     
                                    ( administrador || coordinador )
                                     && 
                                    <Grid item md={4} sm={6} xs={12}> 
                                                        
                                        <FormControl fullWidth>
                                            <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                        
                                                <Select
                                                    labelId="select-autoridad-label"
                                                    id="select-autoridad"                                            
                                                    label="Autoridad"
                                                    name="autoridad"
                                                    value={ autoridad }                                
                                                    onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                                            >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        coordinador
                                                        ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                        :
                                                            autoridadArray.map( ( elem ) => (
                                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    }                          
                                                </Select>
            
                                            </FormControl>
                                                                    
                                    </Grid>
                                }    

                                        <Grid item md={5} xs={12} >    

                                            <FormControl fullWidth>

                                                <InputLabel id="select-tipoJuicio-label">Tipo de juicio</InputLabel>

                                                <Select
                                                    labelId="select-tipoJuicio-label"
                                                    id="select-tipoJuicio"                                            
                                                    label="Tipo de juicio"
                                                    name="tipoJuicio"
                                                    value={ tipoJuicio }
                                                    onChange={ ( e ) => setTipoJuicio( e.target.value ) }
                                                    disabled={ filtroAplicado }  
                                                >
                                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        tipoJuicioArray                                                        
                                                        .map( ( element ) => (
                                                            <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                                                        ))
                                                    }
                                                </Select>

                                            </FormControl>

                                        </Grid>  

                                        <Grid item md={5} xs={12} >    

                                            <Autocomplete                    
                                                disablePortal 
                                                fullWidth              
                                                value={ autocompleteValue }   
                                                options={ dependenciaArray }
                                                onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                                getOptionLabel={ (option) => option.descripcion}
                                                isOptionEqualToValue={(option, value) => option.idDependencia === value.idDependencia }
                                                renderOption={ ( props, option ) => (
                                                    <li { ...props } key={ option.idDependencia} >                         
                                                        { option.descripcion }
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Dependencia" />}
                                                disabled={ filtroAplicado }                   
                                            />

                                        </Grid>  

                                        <Grid item md={2} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                fullWidth 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarTramites() } }
                                                loading={ loadingSearch }
                                                disabled={ tipoJuicio === 0 && dependencia === 0 && autoridad === 0 }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>  

                                    </Grid>

                                </CardContent>

                            </Card>

                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ pt: 3, pb: 3 }} variant="h4" >
                                        Trámites por dependencias
                                    </Typography>
                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >

                                        <TableHead>

                                            <TableRow>      

                                                <TableCell style={{ width:'12%', fontWeight: 'bold' }}> DEPENDENCIA </TableCell>
                                                <TableCell style={{ width:'7%', fontWeight: 'bold' }}> TIPO DE PARTE </TableCell>
                                                <TableCell style={{ width:'7%', fontWeight: 'bold' }}> FECHA </TableCell>
                                                <TableCell style={{ width:'5%', fontWeight: 'bold' }}> EXPEDIENTE </TableCell>
                                                <TableCell style={{ width:'7%', fontWeight: 'bold' }}> TIPO DE JUICIO </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> PONENCIA </TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                rows.map( ( row, i ) => (

                                                    <TableRow key={ i } >

                                                        <TableCell> { row.dependencia } </TableCell>    

                                                        <TableCell> { row.tipoParte } </TableCell>   

                                                        <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>                                              

                                                        <TableCell>                              

                                                            {
                                                                row?.numeroExpediente
                                                                &&                                                            
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { setExpediente( row?.idExpediente ); setOpenModal( true ); } }
                                                                >
                                                                    { row?.numeroExpediente ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell>
                                                        
                                                        <TableCell> { row.tipoJuicio } </TableCell>

                                                        <TableCell> { row.ponencia } </TableCell>

                                                    </TableRow>

                                                ))
                                            }

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>

                                                        <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron expedientes'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TableCell colSpan={2}>
                                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de trámites: { recordsCount } </Typography>
                                                </TableCell> 

                                                <TablePagination
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    colSpan={ 10 }
                                                    count={ recordsCount }                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={ page }                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />

                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Paper>

                        </Grid>

                    </Grid>                   

                </Container>

            </Box>

            <ModalDetalleExpediente 
                open={ openModal } 
                setOpen={ setOpenModal } 
                idExpediente={ expediente } 
            />

        </>
    )
}