
export const ConvertToDateTime = (valueDate, valueTimeInicio, valueTimeFin) => {

    const Fecha = new Date( valueDate );
    const HoraInicio = new Date( valueTimeInicio );
    const HoraFin = new Date( valueTimeFin );

    const Inicio = new Date( Fecha.getFullYear() + '-' + ( Fecha.getMonth() + 1 ) + '-' + Fecha.getDate() + ' ' + HoraInicio.getHours() + ':' + HoraInicio.getMinutes() );  
    const Fin = new Date( Fecha.getFullYear() + '-' + ( Fecha.getMonth() + 1 ) + '-' + Fecha.getDate() + ' ' + HoraFin.getHours() + ':' + HoraFin.getMinutes() );  

    return [
        Inicio,
        Fin 
    ];
}
