import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check'

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { ActualizarDependencia, AgregarDependencia, Dependencias, ObtenerDomicilioDependencia } from '../../../actions/admin/catalogs/dependenciaActions';

import { TipoDependencias } from '../../../actions/admin/catalogs/tipoDependenciaActions';

    
export const DependenciaScreen = ( { history } ) => {

    const { location } = history;
    const { pathname } = location;

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [rowsDomicilio, setRowsDomicilio] = useState( [ ] );

    const [tipoDependencia, setTipoDependencia] = useState( 0 );
    const [tipoDependenciaArray, SetTipoDependenciaArray] = useState( [ ] );

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );

    const [openModalDomicilio, setOpenModalDomicilio] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idDependencia === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const dependencia = rows.filter( function(row){ return row.idDependencia === id; } );
        cambiarEstatus( dependencia[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const Regresar = () => {
        history.goBack();
    }

    const handleCloseModalDomicilio = () => {
        setOpenModalDomicilio(false);
        setErrors( { } );
    }

    const cambiarEstatus = async ( { idDependencia, descripcion, activo, tipoDependencia } ) => {

        const params = {
            idDependencia,
            idTipoDependencia: tipoDependencia.idTipoDependencia,
            descripcion,
            activo,
        };

        await ActualizarDependencia( params );
    }

    const AgregarRegistro = () => {

        setTipoDependencia( 0 );
        setDescripcion( '' );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion, idTipoDependencia ) => {
        
        setDescripcion( descripcion );
        setTipoDependencia( idTipoDependencia );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const editarDomicilio = (idDependencia) => {
        setOpenModalDomicilio(true);
        setIdReferencia(idDependencia);
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        if( opcion === 'agregar' ){
            const params = {
                idDependencia: 0,
                descripcion: descripcion.trim(),
                idTipoDependencia: tipoDependencia,
                activo: true,
            };

            await AgregarDependencia( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            setRows([ 
                                ...rows,  
                                {
                                    idDependencia: response.data.idDependencia,
                                    idTipoDependencia: response.data.idTipoDependencia,
                                    tipoDependencia: response.data.tipoDependencia,
                                    descripcion: descripcion,
                                    activo: true
                                }
                            ]);  

                            handleCloseModal();
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const dependencia = rows.filter( function(row){ return row.idDependencia === idReferencia; } )[0];

            const params = {
                idDependencia: idReferencia,
                descripcion: descripcion.trim(),
                idTipoDependencia: tipoDependencia,
                activo: dependencia.activo,
            };
    
            await ActualizarDependencia( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idDependencia === idReferencia){ 
                                row.descripcion = descripcion; 
                                row.tipoDependencia = response.data.tipoDependencia;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModal();
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await Dependencias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );

                       
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await TipoDependencias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        SetTipoDependenciaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);
    
    useEffect(() => {        
        
        async function ObtenerDomicilio(){         
            
            if(idReferencia !== ''){
                await ObtenerDomicilioDependencia( idReferencia ).then( response => {

                    if(response){
                        if( response.status === 200){
                            setRowsDomicilio(response.data);
                        }                    
                    }
                });
            }
        }

        ObtenerDomicilio();


    }, [ idReferencia ]);


    return (
        <>
            <AppBarMenu />            

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg">   

                    <Grid container spacing={3} >  

                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                            <Button type="button" startIcon={ <ArrowBackIcon /> } variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                                Regresar
                            </Button> 
                        </Grid>
                       
                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>
                           
                        <Grid item xs={12} md={12}> 

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                        {
                                            desc.length !== 0 ? desc[0].descripcion : ''
                                        }
                                    </Typography>
                                </Toolbar>

                                <TableContainer>
                                    <Table sx={{ minWidth: 500 }} >
                                        <TableHead>
                                            <TableRow>        
                                                <TableCell style={{ width:'10%' }}> ID </TableCell>
                                                <TableCell style={{ width:'30%' }}> DESCRIPCIÓN </TableCell>
                                                <TableCell style={{ width:'20%' }}> TIPO DE DEPENDENCIA </TableCell>
                                                <TableCell style={{ width:'20%' }}> DOMICILIO </TableCell>
                                                <TableCell style={{ width:'5%' }}> HABILITADO </TableCell>
                                                <TableCell style={{ width:'5%' }}> EDITAR </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                        {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <TableRow key={ row.idDependencia} >
                                            
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    { row.idDependencia }
                                                </TableCell>

                                                <TableCell>
                                                    { row.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.tipoDependencia.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    <Button
                                                        color='primary'
                                                        onClick={ ( e ) =>  { editarDomicilio(row.idDependencia); } }
                                                    >
                                                        <HomeIcon />
                                                    </Button>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {
                                                        row.activo 
                                                        ? 
                                                        (
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idDependencia, e.target.checked ) }
                                                            />
                                                        ) 
                                                        :                                           
                                                        ( 
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idDependencia, e.target.checked ) }
                                                            />
                                                        )
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton 
                                                            color="primary" 
                                                            aria-label="edit" 
                                                            component="span" 
                                                            onClick={ () => EditarRegistro( row.idDependencia, row.descripcion, row.tipoDependencia.idTipoDependencia ) }
                                                        >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        { rows.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={6} align="center"> 
                                                No se encontraron resultados
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[ ]}
                                                    colSpan={6}
                                                    count={rows.length}                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            
                            </Paper>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openModal } maxWidth='lg' fullWidth={true}>       
            
                <DialogContent>
                       
                    <FormControl fullWidth sx={{ mt: 3 }} >
                        <InputLabel id="select-tipoDependencia-label">Tipo de dependencia</InputLabel>
                    
                        <Select
                            labelId="select-tipoDependencia-label"
                            id="select-tipoDependencia"                                            
                            label="Tipo de dependencia"
                            name="tipoDependencia"
                            value={ tipoDependencia }                                
                            onChange={ ( e ) => { setTipoDependencia( e.target.value ) } }
                        >
                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                            {
                                tipoDependenciaArray.map( ( elem ) => (
                                    <MenuItem key={ elem.idTipoDependencia } value={ elem.idTipoDependencia }> { elem.descripcion } </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                    <TextField
                        sx={{ mt: 3 }}
                        label={ desc.length !== 0 ? 'Descripción - ' + desc[0].descripcion : '' }
                        variant="outlined"                           
                        multiline
                        rows={4}                                        
                        fullWidth
                        value={ descripcion }
                        onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                    />

                    {   
                        errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }> Cancelar </Button>
                    <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

            </Dialog>

            <Dialog open={ openModalDomicilio } maxWidth='lg' fullWidth={true}>     

                <DialogContent>       

                    <TableContainer>
                        
                        <Table sx={{ minWidth: 500 }} >

                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'30%' }}> DESCRIPCION </TableCell>
                                    <TableCell style={{ width:'5%' }}> NUMERO </TableCell>
                                    <TableCell style={{ width:'20%' }}> ASENTAMIENTO </TableCell>
                                    <TableCell style={{ width:'5%' }}> CODIGO POSTAL </TableCell>
                                    <TableCell style={{ width:'20%' }}> MUNICIPIO </TableCell>
                                    <TableCell style={{ width:'20%' }}> ESTADO </TableCell>
                                    <TableCell style={{ width:'5%' }}> ACTIVO </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>
                            {
                                rowsDomicilio.map((row) => (

                                    <TableRow key={ row.idDomicilioDependencia} >
                                    
                                        <TableCell>
                                            { row.domicilio.descripcion}
                                        </TableCell>

                                        <TableCell>
                                            { row.domicilio.numero }
                                        </TableCell>

                                        <TableCell>
                                            { row.domicilio.asentamiento.descripcion }
                                        </TableCell>

                                        <TableCell>
                                            { row.domicilio.asentamiento.codigoPostal }
                                        </TableCell>

                                        <TableCell>
                                            { row.domicilio.asentamiento.municipio.descripcion }
                                        </TableCell>

                                        <TableCell>
                                            { row.domicilio.asentamiento.estado.descripcion }
                                        </TableCell>

                                        <TableCell>
                                            {
                                                row.activo && <CheckIcon/>

                                            }
                                        </TableCell>
                                        
                                    </TableRow>

                                ))
                            }

                            { 
                                rowsDomicilio.length === 0 && (
                                    <TableRow style={{ height: 53 }}>

                                        <TableCell colSpan={7} align="center">                                         
                                            No se encontraron resultados
                                        </TableCell>

                                    </TableRow>
                                )
                            }
                            </TableBody>

                        </Table>

                    </TableContainer>  
                                 
                </DialogContent>             

                <DialogActions>

                    <Button onClick={ handleCloseModalDomicilio }> Cancelar </Button>

                </DialogActions>

            </Dialog>

        </>
    )
}
