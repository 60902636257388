import React from 'react';

import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';

export const DetalleExhortoLibradoScreen = () => {

    const { acuerdo, exhortoLibrado } = useSelector( state => state.acuerdo );

    return (
        <>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 3 }} >

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={4} md={4} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Número de exhorto:
                        </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { exhortoLibrado?.numeroExhortoLibrado }
                        </Typography>
                    </Grid>
                </Grid>  

                {
                    exhortoLibrado?.autoridadExhorto
                    &&
                    <Grid container sx={{ mt: 1, mb: 1 }}>
                        <Grid item lg={4} md={4} xs={4}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Autoridad del exhorto:
                            </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} xs={8} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhortoLibrado?.autoridadExhorto }
                            </Typography>
                        </Grid>
                    </Grid>   
                }
               
                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={4} md={4} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Objeto del exhorto:
                        </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { exhortoLibrado?.objetoExhorto }
                        </Typography>
                    </Grid>
                </Grid>                          

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={4} md={4} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Clase del exhorto:
                        </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { exhortoLibrado?.claseExhorto }
                        </Typography>
                    </Grid>
                </Grid>     

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Exhorto librado:
                        </Typography>
                    </Grid>
                    
                    <Grid item lg={12} md={12} xs={12}>

                        {
                            acuerdo.exhortoDigitalizado
                            ?                        
                            <iframe 
                                title='iframe  Demanda o Escrito Inicial'
                                style={{ width: '100%', height: '400px' }} 
                                src={ acuerdo.exhortoDigitalizado }                                
                            />
                            :
                            null
                        }

                    </Grid>
                </Grid>        

            </Box>
        </>
    )
}
