import React, { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider,  FormControl,  Grid, IconButton,  InputLabel,  MenuItem,  Paper,  Select,  Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SourceIcon from '@mui/icons-material/Source';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';

import { ConsultaExhortosLibrados, ObtenerExhortoLibradoUsuarios } from '../../actions/tribunal/exhortoLibradoActions';
import { ModalDetalleExhortoLibrado } from '../modals/ModalDetalleExhortoLibrado';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';
import { useSelector } from 'react-redux';

export const ExhortoLibradoScreen = () => {
    
    const { administrador, coordinador } = useSelector( state => state.auth );
    
    const [rows, setRows] = useState( [ ] );

    const [fecha, setFecha] = useState( '' );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages();

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [openModal, setOpenModal] = useState( false );

    const [openDetalleExhorto, setOpenDetalleExhorto] = useState( false );

    const [expediente, setExpediente] = useState( 0 );

    const [exhortoLibrado, setExhortoLibrado] = useState( 0 );

    const [usuarioExhorto, setUsuarioExhorto] = useState( 0 );
    const [usuarioExhortoArray, setUsuarioExhortoArray] = useState( [] );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );


    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setFecha( '' );
        setUsuarioExhorto( '');
        setAutoridad( 0 );
        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();

    }

    const handleBuscarExhortos = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );                

    }

    const handleMostrarInformacion = ( id ) => {

            setExpediente( id );
            setOpenModal( true );
              
    }

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaExhortosLibrados( { page, rowsPerPage, idUsuario: usuarioExhorto === 0 ? '' : usuarioExhorto, fecha, idAutoridad: autoridad } ).then( response => {
                    
                if( response ){
    
                    if( response.status === 200 ){

                        if( response.data ){
                            

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );                                
                                setLoadingSearch( false );
                                setLoading( false );  

                            }, 1000);                            

                        }

                    }

                }

            });

        }     
        
        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, usuarioExhorto, fecha, autoridad ]);   

    useEffect(() => {
        
        async function Obtener(){

            await ObtenerExhortoLibradoUsuarios().then( response => {

                if( response ){

                    if( response.status === 200 ){
    
                        setUsuarioExhortoArray( response.data );
    
                    }
                }

            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);


    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  
                
                <Container maxWidth="xl">
                    
                    <Grid container spacing={3} >

                        <Grid item lg={12} md={12} xs={12}>

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3}>

                                {     
                                    ( administrador || coordinador )
                                     && 
                                    <Grid item md={4} sm={6} xs={12}> 
                                                        
                                        <FormControl fullWidth>
                                            <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                        
                                                <Select
                                                    labelId="select-autoridad-label"
                                                    id="select-autoridad"                                            
                                                    label="Autoridad"
                                                    name="autoridad"
                                                    value={ autoridad }                                
                                                    onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                                            >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        coordinador
                                                        ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                        :
                                                            autoridadArray.map( ( elem ) => (
                                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    }                          
                                                </Select>
            
                                            </FormControl>
                                                                    
                                    </Grid>
                                }    

                                        <Grid item md={4} xs={12} >

                                            <TextField                                       
                                                label="Fecha del exhorto librado"
                                                type="date"                                        
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="fechaExhorto"
                                                value={ fecha }
                                                onChange={ ( e ) => setFecha( e.target.value ) }
                                                disabled={ filtroAplicado }                                                  
                                            />

                                        </Grid> 

                                        <Grid item md={4} xs={12} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-usuarios-label">Usuarios</InputLabel>
                                            
                                                <Select
                                                    labelId="select-usuarios-label"
                                                    id="select-usuarios"                                            
                                                    label="Usuarios"
                                                    name="usuarios"
                                                    value={ usuarioExhorto }                                
                                                    onChange={ ( e ) => setUsuarioExhorto( e.target.value ) }
                                                    disabled={ filtroAplicado }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        usuarioExhortoArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                        ))
                                                    }                                          
                                                </Select>

                                            </FormControl>

                                        </Grid>
 

                                        <Grid item md={2} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                fullWidth 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarExhortos() } }
                                                loading={ loadingSearch }
                                                disabled={ fecha === '' && usuarioExhorto=== 0 && autoridad=== 0 }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>  

                                    </Grid>

                                </CardContent>

                            </Card>

                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ pt: 3, pb: 3 }} variant="h4" >
                                        Exhortos Librados
                                    </Typography>
                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >

                                        <TableHead>

                                            <TableRow>   

                                                <TableCell style={{ width:'10%', fontWeight: 'bold', textAlign: 'center' }}>  </TableCell>  
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> FECHA </TableCell> 
                                                <TableCell style={{ width:'7%', fontWeight: 'bold', textAlign: 'center'  }}> NO. EXHORTO LIBRADO  </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> EXPEDIENTE </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> OBJETO DEL EXHORTO </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> AUTORIDAD DEL EXHORTO </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> ELABORO </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> ESTATUS </TableCell>
                                                <TableCell style={{ width:'5%', fontWeight: 'bold' }}>  </TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                rows.map( ( row, i ) => (

                                                    <TableRow key={ i } >

                                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }} > { row.numeroSeguimiento } </TableCell>                                        
                                                        <TableCell> { row.fechaAutorizacion ? moment( row.fechaAutorizacion ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>
                                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }} > { row.numeroExhorto } </TableCell>
                                                       
                                                        <TableCell> 

                                                            {
                                                                row?.numeroExpediente
                                                                &&
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { handleMostrarInformacion( row?.idReferencia ) } }
                                                                >
                                                                    { row?.numeroExpediente ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell>                                               
                                                        <TableCell> { row.objetoExhorto } </TableCell>    
                                                        <TableCell> { row.autoridadExhorto } </TableCell>     
                                                        <TableCell> { row.usuarioElaboro } </TableCell>               
                                                        <TableCell> 
                                                        
                                                            <strong>{ row.estatus }</strong> <br />
                                                            { 
                                                                (row?.usuarioAsignado && row?.idEstatus !== 47)
                                                                &&  
                                                                    <>
                                                                        Asignado a <strong> {row?.usuarioAsignado } </strong>                                                           
                                                                    </>
                                                                    
                                                            }
                                                        
                                                        </TableCell>   

                                                        <TableCell>                              

                                                            {
                                                                row?.exhortoFirmado
                                                                &&                                                            
                                                                <IconButton
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    size='small'
                                                                    onClick={ () => { setExhortoLibrado( row?.idExhortoLibrado ); setOpenDetalleExhorto( true ); } }
                                                                >
                                                                    <SourceIcon /> 
                                                                </IconButton>
                                                            }

                                                        </TableCell>
                                                       

                                                    </TableRow>

                                                ))
                                            }

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>

                                                        <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron exhortos librados'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TableCell colSpan={2}>
                                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de exhortos librados: { recordsCount } </Typography>
                                                </TableCell> 

                                                <TablePagination
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    colSpan={ 10 }
                                                    count={ recordsCount }                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={ page }                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />

                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Paper>

                        </Grid>

                    </Grid>                   

                </Container>

            </Box>

           
            <ModalDetalleExpediente 
                open={ openModal } 
                setOpen={ setOpenModal } 
                idExpediente={ expediente } 
            />

            <ModalDetalleExhortoLibrado
                open={ openDetalleExhorto} 
                setOpen={ setOpenDetalleExhorto } 
                idExhortoLibrado={ exhortoLibrado} 
            />


        </>
    )
}