import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, TextField, Typography } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { ObtenerTiposAcuerdos } from '../../../actions/admin/catalogs/tipoAcuerdoActions';
import { ObtenerSubTiposAcuerdos } from '../../../actions/admin/catalogs/subTipoAcuerdoActions';
import { ObtenerTipoNotificacion, } from '../../../actions/admin/catalogs/tiponotificacionActions';
import { AgregarPlantilla } from '../../../actions/admin/templates/templateActions';
import { ObtenerAutoridades } from '../../../actions/admin/catalogs/autoridadActions';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const NewTemplateScreen = ( {history} ) => {

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    const [alert, setAlert] = useState({  });

    const [errors, setErrors] = useState({ });

    const [tipoPlantilla, setTipoPlantilla] = useState(0);

    const [tipoAcuerdo, setTipoAcuerdo] = useState(0);
    const [tipoAcuerdoArray, setTipoAcuerdoArray] = useState( [ ] );

    const [subTipoAcuerdo, setSubTipoAcuerdo] = useState(0);
    const [subTipoAcuerdoArray, setSubTipoAcuerdoArray] = useState( [ ] );

    const [plantilla, setPlantilla] = useState( '' );
    
    const [tipoNotificacion, setTipoNotificacion] = useState(0);
    const [tipoNotificacionArray, setTipoNotificacionArray] = useState( [ ] );    

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [ ] );

    const GuardarPlantilla = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            'descripcion': plantilla.trim(),
            'contenido': contenido,
            'idTipoAcuerdo': tipoAcuerdo,
            'idSubTipoAcuerdo': subTipoAcuerdo,
            'idTipoNotificacion': tipoNotificacion,
            'idAutoridad': autoridad,
            'activo': true
        };

        dispatch( { type: types.openLoading } );

        await AgregarPlantilla( params ).then( response => {

            if( response ){
               
                if( response.status === 200){
                    
                    setTimeout(() => {
                        
                        setAlert({
                            open: true,
                            msg: 'Se guardó la plantilla correctamente',
                            type: 'success'
                        }); 
                     
                        dispatch( { type: types.closeLoading } );  

                    }, 1200);  

                    limpiarDatos();

                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        dispatch( { type: types.closeLoading } );  

                    }, 1200);  

                    limpiarDatos();
                }

            }

        });
    }
 
    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};

        const contenido = editorRef.current.getContent();

        if( autoridad === 0 ){
            valid = false;
            errores.autoridad = 'Debes seleccionar una autoridad';
        } 

        if( tipoPlantilla === 0 ){           
            valid = false;
            errores.tipoPlantilla = 'Debes seleccionar un tipo de plantilla';
        } 

        if( plantilla.trim() === '' ){          
            valid = false;
            errores.plantilla = 'Debes escribir el nombre de la plantilla';
        }  
        
        if( tipoAcuerdo === 0 && tipoNotificacion === 0 && tipoPlantilla === 1){            
            valid = false;
            errores.tipoAcuerdo = 'Debes seleccionar un tipo de acuerdo';
        } 

        if( tipoAcuerdo !== 0 && subTipoAcuerdo === 0 && subTipoAcuerdoArray.length > 0){           
            valid = false;
            errores.subTipoAcuerdo = 'Debes seleccionar un sub tipo de acuerdo';
        } 

        if( tipoAcuerdo === 0  &&  tipoNotificacion === 0 && tipoPlantilla === 2){      
            valid = false;
            errores.tipoNotificacion = 'Debes seleccionar un tipo de notificación';
        }   

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        }   
        
        setErrors( errores );
        return valid;
    }

    const Regresar = () => {
        history.push("/Admin/Templates");
    }

    const limpiarDatosPlantilla = ( ) => {

        if (editorRef.current){

            editorRef.current.setContent('');

        }

    }

    const limpiarDatos = () => {

        setErrors( { } );
        setAutoridad(0);
        setTipoAcuerdo(0);
        setSubTipoAcuerdo(0);
        setSubTipoAcuerdoArray([]);
        setTipoNotificacion(0);
        setPlantilla('');
        limpiarDatosPlantilla();

    }

    const handleChangeTipoPlantilla = (tipoPlantilla) => {

        setTipoPlantilla(tipoPlantilla);
        limpiarDatos();
    } 

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    //Obtener Autoridades
    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    //Obtener Tipos de Acuerdo
    useEffect(() => {

        async function Obtener(){    

            await ObtenerTiposAcuerdos().then( response => {

                if(response){

                    if( response.status === 200){
                        setTipoAcuerdoArray( response.data );
                    }

                }

            });

        }

        Obtener();

    }, [ ]);

    //Obtener Sub Tipos de Acuerdo
    useEffect(() => {

        async function Obtener(){   

            setSubTipoAcuerdo(0);
            setSubTipoAcuerdoArray([]);
            limpiarDatosPlantilla();

            if(tipoAcuerdo !== 0){

                await ObtenerSubTiposAcuerdos(tipoAcuerdo).then( response => {

                    if(response){

                        if( response.status === 200){

                            setSubTipoAcuerdoArray( response.data );
                        }

                    }

                });

            }       

        }

        Obtener();

    }, [ tipoAcuerdo ]);

    //Obtener Tipos Notificacion
    useEffect(() => {

        limpiarDatosPlantilla();

        async function Obtener(){  

            await ObtenerTipoNotificacion().then( response => {

                if(response){

                    if( response.status === 200){

                        setTipoNotificacionArray( response.data );

                    }

                }

            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }  
            <AppBarMenu />

            <Box sx={{ marginTop: '8em', minHeight: '100%', py: 3 }}>  
                
                <Container maxWidth="lg" component={ Paper } sx={{ paddingBottom: 3 }}>

                    <Grid container spacing={3} >  

                        <Grid item sm={6} md={9} xs={12}> 
                            <Typography variant='h4'>Agregar nueva plantilla</Typography>
                        </Grid>  

                        <Grid item sm={6} md={3} xs={12} sx={{ textAlign: 'right' }}>  
                            <Button type="button" startIcon={ <ArrowBackIcon /> } fullWidth variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                                Regresar
                            </Button> 
                        </Grid>  

                        <Grid item md={12} xs={12} >                                                    
                            <FormControl fullWidth>
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                        <FormLabel sx={{ color: 'black' }} >Seleccione el tipo de plantilla: </FormLabel>
                                    </Grid>
                                    <Grid item md={8} xs={8} >
                                        <RadioGroup row sx={{ textAlign: 'center' }} >
                                            <Grid container >
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Acuerdo" control={ <Radio checked={ tipoPlantilla === 1 } onChange={ () => { handleChangeTipoPlantilla(1) } } /> } label="Plantilla para acuerdos" />
                                                </Grid>
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Notificacion" control={ <Radio checked={ tipoPlantilla === 2 } onChange={ () => { handleChangeTipoPlantilla(2) } } /> } label="Plantilla para notificaciones" />     
                                                </Grid>                                                            
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            {   
                                errors.tipoPlantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoPlantilla } </Alert> )
                            }
                        </Grid>    

                        {
                            ((tipoPlantilla === 1) || (tipoPlantilla === 2))  && (
                                <>
                                <Grid item md={12} xs={12} >                            
                                    <TextField
                                        fullWidth
                                        label="Nombre de la plantilla"
                                        name="plantilla"   
                                        variant="outlined"
                                        value={ plantilla }
                                        onChange={ ( e ) => { setPlantilla( e.target.value ) } }     
                                        autoComplete="off"                       
                                    />
                                    {   
                                        errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                    }

                                </Grid> 
                                </>
                            )   
                        }         

                        {
                            ( ( tipoPlantilla === 1 ) || ( tipoPlantilla === 2 ) )  
                            && 
                            (
                                <Grid item md={12} xs={12}> 

                                    <FormControl fullWidth >
                                        <InputLabel id="select-autoridad-label">Autoridad</InputLabel>

                                        <Select
                                            labelId="select-autoridad-label"
                                            id="select-autoridad"                                            
                                            label="Autoridad"
                                            name="autoridad"
                                            value={ autoridad }                                
                                            onChange={ ( e ) => { setAutoridad( e.target.value ); } }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>   
                                            {
                                                autoridadArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>
                                                ))
                                            }                       
                                        </Select>

                                    </FormControl>

                                    {   
                                        errors.autoridad && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridad } </Alert> )
                                    }  

                                </Grid>
                            )
                        }

                        {
                            tipoPlantilla === 1 && (
                                <>
                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-tipoAcuerdo-label">Tipo de acuerdo</InputLabel>
                                    
                                        <Select
                                            labelId="select-tipoAcuerdo-label"
                                            id="select-tipoAcuerdo"                                            
                                            label="Tipo de Acuerdo"
                                            name="tipoAcuerdo"
                                            value={ tipoAcuerdo }
                                            onChange={ ( e ) => { setTipoAcuerdo( e.target.value ) } }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                tipoAcuerdoArray.map((elem)=>(
                                                    <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    {   
                                        errors.tipoAcuerdo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoAcuerdo } </Alert> )
                                    }
                                </Grid>
                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-subTipoAcuerdo-label">Subtipo de acuerdo</InputLabel>
                                    
                                        <Select
                                            labelId="select-subTipoAcuerdo-label"
                                            id="select-subTipoAcuerdo"                                            
                                            label="Subtipo de Acuerdo"
                                            name="subTipoAcuerdo"
                                            value={ subTipoAcuerdo }
                                            onChange={( e ) => { setSubTipoAcuerdo( e.target.value ) } }
                                            disabled={ tipoAcuerdo === 0 || subTipoAcuerdoArray.length === 0 }
                                        >
                       
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            subTipoAcuerdoArray.map((elem)=>(
                                                <MenuItem key={elem.idSubTipoAcuerdo} value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </FormControl>
                                    {   
                                        errors.subTipoAcuerdo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.subTipoAcuerdo } </Alert> )
                                    }
                                </Grid> 
                                </>
                            )   
                        }
                        {
                            tipoPlantilla === 2 && (
                                <Grid item md={12} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-tipoNotificacion-label">Tipo de notificación</InputLabel>
                                    
                                        <Select
                                            labelId="select-tipoNotificacion-label"
                                            id="select-tipoNotificacion"                                            
                                            label="Tipo de notificación"
                                            name="tipoNotificacion"
                                            value={ tipoNotificacion }
                                            onChange={ ( e ) => { setTipoNotificacion( e.target.value ) } }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                tipoNotificacionArray.map((elem)=>(
                                                    <MenuItem key={elem.idTipoNotificacion} value={ elem.idTipoNotificacion }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }

                                        </Select>

                                    </FormControl>
                                    {   
                                        errors.tipoNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoNotificacion } </Alert> )
                                    }
                                </Grid>  
                            )
                        }
                        {
                            ((tipoPlantilla === 1) || (tipoPlantilla === 2))  && (
                                <>
                                    <Grid item md={12} xs={12} >  
                                        <Editor
                                            onInit = { (evt, editor) => editorRef.current = editor }
                                            initialValue = ""
                                            apiKey = { ObtenerApiKeyRichText( process.env ) }
                                            init = {
                                                {
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount pagebreak'
                                                    ],
                                                    toolbar: 'undo redo | fontselect fontsizeselect | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                        'removeformat | print pagebreak |  Atajos',
                                                        setup: (editor) => {                                        
                                                            editor.ui.registry.addMenuButton('Atajos', {
                                                                icon: 'bookmark' ,
                                                                tooltip: "Atajos",
                                                                fetch: function (callback) {
                                                                    var items = 
                                                                    [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Expediente]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Oficio]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Irregular]</strong>'); } },                                                                     
                                                                        { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Exhorto]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de cierre',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Cierre]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Amparo]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de origen exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Origen]</strong>'); } },                                                                                
                                                                        { type: 'menuitem', text: 'Número de expedientillo de susp.',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Expedientillo_Suspension]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de cierre de intrucción',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Numero_Cierre_Instruccion]</strong>'); } },                                                                                
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Tipo_Juicio]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Ponencia]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Tipo_Irregular]</strong>'); } },                                                                    
                                                                        { type: 'menuitem', text: 'Autoridad de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Autoridad_Exhorto]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Autoridad_Amparo]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Tipo_Amparo]</strong>'); } },                                                                    
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Actor]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Demandado]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Beneficiario',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Beneficiario]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Extinto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Extinto]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Partes_Amparo]</strong>'); } },            
                                                                        { type: 'menuitem', text: 'Tipo de notificación',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Tipo_Notificacion]</strong>'); } }, 
                                                                        { type: 'menuitem', text: 'Forma de notificación',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Forma_Notificacion]</strong>'); } },                                                                                                                                                        
                                                                        { type: 'menuitem', text: 'Persona a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Persona_Notificar]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Acuerdo_Notificar]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Acuerdo_Notificar]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Domicilio a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Domicilio_Notificar]</strong>'); } },
                                                                    ];
                                                                    callback(items);
                                                                }
                                                            });
                                                        },                                                 
                                                        content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                                }
                                            }  
                                        />
                                    </Grid>                                     
                                    
                                    {   
                                        errors.contenido && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                            </Grid>
                                        )
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                        <Button variant="contained" onClick={ GuardarPlantilla } startIcon={ <SaveIcon /> }> Guardar plantilla</Button>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </Container>
            </Box>     
        </>
    )
}
