import React, { useState, useEffect } from 'react';

import { Box, Button, Container, Dialog, DialogActions,  DialogContent,  Grid, IconButton, InputAdornment, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { DateRangePicker, LoadingButton, LocalizationProvider } from '@mui/lab';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import AppBarMenu from '../../ui/AppBarMenu';

import DateAdapter from '@mui/lab/AdapterMoment';

import moment from 'moment';

import 'moment/locale/es-mx';

import { BuscarListaEstrados } from '../../../actions/tribunal/listaEstradosActions';

export const ConsultarListaEstradosScreen = () => {

    const [dataTable, setDataTable] = useState( [] );

    const [openAcuerdos, setOpenAcuerdos] = useState(false);

    const [openListaAcuerdos, setOpenListaAcuerdos] = useState(false);

    const [detalleAcuerdos, setDetalleAcuerdos] = useState( [ ] );

    const [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages();

    const [ pageAcuerdos, rowsPerPageAcuerdos, handleChangePageAcuerdos, handleChangeRowsPerPageAcuerdos, resetPageAcuerdos ] = usePages();

    const [fechas, setFechas] = useState( [ null, null ] );

    const [documento, setDocumento] = useState( '' );
    const [documentoFirmado, setDocumentoFirmado] = useState( '' );

    const [fechaInicio, fechaFin ] = fechas;

    const [loading, setLoading] = useState( false );
    const [filtroAplicado, setFiltroAplicado] = useState( false );
    const [recordsCount, setRecordsCount] = useState( 0 );

    const HandleBuscarListas = async () => {

        setFiltroAplicado( true );
        resetPage();
       
    }

    const handleCloseDetalleAcuerdos = () => {
        
        setDetalleAcuerdos( [ ] );
        setOpenAcuerdos( false ); 
        resetPageAcuerdos( 0 );
        
    }

    const  handleCloseListaAcuerdos = () => {

        setDocumento( '' );
        setDocumentoFirmado( '' );
        setOpenListaAcuerdos( false );

    }

    const HandleClearFiltro = () => {

        setFiltroAplicado( false );

        setFechas([ null, null ]);

        setDataTable( [ ] );
        setRecordsCount( 0 );

        resetPage();
    }

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setDataTable( [ ] );
    
            await BuscarListaEstrados( { page, rowsPerPage, fechaInicio : moment( fechaInicio ).format('YYYY-MM-DD'), fechaFin : moment( fechaFin ).format('YYYY-MM-DD') } ).then( response => {
    
                if( response ){
             
                    if( response.data ){ 
                        
                        setTimeout(() => {
                        
                            setDataTable( response?.data?.records ?? [] );
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
    
                            setLoading( false );                 
    
                        }, 1000);                    
    
                    } else {
    
                        setLoading( false );
                        
                    }
    
                }
    
            });

        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ filtroAplicado, page, rowsPerPage, fechaInicio, fechaFin ])
    
    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '7em', minHeight: '100%', py: 3 }}>  
                        
                <Container maxWidth="lg" sx={{ mt: 1, pb: 2 }} component={ Paper } >
                        
                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12}>

                            <LocalizationProvider dateAdapter={ DateAdapter }>

                                <DateRangePicker
                                    startText="Fecha inicio"
                                    endText="Fecha fin"
                                    value={ fechas }
                                    disabled={ filtroAplicado }
                                    onChange={ ( value ) => {  setFechas(value); }}
                                    renderInput={ ( startProps, endProps ) => (
                                        <>
                                            <TextField 
                                                {...startProps} 
                                                fullWidth 
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarTodayIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            <Box sx={{ mx: 2 }}> </Box>
                                            
                                            <TextField 
                                                {...endProps} 
                                                fullWidth 
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarTodayIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </>
                                    )}                                    
                                />

                            </LocalizationProvider>
                         
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>

                            <LoadingButton 
                               variant="contained" 
                               color={ filtroAplicado ? "inherit" : "primary" } 
                               startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> } 
                               onClick={ () => filtroAplicado ? HandleClearFiltro() : HandleBuscarListas() } 
                                disabled={ !fechaInicio || !fechaFin }
                                loading={ loading }
                            >
                                { filtroAplicado ? 'Limpiar' : 'Buscar'}
                            </LoadingButton>
                         
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ mt: 2 }} >

                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width: '10%', fontWeight: 'bold' }}> NO. SEGUIMIENTO </TableCell>
                                            <TableCell style={{ width: '10%', fontWeight: 'bold' }}> FECHA </TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}> USUARIO </TableCell>
                                            <TableCell style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}> NO. ACUERDOS </TableCell>                                            
                                            <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}> ACUERDOS </TableCell>                                                                                        
                                            <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}> DOCUMENTO </TableCell>   
                                                                                                                                 
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            dataTable.map( ( row ) => (

                                                <TableRow key={ row.idListaEstrados } >
                                                    
                                                    <TableCell sx={{ fontWeight: 'bold' }}> GLE-{ row.idListaEstrados } </TableCell>  
                                                    <TableCell> { row.fechaGeneracion } </TableCell>                                                            
                                                    <TableCell> { row.usuarioGenero } </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}> 
                                                        { row.numeroAcuerdos } 
                                                    </TableCell>
                                                   
                                                    <TableCell sx={{ textAlign: 'center' }}> 
                                                        
                                                        <IconButton
                                                            onClick={  () => { setOpenAcuerdos(true); setDetalleAcuerdos( row.notificaciones ) } }
                                                            color='primary'
                                                        >
                                                            <FormatListBulletedIcon fontSize='large' /> 
                                                        </IconButton>     

                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}> 

                                                        <IconButton
                                                            onClick={ () => { setOpenListaAcuerdos( true ); setDocumento( row.documentoListaEstrados ); setDocumentoFirmado( row.documentoListaEstradosFirmada ); }  }
                                                            color='primary'
                                                        >
                                                            <ArticleIcon fontSize='large' /> 
                                                        </IconButton>                                                        

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            dataTable.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                    {
                                                        loading
                                                        ?
                                                            'Buscando ....'
                                                        :
                                                            'No se encontraron resultados'
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={2}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                            </TableCell> 

                                            <TablePagination 
                                                colSpan={6}
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                count={ recordsCount }
                                                rowsPerPage={ rowsPerPage }
                                                page={ page }
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }

                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }                                                                                       
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>
                                
                            </TableContainer>

                        </Grid>                         

                    </Grid>

                </Container>    

                <Dialog open={ openAcuerdos } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
                    <DialogContent>
                        
                        <Grid container spacing={3} >                                                
                        
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        
                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width: '10%', fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                            <TableCell style={{ width: '35%', fontWeight: 'bold' }}>ACTOR(ES)</TableCell>
                                            <TableCell style={{ width: '35%', fontWeight: 'bold' }}>DEMANDADO(S)</TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}>SE NOTIFICA A</TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (
                                                rowsPerPageAcuerdos > 0
                                                ?
                                                detalleAcuerdos.slice( pageAcuerdos * rowsPerPageAcuerdos, pageAcuerdos * rowsPerPageAcuerdos + rowsPerPageAcuerdos )
                                                :
                                                detalleAcuerdos
                                            ).map( ( row ) => (

                                                <TableRow key={ row.id } >
                                                    
                                                    <TableCell> { row.referencia } </TableCell>                                                            
                                                    
                                                    <TableCell> 

                                                        <List disablePadding>
                                                            {
                                                                row?.actores?.map( ( elem, i ) =>(
                                                                    <ListItem disableGutters key={ i }>
                                                                        <Typography variant='subtitle2'>{ elem }</Typography>
                                                                    </ListItem>
                                                                ))
                                                            }                                                                
                                                        </List>

                                                    </TableCell>
                                                    
                                                    <TableCell> 
                                                        <List disablePadding>
                                                            {
                                                                row?.demandados?.map( ( elem, i ) =>(
                                                                    <ListItem disableGutters key={ i }>
                                                                        <Typography variant='subtitle2'>{ elem }</Typography>
                                                                    </ListItem>
                                                                ))
                                                            }                                                                
                                                        </List>
                                                    </TableCell>

                                                    <TableCell> { row.tipoParte.toUpperCase() } </TableCell>                                                    

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            detalleAcuerdos.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        No se encontraron resultados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>
                                            <TablePagination 
                                                colSpan={5}
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                count={ detalleAcuerdos.length }
                                                rowsPerPage={ rowsPerPageAcuerdos }
                                                page={ pageAcuerdos }
                                                onPageChange={ handleChangePageAcuerdos }
                                                onRowsPerPageChange={ handleChangeRowsPerPageAcuerdos }

                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }                                                                                       
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>

                                </TableContainer>

                            </Grid>
                            
                        </Grid>
                        
                    </DialogContent>

                    <DialogActions>

                        <Button color="primary" onClick={ handleCloseDetalleAcuerdos }>
                            Cerrar
                        </Button>

                    </DialogActions>                

                </Dialog>   

                <Dialog open={ openListaAcuerdos } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
                    <DialogContent>
                        
                        <Grid container spacing={3} >                                                
                        
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        
                                {
                                    documento || documentoFirmado
                                    ?
                                        documentoFirmado
                                        ?
                                        <iframe title='lista' src={ documentoFirmado } height="500px" width="100%"></iframe>
                                        :
                                        <iframe title='lista' src={ "data:application/pdf;base64," + documento } height="500px" width="100%"></iframe>
                                    :
                                    null
                                }

                            </Grid>
                            
                        </Grid>
                        
                    </DialogContent>

                    <DialogActions>

                        <Button color="primary" onClick={ handleCloseListaAcuerdos  }>
                            Cerrar
                        </Button>

                    </DialogActions>                

                </Dialog>          
              
            </Box>
            
        </>
    )
}
