import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Card, CardContent, CardHeader, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ObtenerObjetoExhortos } from '../../../actions/admin/catalogs/objetoExhortoActions';
import { ObtenerClaseExhortos } from '../../../actions/admin/catalogs/claseExhortoActions';

import { types } from '../../../types/types';

import moment from 'moment';

import { ActualizarInformacion } from '../../../actions/recepcion/exhortoRecibidoActions';
import { ActualizarInformacionExhortoLibrado } from '../../../actions/tribunal/exhortoLibradoActions';
import { ActualizarInformacionAmparo } from '../../../actions/recepcion/amparoActions';
import { ObtenerDistritoJudicial } from '../../../actions/admin/catalogs/distritoJudicialActions';
import { ObtenerAutoridadExhortoLibradoDistrito } from '../../../actions/admin/catalogs/autoridadExhortoLibradoActions';

export const CalificarTramiteScreen = ( { edit } ) => {

    const { juzgado } = useSelector( state => state.auth );
    const { acuerdo, exhortoRecibido, exhortoLibrado, amparo } = useSelector( state => state.acuerdo );

    const dispatch = useDispatch();

    const [objetoExhorto, setObjetoExhorto] = useState(0);
    const [objetoExhortoArray, setObjetoExhortoArray] = useState( [ ] );

    const [claseExhorto, setClaseExhorto] = useState(0);
    const [claseExhortoArray, setClaseExhortoArray] = useState( [ ] ) ;

    const [alert, setAlert] = useState( {  } );
    const [errors, setErrors] = useState( { } );

    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const [fechaDiligencia, setFechaDiligencia] = useState( '' );
    const [fechaDevolucion, setFechaDevolucion] = useState( '' );    

    const [fechaInformePrevio, setFechaInformePrevio] = useState( '' );
    const [fechaInformeJustificado, setFechaInformeJustificado] = useState( '' );
    const [fechaInterponeRecurso, setFechaInterponeRecurso] = useState( '' );
    const [fechaResolucionAmparo, setFechaResolucionAmparo] = useState( '' );

    const [distritoJudicial, setDistritoJudicial] = useState(0);
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [ ] );

    const [autoridadExhortoLibrado, setAutoridadExhortoLibrado] = useState(0);
    const [autoridadExhortoLibradoArray, setAutoridadExhortoLibradoArray] = useState( [ ] );

    const [envio, setEnvio] = useState( true );

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const validateFielsExhortoRecibido = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};   

        if( objetoExhorto === 0 ){
            valid = false;
            errores.objetoExhorto = 'Debes seleccionar el objeto';
        } 

        if( claseExhorto === 0  ){      
            valid = false;
            errores.claseExhorto = 'Debes seleccionar la clase';
        }  

        setErrors( errores );
        return valid;
    }

    const validateFielsExhortoLibrado = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};   

        if( autoridadExhortoLibrado === 0 ){
            valid = false;
            errores.autoridadExhortoLibrado = 'Debes seleccionar una autoridad del exhorto';
        } 

        if( fechaDevolucion === '' ){      
            valid = false;
            errores.fechaDevolucion = 'Debes seleccionar la fecha de devolución';
        }  

        setErrors( errores );
        return valid;
    }

    const GuardarDatosExhortoRecibido = async () => {

        const valid = validateFielsExhortoRecibido();
       
        if( !valid ){
            return false;
        }

        setLoadingGuardar( true );

        const params = {
            idExhortoRecibido: exhortoRecibido?.idExhortoRecibido ?? 0,
            idObjetoExhorto: objetoExhorto,
            idClaseExhorto: claseExhorto,
            fechaDiligencia: fechaDiligencia ? moment( new Date( fechaDiligencia ) ).format("YYYY-MM-DDTHH:mm") : null
        };

        await ActualizarInformacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {

                        dispatch({ type: types.actualizarCheckCalificar });
            
                        dispatch({ 
                            type: types.actualizarExhortoRecibidoAcuerdo, 
                            payload:{
                                ...exhortoRecibido,
                                idObjetoExhorto: objetoExhorto,
                                idClaseExhorto: claseExhorto,

                                fechaDiligencia: fechaDiligencia,

                            }
                        });
            
                        setAlert({
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        }); 
                    
                        setLoadingGuardar( false ); 
            
                    }, 1000);

                }
                else{

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  

                }
            }

        });        

    }

    const GuardarDatosExhortoLibrado = async () => {

        const valid = validateFielsExhortoLibrado();
       
        if( !valid ){
            return false;
        }

        setLoadingGuardar( true );

        const params = {
            idExhortoLibrado: exhortoLibrado?.idExhortoLibrado ?? 0,
            fechaDiligencia: fechaDiligencia ? moment( new Date( fechaDiligencia ) ).format("YYYY-MM-DDTHH:mm") : null,
            fechaDevolucion: moment( new Date( fechaDevolucion ) ).format("YYYY-MM-DDTHH:mm"),
            idDistritoJudicial: distritoJudicial,
            idAutoridadExhortoLibrado: autoridadExhortoLibrado
        };

        await ActualizarInformacionExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {

                        dispatch({ type: types.actualizarCheckCalificar });
            
                        dispatch({ 
                            type: types.actualizarExhortoLibradoAcuerdo, 
                            payload:{
                                ...exhortoLibrado,
                                fechaDiligencia: fechaDiligencia,
                                fechaDevolucion: fechaDevolucion,
                                idDistritoJudicial: distritoJudicial,
                                idAutoridadExhortoLibrado: autoridadExhortoLibrado
                            }
                        });
            
                        setAlert({
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        }); 
                    
                        setLoadingGuardar( false ); 
            
                    }, 1000);

                }
                else{

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  

                }
            }

        });        

    }

    const GuardarDatosAmparo = async () => {
      
        setLoadingGuardar( true );

        const params = {
            idAmparo: amparo?.idAmparo ?? 0,
            fechaInformePrevio: fechaInformePrevio ? moment( new Date( fechaInformePrevio ) ).format("YYYY-MM-DDTHH:mm") : null,
            fechaInformeJustificado:  fechaInformeJustificado ? moment( new Date( fechaInformeJustificado ) ).format("YYYY-MM-DDTHH:mm") : null,
            fechaInterponeRecurso:  fechaInterponeRecurso ? moment( new Date( fechaInterponeRecurso ) ).format("YYYY-MM-DDTHH:mm") : null,
            fechaResolucionAmparo:  fechaResolucionAmparo ? moment( new Date( fechaResolucionAmparo ) ).format("YYYY-MM-DDTHH:mm") : null,
        };

        await ActualizarInformacionAmparo( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {

                        dispatch({ type: types.actualizarCheckCalificar });
            
                        dispatch({ 
                            type: types.actualizarAmparoAcuerdo, 
                            payload:{
                                ...amparo,
                                fechaInformePrevio: fechaInformePrevio,
                                fechaInformeJustificado: fechaInformeJustificado,
                                fechaInterponeRecurso: fechaInterponeRecurso,
                                fechaResolucionAmparo: fechaResolucionAmparo,
                            }
                        });
            
                        setAlert({
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        }); 
                    
                        setLoadingGuardar( false ); 
            
                    }, 1000);

                }
                else{

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  

                }
            }

        });        

    }

    useEffect(() => {      
        
        async function Obtener(){

            await ObtenerObjetoExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setObjetoExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      

        async function Obtener(){

            await ObtenerClaseExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setClaseExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerDistritoJudicial().then( response => {

                if( response ){

                    if( response.data ){
                        setDistritoJudicialArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            setAutoridadExhortoLibrado( 0 );
            setAutoridadExhortoLibradoArray( [ ] );

            if( envio && distritoJudicial !== 0 ){
                await ObtenerAutoridadExhortoLibradoDistrito( distritoJudicial ).then( response => {

                    if( response ){
    
                        if( response.data ){
                            setAutoridadExhortoLibradoArray( response.data );
                        }
    
                    }    
    
                });

            }
            else if( !envio ){
                await ObtenerAutoridadExhortoLibradoDistrito( 0 ).then( response => {

                    if( response ){

                        if( response.data ){
                            setAutoridadExhortoLibradoArray( response.data );
                        }

                    }


                });

            }

        }     
        
        Obtener();

    }, [ distritoJudicial, envio ])

    useEffect(() => {     

        if( exhortoRecibido?.success && objetoExhortoArray.length !== 0 ){

            setObjetoExhorto( exhortoRecibido?.idObjetoExhorto ?? 0);
        }

    }, [ exhortoRecibido, objetoExhortoArray ])

    useEffect(() => {     

        if( exhortoRecibido?.success && claseExhortoArray.length !== 0 ){

            setClaseExhorto( exhortoRecibido?.idClaseExhorto ?? 0);
        }

    }, [ exhortoRecibido, claseExhortoArray ])



    useEffect(() => {          
       

        if( exhortoRecibido?.success ){
            setFechaDiligencia( exhortoRecibido?.fechaDiligencia ? moment( new Date( exhortoRecibido?.fechaDiligencia) ).format("YYYY-MM-DDTHH:mm") : '' );            
        }

    }, [ exhortoRecibido ])







    useEffect(() => {     

        if( exhortoLibrado?.success ){

            setFechaDiligencia( exhortoLibrado?.fechaDiligencia ? moment( new Date( exhortoLibrado?.fechaDiligencia) ).format("YYYY-MM-DDTHH:mm") : '' );
            setFechaDevolucion(  exhortoLibrado?.fechaDevolucion ? moment( new Date( exhortoLibrado?.fechaDevolucion) ).format("YYYY-MM-DDTHH:mm") : '' );
            setEnvio( exhortoLibrado?.idDistritoJudicial !== 0 );
        }

    }, [ exhortoLibrado ])

    useEffect(() => {     

        if( exhortoLibrado?.success && distritoJudicialArray.length !== 0 ){

            setDistritoJudicial(  exhortoLibrado?.idDistritoJudicial ?? 0 );
        }

    }, [ exhortoLibrado, distritoJudicialArray ])

    useEffect(() => {     

        if( exhortoLibrado?.success && distritoJudicial !== 0 && autoridadExhortoLibradoArray.length !== 0 ){
            setAutoridadExhortoLibrado(  exhortoLibrado?.idAutoridadExhortoLibrado ?? 0 );
        }

    }, [ exhortoLibrado, distritoJudicial, autoridadExhortoLibradoArray ])

    useEffect(() => {     

        if( amparo?.success ){

            setFechaInformePrevio( amparo?.fechaInformePrevio ? moment( new Date( amparo?.fechaInformePrevio) ).format("YYYY-MM-DDTHH:mm") : '' );
            setFechaInformeJustificado(  amparo?.fechaInformeJustificado ? moment( new Date( amparo?.fechaInformeJustificado) ).format("YYYY-MM-DDTHH:mm") : '' );
            setFechaInterponeRecurso(  amparo?.fechaInterponeRecurso ? moment( new Date( amparo?.fechaInterponeRecurso) ).format("YYYY-MM-DDTHH:mm") : '' );
            setFechaResolucionAmparo(  amparo?.fechaResolucionAmparo ? moment( new Date( amparo?.fechaResolucionAmparo) ).format("YYYY-MM-DDTHH:mm") : '' );
        }

    }, [ amparo ])    

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Grid container spacing={3}>

                {
                    ( acuerdo.idExhortoRecibido !== 0 && acuerdo.idExhortoLibrado === 0)
                    ?                    
                        <Grid item md={12} xs={12} >

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Datos del exhorto recibido </Typography> }/>
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3} sx={{ mt: 1 }}>
                                       
                                        
                                        <Grid item md={6} xs={12} > 

                                            <FormControl fullWidth>
                                                <InputLabel id="select-claseExhorto-label">Clase del exhorto</InputLabel>
                                            
                                                <Select
                                                    labelId="select-claseExhorto-label"
                                                    id="select-claseExhorto"                                            
                                                    label="Clase del exhorto"
                                                    name="claseExhorto"
                                                    value={ claseExhorto }
                                                    onChange={(e) => { setClaseExhorto(e.target.value)}  }                                                    
                                                    disabled={ !edit || acuerdo?.idPromocion !== 0 }                                                    
                                                >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        claseExhortoArray.map((elem)=>(
                                                            <MenuItem key={elem.idClaseExhorto} value={ elem.idClaseExhorto }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                                {   
                                                    errors.claseExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.claseExhorto } </Alert> )
                                                }
                                            </FormControl>

                                        </Grid>  

                                        <Grid item md={6} xs={12} >    

                                            <FormControl fullWidth>
                                                <InputLabel id="select-objetoExhorto-label">Objeto del exhorto</InputLabel>
                                            
                                                <Select
                                                    labelId="select-objetoExhorto-label"
                                                    id="select-objetoExhorto"                                            
                                                    label="Objeto del exhorto"
                                                    name="objetoExhorto"
                                                    value={ objetoExhorto }
                                                    onChange={(e) => {setObjetoExhorto(e.target.value)}  }                                                    
                                                    disabled={ !edit || acuerdo?.idPromocion !== 0 }                                                    
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        objetoExhortoArray.map((elem)=>(
                                                            <MenuItem key={elem.idObjetoExhorto} value={ elem.idObjetoExhorto }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {   
                                                    errors.objetoExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.objetoExhorto } </Alert> )
                                                }
                                            </FormControl>

                                        </Grid>  

                                        {
                                            acuerdo?.idPromocion !== 0
                                            &&
                                                <Grid item md={6} xs={12} >
                                                    <TextField                 
                                                        label="Fecha diligencia"
                                                        type="datetime-local"           
                                                        fullWidth
                                                        value={ fechaDiligencia } 
                                                        onChange={ ( e ) => { setFechaDiligencia( e.target.value ); } }
                                                        inputProps={{
                                                            max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                        }}                             

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}           
                                                        disabled={ !edit  }                                                         
                                                    />

                                                    {  
                                                        errors.fechaDiligencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaDiligencia } </Alert> )
                                                    }
                                                </Grid>

                                        }
                                        
                        

                                        {
                                            edit
                                            &&                                        
                                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                                
                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ GuardarDatosExhortoRecibido } 
                                                    endIcon={ <CheckCircleIcon /> }
                                                    disabled={ objetoExhorto === 0 || claseExhorto === 0 }
                                                    loading={ loadingGuardar }
                                                > 
                                                    Guardar información 
                                                </LoadingButton>

                                            </Grid>    
                                        }
                                       

                                    </Grid>

                                </CardContent>

                            </Card> 

                        </Grid>
                    :
                    null
                }

                {
                    ( acuerdo.idExhortoLibrado !== 0 )
                    ?                    
                        <Grid item md={12} xs={12} >

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Datos del exhorto librado </Typography> }/>
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3} sx={{ mt: 1 }}>

                                        <Grid item md={8} xs={12} >
                                            <FormControl component="fieldset" disabled={ !edit } >
                                                <FormLabel component="legend">¿El exhorto librado va dirigido a un juzgado del poder judicial del estado?</FormLabel>
                                                <RadioGroup row
                                                    aria-label="envioaudiencia"
                                                    defaultValue="no"
                                                    name="radio-buttons-group"  
                                                >   
                                                    <FormControlLabel value="no" control={ <Radio checked={ !envio } onChange={ () => { setEnvio( false ); setAutoridadExhortoLibrado( 0 ); setDistritoJudicial( 0 ); } } />} label="No" />
                                                    <FormControlLabel value="si" control={ <Radio checked={ envio }  onChange={ () => { setEnvio( true ); setAutoridadExhortoLibrado( 0 ); setDistritoJudicial( 0 ); } }/>} label="Si" />                                    
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                        <Grid item md={4} xs={12} >

                                            {
                                                envio
                                                &&     
                                                <FormControl fullWidth>
                                                <InputLabel id="select-distritoJudicial-label"> Distrito Judicial </InputLabel>
                                            
                                                    <Select
                                                        labelId="select-distritoJudicial-label"
                                                        id="select-distritoJudicial"                                            
                                                        label="Distrito Judicial"
                                                        name="distritoJudicial"
                                                        value={ distritoJudicial }
                                                        onChange={ (e) => {setDistritoJudicial(e.target.value)} }
                                                        disabled={ !edit } 
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                        {
                                                            distritoJudicialArray.map((elem)=>(
                                                                <MenuItem key={elem.idDistritoJudicial} value={ elem.idDistritoJudicial }>{ elem.descripcion }</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    {   
                                                        errors.distritoJudicial && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.distritoJudicial } </Alert> )
                                                    }
                                                </FormControl>
                                            }

                                        </Grid>

                                        <Grid item md={12} xs={12} >

                                            <FormControl fullWidth>
                                            <InputLabel id="select-autoridad-label"> Autoridad del exhorto </InputLabel>
                                        
                                                <Select
                                                    labelId="select-autoridad-label"
                                                    id="select-autoridad"                                            
                                                    label="Autoridad del exhorto"
                                                    name="Autoridad"
                                                    value={ autoridadExhortoLibrado }
                                                    onChange={ (e) => { setAutoridadExhortoLibrado(e.target.value)} }
                                                    disabled={ !edit } 
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        autoridadExhortoLibradoArray.map((elem)=>(
                                                            <MenuItem key={elem.idAutoridadExhortoLibrado} value={ elem.idAutoridadExhortoLibrado }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {   
                                                    errors.autoridadExhortoLibrado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridadExhortoLibrado } </Alert> )
                                                }
                                            </FormControl>

                                        </Grid>
                                        
                                        <Grid item md={6} xs={12} >
                                            <TextField                              
                                                label="Fecha diligencia"
                                                type="datetime-local"              
                                                fullWidth
                                                value={ fechaDiligencia }    
                                                onChange={ ( e ) => { setFechaDiligencia( e.target.value ); } } 
                                                inputProps={{
                                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                }}                             
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}             
                                                disabled={ !edit } 
                                            />

                                            {   
                                                errors.fechaDiligencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaDiligencia } </Alert> )
                                            }
                                        </Grid>

                                        <Grid item md={6} xs={12} >
                                            <TextField                              
                                                label="Fecha devolución"
                                                type="datetime-local"              
                                                fullWidth
                                                value={ fechaDevolucion }    
                                                onChange={ ( e ) => { setFechaDevolucion( e.target.value ); } } 
                                                inputProps={{
                                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                }}                             
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}              
                                                disabled={ !edit } 
                                            />

                                            {   
                                                errors.fechaDevolucion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaDevolucion } </Alert> )
                                            }
                                        </Grid>

                                        {
                                            edit
                                            &&                                        
                                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                                
                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ GuardarDatosExhortoLibrado } 
                                                    endIcon={ <CheckCircleIcon /> }
                                                    disabled={ fechaDevolucion === '' || autoridadExhortoLibrado === 0 }
                                                    loading={ loadingGuardar }
                                                > 
                                                    Guardar información 
                                                </LoadingButton>

                                            </Grid>    
                                        }

                                    </Grid>

                                </CardContent>

                            </Card> 

                        </Grid>
                    :
                    null
                }

                {
                    acuerdo.idAmparo !== 0
                    ? 
                        <Grid item md={12} xs={12} >

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Datos del amparo </Typography> }/>
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3} sx={{ mt: 1 }}>   

                                        {
                                            ( amparo?.idTipoAmparo === 1 && acuerdo?.idPromocion === 0 )
                                            &&
                                            <Grid item md={12} xs={12} >
                                                <TextField                              
                                                    label="Fecha informe previó"
                                                    type="datetime-local"              
                                                    fullWidth
                                                    value={ fechaInformePrevio }    
                                                    onChange={ ( e ) => { setFechaInformePrevio( e.target.value ); } } 
                                                    inputProps={{
                                                        max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                    }}                             
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}             
                                                    disabled={ !edit } 
                                                />

                                                {   
                                                    errors.fechaInformePrevio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaInformePrevio } </Alert> )
                                                }
                                            </Grid>
                                        }

                                        {
                                            ( amparo?.idTipoAmparo === 2 && acuerdo?.idPromocion === 0 )
                                            &&
                                            <Grid item md={12} xs={12} >
                                                <TextField                              
                                                    label="Fecha informe justificado"
                                                    type="datetime-local"              
                                                    fullWidth
                                                    value={ fechaInformeJustificado }    
                                                    onChange={ ( e ) => { setFechaInformeJustificado( e.target.value ); } } 
                                                    inputProps={{
                                                        max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                    }}                             
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}             
                                                    disabled={ !edit } 
                                                />

                                                {   
                                                    errors.fechaInformeJustificado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaInformeJustificado } </Alert> )
                                                }
                                            </Grid>
                                        }

                                        {
                                            juzgado &&                                        
                                            <Grid item md={12} xs={12} >
                                                <TextField                              
                                                    label="Fecha interpone recurso"
                                                    type="datetime-local"              
                                                    fullWidth
                                                    value={ fechaInterponeRecurso }    
                                                    onChange={ ( e ) => { setFechaInterponeRecurso( e.target.value ); } } 
                                                    inputProps={{
                                                        max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                    }}                             
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}             
                                                    disabled={ !edit } 
                                                />

                                                {   
                                                    errors.fechaInterponeRecurso && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaInterponeRecurso } </Alert> )
                                                }
                                            </Grid>
                                        }

                                        {
                                            ( amparo?.success && acuerdo?.idPromocion !== 0 )
                                            &&
                                            <Grid item md={12} xs={12} >
                                                <TextField                              
                                                    label="Fecha de resolución del amparo"
                                                    type="datetime-local"              
                                                    fullWidth
                                                    value={ fechaResolucionAmparo }    
                                                    onChange={ ( e ) => { setFechaResolucionAmparo( e.target.value ); } } 
                                                    inputProps={{
                                                        max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                    }}                             
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}             
                                                    disabled={ !edit } 
                                                />

                                                {   
                                                    errors.fechaResolucionAmparo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaResolucionAmparo } </Alert> )
                                                }
                                            </Grid>
                                        }

                                        {
                                            edit
                                            &&                                        
                                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                                
                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ GuardarDatosAmparo } 
                                                    endIcon={ <CheckCircleIcon /> }
                                                    loading={ loadingGuardar }
                                                    disabled={
                                                        
                                                        ( amparo?.idTipoAmparo === 1 && acuerdo?.idPromocion === 0 && fechaInformePrevio === '' ) ||
                                                        ( amparo?.idTipoAmparo === 2 && acuerdo?.idPromocion === 0 && fechaInformeJustificado === '' ) ||
                                                        ( amparo?.success && acuerdo?.idPromocion !== 0 && fechaResolucionAmparo === '' )

                                                    }
                                                > 
                                                    Guardar información 
                                                </LoadingButton>

                                            </Grid>    
                                        }
                                    
                                    </Grid>

                                </CardContent>

                            </Card> 

                        </Grid>  
                    :
                    null
                }              

            </Grid>
        </>
    )
}
