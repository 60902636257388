import React, { useEffect, useRef } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { Editor } from '@tinymce/tinymce-react'
import { ObtenerApiKeyRichText } from '../../helpers/ObtenerApiKeyRichText';

export const ModalViewProyectoSentencia = ({ open, setOpen, obs }) => {

    const editorRef = useRef(null);    

    useEffect(() => {
      
        if( open ){
            editorRef.current = obs?.contenido ?? '';
        }

    }, [ open, obs ])    

    return (
        <Dialog open={ open } onClose={ () => {} } maxWidth={'lg'} >
                        
            <DialogTitle>
                Observacion
            </DialogTitle>
            
            <DialogContent>

                <Typography variant='h6' sx={{ mb: 3 }}> { obs.observacion } </Typography>
            
                <Editor
                    onInit = { (evt, editor) => editorRef.current = editor }
                    initialValue = { obs?.contenido ?? '' }
                    apiKey = { ObtenerApiKeyRichText( process.env ) }
                    init = {
                        {
                            height: 600,
                            width: 900,
                            menubar: false,
                            plugins: [],                                                                                   
                            content_style: 'body { font-family:Arial; font-size:12pt; margin: 1rem auto; max-width: 800px; }', 
                        }
                    }  
                />
            
            </DialogContent>

            <DialogActions>
                <Button onClick={ () => setOpen( false ) }> Cerrar </Button>
            </DialogActions>

        </Dialog>
    )
}
