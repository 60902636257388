import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import AppBarMenu from '../../ui/AppBarMenu';

import { ObtenerTipoDocumentoFirmas } from '../../../actions/admin/catalogs/tipoDocumentoFirmaActions';
import { ObtenerTipoOperacionFirmas } from '../../../actions/admin/catalogs/tipoOperacionFirmaActions';

import { BuscarDocumento, CancelarDocumento } from '../../../actions/admin/administrarDocumentos/administrarDocumentosActions';

export const AdministrarDocumentosScreen = () => {  

    const [loading, setloading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );
    const [busquedaAplicada, setBusquedaAplicada] = useState( false );

    const [alert, setAlert] = useState({  });
    const [errors, setErrors] = useState({  });

    const [numeroSeguimiento, setNumeroSeguimiento] = useState( '' );
    const [referencia, setReferencia] = useState( '' );

    const [tipoDocumento, setTipoDocumento] = useState( 0 );
    const [tipoDocumentoArray, setTipoDocumentoArray] = useState( [] );

    const [tipoOperacion, setTipoOperacion] = useState( 0 );
    const [tipoOperacionArray, setTipoOperacionArray] = useState( [] );

    const [documento, setDocumento] = useState( {} );
    const [filtroAplicado, setFiltroAplicado] = useState( false );   

    const [openConfirmacion, setOpenConfirmacion] = useState( false );   

    const validateFields = () => {

        setErrors( { } );

        let valid = true;
        let errores = {};

        if( tipoDocumento === 0 ){
            valid = false;
            errores.type = 'warning';
            errores.tipoDocumento = 'Selecciona el tipo de documento';
        } 

        if( tipoOperacion === 0 ){
            valid = false;
            errores.type = 'warning';
            errores.tipoOperacion = 'Selecciona el tipo de operacion';
        } 

        if( numeroSeguimiento === '' ){
            valid = false;
            errores.type = 'warning';
            errores.numeroSeguimiento = 'Escribe el número de seguimiento';
        } 

        setErrors( errores );
        return valid;
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleBuscar = async () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }

        setLoadingSearch( true );
        setFiltroAplicado( true );
        setDocumento( {} );

        const params = {
            numeroSeguimiento, 
            idTipoDocumento: tipoDocumento, 
            idTipoOperacion: tipoOperacion
        };

        await BuscarDocumento( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setReferencia( response?.data?.id ?? '' );
                            setDocumento( response?.data ?? {} );
                            setLoadingSearch( false );
                            setBusquedaAplicada( true );
                            
                        }, 700);

                    }
                    else{

                        setTimeout(() => {
                        
                            setLoadingSearch( false );
                            setBusquedaAplicada( true );

                        }, 700);
                        
                    }

                }
            }

        });

    }

    const handleGuardar = async () => {

        setloading( true );

        const params = {
            numeroSeguimiento, 
            id: referencia
        };

        await CancelarDocumento( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setloading( false );
                            setOpenConfirmacion( false );

                            setAlert({
                                open: true,
                                msg: message,
                                type: 'success'
                            });
                            
                        }, 500);

                    }
                    else{

                        setTimeout(() => {

                            setloading( false );
                            setOpenConfirmacion( false );

                            setAlert({
                                open: true,
                                msg: message,
                                type: 'error'
                            });
                            
                        }, 500);

                    }

                }
            }

        });

    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setNumeroSeguimiento( '' );
        setTipoDocumento( 0 );
        setTipoOperacion( 0 );
        
        setBusquedaAplicada( false );
        setDocumento( {} ); 

    }

    useEffect(() => {
        
        async function Obtener(){

            await ObtenerTipoDocumentoFirmas().then( response => {

                if( response ){

                    if( response.status === 200 ){
    
                        setTipoDocumentoArray( response.data );
    
                    }
                }

            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){

            await ObtenerTipoOperacionFirmas().then( response => {

                if( response ){

                    if( response.status === 200 ){
    
                        setTipoOperacionArray( response.data );
    
                    }
                }

            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  
                        
                <Container maxWidth="lg" sx={{ mt: 1, pb: 2 }} >
                        
                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12}>

                            <Card>

                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Criterios de búsqueda </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3} >
                                      
                                        <Grid item md={4} xs={6} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-tipoDocumento-label">Tipo de documento</InputLabel>
                                            
                                                <Select
                                                    labelId="select-tipoDocumento-label"
                                                    id="select-tipoDocumento"                                            
                                                    label="Tipo de documento"
                                                    name="tipoDocumento"
                                                    value={ tipoDocumento }                                
                                                    onChange={ (e) => setTipoDocumento( e.target.value ) }
                                                    disabled={ filtroAplicado }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>         
                                                    {
                                                        tipoDocumentoArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idTipoDocumentoFirma } value={ elem.idTipoDocumentoFirma }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    }                                       
                                                </Select>

                                            </FormControl>

                                            {   
                                                errors.tipoDocumento && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.tipoDocumento  } </Alert> )
                                            }

                                        </Grid>

                                        <Grid item md={4} xs={6} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-tipoOperacion-label">Tipo operación</InputLabel>

                                                <Select
                                                    labelId="select-tipoOperacion-label"
                                                    id="select-tipoOperacion"                                            
                                                    label="Tipo operación"
                                                    name="tipoOperacion"
                                                    value={ tipoOperacion }                                
                                                    onChange={ (e) => setTipoOperacion( e.target.value ) }
                                                    disabled={ filtroAplicado }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>    
                                                    {
                                                        tipoOperacionArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idTipoOperacionFirma } value={ elem.idTipoOperacionFirma }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    }                                            
                                                </Select>

                                            </FormControl>

                                            {   
                                                errors.tipoOperacion && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.tipoOperacion  } </Alert> )
                                            }

                                        </Grid>

                                        {
                                            ( tipoOperacion === 1 || tipoOperacion === 2 )
                                            &&
                                            <Grid item md={4} xs={6} >

                                                <FormControl fullWidth>
                                                    <InputLabel id="select-usuarioFirmante-label">Usuario Firmante</InputLabel>

                                                    <Select
                                                        labelId="select-usuarioFirmante-label"
                                                        id="select-usuarioFirmante"                                            
                                                        label="Usuario Firmante"
                                                        name="usuarioFirmante"
                                                        value={ 0 }                                
                                                        onChange={ () => {} }
                                                        disabled={ filtroAplicado }
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                                               
                                                    </Select>

                                                </FormControl>

                                                {   
                                                    errors.firmante && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.firmante  } </Alert> )
                                                }

                                            </Grid>
                                        }

                                        <Grid item md={4} xs={12} >

                                            <TextField
                                                fullWidth
                                                label="Número de seguimiento"
                                                name="noSeguimiento"
                                                variant="outlined"
                                                autoComplete='off'
                                                value={ numeroSeguimiento }                                
                                                onChange={ (e) => setNumeroSeguimiento( e.target.value.toUpperCase() ) }
                                                disabled={ filtroAplicado }
                                            /> 

                                            {   
                                                errors.numeroSeguimiento && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroSeguimiento  } </Alert> )
                                            }

                                        </Grid>

                                        <Grid item md={8} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                                loading={ loadingSearch }
                                                disabled={ tipoDocumento === 0 || tipoOperacion === 0 || numeroSeguimiento === '' }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>

                                    </Grid>    
                                
                                </CardContent>    

                            </Card>
                        
                        </Grid>

                        <Grid item md={12} xs={12}>

                            <Card>

                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información del documento </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3} >   

                                        {
                                            documento?.success
                                            ?
                                                <>
                                                    <Grid item md={6} xs={12}>

                                                        <Grid container spacing={1} >

                                                            <Grid item md={12} xs={12} >
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }} >
                                                                    Documento firmado:
                                                                </Typography>
                                                            </Grid>
                                                            
                                                            <Grid item md={12} xs={12}>
                                                                        
                                                                <iframe 
                                                                    title='iframe  Demanda o Escrito Inicial'
                                                                    style={{ width: '100%', height: '400px' }} 
                                                                    src={ documento?.urlDescarga }                                
                                                                />

                                                            </Grid>

                                                        </Grid>    

                                                    </Grid>

                                                    <Grid item md={6} xs={12}>

                                                        <Grid container spacing={1} >
                                                        
                                                            <Grid item md={4} xs={4}>
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }} >
                                                                    Número de seguimiento:
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item md={8} xs={8} >
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                                    { documento?.numeroSeguimiento }
                                                                </Typography>
                                                            </Grid>
                                                    
                                                            <Grid item md={4} xs={4}>
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }} >
                                                                    Folio:
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item md={8} xs={8} >
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                                    { documento?.folio }
                                                                </Typography>
                                                            </Grid>
                                                        
                                                            <Grid item md={4} xs={4}>
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }} >
                                                                    Fecha:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={8} xs={8} >
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                                    { documento?.fecha }
                                                                </Typography>
                                                            </Grid>
                                                    
                                                            <Grid item md={4} xs={4}>
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }} >
                                                                    Url descarga:
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item md={8} xs={8} >
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', wordWrap: 'break-word' }}>
                                                                    { documento?.urlDescarga }
                                                                </Typography>
                                                            </Grid>
                                                                                                        
                                                        </Grid>
                                                            
                                                    </Grid>

                                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
            
                                                        <Button
                                                            variant='contained'
                                                            startIcon={ <SaveIcon /> }
                                                            onClick={ () => setOpenConfirmacion( true ) }
                                                        >
                                                            Guardar cambios
                                                        </Button>

                                                    </Grid>
                                                </>
                                            :
                                                null
                                        }   

                                        {
                                            ( !documento?.success && busquedaAplicada )
                                            &&
                                            <Grid item md={12} xs={12} sx={{ mt: 5, mb: 5, textAlign: 'center' }}>
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    No se encontro el documento
                                                </Typography>
                                            </Grid>
                                        }

                                    </Grid>    
                                
                                </CardContent>    

                            </Card>
                        
                        </Grid>                       

                    </Grid>

                </Container>               
              
            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea guardar la operación en este documento?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenConfirmacion( false ) }> Cancelar </Button>
                    <LoadingButton onClick={ handleGuardar } variant='contained' loading={ loading } > Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>
            
        </>
    )
}
