import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { ActualizarEstado, AgregarEstado, Estados } from '../../../actions/admin/catalogs/estadoActions';

import { Paises } from '../../../actions/admin/catalogs/paisActions';
    
export const EstadoScreen = ( { history } ) => {

    const { location } = history;
    const { pathname } = location;

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [pais, setPais] = useState( 0 );
    const [paisArray, SetPaisArray] = useState( [ ] );

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idEstado === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const estado = rows.filter( function(row){ return row.idEstado === id; } );
        cambiarEstatus( estado[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const Regresar = () => {
        history.push("/Admin/Catalogs");
    }

    const cambiarEstatus = async ( { idEstado, descripcion, activo, pais } ) => {

        const params = {
            idEstado,
            idPais: pais.idPais,
            descripcion,
            activo,
        };

        await ActualizarEstado( params );
    }

    const AgregarRegistro = () => {

        setPais( 0 );
        setDescripcion( '' );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion, idPais ) => {
        
        setDescripcion( descripcion );
        setPais( idPais );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        if( opcion === 'agregar' ){
            const params = {
                idEstado: 0,
                descripcion: descripcion.trim(),
                idPais: pais,
                activo: true,
            };

            await AgregarEstado( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            setRows([ 
                                ...rows,  
                                {
                                    idEstado: response.data.idEstado,
                                    idPais: response.data.idPais,
                                    pais: response.data.pais,
                                    descripcion: descripcion,
                                    activo: true
                                }
                            ]);  

                            handleCloseModal();
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const estado = rows.filter( function(row){ return row.idEstado === idReferencia; } )[0];

            const params = {
                idEstado: idReferencia,
                descripcion: descripcion.trim(),
                idPais: pais,
                activo: estado.activo,
            };
    
            await ActualizarEstado( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idEstado === idReferencia){ 
                                row.descripcion = descripcion; 
                                row.pais = response.data.pais;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModal();
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await Estados().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await Paises().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        SetPaisArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <AppBarMenu />            

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg">   

                    <Grid container spacing={3} >  

                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                            <Button type="button" startIcon={ <ArrowBackIcon /> } variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                                Regresar
                            </Button> 
                        </Grid>
                       
                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>
                           
                        <Grid item xs={12} md={12}> 

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                        {
                                            desc.length !== 0 ? desc[0].descripcion : ''
                                        }
                                    </Typography>
                                </Toolbar>

                                <TableContainer>
                                    <Table sx={{ minWidth: 500 }} >
                                        <TableHead>
                                            <TableRow>        
                                                <TableCell style={{ width:'10%' }}> ID </TableCell>
                                                <TableCell style={{ width:'35%' }}> DESCRIPCIÓN </TableCell>
                                                <TableCell style={{ width:'35%' }}> PAÍS </TableCell>
                                                <TableCell style={{ width:'10%' }}> HABILITADO </TableCell>
                                                <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                        {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <TableRow key={ row.idEstado} >
                                            
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    { row.idEstado }
                                                </TableCell>

                                                <TableCell>
                                                    { row.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.pais.descripcion }
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {
                                                        row.activo 
                                                        ? 
                                                        (
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idEstado, e.target.checked ) }
                                                            />
                                                        ) 
                                                        :                                           
                                                        ( 
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idEstado, e.target.checked ) }
                                                            />
                                                        )
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton 
                                                            color="primary" 
                                                            aria-label="edit" 
                                                            component="span" 
                                                            onClick={ () => EditarRegistro( row.idEstado, row.descripcion, row.pais.idPais ) }
                                                        >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        { rows.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={5} align="center"> 
                                                No se encontraron resultados
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[ ]}
                                                    colSpan={5}
                                                    count={rows.length}                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            
                            </Paper>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
            
                <DialogContent>
                       
                    <FormControl fullWidth sx={{ mt: 3 }} >
                        <InputLabel id="select-pais-label">País</InputLabel>
                    
                        <Select
                            labelId="select-pais-label"
                            id="select-pais"                                            
                            label="País"
                            name="pais"
                            value={ pais }                                
                            onChange={ ( e ) => { setPais( e.target.value ) } }
                        >
                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                            {
                                paisArray.map( ( elem ) => (
                                    <MenuItem key={ elem.idPais } value={ elem.idPais }> { elem.descripcion } </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                    <TextField
                        sx={{ mt: 3 }}
                        label={ desc.length !== 0 ? 'Descripción - ' + desc[0].descripcion : '' }
                        variant="outlined"                           
                        multiline
                        rows={4}                                        
                        fullWidth
                        value={ descripcion }
                        onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                    />

                    {   
                        errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }> Cancelar </Button>
                    <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

            </Dialog>

        </>
    )
}
