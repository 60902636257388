import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, FormControlLabel, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Alert, Snackbar, ListItemButton, List, ListItemIcon, ListItemText } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

import { ModalNuevaPersona } from '../modals/ModalNuevaPersona';
import { ModalBuscarPersona } from '../modals/ModalBuscarPersona';
import { ModalAgregarDomicilio } from '../modals/ModalAgregarDomicilio';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { usePages } from '../../hooks/usePages';

import { ObtenerTipoParte } from '../../actions/admin/catalogs/tipoparteActions';
import { ObtenerDependencias } from '../../actions/admin/catalogs/dependenciaActions';
import { ObtenerTipoNotificacion } from '../../actions/admin/catalogs/tiponotificacionActions';
import { FormaNotificacion } from '../../actions/admin/catalogs/formaNotificacionActions';

import { GuardarNotificaciones } from '../../actions/audiencias/audienciaActions';

import { types } from '../../types/types';

export const AudienciaNotificacionScreen = ( { Id, edit, notificaciones, audiencia, setAudiencia } ) => {

    const { tribunal } = useSelector( state => state.auth );
    const { partesExpediente } = useSelector( state => state.audiencias);

    const dispatch = useDispatch();

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [idReferencia, setReferencia] = useState( 0 );
    
    const [tipoNotificacion, setTipoNotificacion] = useState( 0 );
    const [tipoNotificacionArray, setTipoNotificacionArray] = useState( [ ] );

    const [formaNotificacion, setFormaNotificacion] = useState( 0 );
    const [formaNotificacionArray, setFormaNotificacionArray] = useState( [ ] ) ;

    const [tipoParte, setTipoParte] = useState( 0 );
    const [tipoParteArray, setTipoParteArray] = useState( [ ] );

    const [personaDependencia, setPersonaDependencia] = useState( 0 );
   
    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);
    
    const [openModalNuevoDomicilio, setOpenModalNuevoDomicilio] = useState(false);

    const [rows, setRows] = useState( [ ] );
    
    const [errors, setErrors] = useState({ });
    
    const [alert, setAlert] = useState( {  } );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const { idPersona , nombreCompleto } = persona;

    const [personaDomicilio, setPersonaDomicilio] = useState( {  } );
    const [dependenciaDomicilio, setDependenciaDomicilio] = useState( {  } );
    const [idDomicilio, setDomicilio] = useState( 0 );

    const [nuevaParte, setNuevaParte] = useState( false );
    const [parte, setParte] = useState( 0 );
    const [arrayParte, setArrayParte] = useState( [] );
    
    const [openDomiciliosAgregados, setOpenDomiciliosAgregados] = useState( false );
    const [domiciliosAgregados, setDomiciliosAgregados] = useState( [] );

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const handleOpenModalNuevoDomicilio = (dependencia, persona, domicilio) => {      
        
        if( dependencia ){
            setDependenciaDomicilio( dependencia );
        }
        else if( persona ){
            setPersonaDomicilio( persona );
        }

        setDomicilio( domicilio );

        setOpenModalNuevoDomicilio(true);
    };

    const handleCloseModalNuevoDomicilio = () => {
        setOpenModalNuevoDomicilio(false);
    };

    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleChangePersonaDependencia = ( value ) => {
    
        setPersonaDependencia( value );
        setPersona( { idPersona: 0, nombreCompleto: '' } );
        setAutocompleteValue( null );
        setDependencia( 0 );
    }

    const seleccionarPersona = ( persona ) => {
        if( persona ){
            setPersona( persona );
        }
    }   

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleClicAgregarParte = () => {

        const tipoNotificacionData = tipoNotificacionArray.find( ( element ) => { return element.idTipoNotificacion === tipoNotificacion });     
        const formaNotificacionData = formaNotificacionArray.find( ( element ) => { return element.idFormaNotificacion === formaNotificacion });

        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

        if( nuevaParte ){

            const tipoParteData = tipoParteArray.find( ( element ) => { return element.idTipoParte === tipoParte });

            if( idPersona !== 0 ){

                const personaValidate =  rows.find( ( element ) => { return element.idPersona === persona.idPersona });

                if( !personaValidate ){
                    const data = {
                        id: parseInt( id ),
                        idPersona : persona.idPersona,
                        persona: persona,
                        idDependencia: null,
                        dependencia: null,
                        tipoParte: tipoParteData,
                        tipoNotificacion: tipoNotificacionData,
                        formaNotificacion: formaNotificacionData,
                        idDomicilio: 0
                    };
            
                    dispatch({
                        type: types.actualizarNotificacionesAcuerdo,                                
                        payload: [ ...rows, data ],                              
                    });

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La persona ya se encuentra agregada';
                
                    setErrors( errores );  
                }  
            }
            else if ( dependencia !== 0 ){

                const dependenciaValidate =  rows.find( ( element ) => { return element.idDependencia === dependencia });

                if( !dependenciaValidate ){

                    var dependenciaData = dependenciaArray.find( ( element ) => { return element.idDependencia === dependencia  });

                    const data = {
                        id: parseInt( id ),
                        idPersona: null,
                        persona: null,
                        idDependencia: dependencia,
                        dependencia: dependenciaData,
                        tipoParte: tipoParteData,
                        tipoNotificacion: tipoNotificacionData,
                        formaNotificacion: formaNotificacionData,
                        idDomicilio: 0
                    };
            
                    dispatch({
                        type: types.actualizarNotificacionesAcuerdo,                                
                        payload: [ ...rows, data ],                              
                    });

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La dependencia ya se encuentra agregada';
                
                    setErrors( errores ); 
                }            
            }

        }
        else {

            const idPartePersona = arrayParte.find( ( elem ) => elem.id === parte )?.idPersona ?? 0;
            const idParteDependencia = arrayParte.find( ( elem ) => elem.id === parte )?.idDependencia ?? 0;

            if( idPartePersona !== 0 ){

                const personaValidate =  rows.find( ( element ) => { return element.idPersona === idPartePersona });

                if( !personaValidate ){

                    const partePersona = arrayParte.find( ( elem ) => elem.id === parte );

                    const data = {
                        id: parseInt( id ),
                        idPersona : partePersona.idPersona,
                        persona: partePersona.persona,
                        idDependencia: null,
                        dependencia: null,
                        tipoParte: partePersona.tipoParte,
                        tipoNotificacion: tipoNotificacionData,
                        formaNotificacion: formaNotificacionData,
                        idDomicilio: 0
                    };
            
                    dispatch({
                        type: types.actualizarNotificacionesAcuerdo,                                
                        payload: [ ...rows, data ],                              
                    });

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La persona ya se encuentra agregada';
                
                    setErrors( errores );  
                }  
            }
            else if ( idParteDependencia !== 0 ){

                const dependenciaValidate =  rows.find( ( element ) => { return element.idDependencia === idParteDependencia });

                if( !dependenciaValidate ){

                    const parteDependencia = arrayParte.find( ( elem ) => elem.id === parte );
                  
                    const data = {
                        id: parseInt( id ),
                        idPersona: null,
                        persona: null,
                        idDependencia: parteDependencia.idDependencia,
                        dependencia: parteDependencia.dependencia,
                        tipoParte: parteDependencia.tipoParte,
                        tipoNotificacion: tipoNotificacionData,
                        formaNotificacion: formaNotificacionData,
                        idDomicilio: 0
                    };
            
                    dispatch({
                        type: types.actualizarNotificacionesAcuerdo,                                
                        payload: [ ...rows, data ],                              
                    });

                    setRows( [ ...rows, data ] );
                }
                else{
                    let errores = {};

                    errores.error = 'La dependencia ya se encuentra agregada';
                
                    setErrors( errores ); 
                }            
            }

        }

        setPersonaDependencia( 0 );  
        setPersona( { idPersona: 0, nombreCompleto: '' } );      
        setAutocompleteValue( null );
        setDependencia( 0 );        
        setTipoParte( 0 );
        setFormaNotificacion( 0 );
        setTipoNotificacion( 0 );
        setParte( 0 );
    }

    const handleClicEliminarParte = ( Id ) => {

        handleOpenConfirmacion();

        setReferencia( Id );
        
    }

    const eliminarParte = () => {

        var newArray = rows.filter(function( element ){ 
            return element.id !== idReferencia;
        });

        setRows( newArray );

        dispatch({
            type: types.actualizarNotificacionesAcuerdo,                                
            payload: [ ...newArray ],                              
        });

        handleCloseConfirmacion();
    }

    const handleGuardarNotificaciones = async () => {

        let arrayNotificaciones = [];

        rows.forEach(element => {
            
            const parte = {
                idTipoParte : element.tipoParte.idTipoParte,
                idPersona : element.persona ? element.persona.idPersona : null,
                idDependencia: element.dependencia ? element.dependencia.idDependencia : null,
                idTipoNotificacion: element.tipoNotificacion.idTipoNotificacion,
                idFormaNotificacion: element.formaNotificacion.idFormaNotificacion,
                idDomicilio: element.idDomicilio
            };       
            
            arrayNotificaciones.push( parte );
           
        });       
       
        const params = {
            'idAudiencia' : Id,
            'notificaciones':arrayNotificaciones
        };

        setLoadingGuardar( true );

        await GuardarNotificaciones( params ).then( response => {
           
            if( response ){

                if( response.status === 200 ){

                    setTimeout( () => {

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false );           
                        
                        setAudiencia( { ...audiencia, notificaciones: rows } );

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
          
        });
    }

    const SeleccionarDomicilio = ( idDomicilio, domicilio ) => {

        if( Object.keys( personaDomicilio ).length !== 0 ){       
            const array = rows.map( ( element ) => {
                if( element.idPersona === personaDomicilio.idPersona){
                    element.idDomicilio = parseInt(idDomicilio);
                    element.domicilio = domicilio;
                }
                return element;
            });

            dispatch({
                type: types.actualizarNotificacionesAcuerdo,                                
                payload: array,                              
            });

            setRows( array );
            setPersonaDomicilio( { } );
        }
        else if( Object.keys( dependenciaDomicilio ).length !== 0 ){
            const array = rows.map( ( element ) => {
                if( element.idDependencia === dependenciaDomicilio.idDependencia){
                    element.idDomicilio = parseInt(idDomicilio);
                    element.domicilio = domicilio;
                }
                return element;
            });

            dispatch({
                type: types.actualizarNotificacionesAcuerdo,                                
                payload: array,                              
            });

            setRows( array );
            setDependenciaDomicilio( { } );
        }

        setDomicilio( 0 );
    }

    const handleNuevaPersona = ( value ) => {

        setNuevaParte( value ); 

        setParte( 0 );
        setPersonaDependencia( 0 );  
        setPersona( { idPersona: 0, nombreCompleto: '' } );      
        setAutocompleteValue( null );
        setDependencia( 0 );        
        setTipoParte( 0 );       
    }

    const handleCopiarDomicilio = ( idDomicilio, domiclio ) => {       
      
        const array = rows.map( ( element ) => {
            if( element.id === idReferencia){
                element.idDomicilio = parseInt(idDomicilio);
                element.domicilio = domiclio;
            }
            return element;
        });

        dispatch({
            type: types.actualizarNotificacionesAcuerdo,                                
            payload: array,                              
        });

        setRows( array );
        setReferencia( 0 );
        setOpenDomiciliosAgregados( false );
    };

    const handleOpenCopyDomicilio = ( id ) => {

        setReferencia( id ); 

        var arrayDomicilios = [];        
        rows
        .filter( ( item ) => item.idDomicilio !== 0 )
        .forEach( item => {           
            var find = arrayDomicilios.find( (e) => e.idDomicilio === item.idDomicilio );
            if( !find ){
                arrayDomicilios.push( item );
            }            
        });

        setDomiciliosAgregados( arrayDomicilios );

        setOpenDomiciliosAgregados( true );
    }

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoNotificacion().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoNotificacionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);

    useEffect(() => {      
        
        async function Obtener(){

            await FormaNotificacion().then( response => {

                if( response ){

                    if( response.data ){
                        setFormaNotificacionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoParte().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoParteArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response ){

                    if( response.status === 200){
                        setDependenciaArray( response.data ); 
                    }
                    
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {
      
        if( notificaciones.length !== 0 ){

            setRows( notificaciones );

        }

    }, [ notificaciones ])  

    useEffect(() => {
      
        if( partesExpediente.length !== 0 ){
            setArrayParte( partesExpediente );
        }

    }, [ partesExpediente ])  

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Grid container spacing={3} sx={{ mt: 1 }} >

                {
                    edit
                    &&
                    <>                
                        <Grid item md={6} xs={12} >  

                            <FormControl fullWidth>
                                <InputLabel id="select-tipoNotificacion-label">Tipo de notificación</InputLabel>

                                <Select
                                    labelId="select-tipoNotificacion-label"
                                    id="select-tipoNotificacion"                                            
                                    label="Tipo de notificación"
                                    name="tipoNotificacion"
                                    value={ tipoNotificacion }
                                    onChange={ ( e ) => { setTipoNotificacion( e.target.value ); setTipoParte( 0 ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    { 
                                        tipoNotificacionArray
                                        .filter( (element ) => { 
                                            return element.idTipoNotificacion === 2; 
                                        })
                                        .map( ( element ) => (
                                            <MenuItem key={ element.idTipoNotificacion } value={ element.idTipoNotificacion }>{ element.descripcion }</MenuItem>                                                        
                                        ))                                      
                                    }     
                                </Select>

                            </FormControl>
                            {   
                                errors.tipoNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoNotificacion } </Alert> )
                            }

                        </Grid> 

                        <Grid item md={6} xs={12} > 

                            <FormControl fullWidth>
                                <InputLabel id="select-formaNotificacion-label">Forma de notificación</InputLabel>

                                <Select
                                    labelId="select-formaNotificacion-label"
                                    id="select-tipoNoformaNotificaciontificacion"                                            
                                    label="Forma de notificación"   
                                    name="formaNotificacion"
                                    value={ formaNotificacion }
                                    onChange={ (e) => { setFormaNotificacion(e.target.value);} }
                                    disabled ={
                                        tipoNotificacion === 0 || tipoNotificacion === 3 || tipoNotificacion === 4
                                    }
                                    
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem> 
                                    {
                                        tipoNotificacion === 1
                                        ?
                                            formaNotificacionArray
                                            .filter( (elem)=>{ 
                                                return elem.idFormaNotificacion === 1;                                             
                                            })
                                            .map((elem)=>(
                                                <MenuItem key={elem.idFormaNotificacion} value={ elem.idFormaNotificacion }>{ elem.descripcion }</MenuItem>
                                            ))
                                        :
                                        null
                                    }   
                                    {
                                        tipoNotificacion === 2
                                        ?
                                            formaNotificacionArray
                                            .filter((elem)=>{
                                                return elem.idFormaNotificacion === 1 || elem.idFormaNotificacion === 2;
                                            })
                                            .map((elem)=>(
                                                <MenuItem key={elem.idFormaNotificacion} value={ elem.idFormaNotificacion }>{ elem.descripcion }</MenuItem>
                                            ))
                                        :
                                            null
                                    } 
                                </Select>

                            </FormControl>
                            {   
                                errors.formaNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.formaNotificacion } </Alert> )
                            }

                        </Grid> 

                        <Grid item md={12} xs={12} >

                            <FormControl fullWidth disabled={ tipoNotificacion === 0 || formaNotificacion === 0 }>
                                                                
                                <Grid container spacing={3}>
                                    
                                    <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                        <FormLabel sx={{ color: 'black' }} >Persona a notificar</FormLabel>
                                    </Grid>
                                    
                                    <Grid item md={8} xs={12} >
                                        <RadioGroup row >
                                            <Grid container>                                                
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="No" control={ <Radio checked={ nuevaParte === false } onChange={ () => { handleNuevaPersona( false ); } } /> } label="Actor / Demandado" />     
                                                </Grid>   
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Si" control={ <Radio checked={ nuevaParte === true } onChange={ () => { handleNuevaPersona( true ); } } /> } label="Otro" />
                                                </Grid>                                                         
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>

                                </Grid>

                            </FormControl>

                        </Grid>

                        {
                            !nuevaParte
                            &&
                                <Grid item md={12} xs={12} > 

                                    <FormControl fullWidth>
                                        <InputLabel id="select-parte-label">Seleccione una persona</InputLabel>

                                        <Select
                                            labelId="select-parte-label"
                                            id="select-parte"                                            
                                            label="Seleccione una persona"   
                                            name="seleccioneParte"
                                            value={ parte }
                                            onChange={ (e) => { setParte(e.target.value); } }
                                            disabled={ tipoNotificacion === 0 || formaNotificacion === 0 }                                    
                                        >
                                            <MenuItem value={0}>Selecciona una opción</MenuItem>                                     
                                            {                                       
                                                arrayParte                                          
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombre } &nbsp; <strong>({ elem?.tipoParte?.descripcion ?? '' })</strong> </MenuItem>
                                                ))                                       
                                            } 
                                        </Select>

                                    </FormControl>

                                    {   
                                        errors.parte && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.parte } </Alert> )
                                    }

                                </Grid> 
                        }

                        {
                            tribunal && nuevaParte
                            &&   
                                <Grid item md={12} xs={12} >

                                    <FormControl fullWidth>
                                                                        
                                        <Grid container spacing={3}>
                                            
                                            <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                <FormLabel sx={{ color: 'black' }} >Selecciona una opción para agregar: </FormLabel>
                                            </Grid>
                                            
                                            <Grid item md={4} xs={12} >
                                                <RadioGroup row >
                                                    <Grid container >
                                                        <Grid item md={6} xs={6} >
                                                            <FormControlLabel value="Masculino" control={ <Radio checked={ personaDependencia === 1 } onChange={ () => { handleChangePersonaDependencia(1) } } /> } label="Persona" />
                                                        </Grid>
                                                        <Grid item md={6} xs={6} >
                                                            <FormControlLabel value="Femenino" control={ <Radio checked={ personaDependencia === 2 } onChange={ () => { handleChangePersonaDependencia(2) } } /> } label="Dependencia" />     
                                                        </Grid>                                                            
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>

                                        </Grid>

                                    </FormControl>

                                </Grid>
                        }

                        {
                            nuevaParte
                            &&
                                <Grid item md={6} xs={12} sx={{ mt: 1 }} > 
                                    <Paper component="div" sx={{ p: '12px 0px', display: 'flex', alignItems: 'center', width: '100%' }}>                            
                                                    
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar persona' }} 
                                            placeholder="Buscar persona"     
                                            name="persona"
                                            value={ nombreCompleto }   
                                            onChange={ () => { } }             
                                            autoComplete="off"      
                                            disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                        /> 

                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalBuscarPersona }
                                            size='small'
                                            disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                        >
                                            <SearchIcon />
                                        </Button>         

                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalNuevaPersona }
                                            size='small' 
                                            disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                        >
                                            <PersonAddIcon />
                                        </Button>         

                                    </Paper>

                                </Grid>
                        }

                        {
                            tribunal && nuevaParte
                            &&  
                                <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                                    
                                    <Autocomplete                    
                                        disablePortal 
                                        fullWidth              
                                        value={ autocompleteValue }   
                                        options={ dependenciaArray }
                                        onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                        getOptionLabel={ (option) => option.descripcion}
                                        renderOption={ ( props, option ) => (
                                            <li { ...props } key={ option.idDependencia} >                         
                                                { option.descripcion }
                                            </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="Dependencia" />}
                                        disabled={ personaDependencia === 1 || personaDependencia === 0 }                    
                                    />

                                </Grid>  
                        }

                        {
                            nuevaParte
                            &&
                                <Grid item md={6} xs={12} sx={{ mt: 1 }} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>
                                    
                                        <Select
                                            labelId="select-tipoParte-label"
                                            id="select-tipoParte"                                            
                                            label="Tipo de parte"
                                            name="tipoParte"
                                            value={ tipoParte }
                                            onChange={ ( e ) => { setTipoParte( e.target.value ); } }
                                            disabled={ ( tribunal ? personaDependencia === 0 : false ) || tipoNotificacion === 0 }  
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                            {   
                                                tipoNotificacion === 1
                                                ?
                                                    tipoParteArray
                                                    .filter( (element ) => { return element.idTipoParte === 2; } )
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                    ))
                                                :
                                                    tipoParteArray
                                                    .filter( (element ) => { return element.idTipoParte !== 3 && element.idTipoParte !== 4 && element.idTipoParte !== 5 && element.idTipoParte !== 6; } )
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                    ))
                                            }
                                        </Select>

                                    </FormControl>
                                </Grid>                
                        }

                        <Grid item md={6} xs={12} style={{ marginTop: '0.5em' }} >
                            <Button 
                                variant="contained"                    
                                startIcon={ <AddIcon /> }
                                disabled={ 
                                    tribunal 
                                    ?
                                        (       
                                            tipoNotificacion === 3
                                            ?
                                                personaDependencia === 0 ||
                                                ( personaDependencia === 1 && idPersona === 0 ) ||
                                                ( personaDependencia === 2 && dependencia === 0 ) || 
                                                tipoParte === 0 ||
                                                tipoNotificacion === 0
                                            :
                                                nuevaParte
                                                ?
                                                    personaDependencia === 0 ||
                                                    ( personaDependencia === 1 && idPersona === 0 ) ||
                                                    ( personaDependencia === 2 && dependencia === 0 ) || 
                                                    tipoParte === 0 ||
                                                    tipoNotificacion === 0 ||
                                                    formaNotificacion === 0
                                                :
                                                    (
                                                        parte === 0 || 
                                                        tipoNotificacion === 0 ||
                                                        formaNotificacion === 0
                                                    )
                                        ) 
                                    :
                                        nuevaParte
                                        ?
                                            (
                                                idPersona === 0 ||
                                                tipoParte === 0||
                                                tipoNotificacion === 0 ||
                                                formaNotificacion === 0
                                            )
                                        :
                                            (
                                                parte === 0 || 
                                                tipoNotificacion === 0 ||
                                                formaNotificacion === 0
                                            )
                                }
                                onClick={ handleClicAgregarParte }
                            >
                                Agregar
                            </Button>
                        </Grid>   

                    </> 
                }  

                <Grid item md={12} xs={12} >

                    <TableContainer style={{ marginTop: '10px' }}>

                        <Table>

                            <TableHead>

                                <TableRow>

                                    <TableCell style={{ width:'15%', fontWeight: 'bold' }}>TIPO DE NOTIFICACIÓN</TableCell>      
                                    <TableCell style={{ width:'15%', fontWeight: 'bold' }}>FORMA DE NOTIFICACIÓN</TableCell>      
                                    <TableCell style={{ width:'25%', fontWeight: 'bold' }}>NOTIFICAR A</TableCell>
                                    <TableCell style={{ width:'25%', fontWeight: 'bold' }}>DOMICILIO A NOTIFICAR</TableCell>      

                                    {
                                        edit
                                        &&
                                            <TableCell style={{ width:'15%' }}> </TableCell>
                                    }                                    

                                </TableRow>

                            </TableHead>

                            <TableBody>

                            {
                                (rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map( (row) => (

                                    <TableRow key={ row.id } >

                                        <TableCell> { row?.tipoNotificacion?.descripcion } </TableCell>
                                        <TableCell> { row?.formaNotificacion?.descripcion } </TableCell>
                                    
                                        <TableCell> 
                                            { row.dependencia ? row.dependencia.descripcion : '' } { row.persona ? row.persona.nombreCompleto : '' } <br />

                                            <strong>({ row.tipoParte.descripcion })</strong>                                            
                                        </TableCell>                                   
                                        
                                        <TableCell> 
                                                    
                                            {
                                                row?.domicilio
                                                &&
                                                    row?.domicilio?.descripcion + 
                                                    ( row?.domicilio?.numero ? ' #' + ( row?.domicilio?.numero ?? '' ) : '') + ', ' +
                                                    row?.domicilio?.asentamiento?.descripcion + ', C.P ' + 
                                                    row?.domicilio?.asentamiento?.codigoPostal + ', ' + 
                                                    row?.domicilio?.asentamiento?.municipio?.descripcion + ', ' + 
                                                    row?.domicilio?.asentamiento?.estado?.descripcion
                                            }

                                        </TableCell>
                                        
                                        {
                                            edit
                                            &&
                                                <TableCell> 
                                            
                                                    <IconButton color="primary" onClick={ () => { handleClicEliminarParte( row.id ); } } >
                                                        <DeleteIcon /> 
                                                    </IconButton>
                                                                                    
                                                    <IconButton 
                                                        color="primary" 
                                                        onClick={ () => { handleOpenModalNuevoDomicilio( row.dependencia, row.persona, row.idDomicilio ); } }
                                                        disabled={ row?.formaNotificacion?.idFormaNotificacion === 2 || row?.tipoNotificacion?.idTipoNotificacion === 3 }
                                                    >
                                                        <MapIcon />
                                                    </IconButton>
                                                
                                                                                    
                                                    <IconButton 
                                                        color="primary" 
                                                        onClick={ () => handleOpenCopyDomicilio( row.id ) }
                                                        disabled={ row?.formaNotificacion?.idFormaNotificacion === 2 || row?.tipoNotificacion?.idTipoNotificacion === 3 }
                                                    >
                                                        <UndoOutlinedIcon />
                                                    </IconButton>                                                

                                                </TableCell>                 
                                        }     

                                    </TableRow>

                                ))
                            }

                            {
                                rows.length === 0 && 
                                (
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            align="center"
                                            style={{ fontWeight: 'bold', fontSize: 14 }}
                                        >
                                            {
                                                'No se han agregado notificaciones'
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[ ]}
                                        colSpan={7}
                                        count={rows.length}                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }
                                    />
                                </TableRow>
                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid> 

                {   
                    errors.error && ( 
                        <Grid item md={12} xs={12} >

                            <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                        </Grid>
                    )
                }

                {
                    edit
                    &&
                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>    
                        <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                            <LoadingButton 
                                variant="contained" 
                                onClick={ handleGuardarNotificaciones } 
                                startIcon={ <SaveIcon /> }
                                loading={ loadingGuardar }
                                disabled={ 
                                    rows.filter( ( element ) => { return element.formaNotificacion.idFormaNotificacion !== 2 && element.idDomicilio === 0; } ).length !== 0 || 
                                    rows.length === 0 
                                }
                            > 
                                Guardar notificación(es)
                            </LoadingButton>
                        </Grid>
                    </Grid>
                }
            
            </Grid>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <Button onClick={ eliminarParte } variant='contained' > Aceptar </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog 
                open={ openDomiciliosAgregados } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="sm" 
            >

                <DialogTitle>
                    Domicilios agregados
                </DialogTitle>
                
                <DialogContent>
                    
                    <List disablePadding>

                    {
                        domiciliosAgregados
                        .map( ( elem, index ) => (
                            
                            <ListItemButton disableGutters key={ index } onClick={ () => handleCopiarDomicilio( elem?.idDomicilio ?? 0, elem?.domicilio )}>
                                <ListItemIcon>
                                    <MapIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={                                    
                                    elem?.domicilio
                                    &&
                                        elem?.domicilio?.descripcion + 
                                        ( elem?.domicilio?.numero ? ' #' + ( elem?.domicilio?.numero ?? '' ) : '') + ', ' +
                                        elem?.domicilio?.asentamiento?.descripcion + ', C.P ' + 
                                        elem?.domicilio?.asentamiento?.codigoPostal + ', ' + 
                                        elem?.domicilio?.asentamiento?.municipio?.descripcion + ', ' + 
                                        elem?.domicilio?.asentamiento?.estado?.descripcion   
                                }/>
                            </ListItemButton>

                        ))
                    }

                    </List>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenDomiciliosAgregados( false ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalAgregarDomicilio 
                openModal={ openModalNuevoDomicilio } 
                handleCloseModal={ handleCloseModalNuevoDomicilio } 
                persona={ personaDomicilio }
                dependencia={ dependenciaDomicilio }
                domicilio={ idDomicilio }
                SeleccionarDomicilio={ SeleccionarDomicilio }
                edit={ edit }
            />

        </>
    )
}
