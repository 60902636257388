import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { usePages } from '../../../../hooks/usePages';

import { TablePaginationActions } from '../../../../helpers/TablePaginationActions';
import { ObtenerTipoPrestacion } from '../../../../actions/admin/catalogs/tipoprestacionActions';

import { types } from '../../../../types/types';

import { AgregarPrestacionesIrregular, ObtenerPrestaciones } from '../../../../actions/recepcion/irregularActions';

export const ModalEditarPrestaciones = ({ Id, open, setOpen }) => {

    const dispatch = useDispatch();
    const { prestaciones } = useSelector( state => state.recepcion );

    const [idReferencia, setReferencia] = useState( 0 );

    const [tipoPrestacion, setTipoPrestacion] = useState( 0 );
    const [tipoPrestacionArray, setTipoPrestacionArray] = useState( [ ] );
    
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [errors, setErrors] = useState({ });

    const [loading, setLoading] = useState( true );
    const [loadingNext, setLoadingNext] = useState( false );
   
    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleClicAgregarPrestacion = () => {

        setErrors( { } );  

        const tipoPrestacionData =  tipoPrestacionArray.find( ( element ) => { return element.idTipoPrestacion === tipoPrestacion });

        const tipoPrestacionValidate =  rows.find( ( element ) => { return element.idTipoPrestacion === tipoPrestacion });

        if( !tipoPrestacionValidate){
            
            setRows([ ...rows, tipoPrestacionData ]);

            setTipoPrestacion( 0 );
        }
        else{
            let errores = {};

            errores.error = 'La prestación seleccionada ya se encuentra agregada';
        
            setErrors( errores );  
            
            setTipoPrestacion( 0 );
        }
    }

    const handleClicEliminarPrestacion = ( Id ) => {

        handleOpenConfirmacion();

        setReferencia( Id );
        
    }

    const eliminarPrestacion = () => {

        var newArray = rows.filter(function( element ){ 
            return element.idTipoPrestacion !== idReferencia;
        });

        setRows( newArray );
     
        handleCloseConfirmacion();
    }

    const validarInformacion = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );            

        const params = {

            idIrregular: Id,
            prestaciones : rows

        };

        await AgregarPrestacionesIrregular( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    dispatch({
                        type: types.actualizarPrestacionesAcuerdo,                                
                        payload: rows,                                 
                    }); 
            
                    setTimeout(() => {
                                    
                        setLoadingNext( false );

                        setOpen( false );
                        
                    }, 500);  

                }

            }

        });
    }

    const validateFiels = () => {
        let valid = true;
        let errores = {};
        
        if( rows.length === 0 ){
            valid = false;
            errores.error = 'Debes agregar por lo menos una prestación';
        }       
        
        setErrors( errores );        

        return valid;
    } 

    useEffect(() => {
        
        if( prestaciones.length !== 0 ){
            
            setRows( prestaciones );
        }

    }, [ prestaciones ]);

    useEffect(() => {
        
        async function Obtener(){
          
            await ObtenerTipoPrestacion().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoPrestacionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();     

    }, [ ]);

    useEffect(() => {

        async function Obtener(){

            setLoading( true );

            await ObtenerPrestaciones( Id ).then( response => {

                if( response ){

                    if( response.status === 200){

                        if( response.data ){
                            
                            setTimeout(() => {
                                
                                setLoading( false );

                                setRows( response.data );

                            }, 500);

                        }                        

                    }

                }


            });

        }
      
        if( open ){

            Obtener();

        }

    }, [ Id, open ])    

    return (
        <>
            <Dialog open={ open } onClose={ () => {} } maxWidth='sm' >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent> 

                    <Grid container spacing={3} sx={{ mt: 1 }}>

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoPrestacion-label">Tipo de prestación</InputLabel>
                            
                                <Select
                                    labelId="select-tipoPrestacion-label"
                                    id="select-tipoPrestacion"                                            
                                    label="Tipo de prestación"
                                    name="tipoPrestacion"
                                    value={ tipoPrestacion }
                                    onChange={ ( e ) => { setTipoPrestacion( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        tipoPrestacionArray.map( ( element ) => (
                                            <MenuItem key={ element.idTipoPrestacion } value={ element.idTipoPrestacion }>{ element.descripcion }</MenuItem>                                                        
                                        ))
                                    }
                                </Select>

                            </FormControl>
                        </Grid> 

                        <Grid item md={12} xs={12} sx={{ textAlign: 'right' }} >

                            <Button 
                                variant="contained"                    
                                startIcon={ <AddIcon /> }
                                disabled={ tipoPrestacion === 0 }                                
                                onClick={ handleClicAgregarPrestacion }
                            >
                                Agregar
                            </Button>

                        </Grid>   

                        <Grid item md={12} xs={12} >

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'90%' }}>TIPO DE PRESTACIÓN</TableCell>                              
                                            <TableCell style={{ width:'10%' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>         

                                        {
                                            (rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( (row) => (
                                        
                                                <TableRow key={ row.idTipoPrestacion }>
                                                    <TableCell>{ row.descripcion }</TableCell>                              
                                                    <TableCell> 
                                                        <IconButton color="primary" onClick={ () => { handleClicEliminarPrestacion( row.idTipoPrestacion ); } }>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            
                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 14 }}
                                                    >
                                                        {
                                                            loading
                                                            ?
                                                                'Cargando ....'
                                                            :
                                                                'No se encontraron resultados'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={2}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid> 

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >

                                    <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                                </Grid>
                            )
                        }    

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setRows( [ ] ); setErrors( { } ); } }> Cancelar </Button>
                    <LoadingButton onClick={ validarInformacion } variant='contained' loading={ loadingNext } > Guardar </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <Button onClick={ eliminarPrestacion } variant='contained'> Aceptar </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
