
import HttpClienteToken from '../../services/HttpClienteToken';

export const GenerarConvocatoria = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Convocatoria/GenerarConvocatoria', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}


export const ActualizarConvocatoria = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Convocatoria/ActualizarConvocatoria', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ObtenerConvocatoriaDetalle = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Convocatoria/ObtenerConvocatoriaDetalle', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ActualizarEstatusConvocatoria = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Convocatoria/ActualizarEstatusConvocatoria', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const BuscarConvocatorias = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Convocatoria/BuscarConvocatorias', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}