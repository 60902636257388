
import React, { useEffect, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useDispatch } from 'react-redux';

import { Logout, ValidarToken } from '../../actions/auth/authActions';

export const ModalTokenExpired = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = useState( false );

    const [loading, setLoading] = useState( false );

    const IniciarSesion = () => {

        setLoading( true );

        setTimeout(() => {

            dispatch( Logout() );

        }, 1000);

    }

    useEffect(() => {
       
        async function Obtener(){

            await ValidarToken().then( response => {
                if(response){
                    if( response.data.success === false){
                        setOpen(true);
                    }
                }
            });

        }

        Obtener();

    }, [ ] );

    return (
        <>
            <Dialog open={ open } onClose={ () => {} } >
                <DialogTitle>
                    El tiempo de sesión ha expirado
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Por seguridad es necesario volver a iniciar sesión
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton 
                        variant='contained' 
                        color='primary' 
                        onClick={ IniciarSesion } 
                        loading={ loading }
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>  
        </>
    );
}
