import React, { useState, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { BuscarRegistrosValores } from '../../actions/tribunal/registrarValorActions';
import { numberWithCommas } from '../../helpers/FormatNumber';

export const ModalBuscarRegistroValor = ( { openModal, handleCloseModal, seleccionarFolio, idExpediente, idIrregular } ) => {

    const [rows, setRows] = useState( [] );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const handleSeleccionar = ( data ) => {
        
        seleccionarFolio( data );
        handleCloseModal();
    }

    useEffect(() => {

        async function Obtener(){

            const params = {
                idExpediente: idExpediente,
                idIrregular: idIrregular
            }

            await BuscarRegistrosValores( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setRows( response.data );
                    }

                }


            });

        }     
        
        if( openModal ){

            Obtener();    

        }

    }, [ openModal, idExpediente, idIrregular ]);

    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}>  

                        <Grid item md={12} xs={12} >

                            <Typography variant="h6"> Resultado de la busqueda </Typography>

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'10%' }}>Número de seguimiento</TableCell>
                                            <TableCell style={{ width:'10%' }}>Referencia</TableCell>
                                            <TableCell style={{ width:'10%' }}>Cantidad</TableCell>
                                            <TableCell style={{ width:'40%' }}>Consignante</TableCell>
                                            <TableCell style={{ width:'10%' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( (row) => (

                                            <TableRow key={ row.id }>
                                               
                                                <TableCell> GRV-{ row.noSeguimiento } </TableCell>
                                                <TableCell> { row.referencia } </TableCell>
                                                <TableCell> { '$' + numberWithCommas( row?.cantidad ?? 0 ) } </TableCell>
                                                <TableCell> { row.consignante } </TableCell>
                                                <TableCell> 
                                                    <Button 
                                                        variant="contained"
                                                        endIcon={ <PlaylistAddCheckIcon /> }
                                                        size="small"
                                                        onClick={ () => { handleSeleccionar( row ); } }
                                                    >
                                                        Seleccionar
                                                    </Button>
                                                </TableCell>

                                            </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        No se encontraron resultados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={5}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                      return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>
                            
                            </TableContainer>

                        </Grid>           
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }>Cancelar</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
