import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl, InputLabel, MenuItem, Select, TextField, Alert, Autocomplete } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';

import { ObtenerEstados } from '../../actions/admin/catalogs/estadoActions';
import { ObtenerMunicipios } from '../../actions/admin/catalogs/municipioActions';
//import { ObtenerAsentamientos, ObtenerAsentamientosMunicipio, ObtenerCodigosPostales } from '../../actions/admin/catalogs/asentamientoActions';
import { ObtenerAsentamientosMunicipio } from '../../actions/admin/catalogs/asentamientoActions';
import { ObtenerTiposDomicilios } from '../../actions/admin/catalogs/tipoDomicilioActions';

import { ActualizarNotificacionDomicilioDependencia, ActualizarNotificacionDomicilioPersona } from '../../actions/notificaciones/notificacionActions';

export const ModalDomicilioNotificar = ( { openModal, handleCloseModal, persona, dependencia, idNotificacion, domicilio, SeleccionarDomicilio } ) => {
     
    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );
    
    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );

    const [asentamiento, setAsentamiento] = useState( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState( [] );
    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const [tipoDomicilio, setTipoDomicilio] = useState( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState( [] );
    
    const [descripcion, setDescripcion] = useState( '' );
    const [numero, setNumero] = useState( '' );
    const [referencia, setReferencia] = useState( '' );    

    const [errors, setErrors] = useState({ });

    const [loading, setLoading] = useState(false);

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( estado === 0 ){
            valid = false;
            errores.estado = 'Debes seleccionar un estado';
        }
        else if( municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }
        /*else if( codigoPostal === 0 ){
            valid = false;
            errores.codigoPostal = 'Debes seleccionar un código postal';
        }*/
        else if( asentamiento === 0 ){
            valid = false;
            errores.asentamiento = 'Debes seleccionar un asentamiento';
        }

        if( tipoDomicilio === 0 ){
            valid = false;
            errores.tipoDomicilio = 'Debes seleccionar un tipo de domicilio';
        }

        if( descripcion === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }   

        // if( numero === '' || numero === 0 ){
        //     valid = false;
        //     errores.numero = 'Debes escribir el número int./ext.';
        // }   

        errores.type = 'warning';
        
        setErrors( errores );
        return valid;
    }

    const GuardarDomicilio = async () =>{

        const valid = validateFiels();

        if( !valid ){

            return false;
        }

        setLoading( true );

        const existDomicilio = Object.keys( domicilio ).length !== 0 && domicilio?.idDomicilio !== 0;

        if( Object.keys( persona ).length !== 0 ){     
            
            const params = {

                descripcion: descripcion,               
                numero: numero === ''? 0 : numero ,
                referencia: referencia,
                idTipoDomicilio: tipoDomicilio,
                idAsentamiento: asentamiento,
                idPersona: persona.idPersona,             
                idNotificacion: idNotificacion
            }

            await ActualizarNotificacionDomicilioPersona( params ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){
                            
                            setTimeout(() => {

                                SeleccionarDomicilio( response.data.domicilio );

                                setErrors( { message : existDomicilio ? 'Se actualizó el domicilio correctamente': 'Se agregó el domicilio correctamente', type: 'success' } );
                                
                                setLoading( false );

                            }, 1000);
                            
                        }

                    }
                    else{

                        const { message: msg } = response.data;

                        setTimeout(() => {
                            
                            setLoading( false );

                            setErrors( { message : msg ? msg: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                        }, 1000);
                    }           

                }

            });              
        }               
        else if( Object.keys( dependencia ).length !== 0 ){

            const params = {

                descripcion: descripcion,
                numero: numero === ''? 0 : numero ,
                referencia: referencia,
                idTipoDomicilio: tipoDomicilio,
                idAsentamiento: asentamiento,
                idDependencia: dependencia.idDependencia,             
                idNotificacion: idNotificacion               
    
            }

            await ActualizarNotificacionDomicilioDependencia( params ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {

                                SeleccionarDomicilio( response.data.domicilio );

                                setErrors( { message : existDomicilio ? 'Se actualizó el domicilio correctamente': 'Se agregó el domicilio correctamente', type: 'success' } );
                                
                                setLoading( false );

                            }, 1000);
                            
                        }

                    }
                    else{

                        const { message: msg } = response.data;

                        setTimeout(() => {
                            
                            setLoading( false );

                            setErrors( { message : msg ? msg: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                        }, 1000);
                    }  

                }

            });   

        } 
        else{
            setLoading( false );
        }      

    }

    const ClearData = () => {
        
        setEstado(0);
        setMunicipio(0);
        //setCodigoPostal(0);
        setAsentamiento(0);
        setTipoDomicilio(0);
        setDescripcion('');
        setNumero('');         
        setReferencia('');       

        setAsentamientoArray([]);
        //setCodigoPostalArray([]);
        setMunicipioArray([]);
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerEstados().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setEstadoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);
    
    useEffect(() => {
        
        async function Obtener(){     

            setAsentamiento( 0 );
            setAsentamientoArray( [] );
            setAutocompleteValue( null );
            setMunicipio( 0 );

            if( estado !== 0 ){    

                await ObtenerMunicipios( estado ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setMunicipioArray( response.data );   
                        }
                    }
                });

            }

        }

        Obtener();

    }, [ estado ]);

    // useEffect(() => {
        
    //     async function Obtener(){     

    //         setCodigoPostal( 0 );

    //         if( municipio !== 0 ){       
    //             await ObtenerCodigosPostales( municipio ).then( response => {
    //                 if(response){
    //                     if( response.status === 200){
    //                         setCodigoPostalArray( response.data );
    //                     }
    //                 }
    //             });
    //         }

    //     }

    //     Obtener();

    // }, [ municipio ]);

    useEffect(() => {
        
        async function Obtener(){     

            setAsentamiento( 0 );
            setAsentamientoArray( [] );
            setAutocompleteValue( null );

            if( municipio !== 0 ){   

                await ObtenerAsentamientosMunicipio( municipio ).then( response => {

                    if(response){
                        if( response.status === 200){
                            setAsentamientoArray( response.data );
                        }
                    }

                });
            }

        }

        Obtener();

    }, [ municipio ]);

    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerTiposDomicilios().then( response => {
                if(response){
                    if( response.status === 200){
                        setTipoDomicilioArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    useEffect(() => {

        if( Object.keys( domicilio ).length !== 0 && openModal ){

            setEstado( domicilio?.asentamiento?.idEstado ?? 0 );

            setTipoDomicilio(domicilio?.idTipoDomicilio ?? 0 );
            setDescripcion( domicilio?.descripcion ?? '' );
            setNumero( domicilio?.numero === 0 ? '' : domicilio?.numero ?? '' );
            setReferencia( domicilio?.referencia ?? '' );

        }

    }, [ openModal, domicilio ]); 

    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0 && openModal ){
        
            setMunicipio( 0 );

            if( municipioArray.length > 0 && domicilio?.asentamiento?.idEstado === estado){
                setMunicipio( domicilio?.asentamiento?.idMunicipio ?? 0 );
            }
        }

    }, [ openModal, domicilio, estado, municipioArray ]);

    // useEffect(() => {
        
    //     if( Object.keys( domicilio ).length !== 0 && openModal ){
        
    //         setCodigoPostal( 0 );

    //         if( codigoPostalArray.length > 0 && domicilio?.asentamiento?.idMunicipio === municipio){
    //             setCodigoPostal( domicilio?.asentamiento?.codigoPostal ?? 0 );
    //         }

    //     }

    // }, [ openModal, domicilio, municipio, codigoPostalArray ]);

    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0  && openModal ){
        
            setAsentamiento( 0 );

            if( asentamientoArray.length > 0 ){
                
                const asent = asentamientoArray.find( ( e ) => e.idAsentamiento === domicilio?.idAsentamiento ?? 0 );
                
                if( asent ){
                    setAsentamiento( domicilio?.idAsentamiento ?? 0 );
                    setAutocompleteValue( asent );
                }
            }
        }

    }, [ openModal, domicilio, asentamientoArray ]);

    return (
        <>
            <Dialog open={ openModal } maxWidth='lg' fullWidth={true}>

                <DialogTitle>Domicilio a notificar</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} sx={{ mt: 1 }} >
                        
                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-estado-label">Estado</InputLabel>
                            
                                <Select
                                    labelId="select-estado-label"
                                    id="select-estado"                                            
                                    label="Estado"
                                    name="estado"
                                    value={ estado }
                                    onChange={ ( e ) => { setEstado( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        estadoArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>
                            {   
                                errors.estado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.estado } </Alert> )
                            }
                        </Grid>

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-municipio-label">Municipio</InputLabel>
                            
                                <Select
                                    labelId="select-municipio-label"
                                    id="select-municipio"                                            
                                    label="Municipio"
                                    name="municipio"
                                    value={ municipio }
                                    onChange={ ( e ) => { setMunicipio( e.target.value ); } }
                                    disabled={ estado === 0 }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        municipioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>
                            {   
                                errors.municipio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.municipio } </Alert> )
                            }
                        </Grid>
                        
                        {/* 
                            <Grid item md={4} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>
                                
                                    <Select
                                        labelId="select-codigoPostal-label"
                                        id="select-codigoPostal"                                            
                                        label="Código Postal"
                                        name="codigoPostal"
                                        value={ codigoPostal }
                                        onChange={ ( e ) => { setCodigoPostal( e.target.value ); } }
                                        disabled={ municipio === 0 }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            codigoPostalArray.map( ( elem ) => (
                                                <MenuItem key={ elem.codigoPostal } value={ elem.codigoPostal }> { elem.codigoPostal } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>
                                {   
                                    errors.codigoPostal && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.codigoPostal } </Alert> )
                                }
                            </Grid> 
                        */}
                       
                        <Grid item md={8} xs={12} >                            
                                
                            <FormControl fullWidth>
                            
                                <Autocomplete       
                                    disabled={ municipio === 0 }         
                                    disablePortal 
                                    fullWidth              
                                    value={ autocompleteValue }   
                                    options={ asentamientoArray }
                                    onChange={ (event, value) => { setAsentamiento( value?.idAsentamiento ?? 0 ); setAutocompleteValue( value ); } }                        
                                    getOptionLabel={ (option) => option.asentamiento}
                                    renderOption={ ( props, option ) => (
                                        <li { ...props } key={ option.idAsentamiento} >                         
                                            <strong>{ option.asentamiento }</strong>&nbsp;(C.P. { option.codigoPostal }) - { option.tipo }
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Asentamiento" placeholder='Seleccione una opción' InputLabelProps={{ shrink: true }} />}         
                                />      

                            </FormControl>
                            {   
                                errors.asentamiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.asentamiento } </Alert> )
                            }

                        </Grid>

                        <Grid item md={4} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoDomicilio-label">Tipo Domicilio</InputLabel>
                            
                                <Select
                                    labelId="select-tipoDomicilio-label"
                                    id="select-tipoDomicilio"                                            
                                    label="Tipo Domicilio"
                                    name="tipoDomicilio"
                                    value={ tipoDomicilio }
                                    onChange={ ( e ) => { setTipoDomicilio( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        tipoDomicilioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idTipoDomicilio } value={ elem.idTipoDomicilio }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>
                            {   
                                errors.tipoDomicilio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.tipoDomicilio } </Alert> )
                            }
                        </Grid>

                        <Grid item md={5} xs={12} >
                            <TextField
                                fullWidth
                                label="Descripcion"
                                name="descripcion"
                                variant="outlined"                           
                                autoComplete='off'             
                                value={ descripcion }                                
                                onChange={ ( e ) => { setDescripcion( e.target.value ); } }
                            />
                            {   
                                errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.descripcion } </Alert> )
                            }
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <TextField
                                fullWidth
                                label="Numero Int./Ext."
                                name="numero"
                                variant="outlined"   
                                type="number"                                     
                                autoComplete='off'
                                value={ numero }                                
                                onChange={ ( e ) => { setNumero( e.target.value ); } }
                            />
                            {   
                                errors.numero && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.numero } </Alert> )
                            }
                        </Grid>

                        <Grid item md={12} xs={12} >
                            <TextField
                                fullWidth
                                label="Referencia"
                                name="referencia"
                                variant="outlined"        
                                multiline
                                rows={4}                                        
                                InputLabelProps={{
                                    shrink: true
                                }}                               
                                value={ referencia }                                
                                onChange={ ( e ) => { setReferencia( e.target.value ); } }   
                            />
                        </Grid>

                        <Grid item lg={12} md={12} >                    
            
                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>                   
                                
                                <LoadingButton 
                                    type="button" 
                                    variant="contained" 
                                    startIcon={ <SaveIcon /> }
                                    onClick={ GuardarDomicilio }
                                    loading={ loading }
                                > 
                                    {
                                        ( Object.keys( domicilio ).length !== 0 && domicilio?.idDomicilio !== 0 )
                                        ?
                                        'Actualizar domicilio'
                                        :
                                        'Agregar domicilio'
                                    }
                                </LoadingButton> 

                            </Grid>                    
                    
                        </Grid>

                        {   
                            errors.message && ( 
                                <Grid item md={12} xs={12} >
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled" > 
                                        { errors.message } 
                                    </Alert> 
                                </Grid>
                            )
                        }                        

                    </Grid>

                </DialogContent>

                <DialogActions>

                    <Button onClick={ () => { handleCloseModal(); setErrors( { } ); ClearData(); } }>Cancelar</Button>                   

                </DialogActions>

            </Dialog>
        </>
    )
}
