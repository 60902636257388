import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputBase, List, LinearProgress, ListItem, Paper, Typography, RadioGroup, FormLabel, FormControl, FormControlLabel, Radio } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { LoadingButton } from '@mui/lab';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';
import { GuardarExhortoLibrado } from '../../actions/tribunal/exhortoLibradoActions';
import { BuscarExhortoRecibido } from '../../actions/recepcion/exhortoRecibidoActions';

export const ModalNuevoExhortoLibrado = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const { tribunal } = useSelector(state => state.auth);

    const [loading, setLoading] = useState( false );

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [errors, setErrors] = useState({ });

    const [expediente, setExpediente] = useState( {} );

    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [numeroReferencia, setNumeroReferencia] = useState('');
     
    const [tipoTramite, setTipoTramite] = useState(0);
    const [exhortoRecibido, setExhortoRecibido] = useState( { } );
    const [numeroExhorto, setNumeroExhorto] = useState('');
    const [loadingExhortoRecibido, setLoadingExhortoRecibido] = useState( false );

    const GenerarExhortoLibrado = async () => {
        
        const params = {
            idExpediente : expediente.idExpediente,
            numeroReferencia: numeroReferencia,
            idExhortoRecibido: exhortoRecibido.idExhortoRecibido,
        };

        setLoadingGuardar(true);
        
        await GuardarExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                       
                        const {idExhortoLibrado} = response.data;

                        setTimeout(() => {

                            setLoadingGuardar(false);
                            history.push("/Tribunal/ExhortoLibrado/ElaborarExhortoLibrado/" + idExhortoLibrado);  

                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {                       
                        
                        const { message } = response.data;
                        
                        setLoadingGuardar(false);

                        let errores = {};  
                            errores.error =  message;
                            errores.type = 'error';

                        setErrors( errores );

                    }, 1200);  
                }
            }
        });
    }
   
    const cerrarModal = () => {
        
        handleCloseModal();
        
        setErrors( { } );
        setNumeroExpediente('');
        setNumeroReferencia( '');
        setNumeroExhorto( '' );
        setExpediente( {} );
        setExhortoRecibido( {} );
        setTipoTramite( 0 );
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }

        await BuscarExpediente( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setExpediente(response.data);
                            }else{
                                errorExpediente();
                            }
                        }
                    }, 1500);  
                    setNumeroExpediente('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroExpediente('');
                }
            }
        });
       
    }

    const handleChangeTipoTramite = ( tipoTramite ) => {

        setTipoTramite(tipoTramite);
        setExpediente( { } );
        setExhortoRecibido( { } );       
    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' && tipoTramite === 1 ){
            valid = false;             
            errores.numeroExpediente = 'Debes teclear un expediente';
            errores.type = 'warning';

            setLoading( false );
        } 

        if( numeroExhorto === '' && tipoTramite === 2 ){
            valid = false;
            
            errores.numeroExhorto = 'Debes teclear el número de exhorto recibido';
            errores.type = 'warning';

            setLoadingExhortoRecibido( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExhortoRecibido = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExhorto = 'No se encontró el exhorto recibido';
        errores.type = 'warning';
       
        setExhortoRecibido( { } );
        setErrors( errores );
        setNumeroExhorto('');
    }

    const handleClicBuscarExhortoRecibido = async () => {

        setLoadingExhortoRecibido( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExhorto: numeroExhorto
        }

        await BuscarExhortoRecibido( params ).then( response => {

            setLoadingExhortoRecibido( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingExhortoRecibido( false );
                        if(response.data){
                            if(response.data.success){
                                setExhortoRecibido(response.data);
                            }else{
                                errorExhortoRecibido();
                            }
                            setNumeroExhorto('');
                        }
                    }, 1500);  
                    setExhortoRecibido('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingExhortoRecibido( false );

                    }, 1500);  
                    setExhortoRecibido('');
                }
            }
        });
      
    }
    
    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>

                <DialogTitle>Nuevo exhorto librado</DialogTitle>
            
                <DialogContent>    

                    <Grid container spacing={3}>      

                        {
                            tribunal
                            &&

                                <Grid item md={5} xs={12} > 
                                    
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Escribe el número de exhorto' }} 
                                            placeholder={"Escribe el número de exhorto (1/" + (new Date().getFullYear()) + ")"} 
                                            name="numeroReferencia"
                                            value={ numeroReferencia } 
                                            onChange={ ( e ) => { setNumeroReferencia( e.target.value ) }}
                                            autoComplete="off"
                                        />                                
                                    </Paper>
                                    {   
                                        errors.numeroReferencia && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroReferencia  } </Alert> )
                                    }
                                </Grid>                            
                        }

                        <Grid item md={ tribunal ? 7 : 12 } xs={12} >

                            <FormControl component="fieldset">
                                    <FormLabel component="legend">Generar Exhorto Librado a:</FormLabel>
                                    <RadioGroup row
                                        aria-label="tipoTramite"
                                        defaultValue="expediente"
                                        name="radio-buttons-group"  
                                    >
                                        <FormControlLabel value="expediente" control={ <Radio checked={ tipoTramite === 1 } onChange={ () => { handleChangeTipoTramite(1) }} />} label="Expediente" />
                                        <FormControlLabel value="exhortoRecibido" control={ <Radio checked={ tipoTramite === 2 } onChange={ () => { handleChangeTipoTramite(2) }} />} label="ExhortoRecibido" />

                                    </RadioGroup>
                            </FormControl>

                        </Grid>

                        {  
                            tipoTramite === 1 &&(
                                <>
                                    <Grid item md={12} xs={12} > 
                                
                                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                            
                                            <InputBase 
                                                sx={{ ml: 1, flex: 1 }} 
                                                inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                                placeholder="Buscar por número de expediente"     
                                                name="expediente"
                                                value={ numeroExpediente }                                      
                                                onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                                autoComplete="off"
                                            /> 

                                            <LoadingButton 
                                                variant='contained' 
                                                sx={{ borderRadius: 10 }} 
                                                loading={ loading }
                                                onClick={ handleClicBuscarExpediente }
                                                size='small'
                                            >
                                                <SearchIcon />
                                            </LoadingButton>   

                                        </Paper>
                                        {   
                                            errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                                        }

                                    </Grid>

                                    {
                                        expediente.success === true && (
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Expediente:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { expediente.numeroExpediente }
                                                </Typography>
                                            </Grid>                                        

                                            <Grid item md={2} xs={6}>
                                                {
                                                    expediente.ponencia
                                                    &&
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                        Ponencia:
                                                    </Typography>
                                                }
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                {
                                                    expediente.ponencia
                                                    &&
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { expediente.ponencia }
                                                    </Typography>
                                                }                                            
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Actor(es):
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                               
                                                {
                                                    expediente.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                                </List>                            
                                        
                                            </Grid> 
                                    
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                    
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                    {
                                                        expediente.demandado.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem }
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                                  
                                                </List>
                                            </Grid>    

                                        </>

                                    )}

                                </>
                            ) 
                        }
                        
                        {  
                            tipoTramite === 2 &&
                            <>

                                <Grid item md={12} xs={12} > 
                                    
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }}
                                            inputProps={{ 'aria-label': 'Buscar por exhorto recibido' }} 
                                            placeholder="Buscar por número de exhorto recibido"     
                                            name="exhortoRecibido"
                                            value={ numeroExhorto }                                      
                                            onChange={ ( e ) => { setNumeroExhorto( e.target.value ); } }
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loadingExhortoRecibido }
                                            onClick={ handleClicBuscarExhortoRecibido }
                                            size='small'                                           
                                        >
                                            <SearchIcon />
                                        </LoadingButton>            

                                    </Paper>
                                    {   
                                        errors.numeroExhorto && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExhorto  } </Alert> )
                                    }

                                </Grid>  

                                {
                                    exhortoRecibido.success === true && (
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Exhorto Recibido:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { exhortoRecibido.numeroExhorto }
                                            </Typography>
                                        </Grid>                                     
                                        
                                        <Grid item md={2} xs={6}>
                                            {
                                                    exhortoRecibido.ponencia
                                                    && 
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Ponencia:
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            {
                                                    exhortoRecibido.ponencia
                                                    && 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoRecibido.ponencia }
                                                </Typography>
                                            }                                             
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                               
                                                {
                                                    exhortoRecibido.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                            </List>                            
                                    
                                        </Grid> 

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                                {
                                                    exhortoRecibido.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                  
                                            </List>
                                        </Grid>    

                                    </>

                                )}

                            </>
                        }                    

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >
                                    
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '3em' }} > { errors.error  } </Alert> 
                                    
                                </Grid>
                            )
                        }                     

                    </Grid>                      

                </DialogContent>

                <DialogActions>
                    <Button variant="text" color="primary" onClick={ cerrarModal }>Cancelar</Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ GenerarExhortoLibrado } 
                        disabled={ 
                                    ( tipoTramite === 1 && !expediente.success) ||
                                    ( tipoTramite === 2 && !exhortoRecibido.success ) ||
                                    ( tribunal && numeroReferencia === '' ) ||
                                    tipoTramite === 0 ||
                                    loadingGuardar 
                                } 
                    >
                        Guardar
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>
            </Dialog>

        </>
    )
}
