import { types } from "../types/types";

const initialState = {
    avatar: ''
}

export const userReducer = ( state = initialState, action ) => {

    switch (action.type) {        
        case types.changeAvatar:        
            return {
                avatar: action.payload
            }
        default:
            return state;
    }
    
}