import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputBase, LinearProgress, List, ListItem, Paper, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';

import { GenerarProyectoSentencia } from '../../actions/sentencias/proyectoSentenciaActions';

export const ModalNuevoProyectoSentencia = ( { openModal, handleCloseModal } ) => {
    
    const { tribunal } = useSelector( state => state.auth );

    const history = useHistory();

    const [errors, setErrors] = useState({ });
    const [expediente, setExpediente] = useState( { } );
    const [numeroExpediente, setNumeroExpediente] = useState( '' );
    const [loading, setLoading] = useState( false );
    const [loadingGuardar, setLoadingGuardar] = useState(false); 
    
    const GenerarProyecto = async () => {

        const params = {
            idExpediente : expediente.idExpediente,           
        };

        setLoadingGuardar(true);

        await GenerarProyectoSentencia( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                        setErrors( { } );
                        setNumeroExpediente('');
                        
                       const { idProyectoSentencia } = response.data;

                        setTimeout(() => {
                            setLoadingGuardar(false);
                            history.push("/Sentencia/ElaborarProyecto/" + idProyectoSentencia );  
                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {
                        setErrors( { } );
                        setNumeroExpediente('');
                       
                        setLoadingGuardar(false);
                        
                        const { message } = response.data;

                        let errores = {};  
                        errores.error = 'Ocurrió un error, contacte con el administrador ' + ( message ? `(${ message })` : '' );
                        errores.type = 'error';
                        
                        setErrors( errores );
                    }, 1200);  
                }
            }
        });

    }   
    
    const errorExpediente = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExpediente = 'No se encontró el expediente';
        errores.type = 'warning';
       
        setExpediente( { } );
        setErrors( errores );
        // setExpediente('');
        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );
    
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
    
        const params = {
            numeroExpediente: numeroExpediente
        }          
       
        await BuscarExpediente( params ).then( response => {

            setLoading( true );            
    
            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setExpediente(response.data);                                                              
                            }
                            else{
                                errorExpediente();
                            }
                            setNumeroExpediente('');
                        }
                    }, 1500);                      
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );
    
                    }, 1500);  
                    setExpediente('');
                }
            }
        });          
    
    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;             
            errores.numeroExpediente = 'Debes teclear un expediente';
            errores.type = 'warning';

            setLoading( false );
        }         

        setErrors( errores );
        return valid;
    }

    const handleCerrar = () => {

        setNumeroExpediente( '' );
        setExpediente( { } ); 
        setErrors( { } );        
        handleCloseModal();

    }  

    return (
        <>

            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
               
                <DialogTitle>Nuevo proyecto de sentencia</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                        <Grid item md={12} xs={12}>  
                            
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                        
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"
                                    value={ numeroExpediente }                                      
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                    autoComplete="off"
                                /> 

                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    loading={ loading }
                                    onClick={ handleClicBuscarExpediente }
                                    size='small'
                                >
                                    <SearchIcon />
                                </LoadingButton>   

                            </Paper>
                            {   
                                errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                            }

                        </Grid>


                        {
                            expediente.success === true && (

                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Expediente:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { expediente.numeroExpediente }
                                    </Typography>
                                </Grid>                              
                                
                                <Grid item md={2} xs={6}>
                                    {
                                        tribunal &&                                    
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Ponencia:
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    {   
                                        tribunal &&                                    
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.ponencia }
                                        </Typography>
                                    }
                                </Grid>                        

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        expediente.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                
                                    </List> 
                                </Grid> 
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                        {
                                            expediente.demandado.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                  
                                    </List>
                                </Grid>    
                                    
                            </>

                            )
                        }

                       
                    </Grid>

                    {   
                        errors.error && ( 
                            <Grid item md={12} xs={12} >
                                
                                <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '3em' }} > { errors.error  } </Alert> 
                                
                            </Grid>
                        )
                    } 

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCerrar }>Cancelar</Button>
                    <Button  
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ GenerarProyecto } 
                        disabled={ 
                            ( !expediente.success) ||                        
                            loadingGuardar
                        }
                    >
                        Guardar
                    </Button>
                </DialogActions>
                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

        </>
    )
}
