import { createTheme } from '@mui/material';

import { makeStyles } from '@mui/styles';

const theme = createTheme();

const useStyles = makeStyles({  
   
    avatarPerfilAppBar : {
        marginRight: 8,
        backgroundColor: "#156988",
        width: '30px !important',
        height: '30px !important'
    },
    buttonIcon: {
        textTransform: 'none !important'
    }, 
    checkbox : {
        display: "block",
        padding: 0,
        marginTop: 5,
        marginBottom: 5
    },
    containermt: {
        marginTop: '3%'
    },
    containerGrid: {
        marginTop: '10px !important', 
        display: 'flex !important', 
        flexDirection: 'column !important', 
        alignItems: 'center'
    },
    divider :{
        marginTop: '12px !important',
        marginBottom: '12px !important',
    },
    form : {
        marginTop: 40,
        marginBottom: 10
    },
    formLogin: {
        backgroundColor: '#fff', 
        padding: '2em', 
        borderRadius: '0.6em', 
        marginTop: '20%', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    },
    gridmb : {
        marginBottom: '20px !important'
    },
    link : {
        marginTop: 8,
        fontSize: "1.1rem",
        fontFamily : "Roboto",
        lineHeight: 1.5,
        color: 'inherit',
        textDecoration: "none"
    },
    linkAppBarLogo : {
        display : "inline-flex",
        alignItems: "center",
        color: "inherit",
        textDecoration: "none"
    },   
    linkAppBarDesktop : {
        display : "inline-flex",
        alignItems : "center",
        padding: "6px 16px",
        color: "inherit",
        textDecoration: "none"
    },
    linkAppBarMobile : {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        padding: "8px 16px",
        color: "inherit",
        textDecoration: "none"
    },
    list : {
        width : 250,
        listStyle: 'none',
        margin: '0px !important',
        padding: '0px !important',
        position: 'relative',
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    }, 
    listItem : {      
        minWidth: '0px !important',
        flex: '1 1 auto !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',   
        paddingLeft: '0px !important',
    },  
    listItemOptions : {      
        minWidth: '0px !important',
        flex: '0.1 1 auto !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',   
        paddingLeft: '0px !important',

        [theme.breakpoints.down('md')] : {
            flex: '0.1 1 auto !important',
        }
    },    
    listItemIcon : {
        minWidth : 35
    }, 
    listCenter: {
        flex: 0.3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')] : {
            flex: 0
        }
    },
    listLeft: {        
        flex: 1.5,        
        width : 250,
        listStyle: 'none',
        margin: '0px !important',
        padding: '0px !important',
        position: 'relative',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',

    },
    listRight: {        
        flex: 0.5,
        textAlign: 'right !important',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    ListTypography: {
        display: "block",
        [theme.breakpoints.down('lg')] : {
            display: "none",
        }
    },
    ListTypography2: {
        display: "block",
        [theme.breakpoints.down('sm')] : {
            display: "none",
        }
    },
    logoDesktop: {
        display: "block",
        [theme.breakpoints.down('md')] : {
            display: "none",
        }
    },
    listSubItem: {        
        alignItems: "center", 
        width: "150px", 
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
        color: "inherit", 
        textDecoration: "none"
    },    
    menuItem:{
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
    },
    PaddingIcon:{
        paddingRight: '3px'
    },
    progressIcon: {
        top: 0, 
        left: 0, 
        bottom: 0, 
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_title : {
        fontWeight: 600,
        color: "#494949",
        marginBottom: 10
    },
    subtitle: {
        textAlign: 'justify',         
        fontWeight: 'normal !important'
    },
    UserActivo: {
        color: "#3f50b5",
    },
    UserInactivo: {
        color: '#ba000d',
    },   
    UserImg : {
        backgroundColor: "#F2F2F2",
        width: 60,
        height: 60,
        borderRadius: 70
    },
   
}, {index: 1})

export default useStyles;