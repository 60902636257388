import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Grid, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, TextField, Typography, FormControl, Alert, Snackbar, Card, CardContent, Dialog, DialogContent, DialogActions, Button, IconButton } from '@mui/material';

import PrintIcon from '@mui/icons-material/Print';
import PreviewIcon from '@mui/icons-material/Preview';
import MapIcon from '@mui/icons-material/Map';
import EditIcon from '@mui/icons-material/Edit';

import moment from 'moment';

import { types } from '../../types/types';

import AppBarMenu from '../ui/AppBarMenu';

import { DigitalizarRazonamientoScreen } from './DigitalizarRazonamientoScreen';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';

import { ActualizarRazonamiento, ObtenerNotificacionDetalle } from '../../actions/notificaciones/notificacionActions';
import { ModalDetalleAcuerdo } from '../modals/ModalDetalleAcuerdo';
import { ModalDomicilioNotificar } from '../modals/ModalDomicilioNotificar';

export const SubirRazonamientoScreen = ( props ) => {

    const DateNow = moment( new Date()).format("YYYY-MM-DDThh:mm");

    const dispatch = useDispatch();

    const { tribunal, juzgado } = useSelector( state => state.auth );
    
    const { Id } = props?.match?.params;

    const [loading, setLoading] = useState(false);

    const [notificacion, setNotificacion] = useState( {} );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [sendAjax, setSendAjax] = useState(false);   

    const [alert, setAlert] = useState( {  } );

    const [domicilioNotificar, setDomicilioNotificar] = useState( { } );

    const [errors, setErrors] = useState({ });

    const [seNotifico, setSeNotifico] = useState( true );
    const [fechaNotificacion, setFechaNotificacion] = useState( DateNow );
    const [motivoNoNotificacion, setMotivoNoNotificacion] = useState( '' );
    const [reagendarNotificacion, setReagendarNotificacion] = useState( false );
    const [fechaReagenda, setFechaReagenda] = useState( DateNow );
    //const [fechaDevolucion, setFechaDevolucion] = useState( DateNow );
    const [notificacionLocal, setNotificacionLocal] = useState( false );

    const [referencia, setReferencia] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const [openAcuse, setOpenAcuse] = useState( false );

    const [openModalDetalleAcuerdo, setOpenModalDetalleAcuerdo] = useState(false);

    const [persona, setPersona] = useState( { } );
    const [dependencia, setDependencia] = useState( { } );
    const [domicilio, setDomicilio] = useState( { } );
    const [openModalNuevoDomicilio, setOpenModalNuevoDomicilio] = useState(false);

    const handleOpenModalModalDetalleAcuerdo = () => {
        setOpenModalDetalleAcuerdo(true);
    }

    const handleCloseModalDetalleAcuerdo = () => {
        setOpenModalDetalleAcuerdo(false);
    }

    const handleChangeFechaNotificacion = ( value ) => {

        const DateNow = moment( new Date()).format("YYYY-MM-DDThh:mm");

        setSeNotifico( value );
        setFechaNotificacion( DateNow );
        setMotivoNoNotificacion( '' );
        setErrors( {} );
    }

    const handleChangeFechaReagenda = ( value ) => {

        const DateNow = moment( new Date()).format("YYYY-MM-DDThh:mm");

        setReagendarNotificacion( value );
        setFechaReagenda( DateNow );
        setErrors( {} );
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};                       

        if( !seNotifico && motivoNoNotificacion === '' ){
            valid = false;
            errores.motivoNoNotificacion = 'Escribe el motivo de no realizar la notificación';
        } 
        
        if( seNotifico && fechaNotificacion === '' ){
            valid = false;
            errores.fechaNotificacion = 'Selecciona la fecha de notificación';
        } 

        if( reagendarNotificacion && fechaReagenda === '' ){
            valid = false;
            errores.fechaReagenda = 'Selecciona la fecha para reagendar la notificación';
        } 

        /*if( notificacion.numeroExhorto !== '' && fechaDevolucion === '' ){
            valid = false;
            errores.fechaDevolucion = 'Selecciona la fecha de devolución del expediente';
        } */
        
        setErrors( errores );
        return valid;
    }

    const handleValidate = () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        return true;

    }

    const Finalizar = async () => {

        const params = {
            
            idNotificacion: Id,
            notificacionLocal: notificacionLocal,                    
            seNotifico: seNotifico,
            motivoNoNotificado: motivoNoNotificacion,
            reagendarNotificacion: reagendarNotificacion,
            fechaReagendada: reagendarNotificacion ? fechaReagenda : null,
            //fechaDevolucionExpediente: notificacion.numeroExhorto !== '' ? fechaDevolucion : null,
            fechaNotificacion: seNotifico ? fechaNotificacion : null,

        };

        await ActualizarRazonamiento( params ).then( response => {

            if( response ){               

                if( response.status === 200){

                    setTimeout(() => {

                        setLoading( false );

                        setSendAjax( true );
                        
                    }, 1500);

               } 
               
            }           

        });    

    }

    const SeleccionarDomicilio = ( data ) => {

        if( data?.idDomicilio !== 0 ){

            const descripcionDomicilio = data?.descripcion;
            const numeroDomicilio = data?.numero;
            const codigoPostal = data?.asentamiento?.codigoPostal;
            const municipio = data?.asentamiento?.municipio?.descripcion;
            const estado = data?.asentamiento?.estado?.descripcion;                       

            setDomicilioNotificar( descripcionDomicilio + ' #' + numeroDomicilio + ', C.P ' + codigoPostal + ', ' + municipio + ', ' + estado );                                
        }

        setDomicilio( data );
    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idNotificacion': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerNotificacionDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setNotificacion(response.data);      
                            setPersona( response.data.persona ?? { } );
                            setDependencia( response.data.dependencia ?? { } );
                            setDomicilio( response.data.domicilio ?? { } );

                            if( response.data.idFormaNotificacion === 3 ){
                                setNotificacionLocal( true );
                            }   

                            if( ( response?.data?.numeroAmparo ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferencia( response?.data?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhorto ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferencia( response?.data?.numeroExhorto ?? '' );
                            }                           
                            else if( ( response?.data?.numeroIrregular ?? '') !== '' ){
                                setTipoReferencia( 'Número de irregular' );
                                setReferencia( response?.data?.numeroIrregular ?? '' );
                            } 
                            else if(( response?.data?.numeroExpediente ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferencia( response?.data?.numeroExpediente ?? '' );
                            }   
                            
                            if( response.data?.domicilio?.idDomicilio !== 0 ){

                                const descripcionDomicilio = response.data.domicilio?.descripcion;
                                const numeroDomicilio = response.data.domicilio?.numero;
                                const codigoPostal = response.data.domicilio?.asentamiento?.codigoPostal;
                                const municipio = response.data.domicilio?.asentamiento?.municipio?.descripcion;
                                const estado = response.data.domicilio?.asentamiento?.estado?.descripcion;                       

                                setDomicilioNotificar( descripcionDomicilio + ' #' + numeroDomicilio + ', C.P ' + codigoPostal + ', ' + municipio + ', ' + estado );                                
                            }
                     
                            if( response.data.idEstatus !== 12 ){
                                setSendAjax( true );       
                            }
                            
                            dispatch({ type: types.closeLoading });

                            setAjaxLoading( true );                            

                        }, 1200);

                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])    

    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
           {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            No se tienen actividades pendientes para este trámite
                                        </Typography>

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :

                    (
                            
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>
                                
                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}>
                                    
                                    <InformacionSeguimiento 
                                        noSeguimiento={ 'GN-' + notificacion.idNotificacion }
                                        actividad={ 'Generar notificación' }
                                        tipo={ tipoReferencia }
                                        referencia={ referencia }
                                    />

                                </Grid>               
                                
                                {
                                    notificacion.personaNotificar
                                    &&
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                                Notificar a:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                                                { notificacion.personaNotificar }
                                            </Typography>
                                        </Grid>  
                                    </>
                                }

                                {
                                    notificacion.personaNotificar
                                    &&
                                    <>
                                        <Grid item md={2} xs={2}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                                Tipo de parte:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                                { notificacion.tipoParte }
                                            </Typography>
                                        </Grid>  
                                    </> 
                                }                               

                                {
                                    ( notificacion?.idTipoNotificacion !== 3 && domicilio?.idDomicilio !== 0 )
                                    &&
                                    <>
                                        <Grid item md={2} xs={2}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                                Domicilio a notificar:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={9} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                                { domicilioNotificar }
                                            </Typography>
                                        </Grid>       

                                         <Grid item md={1} xs={9} >
                                            <IconButton
                                                onClick={ () => setOpenModalNuevoDomicilio( true ) }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>                                    
                                    </>
                                }

                                <Grid item md={2} xs={2}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Tipo de notificación:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                    { notificacion.tipoNotificacion }
                                    </Typography>
                                </Grid>   

                                <Grid item md={2} xs={2}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Forma notificación:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                        { notificacion.formaNotificacion }
                                    </Typography>
                                </Grid> 

                                {
                                    ( tribunal && notificacion?.idTipoNotificacion !== 3 )
                                    &&
                                        <Grid item md={ domicilio?.idDomicilio === 0 ? 6 : 12 } xs={12} sx={{ textAlign: 'center', mb: 1, mt: 1 }} >
                                            <Button variant="contained" startIcon={<PreviewIcon />} onClick={ handleOpenModalModalDetalleAcuerdo }>
                                                Ver acuerdo a notificar
                                            </Button>
                                        </Grid> 
                                }

                                {
                                    ( tribunal && domicilio?.idDomicilio === 0 && notificacion?.idTipoNotificacion !== 3 )
                                    &&
                                        <Grid item md={6} xs={6}  sx={{ textAlign: 'center' }} >
                                            <Button variant="contained" startIcon={ <MapIcon />} onClick={ () => setOpenModalNuevoDomicilio( true ) }>
                                                Agregar domicilio a notificar
                                            </Button>
                                        </Grid>     
                                }

                                <Grid item md={3} xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">¿Se realizó la notificación?</FormLabel>
                                        <RadioGroup row
                                            aria-label="seRealizoNotificacion"
                                            defaultValue="si"
                                            name="radio-buttons-group"  
                                        >
                                           
                                            <FormControlLabel value="si" control={ <Radio checked={ seNotifico } onChange={ () => { handleChangeFechaNotificacion( true ); } } />} label="Si" />                       
                                            <FormControlLabel value="no" control={ <Radio checked={ !seNotifico } onChange={ () => { handleChangeFechaNotificacion( false ); } } />} label="No" />                                                             
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item md={3} xs={12} >

                                    {
                                        seNotifico
                                        ?
                                            <TextField                              
                                                label="Fecha de notificación"
                                                type="datetime-local"                                
                                                value={ fechaNotificacion }                            
                                                onChange={ ( e ) => { setFechaNotificacion( e.target.value ); } }
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}                                
                                            />
                                        :
                                            <TextField                              
                                                label="Motivo de no realizar la notificación"                                                     
                                                value={ motivoNoNotificacion }                            
                                                onChange={ ( e ) => { setMotivoNoNotificacion( e.target.value ); } }
                                                multiline
                                                rows={4}  
                                                fullWidth                          
                                            />
                                    }

                                    {   
                                        errors.fechaNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaNotificacion } </Alert> )
                                    }

                                    {   
                                        errors.motivoNoNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.motivoNoNotificacion } </Alert> )
                                    }
                                </Grid>

                                {
                                    juzgado
                                    &&
                                    <>

                                        <Grid item md={2} xs={12} >
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">¿Desea reagendar?</FormLabel>
                                                <RadioGroup row
                                                    aria-label="reagendarNotificacion"
                                                    defaultValue="si"
                                                    name="radio-buttons-group"  
                                                >
                                                    <FormControlLabel value="si" control={ <Radio checked={ reagendarNotificacion } onChange={ () => { handleChangeFechaReagenda( true ); } } />} label="Si" />
                                                    <FormControlLabel value="no" control={ <Radio checked={ !reagendarNotificacion } onChange={ () => { handleChangeFechaReagenda( false ); } } />} label="No" />                                        
                                                
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={12} >
                                            
                                            {
                                                reagendarNotificacion
                                                &&
                                                (
                                                    <>
                                                        <TextField                              
                                                            label="Fecha de reagenda"
                                                            type="datetime-local"                                
                                                            value={ fechaReagenda }                            
                                                            onChange={ ( e ) => { setFechaReagenda( e.target.value ); } }
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}                                
                                                        />
                                                        {   
                                                            errors.fechaReagenda && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaReagenda } </Alert> )
                                                        }
                                                    </>
                                                )
                                            }
                                            
                                        </Grid>     

                                    </>
                                }

                                <Grid item md={6} xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">¿Se realizó la notificación en sitio?</FormLabel>
                                        <RadioGroup row
                                            aria-label="notificacionLoacal"
                                            defaultValue="si"
                                            name="radio-buttons-group"  
                                        >
                                            <FormControlLabel value="si" control={ <Radio checked={ notificacionLocal } onChange={ () => { setNotificacionLocal( true ); } } />} label="Si" />
                                            <FormControlLabel value="no" control={ <Radio checked={ !notificacionLocal } onChange={ () => { setNotificacionLocal( false ); } } />} label="No" />                                        
                                            
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                
                                {
                                    ( tribunal && notificacion?.idTipoNotificacion === 3 && notificacion.convocatoria !== '' )
                                    &&
                                        <Grid item md={12} xs={12} textAlign={'center'} >

                                            <Button 
                                                variant="contained" 
                                                startIcon={ <PrintIcon /> } 
                                                onClick={ () => { setOpenAcuse( true ); } } 
                                            >
                                                Visualizar convocatoria
                                            </Button>                 
                                        
                                        </Grid>
                                }

                                <Grid item md={12} xs={12}></Grid>
                                
                                {/*
                                    notificacion.numeroExhorto !== ''
                                    &&
                                    (
                                        <Grid item md={12} xs={12} >
                                            <TextField                              
                                                label="Fecha devolución del expediente"
                                                type="datetime-local"                                
                                                value={ fechaDevolucion }                            
                                                onChange={ ( e ) => { setFechaDevolucion( e.target.value ); } }                   
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}                                
                                            />
                                            {   
                                                errors.fechaDevolucion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaDevolucion } </Alert> )
                                            }
                                        </Grid>
                                    )
                                        */}

                                <DigitalizarRazonamientoScreen                                 
                                    handleValidate={ handleValidate } 
                                    loading={ loading } 
                                    setLoading={ setLoading }
                                    Finalizar={ Finalizar }
                                    idNotificacion={ Id }      
                                    validate={ ( notificacion?.idTipoNotificacion !== 3 && domicilio?.idDomicilio === 0 ) }                                                             
                                />

                            </Grid>

                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openAcuse } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               notificacion.convocatoria 
                               ?                              
                                    <iframe title='notificacion' src={ notificacion.convocatoria } height="500px" width="100%"></iframe>                                   
                               :
                                    <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                        No se encontró el documento
                                    </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAcuse( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

            <ModalDomicilioNotificar 
                openModal={ openModalNuevoDomicilio } 
                handleCloseModal={ () => setOpenModalNuevoDomicilio( false ) } 
                idNotificacion={ notificacion.idNotificacion }
                persona={ persona }
                dependencia={ dependencia }
                domicilio={ domicilio }
                SeleccionarDomicilio={ SeleccionarDomicilio }
            />

            <ModalDetalleAcuerdo 
                openModal={ openModalDetalleAcuerdo } 
                handleCloseModal={ handleCloseModalDetalleAcuerdo } 
                notificacion={ notificacion }
            />

        </>
    )
}
