import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SourceIcon from '@mui/icons-material/Source';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import moment from 'moment';
import 'moment/locale/es-mx';

import AppBarMenu from '../ui/AppBarMenu';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';
import { ModalDetalleExhortoLibrado } from '../modals/ModalDetalleExhortoLibrado';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { GuardarObservaciones, ObtenerDatos } from '../../actions/librosElectronicos/libroExhortosLibradosActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';
import { ObtenerTiposConsecutivos } from '../../actions/admin/catalogs/tipoConsecutivoActions';
import { ModalDetalleExhortoRecibido } from '../modals/ModalDetalleExhortoRecibido';

export const LibroExhortoLibradoScreen = () => {

    const { administrador, coordinador, tribunal } = useSelector( state => state.auth );

    const [rows, setRows] = useState( [ ] );
  
    const [recordsCount, setRecordsCount] = useState( 0 );
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 10 );

    const [referencia, setReferencia] = useState( 0 );

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [observaciones, setObservaciones] = useState( '' );
    const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
    const [loadingObservaciones, setLoadingObservaciones] = useState( false );
    
    const [expediente, setExpediente] = useState( 0 );
    const [openDetalleExpediente, setOpenDetalleExpediente] = useState( false );

    const [exhortoLibrado, setExhortoLibrado] = useState( 0 );
    const [openDetalleExhortoLibrado, setOpenDetalleExhortoLibrado] = useState( false );

    const [numeroReferencia, setnumeroReferencia] = useState( '' );    
    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [filtroAplicado, setFiltroAplicado] = useState( false );  

    const [tipoConsecutivo, setTipoConsecutivo] = useState( 0 );
    const [tipoConsecutivoArray, setTipoConsecutivoArray] = useState( [] );

    const [exhortoRecibido, setExhortoRecibido] = useState( 0 );
    const [openDetalleExhortoRecibido, setOpenDetalleExhortoRecibido] = useState( false );

    const handleEditarObservaciones = ( id, observaciones) => {
        setReferencia( id );
        setObservaciones( observaciones );
        setOpenModalObservaciones( true );
    }

    const handleGuardarObservaciones = async () => {

        setLoadingObservaciones( true );

        const params = {
            id: referencia,
            observaciones: observaciones
        }

        await GuardarObservaciones( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {

                        setOpenModalObservaciones( false );
                        
                        const newRows = rows.map( ( element ) => {
                            if( element.idLibroExhortoLibrado === referencia ){
                                element.observaciones = observaciones;
                            }
                            return element;
                        });

                        setRows( newRows );

                        setLoadingObservaciones( false );
                        setReferencia( 0 );
                        setObservaciones( '' );

                    }, 500);
                }

            }

        });

    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false );         
      
        setnumeroReferencia( '' );
        setAutoridad( 0 );
        setTipoConsecutivo( 0 );

        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();
    }

    const handleBuscar = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );       
        setRecordsCount( 0 );         
        resetPage();
        
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposConsecutivos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoConsecutivoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);
  
    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setRows( [ ] );

            await ObtenerDatos( { page, rowsPerPage } ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( !filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado ])    

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setRows( [ ] );

            await ObtenerDatos( { page, rowsPerPage, idAutoridad: autoridad, idTipoConsecutivo: tipoConsecutivo, numeroReferencia } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoading( false );
                                setLoadingSearch( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, autoridad, tipoConsecutivo, numeroReferencia ])       

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '7em' }}>  
                
                <Container maxWidth="xl" component={ Paper }>

                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >

                        <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
                            
                            <Grid item md={ 12 } xs={12} >
                            
                                <Typography variant="h4" >
                                    Libro de exhortos librados
                                </Typography>

                            </Grid>

                            {     
                                ( administrador || coordinador )
                                && 
                                <Grid item md={4} xs={12}> 
                                                    
                                    <FormControl fullWidth>
                                        <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                    
                                            <Select
                                                labelId="select-autoridad-label"
                                                id="select-autoridad"                                            
                                                label="Autoridad"
                                                name="autoridad"
                                                value={ autoridad }                                
                                                onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                disabled={ filtroAplicado }  
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                {
                                                    coordinador
                                                    ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    :
                                                        autoridadArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                    ))
                                                }                          
                                            </Select>

                                        </FormControl>
                                                                
                                </Grid>
                            }   

                            <Grid item md={4} sm={6} xs={12}> 
                                                
                                <FormControl fullWidth>
                                    <InputLabel id="select-tipoConsecutivo-label">Tipo de consecutivo</InputLabel>
                                
                                    <Select
                                        labelId="select-tipoConsecutivo-label"
                                        id="select-tipoConsecutivo"                                            
                                        label="Tipo de consecutivo"
                                        name="tipoConsecutivo"
                                        value={ tipoConsecutivo }                                
                                        onChange={ ( e ) => setTipoConsecutivo( e.target.value ) }
                                        disabled={ filtroAplicado }  
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                        {
                                            tipoConsecutivoArray
                                            .filter( ( elem ) => { 
                                                return elem.idTipoConsecutivo === 1 || elem.idTipoConsecutivo === 3 || elem.idTipoConsecutivo === 8; 
                                            })
                                            .map( ( elem ) => (
                                                <MenuItem key={ elem.idTipoConsecutivo } value={ elem.idTipoConsecutivo }> { elem.descripcion } </MenuItem>    
                                            ))
                                        }  
                                                                            
                                    </Select>

                                </FormControl>
                            
                            </Grid>   

                            <Grid item md={ ( administrador || coordinador ) ? 2 : 3 } xs={12} >
                                <TextField
                                    fullWidth
                                    label="Número de referencia"
                                    name="numeroReferencia"
                                    variant="outlined"
                                    placeholder='0/0000'   
                                    autoComplete='off'           
                                    InputLabelProps={{
                                        shrink: true
                                    }}                               
                                    value={ numeroReferencia }
                                    onChange={ ( e ) => { setnumeroReferencia( e.target.value ); } }    
                                    disabled={ filtroAplicado }                            
                                />
                            </Grid>

                            <Grid item md={2} xs={12} >

                                <LoadingButton 
                                    type="button" 
                                    fullWidth 
                                    variant="contained"
                                    startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                    onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                    loading={ loadingSearch }
                                    disabled={ 
                                        ( administrador || coordinador )
                                        ? 
                                            autoridad === 0 
                                        :
                                            tipoConsecutivo === 0 ||
                                            numeroReferencia === ''
                                    }
                                > 
                                    { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                </LoadingButton> 

                            </Grid>  

                        </Grid>

                    </Toolbar>

                    <TableContainer>

                        <Table>

                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> EXHORTO </TableCell>
                                    <TableCell style={{ width:'7%', fontWeight: 'bold' }}> CLASE </TableCell>
                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> FECHA DE ACUERDO </TableCell>
                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> EXPEDIENTE </TableCell>
                                    <TableCell style={{ width:'15%', fontWeight: 'bold' }}> AUTORIDAD REQUERIDA </TableCell>
                                    <TableCell style={{ width:'9%', fontWeight: 'bold' }}> OBJETO </TableCell>
                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> OBSERVACIONES </TableCell>
                                    <TableCell style={{ width:'2%', fontWeight: 'bold' }}>  </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row ) => (

                                        <TableRow key={ row.idLibroExhortoLibrado } >

                                            <TableCell>                                  

                                                <Button
                                                    variant='contained'
                                                    color='inherit'
                                                    startIcon={ <SourceIcon /> }
                                                    size='small'
                                                    onClick={ () => { setExhortoLibrado( row?.exhortoLibrado?.idExhortoLibrado ); setOpenDetalleExhortoLibrado( true ); } }
                                                >
                                                    { tribunal ? row?.exhortoLibrado?.numeroReferencia  ?? '' :  row?.exhortoLibrado?.numeroExhorto  ?? '' }  
                                                </Button>

                                            </TableCell>

                                            <TableCell> { row?.exhortoLibrado?.claseExhorto?.descripcion } </TableCell>

                                            <TableCell> { row?.fechaAcuerdo ? moment( row?.fechaAcuerdo ).format('DD-MM-YY HH:mm a') : '' } </TableCell>

                                            <TableCell>         

                                                {
                                                     row?.exhortoLibrado?.expediente?.idExpediente
                                                     &&
                                                        <Button
                                                            variant='contained'
                                                            color='inherit'
                                                            startIcon={ <SourceIcon /> }
                                                            size='small'
                                                            onClick={ () => { setExpediente( row?.exhortoLibrado?.expediente?.idExpediente ); setOpenDetalleExpediente( true ); } }
                                                        >
                                                            EXP { row?.exhortoLibrado?.expediente?.numeroExpediente  ?? '' }  
                                                        </Button>
                                                }    

                                                {
                                                     row?.exhortoLibrado?.exhortoRecibido?.idExhortoRecibido
                                                     &&
                                                        <Button
                                                            variant='contained'
                                                            color='inherit'
                                                            startIcon={ <SourceIcon /> }
                                                            size='small'
                                                            onClick={ () => { setExhortoRecibido( row?.exhortoLibrado?.exhortoRecibido?.idExhortoRecibido ); setOpenDetalleExhortoRecibido( true ); } }
                                                        >                                                        
                                                            EXH-R { row?.exhortoLibrado?.exhortoRecibido?.numeroExhorto ?? '' }  <br />
                                                            { row?.exhortoLibrado?.exhortoRecibido?.numeroOrigen ?? '' }

                                                        </Button>
                                                }      
                                                
                                            </TableCell>

                                            <TableCell> { row?.exhortoLibrado?.autoridadExhortoLibrado?.descripcion } </TableCell>

                                            <TableCell> { row?.exhortoLibrado?.objetoExhorto?.descripcion } </TableCell>

                                            <TableCell> { row?.observaciones ?? '' } </TableCell>

                                            <TableCell> 
                                                <IconButton 
                                                    color="primary" 
                                                    aria-label="edit" 
                                                    component="span" 
                                                    onClick={ () => { handleEditarObservaciones( row.idLibroExhortoLibrado, row?.observaciones  ?? '' ) } }
                                                >                                                        
                                                    <EditIcon />
                                                </IconButton>  
                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 
                                    && 
                                    (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        null
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron registros'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }

                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell> 

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={ 10 }
                                        count={ recordsCount }                                        
                                        rowsPerPage={rowsPerPage}
                                        page={ page }                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>      

                </Container>
            </Box>

            <Dialog open={ openModalObservaciones } maxWidth='sm' fullWidth={true}>
               
               <DialogContent>

                   <TextField
                       sx={{ mt: 1 }}
                       label="Observaciones"
                       variant="outlined"   
                       autoFocus     
                       multiline
                       rows={4}                                        
                       fullWidth
                       name="observaciones"
                       value={ observaciones }
                       onChange={ ( e ) => { setObservaciones( e.target.value ); } }
                   />

               </DialogContent>

               <DialogActions>

                   <Button onClick={ () => { setOpenModalObservaciones( false ); } }>Cancelar</Button>

                   <LoadingButton 
                       variant='contained' 
                       startIcon={ <SaveIcon /> } 
                       onClick={ handleGuardarObservaciones } 
                       loading={ loadingObservaciones }
                   >
                       Guardar
                   </LoadingButton>

               </DialogActions>

           </Dialog>

           <ModalDetalleExpediente 
                open={ openDetalleExpediente }    
                setOpen={ setOpenDetalleExpediente }
                idExpediente={ expediente }
            />

            <ModalDetalleExhortoLibrado
                open={ openDetalleExhortoLibrado}    
                setOpen={ setOpenDetalleExhortoLibrado }
                idExhortoLibrado={ exhortoLibrado }
            />

            <ModalDetalleExhortoRecibido
                open={ openDetalleExhortoRecibido}    
                setOpen={ setOpenDetalleExhortoRecibido }
                idExhortoRecibido={ exhortoRecibido }
            />

        </>
    )
}
