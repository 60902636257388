import React, { useState } from 'react';

import { useHistory } from 'react-router';

import { Alert, Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, LinearProgress, List, ListItem, Paper, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteIcon from '@mui/icons-material/Delete';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';

import { GuardarExpedientesConsultados } from '../../actions/tribunal/expedienteConsultadoActions';

export const ModalNuevoExpedienteConsultado = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const [errors, setErrors] = useState({ });
    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [loading, setLoading] = useState( false );
    const [loadingGuardar, setLoadingGuardar] = useState(false);    

    const [expedientes, setExpedientes] = useState( [] );    

    const RegistrarPrestamo = async () => {

        setLoadingGuardar(true);

        await GuardarExpedientesConsultados( { expedientes: expedientes } ).then( response => {

            if( response ){

                if( response.status === 200){

                    if(response.data){                      
                        
                       const { idExpedienteConsultado } = response.data;

                        setTimeout(() => {

                            history.push("/Tribunal/PrestamosExpediente/RegistrarPrestamo/" + idExpedienteConsultado );  
                            
                            setLoadingGuardar(false);
                            setErrors( { } );                        
                            setNumeroExpediente('');
                            setExpedientes( [] );

                        }, 600);  
                    }

                } 
                else {

                    setTimeout(() => {

                        setErrors( { } );
                        setNumeroExpediente('');                      
                        setLoadingGuardar(false);

                        let errores = {};  
                            errores.expediente = 'Ocurrió un error, contacte con el administrador';
                            errores.type = 'error';

                        setErrors( errores );

                    }, 600);  

                }
            }
        });

    }    

    const errorExpediente = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExpediente = 'No se encontró el expediente';
        errores.type = 'warning';       
       
        setErrors( errores );
    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );
    
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
    
        const params = {
            numeroExpediente: numeroExpediente
        }          
       
        await BuscarExpediente( params ).then( response => {
    
            setLoading( true );
    
            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );

                        if(response.data){

                            if(response.data.success){

                                const { idExpediente, numeroExpediente, actor, demandado, ponencia, abogados } = response.data;
                                const findElement = expedientes.find( (item) => item.idExpediente === idExpediente );

                                if( !findElement  ){
                                    setExpedientes([ ...expedientes, { idExpediente: idExpediente, numeroExpediente: numeroExpediente, actor, demandado, ponencia, abogados: abogados } ]);                               
                                }
                            }
                            else{
                                errorExpediente();
                            }

                            setNumeroExpediente('');
                        }

                    }, 600);                      
                } 
                else {

                    setTimeout(() => {
                        setLoading( false );
    
                    }, 600);  

                }
            }
        });
    
    }

    const handleRemove = ( id ) => {
        const newArray = expedientes.filter( ( item ) => item.idExpediente !== id );
        setExpedientes( newArray );
    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;             
            errores.numeroExpediente = 'Debes teclear un expediente';
            errores.type = 'warning';

            setLoading( false );
        }         

        setErrors( errores );
        return valid;
    }

    const handleCerrar = () => {

        setExpedientes( [] ); 
        setErrors( { } );        
        handleCloseModal();

    }    

    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Nuevo expediente para consulta</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                        <Grid item md={12} xs={12} > 
                       
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"                                    
                                    value={ numeroExpediente }                                      
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                    autoComplete="off"
                                /> 

                                
                                 <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    loading={ loading }
                                    onClick={ handleClicBuscarExpediente }
                                    size='small'
                                >
                                    <PlaylistAddIcon />
                                </LoadingButton>  
                                
                            </Paper>

                            {   
                                errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                            }

                        </Grid>   

                        <Grid item md={12} xs={12}> 
                        
                            <Grid container spacing={3}>

                                {
                                    expedientes.map( ( item, index ) => (
                                        <Grid item xs={4} key={ index }>

                                            <Card>
                                                <CardHeader                                                   
                                                    action={
                                                        <IconButton color='primary' onClick={ () => handleRemove( item.idExpediente ) }>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                    title={ 
                                                        <Typography variant='h6' color="text.secondary">
                                                            { 'Exp. ' + item.numeroExpediente } 
                                                        </Typography> 
                                                    }    
                                                    subheader={                                                        
                                                        item.ponencia
                                                        &&                                                          
                                                            <Typography variant="body1" color="text.secondary" mt={1} fontSize={14}>                                                        
                                                                { item.ponencia }
                                                            </Typography>                                                        
                                                    }                                                   
                                                />                                           

                                                <CardContent sx={{ mt: -2 }}>                                                    
                                                    <Typography variant="body1" color="text.secondary" fontSize={14}>
                                                        { item.actor } 
                                                    </Typography> 

                                                    <Typography variant="body1" color="text.secondary" fontSize={14} textAlign={'center'}>                                                      
                                                        <strong> vs </strong>   
                                                    </Typography> 

                                                    <Typography variant="body1" color="text.secondary" fontSize={14}>                                                        
                                                        { item.demandado }
                                                    </Typography>                                                     

                                                    {
                                                        item.abogados?.length !== 0
                                                        &&
                                                            <Box mt={1}>                                                        
                                                                <Typography variant="body1" fontWeight={'bold'} fontSize={14} color="text.secondary">Abogados:</Typography>
                                                                
                                                                <List disablePadding sx={{ height: 150, overflow: 'scroll' }} >
                                                                    { 
                                                                        item.abogados?.map( ( e ) => (
                                                                            <ListItem disableGutters> 
                                                                                <Typography variant="subtitle2" fontSize={14} color="text.secondary"> { e.abogado } <br /> <strong>{ e.tipo }</strong> </Typography> 
                                                                            </ListItem>
                                                                        ))
                                                                    }    
                                                                </List>
                                                                
                                                            </Box>
                                                    }                                                   
                                                </CardContent>

                                            </Card>
                                         
                                        </Grid>
                                    ))
                                }

                            </Grid>
                        
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCerrar }>Cancelar</Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ RegistrarPrestamo }
                        disabled={  expedientes.length === 0 || loadingGuardar }
                    >
                        Guardar
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

        </>
    )
}
