import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputBase, LinearProgress, Radio, RadioGroup, Paper } from '@mui/material';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { ModalNuevaPersona } from './ModalNuevaPersona';
import { ModalBuscarPersona } from './ModalBuscarPersona';

import { NuevoIrregular } from '../../actions/recepcion/irregularActions';
import { ObtenerPonencias } from '../../actions/admin/catalogs/ponenciaActions';
import { ObtenerTipoIrregular } from '../../actions/admin/catalogs/tipoirregularActions';

export const ModalNuevoIrregular = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const { tribunal, ponencia: ponenciaRedux } = useSelector( state => state.auth );

    const [ponencia, setPonencia] = useState( 0 );
    const [ponenciaArray, SetPonenciaArray] = useState( [ ] );

    const [tipoIrregularArray, setTipoIrregularArray] = useState( [ ] );

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const [presentante, setPresentante] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const { idPersona , nombreCompleto } = presentante;
    
    const [errors, setErrors] = useState({ });

    const [loading, setLoading] = useState(false);

    const [tipoIrregular, setTipoIrregular ] = useState( 0 );

    const handleChangeTipoIrregular = (id) => {
        setTipoIrregular(id);
    }

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const GenerarIrregular = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }   
        
        setLoading( true );

        const params = {

            idPresentante : idPersona,
            idPonencia: ponencia,
            idTipoIrregular: tipoIrregular,

        };

        await NuevoIrregular( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    if(response.data){
                       
                        const { idRecepcion } = response.data;

                        setTimeout(() => {

                            setLoading(false);
                            
                            history.push("/Tribunal/GenerarIrregular/" + idRecepcion);   

                        }, 1200);  
                    }

                } 
                else {

                    setTimeout(() => {                       
                        
                        setLoading(false);

                        let errores = {};  
                        errores.error = 'Ocurrió un error, contacte con el administrador';
                        errores.type = 'error';
                        setErrors( errores );

                    }, 1200);  

                }
            }
        });        
        
    }

    const validateFiels = () => {
        let valid = true;
        let errores = {};

        if( idPersona === 0 ){
            valid = false;
            errores.presentante = 'Debes seleccionar un presentante';
        }    
        
        /*if( tribunal && ponencia === 0 ){
            valid = false;
            errores.ponencia = 'Debes seleccionar una ponencia';
        } */  
        
        setErrors( errores );
        return valid;
    } 

    const cerrarModal = () => {

        setTipoIrregular( 0 );
        
        handleCloseModal();

        setPresentante( { idPersona: 0, nombreCompleto: '' });

        setErrors( { } );        
    }

    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPresentante( persona );

        }

    }   

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerPonencias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        SetPonenciaArray( response.data );
                    }
                }
                
            });

        }

        if (openModal) {

            Obtener();
            
        }

    }, [openModal]);

    //Tipo Irregular
    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTipoIrregular().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoIrregularArray( response.data );
                    }
                }
                
            });

        }

        Obtener();


    }, []);

    useEffect(() => {
        
        if( openModal && ponenciaRedux !== 0 && ponenciaArray.length !== 0 ){
            setPonencia( ponenciaRedux );
        }
 
    }, [ openModal, ponenciaRedux, ponenciaArray ]);
 
    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Nuevo irregular o convenio fuera de juicio</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                        <Grid item md={12} xs={12} > 
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar presentante' }} 
                                    placeholder="Buscar presentante"     
                                    name="presentante"
                                    value={ nombreCompleto }   
                                    onChange={ () => { } }             
                                    autoComplete="off"  
                                    disabled      
                                /> 

                                <Button 
                                    sx={{ borderRadius: 10, mr: 1 }} 
                                    variant='contained' 
                                    onClick={ handleOpenModalBuscarPersona }
                                    size='small'
                                >
                                    <SearchIcon />
                                </Button>         

                                <Button 
                                    sx={{ borderRadius: 10 }} 
                                    variant='contained' 
                                    onClick={ handleOpenModalNuevaPersona }
                                    size='small'
                                >
                                    <PersonAddIcon />
                                </Button>         

                            </Paper>

                            {   
                                errors.presentante && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.presentante } </Alert> )
                            }

                        </Grid>

                        {/*
                            tribunal && (
                                <>   
                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-Ponencia-label">Ponencia</InputLabel>
                                        
                                            <Select
                                                labelId="select-Ponencia-label"
                                                id="select-Ponencia"                                            
                                                label="Ponencia"
                                                name="ponencia"
                                                value={ ponencia }
                                                onChange={ ( e ) => { setPonencia( e.target.value ); } }         
                                                disabled={ ponenciaRedux !== 0 }                  
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    ponenciaArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.idPonencia } value={ elem.idPonencia }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.ponencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.ponencia } </Alert> )
                                        }

                                    </Grid>                                     
                                </>

                            )
                        */}

                        <Grid item md={12} xs={12} >
                            <RadioGroup row >
                                <Grid container >

                                    {
                                        tipoIrregularArray
                                        .filter( ( elem ) => elem.idTipoIrregular !== 3 )                                         
                                        .map( ( elem ) => (
                                            <FormControlLabel 
                                                key={ elem.idTipoIrregular } 
                                                control={ 
                                                    <Radio 
                                                        checked={ tipoIrregular === elem.idTipoIrregular } 
                                                        onChange={ () => { handleChangeTipoIrregular(elem.idTipoIrregular) } } 
                                                        disabled={
                                                            tribunal
                                                            ?
                                                                ( elem.idTipoIrregular === 1 && ponenciaRedux === 0 ) ||
                                                                ( elem.idTipoIrregular === 2 && ponenciaRedux !== 0 ) ||
                                                                ( elem.idTipoIrregular === 3 && ponenciaRedux === 0 ) 
                                                            :
                                                                false
                                                        }
                                                    /> 
                                                } 
                                                label={ elem.descripcion } 
                                            />
                                        ))
                                    }
                                                         
                                </Grid>
                            </RadioGroup>
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    
                    <Button variant="text" color="primary" onClick={ cerrarModal }>Cancelar</Button>
                    
                    <Button 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ GenerarIrregular } 
                        disabled={ 
                            tribunal
                            ?
                                idPersona === 0 || loading || tipoIrregular === 0
                            :
                                idPersona === 0 || loading || tipoIrregular === 0                         
                        }
                    >
                        Guardar
                    </Button>

                </DialogActions>

                <LinearProgress sx={{ display: loading ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

        </>
    )
}
