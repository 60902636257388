import React from 'react'

import { useSelector } from 'react-redux';

import { Box, Container } from '@mui/material';

import AppBarMenu from './ui/AppBarMenu';

import InboxScreen from './inbox/InboxScreen';

import { ReplacePasswordScreen } from './auth/ReplacePasswordScreen';

export const SajiScreen = () => {          

    const { restablecerContrasena } = useSelector(state => state.auth);

    return (
        <>    
            {
                restablecerContrasena
                ?
                    <ReplacePasswordScreen />
                :
                <>                
                    <AppBarMenu />

                    <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  
                        <Container maxWidth="lg"> 

                            <InboxScreen />

                        </Container>
                    </Box>
                </>
            }
            
        </>
    )
}
