import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Alert, Box, Button, Container, Grid, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { useForm } from '../../hooks/useForm';

import useStyles from '../../theme/useStyles';

import { RecuperarContrasena } from '../../actions/auth/authActions';

export const ForgotScreen = () => {

    const [alert, setAlert] = useState({  });

    const [loading, setLoading] = useState( false );

    const classes = useStyles();
    
    const [ formValues, handleInputChange, resetvalues ] = useForm( {
        correoElectronico: '',
        correoElectronicoConfirm: ''
    } );

    const { correoElectronico, correoElectronicoConfirm} = formValues;

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleReset = ( e ) => {
        e.preventDefault();  

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);
        const validConfirmEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronicoConfirm);

        if(!correoElectronico){    
                
            setAlert({
                open: true,
                msg: 'Debe escribir un correo electrónico',
                type: 'error'
            });
            
        } 
        else if(!validEmail){  

            setAlert({
                open: true,
                msg: 'El correo electrónico no tiene el formato correcto',
                type: 'error'
            });
            
        }
        else if(!correoElectronicoConfirm){

            setAlert({
                open: true,
                msg: 'Debe escribir el correo electrónico de confirmación',
                type: 'error'
            });
            
        }   
        else if(!validConfirmEmail){  
                
            setAlert({
                open: true,
                msg: 'El correo electrónico de confirmación no tiene el formato correcto',
                type: 'error'
            });
            
        }
        else if( correoElectronico !== correoElectronicoConfirm){            
                
            setAlert({
                open: true,
                msg: 'Los correos electrónicos no coinciden',
                type: 'error'
            });
            
        }   
        else{        
            
            setLoading( true );

            const params = { "correoElectronico": correoElectronico }

            RecuperarContrasena( params ).then( response => {
                
                if( response.status === 200){

                    setTimeout(() => {
                    
                        setAlert({
                            open: true,
                            msg: `Se envió un correo de recuperación al correo electrónico: ${correoElectronico}`,
                            type: 'success'
                        });

                        setLoading( false );
    
                        resetvalues();                    
                    
                    }, 1200);
                   
                }
                else{

                    setTimeout(() => {
                    
                        setAlert({
                            open: true,
                            msg: `Ocurrió un problema con el envío del correo de recuperacion: ${ response.data.message }`,
                            type: 'error'
                        });

                        setLoading( false );
    
                        resetvalues();                    
                    
                    }, 1200);
                    
                }

            });
        }
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={ 2500 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Container component="main" maxWidth="xs">   

                <Box className={classes.formLogin}>
                   
                    <Typography component="h1" variant="h3">
                        {/* SAJI */}

                        <img style={{ height: 170  }}
                            src={ process.env.PUBLIC_URL + '/logos/SAJI-V.svg'}
                            alt='logo_saji'
                        />
                    </Typography>
                
                    <Box>

                        <Typography component="h1" variant="h6" style={{ textAlign: 'center' }}>
                            ¿Perdiste tu contraseña? 
                        </Typography>

                        <Typography component="h1" variant="subtitle2" sx={{ mt: 1 }} className={classes.subtitle} >                    
                            Ingresa tu correo electrónico y recibiras un enlace para crear una nueva contraseña.
                        </Typography>

                        <form>
                            <TextField
                                margin="normal"                      
                                fullWidth
                                label="Correo Electrónico"
                                name="correoElectronico"
                                type="email"
                                autoComplete="off"
                                value={ correoElectronico }
                                onChange={ handleInputChange }
                            />

                            <TextField
                                margin="normal"                                
                                fullWidth
                                name="correoElectronicoConfirm"
                                label="Confirmar correo electrónico"
                                type="email"        
                                value={ correoElectronicoConfirm }
                                onChange={ handleInputChange }                
                            />           
                    
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={ handleReset }
                                sx={{ mt: 3, mb: 2 }}
                                loading={ loading }
                                startIcon={ <ForwardToInboxIcon />}
                            >
                                Enviar enlace de recuperación
                            </LoadingButton>

                        </form>

                        <Grid container className={classes.containerGrid} >
                            <Grid item xs>
                                <Link to="/Auth/Login" style={{ textDecoration: 'none' }} >                                   
                                    <Button style={{ color: '#626c76', textTransform: 'none' }} > 
                                        ¿Ya tienes una cuenta?, Ingresa aquí.
                                    </Button>                                  
                                </Link>
                            </Grid>              
                        </Grid>

                    </Box>
                </Box>

            </Container>

        </>
    )
}
