import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputBase, List, ListItem, LinearProgress, Paper, Typography, FormControl, InputLabel, Select, MenuItem} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { ModalBuscarAudiencia } from './ModalBuscarAudiencia';

import { BuscarExpediente, BuscarExpedientePonencia } from '../../actions/recepcion/expedienteActions';
import { BuscarUsuarioJuez, BuscarUsuarioSecretario } from '../../actions/admin/users/usuarioAction';

import { GuardarAudiencia } from '../../actions/audiencias/audienciaActions';

export const ModalCelebrarAudiencia = ( { openModal, handleCloseModal } ) => {
    
    const history = useHistory();

    const {juzgado, tribunal} = useSelector(state => state.auth);

    const [openModalBuscarAudiencia, setOpenModalBuscarAudiencia] = useState(false);

    const [loading, setLoading] = useState( false );

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [errors, setErrors] = useState( { } );

    const [numeroExpediente, setNumeroExpediente] = useState('');

    const [habiliarGuardar, setHabiliarGuardar] = useState( true );

    const [audiencia, setAudiencia] = useState( { success: false } );
    const [expediente, setExpediente] = useState( { success: false } );

    const [juez, setJuez] = useState( 0 );
    const [juezArray, setJuezArray] = useState( [ ] );

    const [secretario, setSecretario] = useState( 0 );
    const [secretarioArray, setSecretarioArray] = useState( [ ] );

    const validateFielsExpediente = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un número de expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
        
        setErrors( { } );
        
        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setAudiencia('');
        setNumeroExpediente('');
        setExpediente('');
        setHabiliarGuardar(true);
        setErrors( errores );

    }

    const cerrarModal = () => {
        
        handleCloseModal();

        setErrors( { } );
        setAudiencia('');
        setNumeroExpediente('');
        setExpediente('');
        setHabiliarGuardar(true);
      
    }

    const handleOpenModalBuscarAudiencia = () => {       
        setOpenModalBuscarAudiencia(true);
    };

    const handleCloseModalBuscarAudiencia = () => {
        setOpenModalBuscarAudiencia(false);
    };  

    const handleClicBuscarExpediente = async () => {
    
        setLoading( true );

        const valid = validateFielsExpediente();

        if( !valid ){
           
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }

        if(tribunal){
            await BuscarExpedientePonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                             
                                if(response.data.success){
                                    setAudiencia( '' );
                                    setHabiliarGuardar(true);
                                    setExpediente(response.data);
                                    
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        }else if (juzgado){
            await BuscarExpediente( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                             
                                if(response.data.success){
                                    setAudiencia( '' );
                                    setHabiliarGuardar(true);
                                    setExpediente(response.data);
                      
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        }
    }

    const CelebrarAudiencia = async () => {

        const params = {
            idAgenda : audiencia.id,
            idJuez: juez,
            idSecretario: secretario,
        };
         
        setLoadingGuardar(true);
        setHabiliarGuardar( true );
        
        await GuardarAudiencia( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                       
                        const {idAudiencia} = response.data;

                        setTimeout(() => {

                            history.push("/Audiencia/CelebrarAudiencia/" + idAudiencia);
                            
                            setLoadingGuardar(false); 
                            setHabiliarGuardar( false );

                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {                      
                        
                        const { message } = response.data;

                        setLoadingGuardar(false);
                        setHabiliarGuardar( false );

                        let errores = {};  
                            errores.error = 'Ocurrió un error, contacte con el administrador ' + ( message ? `(${ message })` : '' );
                            errores.type = 'error';

                        setErrors( errores );

                    }, 1200);  
                }
            }
        });   

    }

    const obtenerAgenda = ( row ) => {
        setAudiencia( row );
        setHabiliarGuardar(false);
    }

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioSecretario( ).then( response => {

                if( response ){

                    if( response.data ){
                        setSecretarioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);   

    useEffect(() => {   
        
        if( audiencia?.success && juezArray.length > 0 ){
            setJuez( audiencia.idJuez ?? 0 );
        }
      
    }, [ audiencia, juezArray ])

    useEffect(() => {   
        
        if( audiencia?.success && secretarioArray.length > 0 ){
            setSecretario( audiencia.idSecretario ?? 0 );
        }
      
    }, [ audiencia, secretarioArray ])

    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Celebrar audiencia</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} >
                        
                        <Grid item md={12} xs={12} sx={{ mt: 1 }} > 

                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"
                                    value={ numeroExpediente } 
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                    autoComplete="off"
                                />

                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    loading={ loading }
                                    onClick={ handleClicBuscarExpediente }
                                    size='small'>
                                    <SearchIcon />
                                </LoadingButton>     

                            </Paper>
                            {   
                                errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                            }
                        </Grid>

                        {
                            expediente?.success
                            && 
                            (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Expediente:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.numeroExpediente }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>    
                                        {
                                            expediente.ponencia
                                            &&                                                     
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>   
                                        }                             
                                    </Grid>                            
        
                                    <Grid item md={4} xs={6} >
                                        {
                                            expediente.ponencia
                                            &&       
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.ponencia }
                                            </Typography>
                                        }
                                    </Grid> 

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                           
                                        {
                                            expediente.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                 
                                        </List>      
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                                                                
                                        </List>
                                    </Grid>  
                                </>
                            )
                        }

                        <Grid item md={12} xs={12} sx={{ mt: 1 }} > 
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                                                       
                                <InputBase 
                                    sx={{ ml: 1, flex: 1, }} 
                                    inputProps={{ 'aria-label': 'Buscar audiencia' }} 
                                    placeholder="Buscar audiencia"    
                                    name="audiencia" 
                                    value={ '' }   
                                    onChange={ () => { } }     
                                    autoComplete="off"                      
                                /> 
                                
                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    onClick={ handleOpenModalBuscarAudiencia }
                                    size='small'
                                    disabled={ !expediente.success }>
                                    <SearchIcon />
                                </LoadingButton> 

                            </Paper>

                        </Grid>

                        {
                            audiencia?.success
                            && 
                            (
                                <>
                                    <Grid item md={3} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Tipo de audiencia:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { audiencia.tipoAudiencia }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>                             
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Fecha:
                                            </Typography>
                                    </Grid>

                                    <Grid item md={3} xs={6} > 
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { audiencia.fechaInicio }
                                            </Typography>
                                    </Grid> 
                                </>
                            )
                        }

                        {
                            juzgado
                            &&
                                <Grid item md={12} xs={12} sx={{ mt: 1 }} >

                                    <FormControl fullWidth>
                                        <InputLabel id="select-juez-label">Juez</InputLabel>
                                    
                                        <Select
                                            labelId="select-juez-label"
                                            id="select-juez"                                            
                                            label="Juez"
                                            name="juez"
                                            value={ juez }                                
                                            onChange={ ( e ) => { setJuez( e.target.value ); setHabiliarGuardar( e.target.value === 0 ); }}                                            
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                            {
                                                juezArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                ))
                                            }                                          
                                        </Select>

                                    </FormControl>

                                </Grid>
                        }

                        {
                            juzgado
                            &&
                                <Grid item md={12} xs={12} sx={{ mt: 1 }} >

                                    <FormControl fullWidth>
                                        <InputLabel id="select-SecretarioInstructor-label">Secretario Instructor</InputLabel>
                                    
                                        <Select
                                            labelId="select-SecretarioInstructor-label"
                                            id="select-SecretarioInstructor"                                            
                                            label="Secretario Instructor"
                                            name="SecretarioInstructor"
                                            value={ secretario }                                
                                            onChange={ ( e ) => { setSecretario( e.target.value ); setHabiliarGuardar( e.target.value === 0 ); }}                                            
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                            {
                                                secretarioArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                ))
                                            }                                          
                                        </Select>

                                    </FormControl>

                                </Grid>
                        }

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >
                                    
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.error  } </Alert> 
                                    
                                </Grid>
                            )
                        } 

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ cerrarModal }>Cancelar</Button>
                    <Button variant="contained" color="primary" startIcon={ <SaveIcon /> } onClick={ CelebrarAudiencia } disabled={ habiliarGuardar } >Guardar</Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>
            </Dialog>

            <ModalBuscarAudiencia openModal={ openModalBuscarAudiencia } handleCloseModal={ handleCloseModalBuscarAudiencia } expediente={ expediente } obtenerAgenda={ obtenerAgenda } />
        </>
    )
}
