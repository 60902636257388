import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useForm } from '../../hooks/useForm';

import useStyles from '../../theme/useStyles';

import { RestablecerContrasena, ValidarTokenRecuperacion } from '../../actions/auth/authActions';

export const RecoverScreen = (props) => {

    const { token } = props.match.params;

    const [alert, setAlert] = useState({  });

    const [open, setOpen] = useState( false );

    const [tokenValid, setTokenValid] = useState(true);

    const [loading, setLoading] = useState( false );
    const [loadingInicio, setLoadingInicio] = useState( false );

    const [loadingPage, setLoadingPage] = useState( true );

    const classes = useStyles();

    const [ formValues, handleInputChange, resetValues ] = useForm( {
        contrasena: '',
        contrasenaConfirm: ''
    } );

    const { contrasena, contrasenaConfirm} = formValues;

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const handleChangeViewConfirmarContrasena = () => {
        setViewConfirmarContrasena( ( prev ) => !prev );
    }

    const handleReset = ( e ) => {

        e.preventDefault();  

        if(!contrasena){
                
            setAlert({
                open: true,
                msg: 'Debe escribir una contraseña',
                type: 'error'
            });
            
        } 
        else if(!contrasenaConfirm){          
                
            setAlert({
                open: true,
                msg: 'Debe escribir la confirmación de la contraseña',
                type: 'error'
            });            
                        
        } 
        else if( contrasena !== contrasenaConfirm){
                
            setAlert({
                open: true,
                msg: 'Las contraseñas no coinciden',
                type: 'error'
            });
            
        }   
        else{

            setLoading( true );

            const params = {
                "token" : token,
                "contrasena": contrasena
            }

            RestablecerContrasena( params ).then( response => {
                if(response){
                    if(response.data.success === true){       
                        
                        setTimeout(() => {
                            
                            setAlert({
                                open: true,
                                msg: 'Se cambió la contraseña correctamente',
                                type: 'success'
                            });
    
                            resetValues();

                            setLoading( false );
    
                            setOpen( true );   

                        }, 1200);
                        
                    }
                    else{

                        setTimeout(() => {
                            
                            setAlert({
                                open: true,
                                msg: 'El tiempo de validación de este enlace a pasado de los 30 minutos.',
                                type: 'error'
                            });
    
                            resetValues();

                            setLoading( false );

                        }, 1200);
                       
                    }
                }
            });    

        }
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const IniciarSesion = () => {

        setLoadingInicio( true );

        setTimeout(() => {
        
            handleClose();

            setLoading( false );

            props.history.push("/Auth/Login");

        }, 1200);
        
    }

    useEffect(() => {
       
        async function Obtener(){

            const params = {
                "token" : token
            }

            await ValidarTokenRecuperacion( params ).then( response => {

                if( response ){
                   
                    setTimeout(() => {
                       
                        if(response.data.success === false){                 
                            setTokenValid(false);
                            setLoadingPage(false);
                        }
                        else{
                            setLoadingPage(false);
                        }

                    }, 1000);
                    
                }
            });
        }

        Obtener();

    }, [ token ] );

    if( loadingPage ){
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }} >

                <CircularProgress size={40} />

            </Box>
        )
    }

    if( !tokenValid ){
        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }} >
        
                    <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="md">
                        <Typography variant="h4" component="h1" gutterBottom>
                            LO SENTIMOS, ESTE ENLACE A CADUCADO.
                        </Typography>

                        <Divider />
                    </Container>   

                    <Container component="main" sx={{ mt: 2 }} maxWidth="md">
                        <Typography variant="h6">
                            El tiempo de validación de este enlace a pasado de los 30 minutos.
                        </Typography>
                    </Container> 

                    <Container component="main" sx={{ mt: 2 }} maxWidth="md">               
                        <Link to="/Auth/Forgot" style={{ textDecoration: 'none' }} > 
                            <Button variant="contained" startIcon={ <ArrowBackIcon />} >Regresar a la opción de recuperación</Button> 
                        </Link>  
                    </Container> 

                </Box>
            </>
        )
    }
    else{

        return (
            <>
                {
                    alert.msg && (
                        <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                            <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                                { alert.msg }
                            </Alert>
                        </Snackbar>
                    )
                }

                <Container component="main" maxWidth="xs">   

                    <Box className={classes.formLogin}>
                        
                        <Typography component="h1" variant="h3">
                            {/* SAJI */}

                            <img style={{ height: 170  }}
                                src={ process.env.PUBLIC_URL + '/logos/SAJI-V.svg'}
                                alt='logo_saji'
                            />
                        </Typography>
                    
                        <Box>

                            <Typography variant="h6" style={{ textAlign: 'center', marginTop: '5px' }}>
                                Recuperar contraseña
                            </Typography>

                            <Typography variant="subtitle2" sx={{ mt:2 }} className={classes.subtitle}>                    
                                Ingresa una nueva contraseña.
                            </Typography>

                            <form>

                                <Grid container spacing={2} style={{ marginTop: '5px' }} >

                                    <Grid item md={12} xs={12} >

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Contraseña"
                                                name="contrasena"
                                                type={ viewContrasena ? 'text' : 'password'}
                                                value={ contrasena }                                
                                                onChange={ handleInputChange }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                            {
                                                                viewContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                    </Grid>

                                    <Grid item md={12} xs={12} >

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Confirmar contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Confirmar contraseña"
                                                name="contrasenaConfirm"
                                                type={ viewConfirmarContrasena ? 'text' : 'password'}
                                                value={ contrasenaConfirm }                                
                                                onChange={ handleInputChange }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                            {
                                                                viewConfirmarContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>    

                                    </Grid>

                                </Grid> 
                        
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={ handleReset }
                                    sx={{ mt: 3, mb: 2 }}
                                    loading={ loading }
                                    startIcon={ <SaveIcon /> }
                                >
                                    Guardar
                                </LoadingButton>
                            </form>

                        </Box>
                    </Box>

                </Container>

                <Dialog open={ open } onClose={ () => {} } >
                    <DialogTitle>
                        Iniciar sesión
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            Para que la cuenta tome la nueva contraseña es necesario volver a iniciar sesión.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <LoadingButton variant="contained" color="primary" loading={ loadingInicio } onClick={ IniciarSesion } >
                            Aceptar
                        </LoadingButton>
                    </DialogActions>
                </Dialog>  

            </>
        )    

    }
}
