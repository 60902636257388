import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { ObtenerUsuariosReasignarCancelar } from '../../../actions/admin/users/usuarioAction';
import { ObtenerProcesos } from '../../../actions/admin/catalogs/procesoActions';

import { BuscarActividadReasignacionCancelacion, CancelarActividad, ReasignarActividad } from '../../../actions/admin/actividades/actividadActions';
import { ObtenerAutoridades } from '../../../actions/admin/catalogs/autoridadActions';

export const ActividadesScreen = () => {

    const [rows, setRows] = useState( [] );

    const [numeroSeguimiento, setNumeroSeguimiento] = useState( '' );

    const [referencia, setReferencia] = useState( '' );
    const [ref, setRef] = useState( 0 );
    const [estatus, setEstatus] = useState( 0 );

    const [usuario, setUsuario] = useState( 0 );
    const [usuarioArray, setUsuarioArray] = useState( [] );

    const [proceso, setProceso] = useState( 0 );
    const [procesosArray, setProcesosArray] = useState( [] );

    const [openCancelar, setOpenCancelar] = useState( false );
    const [errors, setErrors] = useState({  });

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );
    const [loadingCancelacion, setLoadingCancelacion] = useState( false );

    const [usuarioAsignacion, setUsuarioAsignacion ] = useState( 0 );
    const [usuarioAsignacionArray, setUsuarioAsignacionArray] = useState( [] );

    const [openAsignacion, setOpenAsignacion ] = useState( false );
    const [loadingAsignacion, setLoadingAsignacion] = useState( false );

    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [autoridadReasignar, setAutoridadReasignar] = useState( 0 );

    const [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages(5);

    const handleGuardarCancelacion = async () => {

        setLoadingCancelacion( true );

        await CancelarActividad({ numeroSeguimiento : referencia }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setRows( rows.filter( ( elem ) => elem.numeroSeguimiento !== referencia ) )

                            setLoadingCancelacion( false );
                            handleClose();
                            
                        }, 500);

                    }
                    else{
                        setLoadingCancelacion( false );
                    }

                }
            }

        });

    }

    const handleGuardarAsignacion = async () => {

        if( usuarioAsignacion === 0 ){

            setErrors( { } );

            let errores = {};  
                errores.asignacion = 'Debe seleccionar un usuario para reasignar';
                errores.type = 'warning';
        
            setErrors( errores );

            return false;
        }

        setLoadingAsignacion( true );

        await ReasignarActividad({ numeroSeguimiento : referencia, idUsuarioReasignacion: usuarioAsignacion, IdEstatus: estatus, idRef: ref }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setRows( rows.filter( ( elem ) => elem.numeroSeguimiento !== referencia ) )

                            setLoadingAsignacion( false );
                            handleClose();
                            
                        }, 500);

                    }
                    else{
                        setLoadingAsignacion( false );
                    }

                }
            }

        });

    }

    const handleClose = () => {

        setOpenCancelar( false ); 
        setOpenAsignacion( false ); 
              
        setUsuarioAsignacion( 0 );
        setRef( 0 );
        setEstatus( 0 );

        setReferencia( '' );

        setErrors( { } );        
    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setNumeroSeguimiento( '' );
        setAutoridad( 0 );
        setUsuario( 0 );
        setProceso( 0 );
        
        setRows( [ ] ); 

        resetPage();

    }

    const handleBuscar = async () => {

        setFiltroAplicado( true );
        setLoading( true );
        setLoadingSearch( true );

        const params = {
            numeroSeguimiento, 
            usuario: usuario === 0 ? '' : usuario, 
            proceso: proceso !== 0 ? procesosArray.find( ( elem ) => elem.idProceso === proceso )?.siglas : ''
        };

        await BuscarActividadReasignacionCancelacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, records } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setRows( records );
                            setLoading( false );
                            setLoadingSearch( false );
                            
                        }, 500);

                    }
                    else{
                        setLoading( false );
                        setLoadingSearch( false );
                    }

                }
            }

        });

    }

    useEffect(() => {

        async function Obtener(){

            await ObtenerProcesos().then( response => {

                if( response ){

                    if( response.status === 200 ){
    
                        setProcesosArray( response.data );
    
                    }
                }

            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){

            setUsuario( 0 );
            setUsuarioArray( [] );

            await ObtenerUsuariosReasignarCancelar( autoridad ).then( response => {

                if( response ){

                    if( response.status === 200 ){
    
                        setUsuarioArray( response.data );
    
                    }
                }

            });

        }

        Obtener();

    }, [ autoridad ]);

    useEffect(() => {
        
        async function Obtener(){

            setUsuarioAsignacion( 0 );
            setUsuarioAsignacionArray( [] );

            await ObtenerUsuariosReasignarCancelar( autoridadReasignar ).then( response => {

                if( response ){

                    if( response.status === 200 ){
    
                        setUsuarioAsignacionArray( response.data );
    
                    }
                }

            });

        }

        Obtener();

    }, [ autoridadReasignar ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }
      
        Obtener();        

    }, [ ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }} >
                        
                <Container maxWidth="lg" sx={{ mt: 1, pb: 2 }} >
                        
                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12}>

                            <Card>

                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Criterios de búsqueda </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3} >

                                        <Grid item md={3} xs={12} >

                                            <TextField
                                                fullWidth
                                                label="Número de seguimiento"
                                                name="noSeguimiento"
                                                variant="outlined"
                                                autoComplete='off'
                                                value={ numeroSeguimiento }                                
                                                onChange={ ( e ) => setNumeroSeguimiento( e.target.value.toUpperCase() ) }
                                                disabled={ filtroAplicado }
                                            /> 

                                        </Grid>

                                        <Grid item md={5} xs={12}> 
                                                    
                                            <FormControl fullWidth>
                                                <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                            
                                                    <Select
                                                        labelId="select-autoridad-label"
                                                        id="select-autoridad"                                            
                                                        label="Autoridad"
                                                        name="autoridad"
                                                        value={ autoridad }                                
                                                        onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                        disabled={ filtroAplicado }  
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                        {
                                                            autoridadArray.map( ( elem ) => (
                                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                            ))
                                                        }                          
                                                    </Select>
                
                                            </FormControl>
                                                                        
                                        </Grid>

                                        <Grid item md={4} xs={12} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-usuarios-label">Usuarios</InputLabel>
                                            
                                                <Select
                                                    labelId="select-usuarios-label"
                                                    id="select-usuarios"                                            
                                                    label="Usuarios"
                                                    name="usuarios"
                                                    value={ usuario }                                
                                                    onChange={ ( e ) => setUsuario( e.target.value ) }
                                                    disabled={ filtroAplicado || autoridad === 0 }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        usuarioArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                        ))
                                                    }                                          
                                                </Select>

                                            </FormControl>

                                        </Grid>

                                        <Grid item md={6} xs={12} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-proceso-label">Proceso (Módulo)</InputLabel>
                                            
                                                <Select
                                                    labelId="select-proceso-label"
                                                    id="select-proceso"                                            
                                                    label="Proceso (Módulo)"
                                                    name="proceso"
                                                    value={ proceso }                                
                                                    onChange={ ( e ) => setProceso( e.target.value ) }
                                                    disabled={ filtroAplicado }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        procesosArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idProceso } value={ elem.idProceso }> { elem.siglas } - { elem.descripcion } </MenuItem>    
                                                        ))
                                                    }                                          
                                                </Select>

                                            </FormControl>

                                        </Grid>

                                        <Grid item md={6} xs={12}>

                                             <LoadingButton 
                                                type="button" 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                                loading={ loadingSearch }
                                                disabled={ numeroSeguimiento === '' && usuario === 0 }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>

                                    </Grid>    
                                
                                </CardContent>    

                            </Card>

                        </Grid> 

                        <Grid item md={12} xs={12}>

                            <Card>

                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Resultado(s) </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3} >

                                        <Grid item md={12} xs={12} sx={{ mt: 2 }} >

                                            <TableContainer>

                                                <Table>

                                                    <TableHead>

                                                        <TableRow>

                                                            <TableCell style={{ width: '5%', fontWeight: 'bold' }}> NO. SEGUIMIENTO </TableCell>
                                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}> USUARIO </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}> PROCESO (MÓDULO) </TableCell>                                            
                                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}> ACTIVIDAD </TableCell>                                            
                                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}> FECHA DE CREACIÓN </TableCell>                                            
                                                            <TableCell style={{ width: '5%', fontWeight: 'bold' }}> CANCELAR </TableCell>                                                                                        
                                                            <TableCell style={{ width: '5%', fontWeight: 'bold' }}> REASIGNAR </TableCell>  

                                                        </TableRow>

                                                    </TableHead>

                                                    <TableBody>

                                                        {
                                                            (
                                                                rowsPerPage > 0
                                                                ?
                                                                rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                                :
                                                                rows
                                                            ).map( ( row ) => (

                                                                <TableRow key={ row.id } >                                                                    
                                                                   
                                                                   <TableCell sx={{ fontWeight: 'bold' }}> { row.numeroSeguimiento } </TableCell>
                                                                   <TableCell> { row.usuario } </TableCell>
                                                                   <TableCell> { row.proceso } </TableCell>
                                                                   <TableCell> { row.actividad } </TableCell>
                                                                   <TableCell> { row.fecha } </TableCell>

                                                                   <TableCell sx={{ textAlign: 'center' }}> 
                                                                
                                                                        {
                                                                            row.actividad !== 'Proceso cancelado'
                                                                            &&
                                                                                <IconButton onClick={ () => { setReferencia( row.numeroSeguimiento ); setOpenCancelar( true ); } }>
                                                                                        <ClearIcon color='primary' />
                                                                                </IconButton>
                                                                        }

                                                                   </TableCell>
                                                                   
                                                                   <TableCell sx={{ textAlign: 'center' }}> 
                                                                        
                                                                        {
                                                                            row.actividad !== 'Proceso cancelado'
                                                                            &&
                                                                                <IconButton onClick={ () => { setAutoridadReasignar( row?.idAutoridad ?? 0 ); setReferencia( row.numeroSeguimiento ); setRef( row.idRef ); setEstatus( row.idEstatus ); setOpenAsignacion( true ); } }>
                                                                                    <LowPriorityIcon color='primary' />
                                                                                </IconButton>
                                                                        }
                                                                   
                                                                   </TableCell>

                                                                </TableRow>
                                                            ))
                                                        }

                                                        { 
                                                            rows.length === 0 && (
                                                                <TableRow style={{ height: 53 }}>

                                                                    <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                                        {
                                                                            loading
                                                                            ?
                                                                                <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                            :
                                                                                null
                                                                        }
                                                                        {
                                                                            !loading && rows.length === 0
                                                                            ?
                                                                                'No se encontraron trámites'
                                                                            :
                                                                                ''
                                                                        }
                                                                    </TableCell>

                                                                </TableRow>
                                                            )
                                                        }

                                                    </TableBody>

                                                    <TableFooter>

                                                        <TableRow>

                                                            <TableCell colSpan={2}>
                                                                <Typography variant='body2' color='black'> Total de registros: { rows.length } </Typography>
                                                            </TableCell> 
                                                        
                                                            <TablePagination 
                                                                colSpan={8}
                                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                                count={ rows.length }
                                                                rowsPerPage={ rowsPerPage }
                                                                page={ page }
                                                                onPageChange={ handleChangePage }
                                                                onRowsPerPageChange={ handleChangeRowsPerPage }

                                                                ActionsComponent={ TablePaginationActions }

                                                                labelRowsPerPage="Filas por página"                                            
                                                                labelDisplayedRows={
                                                                    ({ from, to, count }) => {
                                                                    return '' + from + '-' + to + ' de ' + count
                                                                    }
                                                                }                                                                                       
                                                            />
                                                        </TableRow>

                                                    </TableFooter>

                                                </Table>
                                                
                                            </TableContainer>

                                        </Grid>      

                                    </Grid>    
                                
                                </CardContent>    

                            </Card>

                        </Grid>             

                    </Grid>

                </Container>               
              
            </Box>

            <Dialog open={ openCancelar} >

                <DialogTitle>
                    Confirmación
                </DialogTitle>

                <DialogContent>
                    ¿Desea cancelar el proceso seleccionado?
                </DialogContent>

                <DialogActions>

                    <Button onClick={ handleClose }>Cancelar</Button>
                    
                    <LoadingButton 
                        variant="contained" 
                        color="primary" 
                        onClick={ handleGuardarCancelacion } 
                        loading={ loadingCancelacion }
                    >
                        Aceptar                        
                    </LoadingButton>

                </DialogActions>

            </Dialog>

            <Dialog open={ openAsignacion } maxWidth='sm' fullWidth={true}>

                <DialogContent>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="select-usuarios-label">Usuarios disponibles</InputLabel>
                    
                        <Select
                            labelId="select-usuarios-label"
                            id="select-usuarios"                                            
                            label="Usuarios disponibles"
                            name="usuarios"
                            value={ usuarioAsignacion }                                
                            onChange={ ( e ) => { setUsuarioAsignacion( e.target.value ); } }
                        >
                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>    
                            {
                                usuarioAsignacionArray.map( ( elem ) => (
                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                ))
                            }                                             
                        </Select>

                    </FormControl>

                    {   
                        errors.asignacion && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.asignacion  } </Alert> )
                    }

                </DialogContent>

                <DialogActions>

                    <Button onClick={ handleClose }>Cancelar</Button>
                    
                    <LoadingButton 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ handleGuardarAsignacion } 
                        loading={ loadingAsignacion }
                    >
                        Guardar                        
                    </LoadingButton>

                </DialogActions>

            </Dialog>
            
        </>
    )
}
