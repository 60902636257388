import React, { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';

import SourceIcon from '@mui/icons-material/Source';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';
import { ModalDetalleAmparo } from '../modals/ModalDetalleAmparo';
import { ModalDetalleIrregular } from '../modals/ModalDetalleIrregular';
import { ModalDetalleExhortoRecibido } from '../modals/ModalDetalleExhortoRecibido';
import { ModalDetalleExhortoLibrado } from '../modals/ModalDetalleExhortoLibrado';

import { ConsultaAcuerdos } from '../../actions/tribunal/acuerdoActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';
import { useSelector } from 'react-redux';

export const AcuerdosPendientesScreen = () => {
    
    const { administrador, coordinador } = useSelector( state => state.auth );

    const [rows, setRows] = useState( [ ] );
    
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage ] = usePages( 10 );

    const [loading, setLoading] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    
    const [openModal, setOpenModal] = useState( false );
    const [openDetalleAmparo, setOpenDetalleAmparo] = useState( false );
    const [openDetalleIrregular, setOpenDetalleIrregular] = useState( false );
    const [openDetalleExhortoRecibido, setOpenDetalleExhortoRecibido] = useState( false );
    const [openDetalleExhortoLibrado, setOpenDetalleExhortoLibrado] = useState( false );

    const [expediente, setExpediente] = useState( 0 );
    const [amparo, setAmparo] = useState( 0 );
    const [irregular, setIrregular] = useState( 0 );
    const [exhortoRecibido, setExhortoRecibido] = useState( 0 );
    const [exhortoLibrado, setExhortoLibrado] = useState( 0 );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );


    const handleMostrarInformacion = ( tipoTramite, id ) => {

        if( tipoTramite === 'Demanda o Escrito Inicial' || tipoTramite === 'Promoción a expediente' ){
            setExpediente( id );
            setOpenModal( true );
        }
        else if( tipoTramite === 'Amparo' || tipoTramite === 'Promoción amparo' ){
            setAmparo( id );
            setOpenDetalleAmparo( true );
        }
        else if( tipoTramite === 'Irregular' || tipoTramite === 'Convenio fuera de juicio' || tipoTramite === 'Promoción a irregular' ){
            setIrregular( id );
            setOpenDetalleIrregular( true );
        }
        else if( tipoTramite === 'Exhorto recibido' || tipoTramite === 'Promoción a exhorto recibido' ){
            setExhortoRecibido( id );
            setOpenDetalleExhortoRecibido( true );
        }
        else if( tipoTramite === 'Exhorto librado' || tipoTramite === 'Promoción a exhorto librado' ){
            setExhortoLibrado( id );
            setOpenDetalleExhortoLibrado( true );
        }

    }

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaAcuerdos( { page, rowsPerPage, idAutoridad: autoridad } ).then( response => {
                    
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );                              
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });

        }            
       
        Obtener();        

    }, [ page, rowsPerPage, autoridad ]);   


    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);


    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  
                
                <Container maxWidth="xl">
                    
                    <Grid container spacing={3} >
                        <Grid item lg={12} md={12} xs={12}>
                        {
                            ( administrador || coordinador )
                            && 
                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3}>
                                        
                                        <Grid item md={4} sm={6} xs={12}> 
                                                        
                                            <FormControl fullWidth>
                                                <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                            
                                                    <Select
                                                            labelId="select-autoridad-label"
                                                            id="select-autoridad"                                            
                                                            label="Autoridad"
                                                            name="autoridad"
                                                            value={ autoridad }                                
                                                            onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        coordinador
                                                        ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                ))
                                                                :
                                                                    autoridadArray.map( ( elem ) => (
                                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                ))
                                                    }                          
                                                                </Select>

                                            </FormControl>
                                                        
                                        </Grid>

                                    </Grid>

                                </CardContent>

                            </Card>
                        }

                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ pt: 3, pb: 3 }} variant="h4" >
                                        Acuerdos pendientes
                                    </Typography>
                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >

                                        <TableHead>

                                            <TableRow>      

                                                <TableCell style={{ width:'7%', fontWeight: 'bold', textAlign: 'center' }}> No. SEGUIMIENTO </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> FECHA </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> REFERENCIA </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> TIPO DE TRÁMITE </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> TIPO DE ACUERDO </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> ASIGNADO A </TableCell>
                                                <TableCell style={{ width:'5%', fontWeight: 'bold', textAlign: 'center' }}>  </TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                rows.map( ( row, i ) => (

                                                    <TableRow key={ i } >

                                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}> { row.numeroSeguimiento } </TableCell>    

                                                        <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>                                              

                                                        <TableCell>                              

                                                            {
                                                                row?.referencia
                                                                &&                                                            
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { handleMostrarInformacion( row.tipoTramite, row.idReferencia ); } }
                                                                    sx={{ textAlign: 'start' }}
                                                                >
                                                                    { row?.referencia ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell>

                                                        <TableCell> { row.tipoTramite } </TableCell>
                                                        
                                                        <TableCell> { row.tipoAcuerdo } </TableCell>
                                                        
                                                        <TableCell> { row.usuarioAsignado } <br /> <strong>Actividad: { row.estatus }</strong> </TableCell>

                                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}> { row.duracion } días </TableCell>

                                                    </TableRow>

                                                ))
                                            }

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>

                                                        <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron acuerdos pendientes'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TableCell colSpan={2}>
                                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de acuedos pendientes: { recordsCount } </Typography>
                                                </TableCell> 

                                                <TablePagination
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    colSpan={ 10 }
                                                    count={ recordsCount }                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={ page }                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />

                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Paper>

                        </Grid>

                    </Grid>                   

                </Container>

            </Box>

            <ModalDetalleExpediente 
                open={ openModal } 
                setOpen={ setOpenModal } 
                idExpediente={ expediente } 
            />

            <ModalDetalleAmparo 
                open={ openDetalleAmparo }    
                setOpen={ setOpenDetalleAmparo }
                idAmparo={ amparo }
            />

            <ModalDetalleIrregular
                open={ openDetalleIrregular }    
                setOpen={ setOpenDetalleIrregular }
                idIrregular={ irregular }
            />

            <ModalDetalleExhortoRecibido
                open={ openDetalleExhortoRecibido}    
                setOpen={ setOpenDetalleExhortoRecibido }
                idExhortoRecibido={ exhortoRecibido }
            />

            <ModalDetalleExhortoLibrado
                open={ openDetalleExhortoLibrado}    
                setOpen={ setOpenDetalleExhortoLibrado }
                idExhortoLibrado={ exhortoLibrado }
            />

        </>
    )
}