import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import thunk from 'redux-thunk';

import { acuerdoReducer } from '../reducers/acuerdoReducer';
import { audienciasReducer } from '../reducers/audienciasReducer';
import { authReducer } from '../reducers/authReducer';
import { loadingReducer } from '../reducers/loadingReducer';
import { recepcionReducer } from '../reducers/recepcionReducer';
import { userReducer } from '../reducers/userReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    auth: authReducer,
    audiencias: audienciasReducer,
    loading: loadingReducer,
    user: userReducer,
    recepcion: recepcionReducer,
    acuerdo: acuerdoReducer,
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);