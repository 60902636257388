import React, { useRef, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Paper, Select, Snackbar, Typography,FormControlLabel, FormLabel, FormGroup, Checkbox, Autocomplete, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';
import { ActualizarConvocatoria, ObtenerConvocatoriaDetalle, ActualizarEstatusConvocatoria } from '../../../actions/tribunal/convocatoriaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerDependenciasConDomicilio } from '../../../actions/admin/catalogs/dependenciaActions';
import { ModalDomicilioConvocatoria } from '../../modals/ModalDomicilioConvocatoria';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const ElaborarConvocatoriaScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const { tribunal } = useSelector( state => state.auth );

    const editorRef = useRef(null);

    const [loading, setLoading] = useState( false );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [activarFinalizar, setActivarFinalizar] = useState(true);

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [convocatoria, setConvocatoria] = useState( {} );   

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [richText, setRichText] = useState( '' ); 

    const [loadingGuardar, setLoadingGuardar] = useState( false );  
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 
    const [cadenaExtintos, setCadenaExtintos] = useState( '' ); 

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [observaciones, setObservaciones] = useState( {} );

    const [exhorto, setExhorto] = useState( false );
    const [estrados, setEstrados] = useState( false );
    const [centroTrabajo, setCentroTrabajo] = useState( false );
   
    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaTemp, setDependenciaTemp ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );
    const [autocompleteValue, setAutocompleteValue] = useState( null );
    const [domicilioCentroTrabajo, setDomicilioCentroTrabajo] = useState( { idDomicilio: 0, domicilio: {} } );

    const [openModalDomicilio, setOpenModalDomicilio] = useState(false);

    const GuardarConvocatoria = async () => {
    
        const valid = validateFiels();
        
        if( !valid ){
            return false;
        }
        
        const contenido = editorRef.current.getContent();
        const params = {
            'idConvocatoria' : Id,
            'contenido': contenido,
            'idPlantilla' : plantilla,
            'exhorto' : exhorto,
            'estrados' : estrados,
            'centroTrabajo': centroTrabajo,
            'idDependencia': dependencia,
        };

        setLoadingGuardar( true );

        await ActualizarConvocatoria( params ).then( response => {

            if( response ){

                const { message } = response.data;

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        
                        setActivarFinalizar(false);
                        setDependenciaTemp( dependencia );

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: message ? message : 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        const contenido = editorRef.current.getContent();

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        } 

        if( plantilla === 0  ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar la plantilla';
        }   

        if( exhorto === false && centroTrabajo === false && estrados === false ){      
            valid = false;
            errores.enviarConvocatoria = 'Debes seleccionar una opción para públicar la convocatoria';
        }   
        
        if( centroTrabajo === true && dependencia === 0 ){      
            valid = false;
            errores.centroTrabajo = 'Debes seleccionar un centro de trabajo';
        }   

        setErrors( errores );
        return valid;
    }

    const Finalizar = async () => {
              
        setLoadingFinalizar( true );

        const params = {
            'idConvocatoria' : Id,
            'idEstatus': observaciones?.idEstatusAnterior ? observaciones?.idEstatusAnterior : 74,
        };

        await ActualizarEstatusConvocatoria( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {                        

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }          

                        setLoading( true );                        

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', convocatoria.tipoJuicio ?? "" )
                            .replaceAll('[Ponencia]', convocatoria.ponencia ?? "" )
                            .replaceAll('[Numero_Expediente]', convocatoria.numeroExpediente ?? "" )
                            .replaceAll('[Ciudad]',convocatoria.ciudad ?? "")
                            .replaceAll('[Juez]',convocatoria.juez ?? "")
                            .replaceAll('[Extinto]', cadenaExtintos );

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }   

    const handleChangeCentro = ( value ) => {

        if( value === false){
            setDependencia( 0 );
            setAutocompleteValue( null );
            setDomicilioCentroTrabajo( { idDomicilio: 0, domicilio: {} } );
        }

        setCentroTrabajo( value );
    }

    const handleChangeAutoComplete = ( value ) => {

        setDependencia( value?.idDependencia ?? 0 ); 
        setAutocompleteValue( value );
        setDomicilioCentroTrabajo( { idDomicilio: 0, domicilio: {} } );

        const { idDomicilio, domicilio } = dependenciaArray.find( (e) => e.idDependencia === ( value?.idDependencia ?? 0 ) );

        if( domicilio ){
            setDomicilioCentroTrabajo({ idDomicilio, domicilio });
        }   

    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idTipoAcuerdo': 30,
                'idSubTipoAcuerdo': 0,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependenciasConDomicilio().then( response => {

                if( response ){

                    if( response.status === 200){
                        setDependenciaArray( response.data ); 
                    }
                    
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idConvocatoria': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerConvocatoriaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {                           

                            setConvocatoria(response.data);   
                            setPlantilla(response?.data?.idPlantilla);
                            setRichText(response.data.contenido);
                            setExhorto( response.data.exhorto );
                            setEstrados( response.data.estrados);
                            setCentroTrabajo( response.data.centroTrabajo); 

                            const domicilio = response?.data?.domicilioCentroTrabajo;

                            setDomicilioCentroTrabajo( { idDomicilio: domicilio?.idDomicilio ?? 0, domicilio } );           

                            setObservaciones( response?.data?.observaciones ?? {} );
                            
                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })
                            setCadenaActores(cadenaA);

                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);

                            let cadenaEX = '';
                            response.data.extintos.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.extintos.length){ 
                                    cadenaEX += (value + ', ')
                                } else {
                                    cadenaEX += (value + ' ')
                                }
                            })
                            setCadenaExtintos(cadenaEX);

                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });    
                            
                            if ( response.data.contenido !== null )
                            {
                                setActivarFinalizar(false);
                            }    
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 73 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 73){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])
    
    useEffect(() => {

        if( convocatoria && dependenciaArray.length > 0 ){

            const findCentro = dependenciaArray.find( ( e ) => e.idDependencia === convocatoria?.dependenciaCentroTrabajo?.idDependencia );

            if( findCentro ){
                setDependencia( convocatoria?.dependenciaCentroTrabajo?.idDependencia ?? 0 );
                setDependenciaTemp( convocatoria?.dependenciaCentroTrabajo?.idDependencia ?? 0 );
                setAutocompleteValue( convocatoria?.dependenciaCentroTrabajo ?? null );
            }
        }

    }, [ convocatoria, dependenciaArray ]);          

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>  

                            <InformacionSeguimiento
                                noSeguimiento={ 'GEC-' + convocatoria.idConvocatoria }
                                actividad={ 'Elaborar convocatoria' }
                                tipo={ 'Número de expediente' }
                                referencia={ convocatoria.numeroExpediente ?? "" }
                            />   

                            <Grid container spacing={3} mt={1}>   

                                {
                                    convocatoria.ponencia
                                    &&                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                    }

                                {
                                    convocatoria.ponencia
                                    &&  
                                    <Grid item md={10} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { convocatoria.ponencia }
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        convocatoria?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  

                                {
                                    convocatoria?.demandado?.length !== 0
                                    &&
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                            {
                                                convocatoria?.demandado?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>  
                                    </>
                                }                              

                                {
                                    convocatoria?.extintos?.length !== 0
                                    &&
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Extinto:
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    convocatoria?.extintos?.length !== 0
                                    &&
                                        <Grid item md={10} xs={6} >
                                            <List disablePadding>
                                            {
                                                convocatoria?.extintos?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>                           
                                }   
                                
                                {
                                    convocatoria?.centrosTrabajo?.length !== 0
                                    &&
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Centro de Trabajo:
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    convocatoria?.centrosTrabajo?.length !== 0
                                    &&
                                        <Grid item md={10} xs={6} >
                                            <List disablePadding>
                                            {
                                                convocatoria?.centrosTrabajo?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>                           
                                } 

                                <Grid item md={5} xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontWeight: 'bold', fontSize: 14 }}>Se publicará la convocatoria por:</FormLabel>
                                        <FormGroup row
                                            aria-label="exhorto"
                                            name="exhorto"                        
                                        >                                            
                                            <FormControlLabel control={<Checkbox checked={ exhorto  } onChange={ (e) => { setExhorto( e.target.checked ); } }  />} label="Exhorto" />
                                            <FormControlLabel control={<Checkbox checked={ estrados  } onChange={ (e) => { setEstrados( e.target.checked ); } }  />} label="Estrados" />
                                            <FormControlLabel control={<Checkbox checked={ centroTrabajo  } onChange={ (e) => handleChangeCentro( e.target.checked ) }  />} label="Centro de trabajo" />
                                        </FormGroup>
                                    </FormControl>
                                    {   
                                        errors.enviarConvocatoria && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.enviarConvocatoria } </Alert> )
                                    }
                                </Grid> 

                                {
                                    tribunal && centroTrabajo
                                    &&   
                                        <Grid item md={7} xs={12} sx={{ mt: 1 }} >
                                            
                                            <Autocomplete                    
                                                disablePortal 
                                                fullWidth              
                                                value={ autocompleteValue }   
                                                options={ dependenciaArray }
                                                onChange={ (event, value) => { handleChangeAutoComplete( value ); } }                        
                                                getOptionLabel={ (option) => option.descripcion}
                                                isOptionEqualToValue={(option, value) => option.idDependencia === value.idDependencia }
                                                renderOption={ ( props, option ) => (
                                                    <li { ...props } key={ option.idDependencia} >                         
                                                        { option.descripcion }
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Centro de Trabajo" />}              
                                            />

                                            {   
                                                errors.centroTrabajo && ( 
                                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > 
                                                        { errors.centroTrabajo } 
                                                    </Alert> 
                                                )
                                            }

                                            {
                                                ( domicilioCentroTrabajo?.idDomicilio !== 0 )
                                                ?         
                                                    <Grid container spacing={3}>

                                                            <Grid item md={8}>

                                                                <Typography fontSize={14} fontWeight={'bold'} mt={1}>
                                                                    {
                                                                        domicilioCentroTrabajo?.domicilio?.descripcion + 
                                                                        ( domicilioCentroTrabajo?.domicilio?.numero ? ' #' + ( domicilioCentroTrabajo?.domicilio?.numero ?? '' ) : '') + ', ' +
                                                                        domicilioCentroTrabajo?.domicilio?.asentamiento?.descripcion + ', C.P ' + 
                                                                        domicilioCentroTrabajo?.domicilio?.asentamiento?.codigoPostal + ', ' + 
                                                                        domicilioCentroTrabajo?.domicilio?.asentamiento?.municipio?.descripcion + ', ' + 
                                                                        domicilioCentroTrabajo?.domicilio?.asentamiento?.estado?.descripcion
                                                                    }
                                                                </Typography>               

                                                            </Grid>

                                                            <Grid item md={4} mt={2} textAlign={'end'}>
                                                                <Button
                                                                    size='small'
                                                                    variant='contained'
                                                                    onClick={ () => setOpenModalDomicilio( true ) }
                                                                >
                                                                    Actualizar domicilio
                                                                </Button>
                                                            </Grid>

                                                        </Grid>                  
                                                :
                                                    dependencia !== 0
                                                    &&
                                                        <Grid container spacing={3}>

                                                            <Grid item md={8}>

                                                                <Typography fontSize={14} fontWeight={'bold'} mt={1}>
                                                                    No se encontro un domicilio
                                                                </Typography>       

                                                            </Grid>

                                                            <Grid item md={4} mt={1} textAlign={'end'}>
                                                                <Button
                                                                    size='small'
                                                                    variant='contained'
                                                                    onClick={ () => setOpenModalDomicilio( true ) }
                                                                >
                                                                    Agregar domicilio
                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                                                  
                                            }

                                        </Grid>  
                                }

                                <Grid item md={12} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                    
                                        <Select
                                            labelId="select-plantilla-label"
                                            id="select-plantilla"                                            
                                            label="Plantilla"
                                            name="plantilla"
                                            value={ plantilla }
                                            onChange={ (e) => {handleChangePlantilla(e.target.value)} }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                plantillaArray.map((elem)=>(
                                                    <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {   
                                            errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                        }
                                    </FormControl>
                                </Grid>  
                                
                                <Grid item md={12} xs={12} >  
                                    <Editor
                                        onInit = { (evt, editor) => editorRef.current = editor }
                                        initialValue = {richText}
                                        apiKey = { ObtenerApiKeyRichText( process.env ) }
                                        init = {
                                            {
                                                height: 450,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount pagebreak'
                                                ],
                                                toolbar: 'undo redo | ' +
                                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                    'removeformat | print pagebreak |  Atajos',
                                                    setup: (editor) => {                                        
                                                        editor.ui.registry.addMenuButton('Atajos', {
                                                            icon: 'bookmark' ,
                                                            tooltip: "Atajos",
                                                            fetch: function (callback) {
                                                                var items = [];
                                                                
                                                                if( convocatoria?.ponencia ){
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                      
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + convocatoria.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Extinto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaExtintos +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+convocatoria.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ convocatoria.ponencia +'</strong>'); } },
                                                                    ];
                                                                }
                                                                else{                                                                
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                       
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + convocatoria.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Extinto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaExtintos +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+convocatoria.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Juez',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+convocatoria.juez+'</strong>'); } },
                                                                    ];
                                                                }
                                                                callback(items);
                                                            }
                                                        });
                                                    },                                                 
                                                    content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }',  
                                            }
                                        }  
                                    />
                                </Grid>     
                                {   
                                    errors.contenido && ( 
                                        <Grid item md={12} xs={12}>
                                            <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                        </Grid>
                                    )
                                }

                                {
                                    observaciones.observacion
                                    &&
                                    <>
                                        <Grid item md={3} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight:'bold'  }}>
                                                Observaciones:  
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                <strong>{ observaciones.usuario }:</strong> { observaciones.observacion }
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ GuardarConvocatoria } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                    > 
                                        Guardar convocatoria
                                    </LoadingButton>

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                    <Button 
                                        variant="contained" 
                                        onClick={ handleOpenConfirmacion } 
                                        endIcon={ <CheckCircleIcon /> } 
                                        disabled = { 
                                            activarFinalizar || 
                                            ( centroTrabajo && domicilioCentroTrabajo?.idDomicilio === 0 ) ||
                                            ( !exhorto && !estrados && !centroTrabajo ) ||
                                            plantilla === 0 ||
                                            ( centroTrabajo && dependencia !== dependenciaTemp )
                                        }
                                    > 
                                        Finalizar 
                                    </Button>
                                </Grid>       

                            </Grid>   
                           
                        </Container>

                    )
                }

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar el registro de la convocatoria?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained' 
                            loading={ loadingFinalizar }
                        > 
                            Aceptar 
                        </LoadingButton>

                    </DialogActions>

                </Dialog>

                <ModalDomicilioConvocatoria 
                    openModal={ openModalDomicilio } 
                    handleCloseModal={ () => setOpenModalDomicilio( false )  }    
                    dependencia={ autocompleteValue }
                    domicilio={ domicilioCentroTrabajo?.domicilio ?? {} }
                    SeleccionarDomicilio={ ( value ) => setDomicilioCentroTrabajo( value ) }
                />

            </Box>
        </>
    )
}
