import React, { useRef, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import AppBarMenu from '../../ui/AppBarMenu';

import { ProyectoSentenciaDetalle, ActualizarProyectoSentencia, ActualizarEstatusProyectoSentencia } from '../../../actions/sentencias/proyectoSentenciaActions'; 
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const AutorizarProyectoSentenciaScreen = ({ match }) => {

    const { tribunal } = useSelector( state => state.auth );

    const editorRef = useRef(null);

    const dispatch = useDispatch();
    
    const idProyectoSentencia = match?.params?.Id;

    const [autorizar, setAutorizar] = useState(true);

    const [observacion, setObservacion] = useState( '' );    

    const [sendAjax, setSendAjax] = useState(false);
    const [errors, setErrors] = useState( { } );

    const [loadingRegresarEscrito, setLoadingRegresarEscrito] = useState( false );  
    const [loadingFinalizar, setLoadingFinalizar] = useState( false ); 
    const [proyectoSentencia, setProyectoSentencia] = useState( { } );     
    
    const [loadingGuardar, setLoadingGuardar] = useState( false ); 
    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 
    
    const [alert, setAlert] = useState( {  } );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [richText, setRichText] = useState( '' ); 
    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };  

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', proyectoSentencia.tipoJuicio ?? "")
                            .replaceAll('[Ponencia]', proyectoSentencia.ponencia ?? "")                            
                            .replaceAll('[Numero_Expediente]', proyectoSentencia.numeroExpediente ?? "")                        

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }
        
    const GuardarProyecto = async () => {
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            'idProyectoSentencia' : idProyectoSentencia,
            'contenido': contenido,  
            'idPlantilla' : plantilla,         
        };

        setLoadingGuardar( true );

        await ActualizarProyectoSentencia( params ).then( response => {

            if( response ){               

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        
                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    const Finalizar = async () => {           

        setLoadingFinalizar(true);

        const params = {
            'idProyectoSentencia' : idProyectoSentencia,
            'idEstatus': proyectoSentencia.seRealizaronObservaciones ? 26 : 25
        };

        await ActualizarEstatusProyectoSentencia ( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                            setLoadingFinalizar( false );
                            
                            if( !response.data.usuarioActualAsignacion ){                            
                                setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                                setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                            }     

                            setSendAjax( true );  
            
                    }, 1000);

                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar(false);

                    }, 1000);  
                }
            }
        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const Regresar = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
        
        setLoadingRegresarEscrito( true );
        
        const params = {
            'idProyectoSentencia' : idProyectoSentencia,
            'idEstatus': 22,
            'observacion': observacion
        };

        await ActualizarEstatusProyectoSentencia ( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoadingRegresarEscrito( false );
                        
                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }      

                        setSendAjax( true );  

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingRegresarEscrito( false );

                    }, 1000);  
                }
            }
        });

    }

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        if( autorizar === false && observacion.trim()  === '' ) {
            valid = false;
            errores.observaciones = 'Debes teclear un motivo de rechazo';
        } 
        

        setErrors( errores );
        return valid;
    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idProyectoSentencia': idProyectoSentencia,               
            };

            dispatch({ type: types.openLoading });

            await ProyectoSentenciaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                         setTimeout(() => {

                            setProyectoSentencia(response.data);                            
                            setRichText(response.data.contenido);     
                            
                            setPlantilla(response.data.idPlantilla);
                            
                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response.data.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })

                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response.data.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })

                            setCadenaActores(cadenaA);
                            setCadenaDemandados(cadenaD);

                            dispatch({ type: types.closeLoading });

                            setAjaxLoading( true );

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 23 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 23){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setSendAjax( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ idProyectoSentencia, dispatch ])

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idTipoAcuerdo': 5,
                'idSubTipoAcuerdo': 30,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ])

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

            {
                sendAjax 
                ?                            
                (
                    <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                        <Container maxWidth="lg" >                        

                            <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                
                                <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                
                                    <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                        Se finalizó la actividad
                                    </Typography>

                                    <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                        { mensajeFinalizacion }
                                    </Typography>

                                    {
                                        usuarioAsignacion
                                        ?
                                            <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                { usuarioAsignacion }
                                            </Typography>
                                        :
                                        null
                                    }

                                </CardContent>

                            </Card>                   
                                                    
                        </Container>                    

                    </Box>   
                ) 
                
                :

                (
                        
                    <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>

                        <InformacionSeguimiento 
                            noSeguimiento={ 'GPS-' + proyectoSentencia?.id }
                            actividad={ 'Autorizar proyecto de sentencia' }
                            tipo={ 'Número de expediente' }
                            referencia={ proyectoSentencia?.numeroExpediente }
                        />
                            
                        <Grid container spacing={3} mt={1}>
                           
                            {
                                tribunal &&
                                    <Grid item md={2} xs={6}>                               
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                            Ponencia:
                                        </Typography>  
                                    </Grid>
                            }

                            {   
                                tribunal &&
                                    <Grid item md={10} xs={6} >                                    
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { proyectoSentencia?.ponencia }
                                        </Typography>   
                                    </Grid>  
                            }

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Actor(es):
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                
                                <List disablePadding> 
                                {
                                    proyectoSentencia?.actor?.map((elem, i)=>(
                                        <ListItem key={ i } disablePadding>  
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                            { elem }
                                        </Typography>      
                                    </ListItem>
                                    ))
                                }                                     
                                                    
                                </List>

                            </Grid>    
                            
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Demandado(s):
                                </Typography>
                            </Grid>
                            
                            <Grid item md={4} xs={6} >

                                <List disablePadding>
                                {
                                    proyectoSentencia?.demandado?.map((elem, i)=>(
                                        <ListItem key={ i } disablePadding>  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                { elem }
                                            </Typography>      
                                        </ListItem>
                                    ))
                                }

                                </List>
                            </Grid>   

                            <Grid item md={12} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                
                                    <Select
                                        labelId="select-plantilla-label"
                                        id="select-plantilla"                                            
                                        label="Plantilla"
                                        name="plantilla"
                                        value={ plantilla }
                                        onChange={ (e) => {handleChangePlantilla(e.target.value)} }
                                        disabled={ true }
                                    >
                                        <MenuItem value={0}>Selecciona una opción</MenuItem>
                                        {
                                            plantillaArray.map((elem)=>(
                                                <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>
                            </Grid> 

                            {   
                                errors.plantilla && ( 
                                    <Grid item md={12} xs={12}>
                                        <Alert color='warning' severity="warning" variant="filled"> { errors.plantilla } </Alert> 
                                    </Grid>
                                )
                            }       

                            <Grid item md={12} xs={12} >  
                                <Editor
                                    onInit = { (evt, editor) => editorRef.current = editor }
                                    initialValue = {richText}
                                    apiKey = { ObtenerApiKeyRichText( process.env ) }
                                    init = {
                                        {
                                            height: 450,
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount pagebreak'
                                            ],
                                            toolbar: 'undo redo | ' +
                                                'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                'removeformat | print pagebreak | Atajos',
                                                setup: (editor) => {                                        
                                                    editor.ui.registry.addMenuButton('Atajos', {
                                                        icon: 'bookmark' ,
                                                        tooltip: "Atajos",
                                                        fetch: function (callback) {
                                                            
                                                            var items = [];
                                                            
                                                            if( tribunal ){
                                                                items = 
                                                                [ 
                                                                    { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                    { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + proyectoSentencia.numeroExpediente + '</strong>'); } },
                                                                    { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                    { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                    { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+proyectoSentencia.tipoJuicio +'</strong>'); } },                                                                    
                                                                    { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ proyectoSentencia.ponencia +'</strong>'); } },                     
                                                                ];
                                                            }
                                                            else{
                                                                items = 
                                                                [ 
                                                                    { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                    { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + proyectoSentencia.numeroExpediente + '</strong>'); } },
                                                                    { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                    { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                    { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+proyectoSentencia.tipoJuicio +'</strong>'); } },
                                                                ];
                                                            }                                                            
                                                            callback(items);
                                                        }
                                                    });
                                                },                                                                                             
                                                content_style: 'body { font-family:Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                        }
                                    }  
                                />
                            </Grid>  

                            {/* {
                                !proyectoSentencia?.seRealizaronObservaciones
                                && */}
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ GuardarProyecto } 
                                        startIcon={ <SaveIcon /> } 
                                        loading={ loadingGuardar }
                                    > 
                                        Guardar proyecto
                                    </LoadingButton>
                                </Grid>  
                            {/* }    */}

                            {
                                proyectoSentencia?.observacionesRealizadas?.length !== 0
                                &&                            
                                <Grid item md={12} xs={12} sx={{ mt: 1 }}> 
                                
                                    <Grid container spacing={3}>

                                        <Grid item md={3} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Observaciones:                                        
                                            </Typography>
                                        </Grid>

                                        <Grid item md={8} xs={6} >

                                            <Grid container spacing={3} >                                    
                                                {                                                
                                                    proyectoSentencia.observacionesRealizadas.map( ( element ) => (
                                                        <Grid key={ element.id } item md={12} xs={12} >
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                                <strong> { element.usuario}: </strong>
                                                                <br />
                                                                    { element.observacion ? element.observacion : 'No añadió observaciones' }
                                                                <br />
                                                            </Typography>
                                                        </Grid>
                                                    ))                                                
                                                }                                                
                                            </Grid>
                                            
                                        </Grid>

                                    </Grid>   
                                        
                                </Grid>                       
                            }

                            {/* {
                                !proyectoSentencia?.seRealizaronObservaciones
                                && */}
                                <Grid item md={4} xs={12} sx={{ mt: 1 }} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">¿Se autoriza el proyecto de sentencia?</FormLabel>
                                        <RadioGroup row
                                            aria-label="seRevisaProyecto"
                                            defaultValue="si"
                                            name="radio-buttons-group"  
                                        >
                                            <FormControlLabel value="si" control={ <Radio checked={ autorizar === true } onChange={ () => { setAutorizar(true); setObservacion(''); setErrors({}); } } />} label="Si" />
                                            <FormControlLabel value="no" control={ <Radio checked={ autorizar === false } onChange={ () => { setAutorizar(false); setObservacion(''); setErrors({}); } } />} label="No" />                                                                               
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            {/* } */}

                            {
                                    !autorizar && (
                                        <>
                                        <Grid item md={8} xs={12} sx={{ mt: 1 }} >
                                            <TextField                              
                                                label="Motivo"                                                     
                                                multiline
                                                rows={4}  
                                                fullWidth                          
                                                name="observacion"   
                                                variant="outlined"
                                                value={ observacion }
                                                onChange={ ( e ) => { setObservacion( e.target.value ) }}
                                            />
                                            {   
                                                errors.observaciones && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.observaciones } </Alert> )
                                            }
                                        </Grid>
                                        
                                        </>
                                    )
                            }    
                            
                            <Grid item md={12} xs={12} sx={{ mt: 1 }}> 
                                
                                <Grid container spacing={3}>
                                    
                                    <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 

                                        {/* {
                                            !proyectoSentencia?.seRealizaronObservaciones
                                            && */}
                                            <LoadingButton 
                                                variant="contained" 
                                                onClick={ Regresar } 
                                                startIcon={ <ArrowBackIcon />  } 
                                                disabled={ autorizar }
                                                loading={ loadingRegresarEscrito }
                                            > 
                                                Regresar escrito 
                                            </LoadingButton>
                                        {/* } */}

                                    </Grid>

                                    <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}>                                         
                                        <Button variant="contained" onClick={ handleOpenConfirmacion } endIcon={ <CheckCircleIcon /> } disabled={ !autorizar } > Finalizar </Button>                                                                            
                                    </Grid>

                                </Grid>    
                        
                            </Grid>                              

                        </Grid>                        

                    </Container>
                
                )

            }

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar la autorización del proyecto de sentencia?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained' 
                            loading={ loadingFinalizar }
                        > 
                            Aceptar 
                        </LoadingButton>

                    </DialogActions>

                </Dialog>

            </Box>

        </>
    )
}
