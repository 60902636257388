import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Snackbar, Tab, Tabs, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';

import AppBarMenu from '../../ui/AppBarMenu';

import { types } from '../../../types/types';

import { DetalleDemandaScreen } from './detalles/DetalleDemandaScreen';
import { DetallePromocionScreen } from './detalles/DetallePromocionScreen';
import { DetalleAmparoScreen } from './detalles/DetalleAmparoScreen';
import { DetalleExhortoRecibidoScreen } from './detalles/DetalleExhortoRecibidoScreen';
import { DetalleIrregularScreen } from './detalles/DetalleIrregularScreen';
import { DetalleExhortoLibradoScreen } from './detalles/DetalleExhortoLibradoScreen';

import { ActualizarEstatus, AcuerdoDetalle } from '../../../actions/tribunal/acuerdoActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const VisualizarAcuerdoScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const { expedientilloSuspension, irregular } = useSelector( state => state.acuerdo );

    const dispatch = useDispatch();

    const [noSeguimiento, setNoSeguimiento] = useState( '' );

    const [valueTab, setValueTab] = useState(0);

    const [sendAjax, setSendAjax] = useState(false);

    const [ajaxLoading, setAjaxLoading] = useState(false);
    
    const [alert, setAlert] = useState( {  } );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [documento, setDocumento] = useState( '' );
    const [documentoFirmado, setDocumentoFirmado] = useState( '' );

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [openCaratula, setOpenCaratula] = useState( false );

    const [cuentaResponse, setCuentaResponse] = useState( { } );
    const [numeroReferencia, setNumeroReferencia] = useState( '' );

    const [tabValidation, setTabValidation] = useState({ 
        idDemandaInicial: 0, 
        idExpediente: 0,
        idPromocion: 0,
        idAmparo: 0,
        idExhortoRecibido: 0,
        idExhortoLibrado: 0,
    });

    const [referencia, setReferencia] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };    

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const Finalizar = async () => {
              
        setLoadingFinalizar( true );

        const params = {
            'idAcuerdo' : Id,
            'idEstatus': 47,
            cuentaAsociada: cuentaResponse,
            numeroReferencia: numeroReferencia,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                        setSendAjax( true );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }
    
    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idAcuerdo': Id,
            };

            dispatch({ type: types.openLoading });

            await AcuerdoDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {

                            setNoSeguimiento( response.data.id ?? "" );
                            setDocumento( response.data.documento ?? "" );
                            setDocumentoFirmado( response.data.documentoFirmado ?? "" );
                            setCuentaResponse( response.data.cuentaAsociada ?? { } );  
                            
                            setNumeroReferencia( response.data.numeroReferencia ?? '' );

                            setTabValidation({ 
                                idDemandaInicial: response.data.idDemandaInicial ?? 0, 
                                idExpediente: response.data.idExpediente ?? 0, 
                                idPromocion: response.data.idPromocion ?? 0, 
                                idAmparo: response.data.idAmparo ?? 0, 
                                idExhortoRecibido: response.data.idExhortoRecibido ?? 0, 
                                idExhortoLibrado: response.data.idExhortoLibrado ?? 0, 
                                idIrregular: response.data.idIrregular ?? 0, 
                            });
                           
                            dispatch({
                                type: types.actualizarDetalleAcuerdo,                                
                                payload: {
                                    acuerdo:{                                   
                                        idDemandaInicial: response.data.idDemandaInicial ?? 0, 
                                        idExpediente: response.data.idExpediente ?? 0, 
                                        idPromocion: response.data.idPromocion ?? 0, 
                                        idAmparo: response.data.idAmparo ?? 0, 
                                        idExhortoRecibido: response.data.idExhortoRecibido ?? 0, 
                                        idExhortoLibrado: response.data.idExhortoLibrado ?? 0, 
                                        idIrregular: response.data.idIrregular ?? 0, 
                                        demandaDigitalizada: response.data.demandaDigitalizada ?? '', 
                                        exhortoDigitalizado: response.data.exhortoDigitalizado ?? '', 
                                        amparoDigitalizado: response.data.amparoDigitalizado ?? '',
                                        promocionDigitalizado: response.data.promocionDigitalizado ?? '',                                   
                                    },
                                    expediente: response.data.expediente,
                                    exhortoLibrado: response.data.exhortoLibrado,  
                                    irregular: response.data.irregular,
                                    exhortoRecibido: response.data.exhortoRecibido,
                                    amparo: response.data.amparo,
                                    promocion: response.data.promocion,
                                    expedientilloSuspension: response.data.expedientilloSuspension
                                }
                            }); 

                            dispatch({
                                type: types.actualizarNotificacionesAcuerdo,                                
                                payload: response.data.notificaciones,                              
                            });

                            dispatch({
                                type: types.actualizarPrestacionesAcuerdo,                                
                                payload: response.data.prestaciones,                                 
                            }); 

                            dispatch({
                                type: types.audienciaSetAcuerdo,                                
                                payload: response.data.id ,                              
                            });

                            if( response.data.expediente ){
                                dispatch({
                                    type: types.audienciaSetExpediente,                                
                                    payload: response.data.expediente,                              
                                });
                            }

                            if( ( response?.data?.idAmparo ?? 0 ) !== 0 ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferencia( response?.data?.amparo?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.idExhortoRecibido ?? 0 ) !== 0 ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferencia( response?.data?.exhortoRecibido?.numeroExhorto ?? '' );
                            } 
                            else if( ( response?.data?.idExhortoLibrado ?? 0) !== 0 ){
                                setTipoReferencia( 'Número de exhorto librado' );
                                setReferencia( response?.data?.exhortoLibrado?.numeroExhortoLibrado ?? '' );
                            } 
                            else if(( response?.data?.idExpediente ?? 0 ) !== 0 ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferencia( response?.data?.expediente?.numeroExpediente ?? '' );
                            }   
                            else if( ( response?.data?.idIrregular ?? 0) !== 0 ){
                                if( response?.data?.irregular?.idTipoIrregular === 1 ){
                                    setTipoReferencia( 'Número de convenio' );
                                }
                                else {
                                    setTipoReferencia( 'Número de irregular' );
                                }
                                setReferencia( response?.data?.irregular?.numeroIrregular ?? '' );
                            }                  

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 9 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 9){     
                                
                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }

                                setSendAjax( true );
                            }          
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile sx={{ pt: 2 }}>
                                <Tab label="Acuerdo" { ...a11yProps(0) } />                                                        

                                <Tab label="Promocion" { ...a11yProps(1) } sx={{ display: ( tabValidation.idPromocion !== 0 ? 'flex' : 'none') }} />                                
                                
                                <Tab label="Amparo" { ...a11yProps(2) } sx={{ display: ( tabValidation.idAmparo !== 0 ? 'flex' : 'none') }} />                                
                                
                                <Tab label="Exhorto recibido" { ...a11yProps(3) } sx={{ display: ( tabValidation.idExhortoRecibido !== 0 ? 'flex' : 'none') }} /> 
                                <Tab label="Exhorto Librado" { ...a11yProps(4) } sx={{ display: ( tabValidation.idExhortoLibrado !== 0 ? 'flex' : 'none') }} /> 

                                <Tab label={ irregular?.idTipoIrregular === 1 ? "Convenio fuera de juicio" : "Irregular" } { ...a11yProps(5) } sx={{ display: ( tabValidation.idIrregular !== 0 ? 'flex' : 'none') }} />                                
                                <Tab label={ tabValidation.idDemandaInicial !== 0 ? 'Demanda' : 'Expediente' } { ...a11yProps(6) } sx={{ display: ( (tabValidation.idDemandaInicial !== 0 || tabValidation.idExpediente !== 0 ) ? 'flex' : 'none') }} />                                
                            </Tabs>

                            <TabPanel value={ valueTab } index={0}>

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />
                                
                                <Grid container spacing={3} >  

                                    {
                                        expedientilloSuspension?.success
                                        &&
                                        <>                                            
                                            <Grid item md={6} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Número de expedientillo de suspensión:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={6} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { expedientilloSuspension?.numeroExpedientillo }
                                                </Typography>
                                            </Grid>       

                                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >
                                                <Button 
                                                    variant="contained" 
                                                    startIcon={ <PrintIcon /> } 
                                                    onClick={ () => { setOpenCaratula( true ); } } 
                                                >
                                                    Visualizar carátula de expedientillo de suspensión
                                                </Button>   
                                            </Grid>              

                                        </>
                                    }                         
                                    
                                    <Grid item lg={12} md={12} xs={12} sx={{ mt: 1 }}>

                                        {
                                            documentoFirmado
                                            ?
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '65vh' }} 
                                                    src={ documentoFirmado }  
                                                />
                                            :                                        
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '65vh' }} 
                                                    src={ "data:application/pdf;base64," + documento }  
                                                />
                                        }

                                    </Grid>                                   

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                        <Button 
                                            variant="contained" 
                                            onClick={ () => { setOpenConfirmacion( true ); } } 
                                            endIcon={ <CheckCircleIcon /> }
                                        > 
                                            Finalizar 
                                        </Button>
                                    </Grid>    

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={1}>    

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetallePromocionScreen />

                            </TabPanel>       

                            <TabPanel value={ valueTab } index={2}>  

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleAmparoScreen edit={ false } />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={3}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleExhortoRecibidoScreen edit={ false } />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={4}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleExhortoLibradoScreen />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={5}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleIrregularScreen edit={ false } />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={6}>  

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Visualizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleDemandaScreen edit={ false } />

                            </TabPanel>    

                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la visualización del acuerdo?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

            <Dialog open={ openCaratula } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               expedientilloSuspension.caratula
                               ?
                               <iframe title='caratula' src={ expedientilloSuspension.caratula } height="500px" width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró la carátula
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenCaratula( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  
         
        </>
    )
}
