import React, { useState } from 'react';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputBase, List, LinearProgress, ListItem, Paper, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { LoadingButton } from '@mui/lab';

import { BuscarExpedienteEspecial } from '../../actions/recepcion/expedienteActions';
import { GenerarConvocatoria } from '../../actions/tribunal/convocatoriaActions.js';

export const ModalNuevaConvocatoria = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const [loading, setLoading] = useState( false );

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [errors, setErrors] = useState({ });

    const [expediente, setExpediente] = useState( {} );

    const [numeroExpediente, setNumeroExpediente] = useState('');

    const handlerGenerarConvocatoria = async () => {
        
        const params = {
            idExpediente : expediente.idExpediente,
        };

        setLoadingGuardar(true);
        
        await GenerarConvocatoria( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                       
                        const { idConvocatoria } = response.data;

                        setTimeout(() => {

                            setLoadingGuardar(false);
                            history.push("/Tribunal/Convocatoria/ElaborarConvocatoria/" + idConvocatoria);  

                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {                       
                        
                        const { message } = response.data;
                        
                        setLoadingGuardar(false);

                        let errores = {};  
                            errores.error =  message;
                            errores.type = 'error';

                        setErrors( errores );

                    }, 1200);  
                }
            }
        });
    }
   
    const cerrarModal = () => {
        
        handleCloseModal();
        
        setErrors( { } );
        setNumeroExpediente('');
        setExpediente( {} );
        
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }

        await BuscarExpedienteEspecial( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setExpediente(response.data);
                            }else{
                                errorExpediente();
                            }
                        }
                    }, 1500);  
                    setNumeroExpediente('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroExpediente('');
                }
            }
        });
       
    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === 0 || numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }
    
    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Nueva convocatoria</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                        <Grid item md={ 12 } xs={12} > 
                       
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"
                                    value={ numeroExpediente } 
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                    autoComplete="off"
                                /> 

                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    loading={ loading }
                                    onClick={ handleClicBuscarExpediente }
                                    size='small'
                                >
                                    <SearchIcon />
                                </LoadingButton>   
                            </Paper>
                            {   
                                errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                            }
                        </Grid>

                        {
                            (expediente.success) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Expediente:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.numeroExpediente }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        {
                                            expediente.ponencia
                                            &&                                
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        {
                                            expediente.ponencia
                                            &&  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.ponencia }
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                           
                                        {
                                            expediente.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                 
                                        </List>      
                                    </Grid>

                                    {
                                        expediente?.demandado?.length !== 0
                                        &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                        {
                                                            expediente.demandado.map((elem, i)=>(
                                                                <ListItem key={ i } disablePadding> 
                                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                        { elem }
                                                                    </Typography>
                                                                </ListItem>                    
                                                            ))
                                                        }                                                                                                
                                                </List>
                                            </Grid>   
                                        </>
                                    }
                                    
                                    {
                                        expediente?.extintos?.length !== 0
                                        &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Extintos(s):
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                        {
                                                            expediente.extintos?.map((elem, i)=>(
                                                                <ListItem key={ i } disablePadding> 
                                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                        { elem }
                                                                    </Typography>
                                                                </ListItem>                    
                                                            ))
                                                        }                                                                                                
                                                </List>
                                            </Grid>   
                                        </>
                                    }

                                    {   
                                        expediente?.centrosTrabajo?.length !== 0
                                        &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Centro de Trabajo:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                        {
                                                            expediente.centrosTrabajo?.map((elem, i)=>(
                                                                <ListItem key={ i } disablePadding> 
                                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                        { elem }
                                                                    </Typography>
                                                                </ListItem>                    
                                                            ))
                                                        }                                                                                                
                                                </List>
                                            </Grid>   
                                        </>
                                    }
                                   
                                </>
                            )
                        }
                    </Grid>

                    {   
                        errors.error && ( 
                            <Grid item md={12} xs={12} >
                                
                                <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '3em' }} > { errors.error  } </Alert> 
                                
                            </Grid>
                        )
                    }                    

                </DialogContent>

                <DialogActions>
                    <Button variant="text" color="primary" onClick={ cerrarModal }>Cancelar</Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ handlerGenerarConvocatoria } 
                        disabled={ !expediente.success || loadingGuardar } 
                    >
                        Guardar
                    </Button>
                </DialogActions>
                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>
            </Dialog>

        </>
    )
}
