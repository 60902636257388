import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, LinearProgress, MenuItem, RadioGroup, Select, TextField, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import moment from 'moment';
import 'moment/locale/es-mx';

import { generar } from 'curp';

import { ObtenerEstados } from '../../actions/admin/catalogs/estadoActions';

import { ObtenerClaveEntidad } from '../../helpers/ObtenerClaveEntidad';

import { AgregarPersona } from '../../actions/comun/personaActions';

export const ModalNuevaPersona = ( { openModal, handleCloseModal, seleccionarPersona } ) => {

    const {juzgado, tribunal} = useSelector( state => state.auth );     
    const [nombres, setNombres] = useState( '' );
    const [apellido1, setApellido1] = useState( '' );
    const [apellido2, setApellido2] = useState( '' );
    const [fechaNacimiento, setFechaNacimiento] = useState( '' );
    const [curp, setCurp] = useState( '' );
    const [rfc, setRfc] = useState( '' );
    const [sexo, setSexo] = useState( 0 );
    const [tipoPersona, setTipoPersona] = useState( 1 );
    const [razonSocial, setRazonSocial] = useState( '' );

    const [entidadNacimiento, setEntidadNacimiento] = useState(0);
    const [entidadNacimientoArray, setEntidadNacimientoArray] = useState( [] );

    const [entidadNacimientoDescripcion, setEntidadNacimientoDescripcion] = useState( '' );

    const [errors, setErrors] = useState({ });

    const [isLoading, setIsLoading] = useState( false );

    const handleClose = () => {

        handleCloseModal();

        setNombres( '' );
        setApellido1( '' );
        setApellido2( '' );
        setFechaNacimiento( '' );
        setSexo( 0 );
        setEntidadNacimiento( 0 );
        setCurp( '' );
        setRfc( '' );
        setRazonSocial( '' );
        setTipoPersona(1);
        setEntidadNacimientoArray( [ ] );
        setErrors( { } );
    }

    const handleChangeEntidadNacimiento = ( e, { props } ) => {
        setEntidadNacimiento( e.target.value );
        setEntidadNacimientoDescripcion( props.children);       
    }
    
    const handleChangeTipoPersona = (valor) => {
        
        setTipoPersona(valor);
        setNombres( '' );   
        setApellido1( '' );
        setApellido2( '' );
        setFechaNacimiento( '' );
        setSexo( 0 );
        setEntidadNacimiento( 0 );
        setCurp( '' );
        setRfc( '' );
        setRazonSocial( '' );

    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};
               
        if( nombres === '' && tipoPersona === 1 ){
            valid = false;
            errores.nombres = 'Debes escribir el nombre de la persona';
        }

        if( apellido1 === '' && tipoPersona === 1 ){
            valid = false;
            errores.apellido1 = 'Debes escribir el apellido de la persona';
        }
        
        if( sexo === 0  && tipoPersona === 1){
            valid = false;
            errores.sexo = 'Selecciona el sexo';
        }  
        
        if( razonSocial === ''  && tipoPersona === 2){
            valid = false;
            errores.razonSocial = 'Debes escribir la razon social';
        } 
        if( rfc === ''  && tipoPersona === 2){
            valid = false;
            errores.rfc = 'Debes escribir el RFC';
        } 
        if( curp !== ''  && curp.length < 18){
            valid = false;
            errores.curp = 'El CURP debe contener 18 caracteres';
        }
        if( rfc !== ''  && rfc.length < 12){
            valid = false;
            errores.rfc = 'El RFC debe contener 12 caracteres';
        }
       
       
        setErrors( errores );
        return valid;
    } 

    const Agregar = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setIsLoading( true );

        const params = {
            nombres: nombres === '' ? null : nombres,
            apellido1: apellido1 === '' ? null : apellido1,
            apellido2: apellido2 === '' ? null : apellido2,
            fechaNacimiento: fechaNacimiento === '' ? null : fechaNacimiento,
            idSexo:  sexo === 0 ? null : sexo,
            idEntidadNacimiento: entidadNacimiento === 0 ? null : entidadNacimiento,
            curp: curp === '' ? null : curp,
            rfc: rfc === '' ? null : rfc,
            idTipoPersona: tribunal ? 1 : tipoPersona,  
            razonSocial: razonSocial === '' ? null : razonSocial,
            
        };

        await AgregarPersona( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout(() => {
                        
                        setIsLoading( false );

                        seleccionarPersona( response.data );

                        handleClose();

                    }, 1200);

                }
                else{                    

                    setTimeout(() => {

                        setErrors( { } );

                        let errores = {};
                    
                        errores.error = response.data.message;

                        setErrors( errores );
                        
                        setIsLoading( false );    

                    }, 1200); 
                    
                }

            }

        });

    }

    useEffect(() => {
      
        if( nombres && apellido1 && apellido2 && fechaNacimiento && sexo !== 0 && entidadNacimiento !== 0 ){   

            const clave_estado = ObtenerClaveEntidad( entidadNacimientoDescripcion );

            if( clave_estado ){
                const persona = {
                    nombre: nombres,
                    apellidoPaterno: apellido1,
                    apellidoMaterno: apellido2,
                    genero: sexo === 1 ? 'H' : 'M',
                    fechaNacimiento: moment(fechaNacimiento).format('DD-MM-YYYY'),
                    estado: clave_estado.clave                   
                }
                
                setCurp( generar(persona) );
            }
            else{
                setCurp( '' );
            }
            
        }
        else{
            setCurp( '' );
        }

    }, [ nombres, apellido1, apellido2, fechaNacimiento, sexo, entidadNacimiento, entidadNacimientoDescripcion ]);

    useEffect(() => {
      
        async function Obtener(){

            if( openModal ){
                await ObtenerEstados().then( response => {

                    if( response ){

                        if( response.status === 200){

                            setEntidadNacimientoArray( response.data );
                        }

                    }

                });
            }

        }

        Obtener();

    }, [ openModal ]);    
    
    return (
        <>
            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>
                <DialogTitle>Agregar Persona</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}> 
                        {
                            juzgado && (
                                <>
                                    <Grid item md={4} xs={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                        <FormLabel sx={{ color: 'black' }} >Tipo de persona: </FormLabel>
                                    </Grid>
                                    
                                    <Grid item md={8} xs={10} >
                                        <RadioGroup row sx={{ textAlign: 'center' }} >
                                            <Grid container >
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Física" control={ <Checkbox checked={ tipoPersona === 1 } onChange={ () => {  handleChangeTipoPersona(1); } } /> } label="Física" />
                                                </Grid>
                                                <Grid item md={6} xs={6} >
                                                    <FormControlLabel value="Moral" control={ <Checkbox checked={ tipoPersona === 2 } onChange={ () => { handleChangeTipoPersona(2) } } /> } label="Moral" />     
                                                </Grid>                                                            
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </>
                            )
                        }    

                        {
                            (
                                ( juzgado && tipoPersona === 1 ) ||
                                tribunal
                            )                     
                            && 
                            (
                                <>

                                    <Grid item md={12} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Nombre(s)"
                                            name="nombres"
                                            variant="outlined"        
                                            required     
                                            value={ nombres }   
                                            autoComplete="off"                
                                            onChange={ ( e ) => { setNombres( e.target.value.toUpperCase() ); } }
                                        />
                                        {   
                                            errors.nombres && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.nombres } </Alert> )
                                        } 
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Apellido 1"
                                            name="apellido1"
                                            variant="outlined"        
                                            required       
                                            value={ apellido1 }     
                                            autoComplete="off"                              
                                            onChange={ ( e ) => { setApellido1( e.target.value.toUpperCase() ); } }                      
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Apellido 2"
                                            name="apellido2"
                                            variant="outlined"           
                                            value={ apellido2 }  
                                            autoComplete="off"                                 
                                            onChange={ ( e ) => { setApellido2( e.target.value.toUpperCase() ); } }                            
                                        />
                                    </Grid>

                                    {   
                                        errors.apellido1 && ( 
                                            <Grid item md={12} xs={12} >
                                                <Alert color='warning' severity="warning" variant="filled" > { errors.apellido1 } </Alert> 
                                            </Grid>
                                        )
                                    }   

                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            label="Fecha nacimiento"
                                            type="date"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={ fechaNacimiento }                   
                                            onChange={ ( e ) => { setFechaNacimiento( e.target.value ); } }     
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-entidadNacimiento-label">Entidad de nacimiento</InputLabel>
                                        
                                            <Select
                                                labelId="select-entidadNacimiento-label"
                                                id="select-entidadNacimiento"                                            
                                                label="Entidad de nacimiento"
                                                name="entidadNacimiento"
                                                value={ entidadNacimiento}
                                                onChange={ handleChangeEntidadNacimiento }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    entidadNacimientoArray.map( element => (
                                                        <MenuItem key={ element.idEstado } value={element.idEstado}>{ element.descripcion }</MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>
                                    </Grid>   

                                    <Grid item md={12} xs={12} >
                                        <FormControl fullWidth>
                                            
                                            <Grid container spacing={3}>
                                                
                                                <Grid item md={2} xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                    <FormLabel sx={{ color: 'black' }} >Sexo: </FormLabel>
                                                </Grid>
                                                
                                                <Grid item md={10} xs={10} >
                                                    <RadioGroup row sx={{ textAlign: 'center' }} >
                                                        <Grid container >
                                                            <Grid item md={6} xs={6} >
                                                                <FormControlLabel value="Hombre" control={ <Checkbox checked={ sexo === 1 } onChange={ () => { setSexo(1) } } /> } label="Hombre" />
                                                            </Grid>
                                                            <Grid item md={6} xs={6} >
                                                                <FormControlLabel value="Mujer" control={ <Checkbox checked={ sexo === 2 } onChange={ () => { setSexo(2) } } /> } label="Mujer" />     
                                                            </Grid>                                                            
                                                        </Grid>
                                                    </RadioGroup>
                                                </Grid>

                                            </Grid>

                                        </FormControl>
                                        {   
                                            errors.sexo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.sexo } </Alert> )
                                        } 
                                    </Grid>                           

                                    <Grid item md={6} xs={6} >
                                        <TextField
                                            fullWidth
                                            label="CURP"
                                            name="curp"
                                            variant="outlined"
                                            required
                                            value={ curp }                   
                                            onChange={ ( e ) => { setCurp( e.target.value.toUpperCase() ); } }  
                                            inputProps={{ maxLength: 18 }}
                                            autoComplete="off"                
                                        />
                                        {   
                                            errors.curp && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.curp } </Alert> )
                                        } 
                                    </Grid>
                                    
                                </>
                            )
                        }

                        {
                            juzgado && tipoPersona === 2                    
                            && (
                                <>

                                    <Grid item md={12} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Razon social"
                                            name="razonSocial"
                                            variant="outlined"    
                                            required 
                                            value={ razonSocial }      
                                            autoComplete="off"                             
                                            onChange={ ( e ) => { setRazonSocial( e.target.value.toUpperCase() ); } }                       
                                        />
                                        {   
                                            errors.razonSocial && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.razonSocial } </Alert> )
                                        } 
                                    </Grid>

                                    <Grid item md={6} xs={6} >
                                        <TextField
                                            fullWidth
                                            label="RFC"
                                            name="rfc"
                                            variant="outlined"     
                                            value={ rfc }   
                                            required                
                                            onChange={ ( e ) => { setRfc( e.target.value.toUpperCase() ); } }        
                                            inputProps={{ maxLength: 12 }}                     
                                            autoComplete="off"                
                                        />
                                        {   
                                            errors.rfc && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.rfc } </Alert> )
                                        }
                                    </Grid>
                                
                                </>
                            )
                        }   

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >
                                    <Alert color='error' severity="error" variant="filled" > { errors.error } </Alert> 
                                </Grid>
                            )
                        }    

                        <Grid item md={12} xs={12} >
                            <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>* Campos obligatorios</Typography>
                        </Grid>
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cancelar</Button>
                    <Button 
                        onClick={ Agregar } 
                        variant='contained' 
                        startIcon={ <AddIcon /> }
                    >
                        Agregar
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: isLoading ? 'block' : 'none' }}></LinearProgress>

            </Dialog>
        </>
    )
}
