import React, { useState , useEffect  } from 'react';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Paper, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../../ui/AppBarMenu';

import { types } from '../../../types/types';

import { ProyectoSentenciaDetalle , ActualizarEstatusProyectoSentencia } from '../../../actions/sentencias/proyectoSentenciaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

export const VisualizarProyectoSentenciaScreen = ( { match } ) => {

    const { tribunal } = useSelector( state => state.auth );

    const idProyectoSentencia = match?.params?.Id;

    const dispatch = useDispatch();

    const [sendAjax, setSendAjax] = useState(false);

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [loadingFinalizar, setLoadingFinalizar] = useState( false ); 

    const [proyectoSentencia, setProyectoSentencia] = useState( { } ); 

    const [alert, setAlert] = useState( {  } );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const Finalizar = async () => {           

        setLoadingFinalizar(true);

        const params = {
            'idProyectoSentencia' : idProyectoSentencia,
            'idEstatus': 47
        };

        await ActualizarEstatusProyectoSentencia ( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoadingFinalizar(false);

                        setOpenConfirmacion(false);
                       
                        setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                        setSendAjax( true );
            
                    }, 1000);

                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar(false);

                    }, 1000);  
                }
            }
        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idProyectoSentencia': idProyectoSentencia,               
            };

            dispatch({ type: types.openLoading });

            await ProyectoSentenciaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                         setTimeout(() => {

                            setProyectoSentencia(response.data);                                                  

                            dispatch({ type: types.closeLoading });

                            setAjaxLoading( true );

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 26 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }    
                            
                            if( response.data.idEstatus !== 26){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setSendAjax( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ idProyectoSentencia, dispatch ])

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>           
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>

                            <InformacionSeguimiento 
                                noSeguimiento={ 'GPS-' + proyectoSentencia?.id }
                                actividad={ 'Visualizar proyecto de sentencia' }
                                tipo={ 'Número de expediente' }
                                referencia={ proyectoSentencia?.numeroExpediente }
                            />
                            
                            <Grid container spacing={3} mt={1}> 

                                {
                                    tribunal &&
                                        <Grid item md={2} xs={6}>                               
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Ponencia:
                                            </Typography>  
                                        </Grid>
                                }

                                {   
                                    tribunal &&
                                        <Grid item md={10} xs={6} >                                    
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { proyectoSentencia?.ponencia }
                                            </Typography>   
                                        </Grid>  
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    
                                    <List disablePadding> 
                                    {
                                        proyectoSentencia?.actor?.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding>  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                { elem }
                                            </Typography>      
                                        </ListItem>
                                        ))
                                    }                   
                                    </List>

                                </Grid>    
                            
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >

                                    <List disablePadding>
                                    {
                                        proyectoSentencia?.demandado?.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }

                                    </List>
                                </Grid>    

                                <Grid item lg={12} md={12} xs={12}>

                                    {
                                        proyectoSentencia?.documento &&
                                        (
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ proyectoSentencia.documento }  
                                            />
                                        )
                                    }
                                    
                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 

                                    <Button 
                                        variant="contained" 
                                        onClick={ handleOpenConfirmacion } 
                                        endIcon={ <CheckCircleIcon /> }
                                    
                                    > 
                                        Finalizar 
                                    </Button>

                                </Grid>    

                            </Grid>

                        </Container>

                    )
                }

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                                
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                        
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar la visualización del proyecto de sentencia?
                        </DialogContentText>
                    
                    </DialogContent>
                
                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
    
                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained'                                                      
                            loading={ loadingFinalizar }
                        > 
                            Aceptar 
                        </LoadingButton>
    
                    </DialogActions>
                
                </Dialog>
                
            </Box>
                
    </>
    )
}
                
