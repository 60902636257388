import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/lab';

import DateAdapter from '@mui/lab/AdapterMoment';

import moment from 'moment';

import 'moment/locale/es-mx';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { Consecutivo, Actualizar, Agregar } from '../../../actions/admin/consecutivo/consecutivoActions';
import { ObtenerAutoridades } from '../../../actions/admin/catalogs/autoridadActions';
import { ObtenerTiposConsecutivos } from '../../../actions/admin/catalogs/tipoConsecutivoActions';
    
const anioActual = moment( new Date() ).format('YYYY');

export const ConsecutivosScreen = ( { history }) => {

    const { location } = history;
    const { pathname } = location;

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [numeroConsecutivo, setNumeroConsecutivo] = useState( 1 );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [ ] );

    const [tipoConsecutivo, setTipoConsecutivo] = useState( 0 );
    const [tipoConsecutivoArray, setTipoConsecutivoArray] = useState( [ ] );

    const [anio, setAnio] = useState( anioActual ); 

    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
   
    const [loading, setLoading] = useState(false);
    
    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const AgregarRegistro = () => {

        setAutoridad( 0 );
        setTipoConsecutivo( 0 );
        setAnio( anioActual );
        setNumeroConsecutivo( 1 );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );
        handleOpenModal();
    }

    const EditarRegistro = ( id, numeroConsecutivo, idAutoridad, idTipoConsecutivo, anio ) => {
        
        setNumeroConsecutivo( numeroConsecutivo );
        setAutoridad( idAutoridad);
        setTipoConsecutivo( idTipoConsecutivo );
        setAnio( moment( new Date( anio + '/01/01' ) ).format('YYYY') );       

        setOpcion( 'editar' );
        setIdReferencia( id );
        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoading( true );

        if( opcion === 'agregar' ){
            const params = {
                idConsecutivo: 0,
                numeroConsecutivo: parseInt( numeroConsecutivo ), 
                anio: parseInt( anio ), 
                idTipoConsecutivo: tipoConsecutivo, 
                idAutoridad: autoridad,        
            };

            await Agregar( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){

                            setTimeout(() => {
                                
                                setRows([ 
                                    ...rows,  
                                    {
                                        idConsecutivo: response.data.idConsecutivo,
                                        numeroConsecutivo: parseInt( numeroConsecutivo ),
                                        anio: parseInt( anio ),
                                        idTipoConsecutivo: tipoConsecutivo,                                    
                                        tipoConsecutivo: response.data.tipoConsecutivo, 
                                        idAutoridad: autoridad,
                                        autoridad: response.data.autoridad            
                                    }
                                ]);  

                                handleCloseModal();

                                setLoading( false );

                            }, 1200);
                        }                         
                    }
                    else
                    {
                        setTimeout(() => {
                            
                            setLoading( false );
                            setErrors( { } );
                            let errores = {};
                            errores.error = 'Ya se encuentra registrado un consecutivo para esa autoridad';                         
                            setErrors( errores );  

                        }, 1200);
                        
                    }
                }
            });
        }

        if( opcion === 'editar' ){            

            const params = {
                idConsecutivo: idReferencia,
                numeroConsecutivo: parseInt( numeroConsecutivo ), 
                anio: parseInt( anio ), 
                idTipoConsecutivo: tipoConsecutivo, 
                idAutoridad: autoridad   
                
            };

            await Actualizar( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        setTimeout(() => {                                
                            
                            const newRows = rows.map( (row) => { 
                                if(row.idConsecutivo === idReferencia){ 
                                    row.numeroConsecutivo = parseInt( numeroConsecutivo ); 
                                    row.anio = parseInt( anio );
                                    row.idTipoConsecutivo = tipoConsecutivo;                                    
                                    row.tipoConsecutivo = response.data.tipoConsecutivo; 
                                    row.idAutoridad = autoridad;
                                    row.autoridad = response.data.autoridad;   
                                    return row; 
                                } 
                                else{ 
                                    return row; 
                                } 
                            });

                            setRows( newRows );                         
                            handleCloseModal();

                            setLoading( false );

                        }, 1200);
                        
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( autoridad === 0 ){
            valid = false;
            errores.autoridad = 'Seleccione la autoridad';
        }

        if( tipoConsecutivo === 0 ){
            valid = false;
            errores.tipoConsecutivo = 'Seleccione el tipo de consecutivo';
        }
        
        if( numeroConsecutivo === "0" || numeroConsecutivo === '' ){
            valid = false;
            errores.numeroConsecutivo = 'Escribe el número consecutivo';
        }

        if( anio === 'Fecha inválida' || anio ==='' ){
            valid = false;
            errores.anio = 'Seleccione el año';
        }
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await Consecutivo().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);  

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);


    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposConsecutivos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoConsecutivoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <AppBarMenu />            

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg">   

                    <Grid container spacing={3} >  

                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                           
                        </Grid>
                       
                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>
                           
                        <Grid item xs={12} md={12}> 

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                        {
                                            desc.length !== 0 ? desc[0].numeroconsecutivo : ''
                                        }
                                    </Typography>
                                </Toolbar>

                                <TableContainer>
                                    <Table sx={{ minWidth: 500 }} >
                                        <TableHead>
                                            <TableRow>        
                                                <TableCell style={{ width:'5%' }}> ID </TableCell>
                                                <TableCell style={{ width:'15%' }}> CONSECUTIVO </TableCell>
                                                <TableCell style={{ width:'10%' }}> AÑO </TableCell>
                                                <TableCell style={{ width:'25%' }}> TIPO DE CONSECUTIVO </TableCell>
                                                <TableCell style={{ width:'25%' }}> AUTORIDAD </TableCell>
                                                <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                        {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <TableRow key={ row.idConsecutivo} >
                                            
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    { row.idConsecutivo }
                                                </TableCell>

                                                <TableCell>
                                                    { row.numeroConsecutivo }
                                                </TableCell>
                                                
                                                <TableCell>
                                                    { row.anio }                                  
                                                </TableCell>

                                                <TableCell>
                                                    { row.tipoConsecutivo.descripcion }                                  
                                                </TableCell>

                                                <TableCell>
                                                    { row.autoridad.descripcion }                                  
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton 
                                                            color="primary" 
                                                            aria-label="edit" 
                                                            component="span" 
                                                            onClick={ () => EditarRegistro( row.idConsecutivo, row.numeroConsecutivo, row.idAutoridad, row.idTipoConsecutivo, row.anio ) }
                                                        >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        { rows.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={6} align="center"> 
                                                No se encontraron resultados
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[ ]}
                                                    colSpan={6}
                                                    count={rows.length}                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            
                            </Paper>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
            
                <DialogContent>

                    <Grid container spacing={3} sx={{ paddingTop: 2 }}>  

                        <Grid item md={12} xs={12}> 

                            <FormControl fullWidth >
                                <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                            
                                <Select
                                    labelId="select-autoridad-label"
                                    id="select-autoridad"                                            
                                    label="Autoridad"
                                    name="autoridad"
                                    value={ autoridad }                                
                                    onChange={ ( e ) => { setAutoridad( e.target.value ); } }
                                    disabled={ opcion==='editar' }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>   
                                    {
                                        autoridadArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>
                                        ))
                                    }                       
                                </Select>

                            </FormControl>

                            {   
                                errors.autoridad && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridad } </Alert> )
                            }  
                        
                        </Grid>

                        <Grid item md={12} xs={12}> 

                            <FormControl fullWidth >
                                <InputLabel id="select-tipoConsecutivo-label">Tipo de consecutivo</InputLabel>
                            
                                <Select
                                    labelId="select-tipoConsecutivo-label"
                                    id="select-tipoConsecutivo"                                            
                                    label="Tipo de consecutivo"
                                    name="tipoConsecutivo"
                                    value={ tipoConsecutivo }                               
                                    onChange={ ( e ) => { setTipoConsecutivo( e.target.value ); } }
                                    disabled={ opcion==='editar' }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem> 
                                    {
                                        tipoConsecutivoArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idTipoConsecutivo } value={ elem.idTipoConsecutivo }> { elem.descripcion } </MenuItem>
                                        ))
                                    }                       
                                </Select>

                            </FormControl>

                            {   
                                errors.tipoConsecutivo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoConsecutivo } </Alert> )
                            }
                   
                        </Grid>
                      
                    
                        <Grid item md={6} xs={6}>  

                            <LocalizationProvider dateAdapter={DateAdapter}>
                            
                                <DatePicker
                                    label="Año"
                                    views={ ['year']}                             
                                    name="valueDate"                                                                                
                                    renderInput={(params) => <TextField {...params} helperText={null} sx={{width: '100%'}} />}
                                    value={ anio }
                                    onChange={ ( newValue ) => { setAnio( moment( newValue ).format('YYYY') ); } }
                                
                                />                             

                            </LocalizationProvider>   

                            {   
                                errors.anio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.anio } </Alert> )
                            }

                        </Grid>
                    
                        <Grid item md={6} xs={6}> 

                            <TextField
                                label={ 'Consecutivo' }
                                variant="outlined"      
                                autoFocus                          
                                type="number"                                     
                                fullWidth
                                value={ numeroConsecutivo }
                                onChange={ ( e ) => { setNumeroConsecutivo( e.target.value ) } }
                            />

                            {   
                                errors.numeroConsecutivo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.numeroConsecutivo } </Alert> )
                            }

                        </Grid>

                        <Grid item md={12} xs={12}>
                            {   
                                errors.error && ( <Alert color='error' severity="error" variant="filled" > { errors.error } </Alert> )
                            } 
                        </Grid>
                    
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }> Cancelar </Button>
                    <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loading ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

        </>
    )
}

