export const ObtenerClaveEntidad = ( Descripcion ) => {     
    
    const entidades_federativas = [
        { estado: 'Aguascalientes', clave: 'AS' },
        { estado: 'Baja California', clave:  'BC' },
        { estado: 'Baja California Sur', clave:  'BS' },
        { estado: 'Campeche', clave:  'CC' },
        { estado: 'Coahuila de Zaragoza', clave:  'CL' },
        { estado: 'Colima', clave:  'CM' },
        { estado: 'Chiapas', clave:  'CS' }, 
        { estado: 'Chihuahua', clave:  'CH' },
        { estado: 'Ciudad de México', clave:  'DF' },
        { estado: 'Durango', clave:  'DG' },
        { estado: 'Guanajuato', clave:  'GT' }, 
        { estado: 'Guerrero', clave:  'GR' },
        { estado: 'Hidalgo', clave:  'HG' },
        { estado: 'Jalisco', clave:  'JC' },
        { estado: 'México', clave:  'MC' }, 
        { estado: 'Michoacán de Ocampo', clave:  'MN' },
        { estado: 'Morelos', clave:  'MS' }, 
        { estado: 'Nayarit', clave:  'NT' },
        { estado: 'Nuevo León', clave:  'NL' }, 
        { estado: 'Oaxaca', clave:  'OC' },
        { estado: 'Puebla', clave:  'PL' },
        { estado: 'Querétaro', clave:  'QT' },
        { estado: 'Quintana Roo', clave:  'QR' }, 
        { estado: 'San Luis Potosí', clave:  'SP' },
        { estado: 'Sinaloa', clave:  'SL' }, 
        { estado: 'Sonora', clave:  'SR' },
        { estado: 'Tabasco', clave:  'TC' }, 
        { estado: 'Tamaulipas', clave:  'TS' },
        { estado: 'Tlaxcala', clave:  'TL' }, 
        { estado: 'Veracruz de Ignacio de la Llave', clave:  'VZ' },
        { estado: 'Yucatán', clave:  'YN' }, 
        { estado: 'Zacatecas', clave:  'ZS' }
    ];
    
    const entidad_federativa = entidades_federativas.find( element => { return element.estado === Descripcion });

    return entidad_federativa;
}

export const ObtenerDescripcionEntidad = ( clave ) => {     
    
    const entidades_federativas = [
        { estado: 'Aguascalientes', clave: 'AS' },
        { estado: 'Baja California', clave:  'BC' },
        { estado: 'Baja California Sur', clave:  'BS' },
        { estado: 'Campeche', clave:  'CC' },
        { estado: 'Coahuila de Zaragoza', clave:  'CL' },
        { estado: 'Colima', clave:  'CM' },
        { estado: 'Chiapas', clave:  'CS' }, 
        { estado: 'Chihuahua', clave:  'CH' },
        { estado: 'Ciudad de México', clave:  'DF' },
        { estado: 'Durango', clave:  'DG' },
        { estado: 'Guanajuato', clave:  'GT' }, 
        { estado: 'Guerrero', clave:  'GR' },
        { estado: 'Hidalgo', clave:  'HG' },
        { estado: 'Jalisco', clave:  'JC' },
        { estado: 'México', clave:  'MC' }, 
        { estado: 'Michoacán de Ocampo', clave:  'MN' },
        { estado: 'Morelos', clave:  'MS' }, 
        { estado: 'Nayarit', clave:  'NT' },
        { estado: 'Nuevo León', clave:  'NL' }, 
        { estado: 'Oaxaca', clave:  'OC' },
        { estado: 'Puebla', clave:  'PL' },
        { estado: 'Querétaro', clave:  'QT' },
        { estado: 'Quintana Roo', clave:  'QR' }, 
        { estado: 'San Luis Potosí', clave:  'SP' },
        { estado: 'Sinaloa', clave:  'SL' }, 
        { estado: 'Sonora', clave:  'SR' },
        { estado: 'Tabasco', clave:  'TC' }, 
        { estado: 'Tamaulipas', clave:  'TS' },
        { estado: 'Tlaxcala', clave:  'TL' }, 
        { estado: 'Veracruz de Ignacio de la Llave', clave:  'VZ' },
        { estado: 'Yucatán', clave:  'YN' }, 
        { estado: 'Zacatecas', clave:  'ZS' }
    ];
    
    const entidad_federativa = entidades_federativas.find( element => { return element.clave === clave });

    return entidad_federativa;
}