import HttpClienteToken from '../../../services/HttpClienteToken';

export const ObtenerAutoridadesExhorto = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/AutoridadExhorto/ObtenerAutoridadesExhorto', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const AutoridadesExhorto = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/AutoridadExhorto/', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ActualizarAutoridadExhorto = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/AutoridadExhorto/Actualizar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const AgregarAutoridadExhorto = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/AutoridadExhorto/Agregar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}