import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';
import { usePages } from '../../../hooks/usePages';

import { ObtenerTipoParte } from '../../../actions/admin/catalogs/tipoparteActions';

import { types } from '../../../types/types';

import { ActualizarEstatus } from '../../../actions/recepcion/recepcionActions';

import { AgregarPartes, ObtenerPartesAmparo } from '../../../actions/recepcion/amparoActions';

export const RegistrarPartesScreen = ({ handleNext, handleBack, idRecepcion }) => {

    const dispatch = useDispatch();
    
    const { partes, amparo } = useSelector( state => state.recepcion );
    
    const [idReferencia, setReferencia] = useState( 0 );
    
    const [tipoParte, setTipoParte] = useState( 0 );
    const [tipoParteArray, setTipoParteArray] = useState( [ ] );
   
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [errors, setErrors] = useState({ });

    const [loadingBack, setLoadingBack] = useState( false );
    const [loadingNext, setLoadingNext] = useState( false );
   
    const [parte, setParte] = useState( 0 );
    const [arrayParte, setArrayParte] = useState( [] );
      
    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };
   
    const handleClicAgregarParte = () => {
      
        const idPartePersona = arrayParte.find( ( elem ) => elem.id === parte )?.idPersona ?? 0;
        const idParteDependencia = arrayParte.find( ( elem ) => elem.id === parte )?.idDependencia ?? 0;

        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();
       
        if( idPartePersona !== 0 ){

            const personaValidate =  rows.find( ( element ) => { return element.idPersona === idPartePersona });

            if( !personaValidate ){

                const partePersona = arrayParte.find( ( elem ) => elem.id === parte );

                if( parte === -1 ){                    

                    const data = {
                        id: parseInt( id ),
                        idPersona : partePersona.idPersona,
                        persona: partePersona.persona,
                        idDependencia: null,
                        dependencia: null,
                        tipoParte: partePersona.tipoParte,
                    };
            
                    setRows( [ ...rows, data ] );
                }
                else {                   
                    const tipoParteData = tipoParteArray.find( ( element ) => { return element.idTipoParte === tipoParte });
                    
                    const data = {
                        id: parseInt( id ),
                        idPersona : partePersona.idPersona,
                        persona: partePersona.persona,
                        idDependencia: null,
                        dependencia: null,
                        tipoParte: tipoParteData,
                    };
            
                    setRows( [ ...rows, data ] );
                }
            }
            else{
                let errores = {};

                errores.error = 'La persona ya se encuentra agregada';
            
                setErrors( errores );  
            }  
        }
        else if ( idParteDependencia !== 0 ){

            const dependenciaValidate =  rows.find( ( element ) => { return element.idDependencia === idParteDependencia });

            if( !dependenciaValidate ){

                const parteDependencia = arrayParte.find( ( elem ) => elem.id === parte );
                const tipoParteData = tipoParteArray.find( ( element ) => { return element.idTipoParte === tipoParte });

                const data = {
                    id: parseInt( id ),
                    idPersona: null,
                    persona: null,
                    idDependencia: parteDependencia.idDependencia,
                    dependencia: parteDependencia.dependencia,
                    tipoParte: tipoParteData,
                };       

                setRows( [ ...rows, data ] );
            }
            else{
                let errores = {};

                errores.error = 'La dependencia ya se encuentra agregada';
            
                setErrors( errores ); 
            }            
        }

        setParte( 0 );    
        setTipoParte( 0 );
    }

    const handleClicEliminarParte = ( Id ) => {

        handleOpenConfirmacion();

        setReferencia( Id );
        
    }

    const eliminarParte = () => {

        var newArray = rows.filter(function( element ){ 
            return element.id !== idReferencia;
        });

        setRows( newArray );

        dispatch({

            type: types.eliminarParte,
            payload: { id: idReferencia }

        });

        handleCloseConfirmacion();
    }

    const validarInformacion = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );
        let arrayPartes = [];

        rows.forEach(element => {
            
            const parte = {
                idTipoParte : element.tipoParte.idTipoParte,
                idPersona : element.persona ? element.persona.idPersona : null,
                idDependencia: element.dependencia ? element.dependencia.idDependencia : null

            };       
            
            arrayPartes.push( parte );
           
        });       

        const params = {

            idRecepcion: idRecepcion,
            partes : arrayPartes

        };

        await AgregarPartes( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    dispatch({

                        type: types.agregarPartes,
                        payload: rows
            
                    });
            
                    setTimeout(() => {
                                    
                        setLoadingNext( false );
            
                        handleNext();
            
                    }, 500);  

                }

            }

        });
        
    }

    const validateFiels = () => {
       
            let valid = true;
            const quejoso = rows.filter( ( element ) => { return element.tipoParte.idTipoParte === 3 }).length;
            const tercerInteresado = rows.filter( ( element ) => { return element.tipoParte.idTipoParte === 4 }).length;
            const autoridadResponsable = rows.filter( ( element ) => { return element.tipoParte.idTipoParte === 5 }).length;
            if (quejoso === 0 || tercerInteresado === 0 || autoridadResponsable === 0) 
            {    
                let errores = {};
                valid = false;
                errores.error = 'Debes agregar un quejoso, tercero interesado y autoridad responsable'; 
                setErrors( errores );  
            }
            
        return valid;
    } 

    const Anterior = async() => {

        const params = {
            idRecepcion: idRecepcion,
            idEstatus: 1
        }

        setLoadingBack( true );

        await ActualizarEstatus( params ).then( response => {

            if( response ){
                if( response.status === 200 ){                    
                        
                    setTimeout(() => {
                        
                        setLoadingBack( false );

                        handleBack();

                    }, 500);  
                    
                }
            }
        });

    }

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoParte().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoParteArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);

    useEffect(() => {
      
        if( partes.length !== 0 ){

            setRows( partes );

        }

    }, [ partes ])
    
    useEffect(() => {

        async function Obtener(){
            await ObtenerPartesAmparo( amparo?.expediente?.idExpediente ?? 0 ).then( resp => {
                if( resp ){
                    setArrayParte( resp.data ?? [] );
                }
            });
        }

        if( amparo.expediente.idExpediente !== 0 ){
            Obtener();
        }

    }, [ amparo ])  
    
    return (
        <>   
          
            <Grid item md={6} xs={12} > 

                <FormControl fullWidth>
                    <InputLabel id="select-parte-label">Seleccione una persona</InputLabel>

                    <Select
                        labelId="select-parte-label"
                        id="select-parte"                                            
                        label="Seleccione una persona"   
                        name="seleccioneParte"
                        value={ parte }
                        onChange={ (e) => { 
                            setParte(e.target.value);  
                            if( e.target.value === -1 || e.target.value === 0 ){ setTipoParte( 0 ); }
                        }}                                                                       
                    >
                        <MenuItem value={0}>Selecciona una opción</MenuItem>                                     
                        {                                       
                            arrayParte                                          
                            .map((elem)=>(
                                <MenuItem key={ elem.id } value={ elem.id }> { elem.nombre }&nbsp;<strong>({ elem?.tipoParte?.descripcion ?? '' })</strong> </MenuItem>
                            ))                                       
                        } 
                    </Select>

                </FormControl>

                {   
                    errors.parte && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.parte } </Alert> )
                }

            </Grid>     
    
            <Grid item md={6} xs={12} >                            
                <FormControl fullWidth>
                    <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>
                
                    <Select
                        labelId="select-tipoParte-label"
                        id="select-tipoParte"                                            
                        label="Tipo de parte"
                        name="tipoParte"
                        value={ tipoParte }
                        onChange={ ( e ) => { setTipoParte( e.target.value ); } }
                        disabled={ parte === -1 || parte === 0 }  
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                        {                            
                            tipoParteArray
                            .filter( (element ) => { return element.idTipoParte === 3 || element.idTipoParte === 4; } )
                            .map( ( element ) => (
                                <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                            ))
                        }
                    </Select>

                </FormControl>
            </Grid>     

            <Grid item md={6} xs={12} style={{ marginTop: '0.5em' }} >
                <Button 
                    variant="contained"                    
                    startIcon={ <AddIcon /> }
                    disabled={ parte !== -1 && tipoParte === 0 }  
                    onClick={ handleClicAgregarParte }
                >
                    Agregar
                </Button>
            </Grid>       

            <Grid item md={12} xs={12} >

                <TableContainer style={{ marginTop: '10px' }}>

                    <Table>

                        <TableHead>

                            <TableRow>
                                <TableCell style={{ width:'60%' }}>DESCRIPCIÓN</TableCell>
                                <TableCell style={{ width:'30%' }}>TIPO DE PARTE</TableCell>                                
                                <TableCell style={{ width:'10%' }}> </TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                        {
                            (rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map( (row) => (

                            <TableRow key={ row.id } >
                                <TableCell> { row.dependencia ? row.dependencia.descripcion : '' } { row.persona ? row.persona.nombreCompleto : '' } </TableCell>
                                <TableCell> { row.tipoParte.descripcion } </TableCell>
                                <TableCell> 
                                    <IconButton color="primary" onClick={ () => { handleClicEliminarParte( row.id ); } }>
                                        <DeleteIcon /> 
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                            ))
                        }

                        {
                            rows.length === 0 && 
                            (
                                <TableRow>
                                    <TableCell
                                        colSpan={6}
                                        align="center"
                                        style={{ fontWeight: 'bold', fontSize: 14 }}
                                    >
                                        {
                                            'No se han agregado parte(s)'
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        }

                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[ ]}
                                    colSpan={3}
                                    count={rows.length}                                        
                                    rowsPerPage={rowsPerPage}
                                    page={page}                                                                                
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                    ActionsComponent={ TablePaginationActions }
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                          return '' + from + '-' + to + ' de ' + count
                                        }
                                    }
                                />
                            </TableRow>
                        </TableFooter>

                    </Table>

                </TableContainer>

            </Grid> 

            {   
                errors.error && ( 
                    <Grid item md={12} xs={12} >

                        <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                    </Grid>
                )
            }

            <Grid item md={12} xs={12} sx={{ mt: 1 }}>  

                <Grid container spacing={3}>

                    <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  
                        <LoadingButton 
                            variant="contained" 
                            color="inherit"
                            onClick={ Anterior }
                            startIcon={ <ArrowBackIcon /> }
                            loading={ loadingBack }
                        > 
                            Regresar 
                        </LoadingButton>
                    </Grid>

                    <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  
                        <LoadingButton 
                            variant="contained" 
                            onClick={ validarInformacion }
                            endIcon={ <ArrowForwardIcon /> }
                            loading={ loadingNext }
                        > 
                            Siguiente 
                        </LoadingButton>
                    </Grid>                                        

                </Grid>

            </Grid>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <Button onClick={ eliminarParte } variant='contained' > Aceptar </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
