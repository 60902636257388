export const getCatalogFilter = ( catalogos, value ) => {

    const catalogosFilter = catalogos.filter(function ({ descripcion }) { 

        const descripcionFilter = descripcion.toLowerCase().indexOf(value.toLowerCase() );
        
        return descripcionFilter !== -1;
    });

    return catalogosFilter;
}
