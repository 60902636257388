import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import KeyIcon from '@mui/icons-material/Key';

import useStyles from '../../../theme/useStyles';

export const ListItemAdmin = () => {

    const classes = useStyles();
    
    const [anchorEl, anchorElOpen] = useState(null);
  
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        anchorElOpen( e.currentTarget );
    };
  
    const handleClose = () => {
        anchorElOpen( null );
    };

    return (
        <>
            <ListItemText className={classes.listItemOptions} inset>
                <Button color="inherit" className={classes.buttonIcon} onClick={ handleOpen }>
                    <AdminPanelSettingsIcon className={classes.PaddingIcon} />

                    <Typography color="inherit" variant="subtitle2" className={classes.ListTypography}> 
                        Administración
                    </Typography>
                    
                    <KeyboardArrowDownIcon />                            
                </Button>
            </ListItemText>  

            <Menu elevation={ 2 } anchorEl={ anchorEl } anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }} keepMounted open={ open } onClose={ handleClose }>
                
                <Link to="/Admin/Users" className={classes.link}>
                     <MenuItem onClick={ handleClose } className={ classes.menuItem }>                    
                        <ListItemIcon className={ classes.listItemIcon }> <PeopleIcon /> </ListItemIcon>
                        <ListItemText>Usuarios</ListItemText>                   
                    </MenuItem>
                </Link>
                
                <Link to="/Admin/Catalogs" className={classes.link}>
                    <MenuItem onClick={ handleClose } className={ classes.menuItem }> 
                        <ListItemIcon className={ classes.listItemIcon }> <FormatListBulletedIcon /> </ListItemIcon>
                        <ListItemText>Catálogos</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Admin/Templates" className={classes.link}>
                    <MenuItem onClick={ handleClose } className={ classes.menuItem }> 
                        <ListItemIcon className={ classes.listItemIcon }> <DescriptionIcon /> </ListItemIcon>
                        <ListItemText>Plantillas</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Admin/Consecutivos" className={classes.link}>
                    <MenuItem onClick={ handleClose } className={ classes.menuItem }> 
                        <ListItemIcon className={ classes.listItemIcon }> <FormatListNumberedIcon /> </ListItemIcon>
                        <ListItemText>Consecutivos</ListItemText>
                    </MenuItem>
                </Link>
                
                <Link to="/Admin/Actividades" className={classes.link}>
                    <MenuItem onClick={ handleClose } className={ classes.menuItem }> 
                        <ListItemIcon className={ classes.listItemIcon }> <LowPriorityIcon /> </ListItemIcon>
                        <ListItemText>Reasignar o cancelar actividad</ListItemText>
                    </MenuItem>
                </Link>
                
                <Link to="/Admin/AdministrarDocumentos" className={classes.link}>
                    <MenuItem onClick={ handleClose } className={ classes.menuItem }> 
                        <ListItemIcon className={ classes.listItemIcon }> <DescriptionIcon /> </ListItemIcon>
                        <ListItemText>Administrar documentos</ListItemText>
                    </MenuItem>                
                </Link>

                <Link to="/Admin/Apikeys" className={classes.link}>
                    <MenuItem onClick={ handleClose } className={ classes.menuItem }> 
                        <ListItemIcon className={ classes.listItemIcon }> <KeyIcon /> </ListItemIcon>
                        <ListItemText>Api key´s</ListItemText>
                    </MenuItem>                
                </Link>

            </Menu>  
        </>
    )   
}
