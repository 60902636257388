import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider,  FormControl,  Grid, IconButton,  InputBase,  InputLabel,  MenuItem,  Paper,  Select,  Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SourceIcon from '@mui/icons-material/FileCopyRounded';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';
import { ConsultaConveniosFueraJuicio } from '../../actions/recepcion/irregularActions';

import { ModalDetalleIrregular } from '../modals/ModalDetalleIrregular';

export const ConvenioFueraJuicioScreen = () => {
    
    const { administrador, coordinador } = useSelector( state => state.auth );
    
    const [rows, setRows] = useState( [ ] );

    const [fecha, setFecha] = useState( '' );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages( 10 );

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [irregular, setIrregular] = useState( 0 );
    const [documento, setDocumento] = useState( '' );
    const [openDocumento, setOpenDocumento] = useState( false );
    const [openDetalleIrregular, setOpenDetalleIrregular] = useState( false );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [numeroReferencia, setNumeroReferencia] = useState( '' );

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setNumeroReferencia( '' );
        setFecha( '' );
        setAutoridad( 0 );
        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();

    }

    const handleBuscar = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );                

    }

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaConveniosFueraJuicio( { page, rowsPerPage, fecha, numeroReferencia, idAutoridad: autoridad } ).then( response => {
                    
                if( response ){
    
                    if( response.status === 200 ){

                        if( response.data ){
                            

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );                                
                                setLoadingSearch( false );
                                setLoading( false );  

                            }, 1000);                            

                        }

                    }

                }

            });

        }     
        
        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, fecha, numeroReferencia, autoridad ]);   

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaConveniosFueraJuicio( { page, rowsPerPage, fecha: '', numeroReferencia: '', idAutoridad: 0 } ).then( response => {
                    
                if( response ){
    
                    if( response.status === 200 ){

                        if( response.data ){
                            

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );                                
                                setLoadingSearch( false );
                                setLoading( false );  

                            }, 1000);                            

                        }

                    }

                }

            });

        }     
        
        if( !filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado ]);   

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  
                
                <Container maxWidth="xl">
                    
                    <Grid container spacing={3} >

                        <Grid item lg={12} md={12} xs={12}>

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3}>

                                        {     
                                            ( administrador || coordinador )
                                            && 
                                                <Grid item md={4} sm={6} xs={12}> 
                                                                    
                                                    <FormControl fullWidth disabled={ filtroAplicado } >
                                                        <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                                    
                                                            <Select
                                                                labelId="select-autoridad-label"
                                                                id="select-autoridad"                                            
                                                                label="Autoridad"
                                                                name="autoridad"
                                                                value={ autoridad }                                
                                                                onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                                                        >
                                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                                {
                                                                    coordinador
                                                                    ?
                                                                    autoridadArray 
                                                                    .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                                    .map( ( elem ) => (
                                                                    <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                    ))
                                                                    :
                                                                        autoridadArray.map( ( elem ) => (
                                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                    ))
                                                                }                          
                                                            </Select>
                        
                                                        </FormControl>
                                                                                
                                                </Grid>
                                        }    

                                        <Grid item md={4} xs={12} >

                                            <TextField                                       
                                                label="Fecha del convenio"
                                                type="date"                                        
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="fechaExhorto"
                                                value={ fecha }
                                                onChange={ ( e ) => setFecha( e.target.value ) }
                                                disabled={ filtroAplicado || ( ( administrador || coordinador ) && autoridad === 0 ) }                                                  
                                            />

                                        </Grid> 

                                        <Grid item md={4} xs={12}>      

                                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                              
                                                        
                                                <InputBase sx={{ ml: 1, flex: 1 }} inputProps={{ 'aria-label': 'Buscar por número de convenio' }} 
                                                    placeholder="Buscar por número de convenio"     
                                                    value={ numeroReferencia }
                                                    onChange={ ( e ) => setNumeroReferencia( e.target.value )  }
                                                    disabled={ filtroAplicado || ( ( administrador || coordinador ) && autoridad === 0 ) }                 
                                                />
                                            
                                            </Paper>                                

                                        </Grid> 

                                        <Grid item md={2} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                fullWidth 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                                loading={ loadingSearch }
                                                disabled={ fecha === '' && autoridad === 0 && numeroReferencia === '' }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>  

                                    </Grid>

                                </CardContent>

                            </Card>

                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ pt: 3, pb: 3 }} variant="h4" >
                                        Convenios fuera de juicio
                                    </Typography>
                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >

                                        <TableHead>

                                            <TableRow>   

                                                <TableCell style={{ width:'7%', fontWeight: 'bold', textAlign: 'center'  }}> NO. CONVENIO  </TableCell>                                                
                                                <TableCell style={{ width:'12%', fontWeight: 'bold' }}> FECHA </TableCell> 
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> ACTOR(ES) </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> DEMANDADO(S) </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> PONENCIA </TableCell>
                                                <TableCell style={{ width:'5%', fontWeight: 'bold' }}> ACUERDO </TableCell>                                                

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                rows.map( ( row, i ) => (

                                                    <TableRow key={ i } >
                                 
                                                        <TableCell> 

                                                            {
                                                                row?.numeroIrregular
                                                                &&
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { setIrregular( row?.idIrregular ?? 0 ); setOpenDetalleIrregular( true );  } }
                                                                >
                                                                    { row?.numeroIrregular ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell>

                                                        <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>        
                                                        <TableCell> { row.actor } </TableCell>    
                                                        <TableCell> { row.demandado } </TableCell>     
                                                        <TableCell> { row.ponencia } </TableCell>     

                                                        <TableCell sx={{ textAlign: 'center' }}>                                                  

                                                            {
                                                                row?.acuerdo
                                                                &&                                                            
                                                                <IconButton
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    size='large'
                                                                    onClick={ () => { setDocumento( row?.acuerdo ?? '' ); setOpenDocumento( true ); } }
                                                                >
                                                                    <SourceIcon fontSize='18' /> 
                                                                </IconButton>
                                                            }

                                                        </TableCell>                                                       

                                                    </TableRow>

                                                ))
                                            }

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>

                                                        <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron convenios'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TableCell colSpan={2}>
                                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de convenios: { recordsCount } </Typography>
                                                </TableCell> 

                                                <TablePagination
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    colSpan={ 10 }
                                                    count={ recordsCount }                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={ page }                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />

                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Paper>

                        </Grid>

                    </Grid>                   

                </Container>

            </Box>
            
            <Dialog open={ openDocumento } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                                documento
                                ?                                   
                                    <iframe title='acuse' src={ documento } height="500px" width="100%"></iframe>
                                :
                                    <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                        No se encontró el documento
                                    </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenDocumento( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

           </Dialog>  
         
            <ModalDetalleIrregular
                open={ openDetalleIrregular }    
                setOpen={ setOpenDetalleIrregular }
                idIrregular={ irregular }
            />

        </>
    )
}