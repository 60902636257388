import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';

export const ModalDetalleOficio = ( { open, setOpen, oficioFirmado } ) => {
    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth={ true } disablePortal
                     aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
              
                <DialogContent>

                    <Grid item lg={12} md={12} xs={12}>

                        {
                            oficioFirmado
                            &&
                            <iframe 
                                title='iframe oficio'
                                style={{ width: '100%', height: '65vh' }} 
                                src={ oficioFirmado }  
                            />
                            
                        }

                    </Grid>


                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => setOpen(false) } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>            
        </>
    )
}
