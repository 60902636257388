import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../ui/AppBarMenu';

import { ActualizarEstatusNotificacion, ObtenerNotificacionDetalle } from '../../actions/notificaciones/notificacionActions';

import { types } from '../../types/types';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';

export const VisualizarNotificacionScreen = (props) => {

    const dispatch = useDispatch();
    
    const { Id } = props?.match?.params;

    const [loading, setLoading] = useState(false);

    const [notificacion, setNotificacion] = useState( {} );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [alert, setAlert] = useState( {  } );

    const [domicilioNotificar, setDomicilioNotificar] = useState( { } );

    const [referencia, setReferencia] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const Finalizar = async() => {

        setLoadingFinalizar( true );

        const params = {
            'idNotificacion' : Id,
            'idEstatus': notificacion?.idTipoNotificacion === 3 ? 47 : 12,
        };

        await ActualizarEstatusNotificacion( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoading( true );

                        setLoadingFinalizar( false );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idNotificacion': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerNotificacionDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setNotificacion(response.data);   

                            if( ( response?.data?.numeroAmparo ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferencia( response?.data?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhorto ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferencia( response?.data?.numeroExhorto ?? '' );
                            }                           
                            else if( ( response?.data?.numeroIrregular ?? '') !== '' ){
                                setTipoReferencia( 'Número de irregular' );
                                setReferencia( response?.data?.numeroIrregular ?? '' );
                            } 
                            else if(( response?.data?.numeroExpediente ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferencia( response?.data?.numeroExpediente ?? '' );
                            }   
                         
                            if( Object.keys( response?.data?.domicilio ).length !== 0 ){

                                const descripcionDomicilio = response.data.domicilio?.descripcion ?? '';
                                const numeroDomicilio = response.data.domicilio?.numero ?? '';
                                const codigoPostal = response.data.domicilio?.asentamiento?.codigoPostal ?? '';
                                const municipio = response.data.domicilio?.asentamiento?.municipio?.descripcion ?? '';
                                const estado = response.data.domicilio?.asentamiento?.estado?.descripcion ?? '';                       

                                setDomicilioNotificar( descripcionDomicilio ? ( descripcionDomicilio + ' #' + numeroDomicilio + ', C.P ' + codigoPostal + ', ' + municipio + ', ' + estado ) : '' );                                    
                            }                            
                     
                            if( response.data.idEstatus !== 11 ){
                                setLoading( true );       
                            }
                            
                            dispatch({ type: types.closeLoading });

                            setAjaxLoading( true );                            

                        }, 1200);

                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])    

    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        {
                                            notificacion?.idTipoNotificacion === 3
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                                    No se tienen actividades pendientes para este trámite
                                                </Typography>
                                            :
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>
                                                    La siguiente actividad se encuentra en tu inbox
                                                </Typography>
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>
                                
                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}>
                                   
                                    <InformacionSeguimiento 
                                        noSeguimiento={ 'GN-' + notificacion.idNotificacion }
                                        actividad={ 'Generar notificación' }
                                        tipo={ tipoReferencia }
                                        referencia={ referencia }
                                    />

                                </Grid>
                                        
                                {                                             
                                    notificacion.numeroExhorto
                                    &&
                                    <>
                                        <Grid item md={2} xs={3}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Número de exhorto:
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={10} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { notificacion.numeroExhorto }
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                { 
                                    
                                    notificacion.numeroAmparo
                                    &&
                                    <>
                                        <Grid item md={2} xs={3}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Número de amparo:
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={10} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { notificacion.numeroAmparo }
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                { 
                                    
                                    notificacion.numeroIrregular
                                    &&
                                    <>
                                        <Grid item md={2} xs={3}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Número de irregular:
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={10} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { notificacion.numeroIrregular }
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                
                                { 
                                    notificacion.numeroExpediente
                                    &&
                                    <>
                                        <Grid item md={2} xs={3}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Número de expediente:
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={10} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { notificacion.numeroExpediente }
                                            </Typography>
                                        </Grid>
                                    </>
                                }                           
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Notificar a:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                                        { notificacion.personaNotificar }
                                    </Typography>
                                </Grid>  

                                <Grid item md={2} xs={2}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Tipo de parte:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                    { notificacion.tipoParte }
                                    </Typography>
                                </Grid>  

                                {
                                    domicilioNotificar
                                    &&
                                    <>
                                        <Grid item md={2} xs={2}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                                Domicilio a notificar:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={10} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                            { domicilioNotificar }
                                            </Typography>
                                        </Grid> 
                                    </>
                                }                                 

                                <Grid item md={2} xs={2}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Tipo de notificación:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                    { notificacion.tipoNotificacion }
                                    </Typography>
                                </Grid>  

                                {
                                    notificacion.formaNotificacion
                                    &&
                                    <>
                                        <Grid item md={2} xs={2}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                                Forma notificación:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={9} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                            { notificacion.formaNotificacion }
                                            </Typography>
                                        </Grid>                                     
                                    </>
                                } 

                                <Grid item md={12} xs={12}>
                                    
                                    <Typography gutterBottom variant="subtitle2" style={{ color: 'gray', fontWeight: 'bold' }}>
                                        Notificación
                                    </Typography>

                                    {
                                        notificacion.documentoNotificacionFirmado
                                        ?
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ notificacion.documentoNotificacionFirmado }  
                                            />
                                        :                                        
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ "data:application/pdf;base64," + notificacion.documentoNotificacion }  
                                            />
                                    }

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                    <Button variant="contained" onClick={ handleOpenConfirmacion } endIcon={ <CheckCircleIcon /> } > Finalizar </Button>
                                </Grid>    

                            </Grid>

                        </Container>
                    
                    )

                }

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar la visualización de la notificación?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained' 
                            loading={ loadingFinalizar }
                        > 
                            Aceptar 
                        </LoadingButton>

                    </DialogActions>

                </Dialog>

            </Box>

        </>
    )
}
