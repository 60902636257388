import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Alert, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import moment from 'moment';

import { types } from '../../../types/types';

import { ObtenerPonencias } from '../../../actions/admin/catalogs/ponenciaActions';

import { RegistrarInformacion } from '../../../actions/recepcion/recepcionActions';
import { ObtenerAutoridadesExhorto } from '../../../actions/admin/catalogs/autoridadExhortoActions';
import { ObtenerTipoJuicio } from '../../../actions/admin/catalogs/tipojuicioActions';

export const RegistrarInformacionScreen = ( { handleNext, idRecepcion } ) => {
    
    const dispatch = useDispatch();

    const { tribunal, juzgado } = useSelector( state => state.auth );
    
    const { exhortoRecibido, recepcion } = useSelector( state => state.recepcion );

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [autoridadExhorto, setAutoridadExhorto] = useState( 0 );
    const [autoridadExhortoArray, setAutoridadExhortoArray] = useState( [ ] );

    const [numeroOrigenExhorto, setNumeroOrigenExhorto] = useState( '' );

    const [fechaReloj, setFechaReloj] = useState( DateNow );
    const [motivoAsignacion, setMotivoAsignacion] = useState( '' );
    
    const [ponencia, setPonencia] = useState( 0 );
    const [ponenciaArray, setPonenciaArray] = useState( [ ] );
    
    const [recibidoBuzon, setRecibidoBuzon] = useState( false );
    const [recibidoReloj, setRecibidoReloj] = useState( false );
    const [asignacionDirecta, setAsignacionDirecta] = useState( false );
    
    const [errors, setErrors] = useState({  });

    const [loadingNext, setLoadingNext] = useState( false );

    const handleChangeTipoJuicio = ( e, { props }) => {

        setTipoJuicio( e.target.value );

    }

    const validateFiels = () => {
        let valid = true;
        let errores = {};
        
        if( autoridadExhorto === 0 ){
            valid = false;
            errores.autoridadExhorto = 'Debes seleccionar una autoridad del exhorto';
        }
        if( tipoJuicio === 0 ){
            valid = false;
            errores.tipoJuicio = 'Debes seleccionar un tipo de juicio';
        }
        if( numeroOrigenExhorto === '' ){
            valid = false;
            errores.numeroOrigenExhorto = 'Escribe el número de origen del exhorto';
        }
        if( fechaReloj === '' && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'Debes seleccionar la fecha de reloj';
        }
        if( motivoAsignacion === ''&& asignacionDirecta  ){
            valid = false;
            errores.motivoAsignacion = 'Debes escribir el motivo de la asignación';
        }
        if( ponencia === 0 && asignacionDirecta ){
            valid = false;
            errores.ponencia = 'Debes seleccionar la ponencia';
        }
        if( moment( new Date( fechaReloj ) ) > moment( new Date( ) ) && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'La fecha de reloj no puede ser mayor a la fecha actual';
        }
        
        setErrors( errores );        

        return valid;
    } 

    const Siguiente = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );

        const params = {

            idRecepcion: idRecepcion,
            idAutoridadExhorto: autoridadExhorto,
            numeroOrigen: numeroOrigenExhorto,
            recibidoPorReloj: recibidoReloj,
            asignacionDirecta: asignacionDirecta,
            motivoAsignacion: motivoAsignacion,
            idPonencia: ponencia,
            fechaReloj: recibidoReloj ? fechaReloj : null,
            RecibidoBuzonElectronico: recibidoBuzon,
            idTipoJuicio: tipoJuicio

        };

        await RegistrarInformacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    var { descripcion: descripcionAutoridadExhorto } = autoridadExhortoArray.find( ( element ) => { return element.idAutoridadExhorto === autoridadExhorto });                   
                    var { descripcion: descripcionTipoJuicio } = tipoJuicioArray.find( ( element ) => { return element.idTipoJuicio === tipoJuicio });                   

                    dispatch({

                        type: types.actualizarExhortoRecibido,
                        payload: {

                            idAutoridadExhorto: autoridadExhorto,
                            autoridadExhorto: descripcionAutoridadExhorto,
                            numeroExhortoOrigen: numeroOrigenExhorto,
                            recibidoBuzon: recibidoBuzon,
                            recibidoReloj: recibidoReloj,
                            asignacionDirecta: asignacionDirecta,
                            motivoAsignacion: motivoAsignacion,
                            idPonencia: ponencia,
                            ponencia: ponencia !== 0 ? ponenciaArray.find( ( element ) => { return element.idPonencia === ponencia }).descripcion : '',
                            fechaReloj: recibidoReloj ? moment( new Date( fechaReloj ) ).format("YYYY-MM-DDTHH:mm") : '',
                            numeroExhorto : exhortoRecibido.numeroExhorto,
                            idTipoJuicio: tipoJuicio,
                            tipoJuicio: descripcionTipoJuicio
                        }
                    });       
                    
                    setTimeout(() => {
                        
                        setLoadingNext( false );

                        handleNext();

                    }, 500);  

                }

            }

        });
    }

    const handleChangeAsignacion = ( checked ) => {

        setAsignacionDirecta( checked );
        setMotivoAsignacion( '' );
        setPonencia( 0 );

    }

    const handleChangeRecibidoReloj = ( value ) => {

        setRecibidoReloj( value );

        const Now = moment( new Date()).format("YYYY-MM-DDTHH:mm");
        setFechaReloj( Now );
    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerPonencias( ).then( response => {

                if( response ){

                    if( response.data ){
                        setPonenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerAutoridadesExhorto().then( response => {

                if( response ){

                    if( response.data ){
                        setAutoridadExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoJuicio().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoJuicioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);


    useEffect(() => {      

        if( exhortoRecibido ){
            setNumeroOrigenExhorto( exhortoRecibido.numeroExhortoOrigen ?? '' );
            setRecibidoBuzon( exhortoRecibido.recibidoBuzon );
            setRecibidoReloj( exhortoRecibido.recibidoReloj );
            setAsignacionDirecta( exhortoRecibido.asignacionDirecta );
            setMotivoAsignacion( exhortoRecibido.motivoAsignacion );
            setFechaReloj( exhortoRecibido.fechaReloj === '' ? DateNow : exhortoRecibido.fechaReloj );
        }
      
    }, [ exhortoRecibido, DateNow ])

    useEffect(() => {   
        
        if( exhortoRecibido && ponenciaArray.length > 0 ){
            setPonencia( exhortoRecibido.idPonencia ?? 0 );
        }  

    }, [ exhortoRecibido, ponenciaArray ])

    useEffect(() => {    
        
        if( exhortoRecibido && autoridadExhortoArray.length > 0 ){
            setAutoridadExhorto( exhortoRecibido.idAutoridadExhorto ?? 0 );
        }
      
    }, [ exhortoRecibido, autoridadExhortoArray ])

    useEffect(() => {     
       
        if( exhortoRecibido && tipoJuicioArray.length > 0 ){
            setTipoJuicio( exhortoRecibido.idTipoJuicio ?? 0 );
        }
      
    }, [ exhortoRecibido, tipoJuicioArray ])

    useEffect(() => {
     
        const requests = axios.CancelToken.source(); 

        return () => {
            requests.cancel();
        }

    }, [ ])
    
    return (
        <>           
            <Grid item md={8} xs={12} >                            
               
               <FormControl fullWidth>
                   <InputLabel id="select-tipoJuicio-label">Tipo de juicio</InputLabel>
               
                   <Select
                       labelId="select-tipoJuicio-label"
                       id="select-tipoJuicio"                                            
                       label="Tipo de juicio"
                       name="tipoJuicio"
                       value={ tipoJuicio }
                       onChange={ handleChangeTipoJuicio }
                   >
                       <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                       {
                           juzgado
                           ?                            
                           tipoJuicioArray
                           .filter( (element ) => { return element.juzgado === true; } )
                           .map( ( element ) => (
                               <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                           ))
                           :
                           tipoJuicioArray
                           .filter( (element ) => { return element.tribunal === true; } )
                           .map( ( element ) => (
                               <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                           ))
                       }
                   </Select>

               </FormControl>
               {   
                   errors.tipoJuicio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuicio } </Alert> )
               }
            </Grid> 

            <Grid item md={4} xs={12} >
                <TextField                              
                    label="Número de exhorto de origen"                     
                    value={ numeroOrigenExhorto }                                
                    onChange={ ( e ) => { setNumeroOrigenExhorto( e.target.value ); } }
                    fullWidth                              
                />
                {   
                    errors.numeroOrigenExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.numeroOrigenExhorto } </Alert> )
                }
            </Grid>

            <Grid item md={12} xs={12} >                            
                <FormControl fullWidth>
                    <InputLabel id="select-autoridadExhorto-label">Autoridad del exhorto</InputLabel>
                
                    <Select
                        labelId="select-autoridadExhorto-label"
                        id="select-autoridadExhorto"                                            
                        label="Autoridad del exhorto"
                        name="autoridadExhorto"
                        value={ autoridadExhorto }
                        onChange={ ( e ) => { setAutoridadExhorto( e.target.value ); } }
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                        {
                            autoridadExhortoArray.map( ( element ) => (
                                <MenuItem key={ element.idAutoridadExhorto } value={ element.idAutoridadExhorto }>{ element.descripcion }</MenuItem>                        
                            ))
                        }
                    </Select>
                    {   
                        errors.autoridadExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridadExhorto } </Alert> )
                    }
                </FormControl>
            </Grid> 

            <Grid item md={12} xs={12} >
                <FormControl component="fieldset" disabled={ recepcion?.provieneBuzonElectronico }>
                    <FormLabel component="legend">¿El trámite proviene del buzón electrónico?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoBuzon"
                        name="recibidoBuzon"                         
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === true } onChange={ () => { setRecibidoBuzon( true ); } } />} label="Si" />   
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === false } onChange={ () => { setRecibidoBuzon( false ); } } />} label="No" />
                       
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿El trámite es recibido por reloj?</FormLabel>    
                    <RadioGroup row
                        aria-label="recibidoReloj"
                        name="recibidoReloj"                           
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === true } onChange={ () => { handleChangeRecibidoReloj( true ); } } />} label="Si" /> 
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === false } onChange={ () => { handleChangeRecibidoReloj( false ); } } />} label="No" />                       
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >

                {
                    recibidoReloj && (
                        <>
                            <TextField                              
                                label="Fecha recibida por reloj"
                                type="datetime-local"   
                                name="fechaReloj"                             
                                value={ fechaReloj }    
                                onChange={ ( e ) => { setFechaReloj( e.target.value ); } }                                                     
                                fullWidth
                                inputProps={{
                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                }}                             
                                InputLabelProps={{
                                    shrink: true,
                                }}                   
                                                
                            />

                            {   
                                errors.fechaReloj && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaReloj } </Alert> )
                            }

                        </>
                    )
                }

            </Grid>

            {
                ( tribunal )
                &&
                <Grid item md={3} xs={12} >
                    <FormControl component="fieldset" disabled={ exhortoRecibido.numeroExhorto !== "" } >
                        <FormLabel component="legend">¿Es asignación directa?</FormLabel>
                        <RadioGroup row
                            aria-label="asignacionDirecta"                                   
                            name="asignacionDirecta"                     
                        >
                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === false } onChange={ () => { handleChangeAsignacion( false ); } } />} label="No" />
                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === true } onChange={ () => { handleChangeAsignacion( true ); } } />} label="Si" />   
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }

            {
                asignacionDirecta && (

                    <>
                        <Grid item md={9} xs={12} >
                            <TextField                              
                                label="Motivo de la asignación directa"    
                                name="motivoAsignacion"                                                 
                                value={ motivoAsignacion }
                                onChange={ ( e ) => { setMotivoAsignacion( e.target.value ); } }                           
                                multiline
                                rows={4}  
                                fullWidth      
                                disabled={ exhortoRecibido.numeroExhorto !== "" }                    
                            />

                            {   
                                errors.motivoAsignacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.motivoAsignacion } </Alert> )
                            }

                        </Grid>

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth disabled={ exhortoRecibido.numeroExhorto !== "" } >
                                <InputLabel id="select-Ponencia-label">Ponencia</InputLabel>
                            
                                <Select
                                    labelId="select-Ponencia-label"
                                    id="select-Ponencia"                                            
                                    label="Ponencia"
                                    name="ponencia"
                                    value={ ponencia }
                                    onChange={ ( e ) => { setPonencia( e.target.value ); } }                           
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        ponenciaArray.map( ( element ) => (
                                            <MenuItem key={ element.idPonencia } value={ element.idPonencia }>{ element.descripcion }</MenuItem>                        
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.ponencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.ponencia } </Alert> )
                            }

                        </Grid> 
                    </>

                )
            }   

            <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>  
              
                <LoadingButton 
                    variant="contained" 
                    onClick={ Siguiente }
                    endIcon={ <ArrowForwardIcon /> }
                    loading={ loadingNext }
                > 
                    Siguiente 
                </LoadingButton>

            </Grid>
        </>
    );
}
