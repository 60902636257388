import { List, ListItem, ListItemText, Typography } from '@mui/material';

export const EventComponentMonth = ( { event } ) => {     
    return (
        <ListItemText primary={ <Typography sx={{ fontSize: 14 }} variant="inherit"> { event.title } </Typography> } /> 
    );        
}

export const EventComponentWeek = ( { event } ) => {      
    return ( 
        <ListItemText primary={ <Typography sx={{ fontSize: '14px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> { event.title } </Typography> } 
            secondary={ <Typography sx={{ fontSize: '12px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> Expediente: { event.expediente.numeroExpediente } </Typography> }
        />
    );        
}

export const EventComponentDay = ( { event } ) => {       
    return ( 
        <ListItemText primary={ <Typography sx={{ fontSize: '14px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> { event.title } </Typography> } 
            secondary={ <Typography sx={{ fontSize: '12px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> Expediente: { event.expediente.numeroExpediente } </Typography> }
        />
    );        
}

export const EventComponentAgenda = ( { event } ) => {    
    return ( 
        <List>
            <ListItemText 
                primary={ 
                    <Typography sx={{ fontSize: '14px' }} variant="inherit"> 
                        <strong> { event.title } </strong> 
                        <span style={{ fontSize: '12px' }}>
                            ({ event.estatus }) 
                        </span>                        
                    </Typography> 
                }                     
            />

            <ListItemText 
                primary={ <Typography sx={{ fontSize: '14px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> Expediente: { event.expediente.numeroExpediente } </Typography> }                     
            />

            {
                event.expediente.ponencia
                &&            
                <ListItemText 
                    primary={ <Typography sx={{ fontSize: '14px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> Ponencia: { event.expediente.ponencia } </Typography> } 
                />
            }

            <ListItemText 
                primary={ <Typography sx={{ fontSize: '14px' }} style={{ paddingTop: '0.5em' }} variant="inherit"> Actor(es) </Typography> }                     
            />

            <List disablePadding style={{ paddingTop: '0.2em' }}>                               
            {
                event.expediente.actor.map((elem, i)=>(
                    <ListItem key={ i } disablePadding> 
                        <Typography gutterBottom sx={{ fontSize: '12px' }} style={{ paddingTop: '0.2em' }} >
                             - { elem }
                        </Typography>
                    </ListItem>                    
                ))
            }                                
            </List>  

        </List>
    );        
}