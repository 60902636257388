import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { ActualizarTipoPrestacionJuicio, AgregarTipoPrestacionJuicio, TipoPrestacionJuicio } from '../../../actions/admin/catalogs/tipoPrestacionJuicioActions';

import { ObtenerTipoJuicio } from '../../../actions/admin/catalogs/tipojuicioActions';
import { ObtenerTipoPrestacion } from '../../../actions/admin/catalogs/tipoprestacionActions';
    
export const TipoPrestacionJuicioScreen = ( { history } ) => {

    const { location } = history;
    const { pathname } = location;

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [tipoPrestacion, setTipoPrestacion] = useState( 0 );
    const [tipoPrestacionArray, setTipoPrestacionArray] = useState( [ ] );

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idTipoPrestacionJuicio === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const tipoPrestacionJuicio = rows.filter( function(row){ return row.idTipoPrestacionJuicio === id; } );
        cambiarEstatus( tipoPrestacionJuicio[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const Regresar = () => {
        history.push("/Admin/Catalogs");
    }

    const cambiarEstatus = async ( { idTipoPrestacionJuicio, idTipoPrestacion, idTipoJuicio, activo } ) => {

        const params = {
            idTipoPrestacionJuicio,
            idTipoPrestacion: idTipoPrestacion,
            idTipoJuicio: idTipoJuicio,
            activo,
        };

        await ActualizarTipoPrestacionJuicio( params );
    }

    const AgregarRegistro = () => {

        setTipoJuicio( 0 );
        setTipoPrestacion( 0 );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, idTipoPrestacion, idTipoJuicio ) => {
        
        setTipoPrestacion( idTipoPrestacion );
        setTipoJuicio( idTipoJuicio);

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        if( opcion === 'agregar' ){
            const params = {
                idTipoPrestacionJuicio: 0,
                idTipoPrestacion: tipoPrestacion,
                idTipoJuicio: tipoJuicio,
                activo: true,
            };

            await AgregarTipoPrestacionJuicio( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            setRows([ 
                                ...rows,  
                                {
                                    idTipoPrestacionJuicio: response.data.idTipoPrestacionJuicio,
                                    idTipoPrestacion: response.data.idTipoPrestacion,
                                    tipoPrestacion: response.data.tipoPrestacion,
                                    idTipoJuicio: response.data.idTipoJuicio,        
                                    tipoJuicio: response.data.tipoJuicio,                            
                                    activo: true
                                }
                            ]);  

                            handleCloseModal();
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const TipoPrestacionJuicio = rows.filter( function(row){ return row.idTipoPrestacionJuicio === idReferencia; } )[0];

            const params = {
                idTipoPrestacionJuicio: idReferencia,
                idTipoPrestacion: tipoPrestacion,
                idTipoJuicio: tipoJuicio,
                activo: TipoPrestacionJuicio.activo,
            };
    
            await ActualizarTipoPrestacionJuicio( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idTipoPrestacionJuicio === idReferencia){ 
                                row.tipoPrestacion = response.data.tipoPrestacion;
                                row.tipoJuicio = response.data.tipoJuicio;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModal();
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( tipoPrestacion === 0 ){
            valid = false;
            errores.tipoPrestacion = 'Debes seleccionar un tipo de prestación';
        }  
        
        if( tipoJuicio === 0 ){
            valid = false;
            errores.tipoJuicio = 'Debes seleccionar un tipo de juicio';
        }  
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await TipoPrestacionJuicio().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);


    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTipoJuicio().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoJuicioArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTipoPrestacion().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoPrestacionArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <AppBarMenu />            

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg">   

                    <Grid container spacing={3} >  

                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                            <Button type="button" startIcon={ <ArrowBackIcon /> } variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                                Regresar
                            </Button> 
                        </Grid>
                       
                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>
                           
                        <Grid item xs={12} md={12}> 

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    
                                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                        {
                                            desc.length !== 0 ? desc[0].descripcion : ''
                                        }
                                    </Typography>

                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >
                                        <TableHead>
                                            <TableRow>        
                                                <TableCell style={{ width:'10%' }}> ID </TableCell>
                                                <TableCell style={{ width:'30%' }}> TIPO DE PRESTACIÓN </TableCell>
                                                <TableCell style={{ width:'30%' }}> TIPO DE JUICIO </TableCell>
                                                <TableCell style={{ width:'10%' }}> HABILITADO </TableCell>
                                                <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                        {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <TableRow key={ row.idTipoPrestacionJuicio} >
                                            
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    { row.idTipoPrestacionJuicio }
                                                </TableCell>

                                                <TableCell>
                                                    { row.tipoPrestacion.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.tipoJuicio.descripcion }
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {
                                                        row.activo 
                                                        ? 
                                                        (
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idTipoPrestacionJuicio, e.target.checked ) }
                                                            />
                                                        ) 
                                                        :                                           
                                                        ( 
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idTipoPrestacionJuicio, e.target.checked ) }
                                                            />
                                                        )
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton 
                                                            color="primary" 
                                                            aria-label="edit" 
                                                            component="span" 
                                                            onClick={ () => EditarRegistro( row.idTipoPrestacionJuicio, row.idTipoPrestacion, row.idTipoJuicio ) }
                                                        >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        { rows.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={6} align="center"> 
                                                No se encontraron resultados
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[ ]}
                                                    colSpan={6}
                                                    count={rows.length}                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            
                            </Paper>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
            
                <DialogContent>

                    <FormControl fullWidth sx={{ mt: 3 }} >
                        <InputLabel id="select-tipoPrestacion-label">Tipo de prestación</InputLabel>
                    
                        <Select
                            labelId="select-tipoPrestacion-label"
                            id="select-tipoPrestacion"                                            
                            label="Tipo de prestación"
                            name="tipoPrestacion"
                            value={ tipoPrestacion }                                
                            onChange={ ( e ) => { setTipoPrestacion( e.target.value ) } }
                        >
                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                            {
                                tipoPrestacionArray.map( ( elem ) => (
                                    <MenuItem key={ elem.idTipoPrestacion} value={ elem.idTipoPrestacion}> { elem.descripcion } </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                    {   
                        errors.tipoPrestacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoPrestacion } </Alert> )
                    }                   
                       
                    <FormControl fullWidth sx={{ mt: 3 }} >
                        <InputLabel id="select-tipoetapa-label">Tipo de juicio</InputLabel>
                    
                        <Select
                            labelId="select-tipoetapa-label"
                            id="select-tipoetapa"                                            
                            label="Tipo de Etapa"
                            name="etapa"
                            value={ tipoJuicio }                                
                            onChange={ ( e ) => { setTipoJuicio( e.target.value ) } }
                        >
                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                            {
                                tipoJuicioArray.map( ( elem ) => (
                                    <MenuItem key={ elem.idTipoJuicio} value={ elem.idTipoJuicio}> { elem.descripcion } </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                    {   
                        errors.tipoJuicio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuicio } </Alert> )
                    }                   

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }> Cancelar </Button>
                    <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

            </Dialog>

        </>
    )
}

