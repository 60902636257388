import React, { useState, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { BuscarSentenciaVersionCorta } from '../../actions/sentencias/sentenciaActions';

export const ModalBuscarSentenciaCorta = ( { openModal, handleCloseModal, expediente, SeleccionarSentencia } ) => {

    const [rows, setRows] = useState( [] );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [loadingSearch, setLoadingSearch] = useState(false);

    const Seleccionar = ( data ) => {
        
        SeleccionarSentencia( data );
        CerrarModal();
    }

    const CerrarModal = () => {

        handleCloseModal();
        
        setRows( [ ] );

    } 

    useEffect(() => {

        async function Obtener(){

            const { idExpediente } = expediente;

            setLoadingSearch( true );

            await BuscarSentenciaVersionCorta( idExpediente ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                        
                            setRows( response.data );
                            
                            setLoadingSearch( false );

                        }, 1000);                        

                    }
                    else{
                        
                        setLoadingSearch( false );

                    }

                }

            });
        }       
        
        if( openModal && expediente?.success){

            Obtener();

        }

    }, [ openModal, expediente ]);


    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Buscar sentencia</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}>         

                        <Grid item md={12} xs={12} >                           

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'30%', fontWeight: 'bold' }}>NÚMERO DE SEGUIMIENTO</TableCell>
                                            <TableCell style={{ width:'30%', fontWeight: 'bold' }}>NÚMERO DE SENTENCIA</TableCell>
                                            <TableCell style={{ width:'30%', fontWeight: 'bold' }}>FECHA</TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( (row) => (

                                            <TableRow key={ row.idSentencia }>
                                                <TableCell style={{ fontWeight: 'Bold' }}> { row.noSeguimiento } </TableCell>
                                                <TableCell> { row.noSentencia } </TableCell>
                                                <TableCell> { row.fecha } </TableCell>
                                                <TableCell> 
                                                    <Button 
                                                        variant="contained"
                                                        endIcon={ <PlaylistAddCheckIcon /> }
                                                        size="small"
                                                        onClick={ () => { Seleccionar( row ); } }
                                                    >
                                                        Seleccionar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={4}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                       {
                                                            loadingSearch
                                                            ?
                                                                'Buscando ....'
                                                            :
                                                                'No se encontraron resultados'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={4}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                      return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>
                            
                            </TableContainer>

                        </Grid>           
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }>Cancelar</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
