import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Checkbox, Grid, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { usePages } from '../../../hooks/usePages';

import moment from 'moment';

import { types } from '../../../types/types';

import { PromocionesAcordadas } from '../../../actions/audiencias/audienciaActions';
import { LoadingButton } from '@mui/lab';

export const AcordarPromocionesScreen = ({ edit }) => {

    const { promocionesAcordadas } = useSelector( state => state.audiencias);

    const dispatch = useDispatch();    

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [loading, setLoading] = useState( false );

    const [alert, setAlert] = useState( {  } );

    const handleCloseAlert = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleChangeAcordar = ( { target }, Id ) => {

        const newRows = rows.map( ( element ) => {            
            
            if( element.id === Id ){
                element.acordar = target.checked;
            } 

            return element; 
        });        
       
        setRows( newRows );     
    }

    const GuardarPromocionesAcordadas = async () => {

        let arrayPromocion = [];

        rows.forEach(element => {
            
            const promocion = {
                idAudienciaPromocion : element.id,
                acordar: element.acordar,
            };       
            
            arrayPromocion.push( promocion );
           
        });       

        const params = {

            audienciaPromociones : arrayPromocion

        };

        setLoading( true );

        await PromocionesAcordadas( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    dispatch({

                        type: types.agregarPartesAudiencia,
                        payload: rows
            
                    });
            
                    setTimeout( () => {                    

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoading( false ); 

                    }, 1000); 

                } else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoading( false ); 

                    }, 1000); 

                }

            }

        });

    }

    useEffect(() => {
      
        if( promocionesAcordadas.length !== 0 ){

            setRows( promocionesAcordadas );

        }

    }, [ promocionesAcordadas ])  

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleCloseAlert }>
                        <Alert onClose={ handleCloseAlert } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Grid item md={12} xs={12} >

                <TableContainer style={{ marginTop: '10px' }}>

                    <Table>

                        <TableHead>

                            <TableRow>
                                <TableCell style={{ width:'20%', fontWeight:'bold' }}>NO. SEGUIMIENTO</TableCell>
                                <TableCell style={{ width:'20%', fontWeight:'bold' }}>FECHA</TableCell>                                
                                <TableCell style={{ width:'20%', fontWeight:'bold' }}>ESTATUS</TableCell>
                                <TableCell style={{ width:'10%', fontWeight:'bold', textAlign: 'center' }}>ACORDAR</TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                        {
                            (
                                rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                            ).map( (row) => (

                                <TableRow key={ row.id } >
                                    <TableCell sx={{ fontWeight: 'bold' }} > { row.noSeguimiento } </TableCell>
                                    <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY HH:mm a') : '' } </TableCell>
                                    <TableCell> { row.estatus } </TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center' }}>  

                                        {
                                            edit
                                            ?
                                                <Checkbox 
                                                    checked={ row.acordar }
                                                    onChange={ ( e ) => { handleChangeAcordar( e, row.id ); } }
                                                /> 
                                            :
                                            (   
                                                row.acordar
                                                ?
                                                    <CheckIcon />     
                                                :
                                                null
                                            )
                                        }

                                    </TableCell>

                                </TableRow>

                            ))
                        }

                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[ ]}
                                    colSpan={4}
                                    count={rows.length}                                        
                                    rowsPerPage={rowsPerPage}
                                    page={page}                                                                                
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                    ActionsComponent={ TablePaginationActions }
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                        return '' + from + '-' + to + ' de ' + count
                                        }
                                    }
                                />
                            </TableRow>
                        </TableFooter>

                    </Table>

                </TableContainer>

            </Grid> 

            {
                edit
                &&
                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                        <LoadingButton 
                            variant="contained" 
                            onClick={ GuardarPromocionesAcordadas }
                            startIcon={ <SaveIcon /> }
                            loading={ loading }
                            disabled={ rows.length === 0 }
                        > 
                            Guardar cambios
                        </LoadingButton>

                    </Grid>
            }
            
        </>
    )
}
