import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, FormControl, FormControlLabel, FormLabel, Grid, InputBase, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import SearchIcon from '@mui/icons-material/Search';

import moment from 'moment';

import { types } from '../../../types/types';

import { ObtenerTipoPromocion } from '../../../actions/admin/catalogs/tipopromocionActions';

import { RegistrarInformacion } from '../../../actions/recepcion/recepcionActions';

import { BuscarExpediente } from '../../../actions/recepcion/expedienteActions';
import { BuscarIrregular } from '../../../actions/recepcion/irregularActions';
import { BuscarAmparo } from '../../../actions/recepcion/amparoActions';
import { BuscarExhortoLibrado } from '../../../actions/tribunal/exhortoLibradoActions';
import { BuscarExhortoRecibido } from '../../../actions/recepcion/exhortoRecibidoActions';
import { BuscarExpedientilloSuspension } from '../../../actions/recepcion/expedientilloSuspensionActions';

export const RegistrarInformacionScreen = ({ handleNext, idRecepcion } ) => {

    const dispatch = useDispatch();

    const { promocion, recepcion } = useSelector( state => state.recepcion );
    
    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [tipoPromocion, setTipoPromocion] = useState( 0 );
    const [tipoPromocionArray, setTipoPromocionArray] = useState( [ ] );

    const [fechaReloj, setFechaReloj] = useState( DateNow );
    const [recibidoBuzon, setRecibidoBuzon] = useState( false );
    const [recibidoReloj, setRecibidoReloj] = useState( false );

    const [errors, setErrors] = useState({  });

    const [loadingNext, setLoadingNext] = useState( false );

    const [loadingExpediente, setLoadingExpediente] = useState( false );
    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [expediente, setExpediente] = useState( {} );

    const [loadingIrregular, setLoadingIrregular] = useState( false );
    const [numeroIrregular, setNumeroIrregular] = useState('');
    const [irregular, setIrregular] = useState( { } );    

    const [loadingAmparo, setLoadingAmparo] = useState( false );
    const [numeroAmparo, setNumeroAmparo] = useState('');
    const [amparo, setAmparo] = useState( { } );    

    const [loadingExhortoLibrado, setLoadingExhortoLibrado] = useState( false );
    const [numeroExhortoLibrado, setNumeroExhortoLibrado] = useState('');
    const [exhortoLibrado, setExhortoLibrado] = useState( { } );    

    const [loadingExhortoRecibido, setLoadingExhortoRecibido] = useState( false );
    const [numeroExhortoRecibido, setNumeroExhortoRecibido] = useState('');
    const [exhortoRecibido, setExhortoRecibido] = useState( { } );    

    const [loadingExpedientilloSuspension, setLoadingExpedientilloSuspension] = useState( false );
    const [numeroExpedientilloSuspension, setNumeroExpedientilloSuspension] = useState('');
    const [expedientilloSuspension, setExpedientilloSuspension] = useState( { } );    

    const [activate, setActivate] = useState( true );    

    const handleChangeRecibidoReloj = ( value ) => {

        setRecibidoReloj( value );

        const Now = moment( new Date()).format("YYYY-MM-DDTHH:mm");
        setFechaReloj( Now );
    }

    const handleChangeTipoPromocion = ( value ) => {

        setTipoPromocion( value );

        setExpediente( {} );
        setIrregular( {} );
        setExhortoLibrado( {} );
        setExhortoRecibido( {} );
        setAmparo( {} );    
        setExpedientilloSuspension( {} );
        setErrors( {} );    
        
        setActivate( true );
    }

    const validateFielsSearch = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' && tipoPromocion === 1 ){
            valid = false;             
            errores.numeroExpediente = 'Debes teclear el número de expediente';
            errores.type = 'warning';

            setLoadingExpediente( false );
        } 

        if( numeroIrregular === '' && tipoPromocion === 2 ){
            valid = false;
            
            errores.numeroIrregular = 'Debes teclear el número irregular';
            errores.type = 'warning';

            setLoadingIrregular( false );
        } 

        if( numeroExhortoLibrado === '' && tipoPromocion === 3 ){
            valid = false;
            
            errores.numeroExhortoLibrado = 'Debes teclear el número de exhorto librado';
            errores.type = 'warning';

            setLoadingExhortoLibrado( false );
        } 

        if( numeroAmparo === '' && tipoPromocion === 4 ){
            valid = false;
            
            errores.numeroAmparo = 'Debes teclear el número de amparo';
            errores.type = 'warning';

            setLoadingAmparo( false );
        } 

        if( numeroExpedientilloSuspension === '' && tipoPromocion === 6 ){
            valid = false;
            
            errores.numeroExpedientilloSuspension = 'Debes teclear el número de expedientillo de suspensión';
            errores.type = 'warning';

            setLoadingExpedientilloSuspension( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFiels = () => {

        setErrors( { } );

        let valid = true;
        let errores = {};
        
        if( tipoPromocion === 0 ){
            valid = false;
            errores.tipoPromocion = 'Debes seleccionar un tipo de prómocion';
        }
        if( fechaReloj === '' && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'Debes seleccionar la fecha de reloj';
        }   
        if( moment( new Date( fechaReloj ) ) > moment( new Date( ) ) && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'La fecha de reloj no puede ser mayor a la fecha actual';
        }       

        if( Object.keys( expediente ).length === 0 && tipoPromocion === 1 ){
            if( !expediente.success ){
                valid = false;
                errores.numeroExpediente = 'Debe buscar un expediente para poder continuar';
                errores.type = 'warning';
                setLoadingExpediente( false );
            } 
            else{
                valid = false;
                errores.numeroExpediente = 'Debe buscar un expediente para poder continuar';
                errores.type = 'warning';
                setLoadingExpediente( false );
            } 
        }
        else if(Object.keys( expediente ).length !== 0 && tipoPromocion === 1){
            if( !expediente.success ){
                valid = false;
                errores.numeroExpediente = 'Debe buscar un expediente para poder continuar';
                errores.type = 'warning';
                setLoadingExpediente( false );
            } 
        }

        if( Object.keys( irregular ).length === 0 && tipoPromocion === 2 ){
            if( !irregular.success ){
                valid = false;
                errores.numeroIrregular = 'Debe buscar un irregular para poder continuar';
                errores.type = 'warning';
                setLoadingIrregular( false );
            } 
            else{
                valid = false;
                errores.numeroIrregular = 'Debe buscar un irregular para poder continuar';
                errores.type = 'warning';
                setLoadingIrregular( false );
            } 
        }
        else if( Object.keys( irregular ).length !== 0 && tipoPromocion === 2 ){
            if( !irregular.success ){
                valid = false;
                errores.numeroIrregular = 'Debe buscar un irregular para poder continuar';
                errores.type = 'warning';
                setLoadingIrregular( false );
            } 
        }

        if( Object.keys( exhortoLibrado ).length === 0 && tipoPromocion === 3 ){
            if( !exhortoLibrado.success ){
                valid = false;
                errores.numeroExhortoLibrado = 'Debe buscar un exhorto librado para poder continuar';
                errores.type = 'warning';
                setLoadingExhortoLibrado( false );
            } 
            else{
                valid = false;
                errores.numeroExhortoLibrado = 'Debe buscar un exhorto librado para poder continuar';
                errores.type = 'warning';
                setLoadingExhortoLibrado( false );
            } 
        }
        else if( Object.keys( exhortoLibrado ).length !== 0 && tipoPromocion === 3 ){
            if( !exhortoLibrado.success ){
                valid = false;
                errores.numeroExhortoLibrado = 'Debe buscar un exhorto librado para poder continuar';
                errores.type = 'warning';
                setLoadingExhortoLibrado( false );
            } 
        }

        if( Object.keys( amparo ).length === 0 && tipoPromocion === 4 ){
            if( !amparo.success ){
                valid = false;
                errores.numeroAmparo = 'Debe buscar un amparo para poder continuar';
                errores.type = 'warning';
                setLoadingAmparo( false );
            } 
            else{
                valid = false;
                errores.numeroAmparo = 'Debe buscar un amparo para poder continuar';
                errores.type = 'warning';
                setLoadingAmparo( false );
            } 
        }
        else if( Object.keys( amparo ).length !== 0 && tipoPromocion === 4 ){
            if( !amparo.success ){
                valid = false;
                errores.numeroAmparo = 'Debe buscar un amparo para poder continuar';
                errores.type = 'warning';
                setLoadingAmparo( false );
            } 
        }

        if( Object.keys( expedientilloSuspension ).length === 0 && tipoPromocion === 6 ){
            if( !expedientilloSuspension.success ){
                valid = false;
                errores.numeroExpedientilloSuspension = 'Debe buscar un expedientillo de suspensión para poder continuar';
                errores.type = 'warning';
                setLoadingExpedientilloSuspension( false );
            } 
            else{
                valid = false;
                errores.numeroExpedientilloSuspension =  'Debe buscar un expedientillo de suspensión para poder continuar';
                errores.type = 'warning';
                setLoadingExpedientilloSuspension( false );
            } 
        }
        else if( Object.keys( expedientilloSuspension ).length !== 0 && tipoPromocion === 6 ){
            if( !expedientilloSuspension.success ){
                valid = false;
                errores.numeroExpedientilloSuspension = 'Debe buscar un expedientillo de suspensión para poder continuar';
                errores.type = 'warning';
                setLoadingExpedientilloSuspension( false );
            } 
        }
        
        setErrors( errores );        

        return valid;

    } 

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.numeroExpediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoadingExpediente( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }
                
        await BuscarExpediente( params ).then( response => {

            setLoadingExpediente( true );

            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingExpediente( false );
                        if(response.data){
                            if(response.data.success){
                                setExpediente(response.data);

                                setActivate( response?.data?.acumulado ?? false );

                            }else{
                                errorExpediente();
                                setActivate( true );
                            }
                        }
                    }, 1500);  
                    setNumeroExpediente('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingExpediente( false );

                    }, 1500);  
                    setNumeroExpediente('');
                }
            }
        });

    }    

    const errorIrregular = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroIrregular = 'No se encontró el irregular';
        errores.type = 'warning';
       
        setIrregular( { } );
        setErrors( errores );
        setNumeroIrregular('');
    }

    const handleClicBuscarIrregular = async () => {

        setLoadingIrregular( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroIrregular: numeroIrregular
        }
                
        await BuscarIrregular( params ).then( response => {

            setLoadingIrregular( true );

            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingIrregular( false );
                        if(response.data){
                            if(response.data.success){
                                setIrregular(response.data);
                                setActivate( false );
                            }else{
                                errorIrregular();
                                setActivate( true );
                            }
                            setNumeroIrregular('');
                        }
                    }, 1500);  
                    setIrregular('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingIrregular( false );

                    }, 1500);  
                    setIrregular('');
                }
            }
        });

    }

    const errorAmparo = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroAmparo = 'No se encontró el Amparo';
        errores.type = 'warning';
       
        setAmparo( { } );
        setErrors( errores );
        setNumeroAmparo('');
    }

    const handleClicBuscarAmparo = async () => {

        setLoadingAmparo( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroAmparo: numeroAmparo
        }
                
        await BuscarAmparo( params ).then( response => {

            setLoadingAmparo( true );

            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingAmparo( false );
                        if(response.data){
                            if(response.data.success){
                                setAmparo(response.data);
                                setActivate( false );
                            }else{
                                errorAmparo();
                                setActivate( true );
                            }
                            setNumeroAmparo('');
                        }
                    }, 1500);  
                    setAmparo('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingAmparo( false );

                    }, 1500);  
                    setAmparo('');
                }
            }
        });

    }

    const errorExhortoLibrado = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExhortoLibrado = 'No se encontró el exhorto librado';
        errores.type = 'warning';
       
        setExhortoLibrado( { } );
        setErrors( errores );
        setNumeroExhortoLibrado('');
    }

    const handleClicBuscarExhortoLibrado = async () => {

        setLoadingExhortoLibrado( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExhortoLibrado: numeroExhortoLibrado
        }
                
        await BuscarExhortoLibrado( params ).then( response => {

            setLoadingExhortoLibrado( true );

            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingExhortoLibrado( false );
                        if(response.data){
                            if(response.data.success){
                                setExhortoLibrado(response.data);
                                setActivate( false );
                            }else{
                                errorExhortoLibrado();
                                setActivate( true );
                            }
                            setNumeroExhortoLibrado('');
                        }
                    }, 1500);  
                    setExhortoLibrado('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingExhortoLibrado( false );

                    }, 1500);  
                    setExhortoLibrado('');
                }
            }
        });

    }

    const errorExhortoRecibido = () => {        
        setErrors( { } );
     
        let errores = {};  
       
        errores.numeroExhortoRecibido = 'No se encontró el exhorto recibido';
        errores.type = 'warning';       
       
        setErrors( errores );
    }

    const handleClicBuscarExhortoRecibido = async () => {

        setLoadingExhortoRecibido( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExhorto: numeroExhortoRecibido
        }
                
        await BuscarExhortoRecibido( params ).then( response => {

            setLoadingExhortoRecibido( true );

            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingExhortoRecibido( false );
                        if(response.data){
                            if(response.data.success){
                                setExhortoRecibido(response.data);
                                setActivate( false );
                            }
                            else{
                                errorExhortoRecibido();
                                setActivate( true );
                            }
                            setNumeroExhortoRecibido('');
                        }
                    }, 1500);  
                } 
                else {
                    setTimeout(() => {
                        setLoadingExhortoRecibido( false );
                        setExhortoRecibido('');
                    }, 1500);  
                }
            }
        });

    }

    const errorExpedientilloSuspension = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExpedientilloSuspension = 'No se encontró el expedientillo de suspensión';
        errores.type = 'warning';
       
        setExpedientilloSuspension( { } );
        setErrors( errores );
        setNumeroExpedientilloSuspension('');
    }

    const handleClicBuscarExpedientilloSuspension = async () => {

        setLoadingExpedientilloSuspension( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpedientilloSuspension: numeroExpedientilloSuspension
        }
                
        await BuscarExpedientilloSuspension( params ).then( response => {

            setLoadingExpedientilloSuspension( true );

            if( response ){
               
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingExpedientilloSuspension( false );
                        if(response.data){
                            if(response.data.success){
                                setExpedientilloSuspension(response.data);
                                setActivate( false );
                            }else{
                                errorExpedientilloSuspension();
                                setActivate( true );
                            }
                            setNumeroExpedientilloSuspension('');
                        }
                    }, 1500);  
                    setExpedientilloSuspension('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingExpedientilloSuspension( false );

                    }, 1500);  
                    setExpedientilloSuspension('');
                }
            }
        });

    }

    const Siguiente = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );

        const { idExpediente } = expediente;
        const { idIrregular } = irregular;
        const { idExhortoLibrado } = exhortoLibrado;
        const { idAmparo } = amparo;
        const { idExhortoRecibido } = exhortoRecibido;
        const { idExpedientilloSuspension } = expedientilloSuspension;

        const params = {

            idRecepcion: idRecepcion,
            idTipoPromocion: tipoPromocion,
            recibidoPorReloj: recibidoReloj,
            fechaReloj: recibidoReloj ? fechaReloj : null,
            RecibidoBuzonElectronico: recibidoBuzon,
            idExpediente: idExpediente ?? 0,
            idIrregular: idIrregular ?? 0,
            idExhortoLibrado: idExhortoLibrado ?? 0,
            idAmparo: idAmparo ?? 0,
            idExhortoRecibido: idExhortoRecibido ?? 0,
            idExpedientilloSuspension : idExpedientilloSuspension ?? 0,
        };

        await RegistrarInformacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    var { descripcion: descripcionTipoPromocion } = tipoPromocionArray.find( ( element ) => { return element.idTipoPromocion === tipoPromocion });                   

                    dispatch({
                        type: types.actualizarPromocion,
                        payload: {
                            idTipoPromocion: tipoPromocion,
                            tipoPromocion: descripcionTipoPromocion,
                            recibidoBuzon: recibidoBuzon,
                            recibidoReloj: recibidoReloj,                           
                            fechaReloj: recibidoReloj ? moment( new Date( fechaReloj ) ).format("YYYY-MM-DDTHH:mm") : '',
                            expediente: expediente,
                            irregular: irregular,
                            exhortoLibrado: exhortoLibrado,
                            amparo: amparo,
                            exhortoRecibido: exhortoRecibido,
                            expedientilloSuspension: expedientilloSuspension
                        }
                    });    
                    
                    setTimeout(() => {
                        
                        setLoadingNext( false );

                        handleNext();

                    }, 500);  

                }

            }

        });
    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoPromocion().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoPromocionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {      

        if( promocion ){
            setRecibidoBuzon( promocion.recibidoBuzon );
            setRecibidoReloj( promocion.recibidoReloj );
            setFechaReloj( promocion.fechaReloj === '' ? DateNow : promocion.fechaReloj );

            setExpediente( promocion.expediente );
            setIrregular( promocion.irregular );
            setExhortoLibrado( promocion.exhortoLibrado );
            setAmparo( promocion.amparo );
            setExhortoRecibido( promocion.exhortoRecibido );
            setExpedientilloSuspension( promocion.expedientilloSuspension );

            if( promocion?.expediente?.success || promocion?.irregular?.success || promocion?.exhortoLibrado?.success ||
                promocion?.amparo?.success || promocion?.exhortoRecibido?.success || promocion?.expedientilloSuspension?.success 
            ){
                setActivate( false );
            }
        }
      
    }, [ promocion, DateNow ])

    useEffect(() => {     
        
        if( promocion && tipoPromocionArray.length > 0 ){
            setTipoPromocion( promocion.idTipoPromocion ?? 0 );
        }       
      
    }, [ promocion, tipoPromocionArray ])

    return (
        <>           
            <Grid item md={6} xs={12} >                            
                <FormControl fullWidth>
                    <InputLabel id="select-tipoPromocion-label">Tipo de promocion</InputLabel>
                
                    <Select
                        labelId="select-tipoPromocion-label"
                        id="select-tipoPromocion"                                            
                        label="Tipo de promocion"
                        name="tipoPromocion"
                        value={ tipoPromocion }
                        onChange={ ( e ) => { handleChangeTipoPromocion( e.target.value ); } }
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                        {
                            tipoPromocionArray.map( ( element ) => (
                                <MenuItem key={ element.idTipoPromocion } value={ element.idTipoPromocion }>{ element.descripcion }</MenuItem>                        
                            ))
                        }
                    </Select>
                    {   
                        errors.tipoPromocion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoPromocion } </Alert> )
                    }
                </FormControl>
            </Grid> 

            <Grid item md={6} xs={12} >
                <FormControl component="fieldset" disabled={ recepcion?.provieneBuzonElectronico }>
                    <FormLabel component="legend">¿El trámite proviene del buzón electrónico?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoBuzon"
                        name="recibidoBuzon"                         
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === false } onChange={ () => { setRecibidoBuzon( false ); } } />} label="No" />
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === true } onChange={ () => { setRecibidoBuzon( true ); } } />} label="Si" />   
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿El trámite es recibido por reloj?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoReloj"
                        name="recibidoReloj"                           
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === false } onChange={ () => { handleChangeRecibidoReloj( false ); } } />} label="No" />
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === true } onChange={ () => { handleChangeRecibidoReloj( true ); } } />} label="Si" />                        
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >

                {
                    recibidoReloj && (
                        <>
                            <TextField                              
                                label="Fecha recibida por reloj"
                                type="datetime-local"   
                                name="fechaReloj"                             
                                value={ fechaReloj }    
                                onChange={ ( e ) => { setFechaReloj( e.target.value ); } }                                                     
                                fullWidth
                                inputProps={{
                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                }}                             
                                InputLabelProps={{
                                    shrink: true,
                                }}                 
                                                
                            />

                            {   
                                errors.fechaReloj && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaReloj } </Alert> )
                            }

                        </>
                    )
                }

            </Grid>

            {  
                tipoPromocion === 1 &&
                <>
                    <Grid item md={12} xs={12} > 
                
                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }} 
                                inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                placeholder="Buscar por número de expediente"     
                                name="expediente"
                                value={ numeroExpediente }                                      
                                onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                autoComplete="off"
                            /> 

                            <LoadingButton 
                                variant='contained' 
                                sx={{ borderRadius: 10 }} 
                                loading={ loadingExpediente }
                                onClick={ handleClicBuscarExpediente }
                                size='small'
                                disabled={ numeroExpediente === '' }
                            >
                                <SearchIcon />
                            </LoadingButton>   

                        </Paper>
                        {   
                            errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                        }

                    </Grid>

                    {
                        expediente.success === true 
                        && 
                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Expediente:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { expediente.numeroExpediente }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    {
                                        expediente.ponencia
                                        &&                                
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Ponencia:
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    {
                                        expediente.ponencia
                                        &&  
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.ponencia }
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        expediente.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                
                                    </List>                            
                            
                                </Grid> 
                        
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                        
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                        {
                                            expediente.demandado.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                  
                                    </List>
                                </Grid>    

                            </>
                    }

                    {
                        expediente?.acumulado
                        &&                            
                            <Grid item md={12} xs={12} mt={1} >
                                <Alert severity="info" color="warning">
                                    <strong>
                                        El expediente { expediente?.numeroExpediente } se encuentra acumulado 
                                        { 
                                            expediente?.numeroExpedientePrincipal !== '' 
                                            ? 
                                                ' en el expediente principal: ' + expediente?.numeroExpedientePrincipal + '.'
                                            : 
                                                '.' 
                                        }
                                    </strong>
                                </Alert>               
                            </Grid>       
                    }

                </>
            }
            
            {  
                tipoPromocion === 2 &&
                <>

                    <Grid item md={12} xs={12} > 
                        
                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                placeholder="Buscar por número de irregular"     
                                name="irregular"
                                value={ numeroIrregular }                                      
                                onChange={ ( e ) => { setNumeroIrregular( e.target.value ); } }
                                autoComplete="off"
                            />

                            <LoadingButton 
                                variant='contained' 
                                sx={{ borderRadius: 10 }} 
                                loading={ loadingIrregular }
                                onClick={ handleClicBuscarIrregular }
                                size='small'
                                disabled={ numeroIrregular === '' }
                            >
                                <SearchIcon />
                            </LoadingButton>            

                        </Paper>
                        {   
                            errors.numeroIrregular && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroIrregular  } </Alert> )
                        }

                    </Grid>  

                    {
                        irregular.success === true && (
                        <>
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Irregular:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                { irregular.numeroIrregular }
                                </Typography>
                            </Grid>

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Tipo de irregular:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { irregular.tipoIrregular }
                                </Typography>
                            </Grid> 

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Actor(es):
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <List disablePadding>                               
                                    {
                                        irregular.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                
                                </List>                            
                        
                            </Grid> 

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Demandado(s):
                                </Typography>
                            </Grid>
                    
                            <Grid item md={4} xs={6} >
                                <List disablePadding>
                                    {
                                        irregular.demandado.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                  
                                </List>
                            </Grid>    

                            <Grid item md={2} xs={6}>
                                {
                                    irregular.ponencia
                                    &&                                
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Ponencia:
                                    </Typography>
                                }
                            </Grid>

                            <Grid item md={4} xs={6} >
                                {
                                    irregular.ponencia
                                    &&  
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { irregular.ponencia }
                                    </Typography>
                                }
                            </Grid>

                        </>

                    )}

                </>
            }

            {  
                tipoPromocion === 3 &&
                <>

                    <Grid item md={12} xs={12} > 
                        
                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                placeholder="Buscar por número de exhorto librado"     
                                name="exhortoLibrado"
                                value={ numeroExhortoLibrado }                                      
                                onChange={ ( e ) => { setNumeroExhortoLibrado( e.target.value ); } }
                                autoComplete="off"
                            />

                            <LoadingButton 
                                variant='contained' 
                                sx={{ borderRadius: 10 }} 
                                loading={ loadingExhortoLibrado }
                                onClick={ handleClicBuscarExhortoLibrado }
                                size='small'
                                disabled={ numeroExhortoLibrado === '' }
                            >
                                <SearchIcon />
                            </LoadingButton>            

                        </Paper>
                        {   
                            errors.numeroExhortoLibrado && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExhortoLibrado  } </Alert> )
                        }

                    </Grid>  

                    {
                        exhortoLibrado.success === true && (
                        <>
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                Exhorto librado:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { exhortoLibrado.numeroExhortoLibrado }
                                </Typography>
                            </Grid>

                            <Grid item md={2} xs={6}>
                                { 
                                    exhortoLibrado.distritoJudicial 
                                    ?
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Distrito judicial:
                                    </Typography>
                                    :
                                    null
                                }                                
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { exhortoLibrado.distritoJudicial }
                                </Typography>
                            </Grid>  

                            <Grid item md={2} xs={6}>
                                {
                                    exhortoLibrado.ponencia
                                    &&                                
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Ponencia:
                                    </Typography>
                                }
                            </Grid>

                            <Grid item md={4} xs={6} >
                                {
                                    exhortoLibrado.ponencia
                                    &&  
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { exhortoLibrado.ponencia }
                                    </Typography>
                                }
                            </Grid>

                        </>

                    )}

                </>
            }

            {  
                tipoPromocion === 4 &&
                <>

                    <Grid item md={12} xs={12} > 
                        
                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                placeholder="Buscar por número de amparo"     
                                name="amparo"
                                value={ numeroAmparo }                                      
                                onChange={ ( e ) => { setNumeroAmparo( e.target.value ); } }
                                autoComplete="off"
                            />

                            <LoadingButton 
                                variant='contained' 
                                sx={{ borderRadius: 10 }} 
                                loading={ loadingAmparo }
                                onClick={ handleClicBuscarAmparo }
                                size='small'
                                disabled={ numeroAmparo === '' }
                            >
                                <SearchIcon />
                            </LoadingButton>            

                        </Paper>
                        {   
                            errors.numeroAmparo && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroAmparo  } </Alert> )
                        }

                    </Grid>  

                    {
                        amparo.success === true && (
                        <>
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                Amparo:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                { amparo.numeroAmparo }
                                </Typography>
                            </Grid>

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Tipo de amparo:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { amparo.tipoAmparo }
                                </Typography>
                            </Grid> 

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Partes(es):
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <List disablePadding>                               
                                    {
                                        amparo.partes.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                
                                </List>                            
                        
                            </Grid> 

                        </>

                    )}

                </>
            }

            {  
                tipoPromocion === 5 &&
                <>
                    <Grid item md={12} xs={12} > 
                        
                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                placeholder="Buscar por número de exhorto"     
                                name="exhorto"
                                value={ numeroExhortoRecibido }                                      
                                onChange={ ( e ) => { setNumeroExhortoRecibido( e.target.value ); } }
                                autoComplete="off"
                            />

                            <LoadingButton 
                                variant='contained' 
                                sx={{ borderRadius: 10 }} 
                                loading={ loadingExhortoRecibido }
                                onClick={ handleClicBuscarExhortoRecibido }
                                size='small'
                                disabled={ numeroExhortoRecibido === '' }
                            >
                                <SearchIcon />
                            </LoadingButton>            

                        </Paper>
                        {   
                            errors.numeroExhortoRecibido && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExhortoRecibido } </Alert> )
                        }

                    </Grid>  

                    {
                        exhortoRecibido.success === true && (
                        <>
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                Exhorto:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                { exhortoRecibido.numeroExhorto }
                                </Typography>
                            </Grid>

                            <Grid item md={2} xs={6}>                                                             
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Tipo de juicio:
                                </Typography>                                
                            </Grid>

                            <Grid item md={4} xs={6} >                               
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { exhortoRecibido.tipoJuicio }
                                </Typography>                                
                            </Grid>                            

                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Actor(es):
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <List disablePadding>                               
                                {
                                    exhortoRecibido.actor.map((elem, i)=>(
                                        <ListItem key={ i } disablePadding> 
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                { elem }
                                            </Typography>
                                        </ListItem>                    
                                    ))
                                }                                
                                </List>                            
                        
                            </Grid> 
                    
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                    Demandado(s):
                                </Typography>
                            </Grid>
                    
                            <Grid item md={4} xs={6} >
                                <List disablePadding>
                                    {
                                        exhortoRecibido.demandado.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                  
                                </List>
                            </Grid>    

                            <Grid item md={2} xs={6}>                                                             
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Autoridad del exhorto:
                                </Typography>                                
                            </Grid>

                            <Grid item md={4} xs={6} >                               
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { exhortoRecibido.autoridadExhorto }
                                </Typography>                                
                            </Grid>                             
                              
                            <Grid item md={2} xs={6}>    
                                {
                                    exhortoRecibido.ponencia
                                    &&                                                     
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Ponencia:
                                    </Typography>   
                                }                             
                            </Grid>                            

                            <Grid item md={4} xs={6} >
                                {
                                    exhortoRecibido.ponencia
                                    &&       
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { exhortoRecibido.ponencia }
                                    </Typography>
                                }
                            </Grid> 

                        </>

                    )}

                </>
            }

            {  
                tipoPromocion === 6 &&
                <>
                    <Grid item md={12} xs={12} > 
                        
                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                            <InputBase 
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                placeholder="Buscar por número de expedientillo de suspensión"     
                                name="exhorto"
                                value={ numeroExpedientilloSuspension }                                      
                                onChange={ ( e ) => { setNumeroExpedientilloSuspension( e.target.value ); } }
                                autoComplete="off"
                            />

                            <LoadingButton 
                                variant='contained' 
                                sx={{ borderRadius: 10 }} 
                                loading={ loadingExpedientilloSuspension }
                                onClick={ handleClicBuscarExpedientilloSuspension }
                                size='small'
                                disabled={ numeroExpedientilloSuspension === '' }
                            >
                                <SearchIcon />
                            </LoadingButton>            

                        </Paper>
                        {   
                            errors.numeroExpedientilloSuspension && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpedientilloSuspension } </Alert> )
                        }

                    </Grid>  

                    {
                        expedientilloSuspension.success === true && (
                        <>
                            <Grid item md={2} xs={6}>
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Número de expedientillo de suspensión:
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={6} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { expedientilloSuspension.numeroExpedientillo }
                                </Typography>
                            </Grid>

                            <Grid item md={2} xs={6}>                                                             
                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Número de amparo:
                                </Typography>                                
                            </Grid>

                            <Grid item md={4} xs={6} >                               
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { expedientilloSuspension.numeroAmparo }
                                </Typography>                                
                            </Grid> 
                        </>
                    )}

                </>
            }

            <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>  
              
                <LoadingButton 
                    variant="contained" 
                    onClick={ Siguiente }
                    endIcon={ <ArrowForwardIcon /> }
                    loading={ loadingNext }
                    disabled={ activate }
                > 
                    Siguiente 
                </LoadingButton>

            </Grid>

        </>
    );
}
