import { types } from "../types/types";

const initialState = {
    recepcion: {
        id: 0,
        presentante: '',    
        fechaRecepcion: '',
        acuse: '',
        acuseFirmado: '',
        folioBuzonElectronico: '',
        provieneBuzonElectronico: false,
    },    
    demandaInicial: {
        idTipoJuicio: 0,
        tipoJuicio: '',
        recibidoBuzon: false,
        recibidoReloj: false,
        asignacionDirecta: false,
        motivoAsignacion: '',
        idPonencia: 0,
        ponencia: '',
        numeroExpediente: '',
        fechaReloj: ''
    },    
    amparo: {
        idTipoAmparo: 0,
        idAutoridadAmparo: 0,
        recibidoBuzon: false,
        recibidoReloj: false,                                  
        fechaReloj: '',
        numeroAmparo: '',
        expediente: {},
        asignacionDirecta: false,
        motivoAsignacion: '',
        idPonencia: 0,
        ponencia: '',
        dirigidoExpediente : false
    },
    exhortoRecibido:{
        numeroExhortoOrigen: '',
        idAutoridadExhorto: 0,
        autoridadExhorto: '',
        recibidoBuzon: false,
        recibidoReloj: false,
        fechaReloj: '',
        asignacionDirecta: false,
        motivoAsignacion: '',
        idPonencia: 0,
        ponencia: '',
        numeroExhorto : '',
        idTipoJuicio: 0,
        tipoJuicio: ''
    },
    irregular: {
        numeroIrregular : '',
        ponencia: '',
        idTipoIrregular: 0,
    },
    promocion:{
        idTipoPromocion: 0,
        tipoPromocion: '',
        recibidoBuzon: false,
        recibidoReloj: false,
        fechaReloj: '',
        expediente: {},
        irregular: {},
        exhortoLibrado: {},
        amparo: {},
        exhortoRecibido: {}
    },
    expedientilloSuspension:{
        idExpedientilloSuspension: 0,
        numeroExpedientillo: '',
        numeroAmparo: '',        
    },
    anexos: [],
    prestaciones: [],
    partes: [],    
}

export const recepcionReducer = ( state = initialState, action) => {

    switch (action.type) {
        case types.actualizarRecepcion:
            return {
                ...state,
                recepcion: action.payload,
            }  
        case types.actualizarDemandaInicial:
            return {
                ...state,
                demandaInicial: action.payload,
            }   
        case types.actualizarAmparo:
            return {
                ...state,
                amparo: action.payload,
            }   
        case types.actualizarIrregular:
            return {
                ...state,
                irregular: action.payload,
            } 
        case types.actualizarExhortoRecibido:
            return {
                ...state,
                exhortoRecibido: action.payload,
            } 
        case types.actualizarPromocion:
            return {
                ...state,
                promocion: action.payload,
            } 
        case types.agregarPartes:
            return {
                ...state,
                partes: action.payload
            }     
        case types.agregarAnexos:
            return {
                ...state,
                anexos: action.payload
            }     
        case types.agregarPrestaciones:
            return {
                ...state,
                prestaciones: action.payload
            }   
        case types.eliminarRecepcion:
            return { ...initialState }      
        
        case types.eliminarParte:
            return { 
                ...state,
                partes: state.partes.filter( element => element.id !== action.payload.id )                    
            }      
        case types.eliminarPrestacion:
            return { 
                ...state,
                prestaciones: state.prestaciones.filter( element => element.idTipoPrestacion !== action.payload.id )                    
            }      
        case types.eliminarAnexo:
            return { 
                ...state,
                anexos: state.anexos.filter( element => element.id !== action.payload.id )                    
            }          
        default:
            return state;
    }
}