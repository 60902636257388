import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SourceIcon from '@mui/icons-material/Source';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import moment from 'moment';
import 'moment/locale/es-mx';

import AppBarMenu from '../ui/AppBarMenu';

import { ModalDetalleNotificacion } from '../modals/ModalDetalleNotificacion';
import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';
import { ModalDetalleAmparo } from '../modals/ModalDetalleAmparo';
import { ModalDetalleIrregular } from '../modals/ModalDetalleIrregular';
import { ModalDetalleExhortoRecibido } from '../modals/ModalDetalleExhortoRecibido';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ObtenerDatos } from '../../actions/librosElectronicos/libroActuariosActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';
import { ObtenerTiposConsecutivos } from '../../actions/admin/catalogs/tipoConsecutivoActions';

export const LibroActuarioScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );

    const [rows, setRows] = useState( [ ] );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 10 );

    const [notificacion, setNotificacion] = useState( 0 );
    const [openDetalleNotificacion, setOpenDetalleNotificacion] = useState( false );

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [openModal, setOpenModal] = useState( false );
    const [openDetalleAmparo, setOpenDetalleAmparo] = useState( false );
    const [openDetalleIrregular, setOpenDetalleIrregular] = useState( false );
    const [openDetalleExhortoRecibido, setOpenDetalleExhortoRecibido] = useState( false );

    const [expediente, setExpediente] = useState( 0 );
    const [amparo, setAmparo] = useState( 0 );
    const [irregular, setIrregular] = useState( 0 );
    const [exhortoRecibido, setExhortoRecibido] = useState( 0 );

    const [numeroReferencia, setnumeroReferencia] = useState( '' );    
    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [filtroAplicado, setFiltroAplicado] = useState( false );  

    const [tipoConsecutivo, setTipoConsecutivo] = useState( 0 );
    const [tipoConsecutivoArray, setTipoConsecutivoArray] = useState( [] );

    const handleMostrarInformacion = ( tipoTramite, id ) => {

        if( tipoTramite === 'Expediente' ){
            setExpediente( id );
            setOpenModal( true );
        }
        else if( tipoTramite === 'Amparo' ){
            setAmparo( id );
            setOpenDetalleAmparo( true );
        }
        else if( tipoTramite === 'Irregular' ){
            setIrregular( id );
            setOpenDetalleIrregular( true );
        }
        else if( tipoTramite === 'Exhorto recibido' ){
            setExhortoRecibido( id );
            setOpenDetalleExhortoRecibido( true );
        }

    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false );         
              
        setnumeroReferencia( '' );
        setTipoConsecutivo( 0 );
        setAutoridad( 0 );

        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();
    }

    const handleBuscar = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );       
        setRecordsCount( 0 );         
        resetPage();
        
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposConsecutivos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoConsecutivoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    useEffect(() => {
      
        async function Obtener(){
            
            setLoading( true );
            setRows( [ ] );

            await ObtenerDatos( { page, rowsPerPage } ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {

                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( !filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado ])    

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setRows( [ ] );

            await ObtenerDatos( { page, rowsPerPage, idAutoridad: autoridad, idTipoConsecutivo: tipoConsecutivo, numeroReferencia } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );
                                setLoading( false );
                                setLoadingSearch( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, autoridad, tipoConsecutivo, numeroReferencia ])    

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '7em' }}>  
                
                <Container maxWidth="xl" component={ Paper }>
                    
                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >

                        <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
                            
                            <Grid item md={12} xs={12} >
                            
                                <Typography variant="h4" >
                                    Libro de actuarios
                                </Typography>

                            </Grid>

                            {     
                                ( administrador || coordinador )
                                && 
                                <Grid item md={4} xs={12}> 
                                                    
                                    <FormControl fullWidth>
                                        <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                    
                                            <Select
                                                labelId="select-autoridad-label"
                                                id="select-autoridad"                                            
                                                label="Autoridad"
                                                name="autoridad"
                                                value={ autoridad }                                
                                                onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                disabled={ filtroAplicado }  
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                {
                                                    coordinador
                                                    ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    :
                                                        autoridadArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                    ))
                                                }                          
                                            </Select>

                                        </FormControl>
                                                                
                                </Grid>
                            }   

                            <Grid item md={4} sm={6} xs={12}> 
                                                
                                <FormControl fullWidth>
                                    <InputLabel id="select-tipoConsecutivo-label">Tipo de consecutivo</InputLabel>
                                
                                    <Select
                                        labelId="select-tipoConsecutivo-label"
                                        id="select-tipoConsecutivo"                                            
                                        label="Tipo de consecutivo"
                                        name="tipoConsecutivo"
                                        value={ tipoConsecutivo }                                
                                        onChange={ ( e ) => setTipoConsecutivo( e.target.value ) }
                                        disabled={ filtroAplicado }  
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                        {
                                            tipoConsecutivoArray
                                            .filter( ( elem ) => { 
                                                return elem.idTipoConsecutivo === 1 || elem.idTipoConsecutivo === 2 || elem.idTipoConsecutivo === 7 || elem.idTipoConsecutivo === 8; 
                                            })
                                            .map( ( elem ) => (
                                                <MenuItem key={ elem.idTipoConsecutivo } value={ elem.idTipoConsecutivo }> { elem.descripcion } </MenuItem>    
                                            ))
                                        }  
                                                                            
                                    </Select>

                                </FormControl>
                            
                            </Grid>  

                            <Grid item md={3} xs={12} >
                                <TextField
                                    fullWidth
                                    label="Número de referencia"
                                    name="numeroReferencia"
                                    variant="outlined"
                                    placeholder='0/0000'   
                                    autoComplete='off'           
                                    InputLabelProps={{
                                        shrink: true
                                    }}                               
                                    value={ numeroReferencia }
                                    onChange={ ( e ) => { setnumeroReferencia( e.target.value ); } }    
                                    disabled={ filtroAplicado }                            
                                />
                            </Grid>

                            <Grid item md={2} xs={12} >

                                <LoadingButton 
                                    type="button" 
                                    fullWidth 
                                    variant="contained"
                                    startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                    onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                    loading={ loadingSearch }
                                    disabled={ 
                                        ( administrador || coordinador )
                                        ? 
                                            autoridad === 0 
                                        :
                                            tipoConsecutivo === 0 ||
                                            numeroReferencia === ''
                                    }
                                > 
                                    { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                </LoadingButton> 

                            </Grid>  

                        </Grid>

                    </Toolbar>

                    <TableContainer>

                        <Table>

                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'8%', fontWeight: 'bold' }}> FECHA DE RECIBIDO </TableCell>
                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> REFERENCIA </TableCell>
                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> FECHA AUTO A NOTIFICAR </TableCell>
                                    <TableCell style={{ width:'15%', fontWeight: 'bold' }}> TIPO DE NOTIFICACIÓN </TableCell>
                                    <TableCell style={{ width:'15%', fontWeight: 'bold' }}> NOTIFICAR A </TableCell>
                                    <TableCell style={{ width:'20%', fontWeight: 'bold' }}> LUGAR </TableCell>
                                    <TableCell style={{ width:'10%', fontWeight: 'bold' }}> FECHA DE LA DILIGENCIA </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row ) => (

                                        <TableRow key={ row.idLibroAactuarios } >

                                            <TableCell> { row?.fechaRecibido ? moment( row?.fechaRecibido ).format('DD-MM-YY HH:mm a') : '' } </TableCell>

                                            <TableCell>                              

                                                {
                                                    row?.referencia
                                                    &&                                                            
                                                    <Button
                                                        variant='contained'
                                                        color='inherit'
                                                        startIcon={ <SourceIcon /> }
                                                        size='small'
                                                        onClick={ () => { handleMostrarInformacion( row.tipoTramite, row.idReferencia ); } }
                                                    >
                                                        { row.referencia ?? '' }  
                                                    </Button>
                                                }

                                            </TableCell>

                                            <TableCell> { row?.fechaAutoNotificar ? moment( row?.fechaAutoNotificar ).format('DD-MM-YY HH:mm a') : '' } </TableCell>

                                            <TableCell> 
                                                
                                                { row?.tipoNotificacion } <br />

                                                {
                                                    row?.tipo
                                                    &&
                                                        <strong>({ row?.tipo })</strong>
                                                }
                                            
                                            </TableCell>
                                            
                                            <TableCell> { row?.personaDependencia?.toUpperCase() } </TableCell>

                                            <TableCell> { row?.lugar } </TableCell>
                                            
                                            <TableCell>     

                                                <Button
                                                    variant='contained'
                                                    color='inherit'
                                                    startIcon={ <SourceIcon /> }
                                                    size='small'
                                                    onClick={ () => { setNotificacion( row?.idNotificacion ); setOpenDetalleNotificacion( true ); } }
                                                >
                                                    { 
                                                        row?.seNotifico
                                                        ?
                                                            moment( row?.fechaNotificacion ).format('DD-MM-YY HH:mm a')
                                                        : 
                                                            'No hay fecha'
                                                    }
                                                </Button>
                                                
                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        null
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron registros'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }

                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell> 

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100 ]}
                                        colSpan={ 9 }
                                        count={ recordsCount }                                        
                                        rowsPerPage={rowsPerPage}
                                        page={ page }                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }
                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                                return '' + from + '-' + to + ' de ' + count
                                            }
                                        }                                
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>                

                </Container>

            </Box>

            <ModalDetalleNotificacion
                open={ openDetalleNotificacion }    
                setOpen={ setOpenDetalleNotificacion }
                idNotificacion={ notificacion }
            />

            <ModalDetalleExpediente 
                open={ openModal } 
                setOpen={ setOpenModal } 
                idExpediente={ expediente } 
            />

            <ModalDetalleAmparo 
                open={ openDetalleAmparo }    
                setOpen={ setOpenDetalleAmparo }
                idAmparo={ amparo }
            />

            <ModalDetalleIrregular
                open={ openDetalleIrregular }    
                setOpen={ setOpenDetalleIrregular }
                idIrregular={ irregular }
            />

            <ModalDetalleExhortoRecibido
                open={ openDetalleExhortoRecibido}    
                setOpen={ setOpenDetalleExhortoRecibido }
                idExhortoRecibido={ exhortoRecibido }
            />

        </>
    )
}
