import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import MapIcon from '@mui/icons-material/Map';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Editor } from '@tinymce/tinymce-react';

import AppBarMenu from '../ui/AppBarMenu';

import { ModalDetalleAcuerdo } from '../modals/ModalDetalleAcuerdo';
import { ModalDomicilioNotificar } from '../modals/ModalDomicilioNotificar';

import { ActualizarEstatusNotificacion, ActualizarNotificacion, ObtenerNotificacionDetalle } from '../../actions/notificaciones/notificacionActions';
import { ObtenerTipoNotificacion } from '../../actions/admin/catalogs/tiponotificacionActions';
import { FormaNotificacion } from '../../actions/admin/catalogs/formaNotificacionActions';
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../actions/admin/templates/templateActions';

import { types } from '../../types/types';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../helpers/ObtenerApiKeyRichText';

export const GenerarNotificacionScreen = (props) => {

    const history = useHistory();

    const dispatch = useDispatch();
    
    const { Id } = props?.match?.params;

    const editorRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const [openModalDetalleAcuerdo, setOpenModalDetalleAcuerdo] = useState(false);
    const [openModalNuevoDomicilio, setOpenModalNuevoDomicilio] = useState(false);

    const [notificacion, setNotificacion] = useState( {} );

    const [tipoNotificacion, setTipoNotificacion] = useState( 0 );
    const [tipoNotificacionArray, setTipoNotificacionArray] = useState( [ ] ) ;
    
    const [formaNotificacion, setFormaNotificacion] = useState( 0 );
    const [formaNotificacionArray, setFormaNotificacionArray] = useState( [ ] ) ;

    const [plantilla, setPlantilla] = useState( 0 );    
    const [plantillaTemp, setPlantillaTemp] = useState( 0 );    
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [richText, setRichText] = useState( '' );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [activarFinalizar, setActivarFinalizar] = useState(false);

    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [errors, setErrors] = useState( { } );
    
    const [loadingGuardar, setLoadingGuardar] = useState( false ); 

    const [alert, setAlert] = useState( { } );

    const [persona, setPersona] = useState( { } );
    const [dependencia, setDependencia] = useState( { } );
    const [domicilio, setDomicilio] = useState( { } );
    const [domicilioNotificar, setDomicilioNotificar] = useState( { } );

    const [referencia, setReferencia] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const handleOpenModalModalDetalleAcuerdo = () => {
        setOpenModalDetalleAcuerdo(true);
    }

    const handleCloseModalDetalleAcuerdo = () => {
        setOpenModalDetalleAcuerdo(false);
    }

    const handleOpenModalNuevoDomicilio = () => {       
        setOpenModalNuevoDomicilio(true);
    };

    const handleCloseModalNuevoDomicilio = () => {
        setOpenModalNuevoDomicilio(false);
    };

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        const contenido = editorRef.current.getContent();         

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        }  

        if( plantilla === 0  ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar una plantilla';
        } 

        if( tipoNotificacion === 0  ){      
            valid = false;
            errores.tipoNotificacion = 'Debes seleccionar un tipo de notificación';
        } 

        if( (tipoNotificacion === 1 || tipoNotificacion === 2) && formaNotificacion === 0  ){      
            valid = false;
            errores.formaNotificacion = 'Debes seleccionar una forma de notificación';
        } 
        
        setErrors( errores );
        return valid;
    }

    const handleGuardarNotificacion = async () => {

        console.log( domicilio );
        
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
        const contenido = editorRef.current.getContent();

        const params = {
            'idNotificacion' : Id,
            'contenido': contenido,
            'idPlantilla': plantilla,
            'idTipoNotificacion': tipoNotificacion,
            'idFormaNotificacion': formaNotificacion
        };

        setLoadingGuardar( true );

        await ActualizarNotificacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout( () => {

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 
                        setActivarFinalizar(true);

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        const { message } = response.data;

                        setAlert({
                            open: true,
                            msg: message ? message : 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }

    const Finalizar = async() => {

        setLoadingFinalizar( true );

        const params = {
            'idNotificacion' : Id,
            'idEstatus': 11,
        };

        await ActualizarEstatusNotificacion( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoading( true );

                        history.push("/Notificacion/VisualizarNotificacion/" + Id);

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    const obtenerPlantilla = async ( Id ) => {

        setPlantilla( Id );

        await ObtenerPlantillaDetalle( Id ).then( response => {

            if(response){

                if( response.status === 200){
                    
                    let contenido = '';

                    contenido = response.data.contenido                        
                            .replaceAll('[Numero_Expediente]', notificacion?.numeroExpediente ?? '' )
                            .replaceAll('[Numero_Irregular]', notificacion?.numeroIrregular ?? '' )
                            .replaceAll('[Numero_Exhorto]', notificacion?.numeroExhorto ?? '' )
                            .replaceAll('[Numero_Amparo]', notificacion?.numeroAmparo ?? '')
                            .replaceAll('[Tipo_Notificacion]', notificacion?.tipoNotificacion ?? '' )
                            .replaceAll('[Forma_Notificacion]', notificacion?.formaNotificacion ?? '' )
                            .replaceAll('[Acuerdo_Notificar]', notificacion?.contenidoAcuerdo ?? '' )
                            .replaceAll('[Persona_Notificar]', notificacion?.personaNotificar ?? '' )
                            .replaceAll('[Domicilio_Notificar]', domicilioNotificar ?? '' )       
                            .replaceAll('[Beneficiario]', notificacion?.beneficiario ?? '' ) 
                            .replaceAll('[Actuario]', notificacion?.actuario ?? '' )
                            .replaceAll('[Extinto]', notificacion?.extinto ?? '' )     
                            .replaceAll('[Fecha_acuerdo]', notificacion?.fechaAcuerdo ?? '' )
                            .replaceAll('[Dia_fecha]', notificacion?.diaAcuerdo ?? '' )
                            .replaceAll('[Mes_fecha]', notificacion?.mesAcuerdo ?? '' )
                            .replaceAll('[Anio_fecha]', notificacion?.anioAcuerdo ?? '' )
                            .replaceAll('[Valido_acuerdo]', notificacion?.validadorAcuerdo ?? '' )       
                            .replaceAll('[Actor]', notificacion?.actor ?? '' )
                            .replaceAll('[Demandado]', notificacion?.demandado ?? '' )    
                            .replaceAll('[Autoridad]', notificacion?.autoridad ?? '' )    
                            .replaceAll('[Ciudad]', notificacion?.autoridad ?? '' )   
                            .replaceAll('[Juez]', notificacion?.juez ?? '' )  
                        
                    editorRef.current.setContent( contenido );

                }
            }

        });

    }     
    
    const SeleccionarDomicilio = ( data ) => {

        setDomicilio( data );

    }
    
    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idNotificacion': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerNotificacionDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setNotificacion(response.data);
                            setTipoNotificacion(response?.data?.idTipoNotificacion);
                            setFormaNotificacion(response?.data?.idFormaNotificacion);
                            setPlantillaTemp(response?.data?.idPlantilla);
                            setRichText(response.data.contenidoNotificacion);

                            setPersona( response.data.persona ?? { } );
                            setDependencia( response.data.dependencia ?? { } );
                            setDomicilio( response.data.domicilio ?? { } );

                            if( ( response?.data?.numeroAmparo ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferencia( response?.data?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhorto ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferencia( response?.data?.numeroExhorto ?? '' );
                            }                           
                            else if( ( response?.data?.numeroIrregular ?? '') !== '' ){
                                setTipoReferencia( 'Número de irregular' );
                                setReferencia( response?.data?.numeroIrregular ?? '' );
                            } 
                            else if(( response?.data?.numeroExpediente ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferencia( response?.data?.numeroExpediente ?? '' );
                            }   

                            if(response.data.domicilio){

                                if( Object.keys( response.data.domicilio).length !== 0 ){

                                    const descripcionDomicilio = response.data.domicilio?.descripcion ?? '';
                                    const numeroDomicilio = response.data.domicilio?.numero ?? '';
                                    const codigoPostal = response.data.domicilio?.asentamiento?.codigoPostal ?? '';
                                    const asen = response.data.domicilio?.asentamiento?.descripcion ?? '';
                                    const municipio = response.data.domicilio?.asentamiento?.municipio?.descripcion ?? '';
                                    const estado = response.data.domicilio?.asentamiento?.estado?.descripcion ?? '';                       

                                    setDomicilioNotificar( descripcionDomicilio + ' #' + numeroDomicilio + ', ' + asen + ', C.P ' + codigoPostal + ', ' + municipio + ', ' + estado );
                                    
                                }
                            }

                            if(response.data.contenidoNotificacion !== ''){
                                setActivarFinalizar(true);
                            }

                            if( response.data.idEstatus !== 10 ){
                                setLoading( true );       
                            }
                            
                            dispatch({ type: types.closeLoading });

                            setAjaxLoading( true );                            

                        }, 1200);
                        

                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])    

    useEffect(() => {      
        
        async function Obtener(){

            await ObtenerTipoNotificacion().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoNotificacionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
        
        async function Obtener(){

            await FormaNotificacion().then( response => {

                if( response ){

                    if( response.data ){
                        setFormaNotificacionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {             
        
        if( tipoNotificacion === 0){  
            setPlantilla( 0 );
            setPlantillaArray( [] );       
        }        
    
        async function Obtener(){

            if( tipoNotificacion !== 0 ){            
                const params = {
                    'idTipoAcuerdo': 0,
                    'idSubTipoAcuerdo': 0,
                    'idTipoNotificacion': tipoNotificacion,
                };

                await ObtenerPlantilla( params ).then( response => {

                    if( response ){

                        if( response.data ){
                            setPlantillaArray( response.data );
                        }

                    }

                });
            }

        }     
        
        Obtener();

    }, [tipoNotificacion])

    useEffect(() => {             
       
        if( plantillaTemp !== 0 && plantillaArray.length !== 0){
            setPlantilla( plantillaTemp );
        }

    }, [ plantillaTemp, plantillaArray ])

    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            No se tienen actividades pendientes para este trámite
                                        </Typography>

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>
                                
                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}>

                                    <InformacionSeguimiento 
                                        noSeguimiento={ 'GN-' + notificacion.idNotificacion }
                                        actividad={ 'Generar notificación' }
                                        tipo={ tipoReferencia }
                                        referencia={ referencia }
                                    />

                                </Grid>


                                        { 
                                            
                                            notificacion.numeroExhorto
                                            &&
                                            <>
                                                <Grid item md={2} xs={3}>
                                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                        Número de exhorto:
                                                    </Typography>   
                                                </Grid>

                                                <Grid item md={10} xs={9} >
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { notificacion.numeroExhorto }
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        { 
                                            
                                            notificacion.numeroAmparo
                                            &&
                                            <>
                                                <Grid item md={2} xs={3}>
                                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                        Número de amparo:
                                                    </Typography>   
                                                </Grid>

                                                <Grid item md={10} xs={9} >
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { notificacion.numeroAmparo }
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        { 
                                            
                                            notificacion.numeroIrregular
                                            &&
                                            <>
                                                <Grid item md={2} xs={3}>
                                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                        Número de irregular:
                                                    </Typography>   
                                                </Grid>

                                                <Grid item md={10} xs={9} >
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { notificacion.numeroIrregular }
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        { 
                                            notificacion.numeroExpediente
                                            &&
                                            <>
                                                <Grid item md={2} xs={3}>
                                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                        Número de expediente:
                                                    </Typography>   
                                                </Grid>

                                                <Grid item md={10} xs={9} >
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { notificacion.numeroExpediente }
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }

                            
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Notificar a:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                                        { notificacion.personaNotificar }
                                    </Typography>
                                </Grid>  

                                <Grid item md={2} xs={2}>
                                    <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }} >
                                        Tipo de parte:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={9} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray'  }}>
                                    { notificacion.tipoParte }
                                    </Typography>
                                </Grid>   

                                <Grid item md={6} xs={6} sx={{ textAlign: 'center' }} >
                                    <Button variant="contained" startIcon={<PreviewIcon />} onClick={ handleOpenModalModalDetalleAcuerdo }>
                                        Ver acuerdo a notificar
                                    </Button>
                                </Grid> 

                                <Grid item md={6} xs={6}  sx={{ textAlign: 'center' }} >
                                    <Button variant="contained" startIcon={<MapIcon />} onClick={ handleOpenModalNuevoDomicilio }>
                                        Ver domicilio a notificar
                                    </Button>
                                </Grid>     

                                <Grid item md={12} xs={12} > </Grid>                  

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-tipoNotificacion-label">Tipo de notificación</InputLabel>
                                    
                                        <Select
                                            labelId="select-tipoNotificacion-label"
                                            id="select-tipoNotificacion"                                            
                                            label="Tipo de notificación"
                                            name="tipoNotificacion"
                                            value={ tipoNotificacion }
                                            onChange={ (e) => { setTipoNotificacion(e.target.value); setFormaNotificacion(0); setPlantilla(0);} }
                                        >
                                            <MenuItem value={0}>Selecciona una opción</MenuItem>     
                                            {
                                                notificacion.tipoParte === 'Demandado' && (notificacion.idTipoAcuerdo === 1 || notificacion.idTipoAcuerdo === 13 || notificacion.idTipoAcuerdo === 23 )
                                                ?
                                                    tipoNotificacionArray
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idTipoNotificacion} value={ elem.idTipoNotificacion }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                :
                                                    tipoNotificacionArray
                                                    .filter((elem)=>{return elem.idTipoNotificacion !== 1;})
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idTipoNotificacion} value={ elem.idTipoNotificacion }>{ elem.descripcion }</MenuItem>
                                                    ))   
                                            }                           
                                            
                                        </Select>

                                    </FormControl>

                                    {   
                                        errors.tipoNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoNotificacion } </Alert> )
                                    }

                                </Grid> 

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-formaNotificacion-label">Forma de notificación</InputLabel>
                                    
                                        <Select
                                            labelId="select-formaNotificacion-label"
                                            id="select-tipoNoformaNotificaciontificacion"                                            
                                            label="Forma de notificación"   
                                            name="formaNotificacion"
                                            value={ formaNotificacion }
                                            onChange={ (e) => { setFormaNotificacion(e.target.value);} }
                                            disabled ={
                                                tipoNotificacion === 0 || tipoNotificacion === 3 || tipoNotificacion === 4
                                            }
                                            
                                        >
                                            <MenuItem value={0}>Selecciona una opción</MenuItem> 
                                            {
                                                tipoNotificacion === 1
                                                ?
                                                    formaNotificacionArray
                                                    .filter( (elem)=>{return elem.idFormaNotificacion === 1;})
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idFormaNotificacion} value={ elem.idFormaNotificacion }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                :
                                                null
                                            }   
                                            {
                                                tipoNotificacion === 2
                                                ?
                                                    formaNotificacionArray
                                                    .filter((elem)=>{return elem.idFormaNotificacion === 1 || elem.idFormaNotificacion === 3;})
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idFormaNotificacion} value={ elem.idFormaNotificacion }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                :
                                                null
                                            }     
                                            {
                                                tipoNotificacion === 5
                                                ?
                                                    formaNotificacionArray
                                                    .filter((elem)=>{return elem.idFormaNotificacion === 1;})
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idFormaNotificacion} value={ elem.idFormaNotificacion }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                :
                                                null
                                            }     
                                                        
                                            
                                        </Select>

                                    </FormControl>
                                    
                                    {   
                                        errors.formaNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.formaNotificacion } </Alert> )
                                    }
                                </Grid> 

                                <Grid item md={12} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                    
                                        <Select
                                            labelId="select-plantilla-label"
                                            id="select-plantilla"                                            
                                            label="Plantilla"
                                            name="plantilla"
                                            value={ plantilla }
                                            onChange={ ( e ) => { obtenerPlantilla( e.target.value ); } }
                                            disabled={ 
                                                ( tipoNotificacion === 0 )
                                            }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                plantillaArray.map((elem)=>(
                                                    <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                            
                                    </FormControl>
                                            {   
                                                errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                            }
                                </Grid>   

                                <Grid item md={12} xs={12} >  
                                    <Editor
                                        onInit = { (evt, editor) => editorRef.current = editor }
                                        initialValue = { richText }
                                        apiKey = { ObtenerApiKeyRichText( process.env ) }
                                        init = {
                                            {
                                                height: 450,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount pagebreak'
                                                ],
                                                toolbar: 'undo redo | ' +
                                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                    'removeformat | print pagebreak |  Atajos',
                                                    setup: (editor) => {                                        
                                                        editor.ui.registry.addMenuButton('Atajos', {
                                                            icon: 'bookmark' ,
                                                            tooltip: "Atajos",
                                                            fetch: function (callback) {

                                                                var items = [];

                                                                if (notificacion.numeroExhorto !== "") {
                                                                items = 
                                                                    [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },   
                                                                        { type: 'menuitem', text: 'Hora',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Hora_Actual]</strong>'); } },        
                                                                        { type: 'menuitem', text: 'Autoridad',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.autoridad +'</strong>'); } },                                                                                   
                                                                        { type: 'menuitem', text: 'Persona a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.personaNotificar +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de parte',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.tipoParte +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.numeroExhorto +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actor +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.demandado +'</strong>'); } },                                                                                                          
                                                                        { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.contenidoAcuerdo +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Domicilio a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ domicilioNotificar +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actuario',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actuario +'</strong>'); } },            
                                                                        { type: 'menuitem', text: 'Fecha del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.fechaAcuerdo +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Validó el acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.validadorAcuerdo +'</strong>'); } },              
                                                                        { type: 'menuitem', text: 'Dia del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.diaAcuerdo +'</strong>'); } },              
                                                                        { type: 'menuitem', text: 'Mes del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.mesAcuerdo +'</strong>'); } },              
                                                                        { type: 'menuitem', text: 'Año del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.anioAcuerdo +'</strong>'); } },              
                                                                       
                                                                    ];
                                                                
                                                                }
                                                                else if (notificacion.numeroExpediente !== "" && notificacion.numeroAmparo === "") {
                                                                items = 
                                                                    [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },              
                                                                        { type: 'menuitem', text: 'Hora',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Hora_Actual]</strong>'); } },   
                                                                        { type: 'menuitem', text: 'Autoridad',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.autoridad +'</strong>'); } },                                                                                                                                                                
                                                                        { type: 'menuitem', text: 'Persona a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.personaNotificar +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de parte',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.tipoParte +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.numeroExpediente +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actor +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.demandado +'</strong>'); } },                                                                                                          
                                                                        { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.contenidoAcuerdo +'</strong>'); } },                                                                        
                                                                        { type: 'menuitem', text: 'Domicilio a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ domicilioNotificar +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actuario',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actuario +'</strong>'); } },            
                                                                        { type: 'menuitem', text: 'Fecha del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.fechaAcuerdo +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Validó el acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.validadorAcuerdo +'</strong>'); } },     
                                                                        { type: 'menuitem', text: 'Extinto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.extinto +'</strong>'); } },   
                                                                        { type: 'menuitem', text: 'Dia del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.diaAcuerdo +'</strong>'); } },              
                                                                        { type: 'menuitem', text: 'Mes del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.mesAcuerdo +'</strong>'); } },              
                                                                        { type: 'menuitem', text: 'Año del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.anioAcuerdo +'</strong>'); } },              
                                                                       
                                                                    ];
                                                                
                                                                }
                                                                else if (notificacion.numeroIrregular !== "") {
                                                                    items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                
                                                                            { type: 'menuitem', text: 'Hora',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Hora_Actual]</strong>'); } },   
                                                                            { type: 'menuitem', text: 'Autoridad',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.autoridad +'</strong>'); } },                                                                                                                                                              
                                                                            { type: 'menuitem', text: 'Persona a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.personaNotificar +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de parte',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.tipoParte +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.numeroIrregular +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actor +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.demandado +'</strong>'); } },                                                                                                          
                                                                            { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.contenidoAcuerdo +'</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Domicilio a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ domicilioNotificar +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actuario',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actuario +'</strong>'); } },            
                                                                            { type: 'menuitem', text: 'Fecha del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.fechaAcuerdo +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Validó el acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.validadorAcuerdo +'</strong>'); } },     
                                                                            { type: 'menuitem', text: 'Dia del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.diaAcuerdo +'</strong>'); } },              
                                                                            { type: 'menuitem', text: 'Mes del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.mesAcuerdo +'</strong>'); } },              
                                                                            { type: 'menuitem', text: 'Año del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.anioAcuerdo +'</strong>'); } },              
                                                                       
                                                                        ];
                                                                    
                                                                }
                                                                else if (notificacion.numeroAmparo !== "" && notificacion.numeroExpediente !== "") {
                                                                    items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },    
                                                                            { type: 'menuitem', text: 'Hora',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Hora_Actual]</strong>'); } }, 
                                                                            { type: 'menuitem', text: 'Autoridad',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.autoridad +'</strong>'); } },                                                                                                                                                                            
                                                                            { type: 'menuitem', text: 'Persona a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.personaNotificar +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de parte',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.tipoParte +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.numeroAmparo +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.numeroExpediente +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.contenidoAcuerdo +'</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Domicilio a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ domicilioNotificar +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actuario',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actuario +'</strong>'); } },            
                                                                            { type: 'menuitem', text: 'Fecha del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.fechaAcuerdo +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Validó el acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.validadorAcuerdo +'</strong>'); } },     
                                                                            { type: 'menuitem', text: 'Dia del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.diaAcuerdo +'</strong>'); } },              
                                                                            { type: 'menuitem', text: 'Mes del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.mesAcuerdo +'</strong>'); } },              
                                                                            { type: 'menuitem', text: 'Año del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.anioAcuerdo +'</strong>'); } },              
                                                                       
                                                                        ];
                                                                    
                                                                }

                                                                else if (notificacion.numeroAmparo !== "" && notificacion.numeroExpediente === "") {
                                                                    items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                               
                                                                            { type: 'menuitem', text: 'Hora',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Hora_Actual]</strong>'); } },  
                                                                            { type: 'menuitem', text: 'Autoridad',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.autoridad +'</strong>'); } },                                                                                                                                
                                                                            { type: 'menuitem', text: 'Persona a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.personaNotificar +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de parte',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.tipoParte +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.numeroAmparo +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Acuerdo a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.contenidoAcuerdo +'</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Domicilio a notificar',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ domicilioNotificar +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actuario',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.actuario +'</strong>'); } },            
                                                                            { type: 'menuitem', text: 'Fecha del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.fechaAcuerdo +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Validó el acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.validadorAcuerdo +'</strong>'); } },     
                                                                            { type: 'menuitem', text: 'Dia del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.diaAcuerdo +'</strong>'); } },              
                                                                            { type: 'menuitem', text: 'Mes del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.mesAcuerdo +'</strong>'); } },              
                                                                            { type: 'menuitem', text: 'Año del acuerdo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ notificacion.anioAcuerdo +'</strong>'); } },              
                                                                       
                                                                        ];
                                                                    
                                                                }
                                                                callback(items);
                                                            }
                                                        });
                                                    },                                                 
                                                    content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }',                                                     
                                            }
                                        }  
                                    />
                                </Grid> 
                                        {   
                                            errors.contenido && ( 
                                                <Grid item md={12} xs={12}>
                                                    <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                                </Grid>
                                            )
                                        }   

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ handleGuardarNotificacion } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                        > Guardar notificación</LoadingButton>
                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                    <Button 
                                        variant="contained" 
                                        onClick={ handleOpenConfirmacion } 
                                        endIcon={ <CheckCircleIcon /> } 
                                        disabled ={ !activarFinalizar || domicilio?.idDomicilio === 0 }
                                    > 
                                        Finalizar 
                                    </Button>
                                </Grid>    

                            </Grid>

                        </Container>
                    
                    )

                }

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar el registro de la notificación?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained' 
                            loading={ loadingFinalizar }
                        > 
                            Aceptar 
                        </LoadingButton>

                    </DialogActions>

                </Dialog>

            </Box>

            <ModalDomicilioNotificar 
                openModal={ openModalNuevoDomicilio } 
                handleCloseModal={ handleCloseModalNuevoDomicilio } 
                idNotificacion={ notificacion.idNotificacion }
                persona={ persona }
                dependencia={ dependencia }
                domicilio={ domicilio }
                SeleccionarDomicilio={ SeleccionarDomicilio }
            />

            <ModalDetalleAcuerdo 
                openModal={ openModalDetalleAcuerdo } 
                handleCloseModal={ handleCloseModalDetalleAcuerdo } 
                notificacion={ notificacion }
            />

        </>
    )
}
