import React, { useEffect, useState }  from 'react';

import { useDispatch } from 'react-redux';

import { Grid, Card, CardContent, Divider, Button, TextField, CardHeader, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Autocomplete, Checkbox, Typography, InputAdornment, IconButton, OutlinedInput, Alert, Snackbar } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SaveIcon from '@mui/icons-material/Save';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import '../../../css/index.css';

import { ObtenerPuestos } from '../../../actions/admin/catalogs/puestoActions';
import { ObtenerPonencias } from '../../../actions/admin/catalogs/ponenciaActions';
import { ObtenerTipoUsuarios } from '../../../actions/admin/catalogs/tipoUsuarioActions';

import { ActualizarUsuario } from '../../../actions/admin/users/usuarioAction';

import { types } from '../../../types/types';
import { ObtenerAutoridades } from '../../../actions/admin/catalogs/autoridadActions';

export const UserEditDetailScreen = ({ idUsuario, usuario }) => {

    const dispatch = useDispatch();

    const [alert, setAlert] = useState({  });
    const [errors, setErrors] = useState({ });

    const [correoElectronico, setCorreoElectronico] = useState('');
    
    const [tipoUsuario, setTipoUsuario] = useState(0);
    const [tiposUsuariosArray, setTiposUsuariosArray] = useState( [] );

    const [puesto, setPuesto] = useState(0);
    const [puestosArray, setPuestosArray] = useState( [] );

    const [autoridad, setAutoridad] = useState(0);
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [ponencia, setPonencia] = useState(0);
    const [ponenciasArray, setPonenciasArray] = useState( [] );

    const [idFirma, setIdFirma] = useState(0);
    const [contrasenaFirma, setContrasenaFirma] = useState( '' );
    const [viewContrasenaFirma, setViewContrasenaFirma] = useState( false );

    const [contrasena, setContrasena] = useState( '' );
    const [viewContrasena, setViewContrasena] = useState( false );

    const [confirmarContrasena, setConfirmarContrasena] = useState( '' );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const [checked, setChecked] = useState(false);

    const [checkedRestablecer, setCheckedRestablecer] = useState(false);

    const [roles, setRoles] = useState( [ ] );
    const [rolesSelected, setRolesSelected] = useState( [ ] );

    const [notificadores, setNotificadores] = useState( [ ] );
    const [notificadoresSelected, setNotificadoresSelected] = useState( [ ] );

    const [usuarioPoderenLinea, setUsuarioPoderenLinea] = useState( 0 );

    const toggleChecked = ( e ) => {
        setChecked(e.target.checked);
    };  

    const toggleCheckedRestablecer = ( e ) => {
        setCheckedRestablecer(e.target.checked);
    };  

    const handleChangeRoles = ( items ) => {    
        setRolesSelected( items );
    }

    const handleChangeNotificadores = ( items ) => {    
        setNotificadoresSelected( items );
    }

    const handleChangeAutoridad = ( e ) => {    
        setAutoridad( e.target.value );
        setPonencia( 0 );
    }

    const GuardarInformacionUsuario = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }
      
        dispatch( { type: types.openLoading } );

        const data = {
            idUsuario: idUsuario,
            correoElectronico : correoElectronico,
            idTipoUsuario : tipoUsuario,
            idPuesto : puesto,
            idAutoridad: autoridad,
            idPonencia : ponencia,
            activo : checked,
            restablecerContrasena : checkedRestablecer,
            idFirmaElectronica : idFirma === '' ? 0 : idFirma,
            contrasenaFirmaElectronica : contrasenaFirma,
            contrasena : contrasena,
            idUsuarioExpedienteVirtual: usuarioPoderenLinea,
            roles : rolesSelected.map(({ id }) => id ),
            notificadores : notificadoresSelected.map(({ id }) => id ),
        }

        await ActualizarUsuario( data ).then(response => {

            if( response ){
                if( response.status === 200){

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Se guardó la configuración correctamente',
                            type: 'success'
                        });

                        dispatch( { type: types.closeLoading } );  
                        
                    }, 1200);   

                   
                }
                else{

                    setTimeout(() => {

                        const { message } = response.data;

                        setAlert({
                            open: true,
                            msg: message ? message : 'Ocurrió un error, contacte con el administrador del sistema',
                            type: 'error'
                        });

                        dispatch( { type: types.closeLoading } );  
                        
                    }, 1200);   
                    
                }
            }

        });
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleChangeCorreoElectronico = ( e ) => {
        setCorreoElectronico( e.target.value );
    }

    const handleChangeTipoUsuario = ( e ) => {        
        setTipoUsuario( e.target.value );
        setAutoridad( 0 );
        setPonencia( 0 );
        setPuesto( 0 );
    }

    const handleChangePuesto = ( e ) => {
        setPuesto( e.target.value );
    }

    const handleChangePonencia = ( e ) => {
        setPonencia( e.target.value );
    }

    const handleIdFirma = ( e ) => {
        setIdFirma( e.target.value );
    }

    const handleContrasenaFirma = ( e ) => {
        setContrasenaFirma( e.target.value );
    }
    
    const handleChangeViewContrasenaFirma = () => {
        setViewContrasenaFirma( ( prev ) => !prev );
    }

    const handleContrasena = ( e ) => {
        setContrasena( e.target.value );
    }
    
    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const handleConfirmarContrasena = ( e ) => {
        setConfirmarContrasena( e.target.value );
    }
    
    const handleChangeViewConfirmarContrasena = () => {
        setViewConfirmarContrasena( ( prev ) => !prev );
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( correoElectronico === '' ){
            valid = false;
            errores.correoElectronico = 'Debes escribir el correo electrónico';
        } 
        if( tipoUsuario === 0 ){
            valid = false;
            errores.tipoUsuario = 'Debes seleccionar el tipo de usuario';
        }      
        if( tipoUsuario === 1 && puesto === 0 ){
            valid = false;
            errores.puesto = 'Debes seleccionar el puesto';
        }
        
        if( contrasena === '' ){
            valid = false;
            errores.contrasena = 'Debes escribir la contraseña';
        }
        else if( confirmarContrasena === '' ){
            valid = false;
            errores.contrasena = 'Debes confirmar la contraseña';
        }
        else if( contrasena !== confirmarContrasena ){
            valid = false;
            errores.contrasena = 'Las contraseñas no coinciden';
        }

        if( tipoUsuario === 1 && autoridad === 0){
            valid = false;
            errores.autoridad = 'Debes seleccionar la autoridad';
        }
       
        if( (idFirma !== 0 && idFirma !== '') && contrasenaFirma === ''  ){
            valid = false;
            errores.contrasenaFirma = 'Debes escribir la contraseña de la firma electronica';
        }
        else if( contrasenaFirma !== '' && (idFirma === 0 || idFirma === '')  ){
            valid = false;
            errores.idFirmaElectronica = 'Debes escribir el ID de la firma electronica';
        }
        
        setErrors( errores );
        return valid;
    } 
  
    //Obtener puestos he invocando la APi
    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerTipoUsuarios().then( response => {
                if(response){
                    if( response.status === 200){
                        setTiposUsuariosArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    //Obtener puestos he invocando la APi
    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerPuestos().then( response => {
                if(response){
                    if( response.status === 200){
                        setPuestosArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    //Obtener Ponencias he invocando la APi
    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerPonencias().then( response => {
                if(response){
                    if( response.status === 200){
                        setPonenciasArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

     //Obtener Ponencias he invocando la APi
     useEffect(() => {
        
        async function Obtener(){            
            await ObtenerAutoridades().then( response => {
                if(response){
                    if( response.status === 200){
                        setAutoridadArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    //Llenar catalogos y campos con información del usuario
    useEffect(() => {

        if( Object.keys( usuario ).length !== 0 ){

            setCorreoElectronico( usuario.correoElectronico ?? '' );
            setTipoUsuario( usuario.idTipoUsuario ?? 0 );
            setPuesto( usuario.idPuesto ?? 0 );
            setAutoridad( usuario.idAutoridad ?? 0 );
            setPonencia( usuario.idPonencia ?? 0 );
            
            setChecked( usuario.activo ?? false );
            setCheckedRestablecer( usuario.restablecerContrasena ?? false );
            
            setIdFirma( usuario.idUsuarioFirmaElectronica ?? 0 );
            setContrasenaFirma( usuario.contrasenaFirmaElectronica ?? '' );
            setContrasena( usuario.contrasena ?? '' );
            setConfirmarContrasena( usuario.contrasena ?? '' );
            setUsuarioPoderenLinea( usuario?.idUsuarioExpedienteVirtual ?? 0 );

            if( usuario.roles.length !== 0){

                const optionsDefault = usuario.roles.filter( ({ selected }) => { return selected === true; } );

                setRolesSelected( optionsDefault );

                setRoles( usuario.roles );
            }

            if( usuario?.notificadores?.length !== 0){

                const optionsDefault = usuario?.notificadores?.filter( ({ selected }) => { return selected === true; } );

                setNotificadoresSelected( optionsDefault );

                setNotificadores( usuario?.notificadores ?? [] );
            }
        }

    }, [ usuario ]);    

    return (
        <Grid container spacing={3} >

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
         
            <Grid item lg={12} md={12} xs={12} >                                    
                <Card>
                    <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información de la cuenta </Typography> }/>
                    
                    <Divider />
                
                    <CardContent> 
                        <Grid container spacing={3} >
                            <Grid item md={12} xs={12} >
                                <TextField
                                    fullWidth
                                    label="Correo electrónico"
                                    name="correoElectronico"
                                    variant="outlined"
                                    required
                                    value={ correoElectronico }                                
                                    onChange={ handleChangeCorreoElectronico }
                                />

                                {   
                                    errors.correoElectronico && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.correoElectronico } </Alert> )
                                }
                            </Grid>    

                            <Grid item md={6} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-tipoUsuario-label">Tipo de Usuario</InputLabel>
                                
                                    <Select
                                        labelId="select-tipoUsuario-label"
                                        id="select-tipoUsuario"                                            
                                        label="Tipo de usuario"
                                        name="tipoUsuario"
                                        value={ tipoUsuario }                                
                                        onChange={ handleChangeTipoUsuario }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            tiposUsuariosArray.map( ( elem ) => (
                                                <MenuItem key={ elem.idTipoUsuario } value={ elem.idTipoUsuario }> { elem.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>

                                {   
                                    errors.tipoUsuario && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoUsuario } </Alert> )
                                }

                            </Grid>                                     

                            <Grid item md={6} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-puesto-label">Puesto</InputLabel>
                                
                                    <Select
                                        labelId="select-puesto-label"
                                        id="select-puesto"                                            
                                        label="Puesto"
                                        name="puesto"
                                        value={ puesto }                                
                                        onChange={ handleChangePuesto }
                                        disabled={ tipoUsuario === 2 }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            puestosArray.map( ( elem ) => (
                                                <MenuItem key={ elem.idPuesto } value={ elem.idPuesto }> { elem.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>

                                {   
                                    errors.puesto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.puesto } </Alert> )
                                }

                            </Grid>  

                            <Grid item md={12} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                
                                    <Select
                                        labelId="select-autoridad-label"
                                        id="select-autoridad"                                            
                                        label="Autoridad"
                                        name="autoridad"
                                        value={ autoridad }                                
                                        onChange={ handleChangeAutoridad}
                                        disabled={ tipoUsuario === 2 }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            autoridadArray.map( ( elem ) => (
                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>
                                {   
                                    errors.autoridad && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridad } </Alert> )
                                }                               
                            </Grid>  

                            {
                                autoridad === 1
                                &&
                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-ponencia-label">Ponencia</InputLabel>
                                        
                                            <Select
                                                labelId="select-ponencia-label"
                                                id="select-ponencia"                                            
                                                label="Ponencia"
                                                name="ponencia"
                                                value={ ponencia }                                
                                                onChange={ handleChangePonencia }
                                                disabled={ tipoUsuario === 2 || autoridad !== 1 }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    ponenciasArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idPonencia } value={ elem.idPonencia }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.ponencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.ponencia } </Alert> )
                                        }                               
                                    </Grid>  
                            }

                            {
                                puesto === 15
                                &&
                                <Grid item md={12} xs={12} >
                                    <Autocomplete                                        
                                        multiple
                                        id="select-notificadores"
                                        name="notificadores"  
                                        value={ notificadoresSelected }
                                        options={ notificadores } 
                                        onChange={ (event, value) => handleChangeNotificadores(value) }
                                        disableCloseOnSelect
                                        getOptionLabel={ (option) => option.nombreCompleto}
                                        renderOption={ (props, option, { selected }) => (
                                            <li { ...props } >
                                                <Checkbox 
                                                    icon={ <CheckBoxOutlineBlankIcon fontSize="small" /> }
                                                    checkedIcon={ <CheckBoxIcon fontSize="small" /> }
                                                    style={{ marginRight: 8 }}
                                                    checked={ selected }
                                                />
                                                { option.nombreCompleto }
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField { ...params } label="Notificador(es)" placeholder=""  />
                                        )}
                                    />
                                </Grid>  
                            }

                            <Grid item md={6} xs={6} >                            
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={checked} onChange={ toggleChecked } />}
                                        label="Activar usuario"
                                    />
                                </FormGroup>
                            </Grid>  

                            <Grid item md={6} xs={6} >                            
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={checkedRestablecer} onChange={ toggleCheckedRestablecer } />}
                                        label="Restablecer contraseña"
                                    />
                                </FormGroup>
                            </Grid>       

                            <Grid item md={6} xs={12} >
                                <TextField
                                    fullWidth
                                    label="ID Usuario de la Firma Electrónica"
                                    name="usuarioFirma"                                
                                    type="number"
                                    variant="outlined"
                                    value={ idFirma }                                
                                    onChange={ handleIdFirma }                              
                                />

                                {   
                                    errors.idFirmaElectronica && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.idFirmaElectronica } </Alert> )
                                }
                            </Grid>  

                            <Grid item md={6} xs={12} >
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Contraseña de la Firma Electrónica</InputLabel>
                                    <OutlinedInput
                                        label="Contraseña de la Firma Electrónica"
                                        name="contrasenaFirma"
                                        type={ viewContrasenaFirma ? 'text' : 'password'}
                                        value={ contrasenaFirma }                                
                                        onChange={ handleContrasenaFirma }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={ handleChangeViewContrasenaFirma } edge="end" >
                                                    {
                                                        viewContrasenaFirma 
                                                        ?
                                                        <VisibilityOffIcon />
                                                        :
                                                        <VisibilityIcon />
                                                    }                                                    
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                {   
                                    errors.contrasenaFirma && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.contrasenaFirma } </Alert> )
                                }
                            </Grid>  

                            <Grid item md={12} xs={12} > 

                                <TextField
                                    label='ID Usuario Poder en Línea (Autorizar expedientes)'
                                    variant="outlined"   
                                    type='number'   
                                    autoFocus                                        
                                    fullWidth
                                    value={ usuarioPoderenLinea }
                                    onChange={ ( e ) => { setUsuarioPoderenLinea( e.target.value ) } }
                                />

                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={12} md={12} xs={12} >                                    
                <Card>
                    <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Cambiar contraseña </Typography> }/>
                    
                    <Divider />
                
                    <CardContent>
                        <Grid container spacing={3} >
                            <Grid item md={6} xs={12} >
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Contraseña</InputLabel>
                                    <OutlinedInput
                                        label="Contraseña"
                                        name="contrasena"
                                        type={ viewContrasena ? 'text' : 'password'}
                                        value={ contrasena }                                
                                        onChange={ handleContrasena }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                    {
                                                        viewContrasena
                                                        ?
                                                        <VisibilityOffIcon />
                                                        :
                                                        <VisibilityIcon />
                                                    }                                                    
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>                                            

                            <Grid item md={6} xs={12} >
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Confirmar contraseña</InputLabel>
                                    <OutlinedInput
                                        label="Confirmar contraseña"
                                        name="confirmarContrasena"
                                        type={ viewConfirmarContrasena ? 'text' : 'password'}
                                        value={ confirmarContrasena }                                
                                        onChange={ handleConfirmarContrasena }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                    {
                                                        viewConfirmarContrasena
                                                        ?
                                                        <VisibilityOffIcon />
                                                        :
                                                        <VisibilityIcon />
                                                    }                                                    
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>  
                        </Grid>  

                        {   
                            errors.contrasena && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.contrasena } </Alert> )
                        }

                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={12} md={12} xs={12} >                                    
                <Card>
                    <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Permisos </Typography> }/>
                    
                    <Divider />
                
                    <CardContent>
                        <Grid container spacing={3} >
                            <Grid item md={12} xs={12} >
                                <Autocomplete
                                    
                                    multiple
                                    id="select-roles"
                                    name="roles"  
                                    value={ rolesSelected }
                                    options={ roles } 
                                    onChange={ (event, value) => handleChangeRoles(value) }
                                    disableCloseOnSelect
                                    getOptionLabel={ (option) => option.descripcion}
                                    renderOption={ (props, option, { selected }) => (
                                        <li { ...props } >
                                            <Checkbox 
                                                icon={ <CheckBoxOutlineBlankIcon fontSize="small" /> }
                                                checkedIcon={ <CheckBoxIcon fontSize="small" /> }
                                                style={{ marginRight: 8 }}
                                                checked={ selected }
                                            />
                                            { option.descripcion }
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField { ...params } label="Roles" placeholder=""  />
                                    )}
                                />
                            </Grid>  
                        </Grid>  
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={12} md={12} xs={12} >                    
                
                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>                   
                    <Button type="button" fullWidth variant="contained" startIcon={ <SaveIcon /> } onClick={ GuardarInformacionUsuario }> 
                        Guardar Información
                    </Button> 
                </Grid>                    

            </Grid>

        </Grid>
    )
}
