import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { ObtenerTipoFormato } from '../../../actions/admin/catalogs/tipoformatoActions';
import { ObtenerTiposAnexos } from '../../../actions/admin/catalogs/tipoAnexoActions';
import { AgregarAnexos } from '../../../actions/audiencias/audienciaActions';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { usePages } from '../../../hooks/usePages';

import { types } from '../../../types/types';

export const RegistrarAnexosScreen = ({ edit }) => {

    const dispatch = useDispatch();
    
    const { audiencia, anexos } = useSelector( state => state.audiencias);

    const [idReferencia, setReferencia] = useState( 0 );

    const [loadingInformacion, setLoadingInformacion] = useState( false );

    const [alert, setAlert] = useState( {  } );

    const [errors, setErrors] = useState({ });

    const [tipoAnexo, setTipoAnexo] = useState( 0 );
    const [tipoAnexoArray, setTipoAnexoArray] = useState( [ ] );

    const [tipoFormato, setTipoFormato] = useState( 0 );
    const [tipoFormatoArray, setTipoFormatoArray] = useState( [ ] );
    
    const [cantidad, setCantidad] = useState( 1 );
    const [numeroFojas, setNumeroFojas] = useState( 1 );
    const [observaciones, setObservaciones] = useState( '' );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();    

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleChangeCantidad = ( e ) => {

        if( e.target.value > 0 ){
            setCantidad( e.target.value );
        }

    }

    const handleChangeNumeroFojas = ( e ) => {

        if( e.target.value > 0 ){
            setNumeroFojas( e.target.value );
        }

    }

    const handleClicAgregarAnexo = () => {

        const tipoAnexoData =  tipoAnexoArray.find( ( element ) => { return element.idTipoAnexo === tipoAnexo });
        const tipoFormatoData =  tipoFormatoArray.find( ( element ) => { return element.idTipoFormato === tipoFormato });
        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

        const anexoValidate =  rows.find( ( element ) => { return element.tipoAnexo.idTipoAnexo === tipoAnexo && element.tipoFormato.idTipoFormato === tipoFormato; });

        if( !anexoValidate ){

            const data = {
                idAnexo: parseInt( id ),
                idTipoAnexo: tipoAnexo,
                tipoAnexo: tipoAnexoData,
                idTipoFormato: tipoFormato,
                tipoFormato: tipoFormatoData,
                cantidad: cantidad,
                numeroFojas: numeroFojas,
                observaciones: observaciones
            };
    
            setRows( [ ...rows, data ] );
            
            setErrors( {} ); 
            setTipoAnexo( 0 );
            setTipoFormato( 0 );
            setCantidad( 1 );
            setNumeroFojas( 1 );
            setObservaciones( '' );
            setAutocompleteValue( null );
        } 
        else {

            let errores = {};

            errores.error = 'El anexo ya se encuentra agregado';
        
            setErrors( errores );  
        }
        
    }

    const handleClicEliminarAnexo = ( Id ) => {

        handleOpenConfirmacion();

        setReferencia( Id );
        
    }

    const handleCloseAlert = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const eliminarAnexo = () => {

        var newArray = rows.filter(function( element ){ 
            return element.idAnexo !== idReferencia;
        });

        setRows( newArray );

        dispatch({

            type: types.eliminarAnexoAudiencia,
            payload: { idAnexo: idReferencia }

        });

        handleCloseConfirmacion();
    }

    const GuardarAnexos = async () => {

        let arrayAnexos = [];

        rows.forEach(element => {
            
            const anexo = {
                idTipoAnexo : element.tipoAnexo.idTipoAnexo,
                idTipoFormato : element.tipoFormato.idTipoFormato,
                cantidad: element.cantidad,
                numeroFojas: element.numeroFojas,
                observaciones: element.observaciones
            };       
            
            arrayAnexos.push( anexo );
        
        });       

        const params = {

            idAudiencia: audiencia.idAudiencia,
            anexos : arrayAnexos

        };
        
        setLoadingInformacion( true );

        await AgregarAnexos( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    dispatch({

                        type: types.agregarAnexosAudiencia,
                        payload: rows
            
                    });

                    setTimeout( () => {
                        

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingInformacion( false ); 

                    }, 1000); 
            
                } else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingInformacion( false ); 

                    }, 1000); 

                }


            }

        });
       
    }

    useEffect(() => {

        function Obtener(){          

            ObtenerTiposAnexos( ).then( response => {

                if( response.status === 200){
                    setTipoAnexoArray( response.data ); 
                }
            })  
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {

        function Obtener(){    

            ObtenerTipoFormato( ).then( response => {

                if( response.status === 200){
                    setTipoFormatoArray( response.data ); 
                }
            })

        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {

        if( anexos.length !== 0 ){

            setRows( anexos );

        }

    }, [ anexos ]) 

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleCloseAlert }>
                        <Alert onClose={ handleCloseAlert } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            {
                edit
                &&
                    <>
                        <Grid item md={4} xs={12} >                            
               
                            <FormControl fullWidth>

                                <Autocomplete                
                                    disablePortal 
                                    fullWidth              
                                    value={ autocompleteValue }   
                                    options={ tipoAnexoArray }
                                    onChange={ (event, value) => { setTipoAnexo( value?.idTipoAnexo ?? 0 ); setAutocompleteValue( value ); } }                        
                                    getOptionLabel={ (option) => option.descripcion}
                                    renderOption={ ( props, option ) => (
                                        <li { ...props } key={ option.idTipoAnexo} >                         
                                            { option.descripcion }
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Tipo de anexo" placeholder='Seleccione una opción' InputLabelProps={{ shrink: true }} />}         
                                />      

                            </FormControl>

                        </Grid> 

                        <Grid item md={4} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoFormato-label">Tipo de formato</InputLabel>
                            
                                <Select
                                    labelId="select-tipoFormato-label"
                                    id="select-tipoFormato"                                            
                                    label="Tipo de formato"
                                    name="tipoFormato"
                                    value={ tipoFormato }
                                    onChange={ ( e ) => { setTipoFormato( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        tipoFormatoArray.map( ( element ) => (
                                            <MenuItem key={ element.idTipoFormato } value={ element.idTipoFormato }>{ element.descripcion }</MenuItem>                                                        
                                        ))
                                    }                     
                                    
                                </Select>

                            </FormControl>
                        </Grid>

                        <Grid item md={2} xs={12} >
                            <TextField
                                fullWidth
                                label="Cantidad"
                                name="cantidad"   
                                variant="outlined"
                                type="number"
                                value={ cantidad }                    
                                onChange={ handleChangeCantidad }                                
                                inputProps={{
                                    min: 1
                                }}
                            />
                        </Grid>  

                        <Grid item md={2} xs={12} >
                            <TextField
                                fullWidth
                                label="Número de fojas"
                                name="fojas"   
                                variant="outlined"
                                type="number"
                                value={ numeroFojas }
                                onChange={ handleChangeNumeroFojas }     
                                inputProps={{
                                    min: 1
                                }}                                                        
                            />
                        </Grid>    

                        <Grid item md={10} xs={12} >
                            <TextField
                                fullWidth
                                label="Observaciones"
                                name="observaciones"
                                variant="outlined"        
                                multiline
                                rows={4}                                        
                                InputLabelProps={{
                                    shrink: true
                                }}                               
                                value={ observaciones }
                                onChange={ ( e ) => { setObservaciones( e.target.value ); } }                                
                            />
                        </Grid>

                        <Grid item md={2} xs={12} >
                            <Button 
                                variant="contained"                    
                                startIcon={ <AddIcon /> }
                                fullWidth
                                disabled={ tipoAnexo === 0 || tipoFormato === 0 }
                                onClick={ handleClicAgregarAnexo }
                            >
                                Agregar
                            </Button>
                        </Grid>   
                    </>
            }

            <Grid item md={12} xs={12} >

                <TableContainer style={{ marginTop: '10px' }}>

                    <Table>

                        <TableHead>

                            <TableRow>
                                <TableCell style={{ width:'20%', fontWeight:'bold' }}>TIPO DE ANEXO</TableCell>                              
                                <TableCell style={{ width:'20%', fontWeight:'bold' }}>TIPO DE FORMATO</TableCell>                              
                                <TableCell style={{ width:'10%', fontWeight:'bold' }}>CANTIDAD</TableCell>    
                                <TableCell style={{ width:'15%', fontWeight:'bold' }}>NÚMERO DE FOJAS</TableCell>   
                                <TableCell style={{ width:'25%', fontWeight:'bold' }}>OBSERVACIONES</TableCell>    
                                
                                {
                                    edit
                                    &&
                                        <TableCell style={{ width:'10%' }}> </TableCell>
                                }                          
                                
                            </TableRow>

                        </TableHead>

                        <TableBody>                    
                            {
                                (rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map( (row) => (
                            
                                    <TableRow key={ row.idAnexo }>
                                        <TableCell>{ row.tipoAnexo.descripcion }</TableCell>    
                                        <TableCell>{ row.tipoFormato.descripcion }</TableCell>    
                                        <TableCell>{ row.cantidad }</TableCell>    
                                        <TableCell>{ row.numeroFojas }</TableCell>     
                                        <TableCell>{ row.observaciones }</TableCell>                               
                                        
                                        {
                                            edit
                                            &&
                                                <TableCell> 
                                                    <IconButton color="primary" onClick={ () => { handleClicEliminarAnexo( row.idAnexo ); } }>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                        }

                                    </TableRow>
                                
                                ))
                            }

                            {
                                rows.length === 0 && 
                                (
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            align="center"
                                            style={{ fontWeight: 'bold', fontSize: 14 }}
                                        >
                                            {
                                                'No se han agregado anexo(s)'
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[ ]}
                                    colSpan={6}
                                    count={rows.length}                                        
                                    rowsPerPage={rowsPerPage}
                                    page={page}                                                                                
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                    ActionsComponent={ TablePaginationActions }
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                        return '' + from + '-' + to + ' de ' + count
                                        }
                                    }
                                />
                            </TableRow>
                        </TableFooter>

                    </Table>

                </TableContainer>

            </Grid> 

            {   
                errors.error && ( 
                    <Grid item md={12} xs={12} >

                        <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                    </Grid>
                )
            }

            {
                edit
                &&
                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                    <LoadingButton 
                        variant="contained" 
                        onClick={ GuardarAnexos }
                        startIcon={ <SaveIcon /> }
                        loading={ loadingInformacion }
                    > 
                        Guardar anexos
                    </LoadingButton>
                </Grid>
            }

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <Button onClick={ eliminarAnexo } variant='contained' > Aceptar </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
