import React from 'react';

import { Box, Grid, Card, CardContent, Avatar, Typography } from '@mui/material';

import moment from 'moment';

export const UserInfoDetailScreen = ({ nombreCompleto, puesto, imagenPerfil, curp, rfc, entidadNacimiento, fechaNacimiento, sexo, idTipoUsuario }) => {

    return (
        <Grid container spacing={3} >    
            <Grid item lg={12} md={12} xs={12} >
                <Card>
                    <CardContent>
                        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                            
                            <Avatar src={ imagenPerfil ? imagenPerfil : 'https://media.istockphoto.com/vectors/default-avatar-profile-icon-grey-photo-placeholder-vector-id846183008?k=6&m=846183008&s=170667a&w=0&h=bCKJRIR5IBFOv80Rd8HfBVj_VirFxgWuZIDJcg9OK9M=' } 
                                sx={{ height: 150, width: 150 }} 
                            />

                            <Typography color="textPrimary" gutterBottom variant="h6" sx={{ mt: 1, mb: 1 }} style={{ textAlign: 'center' }}>
                                { ( nombreCompleto ?? '' ) }
                            </Typography>

                            <Typography color="textSecondary" variant="body1" >
                                { idTipoUsuario === 2 ? 'Administrador' : puesto }
                            </Typography>

                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={12} md={12} xs={12} >
                <Card>
                    <CardContent>
                        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" >
                                        CURP:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { curp }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" >
                                        RFC:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { rfc }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" >
                                        Fecha nacimiento:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { fechaNacimiento ? moment(fechaNacimiento).format("YYYY-MM-DD") : '' }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" >
                                        Sexo:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { sexo }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" >
                                        Entidad de Nacimiento:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} >
                                    <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { entidadNacimiento }
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                    </CardContent>                
                </Card>
            </Grid> 
        </Grid>
    )
}
