import React , { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, LinearProgress, List, ListItem, Paper, Radio, RadioGroup, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';
import { BuscarIrregular } from '../../actions/recepcion/irregularActions';
import { BuscarAmparo } from '../../actions/recepcion/amparoActions';
//import { BuscarExhortoRecibido, BuscarExhortoRecibidoPonencia } from '../../actions/recepcion/exhortoRecibidoActions';
import { BuscarExhortoRecibido } from '../../actions/recepcion/exhortoRecibidoActions';
import { GuardarOficio } from '../../actions/tribunal/oficioActions';
import { ObtenerTiposOficios } from '../../actions/admin/catalogs/tipoOficioActions';

export const ModalNuevoOficio = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    //const {juzgado, tribunal} = useSelector(state => state.auth);
    const {tribunal} = useSelector(state => state.auth);

    const [loading, setLoading] = useState( false );

    const [habiliarGuardar, setHabiliarGuardar] = useState( true );

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [tipoOficio, setTipoOficio] = useState( [ ] );
    const [tipoOficioArray, setTipoOficioArray] = useState( [ ] );

    const [errors, setErrors] = useState( { } );

    const [tipoBusqueda, setTipoBusqueda] = useState( '' );

    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [numeroIrregular, setNumeroIrregular] = useState('');
    const [numeroAmparo, setNumeroAmparo] = useState('');
    const [numeroExhortoRecibido, setNumeroExhortoRecibido] = useState('');

    const [expediente, setExpediente] = useState('');
    const [irregular, setIrregular] = useState('');
    const [amparo, setAmparo] = useState('');
    const [exhortoRecibido, setExhortoRecibido] = useState('');
    const [numeroReferencia, setNumeroReferencia] = useState('');

    const GenerarOficio = async () => {      

        const params = {
            idExpediente : expediente.idExpediente,
            idIrregular : irregular.idIrregular,
            idAmparo : amparo.idAmparo,
            idExhortoRecibido : exhortoRecibido.idExhortoRecibido,
            idTipoOficio : tipoOficio,
            numeroReferencia: numeroReferencia,
        };

        setLoadingGuardar(true);
        setHabiliarGuardar( true );
        
        await GuardarOficio( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                       
                        const {idOficio} = response.data;

                        setTimeout(() => {
                            setLoadingGuardar(false); 
                            history.push("/Tribunal/Oficio/ElaborarOficio/" + idOficio);
                            setHabiliarGuardar( false );
                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {                      
                        
                        const { message } = response.data;

                        setLoadingGuardar(false);
                        setHabiliarGuardar( false );

                        let errores = {};  
                            errores.error = message;
                            errores.type = 'error';

                        setErrors( errores );

                    }, 1200);  
                }
            }
        });  
    }

    const limpiarDatos = () => {
        setErrors( { } );
        setNumeroExpediente('');
        setNumeroIrregular('');
        setNumeroAmparo('');
        setNumeroExhortoRecibido('');
        setExpediente('');
        setIrregular('');
        setAmparo('');
        setExhortoRecibido('');
    }

    const cerrarModal = () => {
        
        handleCloseModal();

        setErrors( { } );
        setTipoBusqueda('');
        setNumeroExpediente('');
        setNumeroIrregular('');
        setNumeroAmparo('');
        setNumeroExhortoRecibido('');
        setExpediente('');
        setIrregular('');
        setAmparo('');
        setExhortoRecibido('');
        
    }

    const validateFielsExpediente = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un número de expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsIrregular = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroIrregular === '' ){
            valid = false;
            errores.irregular = 'Debes teclear un número de irregular';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsAmparo = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroAmparo === '' ){
            valid = false;
            errores.amparo = 'Debes teclear un número de amparo';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsExhortoRecibido = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExhortoRecibido === '' ){
            valid = false;
            errores.amparo = 'Debes teclear un número de exhorto';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
        setHabiliarGuardar(true);
        setErrors( { } );
        
        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const errorIrregular = () => {
        setHabiliarGuardar(true);
        setErrors( { } );

        let errores = {};  
        errores.irregular = 'No se encontró el irregular';
        errores.type = 'warning';
        
        setIrregular( {} );
        setErrors( errores );

        setNumeroIrregular('');
    }

    const errorAmparo = () => {
        setHabiliarGuardar(true);
        setErrors( { } );

        let errores = {};  
        errores.amparo = 'No se encontró el amparo';
        errores.type = 'warning';
        
        setAmparo( {} );
        setErrors( errores );

        setNumeroAmparo('');
    }

    const errorExhortoRecibido = () => {
        setHabiliarGuardar(true);
        setErrors( { } );

        let errores = {};  
        errores.exhortoRecibido = 'No se encontró el exhorto';
        errores.type = 'warning';
        
        setExhortoRecibido( {} );
        setErrors( errores );

        setNumeroExhortoRecibido('');
    }

    const handleChangeTipoBusqueda = (tipoBusqueda,idTipoOficio) => {
        if(tipoBusqueda === 'Administrativo'){
            setHabiliarGuardar(false);
        }else{
            setHabiliarGuardar(true);
        }
        
        setTipoOficio(idTipoOficio)
        setTipoBusqueda(tipoBusqueda);
        limpiarDatos();
    } 

    const handleClicBuscarExpediente = async () => {
    
        setLoading( true );

        const valid = validateFielsExpediente();

        if( !valid ){
           
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }

        /*if(tribunal){
            await BuscarExpedientePonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                             
                                if(response.data.success){
                                    setExpediente(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarExpediente( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                             
                                if(response.data.success){
                                    setExpediente(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        //}
    }

    const handleClicBuscarIrregular = async () => {

        setLoading( true );

        const valid = validateFielsIrregular();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroIrregular: numeroIrregular
        }

        /*if(tribunal){
            await BuscarIrregularPonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setIrregular(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorIrregular();
                                }
                            }
                        }, 1500);  
                        setNumeroIrregular('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroIrregular('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarIrregular( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setIrregular(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorIrregular();
                                }
                            }
                        }, 1500);  
                        setNumeroIrregular('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroIrregular('');
                    }
                }
            });
        //}
    }

    const handleClicBuscarAmparo = async () => {

        setLoading( true );

        const valid = validateFielsAmparo();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroAmparo: numeroAmparo
        }

        /*if(tribunal){
            await BuscarAmparoPonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setAmparo(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorAmparo();
                                }
                            }
                        }, 1500);  
                        setNumeroAmparo('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroAmparo('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarAmparo( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setAmparo(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorAmparo();
                                }
                            }
                        }, 1500);  
                        setNumeroAmparo('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroAmparo('');
                    }
                }
            });
        //}         
    }

    const handleClicBuscarExhortoRecibido= async () => {

        setLoading( true );

        const valid = validateFielsExhortoRecibido();
       
        if( !valid ){
            return false;
        }

        const params = {

            numeroExhorto: numeroExhortoRecibido
        }

        /*if(tribunal){
            await BuscarExhortoRecibidoPonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExhortoRecibido(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorExhortoRecibido();
                                }
                            }
                        }, 1500);  
                        setNumeroExhortoRecibido('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExhortoRecibido('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarExhortoRecibido( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExhortoRecibido(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorExhortoRecibido();
                                }
                            }
                        }, 1500);  
                        setNumeroExhortoRecibido('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExhortoRecibido('');
                    }
                }
            });
        //}         
    }

    useEffect(() => {
    
        async function Obtener(){
            
            await ObtenerTiposOficios().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoOficioArray( response.data );
                    }
                }
                
            });

        }

        Obtener();


    }, []);

    return (
        <>

            <Dialog open={ openModal } maxWidth='md' fullWidth={true} >

                <LinearProgress sx={{ display: 'none' }}></LinearProgress>

                <DialogTitle>Nuevo oficio</DialogTitle>
            
                <DialogContent>               
                    
                    <Grid container spacing={4} >

                        {
                            tribunal
                            &&
                                <Grid item md={5} xs={12} > 
                                    
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                            placeholder={"Escribe el número de oficio (1/" + (new Date().getFullYear()) + ")"} 
                                            name="numeroReferencia"
                                            value={ numeroReferencia } 
                                            onChange={ ( e ) => { setNumeroReferencia( e.target.value ) }}
                                            autoComplete="off"
                                        />                                
                                    </Paper>
                                    {   
                                        errors.numeroReferencia && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroReferencia  } </Alert> )
                                    }
                                </Grid>
                        }

                        <Grid item md={ tribunal ? 7 : 12 } xs={12} >

                            <FormControl component="fieldset">

                                <FormLabel component="legend">Oficio para:</FormLabel>
                                <RadioGroup row>
                                    <Grid container >
                                        {
                                            tipoOficioArray.map((elem)=>(
                                                <FormControlLabel key={ elem.idTipoOficio } control={ <Radio  checked={ tipoBusqueda === elem.descripcion } onChange={ () => { handleChangeTipoBusqueda(elem.descripcion,elem.idTipoOficio) } } /> } label={elem.descripcion} />
                                            ))
                                        }
                                    </Grid>
                                </RadioGroup>

                            </FormControl>

                        </Grid>
                        
                        {
                            tipoBusqueda === 'Expediente' && (
                                <>
                                    <Grid item md={12} xs={12} > 
                                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                            
                                            <InputBase 
                                                sx={{ ml: 1, flex: 1 }} 
                                                inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                                placeholder="Buscar por número de expediente"     
                                                name="expediente"
                                                value={ numeroExpediente } 
                                                onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                                autoComplete="off"
                                            />

                                            <LoadingButton 
                                                variant='contained' 
                                                sx={{ borderRadius: 10 }} 
                                                loading={ loading }
                                                onClick={ handleClicBuscarExpediente }
                                                size='small'>
                                                <SearchIcon />
                                            </LoadingButton>                          
                                        </Paper>
                                        {   
                                            errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                                        }
                                    </Grid>
                                </>
                            )
                        }

                        {
                              (expediente.success) && (
                                <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Expediente:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { expediente.numeroExpediente }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    {
                                        expediente.ponencia
                                        &&                                
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Ponencia:
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    {
                                        expediente.ponencia
                                        &&  
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.ponencia }
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                           
                                    {
                                        expediente.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                 
                                    </List>      
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                            {
                                                expediente.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                                                                
                                    </List>
                                </Grid>  
                                </>
                            )
                        }
                        {
                            tipoBusqueda === 'Irregular' && (
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar irregular' }} 
                                            placeholder="Buscar por número de irregular"     
                                            name="irregular"
                                            value={ numeroIrregular } 
                                            onChange={ ( e ) => { setNumeroIrregular( e.target.value ) }}
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarIrregular }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                           
                                    </Paper>
                                    {   
                                        errors.irregular && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.irregular  } </Alert> )
                                    }
                                </Grid>  
                            )
                        }
                        {
                              (irregular.success) && (
                                <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Irregular:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { irregular.numeroIrregular }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Tipo de irregular:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { irregular.tipoIrregular }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                           
                                    {
                                        irregular.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                 
                                    </List>      
                                </Grid>
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                            {
                                                irregular.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                                                                
                                    </List>
                                </Grid>    

                                </>
                            )
                        }
                        {
                            tipoBusqueda === 'Amparo' && (
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                            placeholder="Buscar por número de amparo"     
                                            name="amparo"
                                            value={ numeroAmparo } 
                                            onChange={ ( e ) => { setNumeroAmparo( e.target.value ) }}
                                            autoComplete="off" 
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarAmparo }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                           
                                    </Paper>
                                    {   
                                        errors.amparo && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.amparo  } </Alert> )
                                    }
                                </Grid>                       
                            )
                        }
                        {
                            amparo.success === true && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Amparo:
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { amparo.numeroAmparo }
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Tipo de amparo:
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { amparo.tipoAmparo }
                                        </Typography>
                                    </Grid> 
        
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Partes(es):
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                               
                                            {
                                                amparo.partes.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                
                                        </List>                            
                                
                                    </Grid> 
        
                                </>
        
                            )
                        }

                        {
                            tipoBusqueda === 'Exhorto' && (
                                <>
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar exhorto recibido' }} 
                                            placeholder="Buscar por número de exhorto"     
                                            name="exhortoRecibido"
                                            value={ numeroExhortoRecibido } 
                                            onChange={ ( e ) => { setNumeroExhortoRecibido( e.target.value ) }}
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarExhortoRecibido }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                          
                                    </Paper>
                                    {   
                                        errors.exhortoRecibido && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.exhortoRecibido  } </Alert> )
                                    }
                                </Grid>
                                </>
                            )
                        }
                        {
                              (exhortoRecibido.success) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Exhorto:
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { exhortoRecibido.numeroExhorto }
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={2} xs={6}>                                                             
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Tipo de juicio:
                                        </Typography>                                
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >                               
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoRecibido.tipoJuicio }
                                        </Typography>                                
                                    </Grid> 
                
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                               
                                        {
                                            exhortoRecibido.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                
                                        </List>                            
                                
                                    </Grid> 
                            
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                            
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                            {
                                                exhortoRecibido.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                  
                                        </List>
                                    </Grid>    

                                    <Grid item md={2} xs={6}>                                                             
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Autoridad del exhorto:
                                        </Typography>                                
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >                               
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoRecibido.autoridadExhorto }
                                        </Typography>                                
                                    </Grid>                             
                                      
                                    <Grid item md={2} xs={6}>    
                                        {
                                            exhortoRecibido.ponencia
                                            &&                                                     
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>   
                                        }                             
                                    </Grid>                            
        
                                    <Grid item md={4} xs={6} >
                                        {
                                            exhortoRecibido.ponencia
                                            &&       
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { exhortoRecibido.ponencia }
                                            </Typography>
                                        }
                                    </Grid> 
        
                                </>
        
                            )
                        }

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >
                                    
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.error  } </Alert> 
                                    
                                </Grid>
                            )
                        }  

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ cerrarModal }>Cancelar</Button>
                    <Button variant="contained" color="primary" startIcon={ <SaveIcon /> } onClick={ GenerarOficio } disabled={ habiliarGuardar || ( tribunal && numeroReferencia === '' ) } >Guardar</Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

        </>
    )
}