import React from 'react';

import { Box } from '@mui/material';

import AppBarMenu from '../ui/AppBarMenu';
import { CalendarAudienciasJuezScreen } from './CalendarAudienciasJuezScreen';



export const AgendaAudienciasJuezScreen = () => {
    return (
        <>    
            <AppBarMenu />

            <Box sx={{ mt: 11 }} >

                <CalendarAudienciasJuezScreen edit={ true } />
                
            </Box>

        </>
    )
}
