import React, { useRef, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, TextField, Typography } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { ObtenerDistritoJudicial } from '../../../actions/admin/catalogs/distritoJudicialActions';
import { ObtenerObjetoExhortos } from '../../../actions/admin/catalogs/objetoExhortoActions';
import { ObtenerClaseExhortos } from '../../../actions/admin/catalogs/claseExhortoActions';
import { ActualizarContenidoExhortoLibrado, ObtenerExhortoLibradoDetalle, ActualizarEstatusExhortoLibrado } from '../../../actions/tribunal/exhortoLibradoActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const AutorizarExhortoLibradoScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const { tribunal } = useSelector( state => state.auth );

    const editorRef = useRef(null);

    const dispatch = useDispatch();

    const [sendAjax, setSendAjax] = useState(false);

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [richText, setRichText] = useState( '' ); 

    const [autorizar, setAutorizar] = useState(true);

    const [exhortoLibrado, setExhortoLibrado] = useState( [ ] );

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 

    const [observacion, setObservacion] = useState( '' );

    const [distritoJudicial, setDistritoJudicial] = useState(0);
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [ ] );

    const [objetoExhorto, setObjetoExhorto] = useState(0);
    const [objetoExhortoArray, setObjetoExhortoArray] = useState( [ ] );

    const [claseExhorto, setClaseExhorto] = useState(0);
    const [claseExhortoArray, setClaseExhortoArray] = useState( [ ] ) ;

    const [loadingRegresarEscrito, setLoadingRegresarEscrito] = useState( false );  
    const [loadingFinalizar, setLoadingFinalizar] = useState( false ); 

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [loadingGuardar, setLoadingGuardar] = useState( false );  

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [envio, setEnvio] = useState( false );

    const handleOcultarMostrarEnvio = (val) => {
        setEnvio(val)
        setDistritoJudicial(0);
    }
      
    const Finalizar = async () => {

        setLoadingFinalizar( true );
        
        const params = {
            'idExhortoLibrado' : Id,
            'idEstatus': 40,
        };

        await ActualizarEstatusExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setOpenConfirmacion( false );

                    setLoadingFinalizar( false );

                    if( !response.data.usuarioActualAsignacion ){                            
                        setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                    }  
                    else if( response.data.usuarioActualAsignacion ){
                        setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                    }         

                    setSendAjax( true );      
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const Regresar = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
        
        setLoadingRegresarEscrito( true );
        
        const params = {
            'idExhortoLibrado' : Id,
            'idEstatus': 37,
            'observacion': observacion
        };

        await ActualizarEstatusExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoadingRegresarEscrito( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }         

                        setSendAjax( true );      

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingRegresarEscrito( false );

                    }, 1000);  
                }
            }
        });

    }

    const GuardarExhorto = async () => {
       
        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            'idExhortoLibrado' : Id,
            'contenido': contenido,
        };

        setLoadingGuardar( true );

        await ActualizarContenidoExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( observacion.trim()  === '' &&  autorizar === false) {
            valid = false;
            errores.observaciones = 'Debes teclear un motivo de rechazo';
        } 

        const contenido = editorRef.current.getContent();

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        } 

        setErrors( errores );
        return valid;
    }

    const handleConfirmar = (value) => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setOpenConfirmacion(value)
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerDistritoJudicial().then( response => {

                if( response ){

                    if( response.data ){
                        setDistritoJudicialArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerObjetoExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setObjetoExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerClaseExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setClaseExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
        
        async function Obtener(){

            const params = {
                'idExhortoLibrado': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerExhortoLibradoDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            if(response.data.idDistritoJudicial === 0){
                                setEnvio(false)
                            } else {
                                setEnvio(true)
                                setDistritoJudicial(response.data.idDistritoJudicial);
                            }

                            setDistritoJudicial(response.data.idDistritoJudicial);

                            setExhortoLibrado(response.data);
                            setObjetoExhorto(response.data.idObjetoExhorto);
                            setClaseExhorto(response.data.idClaseExhorto);
                            setRichText(response.data.contenido);

                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })
                            setCadenaActores(cadenaA);

                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });     
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 39 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }   
                            
                            if( response.data.idEstatus !== 39){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setSendAjax( true ); 

                            }
                            
                        }, 1000);   

                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>    

                            <InformacionSeguimiento
                                noSeguimiento={ 'GEXL-' + exhortoLibrado.idExhortoLibrado }
                                actividad={ 'Autorizar exhorto librado' }
                                tipo={ 'Número de exhorto' }
                                referencia={ tribunal ? exhortoLibrado.numeroReferencia : exhortoLibrado.numeroExhorto }
                            />   

                            <Grid container spacing={3} mt={1}>  

                                {
                                    exhortoLibrado.numeroExpediente
                                    &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de expediente:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoLibrado.numeroExpediente }
                                                </Typography>
                                            </Grid>
                                        </>
                                }
                                
                                {
                                    exhortoLibrado.numeroExhortoRecibido
                                    &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de exhorto recibido:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoLibrado.numeroExhortoRecibido }
                                                </Typography>
                                            </Grid>
                                        </>
                                }

                                {
                                    exhortoLibrado.ponencia
                                    &&                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                    }

                                {
                                    exhortoLibrado.ponencia
                                    &&  
                                    <Grid item md={10} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoLibrado.ponencia }
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        exhortoLibrado?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                              
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                    {
                                        exhortoLibrado?.demandado?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                                              
                                    </List>
                                </Grid>              

                                <Grid item md={12} xs={12}> </Grid>

                                <Grid item md={6} xs={12} >
                                    <FormControl component="fieldset" disabled>
                                        <FormLabel component="legend">¿El exhorto librado va dirigido a un juzgado del poder judicial del estado?</FormLabel>
                                        <RadioGroup row
                                            aria-label="envioaudiencia"
                                            defaultValue="no"
                                            name="radio-buttons-group"  
                                        >   
                                            <FormControlLabel value="no" control={ <Radio checked={ !envio } onChange={ () => { handleOcultarMostrarEnvio( false ); } } />} label="No" />
                                            <FormControlLabel value="si" control={ <Radio checked={ envio }  onChange={ () => { handleOcultarMostrarEnvio( true ); } }/>} label="Si" />                                    
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {
                                    envio && (
                                        <Grid item md={6} xs={12} >                            
                                            <FormControl fullWidth>
                                                <InputLabel id="select-distritoJudicial-label"> Distrito Judicial </InputLabel>
                                            
                                                <Select
                                                    labelId="select-distritoJudicial-label"
                                                    id="select-distritoJudicial"                                            
                                                    label="Distrito Judicial"
                                                    name="distritoJudicial"
                                                    value={ distritoJudicial }
                                                    disabled
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        distritoJudicialArray.map((elem)=>(
                                                            <MenuItem key={elem.idDistritoJudicial} value={ elem.idDistritoJudicial }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid> 
                                    )
                                }

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-objetoExhorto-label">Objeto del exhorto</InputLabel>
                                    
                                        <Select
                                            labelId="select-objetoExhorto-label"
                                            id="select-objetoExhorto"                                            
                                            label="Objeto del exhorto"
                                            name="objetoExhorto"
                                            value={ objetoExhorto }
                                            disabled
                                        >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            objetoExhortoArray.map((elem)=>(
                                                <MenuItem key={elem.idObjetoExhorto} value={ elem.idObjetoExhorto }>{ elem.descripcion }</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>  

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-claseExhorto-label">Clase del exhorto</InputLabel>
                                    
                                        <Select
                                            labelId="select-claseExhorto-label"
                                            id="select-claseExhorto"                                            
                                            label="Clase del exhorto"
                                            name="claseExhorto"
                                            value={ claseExhorto }
                                            disabled
                                        >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            claseExhortoArray.map((elem)=>(
                                                <MenuItem key={elem.idClaseExhorto} value={ elem.idClaseExhorto }>{ elem.descripcion }</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>  
                                
                                <Grid item md={12} xs={12} >  
                                    <Editor
                                        onInit = { (evt, editor) => editorRef.current = editor }
                                        initialValue = {richText}
                                        apiKey = { ObtenerApiKeyRichText( process.env ) }
                                        init = {
                                            {
                                                height: 450,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount pagebreak'
                                                ],
                                                    toolbar: 'undo redo | ' +
                                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                    'removeformat | print pagebreak | Atajos',
                                                    setup: (editor) => {                                        
                                                        editor.ui.registry.addMenuButton('Atajos', {
                                                            icon: 'bookmark' ,
                                                            tooltip: "Atajos",
                                                            fetch: function (callback) {
                                                                var items = [];
                                                                
                                                                if( exhortoLibrado?.ponencia ){
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExhorto + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ exhortoLibrado.ponencia +'</strong>'); } },
                                                                    ];
                                                                }
                                                                else{                                                                
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExhorto + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado.tipoJuicio+'</strong>'); } },
                                                                    ];
                                                                }
                                                                callback(items);
                                                            }
                                                        });
                                                    },                                          
                                                    content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                            }
                                        }  
                                    />
                                </Grid> 
                                {   
                                    errors.contenido && ( 
                                        <Grid item md={12} xs={12}>
                                            <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                        </Grid>
                                    )
                                }  

                                 <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ GuardarExhorto } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                    > 
                                        Guardar exhorto
                                    </LoadingButton>

                                </Grid> 

                                <Grid item md={4} xs={12} >
                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend"  sx={{ color: 'rgba(0, 0, 0, 1)' }}>¿Desea autorizar el exhorto librado?</FormLabel>
                                        <RadioGroup row
                                            aria-label="autorizarAcuerdo"
                                            defaultValue="si"
                                            name="radio-buttons-group"  
                                        >
                                            <FormControlLabel value="si" control={ <Radio checked={ autorizar === true } onChange={ () => { setAutorizar(true) } } />}  label="Si" />
                                            <FormControlLabel value="no" control={ <Radio checked={ autorizar === false } onChange={ () => { setAutorizar(false) } } />} label="No" />                                                
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {
                                    !autorizar && (
                                        <>
                                        <Grid item md={8} xs={12} >
                                            <TextField                              
                                                label="Motivo"                                                     
                                                multiline
                                                rows={4}  
                                                fullWidth                          
                                                name="observacion"   
                                                variant="outlined"
                                                value={ observacion }
                                                onChange={ ( e ) => { setObservacion( e.target.value ) }}
                                            />
                                            {   
                                                errors.observaciones && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.observaciones } </Alert> )
                                            }
                                        </Grid>
                                        
                                        </>
                                    )
                                }

                                 

                                <Grid item md={12} xs={12} sx={{ mt: 1 }}> 
                                    
                                    <Grid container spacing={3}>
                                    
                                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 

                                            <LoadingButton 
                                                variant="contained" 
                                                onClick={ Regresar } 
                                                startIcon={ <ArrowBackIcon />  } 
                                                disabled={ autorizar }
                                                loading={ loadingRegresarEscrito }
                                            > 
                                                Regresar escrito 
                                            </LoadingButton>
 
                                        </Grid>
                                        
                                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                            
                                            <Button 
                                                variant="contained" 
                                                onClick={ () => { handleConfirmar( true ); } } 
                                                endIcon={ <CheckCircleIcon />  } 
                                                disabled={ !autorizar }
                                            > 
                                                Finalizar y Firmar exhorto 
                                            </Button>

                                        </Grid>

                                    </Grid>

                                </Grid>       

                            </Grid>   
                           
                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la autorización y firmado del exhorto librado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>
        </>
    )
}
