import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { ActualizarTipoAcuerdo, AgregarTipoAcuerdo, TiposAcuerdos } from '../../../actions/admin/catalogs/tipoAcuerdoActions';
    
export const TipoAcuerdoScreen = ( { history }) => {
    
    const { location } = history;
    const { pathname } = location;

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idTipoAcuerdo === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const tipoacuerdo = rows.filter( function(row){ return row.idTipoAcuerdo === id; } );
        cambiarEstatus( tipoacuerdo[0] );
    }

    const handleChangeSwitchListaAcuerdo = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idTipoAcuerdo === id){ row.listaAcuerdo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const tipoacuerdo = rows.filter( function(row){ return row.idTipoAcuerdo === id; } );
        cambiarEstatus( tipoacuerdo[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const Regresar = () => {
        history.push("/Admin/Catalogs");
    }

    const cambiarEstatus = async ( { idTipoAcuerdo, descripcion, listaAcuerdo, activo } ) => {

        const params = {
            idTipoAcuerdo,
            descripcion,
            listaAcuerdo,
            activo,
        };

        await ActualizarTipoAcuerdo( params );
    }

    const AgregarRegistro = () => {

        setDescripcion( '' );
        setOpcion( 'agregar' );

        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion ) => {
        
        setDescripcion( descripcion );
        setOpcion( 'editar' );

        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        if( opcion === 'agregar' ){
            const params = {
                idTipoAcuerdo: 0,
                descripcion: descripcion.trim(),
                listaAcuerdo: false,
                activo: true,
            };

            await AgregarTipoAcuerdo( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            setRows([ 
                                ...rows,  
                                {
                                    idTipoAcuerdo: response.data.idTipoAcuerdo,
                                    descripcion: descripcion,
                                    listaAcuerdo: false,
                                    activo: true
                                }
                            ]);  

                            handleCloseModal();
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const tipoacuerdo = rows.filter( function(row){ return row.idTipoAcuerdo === idReferencia; } )[0];

            const params = {
                idTipoAcuerdo: idReferencia,
                descripcion: descripcion.trim(),
                listaAcuerdo: tipoacuerdo.listaAcuerdo,
                activo: tipoacuerdo.activo,
            };
    
            await ActualizarTipoAcuerdo( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idTipoAcuerdo === idReferencia){ 
                                row.descripcion = descripcion; 
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModal();
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await TiposAcuerdos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);
    return (
        <>
        <AppBarMenu />            

        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

            <Container maxWidth="lg">   

                <Grid container spacing={3} >  

                    <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                        <Button type="button" startIcon={ <ArrowBackIcon /> } variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                            Regresar
                        </Button> 
                    </Grid>
                   
                    <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                        <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                            Agregar
                        </Button> 
                    </Grid>
                       
                    <Grid item xs={12} md={12}> 

                        <Paper sx={{ width: '100%' }}>

                            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                    {
                                        desc.length !== 0 ? desc[0].descripcion : ''
                                    }
                                </Typography>
                            </Toolbar>

                            <TableContainer>
                                <Table sx={{ minWidth: 500 }} >
                                    <TableHead>
                                        <TableRow>        
                                            <TableCell style={{ width:'10%' }}> ID </TableCell>
                                            <TableCell style={{ width:'30%' }}> DESCRIPCIÓN </TableCell>
                                            <TableCell style={{ width:'20%' }}> PUBLICAR EN LISTA DE ACUERDOS </TableCell>
                                            <TableCell style={{ width:'15%' }}> HABILITADO </TableCell>
                                            <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {
                                    (rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <TableRow key={ row.idTipoAcuerdo} >
                                        
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                { row.idTipoAcuerdo }
                                            </TableCell>

                                            <TableCell>
                                                { row.descripcion }
                                            </TableCell>

                                            <TableCell>
                                                {
                                                    <Switch 
                                                        inputProps={ { 'aria-label': 'Switch demo'} } 
                                                        checked={ row.listaAcuerdo } 
                                                        onChange={ ( e ) => handleChangeSwitchListaAcuerdo( row.idTipoAcuerdo, e.target.checked) }
                                                    />                                                   
                                                }
                                            </TableCell>
                                            
                                            <TableCell>
                                                {                                                   
                                                    <Switch 
                                                        inputProps={ { 'aria-label': 'Switch demo'} } 
                                                        checked={ row.activo } 
                                                        onChange={ ( e ) => handleChangeSwitch( row.idTipoAcuerdo, e.target.checked) }
                                                    />                                                    
                                                }
                                            </TableCell>

                                            <TableCell>
                                                <IconButton 
                                                        color="primary" 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        onClick={ () => EditarRegistro( row.idTipoAcuerdo, row.descripcion, row.listaAcuerdo ) }
                                                    >                                                        
                                                    <EditIcon />
                                                </IconButton>  
                                            </TableCell>

                                        </TableRow>
                                    ))}

                                    { rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={5} align="center"> 
                                            No se encontraron resultados
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={5}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        
                        </Paper>

                    </Grid>

                </Grid>

            </Container>

        </Box>

        <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
        
            <DialogContent>

                <TextField
                    sx={{ mt: 1 }}
                    label={ desc.length !== 0 ? 'Descripción - ' + desc[0].descripcion : '' }
                    variant="outlined"      
                    autoFocus                          
                    multiline
                    rows={4}                                        
                    fullWidth
                    value={ descripcion }
                    onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                />

                {   
                    errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                }

            </DialogContent>

            <DialogActions>
                <Button onClick={ handleCloseModal }> Cancelar </Button>
                <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
            </DialogActions>

        </Dialog>

    </>
    )
}
