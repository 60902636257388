import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Container, Box, Card, Avatar, CardHeader, CardActions, IconButton, Grid, Typography, TextField } from '@mui/material';

import useStyles from '../../../theme/useStyles';

import AppBarMenu from '../../ui/AppBarMenu';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { UserModalScreen } from './UserModalScreen';

import { getUserFilter } from '../../../selectors/users/getUserFilter';

import { ObtenerUsuarios } from '../../../actions/admin/users/usuarioAction';

import { types } from '../../../types/types';

export const UsersScreen = (props) => {

    const dispatch = useDispatch();
    
    const classes = useStyles();

    const [usuariosAll, setUsuariosAll] = useState([ ]);
    const [usuarios, setUsuarios] = useState([ ]);

    const [openModal, setOpenModal] = useState(false);

    const editaUsuario = (id) => {
        props.history.push("/Admin/User/" + id);
    }   

    const filter = (e) => {
        if( e.target.value !== ""){
            const usuariosFilter = getUserFilter(usuariosAll, e.target.value);
            setUsuarios(usuariosFilter);
        }
        else{
            setUsuarios(usuariosAll);
        }
    }

    const handleOpenModal = () => {       
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const ObtenerUsuario = (usuario) =>{
        setUsuarios([...usuarios, usuario]);
    }

    useEffect(() => {
        
        async function Obtener(){        
            
            dispatch( { type: types.openLoading } );

            await ObtenerUsuarios().then( response => {

                if(response){
                    if( response.status === 200){   
                       
                        setTimeout(() => {

                            setUsuarios(response.data);
                            setUsuariosAll(response.data);

                            dispatch( { type: types.closeLoading } );  
                            
                        }, 1200);   
                        
                    }
                }
            });
        }

        Obtener();

    }, [ dispatch ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  
                <Container maxWidth="lg">
                    
                    <Grid container spacing={3} >

                        <Grid item xs={12} md={9}> 

                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />

                                <TextField 
                                    id="input-buscar" 
                                    label="Buscar usuario" 
                                    variant="standard" 
                                    style={{ width: '100%' }} 
                                    onKeyUp={ filter } 
                                    autoComplete="off"
                                />
                            
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={3}> 
                            <Button type="button" fullWidth startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ handleOpenModal }> 
                                Agregar Usuario
                            </Button> 
                        </Grid>

                    </Grid>                    

                    <Grid sx={{ marginTop: '5px' }} container spacing={3} >
                    { 
                        usuarios.map((usuario) => (
                        
                            <Grid key={usuario.id} item xs={12} sm={6} md={4} lg={3} xl={3} >
                        
                                <Card>
                                    <CardHeader avatar={ <Avatar src={ usuario.imagenPerfil ? usuario.imagenPerfil : 'https://media.istockphoto.com/vectors/default-avatar-profile-icon-grey-photo-placeholder-vector-id846183008?k=6&m=846183008&s=170667a&w=0&h=bCKJRIR5IBFOv80Rd8HfBVj_VirFxgWuZIDJcg9OK9M=' } aria-label="recipe"> </Avatar> }
                                        action={ <IconButton onClick={ () => { editaUsuario( usuario.id ) } } aria-label="settings"> <EditIcon /> </IconButton> }
                                        title={ usuario.nombreCompleto }
                                        subheader={ usuario.administrador ? 'Administrador' : usuario.puesto }
                                    />          
                                    <CardActions>
                                        <Button startIcon={ usuario.activo ? <CheckIcon className={classes.UserActivo} /> : <ClearIcon className={classes.UserInactivo} /> } size="small">                                                
                                            <Typography variant="inherit" className={usuario.activo ? classes.UserActivo : classes.UserInactivo} >
                                                { usuario.activo ? 'Activo': 'Inactivo' }
                                            </Typography>
                                        </Button>                                        
                                    </CardActions>                      
                                </Card>

                            </Grid>
                        ))                        
                    }  
                    </Grid>

                    <Grid sx={{ marginTop: 2, textAlign: 'center' }} container spacing={3} >
                    {
                        usuarios.length === 0 && (
                            <Grid item md={12} >
                                <Typography variant="h5" sx={{ mt: 1, mb: 1, color: (theme) => theme.palette.primary.main }}>
                                    No se encontraron resultados
                                </Typography>
                            </Grid>
                        )
                    }
                    </Grid>
                </Container>
                
                <Box style={{ height: 50 }}></Box>
            </Box>

            <UserModalScreen openModal={ openModal } handleCloseModal={ handleCloseModal } ObtenerUsuario={ ObtenerUsuario } />
        </>
    );
}
