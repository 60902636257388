import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputBase, LinearProgress, List, ListItem, Paper, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { ModalBuscarSentencia } from './ModalBuscarSentencia';

import { BuscarExpediente, BuscarExpedienteJuez } from '../../actions/recepcion/expedienteActions';
import { GenerarSentenciaPublica } from '../../actions/sentencias/sentenciaPublicaActions';

export const ModalNuevaSentenciaPublica = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const {juzgado, tribunal} = useSelector(state => state.auth);

    const [openModalBuscarSentencia, setOpenModalBuscarSentencia] = useState(false);

    const [errors, setErrors] = useState({ });

    const [expediente, setExpediente] = useState( {} );
    const [numeroExpediente, setNumeroExpediente] = useState('');

    const [Sentencia, setSentencia] = useState( { idSentencia: 0, noSentencia: '', noSeguimiento: '', fecha: '' } );

    const { idSentencia, noSentencia, noSeguimiento, fecha } = Sentencia;


    const [loading, setLoading] = useState( false );
    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const handleOpenModalBuscarSentencia = () => {       
        setOpenModalBuscarSentencia(true);
    };

    const handleCloseModalBuscarSentencia = () => {
        setOpenModalBuscarSentencia(false);
    };

    const CerrarModal = () => {
        handleCloseModal();
        setExpediente( { } );
        setSentencia( { idSentencia: 0, noSentencia: '', noSeguimiento: '', fecha: '' } );
    }

    const SeleccionarSentencia = ( sentencia ) => {
        setSentencia( sentencia );
    }

    const handleGenerarSentenciaPublica = async () => {

        const params = {
            idExpediente: expediente?.success ? expediente?.idExpediente : 0,
            idSentencia: idSentencia,
        };

        setLoadingGuardar(true);

        await GenerarSentenciaPublica( params ).then( response => {

            if( response ){

                if( response.status === 200){
                   
                    if(response.data){
                        
                        setErrors( { } );
                        setNumeroExpediente('');
                        
                       const { idSentenciaPublica } = response.data;

                        setTimeout(() => {

                            setLoadingGuardar(false);

                            history.push("/Sentencia/ElaborarSentenciaPublica/" + idSentenciaPublica);

                        }, 1200);  
                    }

                } 
                else {
                    
                    setTimeout(() => {
                        setErrors( { } );
                        setNumeroExpediente('');
                       
                        setLoadingGuardar(false);
                        
                        const { message } = response.data;

                        let errores = {};  
                        errores.error = 'Ocurrió un error, contacte con el administrador ' + ( message ? `(${ message })` : '' );
                        errores.type = 'error';
                        
                        setErrors( errores );
                    }, 1200);  

                }
            }
        });
        
    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === 0 || numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }

        if( tribunal ){
            await BuscarExpediente( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExpediente(response.data);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        }
        else if ( juzgado ){
            await BuscarExpedienteJuez( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExpediente(response.data);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        } 
    }

    return (
        <>

            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
               
                <DialogTitle>Nueva sentencia pública</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                        <Grid item md={12} xs={12}>  
                            
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                        
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"
                                    value={ numeroExpediente } 
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                    autoComplete="off"
                                   
                                /> 

                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    loading={ loading }
                                    onClick={ handleClicBuscarExpediente }
                                    size='small'         
                                >
                                    <SearchIcon />
                                </LoadingButton> 


                            </Paper>
                            {   
                                errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                            }

                        </Grid>


                        {
                            ( expediente.success ) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Expediente:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.numeroExpediente }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        {
                                            expediente.ponencia
                                            &&                                
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        {
                                            expediente.ponencia
                                            &&  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.ponencia }
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                           
                                        {
                                            expediente.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                 
                                        </List>      
                                    </Grid>
                                    
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                                                                
                                        </List>
                                    </Grid>   
                                    
                                </>
                            )
                        }
                      
                        <Grid item md={12} xs={12} >    

                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar sentencia"     
                                    name="Sentencia"
                                    value={ '' } 
                                    onChange={ ( ) => { }}
                                    autoComplete="off"
                                /> 

                                <Button 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    disabled={ !expediente?.success }
                                    onClick={ handleOpenModalBuscarSentencia }
                                    size='small'
                                >
                                    <SearchIcon />
                                </Button>   

                            </Paper>                         

                        </Grid>                        

                        {
                            ( idSentencia !== 0 ) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            No. Seguimiento:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { noSeguimiento }
                                        </Typography>
                                    </Grid>           

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            No. Sentencia:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { noSentencia }
                                        </Typography>
                                    </Grid>           

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Fecha:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { fecha }
                                        </Typography>
                                    </Grid>                                   
                                    
                                </>
                            )
                        }

                    </Grid>
                        

                </DialogContent>

                <DialogActions>                    
                    <Button onClick={ CerrarModal }>Cancelar</Button>
                    <Button
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ handleGenerarSentenciaPublica } 
                        disabled={ 
                            ( 
                                tribunal 
                                ? 
                                    !expediente.success || idSentencia === 0
                                : 
                                !expediente.success 
                            ) 
                            ||
                            loadingGuardar
                        }

                    >
                        Guardar
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

            <ModalBuscarSentencia 
                openModal={ openModalBuscarSentencia } 
                handleCloseModal={ handleCloseModalBuscarSentencia }               
                expediente={ expediente }
                SeleccionarSentencia={ SeleccionarSentencia }
            />

        </>
    )
}
