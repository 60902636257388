import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { ActualizarAsentamiento, AgregarAsentamiento, Asentamientos } from '../../../actions/admin/catalogs/asentamientoActions';

import { ObtenerEstados } from '../../../actions/admin/catalogs/estadoActions';
import { ObtenerMunicipios } from '../../../actions/admin/catalogs/municipioActions';
import { ObtenerZonas } from '../../../actions/admin/catalogs/zonaActions';
import { ObtenerTiposAsentamientos } from '../../../actions/admin/catalogs/tipoAsentamientoActions';
import { ObtenerCiudades } from '../../../actions/admin/catalogs/ciudadActions';

import { types } from '../../../types/types';

    
export const AsentamientoScreen = ( { history } ) => {

    const { location } = history;
    const { pathname } = location;

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [ ] );

    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [ ] );

    const [ciudad, setCiudad] = useState( 0 );
    const [ciudadArray, setCiudadArray] = useState( [ ] );

    const [zona, setZona] = useState( 0 );
    const [zonaArray, setZonaArray] = useState( [ ] );

    const [tipoAsentamiento, setTipoAsentamiento] = useState( 0 );
    const [tipoAsentamientoArray, setTipoAsentamientoArray] = useState( [ ] );

    const [codigoPostal, setCodigoPostal] = useState( '' );
    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idAsentamiento === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const asentamiento = rows.filter( function(row){ return row.idAsentamiento === id; } );
        cambiarEstatus( asentamiento[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const Regresar = () => {
        history.push("/Admin/Catalogs");
    }

    const cambiarEstatus = async ( { idAsentamiento, clave, codigoPostal, descripcion, activo, estado, municipio, ciudad, zona, tipoAsentamiento } ) => {

        const params = {
            idAsentamiento,
            clave,
            codigoPostal,
            idEstado: estado.idEstado,
            idMunicipio: municipio.idMunicipio,
            idCiudad: ciudad ? ciudad.idCiudad : 0,
            idZona: zona.idZona,
            idTipoAsentamiento: tipoAsentamiento.idTipoAsentamiento,
            descripcion,
            activo,
        };

        await ActualizarAsentamiento( params );
    }

    const AgregarRegistro = () => {

        setEstado( 0 );
        setMunicipio( 0 );
        setCiudad( 0 );
        setZona( 0 );
        setTipoAsentamiento( 0 );
        setCodigoPostal( '' );
        setDescripcion( '' );        

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion, codigoPostal, idEstado, idMunicipio, idCiudad, idZona, idTipoAsentamiento ) => {
        
        setCodigoPostal( codigoPostal );
        setDescripcion( descripcion );
        setEstado( idEstado );
        
        setZona( idZona );
        setTipoAsentamiento( idTipoAsentamiento );

        setOpcion( 'editar' );
        setIdReferencia( id );

        asyncMunicipiosEdit( idEstado, idMunicipio );
        asyncCiudadEdit(idEstado, idMunicipio, idCiudad);

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        if( opcion === 'agregar' ){
            const params = {
                idAsentamiento: 0,
                clave: '',
                codigoPostal: codigoPostal,
                descripcion: descripcion.trim(),
                idEstado: estado,
                idMunicipio: municipio,
                idCiudad: ciudad,
                idZona: zona,
                idTipoAsentamiento: tipoAsentamiento,
                activo: true,
            };

            await AgregarAsentamiento( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            setRows([ 
                                ...rows,  
                                {
                                    idAsentamiento: response.data.idAsentamiento,
                                    idEstado: response.data.idEstado,
                                    idMunicipio: response.data.idMunicipio,
                                    idCiudad: response.data.idCiudad ?? '',
                                    idZona: response.data.idZona,
                                    idTipoAsentamiento: response.data.idTipoAsentamiento,

                                    estado: response.data.estado,
                                    municipio: response.data.municipio,
                                    ciudad: response.data.municipio,
                                    zona: response.data.zona,
                                    tipoAsentamiento: response.data.tipoAsentamiento,

                                    clave: response.data.clave,
                                    codigoPostal: codigoPostal,
                                    descripcion: descripcion,
                                    activo: true
                                }
                            ]);  

                            handleCloseModal();
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const asentamiento = rows.filter( function(row){ return row.idAsentamiento === idReferencia; } )[0];

            const params = {
                idAsentamiento: idReferencia,
                idEstado: estado,
                idMunicipio: municipio,
                idCiudad: ciudad,
                idZona: zona,
                idTipoAsentamiento: tipoAsentamiento,
                clave: asentamiento.clave,
                codigoPostal: codigoPostal,  
                descripcion: descripcion.trim(),                
                activo: asentamiento.activo,
            };
    
            await ActualizarAsentamiento( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idAsentamiento === idReferencia){ 
                                row.clave = response.data.clave; 
                                row.codigoPostal = codigoPostal; 
                                row.descripcion = descripcion; 
                                row.estado = response.data.estado;
                                row.municipio = response.data.municipio;
                                row.ciudad = response.data.ciudad;
                                row.zona = response.data.zona;
                                row.tipoAsentamiento = response.data.tipoAsentamiento;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModal();
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        } 
        
        if( codigoPostal === '' || codigoPostal === 0 ){
            valid = false;
            errores.codigoPostal = 'Debes escribir el código postal';
        } 

        if( estado === 0 ){
            valid = false;
            errores.estado = 'Debes seleccionar un estado';
        }  

        if( estado !== 0 && municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }  

        if( zona === 0 ){
            valid = false;
            errores.zona = 'Debes seleccionar una zona';
        }  

        if( tipoAsentamiento === 0 ){
            valid = false;
            errores.tipoAsentamiento = 'Debes seleccionar un tipo de asentamiento';
        }  
        
        setErrors( errores );
        return valid;
    } 

    const asyncMunicipiosEdit = async (idEstado, idMunicipio) => {
        
        setCiudad( 0 );

        await ObtenerMunicipios( idEstado ).then( response => {
            if(response){
                if( response.status === 200){
                    setMunicipioArray( response.data );  
                    
                    setMunicipio( idMunicipio );
                }
            }
        });

    }

    const asyncCiudadEdit = async (idEstado, idMunicipio, idCiudad) => {
        
        const params = {
            idEstado,
            idMunicipio
        }

        await ObtenerCiudades( params ).then( response => {
            if(response){
                if( response.status === 200){
                    setCiudadArray( response.data );   

                    setCiudad( idCiudad );
                }
            }
        });
    }

    useEffect(() => {
        
        async function Obtener(){
            
            dispatch( { type: types.openLoading } );

            await Asentamientos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );
                    }

                    dispatch( { type: types.closeLoading } );
                }
                
            });

        }

        Obtener();

    }, [ dispatch ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerEstados().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setEstadoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){     

            setCiudad( 0 );
            setMunicipio( 0 );

            if( estado !== 0 ){       
                await ObtenerMunicipios( estado ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setMunicipioArray( response.data );   
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ estado ]);

    useEffect(() => {
        
        async function Obtener(){    
                
            await ObtenerZonas().then( response => {
                if(response){
                    if( response.status === 200){
                        setZonaArray( response.data );   
                    }
                }
            });

        }

        Obtener();

    }, [ ]);
    
    useEffect(() => {
        
        async function Obtener(){       
            
            await ObtenerTiposAsentamientos().then( response => {
                if(response){
                    if( response.status === 200){
                        setTipoAsentamientoArray( response.data );   
                    }
                }
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){    

            setCiudad( 0 );
              
            if( estado !== 0 && municipio !== 0){

                const params = {
                    idEstado: estado,
                    idMunicipio: municipio,
                };

                await ObtenerCiudades( params ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setCiudadArray( response.data );   
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ estado, municipio ]);

    return (
        <>
            <AppBarMenu />            

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg">   

                    <Grid container spacing={3} >  

                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                            <Button type="button" startIcon={ <ArrowBackIcon /> } variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                                Regresar
                            </Button> 
                        </Grid>
                       
                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>
                           
                        <Grid item xs={12} md={12}> 

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    
                                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                        {
                                            desc.length !== 0 ? desc[0].descripcion : ''
                                        }
                                    </Typography>

                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >
                                        <TableHead>
                                            <TableRow>        
                                                <TableCell style={{ width:'5%' }}> ID </TableCell>
                                                <TableCell style={{ width:'5%' }}> CLAVE </TableCell>
                                                <TableCell style={{ width:'20%' }}> DESCRIPCIÓN </TableCell>
                                                <TableCell style={{ width:'10%' }}> CÓDIGO POSTAL </TableCell>
                                                
                                                <TableCell style={{ width:'20%' }}> ESTADO </TableCell>
                                                <TableCell style={{ width:'20%' }}> MUNICIPIO </TableCell>
                                                                                                
                                                <TableCell style={{ width:'10%' }}> HABILITADO </TableCell>
                                                <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                        {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <TableRow key={ row.idAsentamiento} >
                                            
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    { row.idAsentamiento }
                                                </TableCell>

                                                <TableCell>
                                                    { row.clave }
                                                </TableCell>

                                                <TableCell>
                                                    { row.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.codigoPostal }
                                                </TableCell>

                                                <TableCell>
                                                    { row.estado.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.municipio.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    {
                                                        row.activo 
                                                        ? 
                                                        (
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idAsentamiento, e.target.checked ) }
                                                            />
                                                        ) 
                                                        :                                           
                                                        ( 
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idAsentamiento, e.target.checked ) }
                                                            />
                                                        )
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton 
                                                            color="primary" 
                                                            aria-label="edit" 
                                                            component="span" 
                                                            onClick={ () => EditarRegistro(                                                                 
                                                                row.idAsentamiento, row.descripcion, row.codigoPostal, row.estado.idEstado, row.municipio.idMunicipio, 
                                                                row.ciudad ? row.ciudad.idCiudad : 0, row.zona.idZona, row.tipoAsentamiento.idTipoAsentamiento      
                                                            )}
                                                        >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        { rows.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={11} align="center"> 
                                                No se encontraron resultados
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[ ]}
                                                    colSpan={11}
                                                    count={rows.length}                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            
                            </Paper>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
            
                <DialogContent>
                       
                    <Grid container spacing={3}>
    
                        <Grid item md={6} sm={12}>

                            <FormControl fullWidth sx={{ mt: 3 }} >
                                <InputLabel id="select-estado-label">Estado</InputLabel>
                            
                                <Select
                                    labelId="select-estado-label"
                                    id="select-estado"                                            
                                    label="Estado"
                                    name="estado"
                                    value={ estado }                                
                                    onChange={ ( e ) => { setEstado( e.target.value ) } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        estadoArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.estado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.estado } </Alert> )
                            }     

                        </Grid>

                        <Grid item md={6} sm={12}>

                            <FormControl fullWidth sx={{ mt: 3 }} >
                                <InputLabel id="select-municipio-label">Municipio</InputLabel>
                            
                                <Select
                                    labelId="select-municipio-label"
                                    id="select-municipio"                                            
                                    label="Municipio"
                                    name="municipio"
                                    value={ municipio }                                
                                    onChange={ ( e ) => { setMunicipio( e.target.value ) } }
                                    disabled={ estado === 0 }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        municipioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.municipio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.municipio } </Alert> )
                            }          

                        </Grid>
                                
                    </Grid>                    

                    <FormControl fullWidth sx={{ mt: 3 }} >
                        <InputLabel id="select-ciudad-label">Ciudad</InputLabel>
                    
                        <Select
                            labelId="select-ciudad-label"
                            id="select-ciudad"                                            
                            label="Ciudad"
                            name="ciudad"
                            value={ ciudad }                                
                            onChange={ ( e ) => { setCiudad( e.target.value ) } }
                            disabled={ estado === 0 }
                        >
                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                            {
                                ciudadArray.map( ( elem ) => (
                                    <MenuItem key={ elem.idCiudad } value={ elem.idCiudad }> { elem.descripcion } </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                    <Grid container spacing={3}>
                      
                        <Grid item md={6} sm={12}>

                            <FormControl fullWidth sx={{ mt: 3 }} >
                                <InputLabel id="select-tipoAsentamiento-label">Tipo asentamiento</InputLabel>
                            
                                <Select
                                    labelId="select-tipoAsentamiento-label"
                                    id="select-tipoAsentamiento"                                            
                                    label="Tipo asentamiento"
                                    name="tipoAsentamiento"
                                    value={ tipoAsentamiento }                                
                                    onChange={ ( e ) => { setTipoAsentamiento( e.target.value ) } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        tipoAsentamientoArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idTipoAsentamiento } value={ elem.idTipoAsentamiento }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.tipoAsentamiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoAsentamiento } </Alert> )
                            }

                        </Grid>

                        <Grid item md={6} sm={12}>

                            <FormControl fullWidth sx={{ mt: 3 }} >
                                <InputLabel id="select-zona-label">Zona</InputLabel>
                            
                                <Select
                                    labelId="select-zona-label"
                                    id="select-zona"                                            
                                    label="Zona"
                                    name="zona"
                                    value={ zona }                                
                                    onChange={ ( e ) => { setZona( e.target.value ) } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        zonaArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idZona } value={ elem.idZona }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.zona && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.zona } </Alert> )
                            }

                        </Grid>
                    
                    </Grid>
                    
                    <TextField
                        sx={{ mt: 3 }}
                        label={ 'Código postal' }
                        variant="outlined"             
                        fullWidth
                        value={ codigoPostal }
                        onChange={ ( e ) => { setCodigoPostal( e.target.value ) } }
                    />

                    {   
                        errors.codigoPostal && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.codigoPostal } </Alert> )
                    }

                    <TextField
                        sx={{ mt: 3 }}
                        label={ 'Descripción' }
                        variant="outlined"                           
                        multiline
                        rows={4}                                        
                        fullWidth
                        value={ descripcion }
                        onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                    />

                    {   
                        errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }> Cancelar </Button>
                    <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

            </Dialog>

        </>
    )
}
