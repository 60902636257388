import React, {  useEffect,useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert,  Box, Button, Card,  CardContent, CardHeader,  Container, Divider, Grid, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import AppBarMenu from '../../ui/AppBarMenu';

import { ModalNuevaPersona } from '../../modals/ModalNuevaPersona';
import { ModalBuscarPersona } from '../../modals/ModalBuscarPersona';

import DigitalizarIdentificacionScreen from './DigitalizarIdentificacionScreen';

import { ExpedienteConsultadoDetalle, ActualizaExpedienteConsultado } from '../../../actions/tribunal/expedienteConsultadoActions'; 

import moment from 'moment';

import { types } from '../../../types/types';

import { InformacionSeguimientoSimple } from '../../ui/InformacionSeguimiento';
import { TablePaginationActions } from '../../../helpers/TablePaginationActions';
import { usePages } from '../../../hooks/usePages';

export const RegistrarPrestamoScreen = ( { match } ) => {   

    const idExpedienteConsultado = match?.params?.Id;
    
    const { tribunal } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [loading, setLoading] = useState(false);

    const [sendAjax, setSendAjax] = useState(false);   

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [expedienteConsultado, setExpedienteConsultado] = useState( { } ); 

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    }); 

    const [fechaConsulta, setFechaConsulta] = useState( DateNow );

    const [fechaDevolucion, setFechaDevolucion] = useState( '' );

    const [errors, setErrors] = useState({ });

    const [rows, setRows] = useState( [] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages(5);

    const { idPersona, nombreCompleto } = persona; 

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };   
    
    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPersona( persona );

        }

    }    
    
    const handleValidate = () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        return true;

    }

    const Finalizar = async () => {       

        const params = {
            
            idExpedienteConsultado: idExpedienteConsultado,                       
            idPersonaConsulta: idPersona,           
            fechaConsulta: fechaConsulta,
            fechaDevolucion: fechaDevolucion,

        };

        await ActualizaExpedienteConsultado( params ).then( response => {

            if( response ){               

                if( response.status === 200){

                    setTimeout(() => {

                        setLoading( false );

                        setSendAjax( true );
                        
                    }, 1500);

               } 
               
            }           

        });    

    }

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};                       

        if( fechaConsulta === "" ){
            valid = false;
            errores.fechaConsulta = 'Seleccione la fecha de consulta';
        }   
        
        if( fechaDevolucion === "" ){
            valid = false;
            errores.fechaDevolucion = 'Seleccione la fecha de devolución';
        }

        if( nombreCompleto === ""  ){      
            valid = false;
            errores.nombreCompleto = 'Seleccione una persona';  
        }
        
        setErrors( errores );
        return valid;
    }

    useEffect(() => {      

        async function Obtener(){
           
            const params = {
                idExpedienteConsultado: match?.params?.Id
            };

            dispatch({ type: types.openLoading }); 

            await ExpedienteConsultadoDetalle( params ).then( response => {

                if( response ){
                   
                    if( response.status === 200){
                        
                        setExpedienteConsultado( response.data ); 
                        setRows( response?.data?.expedientes ?? [] ); 

                        if( response.data.idEstatus === 47 ){

                            setSendAjax( true );
                        }

                        setTimeout(() => {
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                        }, 1000);

                    }

                }
                
            })
          
        }

        if( match ){
            Obtener();
        }


    }, [ match, dispatch ])  

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }
    
    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            No se tienen actividades pendientes para este trámite
                                        </Typography>

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>    

                            <Grid container spacing={3} >  

                                <Grid  item md={12} xs={12}>
                                    <InformacionSeguimientoSimple 
                                        noSeguimiento={ 'GPE-' + expedienteConsultado?.id } 
                                        actividad={ 'Registrar préstamo' }
                                    />
                                </Grid>
                              
                                <Grid item md={12} xs={12} >

                                    <Typography variant='h6' fontWeight={'bold'} ml={1}>
                                        Expedientes para consulta
                                    </Typography>

                                    <TableContainer style={{ marginTop: '10px' }}>

                                        <Table>

                                            <TableHead>

                                                <TableRow>
                                                    <TableCell style={{ width:'20%', fontWeight: 'bold' }}> NÚMERO DE EXPEDIENTE </TableCell>

                                                    { tribunal && <TableCell style={{ width:'30%', fontWeight: 'bold' }}> PONENCIA </TableCell> }

                                                    <TableCell style={{ width:'30%', fontWeight: 'bold' }}> ACTOR(ES) </TableCell>
                                                    <TableCell style={{ width:'30%', fontWeight: 'bold' }}> DEMANDADO(S) </TableCell>
                                                </TableRow>

                                            </TableHead>

                                            <TableBody>

                                                {
                                                    (rowsPerPage > 0
                                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : rows
                                                    ).map( ( item, index ) => (
                                                        <TableRow key={ index } >
                                                            
                                                            <TableCell> { item.numeroExpediente } </TableCell>

                                                            { tribunal && <TableCell> { item.ponencia } </TableCell>  }                                                                                                              

                                                            <TableCell> { item.actor } </TableCell>                                                       
                                                            <TableCell> { item.demandado } </TableCell>   

                                                        </TableRow>
                                                    ))
                                                }

                                                {
                                                    rows.length === 0 && 
                                                    (
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={6}
                                                                align="center"
                                                                style={{ fontWeight: 'bold', fontSize: 14 }}
                                                            >
                                                                {
                                                                    'No se han agregado parte(s)'
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }

                                            </TableBody>     

                                            <TableFooter>

                                                <TableRow>

                                                    <TableCell colSpan={2}>
                                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de expedientes: { rows.length } </Typography>
                                                    </TableCell> 

                                                    <TablePagination
                                                        rowsPerPageOptions={[ ]}
                                                        colSpan={4}
                                                        count={rows.length}                                        
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}                                                                                
                                                        onPageChange={ handleChangePage }
                                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                                        ActionsComponent={ TablePaginationActions }
                                                        labelDisplayedRows={
                                                            ({ from, to, count }) => {
                                                            return '' + from + '-' + to + ' de ' + count
                                                            }
                                                        }
                                                    />
                                                </TableRow>

                                            </TableFooter>                                     

                                        </Table>

                                    </TableContainer>

                                </Grid>                                 

                                <Grid item md={12} xs={12}> </Grid>

                                <Grid item md={6} xs={12} > 
                                    <Paper component="div" sx={{ p: '12px 0px', display: 'flex', alignItems: 'center', width: '100%' }}>                              
                                        
                                        <InputBase sx={{ ml: 1, flex: 1, }} inputProps={{ 'aria-label': 'Buscar persona' }} 
                                            placeholder="Buscar persona"     
                                            value={ nombreCompleto } 
                                            onChange={ () => { } }                           
                                        /> 
                                         
                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalBuscarPersona }
                                            size='small'                                            
                                        >
                                            <SearchIcon />
                                        </Button>          

                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalNuevaPersona }
                                            size='small'                                             
                                        >
                                            <PersonAddIcon />
                                        </Button>                            
                                    </Paper>
                                    {   
                                        errors.nombreCompleto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.nombreCompleto } </Alert> )
                                    }
                                </Grid>                                

                                <Grid item md={3} xs={12} >
                                    <TextField                              
                                        label="Fecha de consulta"
                                        type="datetime-local"              
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}  
                                        value={ fechaConsulta }
                                        onChange={ ( e ) => { setFechaConsulta( e.target.value ); } }                       
                                    />
                                    {   
                                        errors.fechaConsulta && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaConsulta } </Alert> )
                                    }
                                    
                                </Grid>

                                <Grid item md={3} xs={12} >
                                    <TextField                              
                                        label="Fecha de devolución"
                                        type="datetime-local"              
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}            
                                        value={ fechaDevolucion }
                                        onChange={ ( e ) => { setFechaDevolucion( e.target.value ); } }             
                                    />
                                    {   
                                        errors.fechaDevolucion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaDevolucion } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={12} xs={12}> </Grid>

                                <Grid item md={12} xs={12}> 

                                    <Card>
                                        <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                            subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Escanear identificación </Typography> }/>
                                        
                                        <Divider />

                                        <CardContent> 

                                            <Grid container spacing={3} sx={{ mt: 1 }}>
 
                                                <DigitalizarIdentificacionScreen
                                                    handleValidate={ handleValidate } 
                                                    loading={ loading } 
                                                    setLoading={ setLoading }
                                                    Finalizar={ Finalizar }
                                                    idExpedienteConsultado={ idExpedienteConsultado }                                                
                                                    
                                                />

                                            </Grid>

                                        </CardContent>

                                    </Card>

                                </Grid>   

                            </Grid>   
                           
                        </Container>

                    )
                }

            </Box>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

        </>
    )
}
