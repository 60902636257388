import React from 'react';

import { Box, Container } from '@mui/material';

import AppBarMenu from '../ui/AppBarMenu';

export const EstadisticaScreen = () => {
    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                <Container maxWidth="lg" > 

                </Container>

            </Box>

        </>
    )
}
