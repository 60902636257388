import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SearchIcon from '@mui/icons-material/Search';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ObtenerTiposAcuerdos } from '../../actions/admin/catalogs/tipoAcuerdoActions';
import { ObtenerSubTiposAcuerdos } from '../../actions/admin/catalogs/subTipoAcuerdoActions';
import { BuscarAcuerdos } from '../../actions/tribunal/acuerdoActions';

import moment from 'moment';

export const ModalBuscarAcuerdo = ( { openModal, handleCloseModal, seleccionarAcuerdo, expediente, irregular, amparo, exhortoRecibido } ) => {

    const [rows, setRows] = useState( [] );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 3 );

    const [tipoAcuerdo, setTipoAcuerdo] = useState(0);
    const [tipoAcuerdoArray, setTipoAcuerdoArray] = useState( [] );

    const [subTipoAcuerdo, setSubTipoAcuerdo] = useState(0);
    const [subTipoAcuerdoArray, setSubTipoAcuerdoArray] = useState( [] );

    const [fecha, setFecha] = useState( '' );

    const [loadingSearch, setLoadingSearch] = useState( false );

    const CerraModal = () => {

        handleCloseModal();

        setSubTipoAcuerdo( 0 );
        setTipoAcuerdo( 0 );
        setFecha( '' );
        setRows( [] );
    }

    const seleccionar = ( acuerdo ) => {
        
        seleccionarAcuerdo( acuerdo );

        CerraModal();
    }

    const handleBuscarAcuerdos = async () => {

        setLoadingSearch( true );

        const params = {
         
            idExpediente: expediente?.idExpediente ?? 0,
            idIrregular: irregular?.idIrregular ?? 0,
            idAmparo: amparo?.idAmparo ?? 0,
            idExhortoRecibido: exhortoRecibido?.idExhortoRecibido ?? 0,
            idTipoAcuerdo: tipoAcuerdo,
            idSubTipoAcuerdo: subTipoAcuerdo,
            fecha: fecha ? moment( fecha ).format('YYYY-MM-DD') : null

        }

        await BuscarAcuerdos( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    
                    if( response.data ){

                        setTimeout(() => {
                        
                            setLoadingSearch( false );

                            setRows( response.data );

                        }, 1000);                       
                        
                    }
                } 
                else{
                    setLoadingSearch( false );
                }
            }

        });
    }

    useEffect(() => {

        async function Obtener(){    

            await ObtenerTiposAcuerdos().then( response => {

                if(response){
                    if( response.status === 200){

                        setTipoAcuerdoArray( response.data );

                    }
                }

            });

        }

        if( openModal ){
            
            Obtener();
        }

    }, [ openModal ]);

    useEffect(() => {

        async function Obtener(){           

            setSubTipoAcuerdo( 0 );
            setSubTipoAcuerdoArray( [] );

            if(tipoAcuerdo !== 0){   

                await ObtenerSubTiposAcuerdos(tipoAcuerdo).then( response => {
                
                    if(response){

                        if( response.status === 200){
                            setSubTipoAcuerdoArray( response.data );                            
                        }

                    }

                });

            }       

        }

        Obtener();

    }, [ tipoAcuerdo ]);

    return (
        <>
             <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Buscar acuerdo</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}>    
        
                        <Grid item md={6} xs={12} >                            
                           
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoAcuerdo-label">Tipo de acuerdo</InputLabel>
                            
                                <Select
                                    labelId="select-tipoAcuerdo-label"
                                    id="select-tipoAcuerdo"                                            
                                    label="Tipo de Acuerdo"
                                    name="tipoAcuerdo"
                                    value={ tipoAcuerdo }
                                    onChange={ ( e ) => { setTipoAcuerdo( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {          
                                        expediente?.success
                                        ?                              
                                        tipoAcuerdoArray          
                                        .filter( (element ) => { 
                                            return element.idTipoAcuerdo === 1 || element.idTipoAcuerdo === 2 || element.idTipoAcuerdo === 3 || 
                                                    element.idTipoAcuerdo === 4 || element.idTipoAcuerdo === 5 || element.idTipoAcuerdo === 7 || 
                                                    element.idTipoAcuerdo === 8 || element.idTipoAcuerdo === 9 || element.idTipoAcuerdo === 10 ||
                                                    element.idTipoAcuerdo === 15 || element.idTipoAcuerdo === 18 || element.idTipoAcuerdo === 19 ||
                                                    element.idTipoAcuerdo === 20 || element.idTipoAcuerdo === 28;

                                        })                                 
                                        .map((elem)=>(
                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                        ))      
                                        :                                 
                                        null
                                    }  
                                    {          
                                        amparo?.success
                                        ?                              
                                        tipoAcuerdoArray          
                                        .filter( (element ) => { 
                                            return element.idTipoAcuerdo === 6;

                                        })                                 
                                        .map((elem)=>(
                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                        ))      
                                        :                                 
                                        null
                                    }  
                                    {          
                                        irregular?.success
                                        ?                              
                                        tipoAcuerdoArray          
                                        .filter( (element ) => { 
                                            return element.idTipoAcuerdo === 11;

                                        })                                 
                                        .map((elem)=>(
                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                        ))      
                                        :                                 
                                        null
                                    }            
                                    {          
                                        exhortoRecibido?.success
                                        ?                              
                                        tipoAcuerdoArray          
                                        .filter( (element ) => { 
                                            return element.idTipoAcuerdo === 13;

                                        })                                 
                                        .map((elem)=>(
                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                        ))      
                                        :                                 
                                        null
                                    }                                                 
                                </Select>

                            </FormControl>

                        </Grid>  

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-subTipoAcuerdo-label">Subtipo de acuerdo</InputLabel>
                            
                                <Select
                                    labelId="select-subTipoAcuerdo-label"
                                    id="select-subTipoAcuerdo"                                            
                                    label="Subtipo de Acuerdo"
                                    name="subTipoAcuerdo"
                                    value={ subTipoAcuerdo }
                                    onChange={ ( e ) => { setSubTipoAcuerdo( e.target.value ); } }
                                    disabled={ tipoAcuerdo === 0 || subTipoAcuerdoArray.length === 0 }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                      
                                    {                                       
                                        subTipoAcuerdoArray                                        
                                        .map((elem)=>(
                                            <MenuItem key={elem.idSubTipoAcuerdo} value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                        ))                                        
                                    }                                              
                                   
                                </Select>

                            </FormControl>   
                                                   
                        </Grid>    

                        <Grid item md={6} xs={12} >

                            <TextField                              
                                label="Fecha"
                                type="date"              
                                fullWidth
                                value={ fecha }    
                                onChange={ ( e ) => { setFecha( e.target.value ); } } 
                                inputProps={{
                                    max: moment( new Date() ).format('YYYY-MM-DD')
                                }}                             
                                InputLabelProps={{
                                    shrink: true,
                                }}              
                            />

                        </Grid>

                        <Grid item md={6} xs={12} style={{ marginTop: '1em' }} >
                            
                            <LoadingButton 
                                variant="contained"                                
                                startIcon={ <SearchIcon /> }
                                loading={ loadingSearch }
                                disabled={ tipoAcuerdo === 0 && fecha === '' }
                                onClick={ handleBuscarAcuerdos }
                            >
                                Buscar
                            </LoadingButton>

                        </Grid>           

                        <Grid item md={12} xs={12} sx={{ mt: 2 }}>

                            <Typography variant="h6"> Resultado de la busqueda </Typography>

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'20%', fontWeight: 'bold' }}>TIPO DE ACUERDO</TableCell>
                                            <TableCell style={{ width:'20%', fontWeight: 'bold' }}>DESCRIPCIÓN</TableCell>
                                            <TableCell style={{ width:'15%', fontWeight: 'bold' }}>FECHA</TableCell>
                                            <TableCell style={{ width:'10%' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( (row) => (

                                            <TableRow key={ row.idAcuerdo }>
                                                <TableCell> { row.tipoAcuerdo } </TableCell>
                                                <TableCell> { row.nombre } </TableCell>
                                                <TableCell> { row.fecha } </TableCell>
                                                <TableCell> 
                                                    <Button 
                                                        variant="contained"
                                                        endIcon={ <PlaylistAddCheckIcon /> }
                                                        size="small"
                                                        onClick={ () => { seleccionar( row ); } }
                                                    >
                                                        Seleccionar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={9}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        {
                                                            loadingSearch
                                                            ?
                                                                'Buscando ....'
                                                            :
                                                                'No se encontraron resultados'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={4}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                      return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>
                            
                            </TableContainer>

                        </Grid>           
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ CerraModal }>Cancelar</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
