import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { types } from '../../../../types/types';

import { GenerarCaratula } from '../../../../actions/recepcion/exhortoRecibidoActions';
import { ModalEditarPartes } from '../modalsExhortoRecibido/ModalEditarPartes';

export const DetalleExhortoRecibidoScreen = ({ edit }) => {

    const dispatch = useDispatch();

    const { tribunal } = useSelector( state => state.auth );
    const { acuerdo, exhortoRecibido } = useSelector( state => state.acuerdo );

    const [loading, setLoading] = useState( false );
    const [openCaratula, setOpenCaratula] = useState( false );

    const [loadingConfirmar, setLoadingConfirmar] = useState( false );
    const [openConfirmar, setOpenConfirmar] = useState( false );

    const [open, setOpen] = useState( false );

    const handleOpenCaratula = async () => {

        setLoading( true );

        await GenerarCaratula( exhortoRecibido?.idExhortoRecibido ?? 0 ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){                     

                        dispatch({

                            type: types.actualizarExhortoRecibidoAcuerdo,
                            payload: {                                
                                ...exhortoRecibido,
                                caratula: response.data.caratula,
                            }
                
                        });
                        
                        setLoading( false );

                        setOpenCaratula( true );

                    }

                }

            }

        });    
        
    }

    const eliminarCaratula = () => {

        setLoadingConfirmar( true );

        setTimeout(() => {

            dispatch({

                type: types.actualizarExhortoRecibidoAcuerdo,
                payload: {                                
                    ...exhortoRecibido,
                    caratula: '',
                }
    
            });
            
            setLoadingConfirmar( false );

            setOpenConfirmar( false );

        }, 1000);  

    }

    return (
        <>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 3 }} >

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={2} md={4} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Número de exhorto:
                        </Typography>
                    </Grid>
                    <Grid item lg={10} md={8} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { exhortoRecibido?.numeroExhorto }
                        </Typography>
                    </Grid>
                </Grid>  

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={2} md={4} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Número de origen:
                        </Typography>
                    </Grid>
                    <Grid item lg={10} md={8} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { exhortoRecibido?.numeroExhortoOrigen }
                        </Typography>
                    </Grid>
                </Grid>  

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={2} md={4} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Autoridad del exhorto:
                        </Typography>
                    </Grid>
                    <Grid item lg={10} md={8} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { exhortoRecibido?.autoridadExhorto }
                        </Typography>
                    </Grid>
                </Grid>                 

                <Grid container sx={{ mt: 1, mb: 1 }}>

                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Actor(es):
                        </Typography>
                    </Grid>

                    <Grid item md={8} xs={6} >
                        <List disablePadding>
                            {
                                exhortoRecibido?.actor?.map( ( element, i  ) => (                                    
                                    <ListItem key={ i } disablePadding>                              
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                            { element }
                                        </Typography>                               
                                    </ListItem>
                                ))
                            }                       
                        </List>
                    </Grid>

                    {
                        edit
                        &&                    
                        <Grid item md={2} xs={2} sx={{ textAlign: 'right' }} >
                            <IconButton
                                color='primary'
                                onClick={ () => { setOpen( true ); } }
                            >
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Grid>
                    }

                </Grid>   

                <Grid container sx={{ mt: 1, mb: 1 }}>

                    <Grid item md={2} xs={6}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Demandado(s):
                        </Typography>
                    </Grid>

                    <Grid item md={10} xs={6} >
                        <List disablePadding>
                            {
                                exhortoRecibido?.demandado?.map( ( element, i  ) => (                                    
                                    <ListItem key={ i } disablePadding>                              
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                            { element }
                                        </Typography>                               
                                    </ListItem>
                                ))
                            }                             
                        </List>
                    </Grid>

                </Grid>  

                {
                    tribunal
                    &&
                        <Grid container sx={{ mt: 3, mb: 1 }}>
                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >  

                            {
                                exhortoRecibido?.caratula === ""
                                ?
                                <LoadingButton 
                                    variant="contained" 
                                    startIcon={ <FileCopyIcon /> } 
                                    onClick={ handleOpenCaratula } 
                                    loading={ loading }
                                >
                                    Generar carátula
                                </LoadingButton>
                            :
                                <>
                                    <Button 
                                        variant="contained" 
                                        startIcon={ <PrintIcon /> } 
                                        onClick={ () => { setOpenCaratula( true ); } } 
                                    >
                                        Visualizar carátula
                                    </Button>    

                                    {
                                        edit
                                        && 
                                        <IconButton
                                            color='primary'
                                            onClick={ () => { setOpenConfirmar( true ); } }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                        
                                </>                 
                            }     

                            </Grid>
                        </Grid>   
                }                

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Exhorto:
                        </Typography>
                    </Grid>
                    
                    <Grid item lg={12} md={12} xs={12}>

                        {
                            acuerdo.exhortoDigitalizado
                            ?                        
                            <iframe 
                                title='iframe  Demanda o Escrito Inicial'
                                style={{ width: '100%', height: '400px' }} 
                                src={ acuerdo.exhortoDigitalizado }                                
                            />
                            :
                            null
                        }

                    </Grid>
                </Grid>                    

            </Box>

            <Dialog open={ openCaratula } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               exhortoRecibido.caratula
                               ?
                               <iframe title='caratula' src={ exhortoRecibido.caratula } height="500px" width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró la carátula
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenCaratula( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

            <Dialog open={ openConfirmar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar la carátula?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmar( false ); } }> Cancelar </Button>
                    <LoadingButton onClick={ eliminarCaratula } variant='contained' loading={ loadingConfirmar } > Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>

            <ModalEditarPartes
                Id={ exhortoRecibido?.idExhortoRecibido ?? 0 }
                open={ open }
                setOpen={ setOpen }            
            />

        </>
    )
}
