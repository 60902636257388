import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, Card, CardContent, List, ListItem } from '@mui/material';

import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';

import { types } from '../../../types/types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import AppBarMenu from '../../ui/AppBarMenu';

import { Detalle, PublicarListaEstrados, FinalizarListaEstrados } from '../../../actions/tribunal/listaEstradosActions';
import { InformacionSeguimientoSimple } from '../../ui/InformacionSeguimiento';

export const GenerarListaEstradosScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [detalleEstrados, setDetalleEstrados] = useState( {} );

    const [rows, setRows] = useState( [] );

    const [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage ] = usePages(10);

    const [loading, setLoading] = useState(false);

    const [habiliarFinalizar, setHabiliarFinalizar] = useState(true);

    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [openConfirmacionFinalizar, setOpenConfirmacionFinalizar] = useState(false);

    const [openListaAcuerdos, setOpenListaAcuerdos] = useState(false);

    const [loadingGenerar, setLoadingGenerar] = useState( false ); 
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );  

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [publicarTodo, setPublicarTodo] = useState(false);

    const [alert, setAlert] = useState( {  } );

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleOpenConfirmacionFinalizar = () => {   
        setOpenConfirmacionFinalizar(true);
    };

    const handleCloseConfirmacionFinalizar = () => {
        setOpenConfirmacionFinalizar(false);
    };

    const handlePublicarTodo = ( checked ) => {

        setRows( rows?.map( ( element ) => { return { ...element, selected: checked }; }) );

        setPublicarTodo( checked );

    };

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }
    
    const Finalizar = async () => {

        setLoadingFinalizar( true );

        const params = {
            'idListaEstrados' : Id
        };

       await FinalizarListaEstrados( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacionFinalizar( false );

                        setLoading( true );

                        setLoadingFinalizar( false );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 

                        setOpenConfirmacionFinalizar( false );
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        }); 

    }

    const GenerarLista = async () => {

        setLoadingGenerar( true );

        let arrayEstrados = [];

        rows.forEach(element => {

            if(element.selected) {
                
                arrayEstrados.push( element );
            }
            
        });    
        
        const params = {

            idListaEstrados : Id,
            notificaciones : arrayEstrados

        };

        await PublicarListaEstrados( params ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){
        
                        setDetalleEstrados( response?.data ?? {} );
                        
                        setHabiliarFinalizar(false);

                        setLoadingGenerar( false );

                        setOpenConfirmacion(false);

                        setOpenListaAcuerdos(true);
                        
                    }

                }

            }

        });   

    }

    const handleChangeCheckBox = ( { target }, id ) => {

        const newArray = rows.map( ( element ) =>{

            if( element.id === id ){
                element.selected = target.checked;
            }   
            
            return element;
        });

        setRows(newArray);
    }

    useEffect(() => {

        async function Obtener(){
            
            const params = {
                'idListaEstrados' : Id,
            };

            dispatch({ type: types.openLoading });

            await Detalle( params ).then( response => {

                if( response ){

                    if( response.data ){  

                        setDetalleEstrados( response.data );

                        setTimeout(() => {
                            
                            if(response.data.listaEstrados !== ''){
                                setHabiliarFinalizar(false);
                            }                            

                            setRows( response?.data?.notificacionDetalle?.map( ( element ) => { return { ...element, selected: false }; }) ?? [] );
                                                        
                            dispatch({ type: types.closeLoading });   

                            if( response.data.idEstatus !== 67 ){

                                setLoading( true );
                                
                            }
                            
                            setAjaxLoading( true );            

                        }, 1200);                       

                    }

                }

            });
        }     
        
        Obtener();

    }, [ Id, dispatch ]);

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '7em', minHeight: '100%', py: 3 }}>  

            {
                loading 
                ?                            
                (
                    <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                        <Container maxWidth="lg" >                        

                            <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                
                                <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                
                                    <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                        Se finalizó la actividad
                                    </Typography>

                                    <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                        No se tienen actividades pendientes para este trámite
                                    </Typography>

                                </CardContent>

                            </Card>                   
                                                    
                        </Container>                    

                    </Box>  
                ) 
                
                :

                (                        
                    <Container maxWidth="lg" sx={{ mt: 1, pb: 2 }} component={ Paper } >
                            
                        <Grid container spacing={3} >

                            <Grid  item md={12} xs={12}>                               
                                <InformacionSeguimientoSimple noSeguimiento={ 'GLE-' + detalleEstrados.idListaEstrados  } />
                            </Grid>

                            <Grid item md={12} xs={12}>

                                <TableContainer>

                                    <Table>

                                        <TableHead>

                                            <TableRow>
                                                <TableCell style={{ width: '10%', fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>ACTOR(ES)</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>DEMANDADO(S)</TableCell>
                                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>SE NOTIFICA A</TableCell>
                                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                                                    PUBLICAR
                                                    <Checkbox checked={ publicarTodo } onChange={ ( e ) => handlePublicarTodo( e.target.checked ) }/>
                                                </TableCell>
                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                (
                                                    rowsPerPage > 0
                                                    ?
                                                    rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                    :
                                                    rows
                                                ).map( ( row ) => (

                                                    <TableRow key={ row.id } >
                                                        
                                                        <TableCell> { row.referencia } </TableCell>                                                            
                                                        
                                                        <TableCell> 

                                                            <List disablePadding>
                                                                {
                                                                    row?.actores?.map( ( elem, i ) =>(
                                                                        <ListItem disableGutters key={ i }>
                                                                            <Typography variant='subtitle2'>{ elem }</Typography>
                                                                        </ListItem>
                                                                    ))
                                                                }                                                                
                                                            </List>

                                                        </TableCell>
                                                        
                                                        <TableCell> 
                                                            <List disablePadding>
                                                                {
                                                                    row?.demandados?.map( ( elem, i ) =>(
                                                                        <ListItem disableGutters key={ i }>
                                                                            <Typography variant='subtitle2'>{ elem }</Typography>
                                                                        </ListItem>
                                                                    ))
                                                                }                                                                
                                                            </List>
                                                        </TableCell>

                                                        <TableCell> { row.tipoParte.toUpperCase() } </TableCell>
                                                        
                                                        <TableCell> 
                                                            {
                                                                !row.publicar && detalleEstrados.listaEstrados === ""
                                                                ?
                                                                    <Checkbox checked={ row.selected ?? false } onChange={ ( e ) => { handleChangeCheckBox( e, row.id ) } }/>
                                                                :
                                                                ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>

                                                ))
                                            }

                                            {
                                                detalleEstrados.length === 0 && 
                                                (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={5}
                                                            align="center"
                                                            style={{ fontWeight: 'bold', fontSize: 15 }}
                                                        >
                                                            No se encontraron resultados
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>
                                                <TablePagination 
                                                    colSpan={5}
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    count={ rows.length }
                                                    rowsPerPage={ rowsPerPage }
                                                    page={ page }
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }

                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }                                                                                       
                                                />
                                            </TableRow>

                                        </TableFooter>

                                    </Table>
                                    
                                </TableContainer>

                            </Grid>

                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}  > 
                            {
                                detalleEstrados.listaEstrados === ""
                                ?
                                    <Button variant="contained" onClick={ handleOpenConfirmacion } startIcon={ <PrintIcon /> } disabled={ rows.filter( ( element ) => { return element.selected === true; } ).length === 0 }> Generar lista de estrados </Button>
                                :
                                    <Button variant="contained" onClick={  () => { setOpenListaAcuerdos( true ); } } startIcon={ <PrintIcon /> } > Visualizar lista de estrados </Button>                 
                            }

                            </Grid>    

                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                <LoadingButton variant="contained" onClick={ handleOpenConfirmacionFinalizar } endIcon={ <CheckCircleIcon /> } disabled={ habiliarFinalizar  }> Finalizar </LoadingButton>
                            </Grid>    

                        </Grid>

                    </Container>
                
                )

            }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea generar la lista de estrados?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ GenerarLista } 
                            variant='contained' 
                            loading={ loadingGenerar }
                        > 
                        Aceptar 
                        </LoadingButton>

                    </DialogActions>

            </Dialog>

            <Dialog open={ openConfirmacionFinalizar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la publicación de la lista de estrados?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacionFinalizar }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                    Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

            <Dialog open={ openListaAcuerdos } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
            
                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
    
                            {
                                detalleEstrados.listaEstrados || detalleEstrados.listaEstradosFirmado
                                ?
                                    detalleEstrados.listaEstradosFirmado
                                        ?
                                        <iframe title='lista' src={ detalleEstrados.listaEstradosFirmado } height="500px" width="100%"></iframe>
                                        :
                                        <iframe title='lista' src={"data:application/pdf;base64," +  detalleEstrados.listaEstrados } height="500px" width="100%"></iframe>
                                :
                                <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                    No se encontró la lista de acuerdos
                                </Typography>
                            }

                        </Grid>
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenListaAcuerdos( false ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog> 
            
        </>
    )
}
