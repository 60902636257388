import React, { useEffect, useState } from 'react';

import { Alert, Autocomplete, Box, Button, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import HomeIcon from '@mui/icons-material/Home';

import AppBarMenu from '../../ui/AppBarMenu';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { catalogsData } from '../../../data/catalogsData';

import { ActualizarAutoridad, AgregarAutoridad, AgregarDomicilio, Autoridades } from '../../../actions/admin/catalogs/autoridadActions';
import { ObtenerEstados } from '../../../actions/admin/catalogs/estadoActions';
import { ObtenerMunicipios } from '../../../actions/admin/catalogs/municipioActions';
//import { ObtenerAsentamientos, ObtenerCodigosPostales } from '../../../actions/admin/catalogs/asentamientoActions';
import { ObtenerTiposDomicilios } from '../../../actions/admin/catalogs/tipoDomicilioActions';
import { ObtenerMateria } from '../../../actions/admin/catalogs/materiaActions';
import { ObtenerDistritoJudicial } from '../../../actions/admin/catalogs/distritoJudicialActions';
import { ObtenerAsentamientosMunicipio } from '../../../actions/admin/catalogs/asentamientoActions';

export const AutoridadScreen = ( { history } ) => {

    const { location } = history;
    const { pathname } = location;

    const [errors, setErrors] = useState({ });

    const desc = catalogsData.filter( catalog => catalog.path === pathname);

    const [rows, setRows] = useState( [ ] );

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [] );

    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [] );
    
    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );

    //const [codigoPostal, setCodigoPostal] = useState( 0 );
    //const [codigoPostalArray, setCodigoPostalArray] = useState( [] );

    const [asentamiento, setAsentamiento] = useState( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState( [] );
    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const [tipoDomicilio, setTipoDomicilio] = useState( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState( [] );
    
    const [descripcionDomicilio, setDescripcionDomicilio] = useState( '' );
    const [numero, setNumero] = useState( '' );
    const [referencia, setReferencia] = useState( '' );    

    const [openModalDomicilio, setOpenModalDomicilio] = useState( false );
    const [domicilio, setDomicilio] = useState( { } );    

    const [referenciaExpVirtual, setReferenciaExpVirtual] = useState( 0 );   

    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idAutoridad === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );      
        
        const autoridad = rows.filter( function(row){ return row.idAutoridad === id; } );
        cambiarEstatus( autoridad[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const handleCloseModalDomicilio = () => {
       
        setOpenModalDomicilio(false);
        setEstado(0);
        setMunicipio(0);
        //setCodigoPostal(0);
        setAsentamiento(0);
        setTipoDomicilio(0);
        setDescripcionDomicilio('');
        setNumero('');
        setReferencia('');
        setDomicilio({});
        setErrors( { } );

        setDistritoJudicial( 0 );
        setMateria( 0 );

        setAsentamientoArray([]);
        //setCodigoPostalArray([]);
        setMunicipioArray([]);
        setAutocompleteValue( null );
    }

    const Regresar = () => {
        history.push("/Admin/Catalogs");
    }

    const cambiarEstatus = async ( { idAutoridad, distritoJudicial, materia, descripcion, activo } ) => {

        const params = {
            idAutoridad,
            descripcion,
            idDistritoJudicial: distritoJudicial.idDistritoJudicial,
            idMateria: materia.idMateria,
            activo,
        };

        await ActualizarAutoridad( params );
    }

    const AgregarRegistro = () => {
        
        setDistritoJudicial( 0 );
        setMateria( 0 );
        setDescripcion( '' );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );
        setReferenciaExpVirtual( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, distritoJudicial, materia, descripcion, referencia ) => {
        
        setDistritoJudicial( distritoJudicial );
        setMateria( materia );
        setDescripcion( descripcion );
        setReferenciaExpVirtual( referencia ?? 0 );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        if( opcion === 'agregar' ){
            const params = {
                idAutoridad: 0,
                descripcion: descripcion.trim(),
                idDistritoJudicial: distritoJudicial,
                idMateria: materia,
                referencia: referenciaExpVirtual,
                activo: true,
            };

            await AgregarAutoridad( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            setRows([ 
                                ...rows,  
                                {
                                    idAutoridad: response.data.idAutoridad,
                                    descripcion: descripcion,
                                    distritoJudicial: response.data.distritoJudicial,
                                    materia: response.data.materia,
                                    referencia: referenciaExpVirtual,
                                    activo: true
                                }
                            ]);  

                            handleCloseModal();
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const autoridad = rows.filter( function(row){ return row.idAutoridad === idReferencia; } )[0];

            const params = {
                idAutoridad: idReferencia,
                descripcion: descripcion.trim(),
                idDistritoJudicial: distritoJudicial,
                idMateria: materia,
                referencia: referenciaExpVirtual,
                activo: autoridad.activo,
            };
    
            await ActualizarAutoridad( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idAutoridad === idReferencia){ 
                                row.descripcion = descripcion; 
                                row.distritoJudicial = response.data.distritoJudicial;
                                row.materia = response.data.materia;
                                row.referencia = response.data.referencia;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModal();
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( distritoJudicial === 0 ){
            valid = false;
            errores.distritoJudicial = 'Debes seleccionar el distrito judicial';
        }     

        if( materia === 0 ){
            valid = false;
            errores.materia = 'Debes seleccionar la materia';
        }     
        
        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    const GuardarDomicilio = async () => {

        const valid = validateFielsDomicilio();

        if( !valid ){

            return false;
        }

        const params = {

            descripcion: descripcionDomicilio,
            numero: numero,
            referencia: referencia,
            idTipoDomicilio: tipoDomicilio,
            idAsentamiento: asentamiento,
            idAutoridad: idReferencia,
            idDomicilio: domicilio.idDomicilio

        }

        await AgregarDomicilio(params).then(response => { 
            if (response) {
                if (response.status === 200) {
                    if( response.data ){
                        const newRows = rows.map( (row) => { 
                            if(row.idAutoridad === idReferencia){ 
                                row.domicilio = response.data.domicilio; 
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        
                        handleCloseModalDomicilio();
                    } 

                }
                
            }
        });

    }

    const validateFielsDomicilio = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( estado === 0 ){
            valid = false;
            errores.estado = 'Debes seleccionar un estado';
        }
        else if( municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }
        /*else if( codigoPostal === 0 ){
            valid = false;
            errores.codigoPostal = 'Debes seleccionar un código postal';
        }*/
        else if( asentamiento === 0 ){
            valid = false;
            errores.asentamiento = 'Debes seleccionar un asentamiento';
        }

        if( tipoDomicilio === 0 ){
            valid = false;
            errores.tipoDomicilio = 'Debes seleccionar un tipo de domicilio';
        }

        if( descripcionDomicilio === '' ){
            valid = false;
            errores.descripcionDomicilio = 'Debes escribir la descripción';
        }   

        /*if( numero === '' || numero === 0 ){
            valid = false;
            errores.numero = 'Debes escribir el número int./ext.';
        }*/
        
        setErrors( errores );
        return valid;
    }
    
    const editarDomicilio = (idAutoridad, domicilio) => {
        setOpenModalDomicilio(true);
        setIdReferencia(idAutoridad);
        if( domicilio ){
            setDomicilio(domicilio);

        }
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await Autoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setRows( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerDistritoJudicial().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setDistritoJudicialArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerMateria().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setMateriaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    //Obtener estados
    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerEstados().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setEstadoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);
    //Obtener municipios
    useEffect(() => {
        
        async function Obtener(){     

            setMunicipio( 0 );

            if( estado !== 0 ){       
                await ObtenerMunicipios( estado ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setMunicipioArray( response.data );   
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ estado ]);

    //Obtener codigos postales
    /*
    useEffect(() => {
        
        async function Obtener(){     

            setCodigoPostal( 0 );

            if( municipio !== 0 ){       
                await ObtenerCodigosPostales( municipio ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setCodigoPostalArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ municipio ]);
    */

    //Obtener asentamientos
    /*
    useEffect(() => {
        
        async function Obtener(){     

            setAsentamiento( 0 );

            if( codigoPostal !== 0 ){       

                const params = {
                    'idMunicipio': municipio,
                    'codigoPostal' : codigoPostal
                };

                await ObtenerAsentamientos( params ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setAsentamientoArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ municipio, codigoPostal ]);
    */

     //Obtener tipo domicilios
     useEffect(() => {
        
        async function Obtener(){            
            await ObtenerTiposDomicilios().then( response => {
                if(response){
                    if( response.status === 200){
                        setTipoDomicilioArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){     

            setAsentamiento( 0 );
            setAsentamientoArray( [] );
            setAutocompleteValue( null );

            if( municipio !== 0 ){   

                await ObtenerAsentamientosMunicipio( municipio ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setAsentamientoArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ municipio ]);

    //Obtener domicilio de autoridad
    useEffect(() => {

        if( Object.keys( domicilio ).length !== 0 ){

            setEstado( domicilio.asentamiento.idEstado ?? 0 );

            setTipoDomicilio(domicilio.idTipoDomicilio ?? 0 );
            setDescripcionDomicilio( domicilio.descripcion ?? '' );
            setNumero( domicilio.numero === 0 ? '' : domicilio.numero );
            setReferencia( domicilio.referencia ?? '' );

        }

    }, [ domicilio ]); 

    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0 ){
        
            setMunicipio( 0 );

            if( municipioArray.length > 0 && domicilio.asentamiento.idEstado === estado){
                setMunicipio( domicilio.asentamiento.idMunicipio ?? 0 );
            }

        }


    }, [ domicilio, estado, municipioArray ]);

    /*
    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0 ){
        
            setCodigoPostal( 0 );

            if( codigoPostalArray.length > 0 && domicilio.asentamiento.idMunicipio === municipio){
                setCodigoPostal( domicilio.asentamiento.codigoPostal ?? 0 );
            }

        }


    }, [ domicilio, municipio, codigoPostalArray ]);
    */

    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0 ){
        
            setAsentamiento( 0 );

            if( asentamientoArray.length > 0 ){
                setAsentamiento( domicilio.idAsentamiento ?? 0 );

                const asent = asentamientoArray.find( ( e ) => e.idAsentamiento === domicilio?.idAsentamiento ?? 0 );

                if( asent ){
                    setAutocompleteValue( asent );
                }
            }
        }

    }, [ domicilio, asentamientoArray ]);

    return (
        <>
            <AppBarMenu />            

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  

                <Container maxWidth="lg">   

                    <Grid container spacing={3} >  

                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>  
                            <Button type="button" startIcon={ <ArrowBackIcon /> } variant="contained" sx={{ mt: 1, textAlign: 'left' }} onClick={ Regresar } > 
                                Regresar
                            </Button> 
                        </Grid>
                       
                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>
                           
                        <Grid item xs={12} md={12}> 

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                        {
                                            desc.length !== 0 ? desc[0].descripcion : ''
                                        }
                                    </Typography>
                                </Toolbar>

                                <TableContainer>
                                    <Table sx={{ minWidth: 500 }} >
                                        <TableHead>
                                            <TableRow>        
                                                <TableCell style={{ width:'5%' }}> ID </TableCell>
                                                <TableCell style={{ width:'35%' }}> DESCRIPCIÓN </TableCell>
                                                <TableCell style={{ width:'10%' }}> MATERIA </TableCell>
                                                <TableCell style={{ width:'15%' }}> DISTRITO JUDICIAL </TableCell>
                                                <TableCell style={{ width:'5%' }}> REFERENCIA </TableCell>
                                                <TableCell style={{ width:'5%' }}> DOMICILIO </TableCell>
                                                <TableCell style={{ width:'5%' }}> HABILITADO </TableCell>
                                                <TableCell style={{ width:'5%' }}> EDITAR </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                        {
                                        (rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <TableRow key={ row.idAutoridad} >
                                            
                                                <TableCell sx={{ fontWeight: 'bold' }}>
                                                    { row.idAutoridad }
                                                </TableCell>

                                                <TableCell>
                                                    { row.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.materia.descripcion }
                                                </TableCell>

                                                <TableCell>
                                                    { row.distritoJudicial.descripcion }
                                                </TableCell>

                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    { row.referencia }
                                                </TableCell>

                                                <TableCell>
                                                    <Button
                                                        color='primary'
                                                        onClick={ () => { editarDomicilio(row.idAutoridad, row.domicilio); } }
                                                    >
                                                        <HomeIcon />
                                                    </Button>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {
                                                        row.activo 
                                                        ? 
                                                        (
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idAutoridad, e.target.checked) }
                                                            />
                                                        ) 
                                                        :                                           
                                                        ( 
                                                            <Switch 
                                                                inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                checked={ row.activo } 
                                                                onChange={ ( e ) => handleChangeSwitch( row.idAutoridad, e.target.checked) }
                                                            />
                                                        )
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton 
                                                            color="primary" 
                                                            aria-label="edit" 
                                                            component="span" 
                                                            onClick={ () => EditarRegistro( row.idAutoridad, row.distritoJudicial.idDistritoJudicial, row.materia.idMateria, row.descripcion, row.referencia ) }
                                                        >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        { rows.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={7} align="center"> 
                                                No se encontraron resultados
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[ ]}
                                                    colSpan={8}
                                                    count={rows.length}                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            
                            </Paper>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
            
                <DialogContent>

                    <Grid container spacing={3} sx={{ mt: 1 }} >        

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-distritoJudicial-label">Distrito Judicial</InputLabel>
                            
                                <Select
                                    labelId="select-distritoJudicial-label"
                                    id="select-distritoJudicial"                                            
                                    label="Distrito Judicial"
                                    name="distritoJudicial"
                                    value={ distritoJudicial }
                                    onChange={ ( e ) => { setDistritoJudicial( e.target.value ) } }
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                    {
                                        distritoJudicialArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idDistritoJudicial } value={ elem.idDistritoJudicial }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.distritoJudicial && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.distritoJudicial } </Alert> )
                            }

                        </Grid>       

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-materia-label">Materia</InputLabel>
                            
                                <Select
                                    labelId="select-materia-label"
                                    id="select-materia"                                            
                                    label="Materia"
                                    name="materia"
                                    value={ materia }
                                    onChange={ ( e ) => { setMateria( e.target.value ) } }
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                    {
                                        materiaArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idMateria } value={ elem.idMateria }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.materia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.materia } </Alert> )
                            }

                        </Grid>     

                        <Grid item md={12} xs={12} > 

                            <TextField
                                label='Referencia (Autoridad en Poder en Línea)'
                                variant="outlined"   
                                type='number'   
                                autoFocus                                        
                                fullWidth
                                value={ referenciaExpVirtual }
                                onChange={ ( e ) => { setReferenciaExpVirtual( e.target.value ) } }
                            />

                        </Grid>            

                        <Grid item md={12} xs={12} > 

                            <TextField
                                label={ desc.length !== 0 ? 'Descripción - ' + desc[0].descripcion : '' }
                                variant="outlined"      
                                autoFocus                          
                                multiline
                                rows={4}                                        
                                fullWidth
                                value={ descripcion }
                                onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                            />

                            {   
                                errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                            }

                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }> Cancelar </Button>
                    <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

            </Dialog>

            <Dialog open={ openModalDomicilio } maxWidth='sm' fullWidth={true}>       
                
                <DialogContent>                    
                    
                    <Grid container spacing={3} sx={{ mt: 1 }} >                                

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-estado-label">Estado</InputLabel>
                            
                                <Select
                                    labelId="select-estado-label"
                                    id="select-estado"                                            
                                    label="Estado"
                                    name="estado"
                                    value={ estado }
                                    onChange={ ( e ) => { setEstado( e.target.value ) } }
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                    {
                                        estadoArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.estado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.estado } </Alert> )
                            }

                        </Grid>

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-municipio-label">Municipio</InputLabel>
                            
                                <Select
                                    labelId="select-municipio-label"
                                    id="select-municipio"                                            
                                    label="Municipio"
                                    name="municipio"
                                    value={ municipio }
                                    onChange={ ( e ) => { setMunicipio( e.target.value ) } }
                                    disabled={ estado === 0 }
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                    {
                                        municipioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.municipio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.municipio } </Alert> )
                            }

                        </Grid>

                        {/* 
                            <Grid item md={6} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>
                                
                                    <Select
                                        labelId="select-codigoPostal-label"
                                        id="select-codigoPostal"                                            
                                        label="Código Postal"
                                        name="codigoPostal"
                                        value={ codigoPostal }
                                        onChange={ ( e ) => { setCodigoPostal( e.target.value ) } }
                                        disabled={ municipio === 0 }
                                    >
                                        <MenuItem value={0}>Selecciona una opción</MenuItem>
                                        {
                                            codigoPostalArray.map( ( elem ) => (
                                                <MenuItem key={ elem.codigoPostal } value={ elem.codigoPostal }> { elem.codigoPostal } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>

                                {   
                                    errors.codigoPostal && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.codigoPostal } </Alert> )
                                }

                            </Grid> 
                        */}

                        {/* 
                            <Grid item md={6} xs={12} >                            
                                <FormControl fullWidth>
                                    <InputLabel id="select-asentamiento-label">Asentamiento</InputLabel>
                                
                                    <Select
                                        labelId="select-asentamiento-label"
                                        id="select-asentamiento"                                            
                                        label="Asentamiento"
                                        name="asentamiento"
                                        value={ asentamiento }
                                        onChange={ ( e ) => { setAsentamiento( e.target.value ) } }
                                        disabled={ codigoPostal === 0 }
                                    >
                                        <MenuItem value={0}>Selecciona una opción</MenuItem>
                                        {
                                            asentamientoArray.map( ( elem ) => (
                                                <MenuItem key={ elem.idAsentamiento } value={ elem.idAsentamiento }> { elem.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>

                                {   
                                    errors.asentamiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.asentamiento } </Alert> )
                                }

                            </Grid> 
                        */}

                        <Grid item md={12} xs={12} >                            
                            
                            <FormControl fullWidth>
                            
                                <Autocomplete       
                                    disabled={ municipio === 0 }         
                                    disablePortal 
                                    fullWidth              
                                    value={ autocompleteValue }   
                                    options={ asentamientoArray }
                                    onChange={ (event, value) => { setAsentamiento( value?.idAsentamiento ?? 0 ); setAutocompleteValue( value ); } }                        
                                    getOptionLabel={ (option) => option.asentamiento}
                                    renderOption={ ( props, option ) => (
                                        <li { ...props } key={ option.idAsentamiento} >                         
                                            <strong>{ option.asentamiento }</strong>&nbsp;(C.P. { option.codigoPostal }) - { option.tipo }
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Asentamiento" placeholder='Seleccione una opción' InputLabelProps={{ shrink: true }} />}         
                                />      

                            </FormControl>
                            {   
                                errors.asentamiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.asentamiento } </Alert> )
                            }

                        </Grid>

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoDomicilio-label">Tipo Domicilio</InputLabel>
                            
                                <Select
                                    labelId="select-tipoDomicilio-label"
                                    id="select-tipoDomicilio"                                            
                                    label="Tipo Domicilio"
                                    name="tipoDomicilio"
                                    value={ tipoDomicilio }
                                    onChange={ ( e ) => { setTipoDomicilio( e.target.value ) } }
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                    {
                                        tipoDomicilioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idTipoDomicilio } value={ elem.idTipoDomicilio }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.tipoDomicilio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.tipoDomicilio } </Alert> )
                            }

                        </Grid>

                        <Grid item md={7} xs={12} >
                            <TextField
                                fullWidth
                                label="Descripcion"
                                name="descripcion"
                                variant="outlined"                                        
                                value={ descripcionDomicilio }                                
                                onChange={ ( e ) => { setDescripcionDomicilio( e.target.value ) } }
                            />

                            {   
                                errors.descripcionDomicilio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.descripcionDomicilio } </Alert> )
                            }

                        </Grid>

                        <Grid item md={5} xs={12} >
                            <TextField
                                fullWidth
                                label="Número Int./Ext."
                                name="numero"
                                variant="outlined"   
                                type="number"                                     
                                value={ numero }                                
                                onChange={ ( e ) => { setNumero( e.target.value ) } }
                            />
                            {   
                                errors.numero && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.numero } </Alert> )
                            }
                        </Grid>

                        <Grid item md={12} xs={12} >
                            <TextField
                                fullWidth
                                label="Referencia"
                                name="referencia"
                                variant="outlined"        
                                multiline
                                rows={4}                                        
                                InputLabelProps={{
                                    shrink: true
                                }}                               
                                value={ referencia }                                
                                onChange={ ( e ) => { setReferencia( e.target.value ) } }
                            />
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModalDomicilio }> Cancelar </Button>
                    <Button onClick={ GuardarDomicilio } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                </DialogActions>

            </Dialog>

        </>
    )
}
