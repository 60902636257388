import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, InputLabel, LinearProgress, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@mui/material';

import moment from 'moment';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { ModalNuevaPersona } from './ModalNuevaPersona';
import { ModalBuscarPersona } from './ModalBuscarPersona';
import { ObtenerTipoTramite } from '../../actions/admin/catalogs/tipotramiteActions';
import { ObtenerPonencias } from '../../actions/admin/catalogs/ponenciaActions';
import { NuevaRecepcion } from '../../actions/recepcion/recepcionActions';

export const ModalNuevaRecepcion = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const { tribunal } = useSelector( state => state.auth );

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const [presentante, setPresentante] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const { idPersona , nombreCompleto } = presentante;
    
    const [tipoTramite, setTipoTramite] = useState( 0 );
    const [fechaReloj, setFechaReloj] = useState( DateNow );
    const [motivoAsignacion, setMotivoAsignacion] = useState( '' );
    const [ponencia, setPonencia] = useState( 0 );
    const [recibidoBuzon, setRecibidoBuzon] = useState( false );
    const [recibidoReloj, setRecibidoReloj] = useState( false );
    const [asignacionDirecta, setAsignacionDirecta] = useState( false );
    
    const [errors, setErrors] = useState({ });

    const [loading, setLoading] = useState(false);

    const [tipoTramiteArray, SetTipoTramiteArray] = useState( [ ] );
    const [ponenciaArray, SetPonenciaArray] = useState( [ ] );

    const [recibidoPaqueteria, setRecibidoPaqueteria] = useState( false );

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const LimpiarVariables = () => {

        setPresentante( { idPersona: 0, nombreCompleto: '' });
        setTipoTramite( 0 );
        setFechaReloj( DateNow );
        setMotivoAsignacion( '' );
        setPonencia( 0 );
        setRecibidoBuzon( false );
        setRecibidoReloj( false );
        setAsignacionDirecta( false );

    }

    const handleChangeAsignacion = ( checked ) => {

        setAsignacionDirecta( checked );
        setMotivoAsignacion( '' );
        setPonencia( 0 );

    }

    const handleChangeTipoTramite = (e) => {
        
        setTipoTramite(e.target.value);
        setAsignacionDirecta( false );

    }

    const handleChangeRecibidoReloj = ( value ) => {

        setRecibidoReloj( value );

        const Now = moment( new Date()).format("YYYY-MM-DDTHH:mm");
        setFechaReloj( Now );
    }

    const GenerarRecepcion = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoading( true );

        const params = {

            idPresentante: idPersona,
            idTipoTramite: tipoTramite,
            recibidoPorReloj: recibidoReloj,
            fechaReloj : recibidoReloj ? fechaReloj : null,
            recibidoBuzonElectronico : recibidoBuzon,
            asignacionDirecta : asignacionDirecta,
            motivoAsignacion : motivoAsignacion,
            idPonencia : ponencia,
            recibidoPaqueteria: recibidoPaqueteria,
        };

        await NuevaRecepcion( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    if( response.data ){

                        setTimeout(() => {

                            const { idRecepcion } = response.data;
            
                            setLoading( false );

                            if( tipoTramite === 1 ){
                                history.push("/Recepcion/DemandaInicial/" + idRecepcion);
                            }
                            else if( tipoTramite === 2 ){
                                history.push("/Recepcion/Promocion/" + idRecepcion);
                            }
                            else if( tipoTramite === 3 ){
                                history.push("/Recepcion/Amparo/" + idRecepcion);
                            }
                            else if( tipoTramite === 4 ){
                                history.push("/Recepcion/ExhortoRecibido/" + idRecepcion);
                            }

                        }, 1200);

                    }

                }

            }

        });
        
    }

    const validateFiels = () => {
        let valid = true;
        let errores = {};

        if( idPersona === 0 && !recibidoPaqueteria ){
            valid = false;
            errores.presentante = 'Debes seleccionar un presentante';
        }
        if( tipoTramite === 0 ){
            valid = false;
            errores.tipoTramite = 'Debes seleccionar un tipo de trámite';
        }
        if( fechaReloj === '' && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'Debes seleccionar la fecha de reloj';
        }
        if( motivoAsignacion === ''&& asignacionDirecta ){
            valid = false;
            errores.motivoAsignacion = 'Debes escribir el motivo de la asignación';
        }
        if( ponencia === 0 && asignacionDirecta ){
            valid = false;
            errores.ponencia = 'Debes seleccionar la ponencia';
        }
        if( moment( new Date( fechaReloj ) ) > moment( new Date( ) ) && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'La fecha de reloj no puede ser mayor a la fecha actual';
        }
        
        setErrors( errores );
        return valid;
    } 

    const cerrarModal = () => {
        
        handleCloseModal();

        setErrors( { } );    
        
        LimpiarVariables();
    }

    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPresentante( persona );

        }

    }   

    useEffect(() => {
        
        async function ObtenerTramite(){
            
            await ObtenerTipoTramite().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        SetTipoTramiteArray( response.data );
                    }
                }
                
            });

        }
        if (openModal) {

            ObtenerTramite();
            
        }

    }, [openModal]);
    
    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerPonencias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        SetPonenciaArray( response.data );
                    }
                }
                
            });

        }

        if (openModal) {

            Obtener();
            
        }

    }, [openModal]);

    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Nueva recepción</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                       <Grid item md={5} xs={12} >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">¿El trámite es recibido por paquetería?</FormLabel>
                                <RadioGroup row
                                    aria-label="recibidoPaqueteria"
                                    name="recibidoPaqueteria"                        
                                >
                                    <FormControlLabel control={ <Radio checked={ recibidoPaqueteria === false } onChange={ ( e ) => { setRecibidoPaqueteria( false ); setPresentante({ idPersona: 0, nombreCompleto: '' }); } } />} label="No" />
                                    <FormControlLabel control={ <Radio checked={ recibidoPaqueteria === true } onChange={  ( e ) => { setRecibidoPaqueteria( true); setPresentante({ idPersona: 0, nombreCompleto: '' }); } } />} label="Si" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                       
                        <Grid item md={7} xs={12} > 
                           
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar presentante' }} 
                                    placeholder="Buscar presentante"     
                                    name="presentante"
                                    value={ nombreCompleto }   
                                    onChange={ () => { } }             
                                    autoComplete="off"   
                                    disabled
                                /> 

                                <Button 
                                    sx={{ borderRadius: 10, mr: 1 }} 
                                    variant='contained' 
                                    onClick={ handleOpenModalBuscarPersona }
                                    size='small'
                                    disabled={ recibidoPaqueteria }
                                >
                                    <SearchIcon />
                                </Button>         

                                <Button 
                                    sx={{ borderRadius: 10 }} 
                                    variant='contained' 
                                    onClick={ handleOpenModalNuevaPersona }
                                    size='small'
                                    disabled={ recibidoPaqueteria }
                                >
                                    <PersonAddIcon />
                                </Button>         

                            </Paper>                            
                            
                            {   
                                errors.presentante && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.presentante } </Alert> )
                            }

                        </Grid>                      

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoTramite-label">Tipo de trámite</InputLabel>
                            
                                <Select
                                    labelId="select-tipoTramite-label"
                                    id="select-tipoTramite"                                            
                                    label="Tipo de trámite"
                                    name="tipoTramite"
                                    value={ tipoTramite }
                                    onChange={ handleChangeTipoTramite
                                    }                           
                                >
                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                    {
                                        tipoTramiteArray.map( ( elem ) => (
                                        <MenuItem key={ elem.idTipoTramite } value={ elem.idTipoTramite }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>
                            
                            {   
                                errors.tipoTramite && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoTramite } </Alert> )
                            }

                        </Grid> 

                        <Grid item md={6} xs={12} >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">¿El trámite proviene del buzón electrónico?</FormLabel>
                                <RadioGroup row
                                    aria-label="recibidoBuzon"
                                    name="recibidoBuzon"                        
                                >
                                    <FormControlLabel control={ <Radio checked={ recibidoBuzon === true } onChange={  ( e ) => { setRecibidoBuzon( true); } } />} label="Si" />
                                    <FormControlLabel control={ <Radio checked={ recibidoBuzon === false } onChange={ ( e ) => { setRecibidoBuzon( false ); } } />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12} >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">¿El trámite es recibido por reloj?</FormLabel>
                                <RadioGroup row
                                    aria-label="recibidoReloj"
                                    name="recibidoReloj"                          
                                >
                                    <FormControlLabel control={ <Radio checked={ recibidoReloj === true } onChange={  ( e ) => { handleChangeRecibidoReloj( true ); } } />} label="Si" />
                                    <FormControlLabel control={ <Radio checked={ recibidoReloj === false } onChange={ ( e ) => { handleChangeRecibidoReloj( false ); } } />} label="No" />
          
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12} >

                            {
                                recibidoReloj && (
                                    <>
                                        <TextField                              
                                            label="Fecha recibida por reloj"
                                            type="datetime-local"   
                                            name="fechaReloj"                             
                                            value={ fechaReloj }    
                                            onChange={ ( e ) => { setFechaReloj( e.target.value ); } }                                                     
                                            fullWidth
                                            inputProps={{
                                                max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                            }}                             
                                            InputLabelProps={{
                                                shrink: true,
                                            }}           
                                                            
                                        />

                                        {   
                                            errors.fechaReloj && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaReloj } </Alert> )
                                        }

                                    </>
                                )
                            }

                        </Grid>

                        {
                            (
                                ( tipoTramite === 4 || tipoTramite === 1 ) &&
                                tribunal
                            ) 
                            
                            && (

                                <Grid item md={3} xs={12} >
                                    <FormControl component="fieldset">  
                                        <FormLabel component="legend">¿Es asignación directa?</FormLabel>
                                        <RadioGroup row
                                            aria-label="asignacionDirecta"                                   
                                            name="asignacionDirecta"                
                                        >
                                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === false } onChange={ () => { handleChangeAsignacion( false ); } } />} label="No" />
                                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === true } onChange={ () => { handleChangeAsignacion( true ); } } />} label="Si" />
                                            
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            )
                        }

                        {
                            asignacionDirecta && (

                                <>
                                        <Grid item md={9} xs={12} >
                                            <TextField                              
                                                label="Motivo de la asignación directa"    
                                                name="motivoAsignacion"                                                 
                                                value={ motivoAsignacion }
                                                onChange={ ( e ) => { setMotivoAsignacion( e.target.value ); } }                           
                                                multiline
                                                rows={4}  
                                                fullWidth                          
                                            />

                                            {   
                                                errors.motivoAsignacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.motivoAsignacion } </Alert> )
                                            }

                                    </Grid>

                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-Ponencia-label">Ponencia</InputLabel>
                                        
                                            <Select
                                                labelId="select-Ponencia-label"
                                                id="select-Ponencia"                                            
                                                label="Ponencia"
                                                name="ponencia"
                                                value={ ponencia }
                                                onChange={ ( e ) => { setPonencia( e.target.value ); } }                           
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    ponenciaArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.idPonencia } value={ elem.idPonencia }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.ponencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.ponencia } </Alert> )
                                        }

                                    </Grid> 
                                    
                                    
                                    
                                </>

                            )
                        }

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button variant="text" color="primary" onClick={ cerrarModal }>Cancelar</Button>
                    <Button variant="contained" color="primary" startIcon={ <SaveIcon /> } onClick={ GenerarRecepcion } disabled={ loading } >Guardar</Button>
                </DialogActions>

                <LinearProgress sx={{ display: loading ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

        </>
    )
}
