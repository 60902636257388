import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Paper, Snackbar, Typography } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../../ui/AppBarMenu';

import { types } from '../../../types/types';

import { ActualizarEstatusExhortoLibrado,ObtenerExhortoLibradoDetalle } from '../../../actions/tribunal/exhortoLibradoActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

export const VisualizarExhortoLibradoScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const { tribunal } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [sendAjax, setSendAjax] = useState(false);

    const [exhortoLibrado, setExhortoLibrado] = useState( { } );

    const [loadingFinalizar, setLoadingFinalizar] = useState( false ); 

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const Finalizar = async () => {

        setLoadingFinalizar( true );
        
        const params = {
            'idExhortoLibrado' : Id,
            'idEstatus': 47,
        };

        await ActualizarEstatusExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                        setSendAjax( true );

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    useEffect(() => {      
        
        async function Obtener(){

            const params = {
                'idExhortoLibrado': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerExhortoLibradoDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){
                        
                        setTimeout(() => {                           
                            
                            setExhortoLibrado( response.data );

                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });    
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 40 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 40){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setSendAjax( true ); 

                            }
                            
                        }, 1000);   

                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>
                            
                            <InformacionSeguimiento
                                noSeguimiento={ 'GEXL-' + exhortoLibrado.idExhortoLibrado }
                                actividad={ 'Visualizar exhorto librado' }
                                tipo={ 'Número de exhorto' }
                                referencia={ tribunal ? exhortoLibrado.numeroReferencia : exhortoLibrado.numeroExhorto }
                            />   

                            <Grid container spacing={3} mt={1}>   

                                {
                                    exhortoLibrado.numeroExpediente
                                    &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de expediente:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoLibrado.numeroExpediente }
                                                </Typography>
                                            </Grid>
                                        </>
                                }
                                
                                {
                                    exhortoLibrado.numeroExhortoRecibido
                                    &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de exhorto recibido:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoLibrado.numeroExhortoRecibido }
                                                </Typography>
                                            </Grid>
                                        </>
                                }

                                {
                                    exhortoLibrado.ponencia
                                    &&                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                    }

                                {
                                    exhortoLibrado.ponencia
                                    &&  
                                    <Grid item md={10} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoLibrado.ponencia }
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        exhortoLibrado?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                              
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                    {
                                        exhortoLibrado?.demandado?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                                              
                                    </List>
                                </Grid>                   

                                <Grid item lg={12} md={12} xs={12}>

                                    {
                                        exhortoLibrado?.exhortoFirmado
                                        ?
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ exhortoLibrado?.exhortoFirmado }  
                                            />
                                        :                                        
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ "data:application/pdf;base64," + exhortoLibrado?.exhorto }  
                                            />
                                    }

                                    
                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 

                                    <Button 
                                        variant="contained" 
                                        onClick={ () => { setOpenConfirmacion( true ); } } 
                                        endIcon={ <CheckCircleIcon /> }
                                    > 
                                        Finalizar 
                                    </Button>

                                </Grid>    

                            </Grid>

                        </Container>
                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la visualización del exhorto librado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
