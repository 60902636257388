import React, { useState } from 'react';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, LinearProgress, List, ListItem, Paper, Radio, RadioGroup, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { LoadingButton } from '@mui/lab';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';

import { BuscarIrregular } from '../../actions/recepcion/irregularActions';

import { GuardarRegistrosValores, GuardarEntregaValor } from '../../actions/tribunal/registrarValorActions';

import { ModalBuscarRegistroValor } from './ModalBuscarRegistroValor';
import { numberWithCommas } from '../../helpers/FormatNumber';

export const ModalNuevoRegistroValor = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const [opcion, setOpcion] = useState('nuevo');

    const [errors, setErrors] = useState({ });

    const [expediente, setExpediente] = useState( { } );
    const [numeroExpediente, setNumeroExpediente] = useState('');

    const [irregular, setIrregular] = useState( { } );
    const [numeroIrregular, setNumeroIrregular] = useState('');

    const [registroValor, setRegistroValor] = useState( { } );
    const [referencia, setReferencia] = useState('');
    
    const [loading, setLoading] = useState( false );
    const [loadingIrregular, setLoadingIrregular] = useState( false );
    const [loadingGuardar, setLoadingGuardar] = useState(false);
    
    const [tipo, setTipo] = useState(0);
    const [tipoTramite, setTipoTramite] = useState(0);

    const [openModalBuscarRegistroValor, SetOpenModalBuscarRegistroValor] = useState(false);

    const handleChangeOpcion = ( e ) => {
        setOpcion( e.target.value );
    }

    const handleOpenModalRegistroValor = () => {       
        SetOpenModalBuscarRegistroValor(true);
    };

    const handleCloseModalRegistroValor = () => {
        SetOpenModalBuscarRegistroValor(false);
    };

    const GenerarRegistro = async () => { 
        
        const params = {
            idExpediente : expediente.success ? expediente.idExpediente : 0,
            idIrregular : irregular.success ? irregular.idIrregular : 0,
        };

        setLoadingGuardar(true);
       
        if( opcion === 'nuevo') {

            await GuardarRegistrosValores( params ).then( response => {

                if( response ){
    
                    if( response.status === 200){
                        if(response.data){
                            setErrors( { } );
                            setNumeroExpediente('');
                            
                            const {idRegistroValor} = response.data;
    
                            setTimeout(() => {
                                setLoadingGuardar(false);
                                history.push("/Tribunal/RegistroValor/NuevoRegistro/" + idRegistroValor);  
                            }, 1200);  
                        }
                    } 
                    else {
                        setTimeout(() => {
                            setErrors( { } );
                            setNumeroExpediente('');
                            setExpediente( { } );
                            setLoadingGuardar(false);
                            let errores = {};  
                            errores.expediente = 'Ocurrió un error, contacte con el administrador';
                            errores.type = 'error';
                            setErrors( errores );
                        }, 1200);  
                    }
                }
            });

        }
        else if( opcion === 'entrega') {

            const params = {
                idRegistroValor : registroValor.id
            }
            

            await GuardarEntregaValor( params ).then( response => {
               
                if( response ) {
                    if( response.status === 200){
                        if(response.data){   
                            setErrors( { } );
                            setNumeroExpediente('');
    
                            setTimeout(() => {
                                setLoadingGuardar(false);
                                history.push("/Tribunal/RegistroValor/EntregaCheque/" + registroValor.id); 
                            }, 1200);  
                        }                    
                    }
                    else {
                        setTimeout(() => {                            
                            setErrors( { } );
                            setNumeroExpediente('');
                            setExpediente( { } );
                            setLoadingGuardar(false);
                            let errores = {};  
                            errores.expediente = 'Ocurrió un error, contacte con el administrador';
                            errores.type = 'error';
                            setErrors( errores );
                        }, 1200);  
                    }
                }
            });            

        } 

    }

    const errorExpediente = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExpediente = 'No se encontró el expediente';
        errores.type = 'warning';
       
        setExpediente( { } );
        setErrors( errores );
        setNumeroExpediente('');

    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }       

        await BuscarExpediente( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setExpediente(response.data);                                                              
                            }
                            else{
                                errorExpediente();
                            }
                            setNumeroExpediente('');
                        }
                    }, 1500);                      
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setExpediente('');
                }
            }
        });     

    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' && tipoTramite === 1 ){
            valid = false;             
            errores.numeroExpediente = 'Debes teclear un expediente';
            errores.type = 'warning';

            setLoading( false );
        } 

        if( numeroIrregular === '' && tipoTramite === 2 ){
            valid = false;
            
            errores.numeroIrregular = 'Debes teclear el número irregular';
            errores.type = 'warning';

            setLoadingIrregular( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorIrregular = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroIrregular = 'No se encontró la irregular';
        errores.type = 'warning';
       
        setIrregular( { } );
        setErrors( errores );
        setNumeroIrregular('');
    }

    const handleClicBuscarIrregular = async () => {

        setLoadingIrregular( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroIrregular: numeroIrregular
        }

        await BuscarIrregular( params ).then( response => {

            setLoadingIrregular( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoadingIrregular( false );
                        if(response.data){
                            if(response.data.success){
                                setIrregular(response.data);
                            }else{
                                errorIrregular();
                            }
                            setNumeroIrregular('');
                        }
                    }, 1500);  
                    setIrregular('');
                } 
                else {
                    setTimeout(() => {
                        setLoadingIrregular( false );

                    }, 1500);  
                    setIrregular('');
                }
            }
        });
      
    }
    
    const seleccionarFolio = ( data ) => {

        setRegistroValor(data);
        
        if( data ){
            setReferencia( data.referencia );
        }

    }

    const handleChangeTipoTramite = ( tipoTramite ) => {

        setTipoTramite(tipoTramite);
        setExpediente( { } );
        setIrregular( { } );
        setRegistroValor( { } );
        setReferencia( '' );
    }

    const handleChangeTipo = ( tipo ) => {

        setTipo(tipo);
        setRegistroValor( { } );
        setReferencia( '' );
    }

    const handleCerrar = () => {

        setTipoTramite( 0 );
        setTipo( 0 );
        setExpediente( { } );
        setIrregular( { } );
        setRegistroValor( { } );
        setErrors( { } );
        setReferencia( '' );
        
        handleCloseModal();

    }

    return (
        <>

            <Dialog open={ openModal } maxWidth='md' fullWidth={true} >

                <LinearProgress sx={{ display: 'none' }}></LinearProgress>

                <DialogTitle>Registrar valor</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={4} >

                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Seleccióne el tipo de trámite:</FormLabel>
                                <RadioGroup row
                                    aria-label="tipoTramite"
                                    defaultValue="expediente"
                                    name="radio-buttons-group"  
                                >
                                    <FormControlLabel value="expediente" control={ <Radio checked={ tipoTramite === 1 } onChange={ () => { handleChangeTipoTramite(1) }} />} label="Expediente" />
                                    <FormControlLabel value="expedienteIrregular" control={ <Radio checked={ tipoTramite === 2 } onChange={ () => { handleChangeTipoTramite(2) }} />} label="Irregular" />

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                            <FormControl component="fieldset" disabled={ tipoTramite === 0 }>
                                <FormLabel component="legend">Selecciona una opción</FormLabel>
                                <RadioGroup row
                                    aria-label="tipoOficio"
                                    value={opcion}
                                    name="radio-buttons-group"  
                                    onChange={ handleChangeOpcion }
                                >
                                    <FormControlLabel value="nuevo" control={ <Radio checked={ tipo === 1 } onChange={ () => { handleChangeTipo(1) }} />} label="Nuevo registro" />
                                    <FormControlLabel value="entrega" control={ <Radio checked={ tipo === 2 } onChange={ () => { handleChangeTipo(2) }} />} label="Entregar cheque" />

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {  
                            tipoTramite === 1 &&
                            <>
                                <Grid item md={12} xs={12} > 
                            
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                            placeholder="Buscar por número de expediente"     
                                            name="expediente"
                                            value={ numeroExpediente }                                      
                                            onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                            autoComplete="off"
                                        /> 

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarExpediente }
                                            size='small'
                                        >
                                            <SearchIcon />
                                        </LoadingButton>   

                                    </Paper>
                                    {   
                                        errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                                    }

                                </Grid>

                                {
                                    expediente.success === true && (

                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Expediente:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.numeroExpediente }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            {
                                                expediente.ponencia
                                                &&
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Ponencia:
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            {
                                                expediente.ponencia
                                                &&
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { expediente.ponencia }
                                                </Typography>
                                            }                                            
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                               
                                            {
                                               expediente.actor.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                
                                            </List>                            
                                    
                                        </Grid> 
                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                  
                                            </List>
                                        </Grid>    

                                    </>

                                )}

                            </>
                        }
                        
                        {  
                            tipoTramite === 2 &&
                            <>

                                <Grid item md={12} xs={12} > 
                                    
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }}
                                            inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                            placeholder="Buscar por número de irregular"     
                                            name="irregular"
                                            value={ numeroIrregular }                                      
                                            onChange={ ( e ) => { setNumeroIrregular( e.target.value ); } }
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loadingIrregular }
                                            onClick={ handleClicBuscarIrregular }
                                            size='small'
                                        >
                                            <SearchIcon />
                                        </LoadingButton>            

                                    </Paper>
                                    {   
                                        errors.numeroIrregular && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroIrregular  } </Alert> )
                                    }

                                </Grid>  

                                {
                                    irregular.success === true && (
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Irregular:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { irregular.numeroIrregular }
                                            </Typography>
                                        </Grid>                                     
                                     
                                        <Grid item md={2} xs={6}>
                                            {
                                                irregular.ponencia
                                                &&
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Ponencia:
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            {
                                                irregular.ponencia
                                                &&
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { irregular.ponencia }
                                                </Typography>
                                            }                                            
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                               
                                                {
                                                    irregular.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                            </List>                            
                                    
                                        </Grid> 

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                                {
                                                    irregular.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                  
                                            </List>
                                        </Grid>    

                                    </>

                                )}

                            </>
                        }

                        { 
                            tipo === 2  && 
                            <>
                                <Grid item md={12} xs={12} > 

                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                                
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar referencia' }} 
                                            placeholder="Buscar referencia"     
                                            name="referencia"
                                            value={ referencia }                                      
                                            onChange={ ( ) => { } } 
                                            autoComplete="off"
                                        />

                                        <Button 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            onClick={ handleOpenModalRegistroValor }
                                            size='small'
                                            disabled={ !expediente.success && !irregular.success }
                                        >
                                            <SearchIcon />
                                        </Button>       

                                    </Paper> 

                                </Grid>  

                                {
                                    registroValor.referencia && (
                                    <>                               

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Referencia:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { registroValor?.referencia }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Cantidad:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { '$' + numberWithCommas( registroValor?.cantidad ?? 0 ) }                                                
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Consignante:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={10} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }}>
                                                { registroValor?.consignante }
                                            </Typography>                                                
                                        </Grid>
                                    </>
                                )}
                                
                            </>
                        }
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCerrar }>Cancelar</Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ GenerarRegistro } 
                        disabled={ 
                            ( tipoTramite === 0) ||  
                            ( tipo === 0) ||                            
                            ( tipo === 1 && tipoTramite === 1 && !expediente.success ) ||
                            ( tipo === 1 && tipoTramite === 2 && !irregular.success ) ||
                            ( tipo === 2 && tipoTramite === 1 && referencia === '' ) ||
                            ( tipo === 2 && tipoTramite === 2 && referencia === '' ) ||
                            loadingGuardar
                        }
                    >
                        Guardar
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

            <ModalBuscarRegistroValor
                openModal={ openModalBuscarRegistroValor } 
                handleCloseModal={ handleCloseModalRegistroValor } 
                seleccionarFolio={ seleccionarFolio }
                        
                idExpediente={ expediente.success ? expediente.idExpediente : 0 }
                idIrregular={ irregular.success ? irregular.idIrregular : 0 }
            />

        </>
    )
}
