import React, { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, Dialog,  DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { NotificacionDetalle } from '../../actions/notificaciones/notificacionActions';
import moment from 'moment';


export const ModalDetalleNotificacion = ( { open, setOpen, idNotificacion } ) => {

    const [loading, setLoading] = useState( false );
    const [notificacion, setNotificacion] = useState( { } );

    useEffect(() => {   
        
        async function Obtener(){
            
            setLoading( true );

            await NotificacionDetalle( idNotificacion ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setNotificacion( response.data );
                            setLoading( false );

                        }, 500);
                        
                    }

                }

            });
        }
        
        if( open ){
            Obtener();
        }

    }, [ open, idNotificacion ])      

    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth >
              
                <DialogContent>

                    <Grid item md={12} xs={12} >
                        
                       {
                            loading
                            ?
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                </Box>
                            :
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Tipo de notificación:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { notificacion?.tipoNotificacion }
                                            </Typography>
                                        </Grid>
                                    </Grid>  

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item md={4} xs={4}>
                                                <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                    Forma de notificación:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={8} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { notificacion?.formaNotificacion }
                                                </Typography>
                                            </Grid>
                                    </Grid>    

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Notificación local:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { notificacion?.notificacionLocal ? 'SI' : 'NO' }  
                                            </Typography>
                                        </Grid>
                                    </Grid>   
                                    
                                {
                                    notificacion?.seNotifico === false
                                    &&  
                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Motivo de la no notificación:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { notificacion?.motivoNoNotificado }
                                            </Typography>
                                        </Grid>
                                    </Grid>   
                                }

                                
                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha en la que se ordeno:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                            { notificacion?.fechaOrdenamiento ? moment( notificacion?.fechaOrdenamiento ).format('DD-MM-YY HH:mm a') : '' } 
                                            </Typography>
                                        </Grid>
                                    </Grid>   

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha de razonamiento:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                            { notificacion?.fechaRazonamiento ? moment( notificacion?.fechaRazonamiento ).format('DD-MM-YY HH:mm a') : '' } 
                                            </Typography>
                                        </Grid>
                                    </Grid>   
                                    
                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha de devolucion:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                            { notificacion?.fechaDevolucion ? moment( notificacion?.fechaDevolucion ).format('DD-MM-YY HH:mm a') : '' } 
                                            </Typography>
                                        </Grid>
                                    </Grid>   

                                    {
                                        notificacion?.documentoNotificacionFirmado
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Cédula de notificación:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={ notificacion?.documentoNotificacionFirmado }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                    {
                                        notificacion?.razonamiento
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Razonamiento:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={   notificacion?.razonamiento }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                </Box>
                        }
                          
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setNotificacion({ }); } } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
