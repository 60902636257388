import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SourceIcon from '@mui/icons-material/Source';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';
import { ModalDetalleAmparo } from '../modals/ModalDetalleAmparo';
import { ModalDetalleIrregular } from '../modals/ModalDetalleIrregular';
import { ModalDetalleExhortoRecibido } from '../modals/ModalDetalleExhortoRecibido';
import { ModalDetallePromocion } from '../modals/ModalDetallePromocion';

import { TipoTramite } from '../../actions/admin/catalogs/tipotramiteActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';

import { ConsultaTramitesRecibidos } from '../../actions/recepcion/recepcionActions';

export const TramitesRecibidosScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );
    
    const [rows, setRows] = useState( [ ] );

    const [fecha, setFecha] = useState( '' );
    
    const [tipoTramite, setTipoTramite] = useState( 0 );
    const [tipoTramiteArray, setTipoTramiteArray] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages();
    const [pageAnexos, rowsPerPageAnexos, handleChangePageAnexos, handleChangeRowsPerPageAnexos ] = usePages();

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [openModal, setOpenModal] = useState( false );
    const [openDetalleAmparo, setOpenDetalleAmparo] = useState( false );
    const [openDetalleIrregular, setOpenDetalleIrregular] = useState( false );
    const [openDetalleExhortoRecibido, setOpenDetalleExhortoRecibido] = useState( false );
    const [openDetallePromocion, setOpenDetallePromocion] = useState( false );

    const [expediente, setExpediente] = useState( 0 );
    const [amparo, setAmparo] = useState( 0 );
    const [irregular, setIrregular] = useState( 0 );
    const [exhortoRecibido, setExhortoRecibido] = useState( 0 );
    const [promocion, setPromocion] = useState( 0 );

    const [openAnexos, setOpenAnexos] = useState(false);
    const [detalleAnexos, setDetalleAnexos] = useState( [ ] );
    
    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setFecha( '' );
        setTipoTramite( 0 );
        setAutoridad( 0 );
        
        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();

    }

    const handleBuscarTramites = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );                

    }

    const handleMostrarInformacion = ( tipoTramite, id ) => {

        if( tipoTramite === 'Demanda o Escrito Inicial' ){
            setExpediente( id );
            setOpenModal( true );
        }
        else if( tipoTramite === 'Amparo' ){
            setAmparo( id );
            setOpenDetalleAmparo( true );
        }
        else if( tipoTramite === 'Irregular' ){
            setIrregular( id );
            setOpenDetalleIrregular( true );
        }
        else if( tipoTramite === 'Exhorto' ){
            setExhortoRecibido( id );
            setOpenDetalleExhortoRecibido( true );
        }
        else if( tipoTramite === 'Promoción' ){
            setPromocion( id );
            setOpenDetallePromocion( true );
        }

    }

    useEffect(() => {
      
        async function Obtener(){

            await TipoTramite( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoTramiteArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);   

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaTramitesRecibidos( { page, rowsPerPage, idTipoTramite: tipoTramite, fecha, idAutoridad: autoridad } ).then( response => {
                    
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );                                
                                setLoadingSearch( false );
                                setLoading( false );  

                            }, 1000);                            

                        }

                    }

                }

            });

        }     
        
        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, tipoTramite, fecha, autoridad ]);   

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  
                
                <Container maxWidth="xl">
                    
                    <Grid container spacing={3} >

                        <Grid item lg={12} md={12} xs={12}>

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                <Grid container spacing={3}>
                                {     
                                    ( administrador || coordinador )
                                     && 
                                    <Grid item md={4} sm={6} xs={12}> 
                                                        
                                        <FormControl fullWidth>
                                            <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                        
                                                <Select
                                                    labelId="select-autoridad-label"
                                                    id="select-autoridad"                                            
                                                    label="Autoridad"
                                                    name="autoridad"
                                                    value={ autoridad }                                
                                                    onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                                            >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        coordinador
                                                        ?
                                                        autoridadArray 
                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                        .map( ( elem ) => (
                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                        :
                                                            autoridadArray.map( ( elem ) => (
                                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                        ))
                                                    }                          
                                                </Select>
            
                                            </FormControl>
                                                                    
                                    </Grid>
                                }   
                                        <Grid item md={5} xs={12} >

                                            <TextField                                       
                                                label="Fecha del trámite"
                                                type="date"                                        
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="fechaAudiencia"
                                                value={ fecha }
                                                onChange={ ( e ) => setFecha( e.target.value ) }
                                                disabled={ filtroAplicado }                                                  
                                            />

                                        </Grid> 
                                       
                                        <Grid item md={5} xs={12} >    

                                            <FormControl fullWidth>

                                                <InputLabel id="select-tipoTramite-label">Tipo de trámite</InputLabel>
                                            
                                                <Select
                                                    labelId="select-tipoTramite-label"
                                                    id="select-tipoTramite"                                            
                                                    label="Tipo de trámite"
                                                    name="tipoTramite"
                                                    value={ tipoTramite }
                                                    onChange={ ( e ) => setTipoTramite( e.target.value ) }
                                                    disabled={ filtroAplicado }  
                                                >
                                                    <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        tipoTramiteArray                                                        
                                                        .map( ( element ) => (
                                                            <MenuItem key={ element.idTipoTramite } value={ element.idTipoTramite }>{ element.descripcion }</MenuItem>                        
                                                        ))
                                                    }
                                                </Select>

                                            </FormControl>

                                        </Grid>  

                                        <Grid item md={2} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                fullWidth 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarTramites() } }
                                                loading={ loadingSearch }
                                                disabled={ fecha === '' && tipoTramite === 0 && autoridad === 0 }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>  

                                    </Grid>

                                </CardContent>

                            </Card>

                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ pt: 3, pb: 3 }} variant="h4" >
                                        Trámites recibidos
                                    </Typography>
                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >

                                        <TableHead>

                                            <TableRow>      

                                                <TableCell style={{ width:'5%', fontWeight: 'bold', textAlign: 'center' }}>  </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> FECHA </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> REFERENCIA </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> TIPO DE TRÁMITE </TableCell>
                                                <TableCell style={{ width:'10%', fontWeight: 'bold', textAlign: 'center' }}> ANEXOS </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> ESTATUS </TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                rows.map( ( row, i ) => (

                                                    <TableRow key={ i } >

                                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }} > { row.numeroSeguimiento } </TableCell>

                                                        <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>                                              

                                                        <TableCell> 

                                                            {
                                                                row?.referencia
                                                                &&
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { handleMostrarInformacion( row.tipoTramite, row?.idReferencia ) } }
                                                                >
                                                                    { row?.referencia ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell>

                                                        <TableCell> 
                                                            
                                                            <strong> { row.tipoTramite } </strong> <br />
                                                        
                                                            { row.subTipoTramite ? '(' + row.subTipoTramite + ')' : '' } 
                                                        
                                                        </TableCell>

                                                        <TableCell sx={{ textAlign: 'center' }}> 

                                                            { 
                                                                row.tieneAnexos
                                                                &&  
                                                                <IconButton
                                                                    onClick={ () => { setDetalleAnexos( row.anexos ); setOpenAnexos( true ); } }
                                                                >
                                                                    <FormatListNumberedIcon color='primary' />
                                                                </IconButton>
                                                            }

                                                        </TableCell>
                                                        
                                                        <TableCell> 
                                                            { 
                                                                row?.usuarioAsignado
                                                                ?  
                                                                    <>
                                                                        <strong>{ row.estatus }</strong> <br />
                                                                        Asignado a <strong> {row?.usuarioAsignado } </strong>                                                           
                                                                    </>
                                                                :
                                                                    <strong>{ row.estatus }</strong>
                                                            }
                                                        
                                                        </TableCell>

                                                    </TableRow>

                                                ))
                                            }

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>

                                                        <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron trámites'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TableCell colSpan={2}>
                                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de trámites: { recordsCount } </Typography>
                                                </TableCell> 

                                                <TablePagination
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    colSpan={ 10 }
                                                    count={ recordsCount }                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={ page }                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />

                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Paper>

                        </Grid>

                    </Grid>                   

                </Container>

            </Box>

            <Dialog open={ openAnexos } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
                <DialogContent>
                    
                    <Grid container spacing={3} >    

                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }} > 

                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width:'20%' , fontWeight: 'bold'}}>TIPO DE ANEXO</TableCell>                              
                                            <TableCell style={{ width:'20%' , fontWeight: 'bold'}}>TIPO DE FORMATO</TableCell>                              
                                            <TableCell style={{ width:'10%' , fontWeight: 'bold', textAlign: 'center' }}>CANTIDAD</TableCell>    
                                            <TableCell style={{ width:'15%' , fontWeight: 'bold', textAlign: 'center'}}>FOJAS</TableCell>   
                                            <TableCell style={{ width:'25%' , fontWeight: 'bold'}}>OBSERVACIONES</TableCell>   

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (
                                                rowsPerPageAnexos > 0
                                                ?
                                                    detalleAnexos.slice( pageAnexos * rowsPerPageAnexos, pageAnexos * rowsPerPageAnexos + rowsPerPageAnexos )
                                                :
                                                    detalleAnexos
                                            ).map( ( element ) => (
                                                    
                                                <TableRow key={ element.id } >
                                                    
                                                    <TableCell> { element?.tipoAnexo?.descripcion ? element?.tipoAnexo?.descripcion : '' } </TableCell>
                                                    <TableCell> { element?.tipoFormato?.descripcion ? element?.tipoFormato?.descripcion : '' } </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }} > { element?.cantidad ? element?.cantidad : '' } </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}> { element?.numeroFojas ? element?.numeroFojas : '' } </TableCell>
                                                    <TableCell> { element?.observaciones ? element?.observaciones : '' } </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            detalleAnexos.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        No se encontraron anexos
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                        </TableBody>

                                        <TableFooter>

                                        <TableRow>
                                            <TablePagination 
                                                colSpan={5}
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                count={ detalleAnexos.length }
                                                rowsPerPage={ rowsPerPageAnexos }
                                                page={ pageAnexos }
                                                onPageChange={ handleChangePageAnexos }
                                                onRowsPerPageChange={ handleChangeRowsPerPageAnexos }

                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }                                                                                       
                                            />
                                        </TableRow>

                                        </TableFooter>
                                </Table>

                            </TableContainer>

                        </Grid>
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () =>{ setOpenAnexos( false ); setDetalleAnexos([ ]); }}>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog> 

            <ModalDetalleExpediente 
                open={ openModal } 
                setOpen={ setOpenModal } 
                idExpediente={ expediente } 
            />

            <ModalDetalleAmparo 
                open={ openDetalleAmparo }    
                setOpen={ setOpenDetalleAmparo }
                idAmparo={ amparo }
            />

            <ModalDetalleIrregular
                open={ openDetalleIrregular }    
                setOpen={ setOpenDetalleIrregular }
                idIrregular={ irregular }
            />

            <ModalDetalleExhortoRecibido
                open={ openDetalleExhortoRecibido}    
                setOpen={ setOpenDetalleExhortoRecibido }
                idExhortoRecibido={ exhortoRecibido }
            />

            <ModalDetallePromocion
                open={ openDetallePromocion}    
                setOpen={ setOpenDetallePromocion }
                idPromocion={ promocion }
            />

        </>
    )
}