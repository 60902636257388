import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { BuscarAudienciaProgramada } from '../../actions/audiencias/agendaActions';

export const ModalBuscarAudiencia = ( { openModal, handleCloseModal, expediente, obtenerAgenda } ) => {

    const [rows, setRows] = useState( [] );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();    

    const { idExpediente } = expediente;

    const [loadingSearch, setLoadingSearch] = useState(false);

    const handleSeleccionar = ( row ) => {
        obtenerAgenda( row );
        handleCloseModal();
    }

    useEffect(() =>  {

        let isSubscribed = true;

        async function Obtener(){
     
            setRows( [ ] );
            setLoadingSearch(true);

            if( idExpediente ){

                await BuscarAudienciaProgramada( idExpediente ?? 0 ).then( response => {
                
                    if( response && isSubscribed ){

                        if( response.status === 200 ){
                            setTimeout(() => {
                                
                                setRows( response.data );
                                setLoadingSearch(false);
                                
                            }, 1000);

                        }
                        else{

                            setRows( [ ] );
                            setLoadingSearch(false);

                        }

                    }

                });
                
            }

        }

        if( openModal ){

            Obtener();    

        }

        return () => ( isSubscribed = false )
        
    }, [ openModal, idExpediente ]);

    return (
        <>
             <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Audiencias agendadas</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}>      

                        <Grid item md={12} xs={12} >

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'45%', fontWeight: 'bold' }}>Tipo de audiencia</TableCell>
                                            <TableCell style={{ width:'45%', fontWeight: 'bold' }}>Fecha</TableCell>
                                            <TableCell style={{ width:'10%' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map( (row) => (

                                            <TableRow key={ row.id }>
                                                <TableCell> { row.tipoAudiencia } </TableCell>
                                                <TableCell> { row.fechaInicio } </TableCell>
                                                <TableCell> 
                                                    <Button 
                                                        variant="contained"
                                                        endIcon={ <PlaylistAddCheckIcon /> }
                                                        size="small"
                                                        onClick={ () => { handleSeleccionar( row ); } }
                                                    >
                                                        Seleccionar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={9}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        {
                                                            loadingSearch
                                                            ?
                                                                'Buscando ....'
                                                            :
                                                                'No se encontraron resultados'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={4}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                      return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>

                                </Table>
                            
                            </TableContainer>

                        </Grid>           
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModal }>Cancelar</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
