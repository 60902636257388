import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Card, CardContent, Container, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';

import AppBarMenu from '../ui/AppBarMenu';

import { RegistrarInformacionScreen } from './celebrarAudiencia/RegistrarInformacionScreen';
import { CalendarScreen } from './CalendarScreen';
import { RegistrarAnexosScreen } from './celebrarAudiencia/RegistrarAnexosScreen';
import { PartesAudienciaScreen } from './celebrarAudiencia/PartesAudienciaScreen';
import { AcordarPromocionesScreen } from './celebrarAudiencia/AcordarPromocionesScreen';
import { AudienciaNotificacionScreen } from './AudienciaNotificacionScreen';

import { types } from '../../types/types';

import { ObtenerAudienciaDetalle } from '../../actions/audiencias/audienciaActions';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CelebrarAudienciaScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [valueTab, setValueTab] = useState(0);

    const [loading, setLoading] = useState( false );
    const [ajaxLoading, setAjaxLoading] = useState( false );
    const [activeFinish, setActiveFinish] = useState( false );

    const [noSeguimiento, setNoSeguimiento] = useState(0);
    const [audiencia, setAudiencia] = useState( { } );

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };   

    useEffect(() => {      

        let isSubscribed = true

        async function Obtener(){

            const params = {
                'idAudiencia': Id,
            };

            dispatch({ type: types.openLoading });
            
            await ObtenerAudienciaDetalle( params ).then( response => {

                if( response && isSubscribed){

                    if( response.data ){

                        setNoSeguimiento( response.data.idAudiencia ?? '');
                        setAudiencia( response.data ?? { } );

                        setTimeout(() => {                          

                            setAjaxLoading( true );

                            dispatch({ 
                                type: types.actualizarDatosAudiencia,
                                payload: response.data
                            });

                            dispatch({ 
                                type: types.agregarPartesAudiencia,
                                payload: response.data.audienciaPartes
                            });

                            dispatch({ 
                                type: types.agregarAnexosAudiencia,
                                payload: response.data.anexos
                            });

                            dispatch({ 
                                type: types.agregarPromocionesAcordadas,
                                payload: response.data.promocionesAcordadas
                            });

                            dispatch({ 
                                type: types.partesExpediente,
                                payload: response.data.partes ?? []
                            });
                            
                            dispatch({ type: types.closeLoading });

                            if( response.data.expediente ){
                             
                                dispatch({
                                    type: types.audienciaSetExpediente,                                
                                    payload: response.data.expediente,                              
                                });
                                
                            }

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 13 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 13){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true );

                            }

                            if( ( response.data.seCelebro && response.data.fechaHoraInicio ) || ( !response.data.seCelebro && response.data.idTipoNoCelebracion !== 0 ) ){
                             
                                setActiveFinish( true );

                            }
                                                        
                        }, 1000);   
                        
                    }

                }

            }); 

        }     
        
        Obtener();

        return () => ( isSubscribed = false )

    }, [ Id, dispatch ]) 
    
    if( !ajaxLoading ){

        return <AppBarMenu />;

    }
   
    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                    
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }} >
                            
                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile sx={{ pt: 2 }}>
                                
                                <Tab label="Información de la audiencia" { ...a11yProps(0) } />
                                <Tab label="Parte(s) de la audiencia" { ...a11yProps(1) } />    
                                <Tab label="Notificación" { ...a11yProps(2) } />                        
                                <Tab label="Agendar audiencia" { ...a11yProps(3) } />
                                <Tab label="Agregar anexo(s)" { ...a11yProps(4) } />       
                                <Tab label="Acordar promocion(es)" { ...a11yProps(5) } />  
                                
                            </Tabs>

                            <TabPanel value={ valueTab } index={0}> 

                                <Grid container spacing={3} >  

                                    <Grid item md={12} xs={12}>
                                        <InformacionSeguimiento 
                                            actividad={'Celebrar audiencia'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ audiencia?.expediente?.numeroExpediente }
                                        />
                                    </Grid>

                                    <RegistrarInformacionScreen 
                                        activeFinish={ activeFinish } 
                                        setActiveFinish={ setActiveFinish } 
                                        edit={ true } 
                                        Id= { Id }
                                        setLoading={ setLoading }
                                        setUsuarioAsignacion = { setUsuarioAsignacion }
                                        setMensajeFinalizacion = { setMensajeFinalizacion }
                                    />                                

                                </Grid>
                            
                            </TabPanel>

                            <TabPanel value={ valueTab } index={1}>                        

                                <Grid container spacing={3} > 

                                    <Grid item md={12} xs={12}>
                                        <InformacionSeguimiento 
                                            actividad={'Celebrar audiencia'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ audiencia?.expediente?.numeroExpediente }
                                        />
                                    </Grid>
                                    
                                    <PartesAudienciaScreen edit={ true } />

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={2}>                        
                                
                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Celebrar audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>

                                <AudienciaNotificacionScreen 
                                    Id={ Id } 
                                    edit={ true } 
                                    notificaciones={ audiencia?.notificaciones ?? [ ] } 
                                    audiencia={ audiencia } 
                                    setAudiencia={ setAudiencia } 
                                />

                            </TabPanel>

                            <TabPanel value={ valueTab } index={3}>        

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Celebrar audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>   
            
                                <CalendarScreen edit={ true } enAudiencia={ true } />

                            </TabPanel>

                            <TabPanel value={ valueTab } index={4}>     

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Celebrar audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>                   

                                <Grid container spacing={3} sx={{ mt:1 }} >  

                                    <RegistrarAnexosScreen edit={ true } />

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={5}>                        

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Celebrar audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>                   

                                <Grid container spacing={3} sx={{ mt:1 }} >  

                                    <AcordarPromocionesScreen edit={ true } />

                                </Grid>

                            </TabPanel>
                                            
                        </Container>       
                    )                
                }
            </Box>   
                
        </>
    )
}