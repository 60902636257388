import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert,  Autocomplete, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  Container, FormControl, Grid, IconButton, InputBase, InputLabel, List, ListItem, MenuItem, Paper, Select, Snackbar, TextField, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { ModalNuevaPersona } from '../../modals/ModalNuevaPersona';
import { ModalBuscarPersona } from '../../modals/ModalBuscarPersona';

import { usePages } from '../../../hooks/usePages';
import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';
import { ObtenerOficioDetalle, ActualizarOficio, GuardarDestinatarios, ActualizarEstatusOficio } from '../../../actions/tribunal/oficioActions';

import { ObtenerDependencias } from '../../../actions/admin/catalogs/dependenciaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ElaborarOficioScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const { juzgado } = useSelector( state => state.auth );

    const editorRef = useRef(null);

    const [loading, setLoading] = useState( false );

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [openConfirmacionEliminar, setOpenConfirmacionEliminar] = useState(false);

    const [activarFinalizar, setActivarFinalizar] = useState(true);

    const [clicGuardarOficio, setClicGuardarOficio] = useState(false);
    const [clicGuardarDestinatarios, setClicGuardarDestinatarios] = useState(false);

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [oficio, setOficio] = useState( [ ] );

    const [valueTab, setValueTab] = useState(0);

    const [rows, setRows] = useState( [] );

    const [rowsTemp, setRowsTemp] = useState( [] );

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const [idReferencia, setReferencia] = useState( 0 );

    const [firmaJuez, setFirmaJuez] = useState( false );
  
    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const { nombreCompleto } = persona;

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [richText, setRichText] = useState( '' ); 

    const [loadingGuardar, setLoadingGuardar] = useState( false ); 
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 
    const [cadenaPartesAmparo, setCadenaPartesAmparo] = useState( '' ); 
      
    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [referenciaA, setReferenciaA] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
  
    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleOpenConfirmacionEliminar = () => {   
        setOpenConfirmacionEliminar(true);
    };

    const handleCloseConfirmacionEliminar = () => {
        setOpenConfirmacionEliminar(false);
    };

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const obtenerPlantilla = async ( plantilla ) => {
        await ObtenerPlantillaDetalle( plantilla ).then( response => {
            if(response){
                if( response.status === 200){
                    if( response.data.contenido !== 0 ){
                        let contenido = '';

                        let tipoJuicio = '';

                        if(oficio?.expediente?.tipoJuicio !== null){
                            tipoJuicio = oficio?.expediente?.tipoJuicio?.descripcion;
                        } else if(oficio?.exhortoRecibido?.tipoJuicio !== null) {
                            tipoJuicio = oficio?.exhortoRecibido?.tipoJuicio?.descripcion;
                        }
                        
                        contenido = response.data.contenido
                        .replaceAll('[Numero_Oficio]', oficio?.numeroOficio ?? "")
                        .replaceAll('[Numero_Expediente]', oficio?.numeroExpediente ?? "")
                        .replaceAll('[Actor]', cadenaActores)
                        .replaceAll('[Demandado]', cadenaDemandados)
                        .replaceAll('[Tipo_Juicio]', tipoJuicio ?? "")
                        .replaceAll('[Ponencia]', oficio?.expediente?.ponencia?.descripcion ?? "")
                        .replaceAll('[Numero_Irregular]', oficio?.numeroIrregular ?? "" )
                        .replaceAll('[Tipo_Irregular]', oficio?.irregular?.tipoIrregular?.descripcion ?? "" )
                        .replaceAll('[Numero_Amparo]', oficio?.numeroAmparo ?? "")
                        .replaceAll('[Autoridad_Amparo]', oficio?.amparo?.autoridad?.descripcion ?? "" )
                        .replaceAll('[Tipo_Amparo]', oficio?.amparo?.tipoAmparo?.descripcion ?? "" )
                        .replaceAll('[Partes_Amparo]', cadenaPartesAmparo)
                        .replaceAll('[Numero_Exhorto]', oficio?.numeroExhorto ?? "" )

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }

    const validateFielsOficio = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        if( plantilla === 0  ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar la plantilla';
        }

        const contenido = editorRef.current.getContent();

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
            errores.type = 'warning';
            setLoadingGuardar( false );
        }  
        
        setErrors( errores );
        return valid;
    }

    const validateFielsDestinatarios = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        if( dependencia === 0 ){      
            valid = false;
            errores.dependencia = 'Debes seleccionar una dependencia'
            setLoadingGuardar( false );
        }  
        
        setErrors( errores );
        return valid;
    }

    const GuardarOficio = async () => {
        const valid = validateFielsOficio();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            'idOficio' : Id,
            'contenido': contenido,
            'idPlantilla': plantilla
        };

        setLoadingGuardar( true );

        await ActualizarOficio( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setClicGuardarOficio(true);
                    
                    setTimeout( () => {

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 
                    
                        if(clicGuardarDestinatarios)
                        {
                            setActivarFinalizar(false);
                        }
                        

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }

    const HandleGuardarDestinatarios = async () => {
        const valid = validateFielsDestinatarios();
       
        if( !valid ){
            return false;
        }

        const params = {
            'idOficio' : Id,
            'idDependencia' : dependencia,
            'destinatarios':rows
        };

        setLoadingGuardar( true );

        await GuardarDestinatarios( params ).then( response => {
           
            if( response ){

                if( response.status === 200 ){

                    setClicGuardarDestinatarios(true)

                    setTimeout( () => {

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                        if(clicGuardarOficio)
                        {
                            setActivarFinalizar(false);
                        }

                        setRowsTemp( rows );

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
          
        });
    }

    const Finalizar = async () => {
              
        setLoadingFinalizar( true );

        const params = {
            'idOficio' : Id,
            'idEstatus': 35,
            firmaJuez: firmaJuez,
        };

        await ActualizarEstatusOficio( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }         

                        setLoadingFinalizar( false );
                        
                        setLoading( true );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    const seleccionarPersona = ( persona ) => {

        if( persona ){
            setPersona( persona );
        }

    } 

    const AgregarDestinatario = () => {
        if(nombreCompleto !== ''){
            const personaValidate =  rows.find( ( element ) => { return element.idPersona === persona.idPersona });
        
            if( !personaValidate ){  
                setErrors( { } );
                setRows( [ ...rows, persona ] );                
            }
            else{
                setErrors( { } );
                let errores = {};    
                errores.duplicado = 'La persona ya se encuentra agregada';
                setErrors( errores );
            } 
    
            setPersona( {
                idPersona: 0,
                nombreCompleto: ''
            });

        } else {
            setErrors( { } );
            let errores = {};    
            errores.vacio = 'Debe seleccionar una persona';
            setErrors( errores );
        }
    }

    const handleClicEliminarParte = ( Id ) => {

        handleOpenConfirmacionEliminar();

        setReferencia( Id );
        
    }

    const handleChangeEditor = () => {

        if( editorRef.current.getContent() !== '' ){

            setRichText(editorRef.current.getContent());

        }

    }

    const eliminarParte = () => {
        var newArray = rows.filter(function( element ){ 
            return element.idPersona !== idReferencia;
        });

        setRows( newArray );

        handleCloseConfirmacionEliminar();
    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idTipoAcuerdo': 12,
                'idSubTipoAcuerdo': 0,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ])
    
    useEffect(() => {      

        async function Obtener(){

            const params = {
                'idOficio': Id
            };

            dispatch({ type: types.openLoading });

            await ObtenerOficioDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){
         
                        setTimeout(() => {

                            setOficio(response.data);
                            setPlantilla(response?.data?.idPlantilla);
                            setFirmaJuez( response?.data?.firmaJuez ?? false );

                            if( ( response?.data?.numeroAmparo ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferenciaA( response?.data?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhorto ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferenciaA( response?.data?.numeroExhorto ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhortoLibrado ?? '') !== '' ){
                                setTipoReferencia( 'Número de exhorto librado' );
                                setReferenciaA( response?.data?.numeroExhortoLibrado ?? '' );
                            } 
                            else if( ( response?.data?.numeroIrregular ?? '') !== '' ){
                                setTipoReferencia( 'Número de irregular' );
                                setReferenciaA( response?.data?.numeroIrregular ?? '' );
                            } 
                            else if(( response?.data?.numeroExpediente ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferenciaA( response?.data?.numeroExpediente ?? '' );
                            }   

                            let cadenaPA = '';
                            response.data.partes.forEach((value, key) => { 
                                if (key + 1 !== response.data.partes.length){ 
                                    cadenaPA += (value.parte + ' (' + value.tipoParte + '), ')
                                } else {
                                    cadenaPA += (value.parte + ' (' + value.tipoParte + ') ')
                                }
                            })
                            setCadenaPartesAmparo(cadenaPA);
                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += ( value + ', ');
                                } else {
                                    cadenaA += ( value + ' ');
                                }
                            })
                            setCadenaActores(cadenaA);
                            
                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);    

                            setRichText(response.data.contenido);                            

                            if(response.data.dependencia)
                            {
                                setDependencia( response.data.dependencia.idDependencia );
                                setAutocompleteValue( response.data.dependencia );
                            }

                            setRows( response.data.destinatarios );

                            setRowsTemp( response.data.destinatarios );

                            if (response.data.contenido !== null && response.data.dependencia !== null)
                            {
                                setActivarFinalizar(false);
                            }     
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 34 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 34){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true ); 

                            }
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response.status === 200){
                    setDependenciaArray( response.data ); 
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 1 }}>     

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" sx={{ pt: 2 }}>
                                <Tab label="Oficio" { ...a11yProps(0) } onClick = {handleChangeEditor} />
                                <Tab label="Destinatario(s)" { ...a11yProps(1) } onClick = {handleChangeEditor} />                          
                            </Tabs>                         

                            <TabPanel value={ valueTab } index={0}> 

                                <InformacionSeguimiento
                                    noSeguimiento={ 'GOF-' + oficio.idOficio }
                                    actividad={ 'Elaborar oficio' }
                                    tipo={ tipoReferencia }
                                    referencia={ referenciaA }
                                />

                                <Grid container spacing={3} mt={1}>  

                                    <Grid item md={2} xs={3}>
                                        <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                            Número de oficio:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={10} xs={9} >
                                        <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                            { oficio.numeroOficio }
                                        </Typography>
                                    </Grid>

                                    { 
                                        (oficio.numeroExpediente !== null && oficio.expediente.ponencia === null) &&                                        
                                        <>      
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Actor(es):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Demandado(s):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>
                                        </>
                                    }  

                                    { 
                                        (oficio.numeroExpediente !== null && oficio.expediente.ponencia !== null) &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Ponencia:
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.expediente?.ponencia?.descripcion }
                                                </Typography>
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Actor(es):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Demandado(s):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>
                                        </> 
                                    }  

                                    { 
                                        oficio.numeroIrregular !== null && 
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de irregular:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.irregular?.tipoIrregular?.descripcion }
                                                </Typography>
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Actor(es):
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List>      
                                            </Grid>
                                            
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                        {
                                                            oficio.demandado.map((elem, i)=>(
                                                                <ListItem key={ i } disablePadding> 
                                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                        { elem }
                                                                    </Typography>
                                                                </ListItem>                    
                                                            ))
                                                        }                                                                                                
                                                </List>
                                            </Grid> 
                                        </>
                                    } 

                                    { oficio.numeroAmparo !== null && 
                                        <>                                        
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de amparo:
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio.amparo.tipoAmparo.descripcion }
                                                </Typography>
                                            </Grid> 
                
                                            <Grid item md={2} xs={3}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Partes(es):
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={10} xs={9} >
                                                <List disablePadding>                               
                                                    {
                                                        oficio.partes.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                
                                                </List>                            
                                        
                                            </Grid> 
                                        </>
                                    }  

                                    { oficio.exhortoRecibido !== null && 
                                       <>                
                                            <Grid item md={2} xs={6}>                                                             
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de juicio:
                                                </Typography>                                
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >                               
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.exhortoRecibido?.tipoJuicio?.descripcion }
                                                </Typography>                                
                                            </Grid> 
                                            
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Actor(es):
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                               
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                                </List>                            
                                        
                                            </Grid> 
                                    
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                    
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                    {
                                                        oficio.demandado.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem }
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                                  
                                                </List>
                                            </Grid>    

                                            <Grid item md={2} xs={6}>                                                             
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Autoridad del exhorto:
                                                </Typography>                                
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >                               
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.exhortoRecibido?.autoridadExhorto?.descripcion }
                                                </Typography>                                
                                            </Grid>                             
                                            
                                            <Grid item md={2} xs={6}>    
                                                {
                                                    oficio?.exhortoRecibido?.ponencia
                                                    &&                                                     
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                        Ponencia:
                                                    </Typography>   
                                                }                             
                                            </Grid>                            
                
                                            <Grid item md={4} xs={6} >
                                                {
                                                    oficio?.exhortoRecibido?.ponencia
                                                    &&       
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { oficio?.exhortoRecibido?.ponencia?.descripcion }
                                                    </Typography>
                                                }
                                            </Grid> 
                                       </>
                                    }

                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                        
                                            <Select
                                                labelId="select-plantilla-label"
                                                id="select-plantilla"                                            
                                                label="Plantilla"
                                                name="plantilla"
                                                value={ plantilla }
                                                onChange={ (e) => {handleChangePlantilla(e.target.value)} }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    plantillaArray.map((elem)=>(
                                                        <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {   
                                                errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                            }
                                        </FormControl>
                                    </Grid> 

                                    {
                                        juzgado
                                        &&
                                            <Grid item md={12} xs={12} >
                                                <FormControl component="fieldset" disabled={ oficio.observaciones !== '' }>
                                                    <FormLabel component="legend">¿El oficio lo firmará el juez?</FormLabel>
                                                    <RadioGroup row
                                                        aria-label="firmaJuez"
                                                        name="firmaJuez"                        
                                                    >
                                                        <FormControlLabel control={ <Radio checked={ firmaJuez === false } onChange={ ( e ) => { setFirmaJuez( false );  } } />} label="No" />
                                                        <FormControlLabel control={ <Radio checked={ firmaJuez === true } onChange={  ( e ) => { setFirmaJuez( true ); } } />} label="Si" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                    }
                                    
                                    <Grid item md={12} xs={12} >  
                                        <Editor
                                            onInit = { (evt, editor) => editorRef.current = editor }
                                            initialValue = { richText }
                                            apiKey = { ObtenerApiKeyRichText( process.env ) }
                                            init = {
                                                {
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount pagebreak'
                                                    ],
                                                    toolbar: 'undo redo | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                        'removeformat | print pagebreak |  Atajos',
                                                        setup: (editor) => {                                        
                                                            editor.ui.registry.addMenuButton('Atajos', {
                                                                icon: 'bookmark' ,
                                                                tooltip: "Atajos",
                                                                fetch: function (callback) {
            
                                                                    var items = [];

                                                                    if (oficio.numeroExpediente !== null && oficio.expediente.ponencia !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.expediente?.tipoJuicio?.descripcion + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.expediente?.ponencia?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    } 
                                                                    else if (oficio.numeroExpediente !== null && oficio.expediente.ponencia === null) {
                                                                        items = 
                                                                        [
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.expediente?.tipoJuicio?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    } 
                                                                    else if (oficio.numeroIrregular !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroIrregular + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  oficio?.irregular?.tipoIrregular?.descripcion +'</strong>'); } },
                                                                        ];

                                                                    } 
                                                                    else if (oficio.numeroAmparo !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad del amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.amparo?.autoridad?.descripcion +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.amparo.tipoAmparo?.descripcion +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Partes',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaPartesAmparo +'</strong>'); } },
                                                                        ];
                                                                    }   
                                                                    else if (oficio.exhortoRecibido !== null && oficio.exhortoRecibido.ponencia !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExhorto + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.tipoJuicio?.descripcion + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.ponencia?.descripcion + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad del exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.autoridadExhorto?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    } 
                                                                    else if (oficio.exhortoRecibido !== null && oficio.exhortoRecibido.ponencia === null) {
                                                                        items = 
                                                                        [
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExhorto + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad del exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.autoridadExhorto?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    }    

                                                                    callback(items);
                                                                }
                                                            });
                                                        },                                                 
                                                        content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                                }
                                            }  
                                        />
                                    </Grid>     

                                    {   
                                        errors.contenido && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                            </Grid>
                                        )
                                    }

                                    {
                                        oficio.observaciones !== '' 
                                        &&
                                        <>
                                        <Grid item md={3} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray' , fontWeight:'bold'    }}>
                                                Motivo de no autorización:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { oficio.observaciones }
                                            </Typography>
                                        </Grid>
                                        </>
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                        <LoadingButton 
                                            variant="contained" 
                                            onClick={ GuardarOficio } 
                                            startIcon={ <SaveIcon /> }
                                            loading={ loadingGuardar }
                                        > 
                                            Guardar oficio
                                        </LoadingButton>
                                    </Grid>

                                </Grid>        

                            </TabPanel>    

                            <TabPanel value={ valueTab } index={1}>    

                                <InformacionSeguimiento
                                    noSeguimiento={ 'GOF-' + oficio.idOficio }
                                    actividad={ 'Elaborar oficio' }
                                    tipo={ tipoReferencia }
                                    referencia={ referenciaA }
                                />   
                                
                                <Grid container spacing={3} mt={1}>  

                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <Autocomplete                    
                                                disablePortal 
                                                fullWidth              
                                                value={ autocompleteValue }   
                                                options={ dependenciaArray }
                                                onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                                getOptionLabel={ (option) => option.descripcion}                                                
                                                isOptionEqualToValue={(option, value) => option.idDependencia === value.idDependencia }  
                                                renderOption={ ( props, option ) => (
                                                    <li { ...props } key={ option.idDependencia} >                         
                                                        { option.descripcion }
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Dependencia(s)" />}
                                            />
                                            {   
                                                errors.dependencia && ( 

                                                    <Alert color='warning' severity="warning" variant="filled" sx={{mt:2}}> { errors.dependencia } </Alert> 
                                               
                                                )
                                            } 
                                        </FormControl>
                                    </Grid> 

                                    <Grid item md={12} xs={12} > 
                                        <Paper component="div" sx={{  p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        <InputBase 
                                                sx={{ ml: 1, flex: 1 }} 
                                                inputProps={{ 'aria-label': 'Buscar destinatario' }} 
                                                placeholder="Buscar destinatario"     
                                                name="destinatario"
                                                value={ nombreCompleto }   
                                                onChange={ () => { } }             
                                                autoComplete="off"      
                                            /> 
                                                    
                                            <Button 
                                                sx={{ borderRadius: 10, mr: 1 }} 
                                                variant='contained' 
                                                onClick={ handleOpenModalBuscarPersona }
                                                size='small'
                                            >
                                                <SearchIcon />
                                            </Button>         
                        
                                            <Button 
                                                sx={{ borderRadius: 10 }} 
                                                variant='contained' 
                                                onClick={ handleOpenModalNuevaPersona }
                                                size='small'
                                            >
                                                <AddCircleIcon />
                                            </Button>                             
                                        </Paper>
                                    </Grid>
                                    {   
                                        errors.vacio && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.vacio } </Alert> 
                                            </Grid>
                                        )
                                    }

                                    <Grid item md={12} xs={12} style={{ textAlign: 'right', marginTop: '0.5em' }} >
                                        <Button 
                                            variant="contained"
                                            startIcon={ <AddCircleIcon /> }
                                            onClick={ AgregarDestinatario }
                                        >
                                            Agregar destinatario
                                        </Button>
                                    </Grid>      

                                    <Grid item md={12} xs={12} >
                                        <TableContainer style={{ marginTop: '10px' }}>

                                            <Table>

                                                <TableHead>

                                                    <TableRow>
                                                        <TableCell style={{ width:'30%', fontWeight:'bold' }}>Nombre(s)</TableCell>
                                                        <TableCell style={{ width:'30%', fontWeight:'bold' }}>Apellido 1</TableCell>
                                                        <TableCell style={{ width:'30%', fontWeight:'bold' }}>Apellido 2</TableCell>
                                                        <TableCell style={{ width:'10%', fontWeight:'bold' }}></TableCell>
                                                    </TableRow>

                                                </TableHead>

                                                <TableBody>

                                                    {
                                                        (rowsPerPage > 0
                                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : rows
                                                        ).map( (row) => (

                                                            <TableRow key={ row.idPersona }>
                                                                <TableCell> { row.nombres } </TableCell>
                                                                <TableCell> { row.apellido1 } </TableCell>
                                                                <TableCell> { row.apellido2 } </TableCell>        
                                                                <TableCell> 
                                                                    <IconButton color="primary" onClick={ () => { handleClicEliminarParte( row.idPersona ); } }>
                                                                        <DeleteIcon /> 
                                                                    </IconButton>
                                                                </TableCell>                                                
                                                            </TableRow>

                                                        ))
                                                    }

                                                    {
                                                        rows.length === 0 && 
                                                        (
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={4}
                                                                    align="center"
                                                                    style={{ fontWeight: 'bold', fontSize: 15 }}
                                                                >
                                                                    Agregue un destinatario
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>

                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[ ]}
                                                            colSpan={4}
                                                            count={rows.length}                                        
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}                                                                                
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }
                                                        />
                                                    </TableRow>
                                                </TableFooter>

                                            </Table>

                                        </TableContainer>
                                    </Grid> 
                                    {   
                                        errors.duplicado && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.duplicado } </Alert> 
                                            </Grid>
                                        )
                                    }
                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                        <LoadingButton 
                                            variant="contained" 
                                            onClick={ HandleGuardarDestinatarios } 
                                            startIcon={ <SaveIcon /> }
                                            loading={ loadingGuardar }
                                        > 
                                            Guardar destinatario(s)
                                        </LoadingButton>
                                    </Grid>
                                </Grid>

                            </TabPanel>  
                            
                            <Grid container spacing={3} >  
                            
                                <Grid item md={12} xs={12} sx={{ textAlign: 'right', mb: 2 }}> 
                                    
                                    <Button 
                                        variant="contained" 
                                        onClick={ handleOpenConfirmacion } 
                                        endIcon={ <CheckCircleIcon /> } 
                                        disabled = { activarFinalizar || ( rows.length !== rowsTemp.length ) }
                                    > 
                                        Finalizar 
                                    </Button>

                                </Grid> 

                            </Grid> 
            
                        </Container>

                    )
                }

                <ModalNuevaPersona 
                    openModal={ openModalNuevaPersona } 
                    handleCloseModal={ handleCloseModalNuevaPersona } 
                    seleccionarPersona={ seleccionarPersona }
                />

                <ModalBuscarPersona 
                    openModal={ openModalBuscarPersona } 
                    handleCloseModal={ handleCloseModalBuscarPersona } 
                    seleccionarPersona={ seleccionarPersona }
                />

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar el registro del oficio?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained' 
                            loading={ loadingFinalizar }
                        > 
                        Aceptar 
                        </LoadingButton>

                    </DialogActions>

                </Dialog>

                <Dialog open={ openConfirmacionEliminar } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea quitar el registro seleccionado?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacionEliminar }> Cancelar </Button>
                        <Button onClick={ eliminarParte } variant='contained' > Aceptar </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </>
    )

}