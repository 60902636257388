import HttpClienteToken from '../../services/HttpClienteToken';

export const MostrarInbox = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Inbox', null, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ObtenerAsuntosPrioritarios = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Inbox/ObtenerAsuntosPrioritarios', null, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}