import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { LoginScreen } from '../components/auth/LoginScreen';

import { ForgotScreen } from '../components/auth/ForgotScreen';

import { RecoverScreen } from '../components/auth/RecoverScreen';

export const AuthRouter = () => {
    
    return (       
        <Switch>
            <Route 
                exact
                path="/Auth/Login"
                component={ LoginScreen }
            />  

            <Route 
                exact
                path="/Auth/Forgot"
                component={ ForgotScreen }
            />  

            <Route 
                exact
                path="/Auth/Recover/:token"
                component={ RecoverScreen }
            />                  

            <Redirect to="/Auth/Login" />

        </Switch>
    )
}
