import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert,  Box, Button,Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Tab, Tabs, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import AppBarMenu from '../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { RegistrarInformacionScreen } from './celebrarAudiencia/RegistrarInformacionScreen';
import { PartesAudienciaScreen } from './celebrarAudiencia/PartesAudienciaScreen';
import { RegistrarAnexosScreen } from './celebrarAudiencia/RegistrarAnexosScreen';
import { AcordarPromocionesScreen } from './celebrarAudiencia/AcordarPromocionesScreen';
import { AudienciaNotificacionScreen } from './AudienciaNotificacionScreen';

import { CalendarScreen } from './CalendarScreen';

import { types } from '../../types/types';

import { ActualizarEstatus, GuardarActaAudiencia, ObtenerAudienciaDetalle } from '../../actions/audiencias/audienciaActions';
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../actions/admin/templates/templateActions';
import { GenerarCierreInstruccionAudiencia } from '../../actions/sentencias/cierreInstruccionActions';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../helpers/ObtenerApiKeyRichText';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ElaborarActaAudienciaScreen = ( props ) => {

    const editorRef = useRef(null);
  
    const { juzgado } = useSelector( state => state.auth );

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [valueTab, setValueTab] = useState(0);

    const [loading, setLoading] = useState( false );
    const [loadingGuardar, setLoadingGuardar] = useState( false );     
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );  
    const [loadingRegresar, setLoadingRegresar] = useState( false );  

    const [ajaxLoading, setAjaxLoading] = useState( false );
    const [activeFinish, setActiveFinish] = useState( false );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [noSeguimiento, setNoSeguimiento] = useState( 0 );

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [expediente, setExpediente] = useState( { } ); 
    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 

    const [errors, setErrors] = useState( { } );
    const [alert, setAlert] = useState( {  } );

    const [contenido, setContenido] = useState( '' ); 
    const [observaciones, setObservaciones] = useState( { } );

    const [audiencia, setAudiencia] = useState( { } );
 
    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [generarResolucion, setGenerarResolucion] = useState( true );
    const [loadingResolucion, setLoadingResolucion] = useState( false );
    const [openConfirmacionResolucion, setOpenConfirmacionResolucion] = useState( false );
    
    const [resolucion, setResolucion] = useState( '' );
    const [numeroReferencia, setNumeroReferencia] = useState( '' );

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        const contenido = editorRef.current.getContent();         

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        }  

        if( plantilla === 0  ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar una plantilla';
        } 
        
        setErrors( errores );
        return valid;
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const Regresar = async () => {
               
        setLoadingRegresar( true );
        
        const params = {
            'idAudiencia' : Id,
            'idEstatus': 13
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoadingRegresar( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }

                        setLoading( true );

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingRegresar( false );

                    }, 1000);  
                }
            }
        });

    }

    const GuardarActa = async () => {
        
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            'idAudiencia' : Id,
            'contenido': contenido,  
            'idPlantilla' : plantilla,         
        };

        setLoadingGuardar( true );

        await GuardarActaAudiencia( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        
                        setActiveFinish( true );

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });

    }  

    const Finalizar = async () => {

        setLoadingFinalizar( true );

        const params = {
            idAudiencia : Id,
            idEstatus: observaciones?.idEstatusAnterior ? observaciones?.idEstatusAnterior : juzgado ? 17 : 16,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }

                        setLoading( true );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };   

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', expediente?.tipoJuicio ?? "")
                            .replaceAll('[Ponencia]', expediente?.ponencia ?? "")                            
                            .replaceAll('[Numero_Expediente]', expediente?.numeroExpediente ?? "")
                            .replaceAll('[Numero_Cierre]', expediente?.noCierreInstruccion ?? "")   
                            .replaceAll('[Tipo_audiencia]', audiencia?.tipoAudiencia ?? "")      
                            .replaceAll('[Juez]', audiencia?.juez ?? "")
                            .replaceAll('[Secretario_Instructor]', audiencia?.secretario ?? "")
                            .replaceAll('[Fecha_audiencia]', audiencia?.fechaInicio ?? "")  
                            .replaceAll('[Hora_audiencia]', audiencia?.horaInicio ?? "");                         

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }

    const handleChangeEditor = () => {

        if( editorRef.current.getContent() !== '' ){

            setContenido( editorRef.current.getContent() );

        }

    }

    const handleGenerarResolucion = async () => {

        setLoadingResolucion( true );
        
        await GenerarCierreInstruccionAudiencia( { idAudiencia : Id, numeroReferencia: numeroReferencia, } ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {                       
                      
                        setResolucion( response.data.numeroResolucion );
                        setLoadingResolucion( false ); 
                        setOpenConfirmacionResolucion( false );

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingResolucion( false ); 

                    }, 1000);  
                }
            }
        });

    }  

    useEffect(() => {      
    
        async function Obtener(){

            const tipoPlantilla = audiencia?.generarConvenio ? 4 : 3;
            const convenio = tipoPlantilla === 4 ? ( audiencia?.idTipoConvenio === 1 ? 3 : 2 ) : 0;

            const params = {
                'idTipoAcuerdo': tipoPlantilla,
                'idSubTipoAcuerdo': convenio,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ audiencia ])

    useEffect(() => {      

        async function Obtener(){

            const params = {
                'idAudiencia': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerAudienciaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setNoSeguimiento( response.data.idAudiencia ?? '');
                        setAudiencia( response.data );
                        setObservaciones( response.data.observaciones ?? { } );
                        setExpediente( response.data.expediente );
                        setResolucion( response.data.numeroResolucion ?? '' );
                        setGenerarResolucion( response.data.numeroResolucion !== '' );

                        let cadenaA = '';
                        response.data.expediente.actor.forEach((value, key) => { 
                            if (key + 1 !== response.data.expediente.actor.length){ 
                                cadenaA += (value + ', ')
                            } else {
                                cadenaA += (value + ' ')
                            }
                        })

                        let cadenaD = '';
                        response.data.expediente.demandado.forEach((value, key) => { 
                            if (key + 1 !== response.data.expediente.demandado.length){ 
                                cadenaD += (value + ', ')
                            } else {
                                cadenaD += (value + ' ')
                            }
                        })

                        setCadenaActores( cadenaA );
                        setCadenaDemandados( cadenaD );  

                        setTimeout(() => {                          

                            setAjaxLoading( true );

                            dispatch({ 
                                type: types.actualizarDatosAudiencia,
                                payload: response.data
                            });

                            dispatch({ 
                                type: types.agregarPartesAudiencia,
                                payload: response.data.audienciaPartes
                            });

                            dispatch({ 
                                type: types.agregarAnexosAudiencia,
                                payload: response.data.anexos
                            });

                            dispatch({ 
                                type: types.agregarPromocionesAcordadas,
                                payload: response.data.promocionesAcordadas
                            });

                            dispatch({ 
                                type: types.partesExpediente,
                                payload: response.data.partes ?? []
                            });
                            
                            dispatch({ type: types.closeLoading });

                            if( response.data.expediente ){
                             
                                dispatch({
                                    type: types.audienciaSetExpediente,                                
                                    payload: response.data.expediente,                              
                                });                                                             
                            }

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 14 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 14){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true );

                            }

                            if( response.data.actaAudiencia !== '' ){
                             
                                setContenido( response.data.actaAudiencia );
                                setPlantilla( response.data.idPlantilla ?? 0 );

                                setActiveFinish( true );

                            }
                                                        
                        }, 1000);   
                        
                    }

                }

            }); 

        }     
        
        Obtener();

    }, [ Id, dispatch ]) 
    
    if( !ajaxLoading ){

        return <AppBarMenu />;

    }    
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :                      

                    (
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }} >
                            
                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile sx={{ pt: 2 }}>
                                
                                <Tab label="Acta de audiencia" { ...a11yProps(0) } />
                                <Tab label="Información de la audiencia" { ...a11yProps(1) } onClick={ handleChangeEditor } />
                                <Tab label="Parte(s) de la audiencia" { ...a11yProps(2) } onClick={ handleChangeEditor } />     
                                <Tab label="Notificación" { ...a11yProps(3) } onClick={ handleChangeEditor } />                                                            
                                <Tab label="Agendar audiencia" { ...a11yProps(4) } onClick={ handleChangeEditor } />
                                <Tab label="Agregar anexo(s)" { ...a11yProps(5) } onClick={ handleChangeEditor } />       
                                <Tab label="Acordar promocion(es)" { ...a11yProps(6) } onClick={ handleChangeEditor } />  

                            </Tabs>

                            <TabPanel value={ valueTab } index={0}>

                                <Grid container spacing={3} >  
                                    
                                    <Grid item md={12} xs={12}>
                                       <InformacionSeguimiento 
                                            actividad={'Elaborar acta de audiencia'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ audiencia?.expediente?.numeroExpediente }
                                        />
                                    </Grid>

                                    {
                                        audiencia?.idTipoAudiencia === 8
                                        &&
                                        <>
                                            {
                                                resolucion === ''
                                                && 
                                                <>
                                                    <Grid item md={6} xs={12} >

                                                        <FormControl fullWidth>
                                                                                            
                                                            <Grid container spacing={3}>
                                                                
                                                                <Grid item md={8} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                                    <FormLabel sx={{ color: 'black' }} >¿Desea generar un número de resolución?</FormLabel>
                                                                </Grid>
                                                                
                                                                <Grid item md={4} xs={12} >
                                                                    <RadioGroup row >
                                                                        <Grid container >
                                                                            <Grid item md={6} xs={6} >
                                                                                <FormControlLabel value="No" control={ <Radio checked={ generarResolucion === false } onChange={ () => { setGenerarResolucion(false); setNumeroReferencia( '' ); } } /> } label="No" />     
                                                                            </Grid>                                                            
                                                                            <Grid item md={6} xs={6} >
                                                                                <FormControlLabel value="Si" control={ <Radio checked={ generarResolucion === true } onChange={ () => { setGenerarResolucion(true); setNumeroReferencia( '' ); } } /> } label="Si" />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </RadioGroup>
                                                                </Grid>

                                                            </Grid>

                                                        </FormControl>

                                                    </Grid>                                                                                        
                                                   
                                                   {
                                                        generarResolucion
                                                        &&
                                                        <>
                                                            <Grid item md={4} xs={12} > 
                                                                
                                                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                                                
                                                                    <InputBase 
                                                                        sx={{ ml: 1, flex: 1 }} 
                                                                        inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                                                        placeholder={"Escribe el número de resolución (1/" + (new Date().getFullYear()) + ")"} 
                                                                        name="numeroReferencia"
                                                                        value={ numeroReferencia } 
                                                                        onChange={ ( e ) => { setNumeroReferencia( e.target.value ) }}
                                                                        autoComplete="off"
                                                                        disabled={ !generarResolucion }
                                                                    />                                
                                                                </Paper>
                                                                
                                                            </Grid>
                            
                                                            <Grid item md={2} xs={12} textAlign={'end'}> 
                                                                                                    
                                                                <Button 
                                                                    variant="contained" 
                                                                    onClick={ () => setOpenConfirmacionResolucion( true ) } 
                                                                    startIcon={ <AddCircleIcon /> }
                                                                    disabled={ !generarResolucion || numeroReferencia === '' }
                                                                > 
                                                                    Generar
                                                                </Button>

                                                            </Grid>
                                                        </>
                                                   }

                                                </>
                                            }

                                            {
                                                resolucion !== ''
                                                &&
                                                <>
                                                    <Grid item md={4} xs={12}>
                                                        <Typography  variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                                                            Número de resolución
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item md={8} xs={12}>
                                                        <Typography variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                                                            { resolucion }
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }

                                        </>
                                    }

                                    <Grid item md={12} xs={8} mt={1}>                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                        
                                            <Select
                                                labelId="select-plantilla-label"
                                                id="select-plantilla"                                            
                                                label="Plantilla"
                                                name="plantilla"
                                                value={ plantilla }
                                                onChange={ (e) => { handleChangePlantilla(e.target.value); } }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    plantillaArray.map((elem)=>(
                                                        <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 2 }}> { errors.plantilla } </Alert> )
                                        }
                                    </Grid>                    

                                    <Grid item md={12} xs={12} >  

                                        <Editor
                                            onInit = { (evt, editor) => editorRef.current = editor }
                                            initialValue = { contenido }
                                            apiKey = { ObtenerApiKeyRichText( process.env ) }
                                            init = {
                                                {
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount pagebreak'
                                                    ],
                                                    toolbar: 'undo redo | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                        'removeformat | print pagebreak |  Atajos',
                                                        setup: (editor) => {                                        
                                                            editor.ui.registry.addMenuButton('Atajos', {
                                                                icon: 'bookmark' ,
                                                                tooltip: "Atajos",
                                                                fetch: function (callback) {
                                                                    var items = 
                                                                    [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente.tipoJuicio + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente.ponencia + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },                       
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ audiencia.fechaInicio +'</strong>'); } },                                                                                                          
                                                                        { type: 'menuitem', text: 'Hora',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ audiencia.horaInicio +'</strong>'); } },   
                                                                    ];
                                                                    callback(items);
                                                                }
                                                            });
                                                        },                                                 
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                                }
                                            }  
                                        />

                                    </Grid>  

                                    {   
                                        errors.contenido && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                            </Grid>
                                        )
                                    }

                                    {
                                        Object.keys( observaciones ).length !== 0
                                        &&                                
                                        <>
                                            <Grid item md={3} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray'  }}>
                                                    Observaciones:                                        
                                                </Typography>
                                            </Grid>

                                            <Grid item md={9} xs={6} >

                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    <strong>{ observaciones.usuario}:</strong> { observaciones.observacion } 
                                                </Typography>                                                           
                                                
                                            </Grid>
                                        </>
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                        
                                        <LoadingButton 
                                            variant="contained" 
                                            onClick={ GuardarActa } 
                                            startIcon={ <SaveIcon /> }
                                            loading={ loadingGuardar }
                                        > 
                                            Guardar acta de audiencia
                                        </LoadingButton>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1 }}> 
                                    
                                        <Grid container spacing={3}>
                                            
                                            <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 
        
                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ Regresar } 
                                                    startIcon={ <ArrowBackIcon />  } 
                                                    loading={ loadingRegresar }
                                                > 
                                                    Regresar audiencia 
                                                </LoadingButton>
        
                                            </Grid>
        
                                            <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                                
                                                <Button 
                                                    variant="contained" 
                                                    onClick={ () => { setOpenConfirmacion( true ); } } 
                                                    endIcon={ <CheckCircleIcon /> }
                                                    disabled={ !activeFinish }
                                                > 
                                                    Finalizar 
                                                </Button>                                 
        
                                            </Grid>  
        
                                        </Grid>  
                                    </Grid>

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={1}> 

                                <Grid container spacing={3} >  

                                    <Grid item md={12} xs={12}>
                                       <InformacionSeguimiento 
                                            actividad={'Elaborar acta de audiencia'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ audiencia?.expediente?.numeroExpediente }
                                        />
                                    </Grid>

                                    <RegistrarInformacionScreen 
                                        activeFinish={ activeFinish } 
                                        setActiveFinish={ setActiveFinish } 
                                        edit={ false } 
                                        Id= { Id }
                                        setLoading={ setLoading }
                                    />                                

                                </Grid>

                            </TabPanel>
                            
                            <TabPanel value={ valueTab } index={2}>                        

                                <Grid container spacing={3} > 

                                    <Grid item md={12} xs={12}>
                                       <InformacionSeguimiento 
                                            actividad={'Elaborar acta de audiencia'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ audiencia?.expediente?.numeroExpediente }
                                        />
                                    </Grid>
                                    
                                    <PartesAudienciaScreen edit={ false } />

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={3}>                        
                                
                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Elaborar acta de audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>

                                <AudienciaNotificacionScreen 
                                    Id={ Id } 
                                    edit={ false } 
                                    notificaciones={ audiencia?.notificaciones ?? [ ] } 
                                    audiencia={ audiencia } 
                                    setAudiencia={ setAudiencia } 
                                />

                            </TabPanel>

                            <TabPanel value={ valueTab } index={4}>        

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Elaborar acta de audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>   

                                <CalendarScreen edit={ false } />

                            </TabPanel>

                            <TabPanel value={ valueTab } index={5}>     

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Elaborar acta de audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>                   

                                <Grid container spacing={3} sx={{ mt:1 }} >  

                                    <RegistrarAnexosScreen edit={ false } />

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={6}>                        

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Elaborar acta de audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>                   

                                <Grid container spacing={3} sx={{ mt:1 }} >  

                                    <AcordarPromocionesScreen edit={ false } />

                                </Grid>

                            </TabPanel>                           
                        
                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la elaboración del acta de audiencia?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

            <Dialog open={ openConfirmacionResolucion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea generar un número de resolución para esta audiencia?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacionResolucion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ handleGenerarResolucion } 
                        variant='contained' 
                        loading={ loadingResolucion }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
