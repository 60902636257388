import React, { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, Dialog,  DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { PromocionDetalle } from '../../actions/recepcion/promocionActions';

export const ModalDetallePromocion = ( { open, setOpen, idPromocion } ) => {

    const [loading, setLoading] = useState( false );
    const [promocion, setPromocion] = useState( { } );

    useEffect(() => {   
        
        async function Obtener(){
            
            setLoading( true );

            await PromocionDetalle( idPromocion ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setPromocion( response.data );
                            setLoading( false );

                        }, 500);
                        
                    }

                }

            });
        }
        
        if( open ){
            Obtener();
        }

    }, [ open, idPromocion ])      

    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth >
              
                <DialogContent>

                    <Grid item md={12} xs={12} >
                        
                        {
                            loading
                            ?
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                </Box>
                            :
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Tipo de promoción:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { promocion?.tipoPromocion }
                                            </Typography>
                                        </Grid>
                                    </Grid>                                   

                                    {
                                        promocion?.url
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Promoción escaneada:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={ promocion?.url }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                </Box>
                        }
                          
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setPromocion({ }); } } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
