import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert,  Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, InputBase, Paper, TextField, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import AppBarMenu from '../../ui/AppBarMenu';

import { ModalNuevaPersona } from '../../modals/ModalNuevaPersona';
import { ModalBuscarPersona } from '../../modals/ModalBuscarPersona';

import DigitalizarIdentificacionScreen from './DigitalizarIdentificacionScreen';
import { RegistroValorDetalle, GuardarCheque } from '../../../actions/tribunal/registrarValorActions'; 

import moment from 'moment';

import { types } from '../../../types/types';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

export const EntregaChequeScreen = ( { match } ) => {  

    const idRegistroValor = match?.params?.Id;
    
    const dispatch = useDispatch();

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [loading, setLoading] = useState(false);

    const [sendAjax, setSendAjax] = useState(false);

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });    

    const { idPersona, nombreCompleto } = persona;  

    const [registroValor, setRegistroValor] = useState( { } ); 

    const [numeroFolio, setNumeroFolio] = useState( '' );

    const [fechaEntrega, setFechaEntrega] = useState( DateNow );

    const [errors, setErrors] = useState({ });

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };   
    
    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPersona( persona );

        }

    } 

    const handleValidate = () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        return true;

    }

    const Finalizar = async () => {       

        const params = {

            idRegistroValor: idRegistroValor,            
            numeroFolio: numeroFolio,            
            idPersonaBeneficiario: idPersona,           
            fechaEntrega: fechaEntrega,

        };

        await GuardarCheque( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoading( false );

                        setSendAjax( true );
                        
                    }, 1500);

               } 
               
            }           

        });    

    }

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};     
       

        if( numeroFolio === "" ){
            valid = false;
            errores.numeroFolio = 'Seleccione el número de folio';
        }         

        if( fechaEntrega === "" ){
            valid = false;
            errores.fechaEntrega = 'Seleccione la fecha de entrega';
        }          

        if( nombreCompleto === ""  ){      
            valid = false;
            errores.nombreCompleto = 'Seleccione una persona beneficiaria';  
        }
        
        setErrors( errores );
        return valid;
    }



   useEffect(() => {      

        async function Obtener(){
           
            const params = {
                idRegistroValor: match?.params?.Id
            };

            dispatch({ type: types.openLoading }); 

            await RegistroValorDetalle( params ).then( response => {

                if( response ){
                   
                    if( response.status === 200){
                        
                        setRegistroValor( response.data ); 

                        if( response.data.idEstatus === 54 ){

                            setSendAjax( true );
                        }

                        setTimeout(() => {
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                        }, 1000);

                    }

                }
                
            })
          
        }

        if( match ){
            Obtener();
        }


    }, [ match, dispatch ])  

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    
    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            No se tienen actividades pendientes para este trámite
                                        </Typography>

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>  

                            <InformacionSeguimiento
                                noSeguimiento={ 'GRV-' + registroValor?.id }
                                actividad={ 'Registrar valor' }
                                tipo={                                    
                                        registroValor?.numeroExpediente !== ''
                                        ?
                                            'Número de expediente'
                                        :                                   
                                            registroValor?.numeroIrregular !== ''
                                            ?
                                                'Número de irregular'
                                            :
                                                ''                                       
                                }
                                referencia={ 
                                    registroValor?.numeroExpediente !== ''
                                        ?
                                            registroValor?.numeroExpediente
                                        :                                   
                                            registroValor?.numeroIrregular !== ''
                                            ?
                                                registroValor?.numeroIrregular
                                            :
                                                ''    
                                }
                            />     

                            <Grid container spacing={3} mt={1}>                                 

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Referencia:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { registroValor?.referencia }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Cantidad:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { registroValor ? '$' +  registroValor?.cantidad : '' }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Consignante:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    {
                                        registroValor?.personaConsignante !== ''
                                        &&
                                        registroValor?.personaConsignante
                                        
                                    }

                                    {
                                        registroValor?.dependenciaConsignante !== ''
                                        &&
                                        registroValor?.dependenciaConsignante

                                    }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Fecha de exhibición:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { registroValor ? moment(registroValor?.fechaExhibicion).format('DD/MM/YYYY hh:mma') : '' }
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12}> </Grid>
                              
                                <Grid item md={6} xs={12} > 
                                    <Paper component="div" sx={{ p: '12px 0px', display: 'flex', alignItems: 'center', width: '100%' }}>                              
                                        
                                        <InputBase sx={{ ml: 1, flex: 1, }} inputProps={{ 'aria-label': 'Buscar persona' }} 
                                            placeholder="Buscar beneficiario"     
                                            value={ nombreCompleto } 
                                            onChange={ () => { } }                           
                                        /> 

                                         
                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalBuscarPersona }
                                            size='small'                                            
                                        >
                                            <SearchIcon />
                                        </Button>          

                                        <Button 
                                            sx={{ borderRadius: 10, mr: 1 }} 
                                            variant='contained' 
                                            onClick={ handleOpenModalNuevaPersona }
                                            size='small'                                             
                                        >
                                            <PersonAddIcon />
                                        </Button>                            
                                    </Paper>
                                    {   
                                        errors.nombreCompleto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.nombreCompleto } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={3} xs={12} >
                                    <TextField                              
                                        label="Número de folio"                                                     
                                        value={ numeroFolio }
                                        onChange={ ( e ) => { setNumeroFolio( e.target.value ); } }
                                        autoComplete="off"
                                        fullWidth                          
                                    />
                                    {   
                                        errors.numeroFolio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.numeroFolio } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={3} xs={12} >
                                    <TextField                              
                                        label="Fecha de entrega"
                                        type="datetime-local"              
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }} 
                                        value={ fechaEntrega }
                                        onChange={ ( e ) => { setFechaEntrega( e.target.value ); } }                        
                                    />
                                    {   
                                        errors.fechaEntrega && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaEntrega } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={12} xs={12}> 

                                    <Card>
                                        <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                            subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Escanear identificación </Typography> }/>
                                        
                                        <Divider />

                                        <CardContent> 

                                            <Grid container spacing={3} sx={{ mt: 1 }}>
 
                                                <DigitalizarIdentificacionScreen 
                                                    handleValidate={ handleValidate } 
                                                    loading={ loading } 
                                                    setLoading={ setLoading } 
                                                    Finalizar={ Finalizar} 
                                                    idRegistroValor={ idRegistroValor }
                                                />                                               

                                            </Grid>

                                        </CardContent>

                                    </Card>

                                </Grid>

                            </Grid>   
                           
                        </Container>

                    )
                }

            </Box>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

        </>
    )
}
