import { types } from "../types/types";

const initialState = {
    open: false,
    openLogout: false,
}

export const loadingReducer = ( state = initialState, action ) => {

    switch (action.type) {
        case types.openLoading:
            return { open: true }
        case types.closeLoading:
            return { open: false }
        case types.openLoadingLogout:
            return { 
                ...state,
                openLogout: true 
            }
        case types.closeLoadingLogout:
            return { 
                ...state,
                openLogout: false 
            }
        default:
            return state;
    }
    
}