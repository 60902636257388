import React from 'react';

import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router';

import { Button, ListItemText, Typography } from '@mui/material';

import AllInboxIcon from '@mui/icons-material/AllInbox';

import useStyles from '../../../theme/useStyles';

import { types } from '../../../types/types';

const ListItemInbox = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const handleClick = () =>{        

        dispatch({ type: types.eliminarRecepcion }); 
        dispatch({ type: types.eliminarAcuerdoDetalle }); 
        dispatch({ type: types.audienciaClear }); 

        props.history.push("/");
    }

    return (
        
        <ListItemText className={classes.listItemOptions} inset>
            <Button color="inherit" className={classes.buttonIcon} onClick={ handleClick }>
                <AllInboxIcon className={classes.PaddingIcon} />

                <Typography color="inherit" variant="subtitle2" className={classes.ListTypography2} >
                    Inbox
                </Typography>                        
            </Button>
        </ListItemText>
    )
}

export default withRouter(ListItemInbox)
