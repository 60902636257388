import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Container, Grid, Paper } from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { Calendar, momentLocalizer  } from 'react-big-calendar';

import { EventComponentAgenda, EventComponentDay, EventComponentMonth, EventComponentWeek } from '../../helpers/ComponentsAgenda';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import '../../css/Calendar.css';

import moment from 'moment';

import 'moment/locale/es-mx';

import { ModalAgendarAudiencia } from '../modals/ModalAgendarAudiencia';

import { types } from '../../types/types';

import { Agenda } from '../../actions/audiencias/agendaActions';

export const CalendarScreen = ( { edit, enAudiencia } ) => {
    
    const dispatch = useDispatch();
 
    const localizer = momentLocalizer(moment);
    
    const [openModal, setOpenModal] = useState(false);
    
    const [bigCalendarView, setBigCalendarView] = useState(false);
    
    const { audiencias, expediente } = useSelector( state => state.audiencias );

    useEffect(() => {

        async function Obtener(){

            dispatch({ type: types.openLoading });

            await Agenda().then( response => {
        
                if( response ){
    
                    if( response.status === 200){
                     
                        const rows = response.data.map(element => {

                            element.start = new Date( element.start );
                            element.end = new Date( element.end );
                            
                            return element;
                        });
    
                        setTimeout(() => {
                            
                            dispatch({
                                type: types.audienciasLoad,
                                payload: rows
                            });  
    
                            dispatch({ type: types.closeLoading });

                        }, 600);    
    
                    }
    
                }
            
            }); 
        }

        Obtener();        

    }, [ dispatch ]);  

    const handleOpenModal = () => {      
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };       

    const handleSelectEvent = ( { id, idTipoAudiencia, title, expediente, estatus, start, end, motivoCancelacion, idJuez, idSecretario} ) => {
      
        dispatch( {
            type: types.audienciaSelected,
            payload: { 
                id: id,
                title: title,
                idTipoAudiencia: idTipoAudiencia,
                start: start, 
                end: end,          
                expediente: expediente,                
                motivoCancelacion,
                estatus: estatus,
                action: 'Modificar',
                idJuez: idJuez,    
                idSecretario: idSecretario,         
            } 
        });

        handleOpenModal();
    }

    const handleSelectSlot = ( { start } ) => {        

        const days = moment( start ).day();

        if( days === 0 || days === 6 ){
            return false;
        }

        const toDate = new Date();
        const DateDefaultInicio = start.getFullYear() + '-' + ( ( '00' +  ( start.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + start.getDate() ).slice(-2) + ' ' + ( '00' + toDate.getHours() ).slice(-2) +  ':00';  
        const DateDefaultFin = start.getFullYear() + '-' + ( ( '00' +  ( start.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + start.getDate() ).slice(-2) + ' ' + ( '00' + ( toDate.getHours() + 1) ).slice(-2) +  ':00';  
        
        let Inicio = "";
        let Fin = "";

        if( start.getHours() === 0 && start.getMinutes() === 0 ){            
            Inicio = moment(DateDefaultInicio);  
            Fin = moment(DateDefaultFin); 
        }
        else{
            Inicio = start;  
            Fin = start; 
        }         

        dispatch( {
            type: types.audienciaSelected,
            payload: {                
                start: Inicio, 
                end: Fin,         
                action: 'Agregar',
                motivoCancelacion: '',
                idSecretario: expediente?.idSecretario ?? 0,
                idJuez:  expediente?.idJuez ?? 0,       
            } 
        });
        
        handleOpenModal();
    }

    const AgregarAudiencia = () => {

        const toDate = new Date();
        const Inicio = toDate.getFullYear() + '-' + ( ( '00' +  ( toDate.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + toDate.getDate() ).slice(-2) + ' ' + ( '00' +toDate.getHours() ).slice(-2) +  ':00';  
        const Fin = toDate.getFullYear() + '-' + ( ( '00' + ( toDate.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + toDate.getDate() ).slice(-2) + ' ' + ( '00' + ( toDate.getHours() + 1) ).slice(-2) +  ':00'; 
          
        dispatch( {
            type: types.audienciaSelected,
            payload: {                
                start: Inicio, 
                end: Fin,         
                action: 'Agregar',
                motivoCancelacion: '',
                idSecretario: expediente?.idSecretario ?? 0,
                idJuez:  expediente?.idJuez ?? 0,
            } 
        });

        handleOpenModal();
    }

    return (
        <>

            <Box sx={{ minHeight: '100%' }}>  
            
                <Container maxWidth="lg">
                    
                    <Grid container spacing={3} > 

                        {
                            edit
                            &&
                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>  

                                <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarAudiencia }> 
                                    Agregar audiencia
                                </Button> 

                            </Grid>
                       }

                       <Grid item md={12} xs={12} >  

                            <Paper sx={{ padding: 2 }}>
                                <Calendar
                                    defaultDate={ new Date() }
                                    events={ audiencias } 
                                    localizer={ localizer }
                                    formats = {
                                        {
                                        timeGutterFormat: 'hh:mm a'
                                        }
                                    }
                                    min={ new Date(0, 0, 0, 8, 0, 0) }
                                    max={ new Date(0, 0, 0, 20, 0, 0) }
                                    culture="es"
                                    defaultView="month"
                                    startAccessor="start"
                                    endAccessor="end"
                                    messages={
                                        {
                                            next: "Siguiente",
                                            previous: "Anterior",
                                            today: "Hoy",
                                            month: "Mes",
                                            week: "Semana",
                                            day: "Día",
                                            noEventsInRange: <strong>No se encontraron audiencias. </strong>,
                                            showMore: ( target ) => '+' + target + ' más',
                                            date: 'Fecha', 
                                            time: 'Hora', 
                                            event: 'Evento'
                                        }
                                    }
                                    step={ 15 }
                                    timeslots={ 1 }
                                    onSelectEvent={ event => { handleSelectEvent(event); } }
                                    onSelectSlot={ handleSelectSlot }
                                    onView={ ( e ) => { setBigCalendarView( e ); } }
                                    selectable                            
                                    style={ { height: '75vh' } }        
                                    disa
                                    components={{
                                        month: {
                                            event: EventComponentMonth
                                        },
                                        week: {
                                            event: EventComponentWeek
                                        },
                                        day: {
                                            event: EventComponentDay
                                        },
                                        agenda: {
                                            event: EventComponentAgenda
                                        },                                
                                    }}     
                                    eventPropGetter={
                                        function( event ) {
                                            
                                            var style = { };

                                            if( bigCalendarView !== 'agenda' ){

                                                if( event?.estatus === 'Audiencia cancelada' ){
                                                    style = { backgroundColor: '#da4141', color: 'white' };
                                                } 
                                                else if( event?.estatus === 'Audiencia celebrada' ){
                                                    style = { backgroundColor: '#51933d', color: 'white' };
                                                } 
                                                else if( event?.estatus === 'Audiencia en proceso' ){
                                                    style = { backgroundColor: '#31ad98', color: 'white' };
                                                }     

                                            }       
                                         
                                            return { style: style };
                                        }
                                    }     
                                    dayPropGetter={
                                        function( date ){

                                            var style = { };

                                            if( ( moment( date ).day() === 0 || moment( date ).day() === 6 ) ){
                                                style = { backgroundColor: 'rgb(230, 230, 230)' };
                                            }      

                                            return { style: style };
                                        }
                                    }
                                />
                            </Paper>

                        </Grid>

                    </Grid>
                                        
                </Container>
            </Box>

            <ModalAgendarAudiencia openModal={ openModal } handleCloseModal={ handleCloseModal } edit={ edit } enAudiencia={ enAudiencia } />

        </>
    )
}
