import React from 'react';

import { Grid, Typography } from '@mui/material';

export const InformacionSeguimientoSimple = ({ noSeguimiento, actividad }) => {
    return (
        <Grid container> 

            <Grid item md={ actividad ? 4 : 6 } xs={12} mt={1}>

                <Grid container> 

                    <Grid item md={12} xs={6}>
                        <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                            Número de seguimiento:
                        </Typography>
                    </Grid>

                    <Grid item md={12} xs={6}>
                        <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                            { noSeguimiento }
                        </Typography>
                    </Grid>

                </Grid>
            
            </Grid>
            
            {
                actividad
                &&
                <Grid item md={6} xs={12} mt={1}>

                    <Grid container> 

            
                        <Grid item md={12} xs={6}>
                            <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                                Actividad:
                            </Typography>
                        </Grid>
                
                        <Grid item md={12} xs={6}>
                            <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                                { actividad }
                            </Typography>   
                        </Grid>

                    </Grid>

                </Grid>
            }            

        </Grid>

    );
}


export const InformacionSeguimiento = ( { noSeguimiento, actividad, referencia, tipo } ) => {
    return (
        <Grid container spacing={1} mb={1}>            

            <Grid item md={4} xs={12} mt={1}>

                <Grid container> 

                    <Grid item md={12} xs={6}>
                        <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                            Número de seguimiento:
                        </Typography>
                    </Grid>

                    <Grid item md={12} xs={6}>
                        <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                            { noSeguimiento }
                        </Typography>
                    </Grid>
                    
                </Grid>

            </Grid>       

            <Grid item md={5} xs={12} mt={1}>

                <Grid container> 

                    <Grid item md={12} xs={6}>
                        <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                            Actividad:
                        </Typography>
                    </Grid>

                    <Grid item md={12} xs={6}>
                        <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                            { actividad }
                        </Typography>   
                    </Grid>

                </Grid>                              

            </Grid>    

            {
                referencia
                &&
                    <Grid item md={3} xs={12} mt={1}>

                        <Grid container> 

                            <Grid item md={12} xs={6}>
                                <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                                    { tipo }:
                                </Typography>
                            </Grid>

                            <Grid item md={12} xs={6}>
                                <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                                    { referencia }
                                </Typography>
                            </Grid>
                            
                        </Grid>

                    </Grid>
            }

        </Grid>
    )
}
