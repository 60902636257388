import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Container, Grid } from '@mui/material';

import AppBarMenu from '../ui/AppBarMenu';

import { AccountScreen } from './account/AccountScreen';

import { AccountDetailsScreen } from './account/AccountDetailsScreen';

import { types } from '../../types/types';

import { ObtenerPerfil } from '../../actions/admin/users/usuarioAction';

export const ProfileScreen = () => {

    const dispatch = useDispatch();

    const [usuario, setUsuario] = useState( { } );
    
    useEffect(() => {        
        
        async function Obtener(){         
            
            dispatch( { type: types.openLoading } );

            await ObtenerPerfil().then( response => {
                if(response){
                    if( response.status === 200){

                        setTimeout(() => {
                            
                            setUsuario(response.data);

                            dispatch( { type: types.closeLoading } );   

                        }, 1200);                        
                    }                    
                }
            });
        }

        Obtener();

    }, [ dispatch ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{marginTop: '5em', minHeight: '100%', py: 3 }} >
                <Container maxWidth="lg">
                    <Grid container spacing={3} >
                        <Grid item md={4} xs={12} >
                            
                            <AccountScreen { ...usuario } />

                        </Grid>

                        <Grid item md={8} xs={12} >

                            <AccountDetailsScreen usuario={ usuario }/>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
