import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { types } from '../../../../types/types';

import { ActualizarExpedienteTipoJuicio, GenerarCaratula } from '../../../../actions/recepcion/expedienteActions';

import { ModalEditarPartes } from '../modalsExpediente/ModalEditarPartes';
import { ModalEditarPrestaciones } from '../modalsExpediente/ModalEditarPrestaciones';

import { ObtenerTipoJuicio } from '../../../../actions/admin/catalogs/tipojuicioActions';

export const DetalleDemandaScreen = ({ edit }) => {

    const dispatch = useDispatch();

    const { juzgado, tribunal } = useSelector( state => state.auth );
    const { acuerdo, expediente, prestaciones, demanda } = useSelector( state => state.acuerdo );

    const [loading, setLoading] = useState( false );
    const [openCaratula, setOpenCaratula] = useState( false );

    const [loadingConfirmar, setLoadingConfirmar] = useState( false );
    const [openConfirmar, setOpenConfirmar] = useState( false );

    const [open, setOpen] = useState( false );
    const [openPrestaciones, setOpenPrestaciones] = useState( false );

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [loadingSaveTipoJuicio, setLoadingSaveTipoJuicio] = useState( false );

    const [alert, setAlert] = useState( {} );

    const [openAnexos, setOpenAnexos] = useState( false );  
    const [openArchivoAnexo, setOpenArchivoAnexo] = useState( false );  
    const [archivoAnexo, setArchivoAnexo] = useState( '' );

    const handleOpenCaratula = async () => {

        setLoading( true );

        await GenerarCaratula( expediente?.idExpediente ?? 0 ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){

                        dispatch({

                            type: types.actualizarExpedienteAcuerdo,
                            payload: {                                
                                ...expediente,
                                caratula: response.data.caratula,
                            }
                
                        });
                        
                        setLoading( false );

                        setOpenCaratula( true );

                    }

                }

            }

        });    
        
    }

    const eliminarCaratula = () => {

        setLoadingConfirmar( true );

        setTimeout(() => {

            dispatch({

                type: types.actualizarExpedienteAcuerdo,
                payload: {                                
                    ...expediente,
                    caratula: '',
                }
    
            });
            
            setLoadingConfirmar( false );

            setOpenConfirmar( false );

        }, 1000);  

    }

    const handleActualizarTipoJuicio = async () => {

        setLoadingSaveTipoJuicio( true );

        await ActualizarExpedienteTipoJuicio({ idExpediente: expediente.idExpediente, idTipoJuicio: tipoJuicio }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;
                       
                        if( success ){

                            setTimeout(() => {

                                setLoadingSaveTipoJuicio( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                                dispatch({
                                    type: types.actualizarExpedienteAcuerdo,
                                    payload: {
                                        ...expediente,
                                        idTipoJuicio: tipoJuicio,
                                    }
                                });

                            }, 1000);
                        }
                        else{
                            setTimeout(() => {
                                
                                setLoadingSaveTipoJuicio( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);
                        }

                    }
                    else{
                        setTimeout(() => {
                                
                            setLoadingSaveTipoJuicio( false );

                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            }); 

                        }, 1000);
                    }

                }
                else{
                    setLoadingSaveTipoJuicio( false );
                }

            }

        });     

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoJuicio( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoJuicioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        if( expediente && tipoJuicioArray.length > 0 ){
            setTipoJuicio( expediente.idTipoJuicio ?? 0 );
        }

    }, [ tipoJuicioArray, expediente ]);  

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ () => setAlert( { open:false }) }>
                        <Alert onClose={ () => setAlert( { open:false }) } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 3 }} >

                {
                    demanda?.folioBuzon
                    &&
                    <Grid container sx={{ mt: 1, mb: 1 }}>
                        <Grid item md={2} xs={4}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Folio buzón electrónico:
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={8} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { demanda?.folioBuzon }
                            </Typography>
                        </Grid>

                        <Grid item md={2} xs={4}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Anexo(s) Buzón electrónico:
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={8} >
                            <Button
                                variant='contained'
                                onClick={ () => setOpenAnexos(true) }
                            >
                                VER ANEXOS 
                            </Button>       
                        </Grid>
                    </Grid>  
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Número de expediente:
                        </Typography>
                    </Grid>
                    <Grid item md={10} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { expediente?.numeroExpediente }
                        </Typography>
                    </Grid>
                </Grid>  

                {
                    expediente?.ponencia
                    ?
                    <Grid container sx={{ mt: 1, mb: 1 }}>
                    
                        <Grid item md={2} xs={4}>                      
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Ponencia:
                            </Typography>                            
                        </Grid>

                        <Grid item md={10} xs={8} >                      
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.ponencia }
                            </Typography>                                                
                        </Grid>

                    </Grid>    
                    :
                    null
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Tipo de juicio:
                        </Typography>
                    </Grid>
                    <Grid item md={10} xs={8} >
                        {
                            ( acuerdo.idDemandaInicial !== 0 && edit )
                            ?
                                <Grid container spacing={3}>

                                    <Grid item xs={10}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoJuicio-label">Tipo de juicio</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoJuicio-label"
                                                id="select-tipoJuicio"                                            
                                                label="Tipo de juicio"
                                                name="tipoJuicio"                                        
                                                value={ tipoJuicio }
                                                onChange={ ( e ) => setTipoJuicio( e.target.value ) }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                {
                                                    juzgado
                                                    ?                            
                                                        tipoJuicioArray
                                                        .filter( (element ) => { return element.juzgado === true; } )
                                                        .map( ( element ) => (
                                                            <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                                                        ))
                                                    :
                                                        tipoJuicioArray
                                                        .filter( (element ) => { return element.tribunal === true; } )
                                                        .map( ( element ) => (
                                                            <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                                                        ))
                                                }
                                            </Select>
                        
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <IconButton 
                                            onClick={ handleActualizarTipoJuicio }
                                            color='primary'
                                            disabled={ tipoJuicio === expediente.idTipoJuicio }
                                            >
                                            {
                                                loadingSaveTipoJuicio
                                                ?
                                                    <CircularProgress size={20} />
                                                :
                                                    <SaveIcon />
                                            }
                                        </IconButton>                                        
                                    </Grid>

                                </Grid>
                            :
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                    { expediente?.tipoJuicio }
                                </Typography>
                        }                        
                    </Grid>
                </Grid>   

                <Grid container sx={{ mt: 1, mb: 1 }}>

                    <Grid item md={2} xs={4}>

                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Actor(es):
                        </Typography>

                    </Grid>

                    <Grid item md={8} xs={6} >
                        <List disablePadding>
                            {
                                expediente?.actor?.map( ( element, i  ) => (                                    
                                    <ListItem key={ i } disablePadding>                              
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                            { element }
                                        </Typography>                               
                                    </ListItem>
                                ))
                            }                       
                        </List>
                    </Grid>

                    <Grid item md={2} xs={2} sx={{ textAlign: 'right' }} >

                        {
                            acuerdo?.idAmparo === 0 && acuerdo?.idExhortoLibrado === 0 && edit
                            ?
                                <IconButton
                                    color='primary'
                                    onClick={ () => { setOpen( true ); } }
                                >
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            :
                            null
                        }

                    </Grid>

                </Grid>  

                {
                    expediente?.demandado?.length !== 0
                    &&
                        <Grid container sx={{ mt: 1, mb: 1 }}>

                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Demandado(s):
                                </Typography>
                            </Grid>

                            <Grid item md={10} xs={8} >
                                <List disablePadding>
                                    {
                                        expediente?.demandado?.map( ( element, i  ) => (                                    
                                            <ListItem key={ i } disablePadding>                              
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                    { element }
                                                </Typography>                               
                                            </ListItem>
                                        ))
                                    }                             
                                </List>
                            </Grid>

                        </Grid>   
                }

                {
                    expediente?.extintos?.length !== 0
                    &&
                        <Grid container sx={{ mt: 1, mb: 1 }}>

                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Extinto(s):
                                </Typography>
                            </Grid>

                            <Grid item md={10} xs={8} >
                                <List disablePadding>
                                    {
                                        expediente?.extintos?.map( ( element, i  ) => (                                    
                                            <ListItem key={ i } disablePadding>                              
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                    { element }
                                                </Typography>                               
                                            </ListItem>
                                        ))
                                    }                             
                                </List>
                            </Grid>

                        </Grid>   
                }

                {
                    expediente?.centrosTrabajo?.length !== 0
                    &&
                        <Grid container sx={{ mt: 1, mb: 1 }}>

                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Centro de Trabajo:
                                </Typography>
                            </Grid>

                            <Grid item md={10} xs={8} >
                                <List disablePadding>
                                    {
                                        expediente?.centrosTrabajo?.map( ( element, i  ) => (                                    
                                            <ListItem key={ i } disablePadding>                              
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                    { element }
                                                </Typography>                               
                                            </ListItem>
                                        ))
                                    }                             
                                </List>
                            </Grid>

                        </Grid>   
                }

                {
                    prestaciones?.length !== 0
                    &&
                        <Grid container sx={{ mt: 1, mb: 1 }}>
                        
                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Prestacion(es):
                                </Typography>
                            </Grid>

                            <Grid item md={8} xs={6} >
                                <List disablePadding>
                                    {
                                        prestaciones?.map( ( element ) => (                                    
                                            <ListItem key={ element.idTipoPrestacion } disablePadding>                    
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                    { element.descripcion }
                                                </Typography>                                
                                            </ListItem>
                                        ))
                                    }  
                                </List>
                            </Grid>
                            
                            <Grid item md={2} xs={2} sx={{ textAlign: 'right' }} >

                                {
                                    acuerdo?.idAmparo === 0 && acuerdo?.idExhortoLibrado === 0 && edit
                                    ?
                                        <IconButton
                                            color='primary'
                                            onClick={ () => { setOpenPrestaciones( true ); } }
                                        >
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                    :
                                    null
                                }

                            </Grid>                    

                        </Grid>  
                }

                {
                    tribunal
                    &&
                        <Grid container sx={{ mt: 3, mb: 1 }}>
                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >  

                            {
                                expediente?.caratula === ""
                                ?
                                <LoadingButton 
                                    variant="contained" 
                                    startIcon={ <FileCopyIcon /> } 
                                    onClick={ handleOpenCaratula } 
                                    loading={ loading }
                                >
                                    Generar carátula
                                </LoadingButton>
                            :
                                <>
                                    <Button 
                                        variant="contained" 
                                        startIcon={ <PrintIcon /> } 
                                        onClick={ () => { setOpenCaratula( true ); } } 
                                    >
                                        Visualizar carátula
                                    </Button>    

                                    {
                                        acuerdo?.idAmparo === 0 && acuerdo?.idExhortoLibrado === 0 && edit
                                        && 
                                        <IconButton
                                            color='primary'
                                            onClick={ () => { setOpenConfirmar( true ); } }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                        
                                </>                
                            }     

                            </Grid>
                        </Grid>   
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Demanda escaneada:
                        </Typography>
                    </Grid>
                    
                    <Grid item lg={12} md={12} xs={12}>

                        {
                            acuerdo.demandaDigitalizada
                            ?                        
                            <iframe 
                                title='iframe  Demanda o Escrito Inicial'
                                style={{ width: '100%', height: '400px' }} 
                                src={ acuerdo.demandaDigitalizada }                                
                            />
                            :
                            null
                        }

                    </Grid>
                </Grid>                    

            </Box>

            <Dialog open={ openCaratula } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               expediente.caratula
                               ?
                               <iframe title='caratula' src={ expediente.caratula } height="500px" width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró la carátula
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenCaratula( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

            <Dialog open={ openConfirmar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar la carátula?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmar( false ); } }> Cancelar </Button>
                    <LoadingButton onClick={ eliminarCaratula } variant='contained' loading={ loadingConfirmar } > Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog 
                open={ openAnexos } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="sm" 
            >

                <DialogTitle>
                    Anexos
                </DialogTitle>
                
                <DialogContent>
                    
                    <List>

                    {
                        demanda?.anexosBuzonElectronico?.map( ( elem, index ) => (
                            
                            <ListItemButton key={ index } onClick={ () => { setArchivoAnexo( elem.url ); setOpenArchivoAnexo( true ); } }>
                                <ListItemIcon>
                                    <FileCopyIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={ elem.archivo } />
                            </ListItemButton>

                        ))
                    }

                    </List>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenAnexos( false ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  

            <Dialog 
                open={ openArchivoAnexo } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Anexo
                </DialogTitle>
                
                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe title='acuse' src={ archivoAnexo } height="500px" width="100%"></iframe>

                            </Grid>
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenArchivoAnexo( false ); setArchivoAnexo( '' ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog> 

            <ModalEditarPartes
                Id={ expediente?.idExpediente ?? 0 }
                open={ open }
                setOpen={ setOpen }            
            />

            <ModalEditarPrestaciones
                Id={ expediente?.idExpediente ?? 0 }
                open={ openPrestaciones }
                setOpen={ setOpenPrestaciones }            
            />

        </>
    )
}
