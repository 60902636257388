
export const types = {

    login: '[auth] login',
    logout: '[auth] logout',
    error: '[auth] error',

    changeAvatar : '[user] change avatar',

    openLoading: '[loading] open loading',
    closeLoading: '[loading] close loading',

    openLoadingLogout: '[loading] open loading logout',
    closeLoadingLogout: '[loading] close loading logout',

    audienciasLoad: '[audiencias] audiencias Load',
    audienciasAddNew: '[audiencias] audiencias Add New',
    audienciasUpdated: '[audiencias] audiencias Updated',
    audienciaSelected: '[audiencias] audiencias Selected',
    audienciaSelectedClear: '[audiencias] audiencias Selected Clear',
    audienciaSetExpediente: '[audiencias] audiencias Set Expediente',
    audienciaSetAcuerdo: '[audiencias] audiencias Set Acuerdo',
    audienciaClear: '[audiencias] audiencias clear',    
      
    actualizarDatosAudiencia: '[audiencias] actualizar datos audiencias',  
    agregarAnexosAudiencia: '[audiencias] agregar anexos en audiencia',  
    eliminarAnexoAudiencia: '[audiencias] eliminar anexo en audiencia',
    agregarPartesAudiencia: '[audiencias] agregar partes en audiencia',  
    eliminarParteAudiencia: '[audiencias] eliminar parte en audiencia',
    agregarPromocionesAcordadas: '[audiencias] agregar promociones acordadas',
    partesExpediente: '[audiencias] partes expediente audiencia',

    actualizarRecepcion: '[recepcion] actualizar recepcion',
    actualizarDemandaInicial: '[recepcion] actualizar  Demanda o Escrito Inicial',
    actualizarAmparo: '[recepcion] actualizar amparo',
    actualizarIrregular: '[recepcion] actualizar irregular',
    actualizarExhortoRecibido: '[recepcion] actualizar exhorto recibido',
    actualizarPromocion: '[recepcion] actualizar promocion',

    agregarAnexos: '[recepcion] agregar anexos',
    agregarPartes: '[recepcion] agregar partes',
    agregarPrestaciones: '[recepcion] agregar prestaciones',

    eliminarRecepcion: '[recepcion] eliminar recepcion',
    eliminarAnexo: '[recepcion] eliminar anexo',
    eliminarParte: '[recepcion] eliminar parte',
    eliminarPrestacion: '[recepcion] eliminar prestacion',

    actualizarDetalleAcuerdo: '[acuerdo] acuerdo detalle',
    actualizarExpedienteAcuerdo: '[acuerdo] expediente detalle',
    actualizarExhortoLibradoAcuerdo: '[acuerdo] exhorto librado detalle',
    actualizarExhortoRecibidoAcuerdo: '[acuerdo] exhorto recibido detalle',
    actualizarIrregularAcuerdo: '[acuerdo] irregular detalle',
    actualizarAmparoAcuerdo: '[acuerdo] amparo detalle',
    actualizarPromocionAcuerdo: '[acuerdo] promocion detalle',
    actualizarEscritoAcuerdo: '[acuerdo] actualizar escrito',
    actualizarPrestacionesAcuerdo: '[acuerdo] prestaciones detalle',
    actualizarNotificacionesAcuerdo: '[acuerdo] notificaciones detalle',    
    eliminarAcuerdoDetalle: '[acuerdo] eliminar acuerdo detalle',
    actualizarCheckCalificar: '[acuerdo] validar cambios calificar tramite',
    actualizarPartesAcuerdo: '[acuerdo] partes detalle',    
}