import React, { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, Dialog,  DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import moment from 'moment';

import { ObtenerRegistroValorDetalle } from '../../actions/tribunal/registrarValorActions';

export const ModalDetalleValor = ( { open, setOpen, idRegistroValor } ) => {

    const [loading, setLoading] = useState( false );
    const [valor, setValor] = useState( { } );

    useEffect(() => {   
        
        async function Obtener(){
            
            setLoading( true );

            await ObtenerRegistroValorDetalle( idRegistroValor ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setValor( response.data );
                            setLoading( false );

                        }, 500);
                        
                    }

                }

            });
        }
        
        if( open ){
            Obtener();
        }

    }, [ open, idRegistroValor ])      

    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth >
              
                <DialogContent>

                    <Grid item md={12} xs={12} >
                        
                        {
                            loading
                            ?
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                </Box>
                            :
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    
                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Referencia:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { valor?.referencia }
                                            </Typography>
                                        </Grid>
                                    </Grid>                                        

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Cantidad:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold'  }}>
                                                $ { valor?.cantidad }
                                            </Typography>
                                        </Grid>
                                    </Grid>   

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Consignante:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { valor?.personaConsignante }
                                                { valor?.dependenciaConsignante }
                                            </Typography>
                                        </Grid>
                                    </Grid>                                       

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Fecha de exhibición:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { valor?.fechaExhibicion ? moment( valor?.fechaExhibicion ).format('DD-MM-YYY HH:mm a') : '' }
                                            </Typography>
                                        </Grid>
                                    </Grid>   

                                    {
                                        valor?.beneficiario
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item md={4} xs={4}>
                                                <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                    Beneficiario:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={8} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { valor?.beneficiario }
                                                </Typography>
                                            </Grid>
                                        </Grid>   
                                    }                                    

                                    {
                                        valor?.fechaEntregaCheque
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item md={4} xs={4}>
                                                <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                    Fecha de entrega del cheque:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={8} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { valor?.fechaEntregaCheque ? moment( valor?.fechaEntregaCheque ).format('DD-MM-YYY HH:mm a') : '' }
                                                </Typography>
                                            </Grid>
                                        </Grid>   
                                    }

                                    {
                                        valor?.identificacion
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Identificación del beneficiario:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={ valor?.identificacion }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                </Box>
                        }
                          
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setValor({ }); } } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
