import React, { useEffect, useState } from 'react';

import { Box, Card, CardHeader, Container, Grid, IconButton, TextField, Typography } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import AppBarMenu from '../../ui/AppBarMenu';

import { getCatalogFilter } from '../../../selectors/catalogs/getCatalogFilter';

import { catalogsData } from '../../../data/catalogsData';

export const CatalogsScreen = ( props ) => {

    const [catalogos, setCatalogos] = useState([ ]);

    const filter = (e) => {
        if( e.target.value !== ""){
            const catalogosFilter = getCatalogFilter(catalogsData, e.target.value);
            setCatalogos(catalogosFilter);
        }
        else{
            setCatalogos(catalogsData);
        }
    }

    const detailCatalog = ( path, descripcion ) => {

        props.history.push( path );
    }

    useEffect(() => {
        
        setCatalogos(
            catalogsData.sort((a, b) => {
                let fa = a.descripcion.toLowerCase(),
                    fb = b.descripcion.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        );

    }, [ ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{marginTop: '5em', minHeight: '100%', py: 3 }} >

                <Container maxWidth="lg">

                    <Grid container spacing={3} >

                        <Grid item xs={12} md={12}> 

                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />

                                <TextField 
                                    id="input-buscar" 
                                    label="Buscar catálogo" 
                                    variant="standard" 
                                    style={{ width: '100%' }} 
                                    onKeyUp={ filter } 
                                    autoComplete="off"
                                />

                            </Box>

                        </Grid>

                    </Grid>    

                    <Grid sx={{ marginTop: '5px' }} container spacing={3} >   

                        { catalogos.map( ({ id, descripcion, path }) => (
                            
                            <Grid key={ id } item xs={6} sm={4} md={3} >
                        
                                <Card onClick={ () => {} } >
                                    <CardHeader avatar={ <FolderIcon sx={{ color: '#999595' }} /> }
                                        action={  <IconButton edge="start" aria-label="View" onClick={ () => detailCatalog(path, descripcion) } > <EditIcon /> </IconButton>  }
                                        title={ descripcion }
                                    />          
                                </Card>

                            </Grid>

                        ))}   

                    </Grid>

                    <Grid sx={{ marginTop: 2, textAlign: 'center' }} container spacing={3} >
                    {
                        catalogos.length === 0 && (
                            <Grid item md={12} >
                                <Typography variant="h5" sx={{ mt: 1, mb: 1, color: '#999595' }}>
                                    No se encontraron resultados
                                </Typography>
                            </Grid>
                        )
                    }
                    </Grid>
                </Container>
            </Box>

        </>
    )
}
