import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';

import useStyles from '../../../theme/useStyles';

export const ListItemReports = () => {

    const classes = useStyles();
    
    const [anchorEl, anchorElOpen] = useState(null);
  
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        anchorElOpen( e.currentTarget );
    };
  
    const handleClose = () => {
        anchorElOpen( null );
    };

    return (
        <>
            <ListItemText className={classes.listItemOptions} inset>
                <Button color="inherit" className={classes.buttonIcon} onClick={ handleOpen }>
                    <AssessmentIcon className={classes.PaddingIcon} />

                    <Typography color="inherit" variant="subtitle2" className={classes.ListTypography}>
                        Reportes
                    </Typography>
                    
                    <KeyboardArrowDownIcon />                            
                </Button>
            </ListItemText>   

            <Menu elevation={ 2 } anchorEl={ anchorEl } anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }} keepMounted open={ open } onClose={ handleClose }>                
             
                <Link to="/Reportes/Estadistica/" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <BarChartIcon /> </ListItemIcon>
                        <ListItemText>Estadística Judicial</ListItemText>
                    </MenuItem>
                </Link>

            </Menu>
        </>
    )
}
