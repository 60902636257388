import HttpClienteApiKey from '../../services/HttpClienteApiKey';
import HttpClienteToken from '../../services/HttpClienteToken';

import { types } from '../../types/types';

export const Login = ( params, setAlert, setLoading ) => {

    return async ( dispatch ) => {

        await HttpClienteApiKey.post('/Api/Auth/Login', params)
        .then( response => {
            
            const { data } = response;
            
            window.localStorage.setItem("data", JSON.stringify(data));

            dispatch({
                type: types.login,
                payload: data
            });
        })
        .catch((error) => {

            if(error.response){

                const { message } = error.response?.data;

                setAlert({
                    open: true,
                    message: message,
                });
                
                setLoading( false );

            }            
            
        });       
    }
}

export const Logout = ( ) => {

    return async ( dispatch ) => {

        dispatch({ type: types.openLoadingLogout }); 

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            await HttpClienteToken.post('/Api/Auth/Logout', null, token)
            .then( response => {
                if( response.status === 200 ){

                    localStorage.removeItem('data');
                    localStorage.removeItem('lastPath');

                    setTimeout(() => {
                        dispatch({ type: types.logout }); 

                        dispatch({ type: types.closeLoadingLogout }); 
                    }, 1200);
                    
                }
            })
            .catch( ( ) => { });
        }   
       
    }
}

export const ValidarToken = () => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Auth/ValidarToken/', null, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const RecuperarContrasena = ( params ) => {

    return new Promise( (resolve, eject) => {

        HttpClienteApiKey.post('/Api/Auth/RecuperarContrasena/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const ValidarTokenRecuperacion = ( params ) => {

    return new Promise( (resolve, eject) => {

        HttpClienteApiKey.post('/Api/Auth/ValidarTokenRecuperacion/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const RestablecerContrasena = ( params ) => {

    return new Promise( (resolve, eject) => {

        HttpClienteApiKey.post('/Api/Auth/RestablecerContrasena/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}