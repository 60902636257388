import React from 'react';

import { Box } from '@mui/material';

import { CalendarAudienciasSecretarioScreen } from './CalendarAudienciasSecretarioScreen';
import AppBarMenu from '../ui/AppBarMenu';



export const AgendaAudienciasSecretarioScreen = () => {
    return (
        <>    
            <AppBarMenu />

            <Box sx={{ mt: 11 }} >

                <CalendarAudienciasSecretarioScreen edit={ true } />
                
            </Box>

        </>
    )
}
