import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert,  Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Snackbar, Tab, Tabs, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../ui/AppBarMenu';

import { RegistrarInformacionScreen } from './celebrarAudiencia/RegistrarInformacionScreen';
import { PartesAudienciaScreen } from './celebrarAudiencia/PartesAudienciaScreen';
import { RegistrarAnexosScreen } from './celebrarAudiencia/RegistrarAnexosScreen';
import { AcordarPromocionesScreen } from './celebrarAudiencia/AcordarPromocionesScreen';

import { CalendarScreen } from './CalendarScreen';

import { types } from '../../types/types';

import { ActualizarEstatus, ObtenerAudienciaDetalle } from '../../actions/audiencias/audienciaActions';
import { AudienciaNotificacionScreen } from './AudienciaNotificacionScreen';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const VisualizarActaAudienciaScreen = ( props ) => {
  
    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [valueTab, setValueTab] = useState(0);

    const [loading, setLoading] = useState( false );
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );  

    const [ajaxLoading, setAjaxLoading] = useState( false );
    const [activeFinish, setActiveFinish] = useState( false );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [noSeguimiento, setNoSeguimiento] = useState( 0 );
 
    const [alert, setAlert] = useState( {  } );

    const [documento, setDocumento] = useState( '' );
    const [documentoFirmado, setDocumentoFirmado] = useState( '' );

    const [audiencia, setAudiencia] = useState( { } );

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [resolucion, setResolucion] = useState( '' );

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const Finalizar = async () => {

        setLoadingFinalizar( true );

        const params = {
            idAudiencia : Id,
            idEstatus: 47,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );
                        
                        if( !response.data.usuarioActualAsignacion && response.data.idEstatus !== 47 ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion && response.data.idEstatus !== 47 ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }
                        else{
                            setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                        }                        

                        setLoading( true );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };   
  
    useEffect(() => {      

        async function Obtener(){

            const params = {
                'idAudiencia': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerAudienciaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setNoSeguimiento( response.data.idAudiencia ?? '');
                        setAudiencia( response.data ?? { } );
                        setDocumento( response.data.documento ?? "" );
                        setDocumentoFirmado( response.data.documentoFirmado ?? "" );
                        setResolucion( response.data.numeroResolucion ?? '' );

                        setTimeout(() => {                          

                            setAjaxLoading( true );

                            dispatch({ 
                                type: types.actualizarDatosAudiencia,
                                payload: response.data
                            });

                            dispatch({ 
                                type: types.agregarPartesAudiencia,
                                payload: response.data.audienciaPartes
                            });

                            dispatch({ 
                                type: types.agregarAnexosAudiencia,
                                payload: response.data.anexos
                            });

                            dispatch({ 
                                type: types.agregarPromocionesAcordadas,
                                payload: response.data.promocionesAcordadas
                            });

                            dispatch({ 
                                type: types.partesExpediente,
                                payload: response.data.partes ?? []
                            });
                            
                            dispatch({ type: types.closeLoading });

                            if( response.data.expediente ){
                             
                                dispatch({
                                    type: types.audienciaSetExpediente,                                
                                    payload: response.data.expediente,                              
                                });  

                            }

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 19 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 19){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true );

                            }
                                                        
                        }, 1000);   
                        
                    }

                }

            }); 

        }     
        
        Obtener();

    }, [ Id, dispatch ]) 
    
    if( !ajaxLoading ){

        return <AppBarMenu />;

    }    
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

            {
                loading 
                ?                            
                (
                    <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                        <Container maxWidth="lg" >                        

                            <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                
                                <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                
                                    <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                        Se finalizó la actividad
                                    </Typography>

                                    <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                        { mensajeFinalizacion }
                                    </Typography>

                                    {
                                        usuarioAsignacion
                                        ?
                                            <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                { usuarioAsignacion }
                                            </Typography>
                                        :
                                        null
                                    }

                                </CardContent>

                            </Card>                   
                                                    
                        </Container>                    

                    </Box>  
                )
                :                      

                (

                    <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }} >
                        
                        <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile sx={{ pt: 2 }}>
                            
                            <Tab label="Visualizar acta" { ...a11yProps(0) } />
                            <Tab label="Información de la audiencia" { ...a11yProps(1) } />
                            <Tab label="Parte(s) de la audiencia" { ...a11yProps(2) } />      
                            <Tab label="Notificación" { ...a11yProps(3) } />                                       
                            <Tab label="Agendar audiencia" { ...a11yProps(4) } />
                            <Tab label="Agregar anexo(s)" { ...a11yProps(5) } />       
                            <Tab label="Acordar promocion(es)" { ...a11yProps(6) } />  
                            
                        </Tabs>

                        <TabPanel value={ valueTab } index={0}>

                            <Grid container spacing={3} >    

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Visualizar acta de audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>    

                                 {
                                    resolucion !== ''
                                    &&
                                    <>
                                        <Grid item md={4} xs={12}>
                                            <Typography  variant="h6" style={{ fontWeight: 'bold', color: 'gray', fontSize: 16 }} >
                                                Número de resolución
                                            </Typography>
                                        </Grid>

                                        <Grid item md={8} xs={12}>
                                            <Typography variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)', fontSize: 24}}>
                                                { resolucion }
                                            </Typography>
                                        </Grid>
                                    </>
                                }                         
                        
                                <Grid item lg={12} md={12} xs={12}>

                                    {
                                        documentoFirmado
                                        ?
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ documentoFirmado }  
                                            />
                                        :                                        
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ "data:application/pdf;base64," + documento }  
                                            />
                                    }

                                </Grid>   

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                            
                                    <Button 
                                        variant="contained"                                             
                                        onClick={ () => { setOpenConfirmacion( true ); } }
                                        endIcon={ <CheckCircleIcon /> }
                                    > 
                                        Finalizar 
                                    </Button>                                        

                                </Grid>  

                            </Grid>

                        </TabPanel>

                        <TabPanel value={ valueTab } index={1}> 

                                <Grid container spacing={3} >  

                                    <Grid item md={12} xs={12}>
                                        <InformacionSeguimiento 
                                            actividad={'Visualizar acta de audiencia'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ audiencia?.expediente?.numeroExpediente }
                                        />
                                    </Grid>

                                    <RegistrarInformacionScreen 
                                        activeFinish={ activeFinish } 
                                        setActiveFinish={ setActiveFinish } 
                                        edit={ false } 
                                        Id= { Id }
                                        setLoading={ setLoading }
                                    />                                

                                </Grid>

                        </TabPanel>

                        <TabPanel value={ valueTab } index={2}>                        

                            <Grid container spacing={3} > 

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Visualizar acta de audiencia'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ audiencia?.expediente?.numeroExpediente }
                                    />
                                </Grid>
                                
                                <PartesAudienciaScreen edit={ false } />

                            </Grid>

                        </TabPanel>

                        <TabPanel value={ valueTab } index={3}>                        
                                
                            <Grid item md={12} xs={12}>
                                <InformacionSeguimiento 
                                    actividad={'Visualizar acta de audiencia'} 
                                    noSeguimiento={ 'GAU-' + noSeguimiento } 
                                    tipo={'Número de expediente'}
                                    referencia={ audiencia?.expediente?.numeroExpediente }
                                />
                            </Grid>

                            <AudienciaNotificacionScreen 
                                Id={ Id } 
                                edit={ false } 
                                notificaciones={ audiencia?.notificaciones ?? [ ] } 
                                audiencia={ audiencia } 
                                setAudiencia={ setAudiencia } 
                            />

                        </TabPanel>

                        <TabPanel value={ valueTab } index={4}>        

                            <Grid item md={12} xs={12}>
                                <InformacionSeguimiento 
                                    actividad={'Visualizar acta de audiencia'} 
                                    noSeguimiento={ 'GAU-' + noSeguimiento } 
                                    tipo={'Número de expediente'}
                                    referencia={ audiencia?.expediente?.numeroExpediente }
                                />
                            </Grid>   

                            <CalendarScreen edit={ false } />

                        </TabPanel>

                        <TabPanel value={ valueTab } index={5}>     

                            <Grid item md={12} xs={12}>
                                <InformacionSeguimiento 
                                    actividad={'Visualizar acta de audiencia'} 
                                    noSeguimiento={ 'GAU-' + noSeguimiento } 
                                    tipo={'Número de expediente'}
                                    referencia={ audiencia?.expediente?.numeroExpediente }
                                />
                            </Grid>                   

                            <Grid container spacing={3} sx={{ mt:1 }} >  

                                <RegistrarAnexosScreen edit={ false } />

                            </Grid>

                        </TabPanel>

                        <TabPanel value={ valueTab } index={6}>                        

                            <Grid item md={12} xs={12}>
                                <InformacionSeguimiento 
                                    actividad={'Visualizar acta de audiencia'} 
                                    noSeguimiento={ 'GAU-' + noSeguimiento } 
                                    tipo={'Número de expediente'}
                                    referencia={ audiencia?.expediente?.numeroExpediente }
                                />
                            </Grid>                   

                            <Grid container spacing={3} sx={{ mt:1 }} >  

                                <AcordarPromocionesScreen edit={ false } />

                            </Grid>

                        </TabPanel>                    
                
                    </Container>

                )
            }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la visualización del acta de audiencia?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
