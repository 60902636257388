import { types } from "../types/types";

const initialState = {
    audiencias: [],
    Selected: null,
    expediente: {},
    idAcuerdo: 0,
    audiencia: {},
    partesAudiencia: [],
    partesExpediente: [],
    anexos: [],
    promocionesAcordadas: [],
}

export const audienciasReducer = ( state = initialState, action) => {

    switch (action.type) {
        case types.audienciasLoad:
            return {
                ...state,
                audiencias: [
                    ...action.payload
                ],
                Selected: null
            }
        case types.audienciasAddNew:
            return {
                ...state,
                audiencias: [ ...state.audiencias, action.payload ],
                Selected: null
            }       
        case types.audienciasUpdated:
            return {
                ...state,
                audiencias: state.audiencias.map(
                    audiencia => 
                        audiencia.id === action.payload.id
                            ? action.payload.audiencia
                            : audiencia
                ),
                Selected: null
            }  
        case types.audienciaSelected:
            return {
                ...state,
                Selected: {
                    ...action.payload
                }
            }
        case types.audienciaSelectedClear:
            return {
                ...state,
                Selected: null
            }
        case types.audienciaSetExpediente:
            return {
                ...state,
                expediente: action.payload
            }
        case types.audienciaSetAcuerdo:
            return {
                    ...state,
                    idAcuerdo: action.payload
            }
        case types.audienciaClear:
            return { ...initialState 
            }  
        case types.actualizarDatosAudiencia:            
            return {
                ...state,
                audiencia: action.payload
            } 
        case types.agregarPartesAudiencia:            
            return {
                ...state,
                partesAudiencia: action.payload
            } 
        case types.partesExpediente:            
            return {
                ...state,
                partesExpediente: action.payload
            } 
        case types.eliminarParteAudiencia:
            return { 
                ...state,
                partesAudiencia: state.partesAudiencia.filter( element => element.id !== action.payload.id )                    
            } 
        case types.agregarAnexosAudiencia:
            return {
                ...state,
                anexos: action.payload
            }  
        case types.eliminarAnexoAudiencia:
            return { 
                ...state,
                anexos: state.anexos.filter( element => element.idAnexo !== action.payload.idAnexo )                    
            } 
        case types.agregarPromocionesAcordadas:
            return { 
                ...state,
                promocionesAcordadas: action.payload                  
            } 
        default:
            return state;
    }
}