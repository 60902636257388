import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider,  FormControl,  Grid, InputAdornment, InputLabel, MenuItem, Paper,  Select,  Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';

import { DateRangePicker, LoadingButton, LocalizationProvider } from '@mui/lab';

import DateAdapter from '@mui/lab/AdapterMoment';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SourceIcon from '@mui/icons-material/Source';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ModalDetalleExpediente } from '../modals/ModalDetalleExpediente';
import { ModalDetalleSentencia } from '../modals/ModalDetalleSentencia';

import { ConsultaCierreInstruccion } from '../../actions/sentencias/cierreInstruccionActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';

export const CierreInstruccionScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );
    
    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages();

    const [loading, setLoading] = useState( false );
    const [loadingSearch, setLoadingSearch] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [openModalExpediente, setOpenModalExpediente] = useState( false );

    const [openModalSentencia, setOpenModalSentencia] = useState( false );

    const [expediente, setExpediente] = useState( 0 );

    const [sentencia, setSentencia] = useState( 0 );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [fechas, setFechas] = useState( [ null, null ] );

    const [fechaInicio, fechaFin ] = fechas;

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        
        setFechas( [ null, null ] );
        setAutoridad( 0 );
       
        setRows( [ ] ); 
        setRecordsCount( 0 );

        resetPage();

    }

    const handleBuscarOficios = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );                

    }

    const handleMostrarInformacion = (  id ) => {

        
            setExpediente( id );
            setOpenModalExpediente( true );
    }

    const handleMostrarInformacionSentencia = (  id ) => {
        
        setSentencia( id );
        setOpenModalSentencia( true );

    }

    useEffect(() => {
      
        async function Obtener(){     
            
            setLoading( true );
            setRows( [ ] );  

            await ConsultaCierreInstruccion( { page, rowsPerPage, fechaInicio: moment(fechaInicio).format('YYYY-MM-DD'), fechaFin: moment(fechaFin).format('YYYY-MM-DD'), idAutoridad: autoridad } ).then( response => {
                    
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );
                                setRows( response.data.records ?? [ ] );                                
                                setLoadingSearch( false );
                                setLoading( false );  

                            }, 1000);                            

                        }

                    }

                }

            });

        }     
        
        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, fechaInicio, fechaFin, autoridad ]);   

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 2 }}>  
                
                <Container maxWidth="xl">
                    
                    <Grid container spacing={3} >

                        <Grid item lg={12} md={12} xs={12}>

                            <Card>
                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />
                            
                                <CardContent> 

                                    <Grid container spacing={3}>

                                        {     
                                            ( administrador || coordinador )
                                            && 
                                            <Grid item md={4} sm={6} xs={12}> 
                                                                
                                                <FormControl fullWidth>
                                                    <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                                                
                                                        <Select
                                                            labelId="select-autoridad-label"
                                                            id="select-autoridad"                                            
                                                            label="Autoridad"
                                                            name="autoridad"
                                                            value={ autoridad }                                
                                                            onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                            disabled={ filtroAplicado }
                                                        >
                                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                            {
                                                                coordinador
                                                                ?
                                                                autoridadArray 
                                                                .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                                .map( ( elem ) => (
                                                                <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                ))
                                                                :
                                                                    autoridadArray.map( ( elem ) => (
                                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                ))
                                                            }                          
                                                        </Select>
                    
                                                    </FormControl>
                                                                            
                                            </Grid>
                                        }    

                                        <Grid item md={( administrador || coordinador ) ? 6 : 10 } xs={12}>

                                            <LocalizationProvider dateAdapter={ DateAdapter }>

                                                <DateRangePicker
                                                    startText="Fecha inicio"
                                                    endText="Fecha fin"
                                                    value={ fechas }
                                                    onChange={ ( value ) => {  setFechas(value); }}
                                                    disabled={ filtroAplicado }
                                                    renderInput={ ( startProps, endProps ) => (
                                                        <>
                                                            <TextField 
                                                                {...startProps} 
                                                                fullWidth 
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <CalendarTodayIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            
                                                            <Box sx={{ mx: 2 }}> </Box>
                                                            
                                                            <TextField 
                                                                {...endProps} 
                                                                fullWidth 
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <CalendarTodayIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </>
                                                    )}                                    
                                                />

                                            </LocalizationProvider>

                                        </Grid>

                                        <Grid item md={2} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                fullWidth 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarOficios() } }
                                                loading={ loadingSearch }
                                                disabled={ 
                                                    ( administrador || coordinador )
                                                    ?
                                                        fechaInicio === null || fechaFin === null || autoridad === 0
                                                    :
                                                        fechaInicio === null || fechaFin === null
                                                }
                                            > 
                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>  

                                    </Grid>

                                </CardContent>

                            </Card>

                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>

                            <Paper sx={{ width: '100%' }}>

                                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                    <Typography sx={{ pt: 3, pb: 3 }} variant="h4" >
                                        Cierre de instrucción
                                    </Typography>
                                </Toolbar>

                                <TableContainer>

                                    <Table sx={{ minWidth: 500 }} >

                                        <TableHead>

                                            <TableRow>   
                                                <TableCell style={{ width:'10%', fontWeight: 'bold', textAlign: 'center' }}> NO. DE CIERRE </TableCell>  
                                                <TableCell style={{ width:'20%', fontWeight: 'bold', textAlign: 'center' }}> FECHA CITACIÓN </TableCell> 
                                                <TableCell style={{ width:'20%', fontWeight: 'bold' }}> EXPEDIENTE </TableCell>
                                                <TableCell style={{ width:'20%', fontWeight: 'bold' }}> SENTENCIA </TableCell>
                                                <TableCell style={{ width:'20%', fontWeight: 'bold' }}>  </TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                rows.map( ( row, i ) => (

                                                    <TableRow key={ i } >

                                                        
                                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }} > { row.numeroCierre } </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>
                                                       
                                                        <TableCell> 

                                                            {
                                                                row?.numeroExpediente
                                                                &&
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { handleMostrarInformacion(row?.idExpediente ) } }
                                                                >
                                                                    { row?.numeroExpediente ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell> 
                                                        <TableCell> 

                                                            {
                                                                row?.numeroSentencia
                                                                &&
                                                                <Button
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    startIcon={ <SourceIcon /> }
                                                                    size='small'
                                                                    onClick={ () => { handleMostrarInformacionSentencia(row?.idSentencia ) } }
                                                                >
                                                                    { row?.numeroSentencia ?? '' }  
                                                                </Button>
                                                            }

                                                        </TableCell> 
                                                        <TableCell> <strong> { row.referencia }   </strong></TableCell>          
  
                                                    </TableRow>

                                                ))
                                            }

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>

                                                        <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron cierres de instrucción'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TableCell colSpan={2}>
                                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de cierre de instrucción: { recordsCount } </Typography>
                                                </TableCell> 

                                                <TablePagination
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    colSpan={ 10 }
                                                    count={ recordsCount }                                        
                                                    rowsPerPage={rowsPerPage}
                                                    page={ page }                                                                                
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }  
                                                />

                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Paper>

                        </Grid>

                    </Grid>                   

                </Container>

            </Box>

          
            <ModalDetalleExpediente 
                open={ openModalExpediente } 
                setOpen={ setOpenModalExpediente } 
                idExpediente={ expediente } 
            />

            <ModalDetalleSentencia
                open={ openModalSentencia } 
                setOpen={ setOpenModalSentencia } 
                idSentencia={ sentencia } 
            />

        </>
    )
}