import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Card, CardContent, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  Grid, List, ListItem, Paper, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Editor } from '@tinymce/tinymce-react';

import AppBarMenu from '../../ui/AppBarMenu';

import moment from 'moment';

import { types } from '../../../types/types';

import { ObtenerSentenciaPublicaDetalle, GuardarSentenciaPublica, ActualizarEstatus  } from '../../../actions/sentencias/sentenciaPublicaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const ElaborarSentenciaPublicaScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    const [loading, setLoading] = useState( false );
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );   
    const [loadingGuardar, setLoadingGuardar] = useState( false );  

    const [sentenciaPublica, setSentenciaPublica] = useState( { } );    

    const [openConfirmacion, setOpenConfirmacion] = useState( false );
    const [activarFinalizar, setActivarFinalizar] = useState( false );
    const [ajaxLoading, setAjaxLoading] = useState( false );

    const [alert, setAlert] = useState( {  } );
    const [errors, setErrors] = useState( { } );

    const [richText, setRichText] = useState( '' );     

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};       
        
        const contenido = editorRef.current.getContent();                  

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la sentencia';
        }  

        setErrors( errores );
        return valid;
    }

    const handleGuardarSentenciaPublica = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {

            idSentenciaPublica: Id,            
            contenido: contenido
        };

        setLoadingGuardar( true );

        await GuardarSentenciaPublica( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout( () => {
                        
                        setActivarFinalizar( true );

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  

                }
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }

            }

        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }  

    const Finalizar = async () => {     
        
        setLoadingFinalizar( true );

        const params = {
            'idSentenciaPublica' : Id,
            'idEstatus': 32,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );
                       
                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }                    

                        setLoading( true );                       

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }      
   
    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idSentenciaPublica': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerSentenciaPublicaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {                            

                            setSentenciaPublica( response.data );   

                            if( response.data.contenido ){
                            
                                let contenido = response.data.contenido ?? '';                            
                                
                                response?.data?.actor?.forEach(( value ) => {                               
                                    contenido = contenido.replaceAll(value, "********************");
                                });
                            
                                response?.data?.demandado?.forEach(( value ) => { 
                                    contenido = contenido.replaceAll(value, "********************");
                                });

                                if( response.data.numeroExpediente ){
                                    contenido = contenido.replaceAll(response.data.numeroExpediente, "********************");
                                }

                                if( response.data.tipoJuicio ){
                                    contenido = contenido.replaceAll(response.data.tipoJuicio, "********************");
                                }

                                if( response.data.ponencia ){
                                    contenido = contenido.replaceAll(response.data.ponencia, "********************");
                                }

                                setRichText( contenido ); 
                            }

                            if( response.data.contenidoGuardado ){
                                setActivarFinalizar( true );
                            }

                            setAjaxLoading( true );                              

                            dispatch({ type: types.closeLoading });  
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 31 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 31){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true ); 

                            }                            
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])
 
    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (                        
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (       
                        
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>

                            <InformacionSeguimiento 
                                noSeguimiento={ 'GSEP-' + sentenciaPublica?.idSentenciaPublica }
                                actividad={ 'Elaborar sentencia pública' }
                                tipo={ 'Número de expediente' }
                                referencia={ sentenciaPublica?.numeroExpediente }
                            />
                                
                            <Grid container spacing={3} mt={1}>                               

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Número de Sentencia (Resolución):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaPublica?.numeroSentencia }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Sentencia con perspectiva de género:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaPublica?.perspectivaGenero ? 'Si' : 'No' }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Fecha de sentencia:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaPublica?.fechaSentencia ? moment(sentenciaPublica?.fechaSentencia).format('DD-MM-YYYY HH:mm') : '' }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Sentido de sentencia:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaPublica?.sentidoSentencia }
                                    </Typography>
                                </Grid>
                              
                                {
                                    sentenciaPublica?.ponencia
                                    &&   
                                        <Grid item md={2} xs={6}>   
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    sentenciaPublica?.ponencia
                                    &&  
                                        <Grid item md={10} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { sentenciaPublica?.ponencia }
                                            </Typography>
                                        </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        sentenciaPublica?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                    {
                                        sentenciaPublica?.demandado?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                                              
                                    </List>
                                </Grid>                               

                                <Grid item md={12} xs={12} >  
                                    
                                    <Editor
                                        onInit = { (evt, editor) => editorRef.current = editor }
                                        initialValue = { richText }
                                        apiKey = { ObtenerApiKeyRichText( process.env ) }
                                        init = {
                                            {
                                                height: 450,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount pagebreak'
                                                ],
                                                toolbar: 'undo redo | ' +
                                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                    'removeformat | print pagebreak |  Atajos',  
                                                    setup: (editor) => {                                        
                                                        editor.ui.registry.addMenuButton('Atajos', {
                                                            icon: 'bookmark' ,
                                                            tooltip: "Atajos",
                                                            fetch: function (callback) {
                                                                var items = [{ type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },];                                                                
                                                                
                                                                callback(items);
                                                            }
                                                        });
                                                    },                                                                                       
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin: 1rem auto; max-width: 900px; }', 
                                            }
                                        }  
                                    />

                                    {   
                                        errors.contenido && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.contenido } </Alert> )
                                    }

                                </Grid>   
                                
                                {
                                    sentenciaPublica?.observaciones
                                    &&
                                    <>
                                        <Grid item md={3} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray'  }}>
                                                Motivo de no autorización:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { sentenciaPublica?.observaciones }
                                            </Typography>
                                        </Grid>
                                    </>
                                } 

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ handleGuardarSentenciaPublica } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                    > 
                                        Guardar sentencia pública
                                    </LoadingButton>

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                    
                                    <Button 
                                        variant="contained" 
                                        onClick={ () => { setOpenConfirmacion( true ); } } 
                                        endIcon={ <CheckCircleIcon /> } 
                                        disabled = { !activarFinalizar }
                                    > 
                                        Finalizar 
                                    </Button>
                                
                                </Grid>       

                            </Grid>

                        </Container>
                
                    )

                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar el registro de la sentencia pública?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
