import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Dialog, DialogActions, DialogContent, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PrintIcon from '@mui/icons-material/Print';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { ActualizarEstatus, GenerarAcuse } from '../../../actions/recepcion/recepcionActions';

import { types } from '../../../types/types';

import moment from 'moment';

export const GenerarAcuseScreen = ({ handleNext, handleBack, idRecepcion, setAjaxFinish, setUsuarioAsignacion }) => {

    const dispatch = useDispatch();

    const { partes, anexos, recepcion, amparo } = useSelector( state => state.recepcion );
    
    const [loading, setLoading] = useState( false );
    const [loadingBack, setLoadingBack] = useState( false );    
    const [loadingNext, setLoadingNext] = useState( false );

    const [openAcuse, setOpenAcuse] = useState( false );

    const [alerta, setAlerta] = useState({ type: 'error', msg: '' });

    const [open, setOpen] = useState( false );

    const { type, msg } = alerta;

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);        
    };

     const handleOpenAcuse = async () => {

        const params = {
            idRecepcion: idRecepcion
        };

        setLoading( true );

        await GenerarAcuse( params ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){

                        dispatch({

                            type: types.actualizarRecepcion,
                            payload: {                                
                                ...recepcion,
                                acuse: response.data.acuse,
                                acuseFirmado: response.data.acuseFirmado,
                            }
                
                        });
                        
                        setLoading( false );

                        setOpenAcuse( true );                        

                    }

                }
                else{                    

                    setTimeout(() => {
                                            
                        const { message } = response.data;

                        setAlerta( { type: 'error', msg : message } );

                        setOpen( true );

                        setLoading( false );

                    }, 500);
                }

            }

        });    
        
    }

    const Anterior = async() => {

        const params = {
            idRecepcion: idRecepcion,
            idEstatus: 52
        }

        setLoadingBack( true );

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200 ){                    
                        
                    setTimeout(() => {                        
                      
                        setLoadingBack( false );

                        if( response.data.usuarioActualAsignacion ){
                                
                            handleBack();
    
                        }
                        else{

                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );
                            
                            setAjaxFinish( true );
                            
                        }

                    }, 800);  
                    
                }

            }
            
        });

    }

    const validarInformacion = async () => {      
      
        const params = {
            idRecepcion: idRecepcion,
            idEstatus: 3
        }

        setLoadingNext( true );

        await ActualizarEstatus( params ).then( response => {

            if( response ){
                if( response.status === 200 ){                    
                        
                    setTimeout(() => {
                        
                        setLoadingNext( false );

                        if( response.data.usuarioActualAsignacion ){
                                
                            handleNext();
    
                        }
                        else{

                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );
                            
                            setAjaxFinish( true );
                            
                        }

                    }, 800);  
                    
                }
                else{                    

                    setTimeout(() => {
                                            
                        const { message } = response.data;

                        setAlerta( { type: 'error', msg : message } );

                        setOpen( true );

                        setLoadingNext( false );

                    }, 800);
                }
            }
        });  
                   
    }

    return (
        <>
            <Snackbar open={ open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
               
                <Alert onClose={ handleClose } variant="filled" severity={ type }sx={{ width: '100%' }}>
                    { msg }
                </Alert>

            </Snackbar>

            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }} >                
               
            {
                    recepcion.acuse === ""
                    ?
                    <LoadingButton 
                        variant="contained" 
                        startIcon={ <FileCopyIcon /> } 
                        onClick={ handleOpenAcuse } 
                        loading={ loading }
                    >
                        Generar acuse de recibo
                    </LoadingButton>
                :
                    <Button 
                        variant="contained" 
                        startIcon={ <PrintIcon /> } 
                        onClick={ () => { setOpenAcuse( true ); } } 
                    >
                        Visualizar acuse de recibo
                    </Button>                    
                }
                   
           </Grid>   

            <Grid item md={12} xs={12}>

                <TableContainer component={ Paper } > 

                    <Table>

                        <TableBody>

                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                        Información del amparo
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ width: '50%' }}> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Número de seguimiento: </Typography> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > RT-{ recepcion.id } </Typography> 
                                </TableCell>
                            
                                <TableCell sx={{ width: '50%' }}> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Número de amparo: </Typography> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > { amparo.numeroAmparo } </Typography> 
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ width: '50%' }}> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Tipo de amparo: </Typography> 
                                    <Typography style={{ fontSize: 14 }} > { amparo.tipoAmparo }  </Typography> 
                                </TableCell>
                            
                                <TableCell sx={{ width: '50%' }}> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Autoridad del amparo: </Typography> 
                                    <Typography style={{ fontSize: 14 }} > { amparo.autoridadAmparo }  </Typography> 
                                </TableCell>
                            </TableRow>

                            <TableRow>

                                {
                                    amparo?.expediente?.numeroExpediente
                                    ?
                                    (
                                        amparo?.ponencia
                                        ?
                                        <TableCell sx={{ width: '50%' }}> 
                                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Número de expediente: </Typography> 
                                            <Typography style={{ fontSize: 14 }} > { amparo?.expediente.numeroExpediente }  </Typography> 
                                        </TableCell>
                                        :
                                        <TableCell colSpan={2}> 
                                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Número de expediente: </Typography> 
                                            <Typography style={{ fontSize: 14 }} > { amparo?.expediente.numeroExpediente }  </Typography> 
                                        </TableCell>
                                    )
                                    :
                                    null                                    
                                }                              

                                {
                                    amparo?.ponencia
                                    ?               
                                    (
                                        amparo?.expediente?.numeroExpediente
                                        ?
                                        <TableCell sx={{ width: '50%' }}> 
                                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Ponencia: </Typography> 
                                            <Typography style={{ fontSize: 14 }} > { amparo?.ponencia } </Typography> 
                                        </TableCell> 
                                        :
                                        <TableCell colSpan={2}> 
                                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Ponencia: </Typography> 
                                            <Typography style={{ fontSize: 14 }} > { amparo?.ponencia } </Typography> 
                                        </TableCell> 
                                    )
                                    :
                                    null
                                }

                            </TableRow>

                            <TableRow>
                                <TableCell> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Presentante: </Typography> 
                                    <Typography style={{ fontSize: 14 }} > { recepcion.presentante }  </Typography> 
                                </TableCell>
                           
                                <TableCell> 
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Fecha de recepción: </Typography>
                                    <Typography style={{ fontSize: 14 }} > { recepcion.fechaRecepcion }  </Typography> 
                                </TableCell>
                            </TableRow>

                            {
                                ( amparo.fechaReloj || amparo.recibidoBuzonElectronico )
                                &&                           

                                <TableRow>

                                    <TableCell sx={{ width: '50%' }}> 
                                        
                                        {
                                            amparo.fechaReloj
                                            ?
                                            <>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Fecha de reloj: </Typography> 
                                                <Typography style={{ fontSize: 14 }} > { moment(  amparo.fechaReloj ).format('YYYY-MM-DD hh:mm a') }  </Typography> 
                                            </>
                                            :
                                            null
                                        }

                                    </TableCell>
                                
                                    <TableCell sx={{ width: '50%' }}> 

                                        {
                                            amparo.recibidoBuzon
                                            ?
                                            <>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }} > Recibido por búzon electrónico: </Typography>                                     
                                                <Typography style={{ fontSize: 14 }} >  { amparo.recibidoBuzon ? 'Si' : 'No' }  </Typography>                                             
                                            </>
                                            :
                                            null
                                        }
                                        
                                    </TableCell>
                                    
                                </TableRow>
                            }

                        </TableBody>

                    </Table>

                </TableContainer>

            </Grid>

            <Grid item md={12} xs={12}>

                <TableContainer component={ Paper } >

                    <Table>

                        <TableBody>

                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                        Parte(s)
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {
                                partes 
                                ?
                                    partes.map( (element) => (

                                        <TableRow key={ element.id }>
                                            <TableCell> <Typography style={{ fontSize: 14 }} > { element.persona ? element.persona.nombreCompleto : '' } { element.dependencia ? element.dependencia.descripcion : '' } </Typography> </TableCell>
                                            <TableCell> <Typography style={{ fontSize: 14 }} > { element.tipoParte ? element.tipoParte.descripcion : '' } </Typography> </TableCell>
                                        </TableRow>

                                    ))
                                :
                                <TableRow>

                                    <TableCell sx={{ textAlign: 'center' }} colSpan={5}> 

                                        <Typography style={{ fontSize: 14 }} > No se añadieron partes </Typography> 

                                    </TableCell>

                                </TableRow>
                            }                            

                        </TableBody>

                    </Table>

                </TableContainer>

            </Grid>         

             <Grid item md={12} xs={12}>

                <Paper sx={{ width: '100%', mb: 2 }}>

                    <Toolbar>
                        <Typography style={{ fontWeight: 'bold', textTransform: 'uppercase'}}> 
                            Anexo(s) 
                        </Typography>
                    </Toolbar>

                    <TableContainer>

                        <Table>

                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width:'20%' }}>Tipo de anexo</TableCell>                              
                                    <TableCell style={{ width:'20%' }}>Tipo de formato</TableCell>                              
                                    <TableCell style={{ width:'10%' }}>Cantidad</TableCell>    
                                    <TableCell style={{ width:'15%' }}>Número de fojas</TableCell>   
                                    <TableCell style={{ width:'25%' }}>Observaciones</TableCell>     
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                {
                                    anexos.length > 0
                                    ?
                                        anexos.map( (element) => (

                                            <TableRow key={ element.id }>
                                                <TableCell> <Typography style={{ fontSize: 14 }} > { element.tipoAnexo ? element.tipoAnexo.descripcion : '' } </Typography> </TableCell>
                                                <TableCell> <Typography style={{ fontSize: 14 }} > { element.tipoFormato ? element.tipoFormato.descripcion : '' } </Typography> </TableCell>
                                                <TableCell> <Typography style={{ fontSize: 14 }} > { element.cantidad ? element.cantidad : '' } </Typography> </TableCell>
                                                <TableCell> <Typography style={{ fontSize: 14 }} > { element.numeroFojas ? element.numeroFojas : '' } </Typography> </TableCell>
                                                <TableCell> <Typography style={{ fontSize: 14 }} > { element.observaciones ? element.observaciones : '' } </Typography> </TableCell>
                                            </TableRow>

                                        ))
                                    :                                                                      
                                    <TableRow>

                                        <TableCell sx={{ textAlign: 'center' }} colSpan={5}> 

                                            <Typography style={{ fontSize: 14 }} > No se añadieron anexos </Typography> 

                                        </TableCell>

                                    </TableRow>
                                }

                            </TableBody>

                        </Table>

                    </TableContainer>

                </Paper>

            </Grid> 

            <Grid item md={12} xs={12} sx={{ mt: 1 }}>  

                <Grid container spacing={3}>

                    <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  
                        <LoadingButton 
                            variant="contained" 
                            color="inherit"
                            onClick={ Anterior }
                            startIcon={ <ArrowBackIcon /> }
                            loading={ loadingBack }
                        > 
                            Regresar 
                        </LoadingButton>
                    </Grid>

                    <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  
                        <LoadingButton 
                            variant="contained" 
                            onClick={ validarInformacion }
                            endIcon={ <ArrowForwardIcon /> }
                            loading={ loadingNext }
                            disabled={ !recepcion.acuse }
                        > 
                            Siguiente 
                        </LoadingButton>
                    </Grid>                                        

                </Grid>

            </Grid>

            <Dialog open={ openAcuse } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                                recepcion.acuse || recepcion.acuseFirmado
                                ?
                                   recepcion.acuseFirmado
                                   ?
                                   <iframe title='acuse' src={ recepcion.acuseFirmado } height="500px" width="100%"></iframe>
                                   :
                                   <iframe title='acuse' src={ "data:application/pdf;base64," + recepcion.acuse } height="500px" width="100%"></iframe>
                                :
                                <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró el acuse de recibo
                                </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAcuse( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

           </Dialog>  

        </>
    )
}
