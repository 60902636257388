import React, { useState } from 'react';

import { Box, Button, Dialog,  DialogActions, DialogContent, Paper, InputBase, Grid, 
    Card, CardContent, Typography, CardHeader, DialogTitle, Alert, LinearProgress, } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import moment from 'moment';
import 'moment/locale/es-mx';

import { AgregarUsuario, BuscarUsuarioRH } from '../../../actions/admin/users/usuarioAction';

import { ObtenerDescripcionEntidad } from '../../../helpers/ObtenerClaveEntidad';

import { validar } from 'curp';
import { LoadingButton } from '@mui/lab';

export const UserModalScreen = ({ openModal, handleCloseModal, ObtenerUsuario }) => {

    const [curpSearch, setCurpSearch] = useState('');

    const [consultaApi, setConsultaApi] = useState( false );

    const [loadingApi, setLoadingApi] = useState( false );

    const [errors, setErrors] = useState({ });

    const [loading, setLoading] = useState(false);

    const [usuario, setUsuario] = useState({  
        nombres: '',
        apellido1: '',
        apellido2: '',
        correoElectronico: '',            
        curp: '',
        rfc: '',
        fechaNacimiento: '',
        sexo: ''
    });

    const CURPFechaNacimiento = (curp) => {
        var m = curp.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
        
        var anyo = parseInt(m[1],10) + 1900;
        
        if( anyo < 1950 ) anyo += 100;

        var mes = parseInt(m[2], 10)-1;
        var dia = parseInt(m[3], 10);

        return (new Date( anyo, mes, dia ));
    }

    const buscarUsuario = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            setUsuario({ });

            return false;
        }        

        setLoadingApi( true );

        await BuscarUsuarioRH( curpSearch ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, nombres, apellido_Primero, apellido_Segundo, email, curp, rfc, sexo } = response.data;

                    if( success ){                        
                        
                        setTimeout(() => {

                            setConsultaApi( true );

                            const entidad = curp ? ObtenerDescripcionEntidad( curp.substring(11, 13) )?.estado : '';
                            const fec_nac = CURPFechaNacimiento( curp );

                            setUsuario({   
                                nombres: nombres,
                                apellido1: apellido_Primero,
                                apellido2: apellido_Segundo,
                                correoElectronico: email,            
                                curp: curp,
                                rfc: rfc,
                                fechaNacimiento: fec_nac ? moment( fec_nac ).format('YYYY-MM-DD') : '',
                                entidadNacimiento: entidad,
                                sexo: sexo ? sexo === 'H' ? 'Masculino' : 'Femenino' : ''
                            });
                    
                            setCurpSearch('');

                            setLoadingApi( false );

                        }, 1200);
                    }
                    else{                        

                        let errores = {};                      
                        errores.Api = `No se encontro la persona con el CURP: ${ curpSearch }`;                       
                        
                        setErrors( errores );

                        setUsuario( {} );

                        setConsultaApi( false );

                        setLoadingApi( false );
                    }

                }

            }

        });
        
    }

    const guardarUsuario = async () => {

        setLoading( true );

        const data = {
            nombres: usuario.nombres,
            apellido1: usuario.apellido1,
            apellido2: usuario.apellido2,
            correoElectronico: usuario.correoElectronico,
            curp: usuario.curp,
            rfc: usuario.rfc,
            fechaNacimiento: usuario.fechaNacimiento,
            entidadNacimiento: usuario.entidadNacimiento,
            idSexo: usuario.sexo === 'Masculino' ? 1 : 2,
        }

        await AgregarUsuario( data ).then( response => {

            if( response ){
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );

                        ObtenerUsuario( response.data );
                        Cancelar();   

                    }, 1200);
                }  
                if( response.status === 400){                    
                    setTimeout(() => {
                        setLoading( false );

                        setErrors( { Api : response.data.message } );  
                    }, 1200);                                      
                }               
            }
        });   
    }

    const Cancelar = () => {
        
        setErrors( { } );

        setCurpSearch('');

        setUsuario( { } );

        setConsultaApi( false );

        setLoading( false );

        handleCloseModal();
    }
    
    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( curpSearch === '' ){
            valid = false;
            errores.curp = 'Debes escribir la CURP';
        }      
        else if( !validar( curpSearch ) ) {
            valid = false;
            errores.curp = 'La CURP no es valida';
        }
        
        setErrors( errores );
        return valid;
    } 
    
    return (
        <>
            <Dialog open={openModal} maxWidth='md' fullWidth={true} disablePortal >                

                <DialogTitle> Nuevo usuario </DialogTitle>

                <DialogContent>

                    <Grid container spacing={3}>

                        <Grid item lg={12} md={12} xs={12} >
                        
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <InputBase sx={{ ml: 1, flex: 1, }} inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                    placeholder="Buscar por la Clave Única de Registro de Población (CURP)"     
                                    value={ curpSearch } onChange={ (e) => { setCurpSearch( e.target.value.toUpperCase() ) } }                           
                                />

                                <LoadingButton 
                                    sx={{ borderRadius: 10 }}
                                    onClick={ buscarUsuario }
                                    variant='contained'
                                    loading={ loadingApi }
                                >                           
                                    <SearchIcon />                            
                                </LoadingButton>       

                            </Paper>
                        
                        </Grid>                        

                        <Grid item lg={12} md={12} xs={12} >

                            <Card>

                                <CardHeader subheader={ <Typography style={{ color: "black", fontWeight: '500' }}  > Resultado de la busqueda </Typography> } />
                                
                                <CardContent>

                                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Nombre(s):
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.nombres }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Apellido 1:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.apellido1 }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Apellido 2:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.apellido2 }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    CURP:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.curp }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    RFC:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.rfc }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Fecha de nacimiento:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.fechaNacimiento }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Entidad de nacimiento:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.entidadNacimiento }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Sexo:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.sexo }
                                                </Typography>
                                            </Grid>
                                        </Grid>  

                                        <Grid container sx={{ mt: 0.1, mb: 0.1 }}>
                                            <Grid item lg={4} md={4} xs={4} >
                                                <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Correo electrónico:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={8} md={8} xs={8} >
                                                <Typography color="textPrimary" noWrap variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { usuario.correoElectronico }
                                                </Typography>
                                            </Grid>
                                        </Grid>                                     

                                    </Box>

                                </CardContent>   

                            </Card>

                            {
                                errors.Api && (
                                    <Alert color='error' severity="error" variant="filled" style={{ marginTop: '1em' }} >
                                        { errors.Api }
                                    </Alert>
                                )
                            }

                            {
                                errors.curp && (
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} >
                                        { errors.curp }
                                    </Alert>
                                )
                            }

                        </Grid> 

                    </Grid>

                </DialogContent>              
              
                <DialogActions>
                    <Button variant="text" color="primary" onClick={ Cancelar }>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ guardarUsuario } disabled={ !consultaApi }>
                        Agregar Usuario
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loading ? 'block' : 'none', height: 5 }}></LinearProgress>
                
            </Dialog>
        </>
    )
}
