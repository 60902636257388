import React from 'react';

import { useSelector } from 'react-redux';

import { AppBar, Avatar, Backdrop, Box, CircularProgress, List, ListItem, Toolbar, Typography } from '@mui/material';

import useStyles from '../../theme/useStyles';

import ListItemUser from './ListItems/ListItemUser';
import ListItemInbox from './ListItems/ListItemInbox';
import { ListItemNew } from './ListItems/ListItemNew';
import { ListItemSearch } from './ListItems/ListItemSearch';
import { ListItemReports } from './ListItems/ListItemReports';
import { ListItemBooks } from './ListItems/ListItemBooks';
import { ListItemAdmin } from './ListItems/ListItemAdmin';
import { ListItemSearchActivity } from './ListItems/ListItemSearchActivity';

import { ModalTokenExpired } from '../modals/ModalTokenExpired';

const AppBarMenu = () => {
    
    const classes = useStyles();

    const { open } = useSelector(state => state.loading);

    const { administrador, roles, juzgado, tribunal } = useSelector(state => state.auth);

    return (
        <AppBar position="absolute">

            <ModalTokenExpired />

            <Backdrop
                sx={ { color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 } }
                open={ open }
                onClick={ () => {} }
            >
                
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    
                    <CircularProgress size={70} color='inherit' />
                    
                    <Box className={ classes.progressIcon } >
                        
                        <Avatar 
                            src={ process.env.PUBLIC_URL + '/logos/SAJI-ICON-WHITE.png'}
                            alt='logo_saji'
                        />
                        
                    </Box>
                </Box>

            </Backdrop>

            <Toolbar sx={{ pt: 0.3, pb: 0.3 }}>
                        
                <Typography variant="h4" sx={{ marginTop: 1 }} className={ classes.listCenter }>
                    {/* SAJI */}

                    <img style={{ width: 140, height: 52 }}
                        src={ process.env.PUBLIC_URL + '/logos/SAJI-H-WHITE_2.png'}
                        alt='logo_saji'
                        className={ classes.logoDesktop }
                    />
                    
                </Typography>
            
                <List component="nav" className={ classes.listLeft }>
                    
                    <ListItem component="div">  
                        
                        <ListItemInbox />
                        
                        {   
                            ( juzgado || tribunal )
                            &&
                            <ListItemNew />     
                        }

                        <ListItemSearchActivity />  

                        {
                            roles?.VisualizadorConsultas && (                
                                <ListItemSearch />                      
                            )
                        }

                        {
                            roles?.VisualizadorReportes && (                
                                <ListItemReports />                    
                            )
                        }

                        {
                            roles?.VisualizadorLibrosElectronicos && (                
                                <ListItemBooks />                  
                            )
                        }

                        {
                            administrador && (
                                <ListItemAdmin />
                            )
                        }                                              

                    </ListItem>
                    
                </List>            

                <List component="nav" className={ classes.listRight } >
                    
                    <ListItemUser />                
                    
                </List>                 
                
            </Toolbar>        

        </AppBar>
    );    
}


export default AppBarMenu;