import React, { useRef, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, TextField, Typography, Checkbox, FormGroup, Autocomplete } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';
import { ActualizarConvocatoria, ObtenerConvocatoriaDetalle, ActualizarEstatusConvocatoria } from '../../../actions/tribunal/convocatoriaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerDependencias } from '../../../actions/admin/catalogs/dependenciaActions';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const AutorizarConvocatoriaScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const { tribunal } = useSelector( state => state.auth );

    const editorRef = useRef(null);

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [convocatoria, setConvocatoria] = useState( {} );   

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [richText, setRichText] = useState( '' ); 

    const [loadingGuardar, setLoadingGuardar] = useState( false );  
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 
    const [cadenaExtintos, setCadenaExtintos] = useState( '' ); 

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );
    
    const [observacion, setObservacion] = useState( '' );    
    const [autorizar, setAutorizar] = useState( true );
    const [loadingRegresarEscrito, setLoadingRegresarEscrito] = useState( false );  
    const [sendAjax, setSendAjax] = useState(false);

    const [exhorto, setExhorto] = useState( false );
    const [estrados, setEstrados] = useState( false );
    const [centroTrabajo, setCentroTrabajo] = useState( false );

    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );
    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const GuardarConvocatoria = async () => {
    
        const valid = validateFiels();
        
        if( !valid ){
            return false;
        }
        
        const contenido = editorRef.current.getContent();
        const params = {
            'idConvocatoria' : Id,
            'contenido': contenido,
            'idPlantilla' : plantilla,
            'exhorto' : exhorto,
            'estrados' : estrados,
            'centroTrabajo': centroTrabajo,
            'idDependencia': dependencia,
        };

        setLoadingGuardar( true );

        await ActualizarConvocatoria( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {  

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        const contenido = editorRef.current.getContent();

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        } 

        if( plantilla === 0  ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar la plantilla';
        }      
        
        if( observacion.trim() === '' && autorizar === false) {
            valid = false;
            errores.observaciones = 'Debes teclear un motivo de rechazo';
        } 

        if( exhorto === false && centroTrabajo === false && estrados === false ){      
            valid = false;
            errores.enviarConvocatoria = 'Debes seleccionar una opción para públicar la convocatoria';
        }   
        
        setErrors( errores );
        return valid;
    }

    const Finalizar = async () => {
              
        setLoadingFinalizar( true );

        const params = {
            'idConvocatoria' : Id,
            'idEstatus': 76,
        };

        await ActualizarEstatusConvocatoria( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {                        

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }             
                        
                        setSendAjax( true );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    } 

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const Regresar = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
        
        setLoadingRegresarEscrito( true );
        
        const params = {
            'idConvocatoria' : Id,
            'idEstatus': 73,
            'idEstatusAnterior': 75,
            'observacion': observacion
        };

        await ActualizarEstatusConvocatoria( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoadingRegresarEscrito( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }         

                        setSendAjax( true );      

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingRegresarEscrito( false );

                    }, 1000);  
                }
            }
        });

    }

    const handleConfirmar = (value) => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setOpenConfirmacion(value)
    }
    
    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', convocatoria.tipoJuicio ?? "" )
                            .replaceAll('[Ponencia]', convocatoria.ponencia ?? "" )
                            .replaceAll('[Numero_Expediente]', convocatoria.numeroExpediente ?? "" )
                            .replaceAll('[Ciudad]',convocatoria.ciudad ?? "")
                            .replaceAll('[Juez]',convocatoria.juez ?? "")
                            .replaceAll('[Extinto]', cadenaExtintos );

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }     

    const handleChangeCentro = ( value ) => {

        if( value === false){
            setDependencia( 0 );
            setAutocompleteValue( null );
        }

        setCentroTrabajo( value );
    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idTipoAcuerdo': 30,
                'idSubTipoAcuerdo': 0,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response ){

                    if( response.status === 200){
                        setDependenciaArray( response.data ); 
                    }
                    
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idConvocatoria': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerConvocatoriaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {                           

                            setConvocatoria(response.data);   
                            setPlantilla(response?.data?.idPlantilla);
                            setRichText(response.data.contenido);
                            setExhorto( response.data.exhorto );
                            setEstrados( response.data.estrados);
                            setCentroTrabajo( response.data.centroTrabajo);
                            
                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })
                            setCadenaActores(cadenaA);

                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);

                            let cadenaEX = '';
                            response.data.extintos.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.extintos.length){ 
                                    cadenaEX += (value + ', ')
                                } else {
                                    cadenaEX += (value + ' ')
                                }
                            })
                            setCadenaExtintos(cadenaEX);

                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });                              
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  
                                    setSendAjax( true ); 
                                }    
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 75 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                                    setSendAjax( true );
                                }
                            }  
                            
                            if( response.data.idEstatus !== 75){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                    setSendAjax( true );
                                }
                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    useEffect(() => {

        if( convocatoria && dependenciaArray.length > 0 ){

            const findCentro = dependenciaArray.find( ( e ) => e.idDependencia === convocatoria?.dependenciaCentroTrabajo?.idDependencia );

            if( findCentro ){
                setDependencia( convocatoria?.dependenciaCentroTrabajo?.idDependencia ?? 0 );
                setAutocompleteValue( convocatoria?.dependenciaCentroTrabajo ?? null );
            }
        }

    }, [ convocatoria, dependenciaArray ]);       

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>    

                            <InformacionSeguimiento
                                noSeguimiento={ 'GEC-' + convocatoria.idConvocatoria }
                                actividad={ 'Autorizar convocatoria' }
                                tipo={ 'Número de expediente' }
                                referencia={ convocatoria.numeroExpediente ?? "" }
                            />   

                            <Grid container spacing={3} mt={1}>  
                                
                                {
                                    convocatoria.ponencia
                                    &&                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                    }

                                {
                                    convocatoria.ponencia
                                    &&  
                                    <Grid item md={10} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { convocatoria.ponencia }
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        convocatoria?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                              
                                {
                                    convocatoria?.demandado?.length !== 0
                                    &&
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                            {
                                                convocatoria?.demandado?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>  
                                    </>
                                }                              

                                {
                                    convocatoria?.extintos?.length !== 0
                                    &&
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Extinto:
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    convocatoria?.extintos?.length !== 0
                                    &&
                                        <Grid item md={10} xs={6} >
                                            <List disablePadding>
                                            {
                                                convocatoria?.extintos?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>                           
                                }   
                                
                                {
                                    convocatoria?.centrosTrabajo?.length !== 0
                                    &&
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Centro de Trabajo:
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    convocatoria?.centrosTrabajo?.length !== 0
                                    &&
                                        <Grid item md={10} xs={6} >
                                            <List disablePadding>
                                            {
                                                convocatoria?.centrosTrabajo?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>                           
                                } 
                                
                                <Grid item md={6} xs={12} >
                                    <FormControl component="fieldset" disabled>
                                        <FormLabel component="legend" sx={{ fontWeight: 'bold', fontSize: 14 }}>Se publicará la convocatoria por:</FormLabel>
                                        <FormGroup row
                                            aria-label="exhorto"
                                            name="exhorto"                        
                                        >                                            
                                            <FormControlLabel control={<Checkbox checked={ exhorto  } onChange={ (e) => { setExhorto( e.target.checked ); } }  />} label="Exhorto" />
                                            <FormControlLabel control={<Checkbox checked={ estrados  } onChange={ (e) => { setEstrados( e.target.checked ); } }  />} label="Estrados" />
                                            <FormControlLabel control={<Checkbox checked={ centroTrabajo  } onChange={ (e) => handleChangeCentro( e.target.checked ) }  />} label="Centro de trabajo" />
                                        </FormGroup>
                                    </FormControl>
                                    {   
                                        errors.enviarConvocatoria && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.enviarConvocatoria } </Alert> )
                                    }
                                </Grid> 

                                {
                                    tribunal && centroTrabajo
                                    &&   
                                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                                            
                                            <Autocomplete                    
                                                disabled 
                                                fullWidth              
                                                value={ autocompleteValue }   
                                                options={ dependenciaArray }
                                                onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                                getOptionLabel={ (option) => option.descripcion}
                                                isOptionEqualToValue={(option, value) => option.idDependencia === value.idDependencia }
                                                renderOption={ ( props, option ) => (
                                                    <li { ...props } key={ option.idDependencia} >                         
                                                        { option.descripcion }
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Centro de Trabajo" />}              
                                            />

                                        {   
                                            errors.centroTrabajo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.centroTrabajo } </Alert> )
                                        }

                                        </Grid>  
                                }

                                <Grid item md={12} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                    
                                        <Select
                                            labelId="select-plantilla-label"
                                            id="select-plantilla"                                            
                                            label="Plantilla"
                                            name="plantilla"
                                            value={ plantilla }
                                            onChange={ (e) => { handleChangePlantilla(e.target.value) } }
                                            disabled
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                plantillaArray.map((elem)=>(
                                                    <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {   
                                            errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                        }
                                    </FormControl>
                                </Grid>  
                                
                                <Grid item md={12} xs={12} >  
                                    <Editor
                                        onInit = { (evt, editor) => editorRef.current = editor }
                                        initialValue = {richText}
                                        apiKey = { ObtenerApiKeyRichText( process.env ) }
                                        init = {
                                            {
                                                height: 450,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount pagebreak'
                                                ],
                                                    toolbar: 'undo redo | ' +
                                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                    'removeformat | print pagebreak | Atajos',
                                                    setup: (editor) => {                                        
                                                        editor.ui.registry.addMenuButton('Atajos', {
                                                            icon: 'bookmark' ,
                                                            tooltip: "Atajos",
                                                            fetch: function (callback) {
                                                                var items = [];
                                                                
                                                                if( convocatoria?.ponencia ){
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                      
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + convocatoria.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Extinto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaExtintos +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+convocatoria.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ convocatoria.ponencia +'</strong>'); } },
                                                                    ];
                                                                }
                                                                else{                                                                
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                       
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + convocatoria.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Extinto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaExtintos +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+convocatoria.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Juez',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+convocatoria.juez+'</strong>'); } },
                                                                    ];
                                                                }
                                                                callback(items);
                                                            }
                                                        });
                                                    },                                          
                                                    content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                            }
                                        }  
                                    />
                                </Grid> 
                                {   
                                    errors.contenido && ( 
                                        <Grid item md={12} xs={12}>
                                            <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                        </Grid>
                                    )
                                }  

                                 <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ GuardarConvocatoria } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                    > 
                                        Guardar convocatoria
                                    </LoadingButton>

                                </Grid> 

                                <Grid item md={4} xs={12} >
                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend"  sx={{ color: 'rgba(0, 0, 0, 1)' }}>¿Desea autorizar la convocatoria?</FormLabel>
                                        <RadioGroup row
                                            aria-label="autorizarAcuerdo"
                                            defaultValue="si"
                                            name="radio-buttons-group"  
                                        >
                                            <FormControlLabel value="si" control={ <Radio checked={ autorizar === true } onChange={ () => { setAutorizar(true) } } />}  label="Si" />
                                            <FormControlLabel value="no" control={ <Radio checked={ autorizar === false } onChange={ () => { setAutorizar(false) } } />} label="No" />                                                
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {
                                    !autorizar && (
                                        <>
                                            <Grid item md={8} xs={12} >
                                                <TextField                              
                                                    label="Motivo"                                                     
                                                    multiline
                                                    rows={4}  
                                                    fullWidth                          
                                                    name="observacion"   
                                                    variant="outlined"
                                                    value={ observacion }
                                                    onChange={ ( e ) => { setObservacion( e.target.value ) }}
                                                />
                                                {   
                                                    errors.observaciones && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.observaciones } </Alert> )
                                                }
                                            </Grid>                                        
                                        </>
                                    )
                                }                                 

                                <Grid item md={12} xs={12} sx={{ mt: 1 }}> 
                                    
                                    <Grid container spacing={3}>
                                    
                                        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 

                                            <LoadingButton 
                                                variant="contained" 
                                                onClick={ Regresar } 
                                                startIcon={ <ArrowBackIcon />  } 
                                                disabled={ autorizar }
                                                loading={ loadingRegresarEscrito }
                                            > 
                                                Regresar escrito 
                                            </LoadingButton>
 
                                        </Grid>
                                        
                                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                            
                                            <LoadingButton 
                                                variant="contained" 
                                                onClick={ () => { handleConfirmar( true ); } } 
                                                endIcon={ <CheckCircleIcon />  } 
                                                disabled={ !autorizar }
                                                
                                            > 
                                                Autorizar convocatoria 
                                            </LoadingButton>

                                        </Grid>

                                    </Grid>

                                </Grid>       

                            </Grid>   
                           
                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la autorización de la convocatoria?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>
        </>
    )
}
