import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Button, Card, CardContent, CardHeader, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField, Typography } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ObtenerNacionalidades } from '../../../actions/admin/catalogs/nacionalidadActions';
import { ObtenerEstados } from '../../../actions/admin/catalogs/estadoActions';
import { ObtenerTiposDomicilios } from '../../../actions/admin/catalogs/tipoDomicilioActions';
import { ObtenerMunicipios } from '../../../actions/admin/catalogs/municipioActions';
import { ObtenerAsentamientos, ObtenerCodigosPostales } from '../../../actions/admin/catalogs/asentamientoActions';

import { CambiarInformacion } from '../../../actions/admin/users/usuarioAction';

import { types } from '../../../types/types';

export const AccountDetailsScreen = ({ usuario }) => {

    const dispatch = useDispatch();

    const [alert, setAlert] = useState({  });
    const [errors, setErrors] = useState({ });
    
    const [correoElectronico, setCorreoElectronico] = useState( '' );
    const [telefonoFijo, setTelefonoFijo] = useState( '' );
    const [telefonoCelular, setTelefonoCelular] = useState( '' );

    const [nacionalidad, setNacionalidad] = useState( 0 );
    const [nacionalidadArray, setNacionalidadArray] = useState( [] );

    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );

    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );

    const [codigoPostal, setCodigoPostal] = useState( 0 );
    const [codigoPostalArray, setCodigoPostalArray] = useState( [] );

    const [asentamiento, setAsentamiento] = useState( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState( [] );

    const [tipoDomicilio, setTipoDomicilio] = useState( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState( [] );
    
    const [descripcion, setDescripcion] = useState( '' );
    const [numero, setNumero] = useState( '' );
    const [referencia, setReferencia] = useState( '' );

    const [contrasena, setContrasena] = useState( '' );
    const [viewContrasena, setViewContrasena] = useState( false );

    const [confirmarContrasena, setConfirmarContrasena] = useState( '' );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const handleContrasena = ( e ) => {
        setContrasena( e.target.value );
    }
    
    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const handleConfirmarContrasena = ( e ) => {
        setConfirmarContrasena( e.target.value );
    }
    
    const handleChangeViewConfirmarContrasena = () => {
        setViewConfirmarContrasena( ( prev ) => !prev );
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};
               
        if( contrasena === '' ){
            valid = false;
            errores.contrasena = 'Debes escribir la contraseña';
        }
        else if( confirmarContrasena === '' ){
            valid = false;
            errores.contrasena = 'Debes confirmar la contraseña';
        }
        else if( contrasena !== confirmarContrasena ){
            valid = false;
            errores.contrasena = 'Las contraseñas no coinciden';
        }

        if( nacionalidad === 0 ){
            valid = false;
            errores.nacionalidad = 'Debes seleccionar una nacionalidad';
        }

        if( estado === 0 ){
            valid = false;
            errores.estado = 'Debes seleccionar un estado';
        }
        else if( municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }
        else if( codigoPostal === 0 ){
            valid = false;
            errores.codigoPostal = 'Debes seleccionar un código postal';
        }
        else if( asentamiento === 0 ){
            valid = false;
            errores.asentamiento = 'Debes seleccionar un asentamiento';
        }

        if( tipoDomicilio === 0 ){
            valid = false;
            errores.tipoDomicilio = 'Debes seleccionar un tipo de domicilio';
        }

        if( descripcion === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }

        if( numero === '' || numero === 0 ){
            valid = false;
            errores.numero = 'Debes escribir el número int./ext.';
        }

        if( telefonoFijo !== '' && telefonoFijo.length < 10 ){
            valid = false;
            errores.telefonoFijo = 'El número de telefono debe ser de 10 dígitos';
        }

        if( telefonoCelular !== '' && telefonoCelular.length < 10 ){
            valid = false;
            errores.telefonoCelular = 'El número de celular debe ser de 10 dígitos';
        }
       
        setErrors( errores );
        return valid;
    } 

    const GuardarInformacion = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        const params = {
            'correoElectronico': correoElectronico,
            'contrasena': contrasena,   
            'telefonoFijo': telefonoFijo,
            'telefonoCelular': telefonoCelular,
            'idAsentamiento': asentamiento,   
            'idNacionalidad': nacionalidad,
            'idTipoDomicilio': tipoDomicilio,   
            'descripcion': descripcion,   
            'numero': numero,   
            'referencia': referencia
        };

        dispatch( { type: types.openLoading } );

        await CambiarInformacion( params ).then(response => {
            if(response){
                if( response.status === 200 ){
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Se guardó la información correctamente',
                            type: 'success'
                        }); 

                        dispatch( { type: types.closeLoading } );  
                        
                    }, 1200);  
                    
                }
                else{

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 

                        dispatch( { type: types.closeLoading } );  
                        
                    }, 1200);  
                    
                }
            }
        });
        
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    //Obtener nacionalidades
    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerNacionalidades().then( response => {
                if(response){
                    if( response.status === 200){
                        setNacionalidadArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    //Obtener estados
    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerEstados().then( response => {
                if(response){
                    if( response.status === 200){
                        setEstadoArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    //Obtener municipios
    useEffect(() => {
        
        async function Obtener(){     

            setMunicipio( 0 );

            if( estado !== 0 ){       
                await ObtenerMunicipios( estado ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setMunicipioArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ estado ]);

    //Obtener codigos postales
    useEffect(() => {
        
        async function Obtener(){     

            setCodigoPostal( 0 );

            if( municipio !== 0 ){       
                await ObtenerCodigosPostales( municipio ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setCodigoPostalArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ municipio ]);

    //Obtener asentamientos
    useEffect(() => {
        
        async function Obtener(){     

            setAsentamiento( 0 );

            if( codigoPostal !== 0 ){       

                const params = {
                    'idMunicipio': municipio,
                    'codigoPostal' : codigoPostal
                };

                await ObtenerAsentamientos( params ).then( response => {
                    if(response){
                        if( response.status === 200){
                            setAsentamientoArray( response.data );
                        }
                    }
                });
            }

        }

        Obtener();

    }, [ municipio, codigoPostal ]);

    //Obtener tipo domicilios
    useEffect(() => {
        
        async function Obtener(){            
            await ObtenerTiposDomicilios().then( response => {
                if(response){
                    if( response.status === 200){
                        setTipoDomicilioArray( response.data );
                    }
                }
            });
        }

        Obtener();

    }, [ ]);

    // obtener datos del perfil de la persona
    useEffect(() => {

        if( Object.keys( usuario ).length !== 0 ){

            setCorreoElectronico( usuario.correoElectronico ?? '' );
            setContrasena( usuario.contrasena ?? '' );
            setConfirmarContrasena( usuario.contrasena ?? '' );

            setTelefonoFijo( usuario.telefonoFijo ?? '' );
            setTelefonoCelular( usuario.telefonoCelular ?? '' );
            setNacionalidad( usuario.idNacionalidad ?? 0 );

            setEstado( usuario.idEstado ?? 0 );

            setTipoDomicilio(usuario.idTipoDomicilio ?? 0 );
            setDescripcion( usuario.descripcion ?? '' );
            setNumero( usuario.numero === 0 ? '' : usuario.numero );
            setReferencia( usuario.referencia ?? '' );

        }

    }, [ usuario ]); 

    useEffect(() => {
        
        if( Object.keys( usuario ).length !== 0 ){
        
            setMunicipio( 0 );

            if( municipioArray.length > 0 && usuario.idEstado === estado){
                setMunicipio( usuario.idMunicipio ?? 0 );
            }

        }


    }, [ usuario, estado, municipioArray ]);

    useEffect(() => {
        
        if( Object.keys( usuario ).length !== 0 ){
        
            setCodigoPostal( 0 );

            if( codigoPostalArray.length > 0 && usuario.idMunicipio === municipio){
                setCodigoPostal( usuario.codigoPostal ?? 0 );
            }

        }


    }, [ usuario, municipio, codigoPostalArray ]);

    useEffect(() => {
        
        if( Object.keys( usuario ).length !== 0 ){
        
            setAsentamiento( 0 );

            if( asentamientoArray.length > 0 && usuario.codigoPostal === codigoPostal){
                setAsentamiento( usuario.idAsentamiento ?? 0 );
            }

        }


    }, [ usuario, codigoPostal, asentamientoArray ]);

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }        
        
            <form autoComplete="off" >
                <Grid container spacing={3} >

                    <Grid item lg={12} md={12} xs={12} >
                        
                        <Card>
                            <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información personal </Typography> }/>
                            
                            <Divider />
                        
                            <CardContent> 
                                <Grid container spacing={3} >
                                    <Grid item md={12} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Correo electrónico"
                                            name="correoElectronico"
                                            variant="outlined"
                                            disabled
                                            value={ correoElectronico }                                
                                            onChange={ () => {} }
                                        />
                                    </Grid>
                                        
                                    <Grid item md={6} xs={12} >
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Contraseña"
                                                name="contrasena"
                                                type={ viewContrasena ? 'text' : 'password'}
                                                value={ contrasena }                                
                                                onChange={ handleContrasena }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                            {
                                                                viewContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>                                    
                                    </Grid>                                            

                                    <Grid item md={6} xs={12} >
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Confirmar contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Confirmar contraseña"
                                                name="confirmarContrasena"
                                                type={ viewConfirmarContrasena ? 'text' : 'password'}
                                                value={ confirmarContrasena }                                
                                                onChange={ handleConfirmarContrasena }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                            {
                                                                viewConfirmarContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>  

                                    {   
                                        errors.contrasena && ( 
                                            <Grid item md={12} xs={12} >
                                                <Alert color='warning' severity="warning" variant="filled" > { errors.contrasena } </Alert> 
                                            </Grid>
                                        )
                                    }

                                    <Grid item md={6} xs={12} >
                                        
                                        <TextField
                                            fullWidth
                                            label="Teléfono fijo"
                                            name="telefonoFijo"
                                            variant="outlined"
                                            type="number"
                                            value={ telefonoFijo }
                                            onChange={ ( e ) => { setTelefonoFijo( e.target.value ) } }                                
                                        />

                                        {   
                                            errors.telefonoFijo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.telefonoFijo } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12} >

                                        <TextField
                                            fullWidth
                                            label="Teléfono celular"
                                            name="telefonoCelular"                                
                                            type="number"
                                            variant="outlined"
                                            value={ telefonoCelular }
                                            onChange={ ( e ) => { setTelefonoCelular( e.target.value ) } }                                                          
                                        />

                                        {   
                                            errors.telefonoCelular && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.telefonoCelular } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-nacionalidad-label">Nacionalidad</InputLabel>
                                        
                                            <Select
                                                labelId="select-nacionalidad-label"
                                                id="select-nacionalidad"
                                                label="Nacionalidad"
                                                name="nacionalidad"
                                                value={ nacionalidad }
                                                onChange={ ( e ) => { setNacionalidad( e.target.value ) } }
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    nacionalidadArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idNacionalidad } value={ elem.idNacionalidad }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.nacionalidad && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.nacionalidad } </Alert> )
                                        }
                                        
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                
                    </Grid>

                    <Grid item lg={12} md={12} xs={12} >
                        
                        <Card>
                            <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información del domicilio </Typography> }/>
                            
                            <Divider />
                        
                            <CardContent> 
                                <Grid container spacing={3} >                                

                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-estado-label">Estado</InputLabel>
                                        
                                            <Select
                                                labelId="select-estado-label"
                                                id="select-estado"                                            
                                                label="Estado"
                                                name="estado"
                                                value={ estado }
                                                onChange={ ( e ) => { setEstado( e.target.value ) } }
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    estadoArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.estado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.estado } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-municipio-label">Municipio</InputLabel>
                                        
                                            <Select
                                                labelId="select-municipio-label"
                                                id="select-municipio"                                            
                                                label="Municipio"
                                                name="municipio"
                                                value={ municipio }
                                                onChange={ ( e ) => { setMunicipio( e.target.value ) } }
                                                disabled={ estado === 0 }
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    municipioArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.municipio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.municipio } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>
                                        
                                            <Select
                                                labelId="select-codigoPostal-label"
                                                id="select-codigoPostal"                                            
                                                label="Código Postal"
                                                name="codigoPostal"
                                                value={ codigoPostal }
                                                onChange={ ( e ) => { setCodigoPostal( e.target.value ) } }
                                                disabled={ municipio === 0 }
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    codigoPostalArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.codigoPostal } value={ elem.codigoPostal }> { elem.codigoPostal } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.codigoPostal && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.codigoPostal } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-asentamiento-label">Asentamiento</InputLabel>
                                        
                                            <Select
                                                labelId="select-asentamiento-label"
                                                id="select-asentamiento"                                            
                                                label="Asentamiento"
                                                name="asentamiento"
                                                value={ asentamiento }
                                                onChange={ ( e ) => { setAsentamiento( e.target.value ) } }
                                                disabled={ codigoPostal === 0 }
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    asentamientoArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idAsentamiento } value={ elem.idAsentamiento }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.asentamiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.asentamiento } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoDomicilio-label">Tipo Domicilio</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoDomicilio-label"
                                                id="select-tipoDomicilio"                                            
                                                label="Tipo Domicilio"
                                                name="tipoDomicilio"
                                                value={ tipoDomicilio }
                                                onChange={ ( e ) => { setTipoDomicilio( e.target.value ) } }
                                            >
                                                <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    tipoDomicilioArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idTipoDomicilio } value={ elem.idTipoDomicilio }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {   
                                            errors.tipoDomicilio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.tipoDomicilio } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={7} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Descripcion"
                                            name="descripcion"
                                            variant="outlined"                                        
                                            value={ descripcion }                                
                                            onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                                        />

                                        {   
                                            errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.descripcion } </Alert> )
                                        }

                                    </Grid>

                                    <Grid item md={5} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Numero Int./Ext."
                                            name="numero"
                                            variant="outlined"   
                                            type="number"                                     
                                            value={ numero }                                
                                            onChange={ ( e ) => { setNumero( e.target.value ) } }
                                        />
                                        {   
                                            errors.numero && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.numero } </Alert> )
                                        }
                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Referencia"
                                            name="referencia"
                                            variant="outlined"        
                                            multiline
                                            rows={4}                                        
                                            InputLabelProps={{
                                                shrink: true
                                            }}                               
                                            value={ referencia }                                
                                            onChange={ ( e ) => { setReferencia( e.target.value ) } }
                                        />
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                
                    </Grid>

                    <Grid item lg={12} md={12} xs={12} >                    
                        
                        <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>                   
                            <Button type="button" fullWidth variant="contained" startIcon={ <SaveIcon /> } onClick={ GuardarInformacion }> 
                                Guardar Información
                            </Button> 
                        </Grid>                    
                
                    </Grid>

                </Grid>
            </form>
        </>
    );
};