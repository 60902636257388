import React, { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, Dialog,  DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { SentenciaDetalle } from '../../actions/sentencias/sentenciaActions';

export const ModalDetalleSentencia = ( { open, setOpen, idSentencia } ) => {

    const [loading, setLoading] = useState( false );
    const [sentencia, setSentencia] = useState( { } );

    useEffect(() => {   
        
        async function Obtener(){
            
            setLoading( true );

            await SentenciaDetalle( idSentencia ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setSentencia( response.data );
                            setLoading( false );

                        }, 500);
                        
                    }

                }

            });
        }
        
        if( open ){
            Obtener();
        }

    }, [ open, idSentencia ])      

    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth >
              
                <DialogContent>

                    <Grid item md={12} xs={12} >
                        
                        {
                            loading
                            ?
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                </Box>
                            :
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Número de sentencia:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { sentencia?.numeroSentencia }
                                            </Typography>
                                        </Grid>
                                    </Grid> 

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Perspectiva de genero:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { sentencia?.perspectivaGenero ? 'Si' : 'No' }
                                            </Typography>
                                        </Grid>
                                    </Grid>  
                                 
                                    {
                                        sentencia?.sentencia
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Sentencia:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={ sentencia?.sentencia }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                </Box>
                        }
                          
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setSentencia({ }); } } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
